import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, lighten, darken } from '@material-ui/core/styles';
import { warehouseApi as api } from '../../../api/warehouse';
import { productionApi } from '../../../api/production';
import {
    Grid,
    Select,
    MenuItem,
    Card,
    CardHeader,
    Divider,
    CardContent,
    IconButton,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Modal,
    TextField,
    Chip,
} from '@material-ui/core';
import { Notifications, DataTable, FacilityPicker, FormComponent } from '../../../shared';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { UserContext } from '../../../contexts/UserContext';
import { Autocomplete } from '@material-ui/lab';

let OutOfStockReasonModal = ({ row, closeModal }) => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [opts, setOpts] = useState([
        'Vendor Short - Case Short, Ct/Cs discrepancy',
        'Inventory Accuracy (Ops related)',
        'Inventory Accuracy (Planning related)',
        'Gapping',
        'Late Truck',
        'Quality Issue - Rejection, QC pull',
        'No more inventory on hand',
        'Late Truck - DLVD',
        'Other',
    ]);

    let formFields = [
        {
            name: 'reason',
            inputElement: 'select',
            label: 'Reason',
            gridValue: 6,
            inputProps: { required: true, opts: opts },
        },
    ];

    return (
        <div>
            <Modal
                open={true}
                onClose={closeModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        outline: 0,
                        backgroundColor: 'white',
                        width: '700px',
                        margin: '10% auto',
                        padding: '10px',
                    }}
                >
                    <div style={{ width: '90%', margin: '0 auto' }}>
                        <h3>Mark item out of stock</h3>
                        <FormComponent
                            formFields={formFields}
                            onSubmit={async (formData) => {
                                let res = await api.markPackLineSlotOOS(row.lineID, row.lineSlot, formData.reason);
                                if (!res.status) {
                                    setNotification({ text: 'Failed to mark item OOS: ' + res.msg, level: 'error' });
                                    return;
                                }
                                closeModal();
                            }}
                        />
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const PackLineCardSmall = ({ packLine, setFocusedCard, lineSlot4000s }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '10px',
            minWidth: 275,
            margin: '20px',
            minHeight: 310,
            boxShadow: '20px',
            backgroundColor: theme.palette.bone.main,
            transition: 'all .5s',
            '&:hover': {
                transition: 'all .5s',
                cursor: 'pointer',
                backgroundColor: lighten(theme.palette.mustard.main, 0.3),
            },
        },
        cardTitle: {
            fontWeight: '500',
        },
    }));
    const classes = useStyles();

    return (
        <Card className={classes.root} onClick={() => setFocusedCard(packLine.lineNumber)}>
            <CardHeader
                classes={{
                    title: classes.cardTitle,
                }}
                title={`LINE ${packLine.lineNumber}`}
            />
            <Divider />
            <CardContent>
                <Grid container style={{ paddingTop: '10px' }}>
                    <div style={{ fontSize: '20px', textAlign: 'center', width: '100%', padding: '10px' }}>
                        Pack Group {packLine.packGroup.name}, Week {packLine.packGroup.week}
                    </div>
                    <div style={{ fontSize: '20px', textAlign: 'center', width: '100%' }}>
                        <div style={{ display: 'inline-block', margin: '0px 20pz' }}>
                            00s{' '}
                            {packLine.has00s ? (
                                <CheckIcon style={{ position: 'relative', top: '4px', color: 'green' }} />
                            ) : (
                                <CloseIcon style={{ position: 'relative', top: '4px', color: 'red' }} />
                            )}
                        </div>
                        <div style={{ display: 'inline-block', margin: '0px 20px' }}>
                            100s{' '}
                            {packLine.has100s ? (
                                <CheckIcon style={{ position: 'relative', top: '4px', color: 'green' }} />
                            ) : (
                                <CloseIcon style={{ position: 'relative', top: '4px', color: 'red' }} />
                            )}
                        </div>
                        <div style={{ display: 'inline-block', margin: '0px 20px' }}>
                            200s{' '}
                            {packLine.has200s ? (
                                <CheckIcon style={{ position: 'relative', top: '4px', color: 'green' }} />
                            ) : (
                                <CloseIcon style={{ position: 'relative', top: '4px', color: 'red' }} />
                            )}
                        </div>
                        <div style={{ display: 'inline-block', margin: '0px 20px' }}>
                            300s{' '}
                            {packLine.has300s ? (
                                <CheckIcon style={{ position: 'relative', top: '4px', color: 'green' }} />
                            ) : (
                                <CloseIcon style={{ position: 'relative', top: '4px', color: 'red' }} />
                            )}
                        </div>
                        {!!lineSlot4000s && (
                            <div style={{ display: 'inline-block', margin: '0px 20px' }}>
                                400s{' '}
                                {packLine.has400s ? (
                                    <CheckIcon style={{ position: 'relative', top: '4px', color: 'green' }} />
                                ) : (
                                    <CloseIcon style={{ position: 'relative', top: '4px', color: 'red' }} />
                                )}
                            </div>
                        )}
                    </div>
                    <div style={{ fontSize: '20px', textAlign: 'center', width: '100%', padding: '10px' }}>
                        {packLine.outOfStock.length} Out of Stock Item(s)
                    </div>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        {packLine.outOfStock.map((item) => {
                            return (
                                <div key={item.sellableItemID}>
                                    {item.name} - {`Slot ${item.slot}`}
                                </div>
                            );
                        })}
                    </div>
                </Grid>
            </CardContent>
        </Card>
    );
};

let PackLines = () => {
    let [lines, setLines] = useState([]);
    let [packGroups, setPackGroups] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [focusedCard, setFocusedCard] = useState(null);
    let [facilityID, setFacilityID] = useState('');
    let [lineSlot4000s, setLineSlot4000s] = useState(false);
    const [trafficLightEnabled, setTrafficLightEnabled] = useState(false);

    const user = useContext(UserContext);

    useEffect(() => {
        setFocusedCard(null);
        getPackLines();
        getPackGroups();
    }, [facilityID]);

    let getPackLines = async () => {
        if (!facilityID) {
            return;
        }
        let response = await api.getPackLines(facilityID);
        if (response.status === true) {
            let packLines = response.data.packLines;
            packLines.forEach((line) => {
                line.outOfStock = line.packLineSlotMap.filter((item) => item.status === 'OUT_OF_STOCK');
                line.outOfStock.forEach((item) => (item.outOfStock = true));
                line.packLineSlotMap.forEach((item) =>
                    item.status === 'OUT_OF_STOCK' ? (item.outOfStock = true) : (item.outOfStock = false)
                );
            });
            setLines(packLines.sort((a, b) => (a.lineNumber > b.lineNumber ? 1 : -1)));
            setTrafficLightEnabled(response.data.trafficLightEnabled);
        }
    };

    let getPackGroups = async () => {
        if (!facilityID) {
            return;
        }
        let res = await productionApi.getActivePackGroups(facilityID);
        if (res.status === true) {
            setPackGroups(res.data.packGroups);
        }
    };

    useEffect(() => {
        getPackGroups();
    }, []);

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item md={6} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                <h1>Pack Lines</h1>
            </Grid>
            <Grid item md={6} style={{ textAlign: 'right', paddingRight: '35px' }}>
                <FacilityPicker
                    defaultValue={user.getFacilityID()}
                    activeOnly={true}
                    onChange={(facilityID, text, facility) => {
                        setFacilityID(facilityID);
                        if (!!facility) {
                            setLineSlot4000s(facility.lineSlot4000s);
                        }
                    }}
                />
            </Grid>
            {focusedCard === null ? (
                <>
                    {lines.map((item) => {
                        return (
                            <Grid key={item.lineNumber} item md={4} sm={6} xs={12}>
                                <PackLineCardSmall
                                    packLine={item}
                                    setFocusedCard={setFocusedCard}
                                    lineSlot4000s={lineSlot4000s}
                                />
                            </Grid>
                        );
                    })}
                </>
            ) : (
                <Grid item xs={12}>
                    <PackLineCardExpanded
                        packLine={lines.find((item) => item.lineNumber === focusedCard)}
                        setFocusedCard={setFocusedCard}
                        setNotification={setNotification}
                        getPackLines={getPackLines}
                        packGroups={packGroups}
                        lineSlot4000s={lineSlot4000s}
                        lines={lines}
                        trafficLightEnabled={trafficLightEnabled}
                    />
                </Grid>
            )}
        </Grid>
    );
};

const PackLineCardExpanded = ({
    packLine,
    setFocusedCard,
    setNotification,
    getPackLines,
    packGroups,
    lineSlot4000s,
    lines,
    trafficLightEnabled,
}) => {
    let [modalLaunch, setModalLaunch] = useState(false);
    let [modalData, setModalData] = useState({ lineSlot: 0, lineID: 0 });
    const [associatedPackLines, setAssociatedPackLines] = useState(packLine.associatedPackLines);
    const [lineOptions, setLineOptions] = useState(
        lines
            .filter((item) => item.id !== packLine.id && item.lineNumber > packLine.lineNumber)
            .map((line) => ({ text: `Line Number ${line.lineNumber}`, value: line.id }))
    );

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '10px',
            minWidth: 275,
            margin: '20px',
            minHeight: 275,
            boxShadow: '20px',
            backgroundColor: theme.palette.bone.main,
        },
        greenText: {
            color: theme.palette.ivy.main,
        },
        redText: {
            color: theme.palette.tomato.main,
        },
        waveParamKey: {
            fontWeight: 'bold',
        },
        cardTitle: {
            fontWeight: '500',
        },
        header: {
            color: darken(theme.palette.ivy.main, 0.3),
        },
    }));
    let classes = useStyles();

    let updateSlotStatus = async (packLineID, slot, status) => {
        let res = await api.updatePackLineSlotStatus(packLineID, slot, status);
        if (!res.status) {
            setNotification({ text: 'Failed to update status: ' + res.msg, level: 'error' });
            return;
        }
        setNotification({ text: 'Status updated', level: 'success' });
        getPackLines();
    };

    let columns = [
        {
            Header: 'Out of Stock',
            sortable: true,
            type: 'checkbox',
            accessor: 'outOfStock',
            Cell: ({ row, saveEdit, column }) => {
                let outOfStock = row.original.status === 'OUT_OF_STOCK';
                const [updating, setUpdating] = useState(false);
                return (
                    <Checkbox
                        checked={outOfStock}
                        onChange={async () => {
                            if (updating) {
                                return;
                            }

                            setUpdating(true);
                            if (outOfStock == false) {
                                setModalData({ lineSlot: row.original.slot, lineID: packLine.id });
                                setModalLaunch(true);
                            } else {
                                await updateSlotStatus(packLine.id, row.original.slot, 'IN_STOCK');
                            }
                        }}
                    />
                );
            },
        },
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'slot', Header: 'Slot' },
        { accessor: 'week', Header: 'Pack Group Week' },
        { accessor: 'packGroupName', Header: 'Pack Group Name' },
        { accessor: 'negativeVarianceUnits', Header: 'Negative Variance' },
    ];

    return (
        <div>
            {modalLaunch && (
                <OutOfStockReasonModal
                    row={modalData}
                    closeModal={() => {
                        setModalLaunch(false);
                        setNotification({ text: 'Status updated', level: 'success' });
                        getPackLines();
                    }}
                />
            )}
            <Card className={classes.root}>
                <CardHeader
                    classes={{ title: classes.cardTitle }}
                    action={
                        <IconButton
                            onClick={(e) => {
                                setFocusedCard(null);
                                e.stopPropagation();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                    title={`LINE ${packLine.lineNumber}`}
                />
                <Divider />
                <CardContent>
                    <Grid container>
                        <Grid item md={lineSlot4000s ? 2 : 3} style={{ padding: '10px' }}>
                            <FormControlLabel
                                labelPlacement="bottom"
                                control={
                                    <Checkbox
                                        id="00sonline_checkbox"
                                        style={{ margin: '10px 0px' }}
                                        onChange={async (event) => {
                                            let val = event.target.checked;
                                            let res = await api.updatePackLine(packLine.id, { has00s: val });
                                            if (res.status === true) {
                                                getPackLines();
                                            } else {
                                                setNotification({
                                                    text: 'Error updating pack line: ' + res.msg,
                                                    level: 'error',
                                                });
                                            }
                                        }}
                                        checked={packLine.has00s}
                                        color="primary"
                                    />
                                }
                                label="00s on line"
                            />
                        </Grid>
                        <Grid item md={lineSlot4000s ? 2 : 3} style={{ padding: '10px' }}>
                            <FormControlLabel
                                labelPlacement="bottom"
                                control={
                                    <Checkbox
                                        id="100sonline_checkbox"
                                        style={{ margin: '10px 0px' }}
                                        onChange={async (event) => {
                                            let val = event.target.checked;
                                            let res = await api.updatePackLine(packLine.id, { has100s: val });
                                            if (res.status === true) {
                                                getPackLines();
                                            } else {
                                                setNotification({
                                                    text: 'Error updating pack line: ' + res.msg,
                                                    level: 'error',
                                                });
                                            }
                                        }}
                                        checked={packLine.has100s}
                                        color="primary"
                                    />
                                }
                                label="100s on line"
                            />
                        </Grid>
                        <Grid item md={lineSlot4000s ? 2 : 3} style={{ padding: '10px' }}>
                            <FormControlLabel
                                labelPlacement="bottom"
                                control={
                                    <Checkbox
                                        id="200sonline_checkbox"
                                        style={{ margin: '10px 0px' }}
                                        onChange={async (event) => {
                                            let val = event.target.checked;
                                            let res = await api.updatePackLine(packLine.id, { has200s: val });
                                            if (res.status === true) {
                                                getPackLines();
                                            } else {
                                                setNotification({
                                                    text: 'Error updating pack line: ' + res.msg,
                                                    level: 'error',
                                                });
                                            }
                                        }}
                                        checked={packLine.has200s}
                                        color="primary"
                                    />
                                }
                                label="200s on line"
                            />
                        </Grid>
                        <Grid item md={lineSlot4000s ? 2 : 3} style={{ padding: '10px' }}>
                            <FormControlLabel
                                labelPlacement="bottom"
                                control={
                                    <Checkbox
                                        id="300sonline_checkbox"
                                        style={{ margin: '10px 0px' }}
                                        onChange={async (event) => {
                                            let val = event.target.checked;
                                            let res = await api.updatePackLine(packLine.id, { has300s: val });
                                            if (res.status === true) {
                                                getPackLines();
                                            } else {
                                                setNotification({
                                                    text: 'Error updating pack line: ' + res.msg,
                                                    level: 'error',
                                                });
                                            }
                                        }}
                                        checked={packLine.has300s}
                                        color="primary"
                                    />
                                }
                                label="300s on line"
                            />
                        </Grid>
                        {!!lineSlot4000s && (
                            <Grid item md={2} style={{ padding: '10px' }}>
                                <FormControlLabel
                                    labelPlacement="bottom"
                                    control={
                                        <Checkbox
                                            style={{ margin: '10px 0px' }}
                                            onChange={async (event) => {
                                                let val = event.target.checked;
                                                let res = await api.updatePackLine(packLine.id, { has400s: val });
                                                if (res.status === true) {
                                                    getPackLines();
                                                } else {
                                                    setNotification({
                                                        text: 'Error updating pack line: ' + res.msg,
                                                        level: 'error',
                                                    });
                                                }
                                            }}
                                            checked={packLine.has400s}
                                            color="primary"
                                        />
                                    }
                                    label="400s on line"
                                />
                            </Grid>
                        )}
                        <Grid item md={6} style={{ padding: '10px' }}>
                            <InputLabel shrink={true}>Pack Group</InputLabel>
                            <Select
                                style={{ width: '100%' }}
                                value={packLine.packGroupID}
                                onChange={async (event) => {
                                    let res = await api.hydratePackLineSlotMap(packLine.id, event.target.value);
                                    if (res.status === true) {
                                        setNotification({ text: 'Pack Group changed', level: 'success' });
                                        getPackLines();
                                    } else {
                                        setNotification({
                                            text: 'Error changing pack group: ' + res.msg,
                                            level: 'error',
                                        });
                                    }
                                }}
                            >
                                {packGroups.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        Pack Group {item.name}, Week {item.week} Year {item.year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item md={6} style={{ padding: '10px' }}>
                            {packLine.outOfStock.length ? (
                                <>
                                    <h3 className={classes.redText}>Out of Stock Items</h3>
                                    {packLine.outOfStock.map((item) => {
                                        return (
                                            <div key={item.sellableItemID}>
                                                {item.name} - {`Slot ${item.slot}`}
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <h2 className={classes.greenText}>No out of stock items</h2>
                            )}
                        </Grid>
                        {trafficLightEnabled && (
                            <Grid item md={6} style={{ padding: '10px' }}>
                                <InputLabel shrink={true}>Associates To:</InputLabel>
                                <Autocomplete
                                    value={associatedPackLines}
                                    multiple={true}
                                    onChange={async (e, val, reason, detail) => {
                                        if (reason === 'select-option') {
                                            let res = await api.createAssociatedPackLine(
                                                packLine.id,
                                                detail.option.value
                                            );
                                            if (!res.status) {
                                                setNotification({
                                                    text: 'Failed to associate pack lines: ' + res.msg,
                                                    level: 'error',
                                                });
                                                return;
                                            } else {
                                                setAssociatedPackLines((items) => [...items, detail.option]);
                                            }
                                        } else if (reason === 'remove-option') {
                                            let res = await api.deleteAssociatedPackLine(
                                                packLine.id,
                                                detail.option.value
                                            );
                                            if (!res.status) {
                                                setNotification({
                                                    text: 'Failed to disassociate pack lines: ' + res.msg,
                                                    level: 'error',
                                                });
                                                return;
                                            } else {
                                                setAssociatedPackLines((items) =>
                                                    items.filter((item) => item.value !== detail.option.value)
                                                );
                                            }
                                        } else if (reason === 'clear') {
                                            let promises = associatedPackLines.map((line) =>
                                                api.deleteAssociatedPackLine(packLine.id, line.value)
                                            );
                                            let res = await Promise.all(promises);
                                            if (!res.every((item) => item.status)) {
                                                setNotification({
                                                    text: 'Failed to disassociate pack lines: ' + res.msg,
                                                    level: 'error',
                                                });
                                                return;
                                            }
                                            setAssociatedPackLines([]);
                                        }

                                        getPackLines();
                                        return true;
                                    }}
                                    filterSelectedOptions
                                    getOptionLabel={(option) => option.text}
                                    options={lineOptions.filter(
                                        (line) =>
                                            !associatedPackLines
                                                .map((associatedLine) => associatedLine.value)
                                                .includes(line.value)
                                    )}
                                    renderInput={(params) => <TextField {...params} variant="standard" />}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <DataTable
                                editable={false}
                                title="Items on Line"
                                columns={columns}
                                data={packLine.packLineSlotMap}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export { PackLines };
