import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { productApi as api } from '../../../api/product';
import { UserContext } from '../../../contexts/UserContext';

let CreateFeatureFlag = () => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });

    let formFields = [
        { name: 'name', inputElement: 'textField', label: 'Name', gridValue: 12, inputProps: { required: true } },
    ];

    let create = async (formData) => {
        let sendParams = {
            name: formData.name,
        };

        let response = await api.createFeatureFlag(sendParams);
        if (response.status === true) {
            setNotification({ text: 'Flag created!', level: 'success' });
        } else {
            let message = 'Error creating flag';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
    };

    return (
        <div>
            <h1>Create feature flag</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        create(formData);
                        resetForm();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

export { CreateFeatureFlag };
