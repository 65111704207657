import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { logisticsApi as api } from '../../../api/logistics';
import { fbmApi } from '../../../api/fbm';
import { TabPanel, Notifications, FormComponent, DataTable } from '../../../shared';
import { Button, Grid, Select, MenuItem, Tabs, Tab, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

let TransitDays = () => {
    const [submittingFiles, setSubmittingFiles] = useState(false);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [marketplaceOpen, setMarketplaceOpen] = useState(false);

    let getMarketplaceOpen = async () => {
        let now = moment(),
            open = moment().set({ hour: 15, minute: 30 }),
            close = moment().set({ hour: 18, minute: 0 });
        let mo = now.isBetween(open, close);
        setMarketplaceOpen(mo);
    };

    useEffect(() => {
        getMarketplaceOpen();
    }, []);

    let formFields = [
        { name: 'transitDayChanges', inputElement: 'file', accept: ['.csv'], label: 'Transit Day File', gridValue: 12 },
    ];
    return (
        <Grid container>
            <Notifications options={notification} />
            <h2> Transit Day Changes </h2>
            {marketplaceOpen && (
                <Grid item xs={12}>
                    <h3 style={{ color: 'darkred' }}>
                        Marketplace is Open. Transit Day Changes will be unlocked at 6pm.
                    </h3>
                </Grid>
            )}
            {!marketplaceOpen && (
                <Grid item xs={12}>
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData, resetForm) => {
                            setSubmittingFiles(true);
                            let res = await api.submitTransitDayFile(formData);
                            if (res.status === true) {
                                setNotification({ level: 'success', text: 'Transit day changes complete' });
                                resetForm();
                            } else {
                                setNotification({ level: 'error', text: `Error changing transit days: ${res.msg}` });
                            }
                            setSubmittingFiles(false);
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

let ZipMap = () => {
    const [submittingFiles, setSubmittingFiles] = useState(false);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [days, setDays] = useState([]);
    const [marketplaceOpen, setMarketplaceOpen] = useState(false);
    const [fbmAccountOpts, setFbmAccountOpts] = useState([]);

    const allDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    let getDays = async () => {
        let res = await api.getValidDaysForUpload();
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to get valid days for zip map upload' });
            return;
        }

        setDays(res.data.days);
    };

    let getMarketplaceOpen = async () => {
        let now = moment(),
            open = moment().set({ hour: 15, minute: 30 }),
            close = moment().set({ hour: 18, minute: 0 });
        let mo = now.isBetween(open, close);
        setMarketplaceOpen(mo);
    };

    let getFBMAccounts = async () => {
        let response = await fbmApi.getFBMAccounts();
        if (response.status === false) {
            return;
        }
        let fbmOpts = response.data.accounts.map((item) => {
            return { text: item.companyName, value: item.fbmAccountName };
        });
        fbmOpts.unshift({ text: '', value: '' });
        setFbmAccountOpts(fbmOpts);
    };

    useEffect(() => {
        getDays();
        getMarketplaceOpen();
        getFBMAccounts();
    }, []);

    let formFields = [
        { name: 'backupFile', inputElement: 'file', accept: ['.csv'], label: 'Backup Zip File', gridValue: 12 },
        { name: 'optimalFile', inputElement: 'file', accept: ['.csv'], label: 'Optimal Zip File', gridValue: 12 },
        {
            name: 'deliveryDay',
            inputElement: 'select',
            label: 'Delivery Day',
            inputProps: {
                opts: (formData) => {
                    return formData.fbmPartnerName ? allDays : days;
                },
                required: true,
            },
            gridValue: 12,
        },
        { name: 'notes', inputElement: 'textField', label: 'Notes', inputProps: { multiline: true }, gridValue: 12 },
        {
            name: 'fbmPartnerName',
            inputElement: 'select',
            label: 'FBM Partner',
            gridValue: 2,
            inputProps: { required: false, opts: fbmAccountOpts },
        },
    ];
    return (
        <Grid container>
            <Notifications options={notification} />
            <h2> Zip Map Upload </h2>
            {marketplaceOpen && (
                <Grid item xs={12}>
                    <h3 style={{ color: 'darkred' }}>Marketplace is Open. Zip Map Upload will be unlocked at 6pm.</h3>
                </Grid>
            )}
            {!marketplaceOpen && (
                <Grid item xs={12}>
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData, resetForm) => {
                            setSubmittingFiles(true);
                            let res = await api.submitZipFiles(formData);
                            if (res.status === true) {
                                setNotification({
                                    level: 'success',
                                    text: 'Files received. You will receive results by email when setting zip map is complete.',
                                });
                                resetForm();
                            } else {
                                setNotification({ level: 'error', text: res.msg ? res.msg : 'Error sending files.' });
                            }
                            setSubmittingFiles(false);
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

let SubsOnInvalidLineHauls = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    let [notification, setNotification] = useState({ text: '', level: '' });

    let getSubsOnInvalidLineHauls = async () => {
        let res = await api.getSubsOnInvalidLineHauls();
        if (!res.status) {
            setNotification({ text: 'Failed to get subs on invalid line hauls', level: 'error' });
            return;
        }
        setRows(res.data.rows);
    };

    useEffect(() => {
        getSubsOnInvalidLineHauls();
    }, []);

    let columns = [
        { accessor: 'day', Header: 'Delivery Day' },
        { accessor: 'total', Header: '# Subs on Invalid LH' },
        {
            Header: 'Reassign',
            Cell: ({ row }) => {
                if (row.original.canReassign) {
                    return (
                        <Button
                            id={`Reassign_Subs_${row.day}`}
                            disabled={loading}
                            onClick={async () => {
                                setLoading(true);
                                let res = await api.reassignSubsOnInvalidLineHauls(row.original.day);
                                if (res.status === true) {
                                    setNotification({
                                        level: 'success',
                                        text: 'Reassigning subs. You will receive results by email when complete.',
                                    });
                                } else {
                                    setNotification({
                                        level: 'error',
                                        text: res.msg ? res.msg : 'Error sending reassigning.',
                                    });
                                }
                                setLoading(false);
                            }}
                        >
                            Reassign
                        </Button>
                    );
                } else {
                    return null;
                }
            },
        },
    ];

    return (
        <Paper>
            <Notifications options={notification} />
            <DataTable columns={columns} data={rows} />
        </Paper>
    );
};

let ZipMapUpload = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ZipMap_Tab" label="Zip Map" value="0" />
                <Tab id="TransitDays_Tab" label="Transit Days" value="1" />
                <Tab id="InvalidLHSubs_Tab" label="Reassign Subs" value="2" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ZipMap />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <TransitDays />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <SubsOnInvalidLineHauls />
            </TabPanel>
        </Paper>
    );
};

export { ZipMapUpload };
