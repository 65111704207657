import React, { useState, useEffect, useContext } from 'react';
import { partnershipsApi as api } from '../../../../api/partnerships';
import { Select } from '../../../shared';
import { UserContext } from '../../../../contexts/UserContext';

const FamAccountPicker = ({ readOnly }) => {
    const [famAccounts, setFamAccounts] = useState([]);

    const { user, famAccount, setFamAccount } = useContext(UserContext);

    useEffect(() => {
        if (user.famPartner) {
            // let partnershipSource = user.partnershipSource;
            // getFamAccount(user.partnershipSource);
        } else {
            getFamAccounts();
        }
    }, [user]);

    const getFamAccounts = async () => {
        const res = await api.getSources();
        if (!res.status) {
            return;
        }

        setFamAccounts(res.data.rows);
    };

    // useEffect(() => {
    //     if (!!fbmAccountIDInternal) {
    //         let account = fbmAccounts.find((item) => item.id === fbmAccountIDInternal);
    //         if (account) {
    //             setFbmAccount(account);
    //         }
    //     }
    // }, [fbmAccountIDInternal]);

    // This will be invisible for partners, it will just set the account
    if (user.famPartner || !user.id) {
        return null;
    }

    return (
        <div style={{ position: 'absolute', right: '20px', top: '2px' }}>
            <Select
                readOnly={readOnly}
                small
                label="Partner"
                value={famAccount.partnershipSource}
                onChange={({ selectedItem }) => {
                    setFamAccount({ partnershipSource: selectedItem.name, facilities: selectedItem.facilities });
                }}
                options={famAccounts.map((item) => ({ ...item, text: item.name, value: item.name }))}
            />
        </div>
    );
};

export { FamAccountPicker };
