import React, { useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const Tooltip = ({ text, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: 'relative', display: 'inline-block' }}
        >
            <InfoOutlinedIcon className="lists-info-icon" />
            {showTooltip && <span className="fam-tooltip grotesk-bold">{text}</span>}
        </div>
    );
};

export { Tooltip };
