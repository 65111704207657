import React, { useState, useEffect, useContext } from 'react';
import { warehouseApi as api } from '../../api/warehouse';
import { Select, MenuItem } from '@material-ui/core';

const FacilityPicker = ({
    activeOnly = false,
    inactiveDisabled = false,
    onChange,
    defaultValue = '',
    selectStyle = {},
    whitelistedFacilities = null,
}) => {
    const [facilities, setFacilities] = useState([]);
    const [facilityID, setFacilityID] = useState(defaultValue);

    let getFacilities = async () => {
        let res = await api.getFacilities(activeOnly);
        if (res.status !== true) {
            return;
        }

        let fac = res.data.rows.map((item) => ({
            text: item.name,
            value: item.id,
            active: item.active,
            lineSlot4000s: item.lineSlot4000s,
            caseMovements: item.caseMovements,
        }));
        if (!!whitelistedFacilities && whitelistedFacilities.length > 0) {
            fac = fac.filter((item) => whitelistedFacilities.includes(item.value));
        }
        setFacilities(fac);
    };

    useEffect(() => {
        if (onChange && onChange instanceof Function) {
            let facility = facilities.find((item) => item.value === facilityID);
            onChange(facilityID, facility?.text, facility);
        }
    }, [facilityID, facilities]);

    useEffect(() => {
        getFacilities();
    }, []);

    useEffect(() => {
        setFacilityID(defaultValue);
    }, [defaultValue]);

    return (
        <span>
            <h2>
                Facility &nbsp;&nbsp;
                <Select
                    id="Facility_select"
                    style={selectStyle}
                    value={facilityID}
                    onChange={(event) => {
                        setFacilityID(event.target.value);
                    }}
                >
                    {facilities.map((item) => (
                        <MenuItem disabled={inactiveDisabled && !item.active} key={item.value} value={item.value}>
                            {item.text}
                        </MenuItem>
                    ))}
                </Select>
            </h2>
        </span>
    );
};

export { FacilityPicker };
