import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let procurementApi = {
    addItemToPurchaseOrder: async (id, item) => {
        let url = `api/procurement/v1/purchaseOrder/` + id + `/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    cancelCreditRequest: async (id) => {
        let url = `api/procurement/v1/creditRequest/${id}/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    cancelPurchaseOrder: async (id, reasonCode) => {
        let url = `api/procurement/v1/purchaseOrder/` + id + `/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ reasonCode }),
        });
        return response;
    },
    cloneItemOnPO: async (poID, poMapID) => {
        let url = `api/procurement/v1/purchaseOrder/${poID}/item/${poMapID}/clone`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    receiveCreditRequest: async (id) => {
        let url = `api/procurement/v1/creditRequest/${id}/receive`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    confirmPurchaseOrdersUpload: async (key) => {
        let url = 'api/procurement/v1/purchaseOrders/confirm';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    createShipper: async (item) => {
        let url = `api/procurement/v1/shipper`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createPurchaseOrder: async (item) => {
        let url = `api/procurement/v1/purchaseOrder`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createCreditRequest: async (data) => {
        let url = `api/procurement/v1/creditRequest`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    },
    downloadPOCSV: async (poID, vendorName) => {
        let filename = 'PO#' + poID + '-' + vendorName + '.csv';
        let url = `api/procurement/v1/purchaseOrder/` + poID + `/csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadPOCSV] error', e.toString());
        }
    },
    downloadPODocument: async (poID, documentID, docName) => {
        let url = `api/procurement/v1/purchaseOrder/` + poID + `/documents/` + documentID;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = docName;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadPODocument] error', e.toString());
        }
    },
    downloadPOPDF: async (poID, vendorName, fcName) => {
        let filename;

        if (fcName !== undefined) {
            //MM PO
            filename = `${fcName}-PO#${poID}-${vendorName}.pdf`;
        } else {
            //FBM ASN
            filename = `PO#${poID}.pdf`;
        }
        let url = `api/procurement/v1/purchaseOrder/` + poID + `/pdf`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadPOPDF] error', e.toString());
        }
    },
    downloadPOShipList: async (poID) => {
        let filename = 'ship_list_' + poID + '.pdf';
        let url = `api/procurement/v1/purchaseOrder/` + poID + `/shipList/pdf`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadPOCSV] error', e.toString());
        }
    },
    getBuyers: async () => {
        let url = `api/procurement/v1/buyers`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCreditRequests: async () => {
        let url = `api/procurement/v1/creditRequest`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCreditRequestItems: async (id) => {
        let url = `api/procurement/v1/creditRequest/${id}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCreditRequestAccountingStatuses: async () => {
        let url = `api/procurement/v1/creditRequestStatuses`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCreditRequestReasons: async () => {
        let url = `api/procurement/v1/creditRequestReasons`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPOsPlaced: async (facilityID = '', truckPoType = '') => {
        let url = `api/procurement/v1/purchaseOrder?status=PLACED`;
        if (!!facilityID) {
            url += `&facilityID=${facilityID}`;
        }
        if (!!truckPoType) {
            url += `&poType=${truckPoType}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getProvinces: async (country) => {
        let url = `api/addresses/v1/provinces?country=` + country;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrderDetails: async (id) => {
        let url = `api/procurement/v1/purchaseOrder/` + id;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLastPOItemFromVendor: async (vendorID, intSku) => {
        let url = `api/procurement/v1/search/purchaseOrders/items?vendorID=` + vendorID + `&intSKU=` + intSku;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrderDocuments: async (id) => {
        let url = `api/procurement/v1/purchaseOrder/` + id + `/documents`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrderItemTypes: async () => {
        let url = `api/procurement/v1/purchaseOrder/items/types`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrders: async (facilityID = 0, externalSku = null, poID = null, typeFilter = null) => {
        let url = `api/procurement/v1/purchaseOrder?facilityID=` + facilityID;
        if (!!externalSku) {
            url += `&externalSku=${externalSku}`;
        }
        if (!!poID) {
            url += `&poID=${poID}`;
        }
        if (!!typeFilter) {
            url += `&typeFilters=${typeFilter}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPOUpdateReasonCodes: async () => {
        let url = `api/procurement/v1/purchaseOrder/reasonCodes`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getShippers: async () => {
        let url = `api/procurement/v1/shippers`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getSourcingReasons: async () => {
        return await mmFetchWithResponse('api/procurement/v1/sourcing/reasons');
    },
    getUnpurchasedOutcomes: async () => {
        return await mmFetchWithResponse('api/procurement/v1/unpurchased-outcomes');
    },
    getVendors: async () => {
        let url = `api/procurement/v1/vendor`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getIncompleteVendors: async () => {
        let url = `api/procurement/v1/incompletevendors`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    removeItemFromPO: async (id, poMapID, reasonCode) => {
        let url = `api/procurement/v1/purchaseOrder/` + id + `/item/` + poMapID + `/remove`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ reasonCode }),
        });
        return response;
    },
    removePODocument: async (poID, id) => {
        let url = `api/procurement/v1/purchaseOrder/` + poID + `/documents/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    sendPurchaseOrderToVendor: async (poID) => {
        let url = `api/procurement/v1/purchaseOrder/${poID}/send`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    downloadPrePopulatedCsv: async (poIDs) => {
        let url = `api/procurement/v1/purchaseOrders/downloadPrePopulatedCsv`;

        try {
            let res = await mmFetch(url, {
                method: 'PUT',
                body: JSON.stringify(poIDs),
            });
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'pre-populated-PO-template.csv';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadPOCSV] error', e.toString());
        }
    },
    updateCreditRequest: async (id, data) => {
        let url = `api/procurement/v1/creditRequest/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(data),
        });
        return response;
    },
    updateCreditRequestItem: async (creditRequestID, itemID, data) => {
        let url = `api/procurement/v1/creditRequest/${creditRequestID}/item/${itemID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(data),
        });
        return response;
    },
    updatePurchaseOrder: async (id, item) => {
        let url = `api/procurement/v1/purchaseOrder/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updatePurchaseOrderMap: async (poID, poMapID, updates) => {
        let url = `api/procurement/v1/purchaseOrder/${poID}/item/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(updates),
        });
        return response;
    },
    updateShipper: async (id, item) => {
        let url = `api/procurement/v1/shipper/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updateVendor: async (id, item) => {
        let url = `api/procurement/v1/vendor/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updateVendorAddress: async (id, item) => {
        let url = `api/procurement/v1/vendorAddress/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    uploadPODocument: async (id, data) => {
        let formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        let url = `api/procurement/v1/purchaseOrder/${id}/documents`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadPurchaseOrders: async (data) => {
        let formData = new FormData();
        formData.append('csvFile', data.file);
        let url = 'api/procurement/v1/purchaseOrders/upload';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
};

export { procurementApi };
