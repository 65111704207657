import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { customerApi as api } from '../../../../api/customer';

const CancelShipmentModal = ({
    setOpen,
    open,
    setNotification,
    getSubscription,
    getOrders,
    selectedShipmentID,
    selectedChargeID,
}) => {
    let cancelShipment = async () => {
        let res = await api.cancelShipment(selectedChargeID, selectedShipmentID);
        if (!res.status) {
            setNotification({ text: 'Unable to cancel shipment: ' + res.msg, level: 'error' });
            return;
        }

        setNotification({ text: 'Shipment cancelled', level: 'success' });
        setOpen(false);
        getSubscription();
        getOrders();
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>Cancel Shipment</DialogTitle>
            <DialogContent>Confirm cancel for shipment. No refunds will be issued.</DialogContent>
            <DialogActions>
                <Button onClick={cancelShipment}>Cancel Shipment</Button>
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { CancelShipmentModal };
