import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let financeApi = {
    confirmVolumeProjection: async (key) => {
        let url = 'api/finance/v1/volumeProjection/confirm';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    deleteDriverTipsDriverRecords: async (ids) => {
        let url = 'api/finance/v1/driverTips/driverReport';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ ids }),
        });
        return response;
    },
    downloadTipAudit: async (startDate, endDate) => {
        let filename = 'tip_audit.csv';
        let url = `api/finance/v1/driverTips/audit?startDate=` + startDate + '&endDate=' + endDate;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadTipAudit] error', e.toString());
        }
    },
    getDriverTipsBackChargeReport: async (startDate) => {
        let url = `api/finance/v1/driverTips/backCharge?startDate=` + startDate;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDriverTipsCostCenterReport: async (hasTipsOnly, startDate, endDate) => {
        let url = `api/finance/v1/driverTips/costCenter?startDate=` + startDate + '&endDate=' + endDate;
        if (hasTipsOnly === true) {
            url += '&hasTipsOnly=true';
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDriverTipsDriverReport: async (startDate, endDate) => {
        let url = `api/finance/v1/driverTips/driverReport?startDate=` + startDate + '&endDate=' + endDate;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrdersWithPaymentInfo: async () => {
        let url = `api/finance/v1/purchaseOrder`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTrucksWithPaymentInfo: async () => {
        let url = `api/finance/v1/fobTruck`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getVolumeProjectionUploads: async () => {
        let url = `api/finance/v1/volumeProjection`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    payPurchaseOrder: async (id, sendParams) => {
        let url = `api/finance/v1/purchaseOrder/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    payTruck: async (id, sendParams) => {
        let url = `api/finance/v1/fobTruck/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    syncDriverTipsTimecards: async () => {
        let url = `api/finance/v1/driverTips/timeCards`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    uploadVolumeProjection: async (data) => {
        let formData = new FormData();
        formData.append('file', data.file);

        let url = 'api/finance/v1/volumeProjection/upload';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    updateVolumeProjection: async (id, sendParams) => {
        let url = `api/finance/v1/volumeProjection/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
};

export { financeApi };
