import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { customerApi as api } from '../../../api/customer';
import moment from 'moment';

const MultishipRefunds = ({
    refundableItems,
    formData,
    setFormField,
    shipmentDetails,
    setItemsSelectedTotal,
    setShipmentBoxChecked,
    shipmentBoxChecked,
    refundableItemsByShipments,
    user,
}) => {
    const [shipmentsAlreadyRefunded, setShipmentsAlreadyRefunded] = useState([]);

    let getShipmentsAlreadyFullyRefunded = () => {
        let shipments = [];
        for (let i = 0; i < refundableItemsByShipments.length; i++) {
            let shipmentID = refundableItemsByShipments[i].shipmentID;
            let itemsInShipment = refundableItemsByShipments[i].items;

            if (itemsInShipment.length === 0 || itemsInShipment.length < 1) {
                shipments.push(shipmentID);
            }
        }
        setShipmentsAlreadyRefunded(shipments);
    };

    let getFullRefundDetailsByShipment = async (selectedShipments) => {
        let totalAmount = 0;
        let filteredItemsByShipment = refundableItemsByShipments.filter((box) =>
            selectedShipments.includes(box.shipmentID)
        );

        for (let i = 0; i < filteredItemsByShipment.length; i++) {
            let items = filteredItemsByShipment[i].items;

            if (items.length < 1) {
                continue;
            }

            let total = items.map(
                (item) => item.sellableItemModel.price * item.qty + item.taxAmount - item.discountAmount
            );
            let amt = total.reduce((acc, currVal) => {
                return acc + currVal;
            });

            totalAmount += amt;
        }

        setItemsSelectedTotal(totalAmount);
    };

    useEffect(() => {
        getShipmentsAlreadyFullyRefunded();
    }, []);

    if (refundableItems.length <= 0) {
        return <div></div>;
    }

    return (
        <Grid item xs={12} style={{ display: 'flex', flexFlow: 'wrap' }}>
            {shipmentDetails.map((shipment) => {
                return (
                    <SelectedShipment
                        shipment={shipment}
                        key={shipment.shipmentNumber}
                        shipmentBoxChecked={shipmentBoxChecked}
                        shipmentsAlreadyRefunded={shipmentsAlreadyRefunded}
                        setShipmentSelected={async (value, action) => {
                            let tmp = [];

                            formData.refundByShipments.forEach((item) => {
                                tmp.push(item);
                            });

                            if (action === 'delete') {
                                tmp = tmp.filter((val) => val !== value);
                            } else if (action === 'add') {
                                tmp.push(value);
                            }

                            setFormField(tmp);
                            setShipmentBoxChecked(tmp);
                            getFullRefundDetailsByShipment(tmp);
                        }}
                        user={user}
                    />
                );
            })}
        </Grid>
    );
};

const SelectedShipment = ({
    shipment,
    setShipmentSelected,
    shipmentBoxChecked,
    formData,
    shipmentsAlreadyRefunded,
    user,
}) => {
    let today = moment();

    return (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={async (event) => {
                        if (!event.target.checked) {
                            setShipmentSelected(shipment.shipmentID, 'delete');
                        } else {
                            setShipmentSelected(shipment.shipmentID, 'add');
                        }
                    }}
                    disabled={
                        formData?.fullRefund.toString() === 'true' ||
                        formData?.refundShipping === true ||
                        formData?.refundColdKeeperFee === true ||
                        shipmentsAlreadyRefunded.indexOf(shipment.shipmentID) >= 0 ||
                        (today.diff(moment(shipment.deliverDate.date), 'days') > 7 && !user.user.canRefundPastDate)
                    }
                    value={shipment.shipmentID}
                    checked={shipmentBoxChecked?.includes(shipment.shipmentID)}
                    color="primary"
                />
            }
            label={`Box ${shipment.shipmentNumber} Refund`}
        />
    );
};

export { MultishipRefunds };
