import React, { useEffect, useState, useRef } from 'react';
import { Paper, TextField, Select, Chip, Checkbox, Dialog, DialogContent, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/DoneOutline';

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const EditableField = ({
    initialValue,
    id,
    field,
    customEdit,
    onCellClick, // only for non editable cells
    saveEdit,
    editProps,
    editable = true,
    type,
    editIconStyle = null,
    restrictEditArea = false,
    row,
    cellStyle,
    colHeader,
    rowID,
    label = '',
}) => {
    const [currentValue, setCurrentValue] = useState(initialValue === null ? '' : initialValue);
    const [inputValue, setInputValue] = useState(initialValue === null ? '' : initialValue);
    const inputEl = useRef(null);
    const prevValue = usePrevious(inputValue);
    const [editing, setEditing] = useState(false);
    const [editClicked, setEditClicked] = useState(false);

    const [editChipModalOpen, setEditChipModalOpen] = useState(false);
    const [editChipModalSelected, setEditChipModalSelected] = useState([]);

    const useStyles = makeStyles((theme) => {
        let returnObj = {
            editIcon: {
                color: 'lightgray',
                '&:hover': {
                    color: 'blue',
                },
            },
        };
        if (editIconStyle) {
            Object.keys(editIconStyle).forEach((key) => {
                if (editIconStyle[key] instanceof Function) {
                    returnObj.editIcon[key] = editIconStyle[key](theme);
                } else {
                    returnObj.editIcon[key] = editIconStyle[key];
                }
            });
        }
        return returnObj;
    });
    const classes = useStyles();

    const validateNumericInput = (value, editProps, eventTarget) => {
        if (value === '-') {
            return true;
        }

        if (isNaN(value)) {
            return false;
        }

        let validationError = '';

        if (editProps.integer) {
            if (value.indexOf('.') > 0 || !Number.isInteger(Number(value))) {
                validationError = 'Value must be an integer';
            }
        }
        if (editProps.hasOwnProperty('min') && Number(value) < Number(editProps.min)) {
            validationError = `Value must be greater than or equal to ${editProps.min}.`;
        } else if (editProps.hasOwnProperty('max') && Number(value) > Number(editProps.max)) {
            validationError = `Value must be less than or equal to ${editProps.max}.`;
        }
        eventTarget.setCustomValidity(validationError);
        inputEl.current.closest('form').reportValidity();

        return true;
    };

    const onChange = (e) => {
        if (editProps && editProps.inputType === 'number') {
            let valid = validateNumericInput(e.target.value, editProps, e.target);
            if (!valid) {
                return false;
            }
        }

        setInputValue(e.target.value);
    };

    useEffect(() => {
        setCurrentValue(initialValue === null ? '' : initialValue);
        setInputValue(initialValue === null ? '' : initialValue);
    }, [initialValue]);

    const getSelectOptions = (opts) => {
        let options = [];
        if (opts instanceof Function) {
            let optionsRes = opts(row);
            if (Array.isArray(optionsRes)) {
                options = optionsRes;
            }
        } else if (Array.isArray(opts)) {
            options = [...opts];
        }
        options = options.map((opt) => {
            let text, value;
            let disabled = false;

            if (opt.hasOwnProperty('text') && opt.hasOwnProperty('value')) {
                text = opt.text;
                value = opt.value;
                if (opt.hasOwnProperty('disabled') && opt.disabled) {
                    disabled = true;
                }
            } else if (opt.hasOwnProperty('id') && opt.hasOwnProperty('name')) {
                text = opt.name;
                value = opt.id;
                if (opt.hasOwnProperty('disabled') && opt.disabled) {
                    disabled = true;
                }
            } else if (typeof opt === 'string') {
                text = opt;
                value = opt;
            }
            if (typeof opt == 'string') {
                return { text, value, id: value, name: text };
            } else {
                return { ...opt, text, value, disabled };
            }
        });
        return options;
    };

    const handleEdit = async ({ type }) => {
        if (inputEl && inputEl.current && inputEl.current.closest('form').checkValidity() === false) {
            inputEl.current.closest('form').reportValidity();
            return false;
        }

        let updateValue = inputValue;

        if (type === 'date' && inputValue === '') {
            updateValue = null;
        }

        if (type === 'checkbox') {
            updateValue = !currentValue;
        }

        if (updateValue !== currentValue) {
            let res = await saveEdit({ id, field, value: updateValue, row, currentValue });
            if (res === false) {
                setInputValue(currentValue);
            } else if (res === true) {
                setCurrentValue(updateValue);
            }
        }

        setEditing(false);
        setEditClicked(false);
    };

    let renderNotEditingElement = (cellStyle) => {
        let displayValue = currentValue;

        if (editProps.type === 'autocomplete-multiple') {
            let options = getSelectOptions(editProps.options);

            if (!Array.isArray(displayValue)) {
                return <div>{displayValue}</div>;
            }
            return (
                <div>
                    {displayValue.map((item) => (
                        <Chip
                            style={{ margin: '5px' }}
                            size="small"
                            key={item}
                            label={options.find((opt) => opt.value === item)?.text}
                            color="primary"
                        />
                    ))}
                </div>
            );
        }

        if (editProps.type === 'autocomplete-multiple-chips') {
            let options = getSelectOptions(editProps.options);
            let currentlySelected = getSelectOptions(currentValue);

            return (
                <div
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <Dialog
                        open={editChipModalOpen}
                        fullWidth={true}
                        maxWidth="sm"
                        onClose={() => setEditChipModalOpen(false)}
                    >
                        <DialogContent>
                            <h4>
                                {editProps.modalHeader instanceof Function
                                    ? editProps.modalHeader(row)
                                    : editProps.modalHeader}
                            </h4>
                            <Autocomplete
                                id={`EditMultipleChips-Autocomplete`}
                                onChange={(event, value) => setEditChipModalSelected(value)}
                                multiple
                                options={options.filter(
                                    (item) =>
                                        !currentlySelected.map((item) => item.value).includes(item.value) &&
                                        !editChipModalSelected.map((item) => item.value).includes(item.value)
                                )}
                                getOptionLabel={(option) => option.text}
                                style={{ width: 500 }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
                            />
                            <br />
                            <br />
                            <Button
                                id={`EditMultipleChips-Add`}
                                variant="outlined"
                                disabled={!setEditChipModalSelected.length}
                                onClick={async () => {
                                    if (editProps.addItem instanceof Function) {
                                        let res = await editProps.addItem({
                                            values: editChipModalSelected.map((item) => item.value),
                                            row,
                                        });
                                        if (res === true) {
                                            setEditChipModalOpen(false);
                                        }
                                    }
                                }}
                            >
                                {editProps.addButtonText || 'Add'}
                            </Button>
                        </DialogContent>
                    </Dialog>

                    <div style={{ textAlign: 'center' }}>
                        {Array.isArray(displayValue) &&
                            currentlySelected.map((item, i) => (
                                <Chip
                                    id={`EditMultipleChips-RemoveItem_${rowID}_${i}`}
                                    size="small"
                                    key={item.id}
                                    label={item.text}
                                    color="primary"
                                    style={{ margin: '2px 1px' }}
                                    onDelete={async () => {
                                        if (editProps.removeItem instanceof Function) {
                                            await editProps.removeItem({ item, row });
                                        }
                                    }}
                                />
                            ))}
                    </div>
                    {editable && (
                        <Chip
                            id={`EditMultipleChips-AddItem_${rowID}`}
                            style={{ margin: '2px' }}
                            size="small"
                            clickable
                            color="secondary"
                            onClick={(event) => {
                                event.stopPropagation();
                                setEditChipModalOpen(true);
                            }}
                            label="+"
                        />
                    )}
                </div>
            );
        }

        if (editProps.type === 'select' || 'autocomplete') {
            let options = getSelectOptions(editProps.options);
            let match = options.find((opt) => !!opt.value && String(opt.value) === String(currentValue));
            if (match) {
                displayValue = match.text;
            }
        }
        cellStyle = cellStyle ?? {};
        return (
            <div style={cellStyle}>
                <div>
                    {cellStyle.renderArrayAsList && Array.isArray(currentValue) ? (
                        <ul style={{ margin: 0, paddingLeft: '16px' }}>
                            {currentValue.map((value, index) => (
                                <li key={`${value}-${index}`}>{value}</li>
                            ))}
                        </ul>
                    ) : (
                        displayValue
                    )}
                </div>
            </div>
        );
    };

    let onKeyDown = (event) => {
        if (editProps.inputType === 'date' && editProps.disableTextInput) {
            event.preventDefault();
            return false;
        }

        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    let renderEditingElement = (ref) => {
        let inputType = 'text';
        if (editProps.inputType !== 'number') {
            inputType = editProps.inputType;
        }
        if (editProps.type === 'input') {
            let inputProps = {};

            Object.keys(editProps).forEach((key) => {
                if (['min', 'max', 'step', 'required', 'maxLength'].includes(key)) {
                    inputProps[key] = editProps[key];
                }
            });
            return (
                <TextField
                    type={inputType}
                    ref={ref}
                    multiline={editProps.multiline === true ? true : false}
                    style={{ width: '100%' }}
                    autoFocus={true}
                    value={inputValue}
                    onChange={onChange}
                    inputProps={inputProps}
                    onFocus={(event) => {
                        if (inputType === 'date') {
                            if (event.target && event.target.showPicker instanceof Function) {
                                event.target.showPicker();
                                onChange(event);
                            }
                        }
                    }}
                    onKeyDown={(event) => onKeyDown(event)}
                />
            );
        }

        if (editProps && editProps.type === 'select') {
            let options = getSelectOptions(editProps.options);
            return (
                <Select
                    id={`${colHeader.replaceAll(' ', '')}_${rowID}_edit_dropdown`}
                    className="dt-select"
                    value={inputValue}
                    autoFocus={true}
                    ref={ref}
                    style={{ width: '100%' }}
                    onChange={onChange}
                    native
                >
                    <>
                        {!inputValue && (
                            <option disabled={true} value={inputValue}>
                                Please Select
                            </option>
                        )}
                        {options.map((item) => (
                            <option disabled={item?.disabled === true} key={item.value} value={item.value}>
                                {item.text}
                            </option>
                        ))}
                    </>
                </Select>
            );
        }

        if (editProps && editProps.type === 'autocomplete') {
            let options = getSelectOptions(editProps.options);
            return (
                <>
                    <Autocomplete
                        required
                        autoFocus={true}
                        multiple={false}
                        value={options?.find((opt) => opt.value === inputValue) || ''}
                        ref={ref}
                        style={{ width: '100%' }}
                        onChange={(event, value) => {
                            setInputValue(value?.value || '');
                        }}
                        options={options}
                        getOptionLabel={(option) => option.text || ''}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </>
            );
        }

        if (editProps && editProps.type === 'autocomplete-multiple') {
            let options = getSelectOptions(editProps.options);
            return (
                <>
                    <Autocomplete
                        required
                        multiple={true}
                        value={options?.filter((opt) => inputValue.includes(opt.value))}
                        onChange={(event, value) => {
                            setInputValue(value.map((item) => item.value));
                        }}
                        options={options.filter((option) => {
                            if (!Array.isArray(inputValue)) {
                                return true;
                            }
                            let valueSelected = false;
                            inputValue.forEach((val) => {
                                if (val === option.value || val === JSON.stringify(option.value)) {
                                    valueSelected = true;
                                }
                            });
                            return !valueSelected;
                        })}
                        getOptionLabel={(option) => option.text || ''}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </>
            );
        }
    };

    if (type === 'checkbox') {
        if (editable) {
            return (
                <Checkbox
                    id={`${colHeader.replaceAll(' ', '')}_${rowID}_checkbox`}
                    checked={inputValue}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={async () => {
                        if (customEdit && customEdit instanceof Function) {
                            await customEdit();
                            return;
                        }
                        setInputValue(!inputValue);
                        handleEdit({ type });
                    }}
                    color="primary"
                />
            );
        } else {
            return <Checkbox checked={inputValue} color="primary" />;
        }
    }

    if (!editable) {
        return (
            <Paper
                id={`${colHeader.replaceAll(' ', '')}_${rowID}_cell`}
                onClick={() => {
                    if (onCellClick && onCellClick instanceof Function) {
                        onCellClick();
                    }
                }}
                className={'dt-paper'}
                style={{ padding: '8px' }}
            >
                {renderNotEditingElement(cellStyle)}
            </Paper>
        );
    }

    return (
        <Paper
            onBlur={async (event) => {
                if (editClicked === true) {
                    event.preventDefault();
                    return;
                }
                await setEditClicked(false);
                setEditing(false);
                setInputValue(currentValue);
            }}
            className={'dt-paper'}
            onClick={async (e) => {
                e.stopPropagation();
                if (restrictEditArea === false) {
                    if (customEdit && customEdit instanceof Function) {
                        await customEdit();
                        return;
                    }
                    setEditing(true);
                }
            }}
        >
            <div style={{ flex: 0.9, padding: '10px' }}>
                <form>{editing ? renderEditingElement(inputEl) : renderNotEditingElement(cellStyle)}</form>
            </div>
            {editable && editProps.type !== 'autocomplete-multiple-chips' && (
                <div
                    className={classes.editIcon}
                    onMouseDown={(event) => {
                        setEditClicked(true);
                    }}
                    onClick={async (e) => {
                        e.stopPropagation();
                        if (editing && editProps.inputType === 'date') {
                            handleEdit({ type: 'date' });
                        } else if (editing) {
                            handleEdit({ type });
                        } else {
                            if (customEdit && customEdit instanceof Function) {
                                await customEdit();
                                return;
                            }
                            if (inputEl && inputEl.current) {
                                inputEl.current.focus();
                            }
                            setEditing(true);
                            setEditClicked(false);
                        }
                    }}
                >
                    {editing ? (
                        <DoneIcon id={`${colHeader.replaceAll(' ', '')}_${rowID}_done`} className={classes.editIcon} />
                    ) : (
                        <EditIcon id={`${colHeader.replaceAll(' ', '')}_${rowID}_edit`} className={classes.editIcon} />
                    )}
                </div>
            )}
        </Paper>
    );
};

const DefaultCell = ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
    let style = {};
    if (column.cellStyle && column.cellStyle instanceof Function) {
        style = column.cellStyle(row.original);
    }
    return (
        <EditableField
            id={row.original.id}
            field={column.id}
            saveEdit={saveEdit}
            initialValue={initialValue}
            customEdit={
                column.customEdit
                    ? () => {
                          column.customEdit(row.original);
                      }
                    : null
            }
            onCellClick={
                column.onCellClick
                    ? () => {
                          column.onCellClick(row.original);
                      }
                    : null
            }
            type={column.type}
            editProps={column.editProps || { type: 'input' }}
            editable={editable}
            row={row.original}
            cellStyle={style}
            colHeader={column.Header}
            rowID={row.id}
            label={column.label}
        />
    );
};

export { DefaultCell, EditableField };
