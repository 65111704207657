import React from 'react';
import { Button } from '@material-ui/core';
import { productionApi as api } from '../../../../api/production.js';
import { FormComponent } from '../../../../shared/index.js';
import { DataTable } from '../../../../shared/index.js';

const CarouselItems = ({
    parentRow,
    sellableBundles,
    sellableItems,
    addItemToCarousel,
    addBundleToCarousel,
    removeItemFromCarousel,
    removeBundleFromCarousel,
    setNotification,
    getData,
}) => {
    let itemsInCarouselIDs = parentRow.original.items.map((item) => item.sellableItemID);
    let bundlesInCarouselIDs = parentRow.original.items.map((item) => item.sellableBundleID);
    let formFields = [
        {
            name: 'sellableItemID',
            label: 'Sellable Item',
            inputElement: 'autocomplete',
            gridValue: 10,
            inputProps: {
                required: true,
                opts: sellableItems
                    .filter((item) => !itemsInCarouselIDs.includes(item.id) && !item.sampleItem && !item.drinksItemID)
                    .map((item) => ({ value: item.id, text: `${item.id}: ${item.name}` })),
            },
        },
    ];
    let sellableBundleFormFields = [
        {
            name: 'sellableBundleID',
            label: 'Sellable Bundle',
            inputElement: 'autocomplete',
            gridValue: 10,
            inputProps: {
                required: true,
                opts: sellableBundles
                    .filter((item) => !bundlesInCarouselIDs.includes(item.id) && item.price > 0)
                    .map((item) => ({ value: item.id, text: `${item.id}: ${item.name}` })),
            },
        },
    ];

    return (
        <div>
            {(parentRow.original.carouselType === 'NORMAL' || parentRow.original.carouselType === 'LIST') && (
                <>
                    <h3>Add Item</h3>
                    <FormComponent
                        formFields={formFields}
                        button={{ gridValue: 2, text: 'Add' }}
                        onSubmit={async (formData, resetForm) => {
                            let sellItemID = formData.sellableItemID;
                            let carouselID = parentRow.original.id;
                            if (!sellItemID) {
                                return;
                            }

                            let res = await addItemToCarousel(carouselID, sellItemID);
                            if (res) {
                                resetForm();
                            }

                            return res;
                        }}
                    />
                    <h3>Add Bundle</h3>
                    <FormComponent
                        formFields={sellableBundleFormFields}
                        button={{ gridValue: 2, text: 'Add' }}
                        onSubmit={async (formData, resetForm) => {
                            let sellBundleID = formData.sellableBundleID;
                            let carouselID = parentRow.original.id;
                            if (!sellBundleID) {
                                return;
                            }

                            let res = await addBundleToCarousel(carouselID, sellBundleID);
                            if (res) {
                                resetForm();
                            }

                            return res;
                        }}
                    />
                </>
            )}
            <DataTable
                columns={[
                    { Header: 'Sellable Item ID', accessor: 'sellableItemID' },
                    { Header: 'Sellable Bundle ID', accessor: 'sellableBundleID' },
                    { Header: 'Sellable Item Name', accessor: 'name' },
                    {
                        Header: 'Rank',
                        accessor: 'rank',
                        editable: true,
                        editProps: { type: 'input', inputType: 'number', integer: true },
                    },
                    {
                        Header: 'Remove Item',
                        Cell: ({ row }) => {
                            return (
                                <Button
                                    onClick={async () => {
                                        let carouselID = parentRow.original.id;
                                        let sellItemID = row.original.sellableItemID;
                                        let sellBundleID = row.original.sellableBundleID;

                                        if (sellItemID !== null) {
                                            var res = await removeItemFromCarousel(carouselID, sellItemID);
                                        } else if (sellBundleID !== null) {
                                            var res = await removeBundleFromCarousel(carouselID, sellBundleID);
                                        }

                                        return res;
                                    }}
                                >
                                    Remove
                                </Button>
                            );
                        },
                    },
                    {
                        Header: 'Sponsored',
                        accessor: 'sponsored',
                        editable: true,
                        type: 'checkbox',
                        editProps: { type: 'checkbox' },
                    },
                    { Header: 'Brand', accessor: 'brand', editable: false },
                ]}
                data={parentRow.original.items}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    if (!['rank'].includes(field)) {
                        return false;
                    }

                    let res = await api.updateSellableItemCarousel({ id, [field]: value });
                    if (res.status !== true) {
                        let msg = `Error updating item ${res.msg ? `. ${res.msg}` : ''}`;
                        setNotification({ text: msg, level: 'error' });
                    }
                    getData();
                    return res.status;
                }}
            />
        </div>
    );
};

export { CarouselItems };
