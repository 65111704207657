import React, { useState, useEffect } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Button, Modal } from '@material-ui/core';
import { logisticsApi as api } from '../../../api/logistics';
import { Grid, Select, MenuItem } from '@material-ui/core';
import moment from 'moment';

let ChargeDayChanges = ({ data }) => {
    let [rows, setRows] = useState(data || []);
    let [notification, setNotification] = useState({ text: null, level: null });

    let columns = [
        { accessor: 'id', Header: 'Sub ID' },
        { accessor: 'customerID', Header: 'Customer ID' },
        { accessor: 'email', Header: 'Email' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'zip', Header: 'Zip Code' },
        { accessor: 'state', Header: 'State' },
        { accessor: 'currChargeDay', Header: 'Old Charge Day' },
        { accessor: 'propChargeDay', Header: 'New Charge Day' },
        { accessor: 'newLH', Header: 'New Line Haul' },
    ];

    return (
        <div>
            <h1>Charge Day Changes</h1>
            <Notifications options={notification} />
            <div>
                <DataTable
                    toolbarActions={[
                        {
                            name: 'Download',
                            action: () => {
                                let csvContent =
                                    'data:text/csv;charset=utf-8,Subscription ID,Customer ID,Email,Status,Zip Code,';
                                csvContent += 'State,Old Charge Day,New Charge Day,New Line Haul\r\n';
                                for (let row of rows) {
                                    csvContent += `${row.id},${row.customerID},${row.email},${row.status},${row.zip},`;
                                    csvContent += `${row.state},${row.currChargeDay},${row.propChargeDay},${row.newLH}\r\n`;
                                }
                                var encodedUri = encodeURI(csvContent);
                                var link = document.createElement('a');
                                link.setAttribute('href', encodedUri);
                                link.setAttribute('download', 'charge_day_changes.csv');
                                document.body.appendChild(link);
                                link.click();
                            },
                        },
                    ]}
                    columns={columns}
                    data={rows}
                    editable={false}
                />
            </div>
        </div>
    );
};

export { ChargeDayChanges };
