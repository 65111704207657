import React, { useState, useEffect } from 'react';
import { logisticsApi as api } from '../../../api/logistics';
import { Notifications, FormComponent, DataTable } from '../../../shared';
import { Button, Grid, TextField } from '@material-ui/core';

let ReassignPurchasedShipments = () => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const [lineHauls, setLineHauls] = useState([]);
    const [fromLineHaul, setFromLineHaul] = useState(0);
    const [toLineHaul, setToLineHaul] = useState(0);
    const [readOnly, setReadOnly] = useState(true);
    const [confirmData, setConfirmData] = useState({});

    const getLineHauls = async () => {
        let data = await api.getLineHauls('purchased');
        if (data.status === true) {
            let orderedLineHauls = [];
            let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            days.forEach((day) => {
                let lineHaulsOnDay = data.data.lineHauls.filter((item) => item.arrival_day === day);
                orderedLineHauls = orderedLineHauls.concat(lineHaulsOnDay);
            });
            setLineHauls(orderedLineHauls);
        }
    };

    useEffect(() => {
        getLineHauls();
    }, []);

    let lineHaulColumns = [
        { accessor: 'id', Header: 'id' },
        { accessor: 'carrier', Header: 'Carrier' },
        { accessor: 'hub', Header: 'Hub' },
        { accessor: 'arrival_day', Header: 'Pack Day' },
        { accessor: 'shipment_count', Header: 'Shipments Assigned' },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item xs={12}>
                <h3>Move Purchased Shipments</h3>
            </Grid>
            <Grid item xs={12} style={{ margin: '20px' }}>
                <FormComponent
                    formFields={[
                        {
                            name: 'fromID',
                            inputElement: 'textField',
                            label: 'From Line Haul',
                            gridValue: 1,
                            inputProps: { required: true, type: 'number' },
                        },
                        {
                            name: 'toID',
                            inputElement: 'textField',
                            label: 'To Line Haul',
                            gridValue: 1,
                            inputProps: { required: true, type: 'number' },
                        },
                        {
                            name: 'qty',
                            inputElement: 'textField',
                            label: 'Quantity',
                            gridValue: 1,
                            inputProps: { required: true, type: 'number' },
                        },
                        {
                            name: 'mystery',
                            inputElement: 'checkbox',
                            label: 'Mystery',
                            gridValue: 1,
                            initialValue: true,
                        },
                        { name: 'custom', inputElement: 'checkbox', label: 'Custom', gridValue: 1, initialValue: true },
                        { name: 'restrictZips', inputElement: 'checkbox', label: 'Restrict Zips', gridValue: 1 },
                        {
                            name: 'restrictZipsFile',
                            inputElement: 'file',
                            label: 'Restrict Zips File',
                            accept: '.csv',
                            gridValue: 3,
                            display: (formData) => formData.restrictZips === true,
                        },
                    ]}
                    button={{
                        disabled: (formData) => {
                            if (!formData.fromID || !formData.toID || !formData.qty) {
                                return true;
                            }
                            if (formData.restrictZips === true && !formData.restrictZipsFile) {
                                return true;
                            }
                            return false;
                        },
                    }}
                    onSubmit={async (formData, resetForm) => {
                        let res = await api.reassignPurchasedShipments({
                            fromID: formData.fromID,
                            toID: formData.toID,
                            quantity: formData.qty,
                            includeMystery: formData.mystery,
                            includeCustom: formData.custom,
                            restrictZips: formData.restrictZips,
                            restrictZipsFile: formData.restrictZipsFile,
                            readOnly: true,
                        });
                        if (res.status === true) {
                            setNotification({
                                level: 'success',
                                text: `Can move ${res.data.count} purchased shipments
                                from line haul ${formData.fromID} to line haul ${formData.toID}`,
                            });
                            formData.quantity = res.data.count;
                            setConfirmData(formData);
                        } else {
                            setNotification({ level: 'error', text: `Unable to move purchased shipments: ${res.msg}` });
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{ margin: '20px' }}>
                <TextField
                    id="FromLineHaulID_textField"
                    style={{ padding: '10px' }}
                    label="From Line Haul ID"
                    value={confirmData.fromID}
                    inputProps={{ type: 'number', readOnly: true }}
                />
                <TextField
                    id="ToLineHaulID_textField"
                    style={{ padding: '10px' }}
                    label="To Line Haul ID"
                    value={confirmData.toID}
                    inputProps={{ type: 'number', readOnly: true }}
                />
                <TextField
                    id="NumMoving_textField"
                    style={{ padding: '10px' }}
                    label="Num Moving"
                    value={confirmData.quantity}
                    inputProps={{ type: 'number', readOnly: true }}
                />
                <Button
                    id="Confirm"
                    variant="outlined"
                    onClick={async () => {
                        let res = await api.reassignPurchasedShipments({
                            fromID: confirmData.fromID,
                            toID: confirmData.toID,
                            quantity: confirmData.quantity,
                            includeMystery: confirmData.mystery,
                            includeCustom: confirmData.custom,
                            restrictZips: confirmData.restrictZips,
                            restrictZipsFile: confirmData.restrictZipsFile,
                            readOnly: false,
                        });
                        if (res.status === true) {
                            setNotification({
                                level: 'success',
                                text: `Moving ${res.data.count} purchased shipments
                                from line haul ${confirmData.fromID} to line haul ${confirmData.toID}`,
                            });
                            setConfirmData({});
                        } else {
                            setNotification({ level: 'error', text: `Unable to move purchased shipments: ${res.msg}` });
                        }
                    }}
                >
                    CONFIRM
                </Button>
            </Grid>
            <Grid item xs={12}>
                <DataTable columns={lineHaulColumns} data={lineHauls} />
            </Grid>
        </Grid>
    );
};

export { ReassignPurchasedShipments };
