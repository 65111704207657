import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewFBMItems } from './viewFBMItems.js';
import { ViewFBMPackaging } from './viewFBMPackaging.js';
import { TabPanel, FBMAccountPicker, Notifications } from '../../../shared';
import CreateFBMItem from './CreateFBMItem.js';
import CreateFBMPackaging from './CreateFBMPackaging.js';
import { catalogApi } from '../../../api/catalog.js';
import { UserContext } from '../../../contexts/UserContext';
import { fbmApi as api } from '../../../api/fbm';

const FBMCatalog = () => {
    let [packagingTypesOpts, setPackagingTypesOpts] = useState([]);
    let [selectedTab, setSelectedTab] = useState('0');
    let [tempZones, setTempZones] = useState({ shipping: [], storage: [], receiving: [] });
    let [notification, setNotification] = useState({ text: null, level: null });
    const { roles, fbmAccount } = useContext(UserContext);
    let canEditFBMItems = roles?.filter((role) => role.name.endsWith('CREATE_EDIT_FBM_ITEMS')).length >= 1;

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    let getPackagingTypes = async () => {
        let response = await api.getPackagingTypes(fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No packaging types found ' + response.msg, level: 'error' });
            setPackagingTypesOpts([]);
            return;
        }

        let packagingTypesOpts = response.data.rows.map((row) => {
            return {
                text: row.display_name,
                value: row.system_name,
            };
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setPackagingTypesOpts([pleaseSelect].concat(packagingTypesOpts));
    };

    const getTempZones = async () => {
        let res = await catalogApi.getItemTempZones();
        if (!res.status) {
            setNotification({ text: 'Failed to get item temp zones', level: 'error' });
            return;
        }

        setTempZones(res.data);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getPackagingTypes();
        }
        getTempZones();
    }, [fbmAccount]);

    return (
        <Paper>
            <Notifications options={notification} />
            <FBMAccountPicker />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="View Items" value="0" />
                {canEditFBMItems && <Tab label="Create Items" value="1" />}
                <Tab label="View Packaging" value="2" />
                {canEditFBMItems && <Tab label="Create Packaging" value="3" />}
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewFBMItems canEditFBMItems={canEditFBMItems} tempZones={tempZones} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateFBMItem tempZones={tempZones} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <ViewFBMPackaging canEditFBMItems={canEditFBMItems} typeOpts={packagingTypesOpts} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'3'}>
                <CreateFBMPackaging typeOpts={packagingTypesOpts} />
            </TabPanel>
        </Paper>
    );
};

export { FBMCatalog };
