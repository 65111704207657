import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';

const PhotoModal = ({ open, selectedPhoto, setPhotoModalOpen, updateItemPhoto }) => {
    let [file, setFile] = useState(null);
    let [source, setSource] = useState(null);
    let [title, setTitle] = useState('');
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedPhoto.key === 'photoUrlSmall') {
            setTitle(`Edit Small Image (Max 300 KB) for ${selectedPhoto.item.name}`);
        } else if (selectedPhoto.key === 'photoUrlLarge') {
            setTitle(`Edit Large Image (Max 300 KB) for ${selectedPhoto.item.name}`);
        } else if (selectedPhoto.key === 'photoUrlMedium') {
            setTitle(`Edit Medium Image (Max 300 KB) for ${selectedPhoto.item.name}`);
        } else if (selectedPhoto.key === 'photoUrlExtraSmall') {
            setTitle(`Edit Extra Small Image (Max 300 KB) for ${selectedPhoto.item.name}`);
        }
        setSource(null);
        setFile(null);
    }, [selectedPhoto]);

    if (!selectedPhoto || !selectedPhoto.item) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                setPhotoModalOpen(false);
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {selectedPhoto.item[selectedPhoto.key] && !source && (
                    <img src={selectedPhoto.item[selectedPhoto.key]} alt="Item" />
                )}
                {source && (
                    <div style={{ textAlign: 'center' }}>
                        <img src={source} alt="Preview" />
                    </div>
                )}
                <Button style={{ textAlign: 'center' }} variant="outlined" component="label">
                    Choose File
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        name="Photo Upload"
                        id="photo-file-input"
                        accept="image/png, image/jpeg, gif"
                        onChange={(event) => {
                            let file = event.target.files[0];
                            setFile(file);

                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = (e) => {
                                setSource(e.target.result);
                            };
                        }}
                    />
                </Button>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={file === null || loading === true}
                    onClick={async () => {
                        setLoading(true);
                        let res = await updateItemPhoto(selectedPhoto.item.id, selectedPhoto.key, file);
                        setLoading(false);
                        if (res === true) {
                            setPhotoModalOpen(false);
                        }
                    }}
                >
                    Confirm
                </Button>
                <Button disabled={loading} onClick={() => setPhotoModalOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { PhotoModal };
