import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const PurchaseOrderGuardrail = ({ open, setOpen, onSubmit, message }) => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth="sm"
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle>{message}</DialogTitle>
            <DialogActions>
                <Button
                    onClick={() => {
                        onSubmit();
                        setOpen(false);
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PurchaseOrderGuardrail;
