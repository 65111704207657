import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let systemApi = {
    dbiScanStrDeepDive: async (scanStr) => {
        let url = `api/system/v1/orderDeepDive?dbiScanStr=` + scanStr;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCCPARequests: async () => {
        let url = `api/ccpa/v1/requests`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getMarketplaceInventory: async (packGroupID) => {
        let url = `api/system/v1/marketplaceInventory?packGroupID=` + packGroupID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getMarketplaceDrinksInventory: async () => {
        let url = `api/system/v1/marketplaceInventory/drinks`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    orderDeepDive: async (orderNum) => {
        let url = `api/system/v1/orderDeepDive?orderNum=` + orderNum;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLiveInventoryForPackGroup: async (packGroupID) => {
        let url = `api/inventory/v1/ims?packGroupID=${packGroupID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLiveInventoryDetailsForSellableItem: async (sellableItemID) => {
        let url = `api/inventory/v1/ims/item?id=${sellableItemID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    imperfectDeepDive: async (imperfectID) => {
        let url = `api/system/v1/orderDeepDive?imperfectID=` + imperfectID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    shipmentDeepDive: async (shipmentID) => {
        let url = `api/system/v1/orderDeepDive?shipmentID=` + shipmentID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
};

export { systemApi };
