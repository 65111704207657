import React, { useEffect, useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import './tabs.css';

const PartnershipTabs = ({ tabs = [] }) => {
    const [selected, setSelected] = useState(0);

    return (
        <div className="partnership-tabs-container">
            <div className="partnership-tabs-tabs" style={{ gridTemplateColumns: `repeat(${tabs.length}, 1fr)` }}>
                {tabs.map((item, index) => {
                    return (
                        <div key={item.name}>
                            <div
                                className={`partnership-tabs-tab grotesk-bold ${
                                    selected === index && 'partnership-tabs-selected'
                                }`}
                            >
                                <span
                                    style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
                                    onClick={() => {
                                        setSelected(index);
                                    }}
                                >
                                    {item.warning && <WarningIcon className="lists-warning-icon" />}
                                    &nbsp;{item.name}&nbsp;
                                    {item.warning && <WarningIcon className="lists-warning-icon" />}
                                </span>
                            </div>
                            {selected === index && <div className="partnership-tabs-selected-underline"></div>}
                        </div>
                    );
                })}
            </div>
            <div className="partnership-tabs-content">
                {tabs.length > 0 && !!tabs[selected].component && (
                    <div>{React.cloneElement(tabs[selected].component)}</div>
                )}
            </div>
        </div>
    );
};

export { PartnershipTabs };
