import React, { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { FormComponent } from '../../../shared';
import { customerApi as api } from '../../../api/customer';
import { DrinksRefundItemDetails } from './DrinksRefundItemDetails';
import { MultishipRefunds } from './MultishipRefunds';
import { ContactSupportOutlined } from '@material-ui/icons';

const DrinksRefundModal = ({
    customerID,
    getSubscription,
    getDrinksOrders,
    setNotification,
    drinkOrderID,
    rowInfo,
    open,
    setOpen,
}) => {
    const [drinksOrder, setDrinksOrder] = useState(null);
    const [orderTotal, setOrderTotal] = useState(0);
    const [refundHistoryData, setRefundHistoryData] = useState(null);
    const [refundableItems, setRefundableItems] = useState([]);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [initialRemainingAmount, setInitialRemainingAmount] = useState(0);
    const [itemsSelected, setItemsSelected] = useState(null);
    const [itemsSelectedTotal, setItemsSelectedTotal] = useState(0);
    const [refundReason, setRefundReason] = useState('');
    const [fullRefundChecked, setFullRefundChecked] = useState(false);
    const [otherReasonForm, setOtherReasonForm] = useState('');

    const getDrinksOrderDetails = async () => {
        let res = await api.getDrinksOrderDetails(drinkOrderID);
        if (!res) {
            console.log('error getting drinks order details');
            return;
        }

        setInitialRemainingAmount(rowInfo?.amountRemaining);
        setRemainingAmount(rowInfo?.amountRemaining);

        let orderInfo = res.data.orderInfo;
        setDrinksOrder(orderInfo);

        let chargeDetails = res.data.orderInfo.charge;
        let sum = chargeDetails.total;
        setOrderTotal(sum);

        let refundHistory = res.data.orderInfo.refundHistory;
        setRefundHistoryData(refundHistory);

        if (refundHistory.totalRefundedAmount > 0) {
            setItemsSelectedTotal(refundHistory.totalRefundedAmount);
        } else {
            setItemsSelectedTotal(0);
        }
    };

    const getRefundableItems = async () => {
        let res = await api.getDrinksRefundableItems(rowInfo.drinksOrderID);
        if (!res) {
            console.log('error getting drinks refundable items');
            return;
        }

        let data = res.data.refundableItems;
        setRefundableItems(data);
    };

    let getItemsSelectedTotal = (arr) => {
        if (arr !== null && arr.length > 0) {
            let total = arr.map((item) => item.price * item.qty + item.tax + item.alcoholFee);
            let amount = total.reduce((acc, currVal) => {
                return acc + currVal;
            });

            setItemsSelectedTotal(amount);
        } else {
            setItemsSelectedTotal(0);
        }
    };

    let handleRefundReasonChange = (event, value) => {
        setRefundReason(value);
        clearSummary();
    };

    let handleFullRefundChange = (event, value) => {
        if (event.target.checked === true) {
            getFullRefundDetails();
            setFullRefundChecked(true);
        } else {
            setFullRefundChecked(false);
            clearSummary();
        }
    };

    let getFullRefundDetails = () => {
        let total = refundHistoryData.totalAvailableToRefund;
        setItemsSelectedTotal(total);
    };

    let clearSummary = () => {
        setItemsSelected(null);
        setItemsSelectedTotal(0);
        setRemainingAmount(initialRemainingAmount);
    };

    useEffect(() => {
        if (open) {
            getDrinksOrderDetails();
            getRefundableItems();
        }
    }, [open]);

    useEffect(() => {
        getItemsSelectedTotal(itemsSelected);
    }, [itemsSelected]);

    if (!drinksOrder) {
        return null;
    }

    const productReasons = [
        { value: 'PRODUCT_MISSING', text: 'Missing Product' },
        { value: 'SPOILED_OR_DAMAGED', text: 'Spoiled or Damaged' },
        { value: 'PRODUCT_FEEDBACK', text: 'Product Feedback' },
        { value: 'PRODUCT_FOREIGN_OBJECT_IN_BOX', text: 'Foreign object in box' },
    ];

    const productReasonsSecondaryMissing = [
        { value: 'PRODUCT_DID_NOT_RECEIVE', text: 'Did not receive' },
        { value: 'PRODUCT_MISSING_QUANTITY', text: 'Missing quantity' },
        { value: 'PRODUCT_INCORRECT_SENT', text: 'Incorrect product sent' },
    ];

    const productReasonsSecondarySpoiledDamaged = [
        { value: 'PRODUCT_ROTTEN_SPOILED', text: 'Rotten or Spoiled Item(s)' },
        { value: 'PRODUCT_BROKEN_DAMAGED', text: 'Broken/Damaged Item(s)' },
        { value: 'PRODUCT_EXPIRED', text: 'Expired product' },
        { value: 'PRODUCT_BUGS_PESTS', text: 'Bugs/Pests' },
    ];

    const productReasonsSecondaryProductFeedback = [
        { value: 'PRODUCT_UNHAPPY_WITH_PURCHASE', text: 'Unhappy with purchased product' },
        { value: 'PRODUCT_POOR_TASTE_QUALITY', text: 'Poor taste/quality' },
        { value: 'PRODUCT_NOT_AS_DESCRIBED', text: 'Product not as described' },
        { value: 'UNHAPPY_WITH_PORTION_SIZE', text: 'Unhappy with portion/size' },
    ];

    const deliveryReasons = [
        { value: 'DELIVERY_LATE', text: 'Arrived Late' },
        { value: 'DELIVERY_MISSING', text: 'Missing Delivery' },
        { value: 'DELIVERY_DAMAGED_DELIVERED', text: 'Damaged Order' },
        { value: 'DELIVERY_WRONG_ORDER', text: 'Wrong order received' },
    ];

    const deliveryReasonsSecondaryMissingDelivery = [
        { value: 'DELIVERY_LABEL_NO_MOVEMENT', text: 'Warehouse did not ship, no label' },
        { value: 'DELIVERY_DID_NOT_ARRIVE', text: 'Did not arrive' },
        { value: 'DELIVERY_DAMAGED_IN_TRANSIT_DISCARDED_BY_CARRIER', text: 'Damaged in transit, discarded by carrier' },
    ];

    const deliveryReasonsSecondaryDamagedOrder = [
        { value: 'DELIVERY_DAMAGED_ARRIVED_DAMAGED_OR_OPEN', text: 'Box arrived damaged or open' },
        { value: 'DELIVERY_DAMAGED_ICE_PACK_BURST', text: 'Ice pack burst' },
        { value: 'DELIVERY_DAMAGED_FROZEN_BOX', text: 'Frozen Box' },
        { value: 'DELIVERY_DAMAGED_PRODUCT_SPILL', text: 'Product spill' },
        { value: 'DELIVERY_DAMAGED_INSULATION_PACKAGING', text: 'Insulation/packaging problem' },
    ];

    const otherReasons = [
        { value: 'SOFTWARE_ISSUE', text: 'Technical Issue/System Error' },
        { value: 'OTHER', text: 'Other' },
    ];

    const getSecondaryReasonsForOption = (formData) => {
        if (formData.deliveryReason === 'DELIVERY_MISSING') {
            return deliveryReasonsSecondaryMissingDelivery;
        } else if (formData.deliveryReason === 'DELIVERY_DAMAGED_DELIVERED') {
            return deliveryReasonsSecondaryDamagedOrder;
        } else if (formData.productReason === 'PRODUCT_MISSING') {
            return productReasonsSecondaryMissing;
        } else if (formData.productReason === 'SPOILED_OR_DAMAGED') {
            return productReasonsSecondarySpoiledDamaged;
        } else if (formData.productReason === 'PRODUCT_FEEDBACK') {
            return productReasonsSecondaryProductFeedback;
        }

        return [];
    };

    const getRequired = () => {
        let reasonsRequiringSecondary = [
            'DELIVERY_MISSING',
            'DELIVERY_DAMAGED_DELIVERED',
            'PRODUCT_MISSING',
            'SPOILED_OR_DAMAGED',
            'PRODUCT_FEEDBACK',
        ];

        if (reasonsRequiringSecondary.includes(refundReason)) {
            return true;
        }

        return false;
    };

    let formFields = [
        {
            name: 'deliveryReason',
            label: 'Delivery Related',
            inputElement: 'select',
            gridValue: 11,
            inputProps: {
                opts: deliveryReasons.sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handleRefundReasonChange,
                disableUnderline: true,
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
            dependencies: ['productReason', 'otherReason'],
        },
        {
            name: 'deliveryReasonSecondary',
            label: 'Secondary Reason (Required)',
            inputElement: 'select',
            gridValue: 11,
            dependencies: ['deliveryReason'],
            inputProps: {
                opts: (formData) => getSecondaryReasonsForOption(formData).sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handleRefundReasonChange,
                disableUnderline: true,
                required: getRequired(),
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
            display: (formData) => ['DELIVERY_MISSING', 'DELIVERY_DAMAGED_DELIVERED'].includes(formData.deliveryReason),
        },
        {
            name: 'productReason',
            label: 'Product Related',
            inputElement: 'select',
            gridValue: 11,
            dependencies: ['deliveryReason', 'otherReason'],
            inputProps: {
                opts: productReasons.sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handleRefundReasonChange,
                disableUnderline: true,
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
        },
        {
            name: 'productReasonSecondary',
            label: 'Secondary Reason (Required)',
            inputElement: 'select',
            gridValue: 11,
            dependencies: ['productReason'],
            inputProps: {
                opts: (formData) => getSecondaryReasonsForOption(formData).sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handleRefundReasonChange,
                disableUnderline: true,
                required: getRequired(),
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
            display: (formData) =>
                ['PRODUCT_MISSING', 'SPOILED_OR_DAMAGED', 'PRODUCT_FEEDBACK'].includes(formData.productReason),
        },
        {
            name: 'otherReason',
            label: 'Other',
            inputElement: 'select',
            gridValue: 11,
            inputProps: {
                opts: otherReasons.sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handleRefundReasonChange,
                disableUnderline: true,
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
            dependencies: ['deliveryReason', 'productReason'],
        },
        {
            name: 'otherReasonSecondary',
            initialValue: '',
            gridValue: 11,
            dependencies: ['otherReason'],
            inputProps: {
                multiline: true,
                disableUnderline: true,
            },
            InputProps: { disableUnderline: true },
            display: (formData) => (formData.otherReason === 'OTHER' ? true : null),
            inputElement: ({ formData, setFormField }) => {
                if (formData.otherReason === '') {
                    return null;
                }

                return (
                    <div>
                        <h2>Other Reason</h2>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            inputProps={{ maxLength: 240 }}
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="outlined"
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                setOtherReasonForm(e.target.value);
                                setFormField(e.target.value);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            name: 'fullRefund',
            initialValue: false,
            label: 'Full Refund',
            inputElement: 'checkbox',
            gridValue: 4,
            dependencies: ['deliveryReason', 'productReason', 'otherReason', 'refundShipping', 'refundByShipments'],
            inputProps: {
                style: { paddingTop: '20px' },
                onChange: handleFullRefundChange,
            },
            display: (formData) => {
                if (formData.deliveryReason === '' && formData.otherReason === '') {
                    return null;
                } else {
                    return true;
                }
            },
        },
        {
            name: 'itemsAffected',
            gridValue: 11,
            dependencies: [
                'deliveryReason',
                'deliveryReasonSecondary',
                'productReason',
                'productReasonSecondary',
                'otherReason',
                'otherReasonSecondary',
                'fullRefund',
            ],
            initialValue: [],
            display: (formData) => formData.productReason !== '',
            inputElement: ({ formData, setFormField }) => {
                if (
                    formData.fullRefund.toString() === 'true' ||
                    (formData.productReason === '' && formData.deliveryReason !== 'DELIVERY_DAMAGED_DELIVERED')
                ) {
                    setFormField([]);
                    return null;
                }

                return (
                    <DrinksRefundItemDetails
                        refundableItems={refundableItems}
                        formData={formData}
                        setFormField={setFormField}
                        itemsSelected={itemsSelected}
                        setItemsSelected={setItemsSelected}
                    />
                );
            },
        },
    ];

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogContent style={{ display: 'flex' }}>
                <Grid item xs={8}>
                    <h2>Select Refund Reason</h2>
                    <div style={{ paddingTop: '0px' }}>
                        <FormComponent
                            inputElementStyle={{ padding: '0px' }}
                            formControlStyle={{ paddingBottom: '10px' }}
                            formFields={formFields}
                            button={{
                                style: { bottom: '8px', right: '100px', position: 'absolute', padding: '6px 8px' },
                                disabled: (formData) => {
                                    if (
                                        formData.deliveryReason === '' &&
                                        formData.productReason === '' &&
                                        formData.otherReason === ''
                                    ) {
                                        return true;
                                    }

                                    return false;
                                },
                            }}
                            onSubmit={async (formData, resetForm) => {
                                let sendParams = { reason: refundReason };
                                let res;

                                sendParams.drinksItems = formData.itemsAffected;

                                if (sendParams.reason === '') {
                                    setNotification({ text: 'Must select a reason', level: 'error' });
                                    return false;
                                }

                                // Optional message from Other Reason form field, if provided by agent
                                if (sendParams.reason === 'OTHER') {
                                    let otherSecondary = formData.otherReasonSecondary;
                                    if (otherSecondary === '') {
                                        setNotification({
                                            text: 'Must provide reason for issue Other: ',
                                            level: 'error',
                                        });
                                        return false;
                                    }

                                    sendParams.otherReason = otherSecondary;
                                    sendParams.drinksItems = [];
                                }

                                console.log('sendParams', sendParams);

                                // Check if full refund
                                if (formData.fullRefund.toString() === 'true') {
                                    delete sendParams.drinksItems;
                                    res = await api.fullRefundDrinksOrder(drinksOrder.drinksOrderID, sendParams);
                                } else {
                                    if (sendParams.drinksItems.length < 1) {
                                        setNotification({ text: 'No items selected', level: 'error' });
                                        return false;
                                    }

                                    res = await api.partialRefundDrinksOrder(drinksOrder.drinksOrderID, sendParams);
                                }

                                if (!res.status) {
                                    setNotification({ text: 'Unable to refund charge: ' + res.msg, level: 'error' });
                                    return false;
                                }

                                setNotification({ text: 'Success!', level: 'success' });
                                getSubscription();
                                getDrinksOrders();
                                getRefundableItems();
                                clearSummary();
                                resetForm();
                                setOpen(false);
                                return true;
                            }}
                        />
                    </div>
                    {refundHistoryData?.itemsWithRefundedAmounts.length > 0
                        ? refundHistoryData.itemsWithRefundedAmounts.map((item) => {
                              return (
                                  <Grid item xs={11}>
                                      <div style={{ display: 'flex', alignItems: 'baseline', padding: '5px 0' }}>
                                          <span style={{ background: '#F7F7F7', borderRadius: '8px', padding: '5px' }}>
                                              Refunded
                                          </span>
                                          &nbsp;&nbsp;
                                          <div key={item.drinksOrderDetailsModel.sellableItemID}>
                                              {item.name} ${item.drinksOrderDetailsModel.dollarAmountRefunded} - x
                                              {item.drinksOrderDetailsModel.quantityRefunded}
                                              &nbsp;(Tax: $
                                              {(Number(item.drinksOrderDetailsModel.taxesRefunded) || 0).toFixed(2)})
                                              &nbsp;(Alcohol Fee: $
                                              {(Number(item.drinksOrderDetailsModel.alcoholFeeRefunded) || 0).toFixed(
                                                  2
                                              )}
                                              )
                                          </div>
                                      </div>
                                  </Grid>
                              );
                          })
                        : null}
                </Grid>
                <Grid item xs={4}>
                    <h2>Refund Drinks Order</h2>
                    <Grid item xs={12} style={{ padding: '0 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Order Number</strong>
                            <span>{rowInfo.mmOrderID}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Drinks Order Number</strong>
                            <span>{rowInfo.drinksOrderID}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 0 10px', borderBottom: '2px solid lightgray' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Charge ID</strong>
                            <span>{drinksOrder?.charge?.chargeID}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '10px 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Subtotal</strong>
                            <span>${Number(drinksOrder?.charge?.subtotal).toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '10px 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Taxes</strong>
                            <span>${Number(drinksOrder?.charge?.taxes).toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '10px 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Alcohol Fees</strong>
                            <span>${Number(drinksOrder?.charge?.alcoholFees).toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 0 10px', borderBottom: '2px solid lightgray' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Order Total</strong>
                            <span>${orderTotal.toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '10px 0 10px', borderBottom: '2px solid lightgray' }}></Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ background: '#F7F7F7', borderRadius: '8px', marginTop: '15px', padding: '20px' }}
                    >
                        <h4 style={{ margin: '0 0 15px' }}>Refund Summary</h4>
                        {itemsSelected !== null &&
                            itemsSelected.length > 0 &&
                            itemsSelected.map((item) => {
                                return (
                                    <p key={item.sellableItemID}>
                                        {item.name} x{item.qty}
                                    </p>
                                );
                            })}
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
                                <strong>Refunded Items</strong>
                                <span>${(Number(itemsSelectedTotal) || 0).toFixed(2)}</span>
                            </div>
                        </Grid>
                    </Grid>

                    {initialRemainingAmount === 0 && (
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '15px',
                                    paddingTop: '10px',
                                    borderTop: '2px solid lightgray',
                                }}
                            >
                                <strong>Remaining Amount</strong>
                                <span>$0.00</span>
                            </div>
                        </Grid>
                    )}

                    {(itemsSelected !== null &&
                        itemsSelected.length > 0 &&
                        itemsSelectedTotal > 0 &&
                        initialRemainingAmount !== 0) ||
                    fullRefundChecked ? (
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '15px',
                                    paddingTop: '10px',
                                    borderTop: '2px solid lightgray',
                                }}
                            >
                                <strong>Remaining Amount</strong>
                                <span>${(remainingAmount - itemsSelectedTotal).toFixed(2)}</span>
                            </div>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '15px',
                                    paddingTop: '10px',
                                    borderTop: '2px solid lightgray',
                                }}
                            >
                                <strong>Remaining Amount</strong>
                                <span>${remainingAmount.toFixed(2)}</span>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    style={{ border: '1px solid lightgray' }}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { DrinksRefundModal };
