import React, { useContext, useEffect, useState } from 'react';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { shippingApi as api } from '../../../api/shipping';
import { logisticsApi } from '../../../api/logistics';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';

const PurchaseCarrierShipLabels = () => {
    const { user } = useContext(UserContext);
    const [carriers, setCarriers] = useState([]);
    const today = moment();

    useEffect(() => {
        const getCarriers = async () => {
            const response = await logisticsApi.getLineHaulCarriers();
            if (!response.status) {
                return;
            }

            // remove DBI from results
            const carriersWithoutDBI = response.data.rows.filter((item) => item !== 'dbi');
            setCarriers(carriersWithoutDBI);
        };

        getCarriers();
    }, []);

    const additionalFormFields = [
        {
            name: 'carrier',
            label: 'Carrier',
            inputElement: 'select',
            gridValue: 6,
            initialValue: 'fedex',
            inputProps: { required: true, opts: carriers },
        },
        {
            name: 'shipDate',
            label: 'Ship Date',
            inputElement: 'date',
            gridValue: 6,
            initialValue: today.format('YYYY-MM-DD'),
            inputProps: { required: true, min: today },
        },
    ];

    const columns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'First Name', accessor: 'firstName' },
        { Header: 'Last Name', accessor: 'lastName' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Line One', accessor: 'lineOne' },
        { Header: 'Line Two', accessor: 'lineTwo' },
        { Header: 'City', accessor: 'city' },
        { Header: 'State', accessor: 'state' },
        { Header: 'Zip', accessor: 'zip' },
        { Header: 'Phone', accessor: 'phone' },
        { Header: 'Delivery Instructions', accessor: 'deliveryInstructions' },
        { Header: 'Errors', accessor: 'errors' },
    ];

    const confirmUpload = async (key) => {
        const res = await api.confirmAddressesForCarrierShipLabels(key);
        return res;
    };

    const uploadOrders = async (formData) => {
        formData.facilityID = user.facilityID;
        const res = await api.uploadAddressesForCarrierShipLabels(formData);
        return res;
    };

    const downloadTemplate = () => {
        const csvContent =
            'data:text/csv;charset=utf-8,First Name,Last Name,Email,' +
            'Line One,Line Two,City,State,Zip,Phone,Delivery Instructions';
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'carrier_labels_template.csv');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <div>
            {carriers.length > 0 && (
                <UploadCSVAndReviewComponent
                    additionalFormFields={additionalFormFields}
                    title={`Purchase Carrier Ship Labels for Facility ${user.facilityName}`}
                    subtitle="Once uploaded your file will be processed offline. You will receive an email with your results once complete."
                    fileLabel="Address File"
                    columns={columns}
                    upload={uploadOrders}
                    confirm={confirmUpload}
                    downloadTemplate={downloadTemplate}
                />
            )}
        </div>
    );
};
export { PurchaseCarrierShipLabels };
