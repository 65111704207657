import { mmFetchWithResponse } from './mmFetchWithResponse';

let qualityApi = {
    getCCOF: async (facilityID) => {
        let url = `api/quality/v1/ccof?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getDirectedRecPhase3Enabled: async (facilityID) => {
        let url = `api/quality/v3/receiving/phase3Enabled/${facilityID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getPendingRejections: async (facilityID) => {
        let url = `api/quality/v3/receiving/pendingRejections/${facilityID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getPendingIssues: async (facilityID) => {
        let url = `api/quality/v3/receiving/pendingIssues/${facilityID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },

    didNotArrive: async (poMapID) => {
        return await mmFetchWithResponse(`api/quality/v3/receiving/didNotArrive/${poMapID}`, {
            method: 'POST',
        });
    },

    acceptRejection: async (poMapID) => {
        let url = `api/quality/v3/receiving/acceptRejection/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    denyRejection: async (poMapID) => {
        let url = `api/quality/v3/receiving/denyRejection/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    recordCasesCounted: async (poMapID, casesCounted) => {
        let uri = `api/quality/v3/receiving/record/${poMapID}/casesCounted`;
        let response = await mmFetchWithResponse(uri, {
            method: 'POST',
            body: JSON.stringify({
                casesCounted: casesCounted,
            }),
        });
        return response;
    },
    recordCaseUnitCountValue: async (poMapID, caseIdx, value) => {
        let uri = `api/quality/v3/receiving/record/${poMapID}/caseUnitCount`;
        let response = await mmFetchWithResponse(uri, {
            method: 'POST',
            body: JSON.stringify({
                value: value,
                caseIdx: caseIdx,
            }),
        });

        return response;
    },
    recordCaaseUnitWeightValue: async (poMapID, caseIdx, weightIdx, value) => {
        let uri = `api/quality/v3/receiving/record/${poMapID}/caseUnitWeight`;
        let response = await mmFetchWithResponse(uri, {
            method: 'POST',
            body: JSON.stringify({
                value: value,
                caseIdx: caseIdx,
                weightIdx: weightIdx,
            }),
        });

        return response;
    },

    resetQCModule: async (purchaseOrderMapId, moduleName) => {
        let uri = `api/quality/v1/receiving/items/${purchaseOrderMapId}/modules/${moduleName}/desktop-reset`;
        return await mmFetchWithResponse(uri, {
            method: 'POST',
        });
    },

    getQualityControlTasks: async (type, facilityID) => {
        let url = `api/inventory/v1/task/qc?type=${type}&facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getQualityIssueCodes: async () => {
        let url = `api/quality/v2/receiving/issueCodes`;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getQualityPOs: async (facilityID) => {
        let url = `api/quality/v1/receiving/purchaseOrders?facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getQualityPODetails: async (poID) => {
        let url = `api/quality/v1/receiving?poID=` + poID;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    recordPalletsInspected: async (poMapID, palletsInspected, issueLevel) => {
        let uri = `api/quality/v2/receiving/record/palletsInspected/` + poMapID;
        let response = await mmFetchWithResponse(uri, {
            method: 'POST',
            body: JSON.stringify({
                palletsInspected: palletsInspected,
                issueLevel: issueLevel,
            }),
        });
        return response;
    },
    recordCasesInspected: async (poMapID, casesInspected, issueLevel) => {
        let uri = `api/quality/v2/receiving/record/casesInspected/` + poMapID;
        let response = await mmFetchWithResponse(uri, {
            method: 'POST',
            body: JSON.stringify({
                casesInspected: casesInspected,
                issueLevel: issueLevel,
            }),
        });
        return response;
    },
    recordQualityUnitInspection: async (poMapID, sendParams) => {
        let uri = `api/quality/v3/receiving/unitInspection/` + poMapID;
        let response = await mmFetchWithResponse(uri, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    recordQualityReceiving: async (poMapID, sendParams) => {
        let url = `api/quality/v1/receiving/` + poMapID;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    getQualityPODetailsV2: async (poID, isPackaging) => {
        let url = `api/quality/v2/receiving?poID=${poID}&isPackaging=${isPackaging}`;
        let response = await mmFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    recordQualityReceivingV2: async (poMapID, sendParams) => {
        let url = `api/quality/v2/receiving/record/` + poMapID;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    recordQualityReceivingCompleteV2: async (poMapID) => {
        let url = `api/quality/v2/receiving/complete/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({}),
        });
        return response;
    },
    recordQualityReceivingIssueV2: async (qcrExternalIssueID, sendParams, poMapID, issueLevel) => {
        let url = `api/quality/v2/receiving/issue/${poMapID}/${qcrExternalIssueID}`;
        sendParams['issueLevel'] = issueLevel;
        console.log(sendParams);
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    createNewIssueV2: async (poMapID, issueLevel) => {
        let url = `api/quality/v2/receiving/create/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                issueLevel: issueLevel,
            }),
        });
        return response;
    },
    deleteIssueV2: async (issueID, poMapID) => {
        let url = `api/quality/v2/receiving/delete/${poMapID}/${issueID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    undoRejection: async (poMapID) => {
        let url = `api/quality/v3/receiving/undoRejection/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
};

export { qualityApi };
