import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Modal,
    Grid,
    Select,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../../contexts/UserContext';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { catalogApi as invApi } from '../../../api/catalog';
import printJS from 'print-js';

const fileReaderCheck = (fr, tmpPDF) => {
    if (tmpPDF instanceof Blob) {
        fr.readAsDataURL(tmpPDF);
    } else {
        alert('There was an issue printing the pdf');
    }
};

let PrintLabels = ({ palletID, caseID, name, type }) => {
    let formFields = [
        {
            name: 'numLabels',
            inputElement: 'textField',
            label: 'Number of Labels',
            inputProps: { required: true, inputType: 'number', integer: true },
        },
    ];

    if (type === 'partialCase') {
        return (
            <div>
                <Button
                    onClick={async () => {
                        let tmpPDF = await api.downloadCaseTags({ caseID, qty: 1 });
                        var fr = new FileReader();
                        fr.addEventListener(
                            'load',
                            () => {
                                let tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                                printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                            },
                            false
                        );
                        fileReaderCheck(fr, tmpPDF);
                    }}
                >
                    Print Partial Case Label
                </Button>
            </div>
        );
    }
    if (type === 'caseLabelsForPallet') {
        return (
            <div>
                <Button
                    onClick={async () => {
                        let tmpPDF = await api.downloadCaseTags({ palletID: palletID });
                        var fr = new FileReader();
                        fr.addEventListener(
                            'load',
                            () => {
                                let tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                                printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                            },
                            false
                        );
                        fileReaderCheck(fr, tmpPDF);
                    }}
                >
                    Print all case tags
                </Button>
            </div>
        );
    } else {
        return (
            <>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let tmpPDF;
                        if (type === 'fullCase') {
                            tmpPDF = await api.downloadCaseTags({ caseID, qty: formData.numLabels });
                        } else if (type === 'pallet') {
                            tmpPDF = await api.downloadPalletTag(palletID, name, formData.numLabels);
                        }
                        var fr = new FileReader();
                        fr.addEventListener(
                            'load',
                            () => {
                                let tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                                printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                            },
                            false
                        );
                        fileReaderCheck(fr, tmpPDF);
                    }}
                />
            </>
        );
    }
};

let AdjustFullCasesInLocation = ({ palletID, locationID, onError, onSuccess }) => {
    let [creatingCases, setCreatingCases] = useState(false);

    return (
        <div>
            <FormComponent
                formFields={[
                    {
                        name: 'numFullCases',
                        inputElement: 'textField',
                        label: '# of Full Cases',
                        inputProps: { required: true, inputType: 'number', integer: true },
                    },
                ]}
                onSubmit={async (formData) => {
                    if (creatingCases) {
                        return;
                    }
                    setCreatingCases(true);
                    let response = await api.adjustFullCasesInLocation(palletID, locationID, formData.numFullCases);
                    setCreatingCases(false);
                    if (response.status === false) {
                        onError(`Failed to adjust full cases in location: ${response.msg}`);
                        return;
                    }

                    if (onSuccess && onSuccess instanceof Function) {
                        onSuccess();
                    }
                }}
            />
        </div>
    );
};

let CycleCountPallet = ({ palletID, numFullCases, numPartialCases, onError, onSuccess }) => {
    let [creatingCases, setCreatingCases] = useState(false);

    return (
        <div>
            <FormComponent
                formFields={[
                    {
                        name: 'numFullCases',
                        inputElement: 'textField',
                        label: '# of Full Cases',
                        gridValue: 6,
                        initialValue: numFullCases,
                        inputProps: { required: true, inputType: 'number', integer: true, max: 100 },
                    },
                    {
                        name: 'partialCases',
                        inputElement: 'textField',
                        label: '# of partial Cases',
                        gridValue: 6,
                        initialValue: numPartialCases,
                        inputProps: { required: true, inputType: 'number', integer: true, readOnly: true },
                    },
                ]}
                onSubmit={async (formData) => {
                    if (creatingCases) {
                        return;
                    }
                    setCreatingCases(true);
                    let sendParams = {
                        numFullCases: formData.numFullCases,
                        partialCases: [],
                        reason: 'Desktop Adjustment',
                        fromDesktop: true,
                    };
                    let response = await api.adjustPallet(palletID, sendParams);
                    setCreatingCases(false);
                    if (response.status === false) {
                        onError(`Failed to adjust full cases in location: ${response.msg}`);
                        return;
                    }

                    if (onSuccess && onSuccess instanceof Function) {
                        onSuccess();
                    }
                }}
            />
        </div>
    );
};

let ConvertFullCaseToPartial = ({ caseID, onError, onSuccess }) => {
    let [convertingCase, setConvertingCase] = useState(false);
    let [caseConverted, setCaseConverted] = useState(false);

    if (caseConverted) {
        return <PrintLabels caseID={caseID} type={'partialCase'} />;
    } else {
        return (
            <FormComponent
                formFields={[
                    {
                        name: 'numUnits',
                        inputElement: 'textField',
                        label: 'Num Units',
                        inputProps: { required: true, inputType: 'number', integer: true },
                    },
                ]}
                onSubmit={async (formData) => {
                    if (convertingCase) {
                        return;
                    }
                    setConvertingCase(true);
                    let response = await api.convertFullCaseToPartial(caseID, formData.numUnits);
                    setConvertingCase(false);
                    if (response.status === false) {
                        onError(`Failed to convert full case to partial: ${response.msg}`);
                        return;
                    }

                    setCaseConverted(true);
                    if (onSuccess && onSuccess instanceof Function) {
                        onSuccess();
                    }
                }}
            />
        );
    }
};

const ReverseConsumePackagingPallet = ({ pallet, onError, onSuccess, locations, palletID }) => {
    let reverseConsumeFormFields = [
        {
            name: 'numCases',
            inputElement: 'textField',
            label: 'Num Cases Returning',
            gridValue: 6,
            inputProps: { required: true, inputType: 'number', integer: true },
        },
        {
            name: 'locationID',
            display: pallet.locationType === 'INTERLINE_STAGING',
            inputElement: 'autocomplete',
            initialValue: locations.find((item) => item.type === 'RED_ZONE' && item.name.includes('200s')),
            label: 'Location',
            gridValue: 6,
            inputProps: { required: true, opts: locations },
        },
        {
            name: 'totalCases',
            inputElement: ({ formData, setFormField }) => {
                return <div>Total Cases: {Number(formData.numCases) + pallet.numCases}</div>;
            },
        },
    ];

    return (
        <>
            <h4>Reverse Consume Pallet {palletID}</h4>
            <div>Num Cases: {pallet.numCases}</div>
            <div>Location: {pallet.locationName}</div>
            <FormComponent
                formFields={reverseConsumeFormFields}
                onSubmit={async (formData) => {
                    let response = await api.reverseConsume(palletID, formData);
                    if (!response.status) {
                        onError(`Error reverse consuming pallet: ${response.msg}`);
                    } else {
                        if (onSuccess && onSuccess instanceof Function) {
                            onSuccess();
                        }
                    }
                }}
            />
        </>
    );
};

const ConsumePackagingPalletToStaging = ({ pallet, onError, onSuccess, locations, palletID }) => {
    let consumeStagingFormFields = [
        {
            name: 'numCases',
            inputElement: 'textField',
            label: 'Num Cases',
            gridValue: 6,
            inputProps: { required: true, inputType: 'number', integer: true },
        },
        {
            name: 'location',
            inputElement: 'autocomplete',
            label: 'Location',
            gridValue: 6,
            inputProps: { required: true, opts: locations.filter((item) => item.type === 'INTERLINE_STAGING') },
        },
    ];

    return (
        <>
            <h4>Consume Pallet {palletID} to Staging</h4>
            <FormComponent
                formFields={consumeStagingFormFields}
                onSubmit={async (formData) => {
                    formData.fullPallet = false;
                    if (Number(formData.numCases) === pallet.numCases) {
                        formData.fullPallet = true;
                    }
                    let response = await api.consumeToStaging(palletID, formData);
                    if (!response.status) {
                        onError(`Error consuming pallet: ${response.msg}`);
                    } else {
                        if (onSuccess && onSuccess instanceof Function) {
                            onSuccess();
                        }
                    }
                }}
            />
        </>
    );
};

let CreateNewPallet = ({ palletID, lotID, onError, onSuccess }) => {
    let [creatingPallet, setCreatingPallet] = useState(false);
    let [palletCreated, setPalletCreated] = useState(false);
    let [clickedIds, setClickedIds] = useState([]);
    let [createdPallet, setCreatedPallet] = useState({});

    if (palletCreated) {
        return (
            <DataTable
                columns={[
                    { accessor: 'id', Header: 'ID' },
                    { accessor: 'name', Header: 'Name' },
                    { accessor: 'numCases', Header: 'Cases' },
                    {
                        Header: 'Pallet Tag',
                        Cell: ({ row }) => {
                            let buttonColor1 = clickedIds.includes(`1_${row.original.id}`) ? 'default' : 'primary';
                            let buttonColor4 = clickedIds.includes(`4_${row.original.id}`) ? 'default' : 'primary';
                            let buttonColor16 = clickedIds.includes(`16_${row.original.id}`) ? 'default' : 'primary';
                            return (
                                <>
                                    <div style={{ padding: '5px' }}>
                                        <Button
                                            variant="contained"
                                            color={buttonColor1}
                                            onClick={async () => {
                                                let ids = [...clickedIds, `1_${row.original.id}`];
                                                setClickedIds(ids);
                                                let tmpPDF = await api.downloadPalletTag(
                                                    row.original.id,
                                                    row.original.name,
                                                    1
                                                );
                                                var fr = new FileReader();
                                                fr.addEventListener(
                                                    'load',
                                                    () => {
                                                        let tmpPDFdata = fr.result.substr(
                                                            fr.result.indexOf('base64,') + 7
                                                        );
                                                        printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                                                    },
                                                    false
                                                );
                                                fileReaderCheck(fr, tmpPDF);
                                            }}
                                        >
                                            🖨x1
                                        </Button>
                                    </div>
                                    <div style={{ padding: '5px' }}>
                                        <Button
                                            variant="contained"
                                            color={buttonColor4}
                                            onClick={async () => {
                                                let ids = [...clickedIds, `4_${row.original.id}`];
                                                setClickedIds(ids);
                                                let tmpPDF = await api.downloadPalletTag(
                                                    row.original.id,
                                                    row.original.name,
                                                    4
                                                );
                                                var fr = new FileReader();
                                                fr.addEventListener(
                                                    'load',
                                                    () => {
                                                        let tmpPDFdata = fr.result.substr(
                                                            fr.result.indexOf('base64,') + 7
                                                        );
                                                        printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                                                    },
                                                    false
                                                );
                                                fileReaderCheck(fr, tmpPDF);
                                            }}
                                        >
                                            🖨x4
                                        </Button>
                                    </div>
                                    <div style={{ padding: '5px' }}>
                                        <Button
                                            variant="contained"
                                            color={buttonColor16}
                                            onClick={async () => {
                                                let ids = [...clickedIds, `16_${row.original.id}`];
                                                setClickedIds(ids);
                                                let tmpPDF = await api.downloadPalletTag(
                                                    row.original.id,
                                                    row.original.name,
                                                    16
                                                );
                                                var fr = new FileReader();
                                                fr.addEventListener(
                                                    'load',
                                                    () => {
                                                        let tmpPDFdata = fr.result.substr(
                                                            fr.result.indexOf('base64,') + 7
                                                        );
                                                        printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                                                    },
                                                    false
                                                );
                                                fileReaderCheck(fr, tmpPDF);
                                            }}
                                        >
                                            🖨x16
                                        </Button>
                                    </div>
                                </>
                            );
                        },
                    },
                ]}
                showPagination={false}
                editable={false}
                data={[createdPallet]}
            />
        );
    } else {
        return (
            <div>
                <Button
                    disabled={creatingPallet}
                    onClick={async () => {
                        if (window.confirm(`Create new pallet from lot ${lotID}`) === false) {
                            return;
                        }

                        setCreatingPallet(true);
                        let response = await api.splitPalletV2(palletID);
                        setCreatingPallet(false);

                        if (response.status === false) {
                            let message = 'Error creating new pallet';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            onError(message);
                        } else {
                            setCreatedPallet(response?.data?.pallet);
                            setPalletCreated(true);
                            if (onSuccess && onSuccess instanceof Function) {
                                onSuccess();
                            }
                        }
                    }}
                >
                    Create New Pallet
                </Button>
            </div>
        );
    }
};

let ConvertPalletOrCaseToItemLevelTwo = ({ il2V2, lotID, onError, palletID, pallets, onSuccess, caseID = null }) => {
    const [il2ConversionComplete, setIl2ConversionComplete] = useState(false);
    const [clickedIds, setClickedIds] = useState([]);
    const [convertedIl2Pallets, setConvertedIl2Pallets] = useState([]);
    let [internalPalletRows, setInternalPalletRows] = useState(
        pallets
            .filter((item) => {
                if (il2V2) {
                    return item.id === palletID;
                } else {
                    return true;
                }
            })
            .map((item) => {
                return { id: item.id, numCases: '', currentCases: item.numCases, name: item.name };
            })
    );
    const [itemLevelTwo, setItemLevelTwo] = useState([]);
    const [selectedItemLevelTwoID, setSelectedItemLevelTwoID] = useState(null);

    useEffect(() => {
        getValidItemLevelTwo();
    }, []);

    let getValidItemLevelTwo = async () => {
        if (!lotID) {
            return;
        }

        let response = await invApi.getItemLevelTwoForLot(lotID);
        if (!response.status) {
            onError('No Item Level 2 Found ' + response.msg);
            setItemLevelTwo([]);
            return;
        }

        let itemLevelTwoAsOpts = response.data.output.map((row) => {
            row.text = row.type + ' ' + row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setItemLevelTwo([pleaseSelect].concat(itemLevelTwoAsOpts));
    };

    let il2FormFields = [
        {
            name: 'totalCases',
            label: 'Total Cases To Convert',
            gridValue: 12,
            value: internalPalletRows.map((item) => Number(item.numCases)).reduce((a, b) => a + b, 0) || '',
        },
        {
            name: 'itemLevelTwoID',
            inputElement: 'select',
            label: 'Item Level 2',
            gridValue: 12,
            inputProps: {
                required: true,
                opts: itemLevelTwo,
                onChange: (event) => {
                    console.log('set selected item level two id', event.target.value);
                    setSelectedItemLevelTwoID(event.target.value);
                },
            },
        },
        {
            name: 'numRPC',
            inputElement: 'textField',
            label: 'Num RPC',
            gridValue: 12,
            inputProps: { required: true, inputType: 'number', integer: true },
        },
    ];

    let il2CaseFormFields = [
        {
            name: 'itemLevelTwoID',
            inputElement: 'select',
            label: 'Item Level 2',
            gridValue: 12,
            inputProps: {
                required: true,
                opts: itemLevelTwo,
                onChange: (event) => {
                    console.log('set selected item level two id', event.target.value);
                    setSelectedItemLevelTwoID(event.target.value);
                },
            },
        },
        {
            name: 'suggestedUnitsPerRPC',
            label: 'Suggested Units Per RPC',
            gridValue: 12,
            display: (formData) => !!formData.itemLevelTwoID,
            value: itemLevelTwo.find((item) => Number(item.id) === Number(selectedItemLevelTwoID))?.unitsPerRPC,
        },
        {
            name: 'unitsPerRPC',
            inputElement: 'textField',
            label: 'Units Per RPC',
            gridValue: 12,
            inputProps: { required: true, inputType: 'number', integer: true },
        },
    ];

    let il2V2FormFields = [
        {
            name: 'totalCases',
            label: 'Total Cases To Convert',
            gridValue: 12,
            value: internalPalletRows.map((item) => Number(item.numCases)).reduce((a, b) => a + b, 0),
        },
        {
            name: 'itemLevelTwoID',
            inputElement: 'select',
            label: 'Item Level 2',
            gridValue: 12,
            inputProps: {
                required: true,
                opts: itemLevelTwo,
                onChange: (event) => {
                    console.log('set selected item level two id', event.target.value);
                    setSelectedItemLevelTwoID(event.target.value);
                },
            },
        },
        {
            name: 'suggestedUnitsPerRPC',
            label: 'Suggested Units Per RPC',
            gridValue: 12,
            display: (formData) => !!formData.itemLevelTwoID,
            value: itemLevelTwo.find((item) => Number(item.id) === Number(selectedItemLevelTwoID))?.unitsPerRPC,
        },
        {
            name: 'unitsPerRPC',
            inputElement: 'textField',
            label: 'Units Per RPC',
            gridValue: 12,
            inputProps: { required: true, inputType: 'number', integer: true },
        },
    ];

    if (il2ConversionComplete) {
        return (
            <>
                <DataTable
                    columns={[
                        { accessor: 'id', Header: 'Pallet ID' },
                        { accessor: 'name', Header: 'Name' },
                        { accessor: 'numCases', Header: 'Cases' },
                        {
                            Header: 'Pallet Tag',
                            Cell: ({ row }) => {
                                let buttonColor1 = clickedIds.includes(`1_${row.original.id}`) ? 'default' : 'primary';
                                let buttonColor4 = clickedIds.includes(`4_${row.original.id}`) ? 'default' : 'primary';
                                let buttonColor16 = clickedIds.includes(`16_${row.original.id}`)
                                    ? 'default'
                                    : 'primary';
                                return (
                                    <>
                                        <div style={{ padding: '5px' }}>
                                            <Button
                                                variant="contained"
                                                color={buttonColor1}
                                                onClick={async () => {
                                                    let ids = [...clickedIds, `1_${row.original.id}`];
                                                    setClickedIds(ids);
                                                    let tmpPDF = await api.downloadPalletTag(
                                                        row.original.id,
                                                        row.original.name,
                                                        1
                                                    );
                                                    var fr = new FileReader();
                                                    fr.addEventListener(
                                                        'load',
                                                        () => {
                                                            let tmpPDFdata = fr.result.substr(
                                                                fr.result.indexOf('base64,') + 7
                                                            );
                                                            printJS({
                                                                printable: tmpPDFdata,
                                                                type: 'pdf',
                                                                base64: true,
                                                            });
                                                        },
                                                        false
                                                    );
                                                    fileReaderCheck(fr, tmpPDF);
                                                }}
                                            >
                                                🖨x1
                                            </Button>
                                        </div>
                                        <div style={{ padding: '5px' }}>
                                            <Button
                                                variant="contained"
                                                color={buttonColor4}
                                                onClick={async () => {
                                                    let ids = [...clickedIds, `4_${row.original.id}`];
                                                    setClickedIds(ids);
                                                    let tmpPDF = await api.downloadPalletTag(
                                                        row.original.id,
                                                        row.original.name,
                                                        4
                                                    );
                                                    var fr = new FileReader();
                                                    fr.addEventListener(
                                                        'load',
                                                        () => {
                                                            let tmpPDFdata = fr.result.substr(
                                                                fr.result.indexOf('base64,') + 7
                                                            );
                                                            printJS({
                                                                printable: tmpPDFdata,
                                                                type: 'pdf',
                                                                base64: true,
                                                            });
                                                        },
                                                        false
                                                    );
                                                    fileReaderCheck(fr, tmpPDF);
                                                }}
                                            >
                                                🖨x4
                                            </Button>
                                        </div>
                                        <div style={{ padding: '5px' }}>
                                            <Button
                                                variant="contained"
                                                color={buttonColor16}
                                                onClick={async () => {
                                                    let ids = [...clickedIds, `16_${row.original.id}`];
                                                    setClickedIds(ids);
                                                    let tmpPDF = await api.downloadPalletTag(
                                                        row.original.id,
                                                        row.original.name,
                                                        16
                                                    );
                                                    var fr = new FileReader();
                                                    fr.addEventListener(
                                                        'load',
                                                        () => {
                                                            let tmpPDFdata = fr.result.substr(
                                                                fr.result.indexOf('base64,') + 7
                                                            );
                                                            printJS({
                                                                printable: tmpPDFdata,
                                                                type: 'pdf',
                                                                base64: true,
                                                            });
                                                        },
                                                        false
                                                    );
                                                    fileReaderCheck(fr, tmpPDF);
                                                }}
                                            >
                                                🖨x16
                                            </Button>
                                        </div>
                                    </>
                                );
                            },
                        },
                        {
                            Header: 'Case Tags',
                            Cell: ({ row }) => {
                                let buttonColorCaseTags = clickedIds.includes(`case_tags_${row.original.id}`)
                                    ? 'default'
                                    : 'primary';
                                return (
                                    <div
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color={buttonColorCaseTags}
                                            onClick={async () => {
                                                let ids = [...clickedIds, `case_tags_${row.original.id}`];
                                                setClickedIds(ids);
                                                let tmpPDF = await api.downloadCaseTags({ palletID: row.original.id });
                                                var fr = new FileReader();
                                                fr.addEventListener(
                                                    'load',
                                                    () => {
                                                        let tmpPDFdata = fr.result.substr(
                                                            fr.result.indexOf('base64,') + 7
                                                        );
                                                        printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                                                    },
                                                    false
                                                );
                                                fileReaderCheck(fr, tmpPDF);
                                            }}
                                        >
                                            Print {row.original.numCases} case tags
                                        </Button>
                                    </div>
                                );
                            },
                        },
                    ]}
                    showPagination={false}
                    editable={false}
                    data={convertedIl2Pallets}
                />
            </>
        );
    } else {
        return (
            <>
                {!caseID && (
                    <DataTable
                        columns={[
                            { accessor: 'name', Header: 'Name' },
                            { accessor: 'currentCases', Header: 'Cases' },
                            {
                                accessor: 'numCases',
                                Header: 'Cases Converted',
                                editable: true,
                                editProps: { type: 'input', inputType: 'number', integer: true },
                            },
                        ]}
                        showPagination={false}
                        editable={true}
                        data={internalPalletRows}
                        saveEdit={({ id, field, value, row }) => {
                            let palletRow = internalPalletRows.find((item) => item.id === id);
                            let tmp = JSON.parse(JSON.stringify(internalPalletRows)).map((item) => {
                                if (item.id === id) {
                                    item.numCases = value;
                                }
                                return item;
                            });
                            setInternalPalletRows(tmp);
                            return true;
                        }}
                    />
                )}
                <FormComponent
                    formFields={!!caseID ? il2CaseFormFields : il2V2 ? il2V2FormFields : il2FormFields}
                    onSubmit={async (formData) => {
                        let sendParams = {
                            itemLevelTwoID: formData.itemLevelTwoID,
                            lotID: lotID,
                        };

                        if (il2V2) {
                            sendParams.unitsPerRPC = formData.unitsPerRPC;
                        } else {
                            sendParams.numRPC = formData.numRPC;
                        }

                        if (!caseID) {
                            let pallets = internalPalletRows
                                .map((item) => ({ id: item.id, numCases: Number(item.numCases) }))
                                .filter((item) => item.numCases > 0);
                            sendParams.pallets = pallets;
                        }
                        let response;

                        if (!caseID) {
                            if (il2V2) {
                                response = await api.convertPalletsToIL2V2(sendParams);
                            } else {
                                response = await api.convertPalletsToIL2(sendParams);
                            }
                        } else {
                            response = await api.convertCaseToIL2(caseID, sendParams);
                        }
                        if (response.status === false) {
                            let message = 'Error converting to IL2';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            onError(message);
                        } else {
                            setConvertedIl2Pallets(response.data.pallets);
                            setIl2ConversionComplete(true);
                            if (onSuccess && onSuccess instanceof Function) {
                                onSuccess();
                            }
                        }
                    }}
                />
            </>
        );
    }
};

let TransferPallet = ({ onError, palletID, pallets, lotID, facilities, onSuccess }) => {
    const [palletTransferComplete, setPalletTransferComplete] = useState(false);
    const [transferredPallets, setTransferredPallets] = useState(false);
    const [clickedIds, setClickedIds] = useState([]);

    let facilitiesAsOpts = [{ text: 'Please Select', value: 0 }, ...facilities];

    let transferFormFields = [
        {
            name: 'facilityID',
            inputElement: 'select',
            label: 'Facility',
            inputProps: { required: true, opts: facilitiesAsOpts },
        },
    ];

    if (palletTransferComplete) {
        return (
            <>
                <DataTable
                    columns={[
                        { accessor: 'id', Header: 'ID' },
                        { accessor: 'name', Header: 'Name' },
                        { accessor: 'numCases', Header: 'Cases' },
                        {
                            Header: 'Pallet Tag',
                            Cell: ({ row }) => {
                                let buttonColor1 = clickedIds.includes(`1_${row.original.id}`) ? 'default' : 'primary';
                                let buttonColor4 = clickedIds.includes(`4_${row.original.id}`) ? 'default' : 'primary';
                                let buttonColor16 = clickedIds.includes(`16_${row.original.id}`)
                                    ? 'default'
                                    : 'primary';
                                return (
                                    <>
                                        <div style={{ padding: '5px' }}>
                                            <Button
                                                variant="contained"
                                                color={buttonColor1}
                                                onClick={async () => {
                                                    let ids = [...clickedIds, `1_${row.original.id}`];
                                                    setClickedIds(ids);
                                                    let tmpPDF = await api.downloadPalletTag(
                                                        row.original.id,
                                                        row.original.name,
                                                        1
                                                    );
                                                    var fr = new FileReader();
                                                    fr.addEventListener(
                                                        'load',
                                                        () => {
                                                            let tmpPDFdata = fr.result.substr(
                                                                fr.result.indexOf('base64,') + 7
                                                            );
                                                            printJS({
                                                                printable: tmpPDFdata,
                                                                type: 'pdf',
                                                                base64: true,
                                                            });
                                                        },
                                                        false
                                                    );
                                                    fileReaderCheck(fr, tmpPDF);
                                                }}
                                            >
                                                🖨x1
                                            </Button>
                                        </div>
                                        <div style={{ padding: '5px' }}>
                                            <Button
                                                variant="contained"
                                                color={buttonColor4}
                                                onClick={async () => {
                                                    let ids = [...clickedIds, `4_${row.original.id}`];
                                                    setClickedIds(ids);
                                                    let tmpPDF = await api.downloadPalletTag(
                                                        row.original.id,
                                                        row.original.name,
                                                        4
                                                    );
                                                    var fr = new FileReader();
                                                    fr.addEventListener(
                                                        'load',
                                                        () => {
                                                            let tmpPDFdata = fr.result.substr(
                                                                fr.result.indexOf('base64,') + 7
                                                            );
                                                            printJS({
                                                                printable: tmpPDFdata,
                                                                type: 'pdf',
                                                                base64: true,
                                                            });
                                                        },
                                                        false
                                                    );
                                                    fileReaderCheck(fr, tmpPDF);
                                                }}
                                            >
                                                🖨x4
                                            </Button>
                                        </div>
                                        <div style={{ padding: '5px' }}>
                                            <Button
                                                variant="contained"
                                                color={buttonColor16}
                                                onClick={async () => {
                                                    let ids = [...clickedIds, `16_${row.original.id}`];
                                                    setClickedIds(ids);
                                                    let tmpPDF = await api.downloadPalletTag(
                                                        row.original.id,
                                                        row.original.name,
                                                        16
                                                    );
                                                    var fr = new FileReader();
                                                    fr.addEventListener(
                                                        'load',
                                                        () => {
                                                            let tmpPDFdata = fr.result.substr(
                                                                fr.result.indexOf('base64,') + 7
                                                            );
                                                            printJS({
                                                                printable: tmpPDFdata,
                                                                type: 'pdf',
                                                                base64: true,
                                                            });
                                                        },
                                                        false
                                                    );
                                                    fileReaderCheck(fr, tmpPDF);
                                                }}
                                            >
                                                🖨x16
                                            </Button>
                                        </div>
                                    </>
                                );
                            },
                        },
                    ]}
                    showPagination={false}
                    editable={false}
                    data={transferredPallets}
                />
            </>
        );
    } else {
        return (
            <>
                <h4>Transfer Pallets From Lot</h4>
                <div>This will transfer pallets to the receiving dock in the selected facility</div>
                <FormComponent
                    formFields={transferFormFields}
                    onSubmit={async (formData) => {
                        let sendParams = {
                            pallets: [palletID],
                            facilityID: formData.facilityID,
                            lotID: lotID,
                        };
                        let response = await api.transferPallets(sendParams);
                        if (response.status === false) {
                            let message = 'Error transfering pallets';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            onError(message);
                        } else {
                            setTransferredPallets(response.data.pallets);
                            setPalletTransferComplete(true);
                            if (onSuccess && onSuccess instanceof Function) {
                                onSuccess();
                            }
                        }
                    }}
                />
            </>
        );
    }
};

let DumpDonateRTVPalletOrCase = ({ palletID, onError, action, onSuccess, caseID }) => {
    const [reasonCodes, setReasonCodes] = useState([]);

    let getReasonCodes = async () => {
        let response = await api.getReasonCodes();
        if (!response.status) {
            onError('Failed to get reason codes');
            return;
        }
        setReasonCodes(response.data.reasonCodes);
    };

    useEffect(() => {
        getReasonCodes();
    }, []);

    let formFields = {
        dump: [
            {
                name: 'reasonCode',
                label: 'Reason Code',
                inputElement: 'select',
                gridValue: 6,
                inputProps: { opts: reasonCodes, required: true },
            },
        ],
        donate: [
            {
                name: 'reasonCode',
                label: 'Reason Code',
                inputElement: 'select',
                gridValue: 6,
                inputProps: { opts: reasonCodes, required: true },
            },
        ],
        vendor: [],
    };

    formFields.dump.push({
        name: 'creditRequested',
        inputElement: 'checkbox',
        label: 'Requested Credit',
        gridValue: 6,
    });
    formFields.donate.push({
        name: 'creditRequested',
        inputElement: 'checkbox',
        label: 'Requested Credit',
        gridValue: 6,
    });
    formFields.vendor.push({
        name: 'creditRequested',
        inputElement: 'checkbox',
        label: 'Requested Credit',
        gridValue: 6,
    });

    return (
        <>
            {['dump', 'donate', 'vendor'].includes(action) && (
                <FormComponent
                    formFields={formFields[action]}
                    onSubmit={async (formData) => {
                        let item = {
                            reasonCode: formData.reasonCode,
                            creditRequested: formData.creditRequested,
                        };

                        let confirmMsg;
                        let type;
                        if (!!caseID) {
                            type = 'case';
                        } else if (!!palletID) {
                            type = 'pallet';
                        }
                        if (action === 'dump') {
                            confirmMsg = `Dump ${type}?`;
                        } else if (action === 'donate') {
                            confirmMsg = `Donate ${type}?`;
                        } else if (action === 'vendor') {
                            confirmMsg = `Return ${type} to vendor?`;
                        }
                        if (window.confirm(confirmMsg) === false) {
                            return;
                        }

                        let response;
                        if (action === 'dump') {
                            if (type === 'pallet') {
                                response = await api.dumpPallet(palletID, item);
                            } else if (type === 'case') {
                                response = await api.dumpCase(caseID, item);
                            }
                        } else if (action === 'donate') {
                            if (type === 'pallet') {
                                response = await api.donatePallet(palletID, item);
                            } else if (type === 'case') {
                                response = await api.donateCase(caseID, item);
                            }
                        } else if (action === 'vendor') {
                            if (type === 'case') {
                                response = await api.returnCaseToVendor(caseID, item);
                            } else if (type === 'pallet') {
                                response = await api.returnPalletToVendor(palletID, item);
                            }
                        }

                        if (response.status === false) {
                            let message = 'Error';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            onError(message);
                        } else {
                            onSuccess();
                        }
                    }}
                />
            )}
        </>
    );
};

const MovePallet = ({ palletID, onError, onSuccess, locations }) => {
    let formFields = [
        {
            name: 'location',
            inputElement: 'autocomplete',
            label: 'Location',
            gridValue: 6,
            inputProps: {
                required: true,
                opts: locations,
                opts: locations.filter((item) => item.type !== 'LINE_SLOT'),
            },
        },
    ];

    return (
        <>
            <h4>Move Pallet {palletID}</h4>
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData) => {
                    let item = {
                        newLocation: formData.location,
                        fromDesktop: true,
                        reason: 'Moved from desktop',
                    };

                    let response = await api.movePallet(palletID, item);
                    if (!response.status) {
                        onError(`Error consuming pallet: ${response.msg}`);
                    } else {
                        if (onSuccess && onSuccess instanceof Function) {
                            onSuccess();
                        }
                    }
                }}
            />
        </>
    );
};

const MovePartialCase = ({ caseID, locations, lotID, onError, onSuccess }) => {
    const [palletOptions, setPalletOptions] = useState([]);

    let getPalletOptions = async () => {
        let response = await api.getPalletsInLot(lotID);
        if (!response.status) {
            onError('Failed to get pallets');
            return;
        }

        let palletOptionsAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setPalletOptions(palletOptionsAsOpts);
    };

    useEffect(() => {
        getPalletOptions();
    }, []);

    let formFields = [
        {
            name: 'location',
            inputElement: 'autocomplete',
            label: 'Location',
            gridValue: 6,
            inputProps: { opts: locations, opts: locations.filter((item) => item.type !== 'BULK') },
        },
        {
            name: 'pallet',
            inputElement: 'autocomplete',
            label: 'Pallet',
            gridValue: 6,
            inputProps: { opts: palletOptions },
        },
    ];

    return (
        <>
            <h4>Move Partial Case {caseID}</h4>
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData) => {
                    if (formData.location === null && formData.pallet === null) {
                        onError(`Must specify location or pallet`);
                        return;
                    }

                    let item = {
                        palletID: formData.pallet,
                        locationID: formData.location,
                        fromDesktop: true,
                        reason: 'Moved from desktop',
                    };

                    let response = await api.movePartialCase(caseID, item);
                    if (!response.status) {
                        onError(`Error moving case: ${response.msg}`);
                    } else {
                        if (onSuccess && onSuccess instanceof Function) {
                            onSuccess();
                        }
                    }
                }}
            />
        </>
    );
};

const MoveFullCase = ({ currentPalletID, locations, lotID, onError, onSuccess }) => {
    const [palletOptions, setPalletOptions] = useState([]);

    let getPalletOptions = async () => {
        let response = await api.getPalletsInLot(lotID);
        if (!response.status) {
            onError('Failed to get pallets');
            return;
        }

        let palletOptionsAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setPalletOptions(palletOptionsAsOpts);
    };

    useEffect(() => {
        getPalletOptions();
    }, []);

    let formFields = [
        {
            name: 'numCases',
            inputElement: 'textField',
            label: 'Num cases',
            gridValue: 6,
            inputProps: { required: true, inputType: 'number', integer: true },
        },
        {
            name: 'currLocation',
            inputElement: 'autocomplete',
            label: 'From Location',
            gridValue: 6,
            inputProps: { opts: locations },
        },
        {
            name: 'location',
            inputElement: 'autocomplete',
            label: 'To Location',
            gridValue: 6,
            inputProps: { opts: locations, opts: locations.filter((item) => item.type !== 'BULK') },
        },
        {
            name: 'pallet',
            inputElement: 'autocomplete',
            label: 'To Pallet',
            gridValue: 6,
            inputProps: { opts: palletOptions },
        },
    ];

    return (
        <>
            <h4>Move Full Cases from pallet {currentPalletID}</h4>
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData) => {
                    if (formData.location === '') {
                        formData.location = null;
                    }

                    if (formData.pallet === '') {
                        formData.pallet = null;
                    }

                    if (formData.location === null && formData.pallet === null) {
                        onError(`Must specify location or pallet`);
                        return;
                    }

                    let item = {
                        palletID: formData.pallet,
                        currentLocationID: formData.currLocation,
                        locationID: formData.location,
                        numCases: formData.numCases,
                        fromDesktop: true,
                        reason: 'Moved from desktop',
                    };

                    let response = await api.moveFullCase(currentPalletID, item);
                    if (!response.status) {
                        onError(`Error moving case: ${response.msg}`);
                    } else {
                        if (onSuccess && onSuccess instanceof Function) {
                            onSuccess();
                        }
                    }
                }}
            />
        </>
    );
};

const BulkAdjustNumUnits = ({ lotID, onError, onSuccess }) => {
    let formFields = [
        {
            name: 'numUnits',
            inputElement: 'textField',
            label: 'Num units',
            gridValue: 12,
            inputProps: { required: true, inputType: 'number', integer: true, max: 1000 },
        },
    ];

    return (
        <>
            <h4>Adjust each case within a lot {lotID} to new units per case</h4>
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData) => {
                    let item = {
                        lotID: lotID,
                        numUnits: formData.numUnits,
                        reason: 'Desktop adjustment',
                    };

                    let response = await api.bulkAdjustNumUnits(item);
                    if (!response.status) {
                        onError(`Error bulk adjusting: ${response.msg}`);
                    } else {
                        if (onSuccess && onSuccess instanceof Function) {
                            onSuccess();
                        }
                    }
                }}
            />
        </>
    );
};

const ActionModal = ({
    closeModal,
    type,
    open,
    row,
    facilities,
    setNotification,
    reloadData,
    il2V2 = true,
    locations,
}) => {
    const [selectedAction, setSelectedAction] = useState('');
    const user = useContext(UserContext);

    let actions = [{ text: `Print ${type} labels`, value: 'labels' }];

    if (type === 'pallet') {
        actions.push(
            { text: 'Dump', value: 'dump' },
            { text: 'Donate', value: 'donate' },
            { text: 'Return to Vendor', value: 'vendor' }
        );

        if (user.canAdjustInvFromDesktop() == true) {
            actions.push(
                { text: 'Adjust Cases on Pallet', value: 'cycleCountPallet' },
                { text: 'Move', value: 'movePallet' },
                { text: 'Bulk Adjust Lot', value: 'bulkAdjustLot' }
            );
        }

        if (row.itemType === 'Item Master') {
            actions.push({ text: 'Item Level 2', value: 'il2' });
        }

        if (row.itemType === 'Packaging') {
            if (row.locationType !== 'INTERLINE_STAGING') {
                actions.push({ text: 'Consume To Staging', value: 'consumeToStaging' });
            }

            if (
                row.locationType === 'INTERLINE_STAGING' ||
                row.locationType === 'RED_ZONE' ||
                row.locationType === 'SUPERMARKET'
            ) {
                actions.push({ text: 'Reverse Consume', value: 'reverseConsume' });
            }
        } else {
            actions.push({ text: 'Print all Case Tags', value: 'caseLabelsForPallet' });
        }

        actions.push({ text: 'Transfer Pallet', value: 'transfer' });

        actions.push({ text: 'Create New Pallet', value: 'createNew' });
    } else if (type === 'case') {
        actions.push(
            { text: 'Dump', value: 'dump' },
            { text: 'Donate', value: 'donate' },
            { text: 'Return to Vendor', value: 'vendor' }
        );

        if (row.itemType === 'Item Master') {
            actions.push({ text: 'Item Level 2', value: 'il2' });
        }

        if (row.caseType === 'FULL' && row.itemType !== 'Packaging' && user.canAdjustCount()) {
            actions.push({ text: 'Create Partial Case Label', value: 'createPartial' });

            if (user.canAdjustInvFromDesktop()) {
                actions.push({ text: 'Move full case', value: 'moveFull' });
            }

            if (!row.currentPalletID && user.canAdjustInvFromDesktop()) {
                actions.push({ text: 'Adjust Full Cases In Location', value: 'adjustFullCases' });
            }
        }

        if (row.caseType === 'PARTIAL' && row.itemType !== 'Packaging' && user.canAdjustInvFromDesktop()) {
            actions.push({ text: 'Move Partial case', value: 'movePartial' });
        }
    }

    const handleActionChange = (event) => {
        setSelectedAction(event.target.value);
    };

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            fullWidth={true}
            onClose={() => {
                setSelectedAction('');
                closeModal();
            }}
        >
            <DialogTitle>
                Actions for {type} {type === 'case' ? row.caseName : row.palletName}
                <div style={{ float: 'right' }}>
                    <Button
                        onClick={() => {
                            setSelectedAction('');
                            closeModal();
                        }}
                    >
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                <InputLabel shrink={true}>Option</InputLabel>
                <Select native className="input-element-select" onChange={handleActionChange}>
                    <option key="ps" value="ps">
                        Please Select
                    </option>
                    {actions.map((item) => {
                        return (
                            <option key={item.value} value={item.value}>
                                {item.text}
                            </option>
                        );
                    })}
                </Select>

                {selectedAction === 'labels' && (
                    <PrintLabels
                        palletID={row.palletID}
                        caseID={row.caseID}
                        type={
                            type === 'pallet'
                                ? 'pallet'
                                : type === 'case' && row.caseType === 'FULL'
                                ? 'fullCase'
                                : 'partialCase'
                        }
                        name={row.palletName || ''}
                    />
                )}
                {selectedAction === 'caseLabelsForPallet' && (
                    <PrintLabels palletID={row.palletID} type={'caseLabelsForPallet'} name={row.paleltName} />
                )}
                {selectedAction === 'createNew' && (
                    <CreateNewPallet
                        palletID={row.palletID}
                        lotID={row.lotID}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                    />
                )}
                {selectedAction === 'createPartial' && (
                    <ConvertFullCaseToPartial
                        caseID={row.caseID}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                        }}
                    />
                )}
                {selectedAction === 'adjustFullCases' && (
                    <AdjustFullCasesInLocation
                        locationID={row.locationID}
                        numFullCases={row.numFullCases}
                        palletID={row.originPalletID}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                            closeModal();
                        }}
                    />
                )}
                {selectedAction === 'movePallet' && (
                    <MovePallet
                        palletID={row.palletID}
                        locations={locations}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                        }}
                    />
                )}
                {selectedAction === 'il2' && (
                    <ConvertPalletOrCaseToItemLevelTwo
                        il2V2={il2V2}
                        palletID={row.palletID}
                        lotID={row.lotID}
                        caseID={row.caseID}
                        pallets={[{ id: row.palletID, name: row.palletName, numCases: row.numCases }]}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                        }}
                    />
                )}
                {['dump', 'donate', 'vendor'].includes(selectedAction) && (
                    <DumpDonateRTVPalletOrCase
                        action={selectedAction}
                        palletID={row.palletID}
                        caseID={row.caseID}
                        onSuccess={() => {
                            setSelectedAction('');
                            closeModal();
                            reloadData();
                        }}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                    />
                )}
                {selectedAction === 'transfer' && (
                    <TransferPallet
                        lotID={row.lotID}
                        palletID={row.palletID}
                        facilities={facilities}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                        }}
                        palletID={row.palletID}
                        pallets={[{ id: row.palletID, name: row.palletName, numCases: row.numCases }]}
                    />
                )}
                {selectedAction === 'consumeToStaging' && (
                    <ConsumePackagingPalletToStaging
                        palletID={row.palletID}
                        pallet={row}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            setSelectedAction('');
                            closeModal();
                            reloadData();
                        }}
                        locations={locations}
                    />
                )}
                {selectedAction === 'reverseConsume' && (
                    <ReverseConsumePackagingPallet
                        palletID={row.palletID}
                        pallet={row}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            setSelectedAction('');
                            closeModal();
                            reloadData();
                        }}
                        locations={locations}
                    />
                )}
                {selectedAction === 'movePartial' && (
                    <MovePartialCase
                        caseID={row.caseID}
                        locations={locations}
                        lotID={row.lotID}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                            closeModal();
                        }}
                    />
                )}
                {selectedAction === 'cycleCountPallet' && (
                    <CycleCountPallet
                        palletID={row.palletID}
                        numPartialCases={row.numPartialCases}
                        numFullCases={row.numFullCases}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                            closeModal();
                        }}
                    />
                )}
                {selectedAction === 'moveFull' && (
                    <MoveFullCase
                        locations={locations}
                        lotID={row.lotID}
                        currentPalletID={row.currentPalletID}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                            closeModal();
                        }}
                    />
                )}
                {selectedAction === 'bulkAdjustLot' && (
                    <BulkAdjustNumUnits
                        lotID={row.lotID}
                        onError={(err) => {
                            setNotification({ text: err, level: 'error' });
                        }}
                        onSuccess={() => {
                            reloadData();
                            closeModal();
                        }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export {
    ConvertPalletOrCaseToItemLevelTwo,
    TransferPallet,
    DumpDonateRTVPalletOrCase,
    CreateNewPallet,
    PrintLabels,
    ConvertFullCaseToPartial,
    ConsumePackagingPalletToStaging,
    ReverseConsumePackagingPallet,
    ActionModal,
};
