import React, { useRef, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const FileUpload = function ({
    accept,
    disabled: disabledProp,
    text,
    onFileChange,
    file,
    inputProps,
    multiple = false,
    maxNumFiles = null,
}) {
    const inputEl = useRef(null);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!multiple && !!file) {
            setDisabled(true);
        } else if (multiple && !!file && file.length > 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [file, multiple]);

    const [disabled, setDisabled] = useState(false);

    return (
        <Grid container alignItems="center">
            {
                <label
                    style={{
                        backgroundColor: 'white',
                        height: '175px!important',
                        display: 'inline-block',
                        paddingRight: '30px',
                    }}
                >
                    <input
                        multiple={multiple}
                        id={`${text.replaceAll(' ', '')}_fileUpload`}
                        type="file"
                        style={{ display: 'none' }}
                        disabled={disabledProp || disabled}
                        ref={inputEl}
                        accept={accept}
                        onChange={(event) => {
                            if (!multiple) {
                                let file = event.target.files[0];
                                if (onFileChange && onFileChange instanceof Function) {
                                    onFileChange(file);
                                }
                            } else {
                                if (onFileChange && onFileChange instanceof Function) {
                                    const arr = Array.from(event.target.files);
                                    if (!!maxNumFiles && arr.length > maxNumFiles) {
                                        setError(`Maximum number of files is ${maxNumFiles}`);
                                        return;
                                    } else {
                                        setError('');
                                        onFileChange(Array.from(event.target.files));
                                    }
                                }
                            }
                        }}
                    />
                    <Button
                        id={text ? `${text.replaceAll(' ', '')}_fileUpload` : 'ChooseFile_fileUpload'}
                        variant="contained"
                        color="primary"
                        disabled={disabledProp || disabled}
                        onClick={(e) => {
                            inputEl.current.click();
                        }}
                    >
                        {text || 'Choose File'}
                        {maxNumFiles && ` (Max ${maxNumFiles})`}
                    </Button>
                    <span style={{ paddingLeft: '20px', cursor: 'pointer' }}>
                        {!file && 'No file selected'}
                        {!!file && file instanceof File && `${file.name}`}
                        {!!file && file instanceof Array && (
                            <div>
                                <FileListDisplay fileList={file} onFileChange={onFileChange} />
                            </div>
                        )}
                    </span>
                    {!!error && <div style={{ color: 'red' }}>{error}</div>}
                </label>
            }
            {((!!file && !multiple) || (multiple && Array.isArray(file) && file.length > 0)) && (
                <DeleteIcon
                    id={`${text.replaceAll(' ', '')}_removeFile`}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    color="primary"
                    onClick={() => {
                        inputEl.current.value = null;
                        if (onFileChange && onFileChange instanceof Function) {
                            if (multiple) {
                                onFileChange([]);
                            } else {
                                onFileChange(null);
                            }
                            setError('');
                        }
                    }}
                />
            )}
        </Grid>
    );
};

const FileListDisplay = ({ fileList, onFileChange }) => {
    return (
        <div>
            {fileList.map((file, ind) => (
                <div key={`${ind}`}>
                    {file.name}
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            let tmp = fileList.slice();
                            tmp.splice(ind, 1);
                            onFileChange(tmp);
                        }}
                    >
                        Remove
                    </Button>
                </div>
            ))}
        </div>
    );
};

export { FileUpload };
