import React, { useState, useEffect } from 'react';
import {
    Button,
    Grid,
    TextField,
    Paper,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Tabs,
    Tab,
    Chip,
} from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';
import { Notifications, DataTable, TabPanel, FormComponent } from '../../../shared';
import { productionApi as api } from '../../../api/production.js';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

let SellItemCell = ({ row, column }) => {
    let green = '#ecfdf0';
    let red = '#fbe9eb';
    let prevWeekValue = row.original.prevWeekValues[column.id];
    if (prevWeekValue && prevWeekValue !== row.original[column.id]) {
        return (
            <div>
                <div style={{ backgroundColor: red }}>
                    <s>{prevWeekValue}</s>
                </div>
                <div style={{ backgroundColor: green }}>{row.original[column.id]}</div>
            </div>
        );
    }
    if (!prevWeekValue) {
        return (
            <div>
                <div style={{ backgroundColor: green }}>{row.original[column.id]}</div>
            </div>
        );
    }
    return row.original[column.id];
};

let SellableItemUpload = ({ packGroupID, onSuccess }) => {
    let [uploadResults, setUploadResults] = useState(null);
    let [toolbarActions, setToolbarActions] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [confirming, setConfirming] = useState(false);

    let formFields = [
        { name: 'sellItemFile', inputElement: 'file', accept: ['.csv'], label: 'Sellable Item File', gridValue: 12 },
    ];
    let columns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'External sku', accessor: 'externalSku' },
        { Header: 'Name', id: 'name', Cell: SellItemCell },
        { Header: 'Portion Name', id: 'portionName', Cell: SellItemCell },
        { Header: 'Price', id: 'price', Cell: SellItemCell },
        { Header: 'New', id: 'newItemFlag', Cell: SellItemCell },
        { Header: 'Sample', id: 'sampleItemFlag', Cell: SellItemCell },
        { Header: 'Gift', id: 'giftItemFlag', Cell: SellItemCell },
        { Header: 'Exit Sku', id: 'exitSkuFlag', Cell: SellItemCell },
        { Header: 'Best Seller', id: 'bestSellerFlag', Cell: SellItemCell },
        { Header: 'On Sale', id: 'onSaleFlag', Cell: SellItemCell },
        { Header: 'Mischief Pick', id: 'pickMischief', Cell: SellItemCell },
        { Header: 'Max Quantity', id: 'maxQuantity', Cell: SellItemCell },
        { Header: 'Sourcing Reason', id: 'sourcingReason', Cell: SellItemCell },
        { Header: 'Country of Origin(s)', id: 'countryOfOriginNames', Cell: SellItemCell },
        { Header: 'L0 Rank', accessor: 'level0AisleRank' },
        { Header: 'L1 Rank', accessor: 'level1AisleRank' },
        { Header: 'L2 Rank', accessor: 'level2AisleRank' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let errorColumns = [
        { Header: 'Row', accessor: 'rowNumber' },
        { Header: 'External sku', accessor: 'externalSku' },
        { Header: 'Errors', accessor: 'errors' },
    ];

    let confirmUpload = async (key) => {
        setConfirming(true);
        if (!key) {
            setNotification({ text: 'No key provided for sellable item upload', level: 'error' });
            return;
        }

        let res = await api.setSellableItems(key);
        setConfirming(false);
        if (!res.status) {
            setNotification({ text: 'Failed to create sellable items', level: 'error' });
            return;
        }

        setNotification({ text: 'Sellable items created!', level: 'success' });
        setUploadResults(null);
        setToolbarActions([]);
        if (onSuccess instanceof Function) {
            onSuccess();
        }
    };

    let uploadSellableItems = async (formData) => {
        let res = await api.uploadSellableItems(packGroupID, formData);
        if (!res.status) {
            setNotification({ text: 'Failed to get results from sellable item upload. ' + res.msg, level: 'error' });
            return;
        }
        let rowsWithErrors = [];
        let data = res.data;
        let results = res.data.results.map((row) => {
            row.errors = row.errors.join('. ');
            row.warnings = row.warnings.join('. ');
            row.countryOfOriginNames = row.countryOfOriginNames.join(', ');
            row.errors.length && rowsWithErrors.push(row);
            return row;
        });
        data.results = results;
        data.rowsWithErrors = rowsWithErrors;

        let actions = [
            {
                name: 'Clear File',
                action: () => {
                    setToolbarActions([]);
                    setUploadResults(null);
                },
            },
        ];
        if (!data.error && !data.rowsWithErrors.length) {
            actions.push({
                name: 'Confirm Changes',
                action: () => {
                    let key = data.key;
                    confirmUpload(key);
                },
                disabled: confirming,
            });
        }

        setUploadResults(data);
        setToolbarActions(actions);
    };

    return (
        <div>
            <Notifications options={notification} />
            <h3>Upload Sellable Items</h3>
            {!uploadResults && (
                <>
                    <Button
                        onClick={() => {
                            let csvContent =
                                'data:text/csv;charset=utf-8,External Sku,Name,Portion Name,Price,New (Y/N),';
                            csvContent += 'Sample (Y/N),On Sale(Y/N),Mischief Pick,Max Quantity,';
                            csvContent += 'Level 0 Aisle Rank,Level 1 Aisle Rank,Level 2 Aisle Rank';
                            csvContent += ',Gift (Y/N),Exit Sku (Y/N),Best Seller (Y/N),Sourcing Reason';
                            csvContent +=
                                ',Country of Origin 1*,Country of Origin 2,Country of Origin 3,Hide Sku (Y/N)';
                            var encodedUri = encodeURI(csvContent);
                            var link = document.createElement('a');
                            link.setAttribute('href', encodedUri);
                            link.setAttribute('download', 'sellable_item_upload_template.csv');
                            document.body.appendChild(link);

                            link.click();
                        }}
                    >
                        Download Template
                    </Button>
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData, resetForm) => {
                            await uploadSellableItems(formData);
                        }}
                    />
                </>
            )}
            {!!uploadResults && (
                <>
                    {uploadResults.error ? (
                        <>
                            <Alert severity="warning">
                                There are errors in the uploaded file. Please review and upload corrected csv.
                            </Alert>
                            <DataTable
                                columns={errorColumns}
                                data={uploadResults.rowsWithErrors}
                                csvExport={true}
                                csvExportHeadersUseAccessor={true}
                                csvExportFileName={`sellable_item_upload_errors_pg-${packGroupID}`}
                            />
                        </>
                    ) : (
                        <Alert severity="success">Please review and confirm changes.</Alert>
                    )}
                    <DataTable
                        columns={columns}
                        toolbarActions={toolbarActions}
                        data={uploadResults.results}
                        rowStyle={(row) => {
                            if (row.errors.length) {
                                return { color: 'red', border: '3px solid red' };
                            } else if (row.warnings.length) {
                                return { color: '#FFB300', border: '3px solid #FFB300' };
                            }
                            return {};
                        }}
                    />
                </>
            )}
        </div>
    );
};

export { SellableItemUpload };
