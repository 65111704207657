const deliveryReasons = [
    { value: 'DELIVERY_PRIMARY_MISSING_ORDER', text: 'Missing Order' },
    { value: 'DELIVERY_PRIMARY_WRONG_ORDER', text: 'Wrong Order' },
    { value: 'DELIVERY_PRIMARY_QUALITY_ARRIVED_DAMAGED', text: 'Arrived Damaged' },
    { value: 'DELIVERY_PRIMARY_UNWANTED_ORDER', text: 'Unwanted Order' },
    { value: 'DELIVERY_PRIMARY_SHORTS', text: 'Shorts' },
    { value: 'DELIVERY_PRIMARY_OTHER', text: 'Other' },
];

const deliveryReasonsSecondaryMissingOrder = [
    {
        value: 'DELIVERY_SECONDARY_MISSING_ORDER_BOX_DELIVERED_TO_WRONG_ADDRESS',
        text: 'Box Delivered To Wrong Address',
    },
    {
        value: 'DELIVERY_SECONDARY_MISSING_ORDER_BOX_NOT_RECEIVED_CUST_FAULT_OR_STOLEN',
        text: 'Box Not Received: Cust. Fault or Stolen',
    },
    {
        value: 'DELIVERY_SECONDARY_MISSING_ORDER_CANT_LOCATE_BOX_NO_OR_POOR_PHOTO',
        text: "Can't Locate Box (No or Poor Photo)",
    },
    {
        value: 'DELIVERY_SECONDARY_MISSING_ORDER_DAMAGED_IN_TRANSIT_DISCARDED_BY_CARRIER',
        text: 'Damaged in Transit, Discarded by Carrier',
    },
    { value: 'DELIVERY_SECONDARY_MISSING_ORDER_DELIVERY_FAILED', text: 'Delivery Failed' },
    { value: 'DELIVERY_SECONDARY_MISSING_ORDER_DIDNT_LEAVE_WAREHOUSE', text: "Didn't Leave Warehouse" },
    { value: 'DELIVERY_SECONDARY_MISSING_ORDER_EXPORT_FAILED', text: 'Export Failed' },
    {
        value: 'DELIVERY_SECONDARY_MISSING_ORDER_INSTRUCTIONS_NOT_FOLLOWED_BOX_NOT_RECEIVED',
        text: 'Instructions Not Followed Box Not Received',
    },
    { value: 'DELIVERY_SECONDARY_MISSING_ORDER_NOT_SCANNED_IN_BY_CARRIER', text: 'Not Scanned In By Carrier' },
    { value: 'DELIVERY_SECONDARY_MISSING_ORDER_REDELIVERY_FAILED', text: 'Redelivery Failed' },
];

const deliveryReasonsSecondaryWrongOrder = [
    { value: 'DELIVERY_SECONDARY_WRONG_ORDER', text: 'Wrong Box Delivered To Customer' },
];

const deliveryReasonsSecondaryQuality = [
    { value: 'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_BOX_ARRIVED_OPEN', text: 'Box Arrived Open' },
    { value: 'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_BROKEN_GLASS', text: 'Broken Glass' },
    { value: 'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_FROZEN', text: 'Frozen' },
    { value: 'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_ICE_PACK_BROKEN', text: 'Ice Pack Broken' },
    {
        value: 'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_INSULATION_PACKAGING_PROBLEM',
        text: 'Insulation/Packaging Problem',
    },
    {
        value: 'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_PRODUCT_SPILL_BROKEN_GROCERY_PACKAGING',
        text: 'Product Spill/Broken Grocery Packaging',
    },
    { value: 'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_TOO_LONG_IN_TRANSIT', text: 'Too Long In Transit' },
];

const deliveryReasonsSecondaryUnwantedOrder = [
    { value: 'DELIVERY_SECONDARY_UNWANTED_ORDER_PAST_CANCEL_CUTOFF', text: 'Past Cancel Cutoff' },
    { value: 'DELIVERY_SECONDARY_UNWANTED_ORDER_SUBSCRIPTION_CONFUSION', text: 'Subscription Confusion' },
    {
        value: 'DELIVERY_SECONDARY_UNWANTED_ORDER_TECHNICAL_ISSUES_SYSTEM_ERROR',
        text: 'Technical Issue/System Error',
    },
    { value: 'DELIVERY_SECONDARY_UNWANTED_ORDER_THOUGHT_THEY_SKIPPED', text: 'Thought They Skipped' },
];

const deliveryReasonsSecondaryShorts = [
    { value: 'DELIVERY_SECONDARY_SHORTS_TOO_MANY_SHORTS', text: 'Too many shorts' },
];

const productReasons = [
    { value: 'PRODUCT_PRIMARY_MISSING', text: 'Missing' },
    { value: 'PRODUCT_PRIMARY_DAMAGED', text: 'Damaged' },
    { value: 'PRODUCT_PRIMARY_PACKAGING_ISSUE', text: 'Packaging Issue' },
    { value: 'PRODUCT_PRIMARY_ROTTEN_SPOILED', text: 'Rotten or Spoiled' },
    { value: 'PRODUCT_PRIMARY_UNHAPPY_WITH_ITEM', text: 'Unhappy with Item' },
    { value: 'PRODUCT_PRIMARY_UNWANTED_ITEM', text: 'Unwanted Item' },
    { value: 'PRODUCT_PRIMARY_FOOD_SAFETY', text: 'Food Safety' },
    { value: 'PRODUCT_PRIMARY_OTHER', text: 'Other' },
];

const productReasonsSecondaryMissing = [
    { value: 'PRODUCT_SECONDARY_MISSING_INCORRECT_ITEM_SENT', text: 'Incorrect Item Sent' },
    { value: 'PRODUCT_SECONDARY_MISSING_ITEM', text: 'Missing Item' },
    {
        value: 'PRODUCT_SECONDARY_MISSING_WHOLE_BOX_ONE_OR_MORE_MULTIPLE_BOXES',
        text: 'Missing Whole Box / Missing 1 or More of Multiple Boxes',
    },
    { value: 'PRODUCT_SECONDARY_MISSING_QUANTITY', text: 'Missing Quantity' },
    { value: 'PRODUCT_SECONDARY_MISSING_UNDER_COUNT', text: 'Under Count' },
    { value: 'PRODUCT_SECONDARY_MISSING_UNDER_WEIGHT', text: 'Under Weight' },
];

const productReasonsSecondaryDamaged = [
    { value: 'PRODUCT_SECONDARY_DAMAGED_BROKEN', text: 'Broken' },
    { value: 'PRODUCT_SECONDARY_DAMAGED_BROKEN_EGGS', text: 'Broken Eggs' },
    { value: 'PRODUCT_SECONDARY_DAMAGED_BROKEN_GLASS', text: 'Broken Glass' },
    { value: 'PRODUCT_SECONDARY_DAMAGED_BRUISED', text: 'Bruised' },
    { value: 'PRODUCT_SECONDARY_DAMAGED_DAMAGED_BY_ANOTHER_PRODUCT', text: 'Damaged By Another Product' },
];

const productReasonsSecondaryPackagingIssue = [
    { value: 'PRODUCT_SECONDARY_PACKAGING_FROZEN_DUE_TO_ICE_PACK', text: 'Frozen - Due to Ice Pack' },
    { value: 'PRODUCT_SECONDARY_PACKAGING_FROZEN_DUE_TO_WEATHER', text: 'Frozen - Due to Weather' },
    { value: 'PRODUCT_SECONDARY_PACKAGING_HEAT_DAMAGE_DUE_TO_WEATHER', text: 'Heat Damage - Due to Weather' },
    { value: 'PRODUCT_SECONDARY_PACKAGING_HEAT_DAMAGE_MISSING_FOIL_BAG', text: 'Heat Damage - Missing Foil Bag' },
    { value: 'PRODUCT_SECONDARY_PACKAGING_HEAT_DAMAGE_MISSING_ICE_PACK', text: 'Heat Damage - Missing Ice Pack' },
    { value: 'PRODUCT_SECONDARY_PACKAGING_ICE_PACK_BROKEN', text: 'Ice Pack Broken' },
];

const productReasonsSecondaryRottenOrSpoiled = [
    { value: 'PRODUCT_SECONDARY_ROTTEN_SPOILED_BUGS_PESTS', text: 'Bugs / Pests' },
    { value: 'PRODUCT_SECONDARY_ROTTEN_SPOILED_EXPIRED_PRODUCT', text: 'Expired Product' },
    { value: 'PRODUCT_SECONDARY_ROTTEN_SPOILED_MOLDY_AFTER_3_DAYS', text: 'Moldy After 3 Days' },
    { value: 'PRODUCT_SECONDARY_ROTTEN_SPOILED_MOLDY_UPON_DELIVERY', text: 'Moldy Upon Delivery' },
    { value: 'PRODUCT_SECONDARY_ROTTEN_SPOILED_OVERRIPE_DAY_OF_DELIVERY', text: 'Overripe Day of Delivery' },
    { value: 'PRODUCT_SECONDARY_ROTTEN_SPOILED_ROTTEN_PRODUCE', text: 'Rotten Produce' },
    { value: 'PRODUCT_SECONDARY_ROTTEN_SPOILED_WILTED', text: 'Wilted' },
];

const productReasonsSecondaryUnhappyWithItem = [
    { value: 'PRODUCT_SECONDARY_UNHAPPY_WITH_ITEM_POOR_TASTE_QUALITY', text: 'Poor Taste / Quality' },
    { value: 'PRODUCT_SECONDARY_UNHAPPY_WITH_ITEM_SHORT_SHELF_LIFE', text: 'Short Shelf Life' },
    { value: 'PRODUCT_SECONDARY_UNHAPPY_WITH_ITEM_PORTION_SIZE', text: 'Unhappy with Portion / Size' },
    { value: 'PRODUCT_SECONDARY_UNHAPPY_WITH_ITEM_PURCHASED_PRODUCT', text: 'Unhappy with Purchased Product' },
];

const productReasonsSecondaryUnwantedItem = [
    {
        value: 'PRODUCT_SECONDARY_UNWANTED_ITEM_ALWAYS_LIST_OR_ADD_ON_COMPLAINT',
        text: 'Always List Or Add On Complaint',
    },
    { value: 'PRODUCT_SECONDARY_UNWANTED_ITEM_FORGOT_TO_CUSTOMIZE', text: 'Forgot To Customize' },
    { value: 'PRODUCT_SECONDARY_UNWANTED_ITEM_PRODUCT_NOT_AS_DESCRIBED', text: 'Product Not As Described' },
    { value: 'PRODUCT_SECONDARY_UNWANTED_ITEM_SUBSTITUTION_COMPLAINT', text: 'Substitution Complaint' },
];

const productReasonsSecondaryFoodSafety = [
    { value: 'PRODUCT_SECONDARY_FOOD_SAFETY_FOOD_POISONING', text: 'Food Poisoning' },
    { value: 'PRODUCT_SECONDARY_FOOD_SAFETY_FOREIGN_OBJECT_IN_BOX', text: 'Foreign Object in Box' },
    { value: 'PRODUCT_SECONDARY_FOOD_SAFETY_PRODUCT_RECALL', text: 'Product Recall' },
];

const deliveryReasonsMap = {
    DELIVERY_PRIMARY_MISSING_ORDER: deliveryReasonsSecondaryMissingOrder,
    DELIVERY_PRIMARY_WRONG_ORDER: deliveryReasonsSecondaryWrongOrder,
    DELIVERY_PRIMARY_QUALITY_ARRIVED_DAMAGED: deliveryReasonsSecondaryQuality,
    DELIVERY_PRIMARY_UNWANTED_ORDER: deliveryReasonsSecondaryUnwantedOrder,
    DELIVERY_PRIMARY_SHORTS: deliveryReasonsSecondaryShorts,
    DELIVERY_PRIMARY_OTHER: [],
};

const productReasonsMap = {
    PRODUCT_PRIMARY_MISSING: productReasonsSecondaryMissing,
    PRODUCT_PRIMARY_DAMAGED: productReasonsSecondaryDamaged,
    PRODUCT_PRIMARY_PACKAGING_ISSUE: productReasonsSecondaryPackagingIssue,
    PRODUCT_PRIMARY_ROTTEN_SPOILED: productReasonsSecondaryRottenOrSpoiled,
    PRODUCT_PRIMARY_UNHAPPY_WITH_ITEM: productReasonsSecondaryUnhappyWithItem,
    PRODUCT_PRIMARY_UNWANTED_ITEM: productReasonsSecondaryUnwantedItem,
    PRODUCT_PRIMARY_FOOD_SAFETY: productReasonsSecondaryFoodSafety,
    PRODUCT_PRIMARY_OTHER: [],
};

export { deliveryReasons, deliveryReasonsMap, productReasons, productReasonsMap };
