import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { TabPanel } from '../../../shared';
import { CreateCreditRequest } from './createCreditRequest';
import { ViewCreditRequests } from './viewCreditRequests';
import { warehouseApi } from '../../../api/warehouse';
import { procurementApi as api } from '../../../api/procurement';

const CreditsTracking = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    const [reasons, setReasons] = useState([]);
    const [statuses, setStatuses] = useState([]);
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    const getReasons = async () => {
        let res = await api.getCreditRequestReasons();
        if (res.status === false) {
            return;
        }

        setReasons(res.data.rows);
    };

    const getStatuses = async () => {
        let res = await api.getCreditRequestAccountingStatuses();
        if (res.status === false) {
            return;
        }

        setStatuses(res.data.rows);
    };

    useEffect(() => {
        getReasons();
        getStatuses();
    }, []);

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewCreditRequest_Tab" label="View Credit Requests" value="0" />
                <Tab id="CreateCreditRequest_Tab" label="Create Credit Request" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewCreditRequests accountingStatusOpts={statuses} reasons={reasons} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateCreditRequest accountingStatusOpts={statuses} reasons={reasons} />
            </TabPanel>
        </Paper>
    );
};

export { CreditsTracking };
