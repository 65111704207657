import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DataTable, Notifications, TabPanel, FormComponent } from '../../../shared';
import { shippingApi } from '../../../api/shipping';
import { customerApi } from '../../../api/customer';
import { UserContext } from '../../../contexts/UserContext';

let EditAddress = ({ editing, setEditing, addressInfo, setNotification, currentShipID, getFailedShipments }) => {
    const updateAddress = async (sendParams) => {
        let res = await shippingApi.updateShipmentAddress(currentShipID, sendParams);
        if (res.status === true) {
            setNotification({ text: 'Address Updated!', level: 'success' });
            getFailedShipments();
        } else {
            setNotification({ text: 'Failed to update address', level: 'error' });
        }
    };

    return (
        <Dialog open={editing} onClose={() => setEditing(false)}>
            <DialogContent>
                <FormComponent
                    formFields={[
                        {
                            name: 'firstName',
                            label: 'First Name',
                            initialValue: addressInfo?.firstName ? addressInfo.firstName : '',
                        },
                        {
                            name: 'lastName',
                            label: 'Last Name',
                            initialValue: addressInfo?.lastName ? addressInfo.lastName : '',
                        },
                        {
                            name: 'lineOne',
                            label: 'Address Line 1',
                            initialValue: addressInfo?.lineOne ? addressInfo.lineOne : '',
                        },
                        {
                            name: 'lineTwo',
                            label: 'Address Line 2',
                            initialValue: addressInfo?.lineTwo ? addressInfo.lineTwo : '',
                        },
                    ]}
                    onSubmit={(formData) => {
                        let addrModel = {
                            firstName: addressInfo.firstName,
                            lastName: addressInfo.lastName,
                            lineOne: formData.lineOne,
                            lineTwo: formData.lineTwo,
                            country: addressInfo.country,
                            city: addressInfo.city,
                            province: addressInfo.province,
                            zip: addressInfo.zip,
                            phone: addressInfo.phone,
                            validationStatus: addressInfo.validationStatus,
                        };

                        updateAddress(addrModel);
                        setEditing(false);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let FailedToPurchase = () => {
    const [rows, setRows] = useState([]);
    const [editing, setEditing] = useState(false);
    const [addressInfo, setAddressInfo] = useState();
    const [currentShipID, setCurrentShipID] = useState();
    const [notification, setNotification] = useState({ text: '', level: null });
    const user = useContext(UserContext);
    const [facilityID, setFacilityID] = useState(user.getFacilityID());

    let getFailedShipments = async () => {
        let result = await shippingApi.getFailedShipments(facilityID);
        if (result.status === true) {
            setRows(result.data.shipments);
        }
    };

    let purchaseShipment = async (id) => {
        let res = await shippingApi.purchaseShipment(id);
        if (res.data && res.data.purchased === true) {
            getFailedShipments();
            setNotification({ text: 'Label purchased!', level: 'success' });
        } else {
            setNotification({ text: 'Failed to purchase shipment', level: 'error' });
        }
    };

    let cancelAndRefundByShipment = async (chargeID, shipmentID) => {
        let sendParams = { reason: 'SOFTWARE_ISSUE', secondary: 'SOFTWARE_ISSUE' };
        let res = await customerApi.cancelAndRefundByShipment(chargeID, shipmentID, sendParams);
        if (res.status === true) {
            getFailedShipments();
            setNotification({ text: 'Shipment cancelled and refunded', level: 'success' });
        } else {
            setNotification({ text: 'Failed to cancel shipment', level: 'error' });
        }
    };

    let getAddressInfo = async (addrID) => {
        let res = await shippingApi.getShipmentAddress(addrID);
        if (res.status === false) {
            setNotification({ text: 'Failed get shipment address info', level: 'error' });
        }
        setAddressInfo(res.data.model);
    };

    useEffect(() => {
        getFailedShipments();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'source', Header: 'Order Source' },
        { accessor: 'chargeStatus', Header: 'Charge Status' },
        { accessor: 'createdAt', Header: 'Created Date' },
        { accessor: 'shopifyOrderNumber', Header: 'Order Number' },
        {
            accessor: 'purchase',
            Header: 'Purchase',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                let [purchasing, setPurchasing] = useState(false);
                return (
                    <Button
                        disabled={purchasing}
                        onClick={async () => {
                            setPurchasing(true);
                            await purchaseShipment(row.original.id);
                            setPurchasing(false);
                        }}
                    >
                        Purchase
                    </Button>
                );
            },
        },
        {
            accessor: 'cancel',
            Header: 'Cancel',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                let [cancelling, setCancelling] = useState(false);
                return (
                    <Button
                        disabled={cancelling}
                        onClick={async () => {
                            if (
                                window.confirm(`Cancel and Refund order number ${row.original.shopifyOrderNumber}?`) ===
                                true
                            ) {
                                setCancelling(true);
                                await cancelAndRefundByShipment(row.original.chargeID, row.original.id);
                                setCancelling(false);
                            }
                        }}
                    >
                        Cancel/Refund
                    </Button>
                );
            },
        },
        {
            accessor: 'edit',
            Header: 'Edit Address',
            Cell: ({ row }) => {
                return (
                    <Button
                        disabled={editing}
                        onClick={async () => {
                            await getAddressInfo(row.original.addressID);
                            setCurrentShipID(row.original.id);
                            setEditing(true);
                        }}
                    >
                        Edit
                    </Button>
                );
            },
        },
    ];
    return (
        <div>
            <Notifications options={notification} />
            <EditAddress
                editing={editing}
                setEditing={setEditing}
                addressInfo={addressInfo}
                setNotification={setNotification}
                currentShipID={currentShipID}
                getFailedShipments={getFailedShipments}
            />
            <DataTable title="Failed To Purchase" columns={columns} data={rows} editable={false} />
        </div>
    );
};

export { FailedToPurchase };
