import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

const pricingApi = {
    getSellableItemPrices: async (packGroupID) => {
        let url = `api/customize/v1/zonePricing/prices?packGroupID=${packGroupID}`;
        return mmFetchWithResponse(url);
    },
    uploadSellableItemPrices: async (packGroupID, pricingZoneID, data) => {
        let formData = new FormData();
        formData.append('file', data.file);
        formData.append('packGroupID', packGroupID);
        formData.append('pricingZoneID', pricingZoneID);

        let url = `api/customize/v1/zonePricing/prices/upload`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    confirmSellableItemPrices: async (key) => {
        let url = 'api/customize/v1/zonePricing/prices/confirm';
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    getPricingZones: async () => {
        let url = 'api/customize/v1/zonePricing/zones';
        return mmFetchWithResponse(url);
    },
};

export { pricingApi };
