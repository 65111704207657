import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { shippingApi } from '../../../api/shipping';
import { systemApi as api } from '../../../api/system';
import { Button, Grid, InputLabel, CircularProgress } from '@material-ui/core';
import moment from 'moment';

const ORDER_NUMBER = 'orderNumber';
const SHIPMENT_ID = 'shipmentID';
const IMPERFECT_ORDER_ID = 'imperfectOrderID';
const DBI_SCAN_STRING = 'dbiScanStr';

let OrderDeepDive = () => {
    let [shipmentRow, setShipmentRow] = useState([]);
    let [itemsRow, setItemsRow] = useState([]);
    let [pickRow, setPickRow] = useState([]);
    let [eventRow, setEventRow] = useState([]);
    let [proteinBagRow, setProteinBagRow] = useState([]);
    let [numShipments, setNumShipments] = useState(0);
    let [totalCube, setTotalCube] = useState(0);
    let [orderNum, setOrderNum] = useState(0);
    let [boxTypes, setBoxTypes] = useState('');
    let [cubeOnExec, setCubeOnExec] = useState('');
    let [carrier, setCarrier] = useState('');
    let [hub, setHub] = useState('');
    let [source, setSource] = useState('');
    let [notes, setNotes] = useState('');
    let [deliveryDay, setDeliveryDay] = useState('');
    let [partnerOrderNumber, setPartnerOrderNum] = useState('');
    let [notification, setNotification] = useState({ text: null, level: null });

    const queryParameters = new URLSearchParams(window.location.search);
    const orderNumber = queryParameters.get(ORDER_NUMBER);
    const shipmentID = queryParameters.get(SHIPMENT_ID);
    const imperfectOrderID = queryParameters.get(IMPERFECT_ORDER_ID);
    const dbiScanString = queryParameters.get(DBI_SCAN_STRING);

    const defaultSearchType = orderNumber
        ? ORDER_NUMBER
        : shipmentID
        ? SHIPMENT_ID
        : imperfectOrderID
        ? IMPERFECT_ORDER_ID
        : dbiScanString
        ? DBI_SCAN_STRING
        : ORDER_NUMBER;

    const defaultSearchValue = orderNumber
        ? orderNumber
        : shipmentID
        ? shipmentID
        : imperfectOrderID
        ? imperfectOrderID
        : dbiScanString
        ? dbiScanString
        : '';

    let [searchBy, setSearchBy] = useState(defaultSearchType);

    useEffect(() => {
        if (defaultSearchValue != '') {
            setSearchBy(defaultSearchType);
            loadData(defaultSearchValue);
        }
    }, []);

    let shipmentColumn = [
        { accessor: 'id', Header: 'Shipment ID' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'boxType', Header: 'Box' },
        { accessor: 'numIcePacks', Header: 'Num Ice Packs' },
        { accessor: 'cubeOnExec', Header: 'Cube (On Exec)' },
        { accessor: 'packaging', Header: 'Insulation' },
        {
            accessor: 'download',
            Header: carrier == 'dbi' ? 'DBI Label' : 'License Plate',
            Cell: ({ cell: { value: initialValue }, row }) => {
                const [downloading, setDownloading] = useState(false);
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        {downloading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                id={'Download'}
                                onClick={async () => {
                                    setDownloading(true);
                                    await shippingApi.getShipmentLabelPDF(row.original.id);
                                    setDownloading(false);
                                }}
                            >
                                Download
                            </Button>
                        )}
                    </div>
                );
            },
        },
        {
            accessor: 'bringgTaskUrl',
            Header: 'Bringg Task',
            Cell: ({ row }) => {
                const url = row.original.bringgTaskUrl;
                if (url) {
                    return (
                        <a href={url} target="_blank">
                            Bringg Task
                        </a>
                    );
                }
                return null;
            },
        },
        {
            accessor: 'trackingUrl',
            Header: 'Tracking',
            Cell: ({ cell: { value: initialValue }, row }) => {
                const trackingUrl = row.original.trackingUrl;
                if (trackingUrl) {
                    return (
                        <a href={row.original.trackingUrl} target="_blank" style={{ wordBreak: 'break-all' }}>
                            {row.original.trackingUrl}
                        </a>
                    );
                }
                return null;
            },
        },
        {
            accessor: 'inductionPoint',
            Header: 'Induction Point',
        },
    ];

    let itemsColumn = [
        { accessor: 'orderedOn', Header: 'Order On (UTC' + moment().format('Z') + ')' },
        { accessor: 'sellableItemID', Header: 'Sellable Item ID' },
        { accessor: 'lotNumber', Header: 'Lot Number' },
        { accessor: 'sellableItemName', Header: 'Sellable Item Name' },
        { accessor: 'packGroup', Header: 'Pack Group' },
        { accessor: 'amount', Header: 'Amount' },
        { accessor: 'shipmentID', Header: 'Shipment ID' },
        { accessor: 'cube', Header: 'Cube' },
    ];

    let pickColumn = [
        { accessor: 'pickedAt', Header: 'Picked at (UTC' + moment().format('Z') + ')' },
        { accessor: 'lineNumber', Header: 'Pack Line' },
        { accessor: 'facilityID', Header: 'Facility ID' },
        { accessor: 'sellableItemID', Header: 'Sellable Item ID' },
        { accessor: 'shipmentID', Header: 'Shipment ID' },
        { accessor: 'picker', Header: 'Picker' },
    ];

    let eventsColumn = [
        { accessor: 'timestamp', Header: 'Timestamp (UTC' + moment().format('Z') + ')' },
        { accessor: 'scanType', Header: 'Scan Type' },
        { accessor: 'n1', Header: 'Details' },
        { accessor: 'message', Header: 'Message' },
        { accessor: 'city', Header: 'City' },
        { accessor: 'lineNumber', Header: 'Pack Line' },
        { accessor: 'shipmentID', Header: 'Shipment ID' },
    ];

    let proteinBagColumn = [
        { accessor: 'shipmentID', Header: 'Shipment ID' },
        { accessor: 'packagingName', Header: 'Packaging Name' },
        { accessor: 'internalCubeValue', Header: 'Internal Cube Value' },
        { accessor: 'externalCubeValue', Header: 'External Cube Value' },
        { accessor: 'icePackOz', Header: 'Ice Pack Oz' },
        { accessor: 'avgHeatIndex', Header: 'Avg Heat Index' },
        { accessor: 'ozIceMultiplier', Header: 'Multiplier' },
    ];

    let searchByOptions = [
        { value: ORDER_NUMBER, text: 'Order Number' },
        { value: SHIPMENT_ID, text: 'Shipment ID' },
        { value: IMPERFECT_ORDER_ID, text: 'Imperfect Order ID' },
        { value: DBI_SCAN_STRING, text: 'DBI Scan String' },
    ];

    let formFields = [
        {
            name: 'searchBy',
            inputElement: 'select',
            initialValue: defaultSearchType,
            gridValue: 3,
            inputProps: {
                required: true,
                opts: searchByOptions,
                onChange: (event, value) => {
                    setSearchBy(value);
                },
            },
        },
        {
            name: 'searchTerm',
            label: ' ',
            initialValue: defaultSearchValue,
            inputElement: 'textField',
            gridValue: 8,
            dependencies: ['searchBy'],
        },
    ];

    let loadDataBySearch = async (id) => {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(ORDER_NUMBER);
        searchParams.delete(SHIPMENT_ID);
        searchParams.delete(IMPERFECT_ORDER_ID);
        searchParams.delete(DBI_SCAN_STRING);
        searchParams.set(searchBy, id);
        window.location.search = searchParams.toString();
    };

    // Load the page
    let loadData = async (id) => {
        if (searchBy == ORDER_NUMBER) {
            var response = await api.orderDeepDive(id);
            if (response.status === false) {
                setNotification({ text: 'Error retrieving order ' + response.msg, level: 'error' });
                return;
            }
        } else if (searchBy == SHIPMENT_ID) {
            var response = await api.shipmentDeepDive(id);
            if (response.status === false) {
                setNotification({ text: 'Error retrieving order ' + response.msg, level: 'error' });
                return;
            }
        } else if (searchBy == IMPERFECT_ORDER_ID) {
            var response = await api.imperfectDeepDive(id);
            if (response.status === false) {
                setNotification({ text: 'Error retrieving order ' + response.msg, level: 'error' });
                return;
            }
        } else if (searchBy == DBI_SCAN_STRING) {
            var response = await api.dbiScanStrDeepDive(id);
            if (response.status === false) {
                setNotification({ text: 'Error retrieving order ' + response.msg, level: 'error' });
                return;
            }
        } else {
            setNotification({ text: 'Error retrieving order. Contact engineering', level: 'error' });
            return;
        }

        //null values for 'n1' get sent to the FE as zeros
        //reset them to blank
        response.data.data.events.forEach((ev) => {
            if (ev.n1 === 0) {
                ev.n1 = '';
            }
        });

        let picksArr = response.data.data.pickLog.map((item) => {
            item.pickedAt = moment(item.pickedAt).local().format('MM/DD/YYYY h:mm a');
            return item;
        });

        let eventsArr = response.data.data.events.map((item) => {
            item.timestamp = moment(item.timestamp).local().format('MM/DD/YYYY h:mm a');
            return item;
        });

        let itemsArr = response.data.data.items.map((item) => {
            item.orderedOn = moment(item.orderedOn).local().format('MM/DD/YYYY h:mm a');
            return item;
        });

        setNumShipments(response.data.data.numBoxes);
        setOrderNum(response.data.data.orderNum);
        setItemsRow(itemsArr);
        setPickRow(picksArr);
        setEventRow(eventsArr);
        setTotalCube(response.data.data.cube);
        setBoxTypes(response.data.data.boxTypes);
        setCubeOnExec(response.data.data.cubeOnExec);
        setProteinBagRow(response.data.data.proteinBags);
        setCarrier(response.data.data.carrier);
        setHub(response.data.data.hub);
        setNotes(response.data.data.notes);
        setShipmentRow(response.data.data.shipmentInfo);
        setSource(response.data.data.source);
        setDeliveryDay(response.data.data.deliveryDay);
        setPartnerOrderNum(response.data.data.partnerOrderNumber);
    };

    return (
        <div>
            <h1>Order Deep Dive</h1>
            <Notifications options={notification} />
            {}
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        loadDataBySearch(formData.searchTerm);
                    }}
                />
                <br />
                <br />
                <h2>Summary Information</h2>
                <Grid container>
                    <Grid item xs={3}>
                        <InputLabel>Number of shipments</InputLabel>
                        {numShipments}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Cube (as cubed now)</InputLabel>
                        {totalCube}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Order number</InputLabel>
                        {orderNum}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Delivery Day</InputLabel>
                        {deliveryDay}
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xs={3}>
                        <InputLabel>Notes</InputLabel>
                        {notes}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Carrier</InputLabel>
                        {carrier}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Hub</InputLabel>
                        {hub}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Source</InputLabel>
                        {partnerOrderNumber !== '' ? (
                            <a
                                href={`/lettuce/fbm/orders?source=${source}&orderNum=${encodeURIComponent(
                                    partnerOrderNumber
                                )}`}
                            >
                                {source}
                            </a>
                        ) : (
                            <span>{source}</span>
                        )}
                    </Grid>
                </Grid>
                <br />
                <h2>Shipment Information</h2>
                <DataTable columns={shipmentColumn} data={shipmentRow} />
                <br />
                <h2>Items ordered</h2>
                <DataTable columns={itemsColumn} data={itemsRow} />
                <br />
                <h2>Pick Log</h2>
                <DataTable columns={pickColumn} data={pickRow} />
                <br />
                <h2>Events Log</h2>
                <DataTable columns={eventsColumn} data={eventRow} />
                <h2>Cold Keeper Bags</h2>
                <DataTable columns={proteinBagColumn} data={proteinBagRow} />
            </div>
        </div>
    );
};

export { OrderDeepDive };
