import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { Button } from '@material-ui/core';
import { packagingApi as api } from '../../../api/packaging';

let AddPackagingItems = ({ setNotification, setFormField, formData, initialValue }) => {
    let [packagingOptions, setPackagingOptions] = useState([]);

    let [currentPackagingSku, setCurrentPackagingSku] = useState(null);
    let [autoPopUnitsPerCase, setAutoPopUnitsPerCase] = useState(null);
    let [autoPopItemQty, setAutoPopItemQty] = useState(null);

    let initValue = [];
    if (initialValue && initialValue.additionalData) {
        initValue = initialValue.additionalData.map((item) => {
            item.id = item.packagingID;
            return item;
        });
    }
    let [itemsInPO, setItemsInPO] = useState(initValue);

    let clearItems = () => {
        setItemsInPO([]);
    };

    useEffect(() => {
        // reset form (after submit) clears formData.itemsInPO, but does not reset internal itemsInPO
        // we need to do this manually when the form data has been cleared and there is no initial value
        if (itemsInPO.length && !formData.itemsInPO && !initValue.length) {
            clearItems();
        }
    }, [formData]);

    let getPackaging = async () => {
        let itemMasterRes = await api.getPackaging();
        if (itemMasterRes.status === false) {
            return;
        } else {
            let items = itemMasterRes.data.rows.map((item) => {
                return { text: `SKU ${item.model.masterSKU}: ${item.model.description}`, value: item.model };
            });
            setPackagingOptions(items);
        }
    };

    // Add item submit function
    let addItemSubmit = async (formData) => {
        let item = {};
        item.id = formData.packagingSKU.id;
        item.description = formData.packagingSKU.description;
        item.masterSku = formData.packagingSKU.masterSKU;
        item.qty = formData.itemQty;
        item.unitPrice = formData.itemUnitPrice;
        item.numPallets = formData.numPallets;
        item.unitsPerCase = formData.unitsPerCase;
        item.unitsPerCaseUOM = formData.unitsPerCaseUOM;
        item.vendorSku = formData.itemVendorSKU;
        let items = itemsInPO.concat(item);
        setItemsInPO(items);
    };
    // Add item submit function

    // Remove an item from the PO
    let removeItemPressed = async (row) => {
        var array = [...itemsInPO];
        array.splice(row.index, 1);
        setItemsInPO(array);
    };

    useEffect(() => {
        getPackaging();
        if (initialValue.length) {
            setFormField(initialValue);
        }
    }, []);

    useEffect(() => {
        setFormField(itemsInPO);
    }, [itemsInPO]);

    let numPalletsOnChange = (csPallet, numPallets, fromData) => {
        csPallet = parseInt(csPallet);
        if (csPallet <= 0) return;

        if (!numPallets) return;
        numPallets = parseInt(numPallets);
        if (numPallets <= 0) return;

        let itemQty = numPallets * csPallet;

        if (!fromData.itemQty || parseInt(fromData.itemQty) == autoPopItemQty) {
            fromData.itemQty = itemQty;
        }

        setAutoPopItemQty(itemQty);
    };

    let packagingSKUOnChange = (value, formData) => {
        if (!value) {
            setCurrentPackagingSku(null);
        } else {
            setCurrentPackagingSku(value.value);

            if (!formData.unitsPerCase || parseInt(formData.unitsPerCase) == parseInt(autoPopUnitsPerCase)) {
                formData.unitsPerCase = value.value.unitsPerCase;
            }

            numPalletsOnChange(value.value.csPallet, formData.numPallets, formData);

            setAutoPopUnitsPerCase(value.value.unitsPerCase);
        }
    };

    let packagingFormFields = [
        {
            name: 'packagingSKU',
            inputElement: 'autocomplete',
            label: 'Packaging',
            gridValue: 3,
            inputProps: {
                required: true,
                onChange: (e, value, formData) => packagingSKUOnChange(value, formData),
                opts: packagingOptions,
            },
        },
        {
            name: 'numPallets',
            inputElement: 'textField',
            label: 'Pallets per SKU',
            gridValue: 1,
            inputProps: {
                type: 'number',
                onChange: (e, fromData) => {
                    if (!currentPackagingSku) return;
                    numPalletsOnChange(currentPackagingSku.csPallet, e.target.value, fromData);
                },
                required: true,
            },
        },
        {
            name: 'itemQty',
            inputElement: 'textField',
            label: 'Quantity (cases)',
            gridValue: 2,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'itemUnitPrice',
            inputElement: 'textField',
            label: 'Unit Price',
            gridValue: 2,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'unitsPerCase',
            inputElement: 'textField',
            label: 'Units per Case',
            gridValue: 1,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'unitsPerCaseUOM',
            inputElement: 'select',
            label: 'Unit Of Measure',
            gridValue: 1,
            inputProps: { opts: ['ct'], required: true },
        },
        {
            name: 'itemVendorSKU',
            inputElement: 'textField',
            label: 'Vendor SKU',
            gridValue: 2,
            inputProps: { required: true },
        },
    ];

    let itemsInPOColumns = [
        { accessor: 'masterSku', Header: 'Item Master SKU' },
        { accessor: 'description', Header: 'Description' },
        {
            accessor: 'qty',
            Header: 'Quantity',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        { accessor: 'vendorSku', Header: 'Vendor SKU' },
        { accessor: 'unitPrice', Header: 'Unit Price' },
        {
            accessor: 'numPallets',
            Header: 'Pallets per SKU',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'unitsPerCase',
            Header: 'Units Per Case',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        { accessor: 'unitsPerCaseUOM', Header: 'Unit Of Measure' },
        {
            Header: 'Total',
            Cell: ({ row }) => {
                if (row.original.qty) {
                    return `$${row.original.unitPrice * row.original.qty}`;
                } else {
                    return '';
                }
            },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h3>Items In PO</h3>
            <FormComponent
                formFields={packagingFormFields}
                onSubmit={async (formData, resetForm) => {
                    addItemSubmit(formData);
                    resetForm();
                }}
                button={{
                    text: 'Add',
                    gridValue: 1,
                    disabled: (formData) => {
                        if (!formData.packagingSKU) {
                            return true;
                        }
                        return false;
                    },
                }}
            />
            <DataTable
                columns={itemsInPOColumns}
                data={itemsInPO}
                editable={true}
                saveEdit={({ id, field, value, row }) => {
                    let items = JSON.parse(JSON.stringify(itemsInPO)).map((item) => {
                        if (item.id === id) {
                            item[field] = value;
                        }
                        return item;
                    });
                    setItemsInPO(items);
                }}
            />
        </div>
    );
};

export { AddPackagingItems };
