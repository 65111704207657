import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { CreateSpecialOrder } from '../components/createSpecialOrder.js';
import { SpecialOrders } from '../../../shared/components/specialOrders/specialOrders.js';
import { TabPanel } from '../../../shared';

const SpecialOrdersMarketing = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewSpecialOrders_Tab" label="View Special Orders" value="0" />
                <Tab id="CreateSpecialOrder_Tab" label="Create Special Order" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <SpecialOrders editable={true} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateSpecialOrder />
            </TabPanel>
        </Paper>
    );
};

export { SpecialOrdersMarketing };
