import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';

const DrinksRefundItemDetails = ({ formData, refundableItems, setFormField, setItemsSelected }) => {
    let itemsToMap = refundableItems?.length > 0 ? refundableItems : null;
    let arrForItemsSelected = [];

    let getItemsForSelect = (arr) => {
        if (arr.length < 1) {
            return setItemsSelected(null);
        }

        return arr.map((item) => {
            let tmp = {};
            let findItem = refundableItems.find((obj) => obj.drinksOrderDetailsModel.id === item.id);
            if (findItem) {
                tmp.name = findItem.sellableItemModel.name;
                tmp.price = Number(findItem.drinksOrderDetailsModel.dollarAmount);
                tmp.qty = item.qty;
                tmp.tax =
                    ((Number(findItem.drinksOrderDetailsModel.taxes) -
                        Number(findItem.drinksOrderDetailsModel.taxesRefunded)) /
                        findItem.drinksOrderDetailsModel.quantity) *
                    item.qty;
                tmp.alcoholFee =
                    ((Number(findItem.drinksOrderDetailsModel.alcoholFee) -
                        Number(findItem.drinksOrderDetailsModel.alcoholFeeRefunded)) /
                        findItem.drinksOrderDetailsModel.quantity) *
                    item.qty;
                arrForItemsSelected.push(tmp);
                tmp = {};
            }
            setItemsSelected(arrForItemsSelected);
        });
    };

    if (itemsToMap === null) {
        return <div></div>;
    }

    return (
        <div>
            <h2>Select Items</h2>
            {itemsToMap.map((item) => {
                let qty = 0;
                let affectedItem = formData.itemsAffected.find((obj) => obj.id === item.drinksOrderDetailsModel.id);

                if (affectedItem) {
                    qty = affectedItem.qty;
                }

                return (
                    <SelectedItem
                        item={item}
                        key={item.drinksOrderDetailsModel.id}
                        quantity={qty}
                        setQuantity={(value) => {
                            let tmp = {};

                            formData.itemsAffected.forEach((item) => {
                                tmp[item.id] = item.qty;
                            });

                            if (Number(value) === 0) {
                                delete tmp[item.drinksOrderDetailsModel.id];
                            } else if (Number(value) > 0) {
                                tmp[item.drinksOrderDetailsModel.id] = value;
                            }

                            let arr = Object.keys(tmp).map((key) => ({ id: Number(key), qty: tmp[key] }));
                            setFormField(arr);
                            getItemsForSelect(arr);
                        }}
                    />
                );
            })}
        </div>
    );
};

const SelectedItem = ({ item, quantity, setQuantity }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '3px' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '-webkit-fill-available',
                    paddingRight: '20px',
                }}
            >
                <span>
                    {item.sellableItemModel.name}&nbsp; - ${Number(item.drinksOrderDetailsModel.dollarAmount)} (Tax: $
                    {Number(item.drinksOrderDetailsModel.taxes) <= 0
                        ? 0
                        : Number(item.drinksOrderDetailsModel.taxes).toFixed(2)}
                    ) &nbsp;(Alcohol Fee: $
                    {Number(item.drinksOrderDetailsModel.alcoholFee) <= 0
                        ? 0
                        : Number(item.drinksOrderDetailsModel.alcoholFee).toFixed(2)}
                    )
                </span>
                {quantity > 0 && <span>x{quantity}</span>}
            </div>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                    style={{
                        background: '#4050B5',
                        color: '#FFFFFF',
                        minWidth: '24px',
                        height: '24px',
                        margin: '0 10px',
                        fontSize: 'xx-large',
                        borderRadius: '0',
                    }}
                    onClick={() => {
                        let tmp = Number(quantity);
                        if (tmp > 0) {
                            setQuantity(tmp - 1);
                        }
                    }}
                >
                    -
                </Button>
                <TextField
                    inputProps={{ disableUnderline: true }}
                    InputProps={{ disableUnderline: true }}
                    readOnly
                    disabled
                    max={'' + item.drinksOrderDetailsModel.quantity}
                    value={quantity}
                    min="0"
                    style={{
                        background: '#F7F7F7',
                        borderRadius: '8px',
                        width: '34px',
                        height: '34px',
                        textAlignLast: 'center',
                    }}
                />
                <Button
                    style={{
                        background: '#4050B5',
                        color: '#FFFFFF',
                        minWidth: '24px',
                        height: '24px',
                        margin: '0 10px',
                        fontSize: 'x-large',
                        borderRadius: '0',
                    }}
                    onClick={() => {
                        let tmp = Number(quantity);
                        if (tmp < item.drinksOrderDetailsModel.quantity) {
                            setQuantity(tmp + 1);
                        }
                    }}
                >
                    {' '}
                    +{' '}
                </Button>
            </div>
        </div>
    );
};

export { DrinksRefundItemDetails };
