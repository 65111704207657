import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Select, NativeSelect } from '@material-ui/core';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { packagingApi as api } from '../../../api/packaging';
import { CreatePackagingPO } from './createPurchaseOrder';
import { UserContext } from '../../../contexts/UserContext';
import { AddPackagingItems } from './addPackagingItems';

const ViewItemsTable = ({ parentRow, setExpandedRow }) => {
    let [poID, setPOID] = useState(parentRow.original.id);
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [addItemModalOpen, setAddItemModalOpen] = useState(false);
    let [packagingOptions, setPackagingOptions] = useState([]);

    let getPackagingOptions = async () => {
        let itemMasterRes = await api.getPackaging();
        if (itemMasterRes.status === false) {
            return;
        } else {
            let items = itemMasterRes.data.rows.map((item) => {
                return { text: `SKU ${item.model.masterSKU}: ${item.model.description}`, value: item.model.id };
            });
            setPackagingOptions(items);
        }
    };

    let columns = [
        { accessor: 'masterSku', Header: 'Item SKU', editable: true },
        { accessor: 'description', Header: 'Description' },
        {
            accessor: 'unitsPerCase',
            Header: 'Units Per Case',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        { accessor: 'unitsPerCaseUOM', Header: 'Unit of Measure' },
        {
            accessor: 'numPallets',
            Header: 'Pallets per SKU',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'qty',
            Header: 'Quantity',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'vendorSku',
            Header: 'Vendor SKU',
            editable: true,
            editProps: { type: 'input', inputType: 'text' },
        },
        {
            accessor: 'unitPrice',
            Header: 'Unit Price',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'estimatedFreightCost',
            Header: 'Estimated Freight Cost',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        onClick={async () => {
                            let response = await api.removeItemFromPO(poID, row.original.id);

                            if (response.status === false) {
                                setNotification({ text: "Can't remove item " + response.msg, level: 'error' });
                                return;
                            }

                            getItemsInTable();
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    let getItemsInTable = async () => {
        let response = await api.getPurchaseOrderDetails(poID);

        if (response.status === false) {
            setNotification({ text: 'No items found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        let poDetails = response.data.output;

        // Need to add id which will populate in saveEdit
        setRows(
            poDetails.items.map((row) => {
                row.id = row.poMapID;
                row.unitPrice = parseFloat(row.unitPrice).toFixed(2);
                return row;
            })
        );
    };

    useEffect(() => {
        getItemsInTable();
        getPackagingOptions();
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            {addItemModalOpen && (
                <AddItemModal
                    poID={poID}
                    packagingOptions={packagingOptions}
                    closeModal={() => {
                        setAddItemModalOpen(false);
                        getItemsInTable();
                    }}
                />
            )}
            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let updates = { [field]: value };
                    let response = await api.updatePurchaseOrderMap(poID, id, updates);
                    if (response.status === false) {
                        let message = 'Error modifying packaging purchase order';
                        if (response) {
                            message += ': ' + response.msg;
                        }
                        setNotification({ text: message, level: 'error' });
                    } else {
                        setNotification({ text: 'Packaging Purchase Order modified!', level: 'success' });
                    }
                    if (response.status === true && field === 'masterSku') {
                        getItemsInTable();
                    } else {
                        return response.status;
                    }
                }}
            />
            <Button
                onClick={() => {
                    setAddItemModalOpen(true);
                }}
            >
                Add Item
            </Button>
        </div>
    );
};

const AddItemModal = ({ poID, closeModal, packagingOptions }) => {
    let [error, setError] = useState(null);

    let packagingFormFields = [
        {
            name: 'id',
            inputElement: 'autocomplete',
            label: 'Packaging',
            gridValue: 8,
            inputProps: { required: true, opts: packagingOptions },
        },
        {
            name: 'qty',
            inputElement: 'textField',
            label: 'Quantity (cases)',
            gridValue: 2,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'unitPrice',
            inputElement: 'textField',
            label: 'Unit Price',
            gridValue: 2,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'unitsPerCase',
            inputElement: 'textField',
            label: 'Units Per Case',
            gridValue: 3,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'unitsPerCaseUOM',
            inputElement: 'select',
            label: 'Unit Of Measure',
            gridValue: 3,
            inputProps: { opts: ['ct'], required: true },
        },
        {
            name: 'vendorSku',
            inputElement: 'textField',
            label: 'Vendor SKU',
            gridValue: 4,
            inputProps: { required: true },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={packagingFormFields}
                    onSubmit={async (formData) => {
                        let items = {};
                        items['items'] = [formData];

                        let response = await api.addItemToPurchaseOrder(poID, items);
                        if (response.status === false) {
                            let message = 'Error adding item';
                            if (response.msg) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                            return;
                        }

                        closeModal();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const EditDeliveryModal = ({ rowData, closeModal }) => {
    let [error, setError] = useState(null);

    let date = moment(rowData.scheduledDeliveryDate).format('YYYY-MM-DD');
    let time = moment(rowData.scheduledDeliveryDate).format('HH:mm');

    let formFields = [
        {
            name: 'poID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'Purchase Order ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'scheduledDeliveryDate',
            initialValue: date,
            inputElement: 'date',
            disableTextInput: true,
            label: 'Scheduled Delivery Date',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'scheduledTime',
            initialValue: time,
            inputElement: 'time',
            label: 'Delivery time',
            gridValue: 4,
            inputProps: { required: true },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['scheduledDeliveryDate'] = formData.scheduledDeliveryDate;
                        item['deliverTime'] = formData.scheduledTime;

                        let response = await api.updatePurchaseOrder(formData.poID, item);
                        if (response.status === false) {
                            let message = 'Error modifying purchase order';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                        } else {
                            closeModal();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

let ViewPackagingPO = () => {
    let [rows, setRows] = useState([]);
    let [editDeliveryModalOpen, setEditDeliveryModalOpen] = useState(false);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [selectedRow, setSelectedRow] = useState({});
    const user = useContext(UserContext);

    // Load
    let getRows = async () => {
        let response = await api.getPurchaseOrders('', user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No POs found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        setRows(response.data.rows);
    };

    useEffect(() => {
        getRows();
    }, []);

    let closeDeliveryEditModal = () => {
        setEditDeliveryModalOpen(false);
    };

    let showDeliveryEditModal = (row, closeDeliveryEditModal) => {
        setSelectedRow(row);
        setEditDeliveryModalOpen(true);
    };

    let columns = [
        { accessor: 'id', Header: 'ID' },
        {
            accessor: 'scheduledDeliveryDate.date',
            Header: 'Scheduled Delivery',
            editable: true,
            customEdit: showDeliveryEditModal,
        },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'vendorName', Header: 'Vendor' },
        { accessor: 'facilityName', Header: 'Facility' },
        { accessor: 'referenceNumber', Header: 'Reference', editable: true },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                const [downloadOpt, setDownloadOpt] = useState('pdf');
                const downloadOptions = ['pdf', 'ship-list'];
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <NativeSelect
                            id={`Download_Select_${row.id}`}
                            className="input-element-select"
                            value={downloadOpt}
                            label="Download"
                            onChange={(event) => {
                                setDownloadOpt(event.target.value);
                            }}
                        >
                            {downloadOptions.map((value, i) => {
                                return (
                                    <option key={i} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                            ;
                        </NativeSelect>
                        <Button
                            id={`Download_${row.id}`}
                            onClick={() => {
                                if (downloadOpt === 'pdf') {
                                    api.downloadPackagingPOPDF(row.original.id, row.original.vendorName);
                                } else if (downloadOpt === 'ship-list') {
                                    api.downloadPackagingPOShipList(row.original.id);
                                } else {
                                    console.log('Unrecognized download option ' + downloadOpt);
                                }
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'send',
            Header: 'Send To Vendor',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Send_${row.id}`}
                            onClick={async () => {
                                let response = await api.sendPurchaseOrderToVendor(row.original.id);
                                if (response.status === false) {
                                    setNotification({ text: response.msg, level: 'error' });
                                    return;
                                }
                                setNotification({ text: 'Sent Packaging PO to Vendor', level: 'success' });
                            }}
                        >
                            SEND
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'cancel',
            Header: 'Cancel',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Cancel_${row.id}`}
                            onClick={async () => {
                                let response = await api.cancelPurchaseOrder(row.original.id);
                                if (response.status === false) {
                                    setNotification({ text: 'Failed to cancel PO ' + response.msg, level: 'error' });
                                    return;
                                }
                                setNotification({ text: 'Purchase Order cancelled', level: 'success' });
                                await getRows();
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <h1>Packaging Purchase Orders</h1>
            <Notifications options={notification} />
            {}
            <div>
                {editDeliveryModalOpen && (
                    <EditDeliveryModal
                        rowData={selectedRow}
                        closeModal={() => {
                            setEditDeliveryModalOpen(false);
                            getRows();
                        }}
                    />
                )}
                <DataTable
                    columns={columns}
                    data={rows}
                    expandable={true}
                    ExpansionComponent={<ViewItemsTable />}
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        let item = {};
                        item[field] = value;
                        if (field == 'vendorName') {
                            item['vendorID'] = value;
                        }
                        let response = await api.updatePurchaseOrder(id, item);
                        if (response.status === false) {
                            let message = 'Error modifying purchase order';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Purchase Order modified!', level: 'success' });
                            await getRows();
                        }
                        return response.status;
                    }}
                    duplicateRowSettings={{
                        enable: true,
                        CustomFormComponent: <CreatePackagingPO />,
                        fetchAdditionalData: async (row) => {
                            let response = await api.getPurchaseOrderDetails(row.id);

                            if (!response.status) {
                                setNotification({ text: 'Failed to get items in purchase order', level: 'error' });
                                return [];
                            }

                            let poDetails = response.data.output;

                            let details = poDetails.items.map((row) => {
                                row.unitPrice = parseFloat(row.unitPrice).toFixed(2);
                                return row;
                            });
                            return details;
                        },
                        onSuccess: () => {
                            getRows();
                            setNotification({ text: 'Packaging Purchase Order Created', level: 'success' });
                        },
                    }}
                />
            </div>
        </div>
    );
};

export { ViewPackagingPO };
