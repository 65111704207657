import React, { useState, useEffect } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { partnershipsApi as api } from '../../../api/partnerships';
import { Grid, Dialog, DialogTitle, DialogContent, Paper } from '@material-ui/core';

let Drinks = () => {
    const [drinksItems, setDrinksItems] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [addedItems, setAddedItems] = useState(null);
    const [addingNewItems, setAddingNewItems] = useState(false);

    useEffect(() => {
        getDrinksItems();
    }, []);

    const drinksColumns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Active', accessor: 'active', type: 'checkbox', editable: true },
        { Header: 'Drinks Sku', accessor: 'drinksSku' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'MM Sweetness', accessor: 'mmSweetness', editable: true },
        { Header: 'MM Pairing Notes', accessor: 'mmPairingNotes', editable: true },
        {
            Header: 'MM Price',
            accessor: 'mmPrice',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        { Header: 'Alcoholic', accessor: 'isAlcoholic', type: 'checkbox' },
        { Header: 'Price', accessor: 'price' },
        { Header: 'Bottle Count', accessor: 'bottleCount' },
        { Header: 'Volume', accessor: 'volume' },
        { Header: 'UPC', accessor: 'upc' },
        { Header: 'Brand', accessor: 'brand' },
        { Header: 'Winery', accessor: 'winery' },
        { Header: 'Color', accessor: 'color' },
        { Header: 'Varietal', accessor: 'varietal' },
        { Header: 'Vintage', accessor: 'vintage' },
        { Header: 'ABV', accessor: 'abv' },
        { Header: 'Country', accessor: 'country' },
        { Header: 'Region', accessor: 'region' },
        { Header: 'Producer', accessor: 'producer' },
        { Header: 'Sweetness', accessor: 'sweetness' },
        { Header: 'Category', accessor: 'wineCategory' },
    ];

    const getDrinksItems = async () => {
        let res = await api.getDrinksItems();
        if (!res.status || !res.data) {
            setNotification({ text: 'Failed to get drinks.com items', level: 'error' });
            return;
        }

        setDrinksItems(res.data.items);
    };

    const addNewItems = async () => {
        setAddingNewItems(true);
        const res = await api.getNewDrinksItems();
        setAddingNewItems(false);
        if (!res.status || !res.data) {
            setNotification({ text: 'Failed to pull new items from drinks.com', level: 'error' });
            return;
        }
        setAddedItems(res.data.items);
    };

    const toolbarActions = [
        {
            name: 'Get New Items',
            action: () => {
                addNewItems();
            },
        },
    ];

    return (
        <Paper>
            <Grid container>
                <Notifications options={notification} />
                <NewItemsModal
                    open={!!addedItems}
                    closeModal={() => {
                        setAddedItems(null);
                        getDrinksItems();
                    }}
                    results={addedItems}
                />
                <DataTable
                    title="Drinks Items"
                    toolbarActions={!addingNewItems ? toolbarActions : []}
                    columns={drinksColumns}
                    data={drinksItems}
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        let sendParams = { [field]: value };
                        let res = await api.updateDrinksItem(id, sendParams);
                        if (!res.status) {
                            setNotification({ level: 'error', text: `Failed to update item: ${res.msg}` });
                            return false;
                        }
                        getDrinksItems();
                        return true;
                    }}
                />
            </Grid>
        </Paper>
    );
};

const NewItemsModal = ({ results, closeModal, open }) => {
    const columns = [
        { Header: 'Name', accessor: 'name' },
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Drinks Sku', accessor: 'drinksSku' },
    ];

    if (!results) {
        return null;
    }

    return (
        <Dialog open={open} onClose={closeModal} maxWidth="lg" fullWidth={true}>
            <DialogTitle>New Item Results</DialogTitle>
            <DialogContent>
                <h4>New Items</h4>
                {results.length > 0 ? <DataTable columns={columns} data={results} /> : <div>No new items created</div>}
            </DialogContent>
        </Dialog>
    );
};

export { Drinks };
