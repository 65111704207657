import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const SearchResults = ({ searchResults, setCustomerID }) => {
    const useStyles = makeStyles((theme) => ({
        header: {
            display: 'grid',
            fontWeight: 'bold',
            padding: '0 15px',
            gridTemplateColumns: '100px 200px 1fr',
            '@media (min-width: 780px)': {
                gridTemplateColumns: '150px 300px 1fr',
            },
        },
        card: {
            marginTop: '10px',
            backgroundColor: '#fff',
            borderRadius: '5px',
            boxShadow: '0px 0px 25px rgba(45, 45, 45, 0.1)!important',
            cursor: 'pointer',
            padding: '15px',
            display: 'grid',
            gridTemplateColumns: '100px 200px 1fr',
            '@media (min-width: 780px)': {
                gridTemplateColumns: '150px 300px 1fr',
            },
        },
        tablePadding: {
            paddingRight: '10px',
            textAlign: 'left',
            margin: '0',
            fontSize: '14px',
            '@media (min-width: 780px)': {
                fontSize: '16px',
            },
        },
    }));

    const classes = useStyles();

    if (!searchResults.length) {
        return <div>No results</div>;
    }

    return (
        <div>
            <div className={classes.header}>
                <p className={classes.tablePadding}>Customer ID</p>
                <p className={classes.tablePadding}>Name</p>
                <p className={classes.tablePadding}>Email</p>
            </div>
            {searchResults.map((item) => {
                return (
                    <div
                        key={item.customerID}
                        className={classes.card}
                        onClick={() => {
                            setCustomerID(item.customerID);
                        }}
                    >
                        <p className={classes.tablePadding}>{item.customerID}</p>
                        <p className={classes.tablePadding}>
                            {item.firstName} {item.lastName}
                        </p>
                        <p className={classes.tablePadding}>{item.email}</p>
                    </div>
                );
            })}
        </div>
    );
};

export { SearchResults };
