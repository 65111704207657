import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { Button } from '@material-ui/core';
import { packagingApi as api } from '../../../api/packaging';
import { procurementApi as procurementApi } from '../../../api/procurement';
import { warehouseApi as warehouseApi } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';
import { AddPackagingItems } from './addPackagingItems.js';

let BulkCreatePackagingPO = ({ initialValues = {}, onSuccess }) => {
    let [error, setError] = useState(null);
    let [vendors, setVendors] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const user = useContext(UserContext);

    // Master form submit function
    let createPurchaseOrder = async (formData, resetForm) => {
        let error = false;
        let items = formData.itemsInPO.map((item) => {
            if (!item.id || !item.qty || !item.unitPrice || !item.vendorSku) {
                setNotification({ level: 'error', text: 'All item fields required' });
                error = true;
            }
            return {
                id: item.id,
                qty: item.qty,
                unitPrice: item.unitPrice,
                vendorSku: item.vendorSku,
                numPallets: item.numPallets,
                unitsPerCase: item.unitsPerCase,
                unitsPerCaseUOM: item.unitsPerCaseUOM,
            };
        });

        if (error) {
            return false;
        }

        for (let i = 0; i < formData.posToCreate.length; i++) {
            let sendParams = {
                vendorID: formData.vendor,
                deliverDate: formData.posToCreate[i].scheduledDeliveryDate,
                deliverTime: formData.posToCreate[i].scheduledTime,
                referenceNumber: formData.posToCreate[i].referenceNumber,
                facilityID: user.getFacilityID(),
                items: items,
            };

            let response = await api.createPurchaseOrder(sendParams);
            if (response.status === false) {
                let message = 'Error creating purchase order';
                if (response) {
                    message += ': ' + response.msg;
                }
                setNotification({ text: message, level: 'error' });
                error = true;
                break;
            }
        }

        if (error === false) {
            setNotification({ text: 'Purchase Order(s) created!', level: 'success' });
            resetForm();
            return true;
        }

        return false;
    };

    // Load vendors
    let getVendors = async () => {
        let response = await procurementApi.getVendors();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setVendors([]);
            return;
        }

        let vendorAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setVendors(vendorAsOpts);
    };

    useEffect(() => {
        getVendors();
    }, []);

    let formFields = [
        {
            name: 'vendor',
            initialValue: initialValues.vendorID
                ? { text: initialValues.vendorName, value: initialValues.vendorID }
                : undefined,
            inputElement: 'autocomplete',
            label: 'Vendor',
            gridValue: 10,
            inputProps: { required: true, opts: vendors },
        },
        {
            name: 'facility',
            inputElement: 'textField',
            label: 'Facility',
            gridValue: 2,
            initialValue: user.getFacilityName(),
            inputProps: { required: true, readOnly: true },
        },
        {
            name: 'itemsInPO',
            label: 'Items',
            inputElement: ({ formData, setFormField }) => (
                <AddPackagingItems
                    setNotification={setNotification}
                    initialValue={initialValues}
                    formData={formData}
                    setFormField={setFormField}
                />
            ),
        },
        {
            name: 'posToCreate',
            label: 'Purchase Orders',
            inputElement: ({ formData, setFormField }) => (
                <AddPackagingPORows setNotification={setNotification} formData={formData} setFormField={setFormField} />
            ),
        },
    ];

    return (
        <div>
            <h1>Bulk Create Purchase Orders</h1>
            <Notifications options={notification} />
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    createPurchaseOrder(formData, resetForm);
                }}
            />
        </div>
    );
};

let AddPackagingPORows = ({ setNotification, setFormField, formData }) => {
    let [itemsToCreate, setItemsToCreate] = useState([]);

    let clearItems = () => {
        setItemsToCreate([]);
    };

    // Add item submit function
    let addItemSubmit = async (formData) => {
        let item = {};
        item.scheduledDeliveryDate = formData.scheduledDeliveryDate;
        item.scheduledTime = formData.scheduledTime;
        item.referenceNumber = formData.referenceNumber;
        let items = itemsToCreate.concat(item);
        setItemsToCreate(items);
    };

    // Remove an item from the PO
    let removeItemPressed = async (row) => {
        var array = [...itemsToCreate];
        array.splice(row.index, 1);
        setItemsToCreate(array);
    };

    useEffect(() => {
        setFormField(itemsToCreate);
    }, [itemsToCreate]);

    let packagingFormFields = [
        {
            name: 'scheduledDeliveryDate',
            inputElement: 'date',
            label: 'Scheduled Delivery Date',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'scheduledTime',
            inputElement: 'time',
            label: 'Scheduled delivery time',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'referenceNumber',
            inputElement: 'textField',
            label: 'Reference Number',
            gridValue: 2,
            inputProps: { required: true },
        },
    ];

    let poCols = [
        { accessor: 'scheduledDeliveryDate', Header: 'Scheduled Delivery Date' },
        { accessor: 'scheduledTime', Header: 'Scheduled delivery time' },
        { accessor: 'referenceNumber', Header: 'Reference Number' },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h3>POs to Create</h3>
            <FormComponent
                formFields={packagingFormFields}
                onSubmit={async (formData, resetForm) => {
                    addItemSubmit(formData);
                    resetForm();
                }}
                button={{ text: 'Add', gridValue: 1 }}
            />
            <DataTable
                columns={poCols}
                data={itemsToCreate}
                editable={true}
                saveEdit={({ id, field, value, row }) => {
                    let items = JSON.parse(JSON.stringify(itemsToCreate)).map((item) => {
                        if (item.id === id) {
                            item[field] = value;
                        }
                        return item;
                    });
                    setItemsToCreate(items);
                }}
            />
        </div>
    );
};

export { BulkCreatePackagingPO };
