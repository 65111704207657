import React, { useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';

const ExpandableNotice = ({ message, expandedNotices = [], initialExpandedValue = false }) => {
    const [expanded, setExpanded] = useState(initialExpandedValue);
    return (
        <div className="fam-warning-container">
            <div className="fam-warning-item-one">
                <WarningIcon className="fam-warning-icon" />
                <span className="fam-warning-text grotesk-bold">{message}</span>
            </div>
            <div className="fam-warning-item-two">
                {expandedNotices.length > 0 && (
                    <div
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                        className="fam-warning-expand-button grotesk-regular"
                    >
                        {expanded ? 'Collapse' : 'Expand'}
                    </div>
                )}
            </div>
            <div>
                {expanded && (
                    <div>
                        <ul>
                            {expandedNotices.map((notice) => (
                                <li key={notice} className="fam-warning-text grotesk-regular">
                                    {notice}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export { ExpandableNotice };
