import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Drawer,
    CssBaseline,
    AppBar,
    Toolbar,
    List,
    Divider,
    Typography,
    IconButton,
    ListItem,
    ListItemText,
    Collapse,
    Button,
    TextField,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';
import { api } from '../../api/api';
import './nav-bar.css';
import { UserContext } from '../../contexts/UserContext';

const drawerWidth = 240;

function NavDrawer() {
    const theme = useTheme();
    const [backgroundColor, setBackgroundColor] = useState(theme.palette.primary.main);

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            color: theme.palette.bone.main,
            fontWeight: 'bold',
            backgroundColor: backgroundColor,
        },
        divider: {
            backgroundColor: theme.palette.bone.main,
        },
        iconButton: {
            color: theme.palette.bone.main,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
    }));

    const [roles, setRoles] = useState([]);
    const [openPanel, setOpenPanel] = useState(null);
    const [menuSearchVal, setMenuSearchVal] = useState('');
    const [training, setTraining] = useState(false);
    const [open, setOpen] = useState(false);
    const { user, clearUserAndLogOut, setUserAttributes } = useContext(UserContext);

    const classes = useStyles();

    const getRoles = async () => {
        let res = await api.getRoles();
        if (!res.status) {
            clearUserAndLogOut();
            return;
        }
        setTraining(res.data.training);

        // let userData = res?.data?.user || {};
        // setUserAttributes(userData);

        const rolesArray = Object.keys(res.data.roles).map((key) => ({ name: key, ...res.data.roles[key] }));
        setRoles(rolesArray);
    };

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        if (training) {
            setBackgroundColor(theme.palette.tomato.main);
            return;
        }

        let facilityID = user.facilityID;
        if (facilityID === 3) {
            setBackgroundColor(theme.palette.primary.main);
        } else if ([4, 5, 6, 8].includes(facilityID)) {
            setBackgroundColor(theme.palette.ivy.main);
        } else if (facilityID === 7) {
            setBackgroundColor(theme.palette.texas.main);
        } else if (facilityID === 9) {
            setBackgroundColor(theme.palette.black.main);
        } else if (facilityID === 10) {
            setBackgroundColor(theme.palette.chiyellow.main);
        } else if (facilityID === 11) {
            setBackgroundColor(theme.palette.pink.main);
        } else if (facilityID === 12) {
            setBackgroundColor(theme.palette.purple.main);
        } else if (facilityID === 13) {
            setBackgroundColor(theme.palette.teal.main);
        }
    }, [user]);

    useEffect(() => {}, [backgroundColor]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setMenuSearchVal('');
        setOpen(false);
    };

    const filterRoles = (query, roles) => {
        const deepCopyRoles = JSON.parse(JSON.stringify(roles));

        if (!query) {
            return createMenu(deepCopyRoles);
        } else {
            const filteredRoles = deepCopyRoles.filter((role) => {
                if (role && role.name && role.name.toLowerCase().includes(query.toLowerCase())) {
                    return true;
                } else if (role.tabs && role.tabs.length) {
                    const filteredTabs = role.tabs.filter(
                        (tab) => tab && tab.name && tab.name.toLowerCase().includes(query.toLowerCase())
                    );
                    if (filteredTabs.length) {
                        role.tabs = filteredTabs;
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });

            return createMenu(filteredRoles, true);
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar className={classes.appBar} style={{ backgroundColor: backgroundColor }} position="fixed">
                <Toolbar>
                    <IconButton
                        id="MenuButton"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {!open && (
                            <>
                                <Link
                                    id="Header_link"
                                    to={'/'}
                                    className="nav-menu-item"
                                    style={{ display: 'inline-block' }}
                                >
                                    <div>Misfits Market Admin</div>
                                </Link>
                                {training ? (
                                    <span style={{ paddingLeft: '30px' }}>TRAINING - {user.facilityName}</span>
                                ) : (
                                    <span style={{ paddingLeft: '30px' }}>{user.facilityName}</span>
                                )}
                            </>
                        )}
                    </Typography>
                    <span style={{ position: 'absolute', right: '125px' }}>
                        <Link to={'/'} className="nav-menu-item" style={{ display: 'inline-block' }}>
                            <div style={{ paddingTop: '5px', fontSize: '17px' }}>{user.name}</div>
                        </Link>
                    </span>
                    <div id="logout-button">
                        <Button
                            color="inherit"
                            style={{ fontSize: '17px' }}
                            onClick={() => {
                                clearUserAndLogOut();
                            }}
                        >
                            Log Out
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <TextField
                        id="outlined-basic"
                        style={{ backgroundColor: 'white' }}
                        onChange={(e) => {
                            setMenuSearchVal(e.target.value);
                        }}
                        label="Search menu"
                        variant="outlined"
                        value={menuSearchVal}
                    />
                    <IconButton onClick={handleDrawerClose} className={classes.iconButton}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider className={classes.divider} />
                <List>
                    {filterRoles(menuSearchVal, roles).map((item, index) => (
                        <React.Fragment key={index}>
                            {item.linkTo && (
                                <Link
                                    to={item.linkTo}
                                    onClick={() => {
                                        setOpenPanel(null);
                                        handleDrawerClose();
                                    }}
                                    className="nav-menu-item"
                                >
                                    <ListItem button key={item.name}>
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                </Link>
                            )}
                            {item.menu && (
                                <>
                                    <ListItem
                                        id={`${item.name.replaceAll(' ', '')}_tab`}
                                        button
                                        key={item.name}
                                        className="nav-menu-item"
                                        onClick={() => {
                                            if (openPanel === index) {
                                                setOpenPanel(null);
                                            } else {
                                                setOpenPanel(index);
                                            }
                                        }}
                                    >
                                        <ListItemText primary={item.name} />
                                        {openPanel === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </ListItem>
                                    <Collapse in={item.expand || openPanel === index} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding className="nav-menu-item">
                                            {item.menu.map((i) => (
                                                <Link to={i.linkTo} key={i.name}>
                                                    <ListItem
                                                        id={`${i.name.replaceAll(' ', '')}_sub_tab`}
                                                        button
                                                        onClick={() => {
                                                            setOpenPanel(null);
                                                            handleDrawerClose();
                                                        }}
                                                        className="nested-nav-item"
                                                    >
                                                        <ListItemText primary={i.name} />
                                                    </ListItem>
                                                </Link>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </List>
                <Divider className={classes.divider} />
            </Drawer>
            <main>
                <div className={classes.drawerHeader} />
            </main>
        </div>
    );
}

let createMenu = (roles, expandAll = false) => {
    let navMenu = [];

    roles.forEach((item) => {
        let name = item.name;
        let linkTo;
        let menu;
        let expand = expandAll;
        if (item.tabs?.length) {
            menu = item.tabs.map((tab) => ({ name: tab.name, linkTo: `/${tab.location}` }));
        } else if (item.location) {
            linkTo = `/${item.location}`;
        }
        navMenu.push({ linkTo, name, menu, expand });
    });
    return navMenu;
};

export { NavDrawer };
