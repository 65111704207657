import React, { useContext, useState, useEffect } from 'react';
import { Paper, TextField, Button, Select, MenuItem, Grid } from '@material-ui/core';
import { DataTable, Notifications } from '../../../../shared';
import { UserContext } from '../../../../contexts/UserContext';
import { fbmApi as api } from '../../../../api/fbm';
import moment from 'moment';

const ViewOnTimeDelivery = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [reportIsReady, setReportIsReady] = useState(false);
    let [reportButtonText, setReportButtonText] = useState('Loading...');

    const [reportStartDate, setReportStartDate] = React.useState(moment().format('YYYY-MM-DD'));
    let handleReportStartDateChange = (event) => {
        setReportStartDate(event.target.value);
    };

    const [reportEndDate, setReportEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    let handleReportEndDateChange = (event) => {
        setReportEndDate(event.target.value);
    };

    const { fbmAccount } = useContext(UserContext);

    let handleRunReportClick = (event) => {
        getAndRenderReportData();
    };

    let columns = [
        { accessor: 'estimated_delivery_date', Header: 'Est Delv Date', width: 125 },
        { accessor: 'order_id', Header: 'Order ID', width: 100 },
        { accessor: 'shipment_id', Header: 'Shipment ID', width: 125 },
        { accessor: 'tracking_number', Header: 'Tracking #', width: 150 },
        { accessor: 'status', Header: 'Status', width: 100 },
        { accessor: 'facility', Header: 'Facility', width: 100 },
        { accessor: 'carrier', Header: 'Carrier', width: 100 },
        { accessor: 'category', Header: 'Category', width: 150 },
        { accessor: 'line_haul', Header: 'Line Haul', width: 150 },
        { accessor: 'pack_day', Header: 'Pack Day', width: 125 },
        { accessor: 'actual_packed_time', Header: 'Actual Packed Time', width: 175 },
        { accessor: 'shipped_time', Header: 'Shipped Time', width: 175 },
        { accessor: 'injection_day', Header: 'Injection Day', width: 150 },
        { accessor: 'in_transit_time', Header: 'In Transit Time', width: 175 },
        { accessor: 'zip', Header: 'Zip', width: 150 },
    ];

    let getAndRenderReportData = async () => {
        setReportIsReady(false);

        setReportButtonText('Loading...');
        setRows([]);

        let response = await api.getReportOnTimeDeliveryView(fbmAccount.fbmAccountName, reportStartDate, reportEndDate);

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportButtonText('Run Report');
            setReportIsReady(true);
            return;
        }

        setRows(
            response.data.rows.map((item) => {
                item.estimated_delivery_date = item.estimated_delivery_date
                    ? moment(item.estimated_delivery_date).format('YYYY-MM-DD')
                    : '';
                item.pack_day = item.pack_day ? moment(item.pack_day).format('YYYY-MM-DD') : '';
                item.injection_day = item.injection_day ? moment(item.injection_day).format('YYYY-MM-DD') : '';
                item.actual_packed_time = item.actual_packed_time
                    ? moment(item.actual_packed_time).format('YYYY-MM-DD HH:mm:ss')
                    : '';
                item.shipped_time = item.shipped_time ? moment(item.shipped_time).format('MM/DD/YYYY HH:mm:ss') : '';
                item.in_transit_time = item.in_transit_time
                    ? moment(item.in_transit_time).format('YYYY-MM-DD HH:mm:ss')
                    : '';

                return item;
            })
        );

        setReportButtonText('Run Report');
        setReportIsReady(true);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getAndRenderReportData();
        }
    }, [fbmAccount]);

    return (
        <Paper>
            <Notifications options={notification} />

            <Grid container>
                <Grid item xs={12}>
                    <h2>{fbmAccount.companyName} - On Time Delivery Report</h2>

                    <Grid item md={12} style={{ paddingRight: '35px' }}>
                        <TextField
                            id="reportStartDate"
                            label="Start Date"
                            type="date"
                            defaultValue={reportStartDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={!reportIsReady}
                            onChange={handleReportStartDateChange}
                            style={{ marginRight: '50px' }}
                        />

                        <TextField
                            id="reportEndDate"
                            label="End Date"
                            type="date"
                            defaultValue={reportEndDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={!reportIsReady}
                            onChange={handleReportEndDateChange}
                            style={{ marginRight: '50px' }}
                        />

                        <Button
                            id="runReport"
                            onClick={handleRunReportClick}
                            color="secondary"
                            disabled={!reportIsReady}
                        >
                            {reportButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <br />
            <span>Note: The date parameters are applied to the estimated delivery date.</span>

            <DataTable
                csvExport={reportIsReady}
                csvExportFileName="on-time-delivery"
                csvExportHeadersUseAccessor={true}
                columns={columns}
                data={rows}
                editable={false}
            />
        </Paper>
    );
};

export { ViewOnTimeDelivery };
