import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { catalogApi } from '../../../api/catalog';
import { UserContext } from '../../../contexts/UserContext';


const CreateFBMItem = ({tempZones}) => {

    let [notification, setNotification] = useState({ text: null, level: null });
    const { fbmAccount } = useContext(UserContext);

    let formFields = [
        {
            name: 'itemNameInternal',
            inputElement: 'textField',
            label: 'Internal Name',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'thirdPartyItemID',
            inputElement: 'textField',
            label: 'Partner SKU',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'csPallet',
            inputElement: 'textField',
            label: 'Cases per pallet (cs/pallet)',
            gridValue: 3,
            inputProps: { required: true, type: 'number' },
        },
        {
            name: 'lbCs',
            inputElement: 'textField',
            label: 'Pounds per case (lb/cs)',
            gridValue: 3,
            inputProps: { required: true, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'lengthInCs',
            inputElement: 'textField',
            label: 'Case length (in)',
            gridValue: 4,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'widthInCs',
            inputElement: 'textField',
            label: 'Case width (in)',
            gridValue: 4,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'heightInCs',
            inputElement: 'textField',
            label: 'Case height (in)',
            gridValue: 4,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'lengthInUnit',
            inputElement: 'textField',
            label: 'Unit length (in)',
            gridValue: 4,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'widthInUnit',
            inputElement: 'textField',
            label: 'Unit width (in)',
            gridValue: 4,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'heightInUnit',
            inputElement: 'textField',
            label: 'Unit height (in)',
            gridValue: 4,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'ozUnit',
            inputElement: 'textField',
            label: 'Ounces per unit (oz/unit)',
            gridValue: 3,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'baseUnitSize',
            inputElement: 'textField',
            label: 'Sellable Unit Size',
            gridValue: 3,
        },
        {
            name: 'baseUnitsPerCase',
            inputElement: 'textField',
            label: 'Sellable Units Per Case',
            gridValue: 3,
            inputProps: { required: true, type: 'number'},
        },
        {
            name: 'dbiOnly',
            label: 'Fulfillable by DBI Only',
            inputElement: 'select',
            gridValue: 3,
            inputProps: { opts: ['Yes', 'No'] }
        },
        {
            name: 'receivingTempZone',
            inputElement: 'select',
            label: 'Receiving Temp Zone',
            gridValue: 3,
            inputProps: {opts: tempZones.receiving}
        },
        {
            name: 'storageTempZone',
            inputElement: 'select',
            label: 'Storage Temp Zone',
            gridValue: 3,
            inputProps: {opts: tempZones.storage}
        },
        {
            name: 'shippingTempZone',
            inputElement: 'select',
            label: 'Carrier Shipping Temp Zone',
            gridValue: 3,
            inputProps: {opts: tempZones.shipping}
        },
        {
            name: 'dbiShippingTempZone',
            inputElement: 'select',
            label: 'DBI Shipping Temp Zone',
            gridValue: 3,
            inputProps: {opts: tempZones.dbiShipping}
        },
        {
            name: 'customerGuaranteeDays',
            inputElement: 'textField',
            label: 'Customer Guarantee Days',
            gridValue: 3,
        },
        {
            name: 'vendorGuaranteedDaysUponDelivery',
            inputElement: 'textField',
            label: 'Vendor Guaranteed Days Upon Delivery',
            gridValue: 3,
            inputProps: { type: 'number' },
        },
        {
            name: 'expirationType',
            inputElement: 'select',
            label: 'SKU Date Type',
            gridValue: 3,
            inputProps: {
                required: true,
                opts: ['NO_EXPIRATION_DATE', 'EXPIRATION_DATE', 'PRODUCTION_DATE', 'DAYS_AFTER_RECEIPT']
            }
        }
    ]

  return (
    <div>
        <h1>Create FBM Item</h1>
        <Notifications options={notification} />
        <FormComponent 
            formFields={formFields}
            onSubmit={async (formData, resetForm) => {
                formData.fbmAccountName = fbmAccount.fbmAccountName
                formData.dbiOnly = formData.dbiOnly === 'Yes' ? true : false

                let res = await catalogApi.createItemMaster(formData);
                if (res.status === true) {
                    setNotification({ text: 'FBM Item created!', level: 'success' });
                    resetForm();
                } else {
                    let message = 'Error creating FBM Item';
                    if (res) {
                        message += ': ' + res.msg;
                    }
                    setNotification({ text: message, level: 'error' });
                }

            }}
        />
    </div>
  )
}

export default CreateFBMItem