import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper, Button } from '@material-ui/core';
import { logisticsApi as api } from '../../../api/logistics';
import { DataTable, TabPanel } from '../../../shared';
import { ZipMapResultDetails } from './zipMapResultDetails';

const ZipMapResults = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Zip Map Uploads" value="0" />
                <Tab label="Reassignments" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ZipMapUploads />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <ZipMapReassignments />
            </TabPanel>
        </Paper>
    );
};

const ZipMapUploads = () => {
    let [notification, setNotification] = useState({ text: '', level: '' });
    let [zipMapResults, setZipMapResults] = useState([]);

    let getZipMapResults = async () => {
        let res = await api.getZipMapResults();
        if (!res.status) {
            setNotification({ text: 'Failed to get zip map results', level: 'error' });
            return;
        }
        setZipMapResults(res.data.rows);
    };

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'deliveryDay', Header: 'Delivery Day' },
        {
            Header: 'Optimal File',
            Cell: ({ row }) => {
                return (
                    <Button
                        id={`OptimalFile_Download_${row.id}`}
                        onClick={() => {
                            api.getZipMapFile(
                                row.original.id,
                                'optimal',
                                row.original.timestamp.date,
                                row.original.deliveryDay
                            );
                        }}
                    >
                        Download
                    </Button>
                );
            },
        },
        {
            Header: 'Backup File',
            Cell: ({ row }) => {
                return (
                    <Button
                        id={`BackupFile_Download_${row.id}`}
                        onClick={() => {
                            api.getZipMapFile(
                                row.original.id,
                                'backup',
                                row.original.timestamp.date,
                                row.original.deliveryDay
                            );
                        }}
                    >
                        Download
                    </Button>
                );
            },
        },
        { accessor: 'notes', Header: 'Notes' },
        { accessor: 'authUser', Header: 'Auth User' },
        { accessor: 'timestamp.date', Header: 'Timestamp' },
        { accessor: 'fbmPartnerName', Header: 'FBM Partner' },

        // {Header: "Revert", Cell: ({row}) => {
        //     return <Button>REVERT</Button>
        // }}
    ];

    useEffect(() => {
        getZipMapResults();
    }, []);

    return (
        <Paper>
            <DataTable
                columns={columns}
                data={zipMapResults}
                expandable={true}
                ExpansionComponent={<ZipMapResultDetails />}
            />
        </Paper>
    );
};

const ZipMapReassignments = () => {
    let [rows, setRows] = useState([]);

    let getZipMapReassignmentResults = async () => {
        let res = await api.getZipMapReassignmentResults();
        if (!res.status) {
            setRows([]);
        }
        setRows(res.data.rows);
    };

    useEffect(() => {
        getZipMapReassignmentResults();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'deliveryDay', Header: 'Delivery Day' },
        {
            Header: '# Reassigned',
            Cell: ({ row }) => {
                return (
                    <div>
                        <Button
                            disabled={row.original.reassignedSubs === 0}
                            onClick={() => {
                                api.getReassignmentFile(row.original.id, 'reassignedSubs');
                            }}
                        >
                            {row.original.reassignedSubs}
                        </Button>
                    </div>
                );
            },
        },
        {
            Header: '# No Alternate',
            Cell: ({ row }) => {
                return (
                    <div>
                        <Button
                            disabled={row.original.noAlternateSubs === 0}
                            onClick={() => {
                                api.getReassignmentFile(row.original.id, 'noAlternateSubs');
                            }}
                        >
                            {row.original.noAlternateSubs}
                        </Button>
                    </div>
                );
            },
        },
        {
            Header: '# Alternate At Capacity',
            Cell: ({ row }) => {
                return (
                    <div>
                        <Button
                            disabled={row.original.alternateAtCapacitySubs === 0}
                            onClick={() => {
                                api.getReassignmentFile(row.original.id, 'alternateAtCapacitySubs');
                            }}
                        >
                            {row.original.alternateAtCapacitySubs}
                        </Button>
                    </div>
                );
            },
        },
        { accessor: 'authUser', Header: 'Auth User' },
        { accessor: 'timestamp.date', Header: 'Timestamp' },
    ];

    return (
        <Paper>
            <DataTable columns={columns} data={rows} />
        </Paper>
    );
};

export { ZipMapResults };
