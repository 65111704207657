import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Card, CardContent, Grid, CardHeader, IconButton, Divider, CircularProgress } from '@material-ui/core';
import { FormComponent, Notifications } from '../../../shared';
import { warehouseApi } from '../../../api/warehouse';
import { shippingApi } from '../../../api/shipping';
import { makeStyles, lighten, darken } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../../contexts/UserContext';

const uppercaseFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const CurrentPackLineTable = ({ waveParams }) => {
    const useStyles = makeStyles((theme) => ({
        waveParamKey: {
            fontWeight: 'bold',
        },
    }));

    const classes = useStyles();

    return (
        <table style={{ 'table-layout': 'fixed' }}>
            <tbody>
                <tr>
                    <td className={classes.waveParamKey}>Carrier</td>
                    <td>{waveParams.carrier}</td>
                </tr>
                <tr>
                    <td className={classes.waveParamKey}>Hub</td>
                    <td>{waveParams.hub}</td>
                </tr>
                <tr>
                    <td className={classes.waveParamKey}>Pack Day</td>
                    <td>{waveParams.arrivalDay}</td>
                </tr>
                <tr>
                    <td className={classes.waveParamKey}>Product</td>
                    <td>{waveParams.productSearchTerm}</td>
                </tr>
                <tr>
                    <td className={classes.waveParamKey}>Packaging</td>
                    <td>{waveParams.packagingLiner}</td>
                </tr>
                <tr>
                    <td className={classes.waveParamKey}>Shipments</td>
                    <td>{waveParams.shipmentCount}</td>
                </tr>
                <tr>
                    <td className={classes.waveParamKey}>Excluded Slots</td>
                    <td style={{ 'overflow-wrap': 'anywhere' }}>{waveParams.excludedSlots}</td>
                </tr>
            </tbody>
        </table>
    );
};

const PackLineCardExpanded = ({
    packLines,
    focusedCard,
    setFocusedCard,
    setNotification,
    getPackLines,
    lineSlot4000s,
    facilityID,
    fullBatchelorPDF,
}) => {
    const [packLine, setPackLine] = useState(packLines.find((item) => item.lineNumber === focusedCard));
    const [associatedPackLines, setAssociatedPackLines] = useState([]);
    const [packLineSlotMap, setPackLineSlotMap] = useState([]);
    const [lineHauls, setLineHauls] = useState([]);
    const [packDayOptions, setPackDayOptions] = useState([]);
    const [cubeLevelOptions, setCubeLevelOptions] = useState([]);
    const [downloadingPDF, setDownloadingPDF] = useState(false);
    const [abandoningWave, setAbandoningWave] = useState(false);
    const [claiming, setClaiming] = useState(false);
    const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);

    // need to check the endLines and startLines of pack line
    // to get full list of associated lines
    useEffect(() => {
        let tempArr = [];
        if (packLine.endLines && packLine.endLines.length > 0) {
            packLine.endLines.map((line) => {
                tempArr.push(packLines.find((item) => item.lineNumber === line));
            });
        }

        if (packLine.startLine) {
            tempArr.push(packLines.find((item) => item.lineNumber === packLine.startLine));
        }

        setAssociatedPackLines(tempArr);
    }, [packLine, packLines]);

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '10px',
            minWidth: 275,
            margin: '20px',
            minHeight: 275,
            boxShadow: '20px',
            backgroundColor:
                packLine.claimed.shipments === 0 ? lighten(theme.palette.tomato.main, 0.35) : theme.palette.bone.main,
        },
        shipmentCount: {
            color: theme.palette.ivy.main,
        },
        waveParamKey: {
            fontWeight: 'bold',
        },
        cardTitle: {
            fontWeight: '500',
        },
        header: {
            color: darken(theme.palette.ivy.main, 0.3),
        },
    }));
    let classes = useStyles();

    let getPackLineSlotMap = async () => {
        let res = await warehouseApi.getPackLineSlotMapByLine(packLine.id);
        if (!res.status) {
            return;
        }

        setPackLineSlotMap(
            res.data.packLineSlotMap.map((item) => {
                return {
                    slot: Number(item.slot),
                    text: `Slot ${item.slot}: ${item.name}`,
                    value: item.sellableItemID,
                };
            })
        );
    };

    let getLineHaulsByPackGroupID = async (packGroupID) => {
        if (!facilityID) {
            return;
        }
        let res = await shippingApi.getLineHauls(facilityID, packGroupID);
        if (res.status !== true) {
            return;
        }
        setLineHauls(res.data.lineHauls);
    };

    useEffect(() => {
        getPackLineSlotMap();
        if (packLine?.packGroup?.id) {
            getLineHaulsByPackGroupID(packLine.packGroup.id);
        }
    }, []);

    useEffect(() => {
        let opts = [];
        let cubeOpts = [];
        lineHauls.forEach((item) => {
            if (!opts.includes(item.arrivalDay)) {
                opts.push(item.arrivalDay);
            }
            item.cubeNames.forEach((cube) => {
                if (!cubeOpts.includes(cube)) {
                    cubeOpts.push(cube);
                }
            });
        });

        opts = opts.map((item) => {
            return {
                text: uppercaseFirst(item),
                value: item,
            };
        });

        cubeOpts.sort((a, b) => a.localeCompare(b));
        setPackDayOptions(opts);
        setCubeLevelOptions(cubeOpts);
    }, [lineHauls]);

    // Handle when a line haul changes
    let handleLineHaulChanged = async (event, value) => {
        let hasDbi = false;
        for (let i = 0; i < value.length; i++) {
            if (value[i].text.includes('Dbi') == true) {
                // check all has##s if line has an association
                if (associatedPackLines.length > 0) {
                    let shared00s,
                        shared100s,
                        shared200s,
                        shared300s = false;
                    associatedPackLines.forEach((line) => {
                        if (line.has00s || packLine.has00s) shared00s = true;
                        if (line.has100s || packLine.has100s) shared100s = true;
                        if (line.has200s || packLine.has200s) shared200s = true;
                        if (line.has300s || packLine.has300s) shared300s = true;
                    });

                    if (![shared00s, shared100s, shared200s, shared300s].every((val) => val)) {
                        setNotification({ level: 'error', text: `DBI orders must go on full lines` });
                        return;
                    }
                } else if (
                    packLine.has00s === false ||
                    packLine.has100s === false ||
                    packLine.has200s === false ||
                    packLine.has300s === false
                ) {
                    setNotification({ level: 'error', text: `DBI orders must go on full lines` });
                    return;
                }

                hasDbi = true;
                break;
            }
        }

        if (hasDbi == true && value.length > 1) {
            // Throw error, only 1 DBI at a time
            setNotification({ level: 'error', text: `Can only select 1 DBI line haul at a time` });
            return;
        }
    };

    let packLineSettingsOpts = [
        { text: 'Require', value: 'require' },
        { text: 'Allow', value: 'allow' },
        { text: 'Exclude', value: 'exclude' },
    ];

    let formFields = [
        {
            name: 'arrivalDay',
            inputElement: 'select',
            label: 'Pack Day',
            gridValue: 12,
            inputProps: { required: true, opts: packDayOptions },
        },
        {
            name: 'lineHauls',
            inputElement: 'autocomplete',
            label: 'Line Hauls',
            gridValue: 12,
            dependencies: ['arrivalDay'],
            inputProps: {
                required: true,
                multiple: true,
                onChange: handleLineHaulChanged,
                opts: (formData) => {
                    if (!formData.arrivalDay) {
                        return [];
                    }

                    let options = lineHauls
                        .filter((item) => item.arrivalDay === formData.arrivalDay)
                        .map((item) => {
                            return {
                                text: `${uppercaseFirst(item.carrier)} ${uppercaseFirst(item.hub)}`,
                                value: item.id,
                            };
                        });
                    return options;
                },
            },
        },
        {
            name: 'cube',
            label: 'Cube Level',
            inputElement: 'select',
            inputProps: {
                opts: cubeLevelOptions,
                required: !fullBatchelorPDF,
            },
            display: !fullBatchelorPDF,
        },
        {
            name: '00s',
            label: '00s',
            inputElement: 'select',
            gridValue: lineSlot4000s ? 2 : 3,
            initialValue: packLine.has00s ? 'allow' : 'exclude',
            inputProps: {
                opts: packLineSettingsOpts.filter((item) => {
                    if (packLine.has00s === false && (item.value === 'require' || item.value === 'allow')) {
                        return false;
                    }
                    return true;
                }),
            },
            display: !fullBatchelorPDF,
        },
        {
            name: '100s',
            label: '100s',
            inputElement: 'select',
            gridValue: lineSlot4000s ? 2 : 3,
            initialValue: packLine.has100s ? 'allow' : 'exclude',
            inputProps: {
                opts: packLineSettingsOpts.filter((item) => {
                    if (packLine.has100s === false && (item.value === 'require' || item.value === 'allow')) {
                        return false;
                    }
                    return true;
                }),
            },
            display: !fullBatchelorPDF,
        },
        {
            name: '200s',
            label: '200s',
            inputElement: 'select',
            gridValue: lineSlot4000s ? 2 : 3,
            initialValue: packLine.has200s ? 'allow' : 'exclude',
            inputProps: {
                opts: packLineSettingsOpts.filter((item) => {
                    if (packLine.has200s === false && (item.value === 'require' || item.value === 'allow')) {
                        return false;
                    }
                    return true;
                }),
            },
            display: !fullBatchelorPDF,
        },
        {
            name: '300s',
            label: '300s',
            inputElement: 'select',
            gridValue: lineSlot4000s ? 2 : 3,
            initialValue: packLine.has300s ? 'allow' : 'exclude',
            inputProps: {
                opts: packLineSettingsOpts.filter((item) => {
                    if (packLine.has300s === false && (item.value === 'require' || item.value === 'allow')) {
                        return false;
                    }
                    return true;
                }),
            },
            display: !fullBatchelorPDF,
        },
        {
            name: '400s',
            label: '400s',
            inputElement: 'select',
            gridValue: lineSlot4000s ? 2 : 3,
            initialValue: packLine.has400s ? 'allow' : 'exclude',
            inputProps: {
                opts: packLineSettingsOpts.filter((item) => {
                    if (packLine.has400s === false && (item.value === 'require' || item.value === 'allow')) {
                        return false;
                    }
                    return true;
                }),
            },
            display: lineSlot4000s && !fullBatchelorPDF,
        },
        {
            name: 'cartPicks',
            label: 'Cart Picks',
            inputElement: 'select',
            display: () => packLine.packGroup.name !== 'LS' && facilityID === 8,
            gridValue: lineSlot4000s ? 2 : 3,
            initialValue: facilityID === 8 ? 'exclude' : 'allow',
            inputProps: {
                opts: packLineSettingsOpts,
            },
        },
        // fullBatchelorPDF = dbi
        {
            name: 'quantity',
            label: 'Quantity',
            inputElement: 'textField',
            inputProps: {
                type: 'number',
                integer: true,
                max: 500,
                min: 0,
            },
            initialValue: fullBatchelorPDF ? 500 : 250,
        },
        {
            name: 'exclude',
            label: 'Exclude Items',
            inputElement: 'autocomplete',
            inputProps: {
                opts: packLineSlotMap,
                multiple: true,
            },
            display: !fullBatchelorPDF,
        },
    ];

    const ClaimedWaves = () => {
        return (
            <Grid item xs={7} align="center">
                {packLine.waves && (
                    <>
                        <CurrentPackLineTable waveParams={packLine.waves[0]} />
                        <div>
                            <Button
                                id="AbandonWave"
                                color="secondary"
                                disabled={downloadButtonDisabled}
                                onClick={async () => {
                                    setAbandoningWave(true);
                                    if (window.confirm('Abandon Wave?') === true) {
                                        let res = await shippingApi.abandonLineHaulBatch(
                                            packLine.id,
                                            packLine.waves[0].lineHaulID
                                        );
                                        if (res.status === true) {
                                            setNotification({ level: 'success', text: `Wave abandoned` });
                                            getPackLines();
                                            setDownloadButtonDisabled(true);
                                        } else {
                                            setNotification({
                                                level: 'error',
                                                text: `Failed to abandon shipments: ${res.msg}`,
                                            });
                                        }
                                    }
                                    setAbandoningWave(false);
                                }}
                            >
                                Abandon Wave
                            </Button>
                            {fullBatchelorPDF && (
                                <div>
                                    <div>
                                        <Button
                                            id="DownloadPDF"
                                            color="secondary"
                                            disabled={downloadButtonDisabled}
                                            onClick={async () => {
                                                setDownloadingPDF(true);
                                                let res = await shippingApi.printEntireWave(packLine.id);

                                                if (res.status === true) {
                                                    getPackLines();
                                                    setDownloadButtonDisabled(true);
                                                } else {
                                                    setNotification({
                                                        level: 'error',
                                                        text: `Failed to download PDF: ${res.msg}`,
                                                    });
                                                }
                                                setDownloadingPDF(false);
                                            }}
                                        >
                                            Download All Labels PDF
                                        </Button>
                                    </div>
                                    {((!!packLine.endLineIDs && packLine.endLineIDs.length > 0) ||
                                        packLine.startLineID) && (
                                        <div>
                                            <Button
                                                id="DownloadInductionPointPDFs"
                                                color="secondary"
                                                disabled={downloadButtonDisabled}
                                                onClick={async () => {
                                                    setDownloadingPDF(true);

                                                    //download any end and start line associations
                                                    let allAssocLineIDs = packLine.endLineIDs;
                                                    allAssocLineIDs.push(packLine.id);

                                                    if (packLine.startLine) {
                                                        allAssocLineIDs.push(packLine.startLineID);
                                                    }

                                                    let promises = allAssocLineIDs.map((id) =>
                                                        shippingApi.printEntireWave(id, true)
                                                    );
                                                    let res = await Promise.all(promises);

                                                    if (!res.every((item) => item.status)) {
                                                        setNotification({
                                                            text: `Failed to download PDF: ${res.msg}`,
                                                            level: 'error',
                                                        });
                                                    } else {
                                                        getPackLines();
                                                    }

                                                    setDownloadButtonDisabled(true);
                                                    setDownloadingPDF(false);
                                                }}
                                            >
                                                Download All Induct Points PDF
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                )}
                {packLine.waves &&
                    packLine.waves.slice(1).map((wave) => (
                        <>
                            <Divider />
                            <CurrentPackLineTable waveParams={wave} />
                            <div>
                                <Button
                                    id="AbandonWaves"
                                    color="secondary"
                                    onClick={async () => {
                                        if (window.confirm('Abandon Wave?') === true) {
                                            let res = await shippingApi.abandonLineHaulBatch(
                                                packLine.id,
                                                wave.lineHaulID
                                            );
                                            if (res.status === true) {
                                                setNotification({ level: 'success', text: `Wave abandoned` });
                                                getPackLines();
                                            } else {
                                                setNotification({
                                                    level: 'error',
                                                    text: `Failed to abandon shipments: ${res.msg}`,
                                                });
                                            }
                                        }
                                    }}
                                >
                                    Abandon Wave
                                </Button>
                            </div>
                        </>
                    ))}
            </Grid>
        );
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                classes={{ title: classes.cardTitle }}
                action={
                    <IconButton
                        onClick={(e) => {
                            setFocusedCard(null);
                            e.stopPropagation();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                title={
                    packLine && packLine.packGroup && packLine.packGroup.week
                        ? `LINE ${packLine.lineNumber} - W${packLine.packGroup.week} ${packLine.packGroup.name}`
                        : `LINE ${packLine.lineNumber}`
                }
            />
            <Divider />
            <CardContent>
                <Grid container>
                    {claiming || abandoningWave || downloadingPDF ? (
                        <Grid item xs={12} align="center">
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} md={9} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                <h2 className={classes.header}>Assign To Line</h2>
                                <FormComponent
                                    formFields={formFields}
                                    onSubmit={async (formData, resetForm) => {
                                        setClaiming(true);

                                        // if pack group is LS, allow all slots, don't allow cart picks
                                        let lsPackGroup = packLine.packGroup.name === 'LS' ? true : false;

                                        let obj = {
                                            lineHaulIDs: formData.lineHauls.map((item) => item.value),
                                            cube: formData.cube.toLowerCase(),
                                            packLineID: packLine.id,
                                            quantity: formData.quantity,
                                            exclude: formData.exclude.map((item) => item.value),
                                            '00s': formData['00s'],
                                            '100s': formData['100s'],
                                            '200s': formData['200s'],
                                            '300s': formData['300s'],
                                            cartPicks: lsPackGroup ? 'exclude' : formData['cartPicks'],
                                        };

                                        if (lineSlot4000s) {
                                            obj['400s'] = lsPackGroup ? 'allow' : formData['400s'];
                                        }

                                        let res = await shippingApi.claimBatches(obj);
                                        if (res.status === true) {
                                            setNotification({
                                                level: 'success',
                                                text: `Successfully claimed ${res.data.shipmentsClaimed} shipments`,
                                            });
                                            getPackLines();
                                            resetForm();
                                        } else {
                                            setNotification({
                                                level: 'error',
                                                text: `Failed to claim shipments: ${res.msg}`,
                                            });
                                        }
                                        setClaiming(false);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} align="center">
                                <h2 className={classes.header}>Currently Assigned</h2>
                                {packLine.claimed.waveParams && <ClaimedWaves />}
                                <div style={{ marginTop: '20px' }}>
                                    Total Shipments Assigned{' '}
                                    <h1 className={classes.shipmentCount}>{packLine.claimed.shipments}</h1>
                                </div>

                                {packLine.endLines && packLine.endLines.length > 0 && (
                                    <div>
                                        This line leads to:
                                        {packLine.endLines.map((item) => (
                                            <div>Line {item}</div>
                                        ))}
                                    </div>
                                )}
                                {packLine.startLine && (
                                    <div>
                                        This line originates on:
                                        {<div>Line {packLine.startLine}</div>}
                                    </div>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

const PackLineCardSmall = ({ disabled, packLine, setFocusedCard }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '10px',
            minWidth: 275,
            margin: '20px',
            minHeight: 310,
            boxShadow: '20px',
            backgroundColor:
                packLine.claimed.shipments === 0 ? lighten(theme.palette.tomato.main, 0.35) : theme.palette.bone.main,
            transition: 'all .5s',
            '&:hover': disabled
                ? {
                      backgroundColor: '#555',
                  }
                : {
                      transition: 'all .5s',
                      cursor: 'pointer',
                      backgroundColor:
                          packLine.claimed.shipments === 0
                              ? theme.palette.tomato.main
                              : lighten(theme.palette.mustard.main, 0.3),
                  },
        },
        cardTitle: {
            fontWeight: '500',
        },
        shipmentCount: {
            color: theme.palette.ivy.main,
        },
        waveParamKey: {
            fontWeight: 'bold',
        },
    }));
    const classes = useStyles();

    const ClaimedWaves = () => {
        return (
            <Grid item xs={7} align="center">
                {packLine.waves && <CurrentPackLineTable waveParams={packLine.waves[0]} />}
                {packLine.waves &&
                    packLine.waves.slice(1).map((wave) => (
                        <>
                            <Divider />
                            <CurrentPackLineTable waveParams={wave} />
                        </>
                    ))}
            </Grid>
        );
    };

    return (
        <Card
            id={`LINE_${packLine.lineNumber}`}
            className={classes.root}
            onClick={!disabled ? () => setFocusedCard(packLine.lineNumber) : undefined}
        >
            <CardHeader
                classes={{
                    title: classes.cardTitle,
                }}
                title={
                    packLine && packLine.packGroup && packLine.packGroup.week
                        ? `LINE ${packLine.lineNumber} - W${packLine.packGroup.week} ${packLine.packGroup.name}`
                        : `LINE ${packLine.lineNumber}`
                }
            />
            <Divider />
            <CardContent>
                <Grid container style={{ paddingTop: '10px' }}>
                    <Grid item xs={packLine.claimed.shipments === 0 ? 12 : 5}>
                        {disabled ? (
                            <span>This line is associated with more than one line and cannot be batched to.</span>
                        ) : (
                            <span>
                                Shipments Assigned{' '}
                                <h1 className={classes.shipmentCount}>{packLine.claimed.shipments}</h1>
                            </span>
                        )}
                    </Grid>
                    {packLine.claimed.waveParams && <ClaimedWaves />}
                </Grid>
            </CardContent>
        </Card>
    );
};

const Batchelor = () => {
    const [focusedCard, setFocusedCard] = useState(null);
    const [packLines, setPackLines] = useState([]);
    const [notification, setNotification] = useState({ level: null, text: null });
    const user = useContext(UserContext);
    const [facilityID, setFacilityID] = useState(user.getFacilityID());
    const [lineSlot4000s, setLineSlot4000s] = useState(false);
    const [fullBatchelorPDF, setFullBatchelorPDF] = useState(false);

    useInterval(async () => {
        getPackLines();
    }, 10000);

    useEffect(() => {
        setFocusedCard(null);
        getPackLines();
        getLineSlot4000s();
    }, [facilityID]);

    let getLineSlot4000s = async () => {
        if (!facilityID) {
            return;
        }
        let res = await warehouseApi.getFacilities(true);
        if (!res.status || !res.data) {
            return;
        }
        let facility = res.data.rows.find((item) => item.id === facilityID);
        if (!!facility) {
            setLineSlot4000s(facility.lineSlot4000s);
            setFullBatchelorPDF(facility.printBatchelorPDF);
        }
    };

    let getPackLines = async () => {
        if (!facilityID) {
            return;
        }

        let response = await shippingApi.getPackLinesWithClaims(facilityID);
        if (response.status !== true) {
            return;
        }
        let pl = response.data.packLines;
        pl.forEach((line) => {
            let waves = line.waves && line.waves[0] ? line.waves.sort((a, b) => a.arrivalHour - b.arrivalHour) : null;
            line.claimed = {
                shipments: 0,
            };
            if (waves) {
                waves.sort((a, b) => {
                    if (a.arrivalHour !== b.arrivalHour) {
                        return a.arrivalHour - b.arrivalHour;
                    } else if (a.carrier < b.carrier) {
                        return -1;
                    } else if (a.carrier > b.carrier) {
                        return 1;
                    } else if (a.hub < b.hub) {
                        return -1;
                    } else if (a.hub > b.hub) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                waves.forEach((wave) => {
                    let newTotal = (line.claimed.shipments += wave.shipmentCount);
                    line.claimed = {
                        shipments: newTotal,
                    };
                });
                line.claimed.waveParams = {
                    carrier: waves[0].carrier,
                    arrivalDay: waves[0].arrivalDay,
                    hub: waves[0].hub,
                    productSearchTerm: waves[0].productSearchTerm,
                    packagingLiner: waves[0].packagingLiner,
                };
            }
        });
        setPackLines(pl.sort((a, b) => (a.lineNumber > b.lineNumber ? 1 : -1)));
    };

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item md={12} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                <h1>Batchelor Command and Control 🌹</h1>
            </Grid>
            {focusedCard === null ? (
                <>
                    {packLines.map((item) => {
                        return (
                            <Grid key={item.lineNumber} item md={4} sm={6} xs={12}>
                                <PackLineCardSmall
                                    disabled={item.endLines && item.endLines.length > 1 ? true : false}
                                    packLine={item}
                                    setFocusedCard={setFocusedCard}
                                />
                            </Grid>
                        );
                    })}
                </>
            ) : (
                <Grid item xs={12}>
                    <PackLineCardExpanded
                        packLines={packLines}
                        focusedCard={focusedCard}
                        setFocusedCard={setFocusedCard}
                        setNotification={setNotification}
                        getPackLines={getPackLines}
                        lineSlot4000s={lineSlot4000s}
                        facilityID={facilityID}
                        fullBatchelorPDF={fullBatchelorPDF}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export { Batchelor };
