import React, { useState, useEffect, useContext } from 'react';
import { warehouseApi } from '../../../../api/warehouse.js';
import { Notifications } from '../../../../shared/components/Notifications.js';
import { DataTable } from '../../../../shared/components/dataTable/dataTable.js';
import { UserContext } from '../../../../contexts/UserContext.js';
import { FacilityPicker, FormComponent } from '../../../../shared/';
import { Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import moment from 'moment';

const NetsuitePackSync = () => {
    const user = useContext(UserContext);

    let [notification, setNotification] = useState({ text: null, level: null });
    let [isLoading, setIsLoading] = useState(false);
    let [packSyncRows, setPackSyncRows] = useState([]);
    let [facilityID, setFacilityID] = useState(user.getFacilityID());
    let [facilityName, setFacilityName] = useState(null);
    let [inProgressSync, setInProgressSync] = useState(false);
    let [count, setCount] = useState(0);
    let [lastSuccess, setLastSuccess] = useState(null);

    let columns = [
        { accessor: 'id', Header: 'Pack Sync Run ID' },
        { accessor: 'startTime', Header: 'Start Time' },
        { accessor: 'endTime', Header: 'End Time' },
        { accessor: 'triggeredBy', Header: 'Triggered By' },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: ({ row }) => {
                let retVal = row.original.status;
                switch (retVal) {
                    case 'FAILED':
                    case 'VALIDATION_FAILED':
                    case 'CANCELLED':
                        retVal = '❌ ' + retVal;
                        break;
                    case 'VALIDATING':
                    case 'RUNNING':
                        retVal = '🏃‍♀️ ' + retVal;
                        break;
                    case 'PENDING_CONFIRMATION':
                        retVal = '👌 ' + retVal;
                        break;
                    case 'SUCCESS':
                        retVal = '✅ ' + retVal;
                        break;
                }
                return retVal;
            },
        },
    ];

    let getPackSyncRows = async () => {
        let res = await warehouseApi.getPackSyncRuns(facilityID);
        if (!res.status) {
            setNotification({ text: 'Error getting pack sync rows: ' + res.msg, level: 'error' });
            return;
        }
        let inProgressSyncInBatch = false;
        for (let row of res.data.rows) {
            if (['VALIDATING', 'RUNNING', 'PENDING_CONFIRMATION'].includes(row.status)) {
                inProgressSyncInBatch = true;
            }
        }
        setPackSyncRows(
            res.data.rows.map((row) => {
                if (!!row.startTime.date) {
                    let startDateTime = new Date(row.startTime.date);
                    startDateTime.setMinutes(startDateTime.getMinutes() - startDateTime.getTimezoneOffset());
                    row.startTime = startDateTime.toLocaleString();
                }
                if (!!row.endTime.date) {
                    let endDateTime = new Date(row.endTime.date);
                    endDateTime.setMinutes(endDateTime.getMinutes() - endDateTime.getTimezoneOffset());
                    row.endTime = endDateTime.toLocaleString();
                }
                return row;
            })
        );
        setInProgressSync(inProgressSyncInBatch);
        if (!!res.data.lastSuccess.endTime) {
            let endDateTime = new Date(res.data.lastSuccess.endTime.date);
            endDateTime.setMinutes(endDateTime.getMinutes() - endDateTime.getTimezoneOffset());
            res.data.lastSuccess.endTime = endDateTime.toLocaleString();
        } else {
            res.data.lastSuccess.endTime = '';
        }
        setLastSuccess(res.data.lastSuccess);
    };

    useEffect(() => {
        const timer = setTimeout(() => setCount(count + 1), 15000);
        getPackSyncRows();
        return () => clearTimeout(timer);
    }, [count]);

    useEffect(() => {
        getPackSyncRows();
    }, [facilityID]);

    return (
        <div>
            <Notifications options={notification} />

            <Dialog
                open={isLoading}
                onClose={() => {
                    setIsLoading(false);
                }}
            >
                <DialogContent>
                    <DialogTitle>Are you sure you want to do this?</DialogTitle>
                    <p>To verify, type the Facility Name below:</p>
                    <FormComponent
                        button={{
                            gridValue: 6,
                            text: 'Initiate pack sync to NetSuite',
                        }}
                        formFields={[
                            {
                                name: 'facility',
                                label: facilityName,
                                inputElement: 'textField',
                                gridValue: 6,
                            },
                        ]}
                        onSubmit={async (formData, resetForm) => {
                            if (formData.facility == facilityName) {
                                let res = await warehouseApi.initiatePackSync(facilityID);
                                if (!res.status) {
                                    setNotification({
                                        text: 'Failed to initiate pack sync: ' + res.msg,
                                        level: 'error',
                                    });
                                    await getPackSyncRows();
                                    setIsLoading(false);
                                    return;
                                }
                                setNotification({ text: 'Pack sync initiated', level: 'success' });
                                await getPackSyncRows();
                                setIsLoading(false);
                                return;
                            } else {
                                setNotification({
                                    text: 'Error: Facility Names do not match. Re-enter name or change facility selection.',
                                    level: 'error',
                                });
                                return;
                            }
                            setIsLoading(false);
                        }}
                    />
                </DialogContent>
            </Dialog>

            <div class="flexbox-row">
                <FacilityPicker
                    defaultValue={user.getFacilityID()}
                    activeOnly={true}
                    onChange={(facilityID, text, facility) => {
                        setFacilityID(facilityID);
                        setFacilityName(text);
                    }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading || inProgressSync}
                    onClick={async () => {
                        setIsLoading(true);
                    }}
                >
                    Initiate Pack Sync
                </Button>
            </div>
            {lastSuccess && lastSuccess.endTime && <div>{`Last Successful Pack Sync: ${lastSuccess.endTime}`}</div>}
            <DataTable
                columns={columns}
                data={packSyncRows}
                expandable={true}
                ExpansionComponent={
                    <ViewPackSyncRunDetails
                        setNotification={setNotification}
                        getPackSyncRows={getPackSyncRows}
                        facilityID={facilityID}
                        isLoading={isLoading}
                    />
                }
            />
        </div>
    );
};

let ViewPackSyncRunDetails = ({
    parentRow,
    setExpandedRow,
    setNotification,
    getPackSyncRows,
    facilityID,
    isLoading,
}) => {
    const [loading, setLoading] = useState(true);
    const [helpNeedsAHumanAdjustments, setHelpNeedsAHumanAdjustments] = useState([]);
    const [validatedPreadjustments, setValidatedPreadjustments] = useState([]);
    const [validatedAdjustments, setValidatedAdjustments] = useState([]);

    let helpNeedsAHumanAdjustmentsColmuns = [
        { Header: 'Error', accessor: 'error.message' },
        { Header: 'Netsuite Item ID', accessor: 'netsuiteItemID' },
        { Header: 'Adjustment Quantity', accessor: 'quantity' },
        { Header: 'Negative Variance', accessor: 'negativeVariance' },
        { Header: 'Reason Code', accessor: 'reasonCode' },
    ];

    let validatedAdjustmentsColumns = [
        { Header: 'Date', accessor: 'date' },
        { Header: 'Memo', accessor: 'memo' },
        { Header: 'Netsuite Item ID', accessor: 'netsuiteItemID' },
        { Header: 'Quantity', accessor: 'quantity' },
    ];

    let validatedPreadjustmentsColumns = [
        { Header: 'Date', accessor: 'date' },
        { Header: 'Memo', accessor: 'memo' },
        { Header: 'Netsuite Item ID', accessor: 'netsuiteItemID' },
        { Header: 'Quantity', accessor: 'quantity' },
    ];

    useEffect(() => {
        async function happyEffect() {
            let res = await warehouseApi.getPackSyncRunsData(parentRow.original.id);
            let adjustmentData = res.data.packSyncRunsData.adjustmentData;
            setHelpNeedsAHumanAdjustments(adjustmentData?.helpNeedsAHumanAdjustments ?? []);
            setHelpNeedsAHumanAdjustments(
                adjustmentData?.helpNeedsAHumanAdjustments
                    ? adjustmentData.helpNeedsAHumanAdjustments.map((x) => {
                          if (x.error.name === 'NEGATIVE_INVENTORY_ERROR') {
                              let negvar = x.error.message.split('-');
                              x.negativeVariance = `-${negvar[negvar.length - 1]}`;
                          }
                          return x;
                      })
                    : []
            );
            setValidatedAdjustments(adjustmentData?.validatedAdjustments?.picksGroupedByFacility[0]?.items ?? []);
            setValidatedPreadjustments(
                adjustmentData?.validatedPreadjustments?.picksGroupedByFacility
                    ? adjustmentData.validatedPreadjustments.picksGroupedByFacility[0].items
                    : []
            );
            setLoading(false);
        }
        happyEffect();
    }, []);

    useEffect(() => {
        if (!loading) {
            setExpandedRow(null);
        }
    }, [facilityID, isLoading]);

    return (
        <div>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', margin: 20, padding: 10 }}>
                    <CircularProgress size={100} />
                </div>
            ) : (
                <>
                    {helpNeedsAHumanAdjustments.length > 0 ? (
                        <>
                            <Card
                                raised={true}
                                style={{
                                    padding: 10,
                                    margin: 10,
                                    marginBlock: 50,
                                }}
                            >
                                <DataTable
                                    title="Invalid Adjustment Errors"
                                    columns={helpNeedsAHumanAdjustmentsColmuns}
                                    data={helpNeedsAHumanAdjustments}
                                    csvExport={true}
                                    csvExportFileName={`pack_sync_errors_${parentRow.original.id}`}
                                />
                            </Card>
                        </>
                    ) : (
                        <></>
                    )}
                    {validatedPreadjustments.length > 0 ? (
                        <Card
                            raised={true}
                            style={{
                                padding: 10,
                                margin: 10,
                                marginBlock: 50,
                            }}
                        >
                            <DataTable
                                title={
                                    parentRow.original.status === 'SUCCESS'
                                        ? 'Negative Variance Auto-Adjustments'
                                        : 'Pending Negative Variance Auto-Adjustments'
                                }
                                columns={validatedPreadjustmentsColumns}
                                data={validatedPreadjustments}
                            />
                        </Card>
                    ) : (
                        <></>
                    )}
                    <Card
                        raised={true}
                        style={{
                            padding: 10,
                            margin: 10,
                            marginBlock: 50,
                        }}
                    >
                        {validatedAdjustments.length == 0 && parentRow.original.status == 'FAILED' ? (
                            <h3>No picks found since last pack sync</h3>
                        ) : (
                            <DataTable
                                title={
                                    parentRow.original.status === 'SUCCESS'
                                        ? 'Pack Sync Adjustments'
                                        : 'Pending Pack Sync Adjustments'
                                }
                                columns={validatedAdjustmentsColumns}
                                data={validatedAdjustments}
                                toolbarActions={
                                    parentRow.original.status === 'PENDING_CONFIRMATION'
                                        ? [
                                              {
                                                  name: 'CANCEL',
                                                  action: async () => {
                                                      await warehouseApi.cancelPendingPackSync(parentRow.original.id);
                                                      await getPackSyncRows();
                                                      setExpandedRow(null);
                                                      return;
                                                  },
                                              },
                                              {
                                                  name: 'CONFIRM AND SEND TO NETSUITE',
                                                  action: async () => {
                                                      let res = await warehouseApi.confirmPackSync(
                                                          parentRow.original.id
                                                      );
                                                      if (res.status === true) {
                                                          await getPackSyncRows();
                                                          setExpandedRow(null);
                                                          return;
                                                      } else {
                                                          setNotification({
                                                              text: 'Error: ' + res.msg,
                                                              level: 'error',
                                                          });
                                                      }
                                                  },
                                              },
                                          ]
                                        : []
                                }
                                csvExport={true}
                                csvExportFileName={`pack_sync_adjustments_${parentRow.original.id}`}
                            />
                        )}
                    </Card>
                </>
            )}
        </div>
    );
};

export { NetsuitePackSync };
