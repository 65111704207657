import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { Button } from '@material-ui/core';
import { packagingApi as api } from '../../../api/packaging';
import { procurementApi as procurementApi } from '../../../api/procurement';
import { warehouseApi as warehouseApi } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';
import { AddPackagingItems } from './addPackagingItems.js';

let CreatePackagingPO = ({ initialValues = {}, onSuccess }) => {
    let [error, setError] = useState(null);
    let [vendors, setVendors] = useState([]);
    let [facilities, setFacilities] = useState([]);
    let [itemsInPO, setItemsInPO] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const user = useContext(UserContext);

    // Master form submit function
    let createPurchaseOrder = async (formData, resetForm) => {
        let error = false;
        let items = formData.itemsInPO.map((item) => {
            if (!item.id || !item.qty || !item.unitPrice || !item.vendorSku) {
                setNotification({ level: 'error', text: 'All item fields required' });
                error = true;
            }
            return {
                id: item.id,
                qty: item.qty,
                unitPrice: item.unitPrice,
                vendorSku: item.vendorSku,
                numPallets: item.numPallets,
                unitsPerCase: item.unitsPerCase,
                unitsPerCaseUOM: item.unitsPerCaseUOM,
            };
        });

        if (error) {
            return false;
        }

        let sendParams = {
            vendorID: formData.vendor,
            deliverDate: formData.scheduledDeliveryDate,
            deliverTime: formData.scheduledTime,
            referenceNumber: formData.referenceNumber,
            facilityID: user.getFacilityID(),
            items: items,
            estimatedFreightCost: formData.estimatedFreightCost,
        };

        let response = await api.createPurchaseOrder(sendParams);
        if (response.status === false) {
            let message = 'Error creating purchase order';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            setNotification({ text: 'Purchase Order created!', level: 'success' });
            resetForm();

            if (onSuccess && onSuccess instanceof Function) {
                onSuccess();
            }
        }
    };

    // Load vendors
    let getVendors = async () => {
        let response = await procurementApi.getVendors();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setVendors([]);
            return;
        }

        let vendorAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setVendors(vendorAsOpts);
    };

    // Load facilities
    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (response.status === false) {
            setNotification({ text: 'No facilities found ' + response.msg, level: 'error' });
            setFacilities([]);
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setFacilities([pleaseSelect].concat(facilitiesAsOpts));
    };

    useEffect(() => {
        getVendors();
        getFacilities();
    }, []);

    let formFields = [
        {
            name: 'vendor',
            initialValue: initialValues.vendorID
                ? { text: initialValues.vendorName, value: initialValues.vendorID }
                : undefined,
            inputElement: 'autocomplete',
            label: 'Vendor',
            gridValue: 4,
            inputProps: { required: true, opts: vendors },
        },
        {
            name: 'scheduledDeliveryDate',
            inputElement: 'date',
            disableTextInput: true,
            label: 'Scheduled Delivery Date',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'scheduledTime',
            inputElement: 'time',
            label: 'Scheduled delivery time',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'referenceNumber',
            inputElement: 'textField',
            label: 'Reference Number',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'facility',
            inputElement: 'textField',
            label: 'Facility',
            gridValue: 2,
            initialValue: user.getFacilityName(),
            inputProps: { required: true, readOnly: true },
        },
        {
            name: 'estimatedFreightCost',
            inputElement: 'textField',
            label: 'Freight Cost',
            gridValue: 2,
            initialValue: initialValues.estimatedFreightCost || undefined,
            inputProps: { type: 'number' },
        },
        {
            name: 'itemsInPO',
            label: 'Items',
            inputElement: ({ formData, setFormField }) => (
                <AddPackagingItems
                    setNotification={setNotification}
                    initialValue={initialValues}
                    formData={formData}
                    setFormField={setFormField}
                />
            ),
        },
    ];

    return (
        <div>
            <h1>Create Purchase Order</h1>
            <Notifications options={notification} />
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    createPurchaseOrder(formData, resetForm);
                }}
            />
        </div>
    );
};

export { CreatePackagingPO };
