import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { logisticsApi } from '../../../api/logistics';
import { UserContext } from '../../../contexts/UserContext';
import { Button, Modal } from '@material-ui/core';

const ResetModal = ({ lineHaulID, hub, closeModal }) => {
    return (
        <div>
            <Modal open={true} onClose={closeModal}>
                <div>
                    <div
                        style={{
                            outline: 0,
                            backgroundColor: 'white',
                            width: '400px',
                            margin: '10% auto',
                            padding: '10px',
                        }}
                    >
                        You are about to revert {hub} ID {lineHaulID} with Delivery Day to unplanned status - Do you
                        wish to proceed?
                        <div>
                            <Button
                                onClick={async () => {
                                    let res = await logisticsApi.resetLineHaulRouting(lineHaulID);
                                    if (res.status == false) {
                                        closeModal({ text: 'Error reverting line haul: '.res.msg, level: 'error' });
                                        return;
                                    }
                                    closeModal({ text: 'Successfully reverted line haul', level: 'success' });
                                }}
                            >
                                Confirm
                            </Button>
                            <Button onClick={() => closeModal({})}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
const ResetLineHaulRouting = () => {
    const user = useContext(UserContext);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [showResetModal, setShowResetModal] = useState(false);
    let [modalParams, setModalParams] = useState({});
    let [rows, setRows] = useState([]);

    const getRecentLineHaulShippingDays = async () => {
        let response = await logisticsApi.getRecentLineHaulShippingDays(user.getFacilityID());

        if (response.status === false) {
            setNotification({ text: 'Error getting recent shipping day: '.response.msg, level: 'error' });
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getRecentLineHaulShippingDays();
    }, []);

    const columns = [
        { accessor: 'lineHaulID', Header: 'ID' },
        { accessor: 'hub', Header: 'Hub' },
        { accessor: 'preferredDay', Header: 'Preferred Day' },
        {
            accessor: 'reset',
            Header: 'Reset',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={() => {
                            setModalParams({
                                lineHaulID: row.original.lineHaulID,
                                preferredDay: row.original.preferredDay,
                                hub: row.original.hub,
                            });
                            setShowResetModal(true);
                        }}
                    >
                        Reset
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            {showResetModal && (
                <ResetModal
                    lineHaulID={modalParams.lineHaulID}
                    hub={modalParams.hub}
                    closeModal={(notificationPassback) => {
                        setModalParams({});
                        setShowResetModal(false);
                        setNotification(notificationPassback);
                    }}
                />
            )}
            <DataTable columns={columns} data={rows} />
        </div>
    );
};

export { ResetLineHaulRouting };
