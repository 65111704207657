import React from 'react';
import { Link } from 'react-router-dom';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';

let GoPuffPricingUpload = () => {
    let columns = [
        { Header: 'External SKU', accessor: 'externalSku' },
        { Header: 'Effective Date', accessor: 'effectiveDate' },
        { Header: 'Each Cost', accessor: 'eachCost' },
        { Header: 'Case Cost', accessor: 'caseCost' },
        { Header: 'Currency', accessor: 'currency' },
        { Header: 'GP OpCo Name', accessor: 'opCoName' },
        { Header: 'GP Location ID', accessor: 'gpLocationID' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.confirmPricingUpload(key);
        return res;
    };

    let uploadRows = async (formData) => {
        let res = await api.uploadPricing(formData);
        return res;
    };

    let downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,External SKU,Effective Date,';
        csvContent += 'Each Cost,Case Cost,Currency,GP OpCo Name,GP LocationID';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'go_puff_pricing_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <Link to={`/goPuff/pricing`}>View Pricing</Link>
            <UploadCSVAndReviewComponent
                title="Upload Go Puff Pricing"
                fileLabel="Pricing File"
                columns={columns}
                upload={uploadRows}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
            />
        </div>
    );
};

export { GoPuffPricingUpload };
