import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Button, Modal } from '@material-ui/core';
import { procurementApi as api } from '../../../api/procurement';
import { UserContext } from '../../../contexts/UserContext';

const DocumentUploadModal = ({ rowData, closeModal }) => {
    let [error, setError] = useState(null);
    let [poID, setPoID] = useState(rowData.poID);

    let formFields = [
        {
            name: 'poID',
            initialValue: poID,
            inputElement: 'textField',
            label: 'PO ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        { 
            name: 'notes', 
            inputElement: 'select', 
            label: 'Notes', 
            gridValue: 4,
            inputProps: { opts: ['BOL', 'Other']},
        },
        { name: 'document', inputElement: 'file', gridValue: 2, label: 'Document', accept: ['.png', '.jpeg', '.pdf'] },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        if (!formData.document) {
                            setError('Document is required');
                            return false;
                        }

                        let response = await api.uploadPODocument(poID, formData);

                        if (response.status === false) {
                            setError(response.msg);
                            return;
                        }

                        closeModal();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

// The table to show items
const ViewDocumentsTable = ({ parentRow, setExpandedRow }) => {
    let [rows, setRows] = useState([]);
    let [poID, setPoID] = useState(parentRow.original.id);
    let [notification, setNotification] = useState({ text: null, level: null });

    // Load purchase orders
    let getDocuments = async () => {
        let response = await api.getPurchaseOrderDocuments(poID);

        if (response.status === false) {
            setNotification({ text: "Can't access documents: " + response.msg, level: 'error' });
            return;
        }

        setRows(response.data.rows);
    };

    let columns = [
        { accessor: 'id', Header: 'Document ID' },
        { accessor: 'sku', Header: 'SKU' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'notes', Header: 'Photo Label' },
        {
            accessor: 'documentURL',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                if (!row.original.documentURL) {
                    return '';
                }
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Download_${row.id}`}
                            onClick={() => {
                                api.downloadPODocument(
                                    poID,
                                    row.original.id,
                                    row.original.documentURL.replace(/^.*[\\\/]/, '')
                                );
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getDocuments();
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            {}
            <div>
                <DataTable columns={columns} data={rows} />
            </div>
        </div>
    );
};

let PurchaseOrderDocuments = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
    let [modalLaunch, setModalLaunch] = useState({ poID: 0 });
    const user = useContext(UserContext);

    let closeDocumentUploadModal = () => {
        setDocumentUploadModalOpen(false);
    };

    let showDocumentUploadModal = (row, closeDocumentUploadModal) => {
        setModalLaunch({ poID: row.original.id });
        setDocumentUploadModalOpen(true);
    };

    let columns = [
        { accessor: 'id', Header: 'PO ID' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'facilityName', Header: 'Facility' },
        { accessor: 'vendorName', Header: 'Vendor' },
        {
            accessor: 'upload',
            Header: 'Upload',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id="Upload"
                            onClick={() => {
                                showDocumentUploadModal(row);
                            }}
                        >
                            Upload
                        </Button>
                    </div>
                );
            },
        },
    ];

    // Load purchase orders
    let getPurchaseOrders = async () => {
        let response = await api.getPurchaseOrders(user.getFacilityID(), null, null, 'PURCHASE_ORDER,THIRD_PARTY_ASN');

        if (response.status === false) {
            setNotification({ text: "Can't access POs: " + response.msg, level: 'error' });
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getPurchaseOrders();
    }, []);

    return (
        <div>
            <h1>Purchase Order Documents</h1>
            <Notifications options={notification} />
            {}
            {documentUploadModalOpen && (
                <DocumentUploadModal
                    rowData={modalLaunch}
                    closeModal={() => {
                        setDocumentUploadModalOpen(false);
                        getPurchaseOrders();
                    }}
                />
            )}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    expandable={true}
                    ExpansionComponent={<ViewDocumentsTable />}
                />
            </div>
        </div>
    );
};

export { PurchaseOrderDocuments };
