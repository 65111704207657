import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { catalogApi as api } from '../../../api/catalog';
import { packagingApi } from '../../../api/packaging';
const filter = createFilterOptions();

let AddBOMIngredients = ({ setNotification, setFormField, formData, itemMasterOptions, packagingOptions }) => {
    let initValue = [];
    let [bomIngredients, setBomIngredients] = useState(initValue);

    let clearItems = () => {
        setBomIngredients([]);
    };

    useEffect(() => {
        // reset form (after submit) clears formData.bomIngredients, but does not reset internal bomIngredients
        // we need to do this manually when the form data has been cleared and there is no initial value
        if (
            bomIngredients.length &&
            (!formData.bomIngredients || !formData.bomIngredients.length) &&
            !initValue.length
        ) {
            clearItems();
        }
    }, [formData]);

    // Add item submit function
    let addItemSubmit = async (formData) => {
        let item = {
            ingredientType: formData.ingredientType,
            itemMasterExternalSku: formData.itemMasterExternalSku.externalSku,
            packagingMasterSku: formData.packagingMasterSku.masterSKU,
            name: formData.itemMasterExternalSku
                ? formData.itemMasterExternalSku.name
                : formData.packagingMasterSku.description,
            qty: formData.qty,
            uom: formData.itemMasterExternalSku ? formData.uom : 'ct',
        };
        let items = bomIngredients.concat(item);
        setBomIngredients(items);
    };

    // Remove an item from the PO
    let removeItemPressed = async (row) => {
        var array = [...bomIngredients];
        array.splice(row.index, 1);
        setBomIngredients(array);
    };

    useEffect(() => {
        setFormField(bomIngredients);
    }, [bomIngredients]);

    let bomIngredientFormFields = [
        {
            name: 'ingredientType',
            label: 'Ingredient Type',
            inputElement: 'select',
            inputProps: { opts: ['Item Master', 'Packaging'] },
        },
        {
            name: 'itemMasterExternalSku',
            label: 'Item Master',
            display: (formData) => formData.ingredientType === 'Item Master',
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: itemMasterOptions },
        },
        {
            name: 'packagingMasterSku',
            label: 'Packaging',
            display: (formData) => formData.ingredientType === 'Packaging',
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: packagingOptions },
        },
        {
            name: 'uom',
            label: 'UOM',
            display: (formData) => formData.ingredientType === 'Item Master',
            inputElement: 'select',
            inputProps: { opts: ['ct', 'oz', 'lb'], required: true },
        },
        { name: 'qty', label: 'Quantity', inputElement: 'textField', inputProps: { required: true, type: 'number' } },
    ];

    let bomIngredientColumns = [
        { accessor: 'ingredientType', Header: 'Ingredient Type' },
        { accessor: 'itemMasterExternalSku', Header: 'Item Master External SKU' },
        { accessor: 'packagingMasterSku', Header: 'Packaging Master SKU' },
        { accessor: 'name', Header: 'Name' },
        { accessor: 'uom', Header: 'UOM' },
        { accessor: 'qty', Header: 'Quantity' },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h4>Ingredients</h4>
            <FormComponent
                formFields={bomIngredientFormFields}
                onSubmit={async (formData, resetForm) => {
                    addItemSubmit(formData);
                    resetForm();
                }}
                button={{
                    text: 'Add',
                    gridValue: 1,
                    disabled: (formData) => {
                        if (!formData.itemMasterExternalSku && !formData.packagingMasterSku) {
                            return true;
                        }
                        return false;
                    },
                }}
            />
            <DataTable
                columns={bomIngredientColumns}
                data={bomIngredients}
                editable={true}
                saveEdit={({ id, field, value, row }) => {
                    let items = JSON.parse(JSON.stringify(bomIngredients)).map((item) => {
                        if (item.id === id) {
                            item[field] = value;
                        }
                        return item;
                    });
                    setBomIngredients(items);
                }}
            />
        </div>
    );
};

let CreateItemLevelTwo = () => {
    let [error, setError] = useState(null);
    let [items, setItems] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [autocompleteKey, setAutocompleteKey] = useState(1);
    let [itemMaster, setItemMaster] = useState([]);
    let [packaging, setPackaging] = useState([]);
    let [taxonomy, setTaxonomy] = useState({ l1: [], l2: [], l3: [], l4: [] });
    let [tempZones, setTempZones] = useState({ shipping: [], storage: [], receiving: [] });
    let [typeOptions, setTypeOptions] = useState([]);

    const getTempZones = async () => {
        let res = await api.getItemTempZones();
        if (!res.status) {
            setNotification({ text: 'Failed to get item temp zones', level: 'error' });
            return;
        }

        setTempZones(res.data);
    };

    const getTaxonomyOptions = (row, level) => {
        let parentID = level > 1 ? Number(row[`l${level - 1}TaxonomyID`]) : 0;
        let opts = taxonomy[`l${level}`]
            .filter((item) => item.parentID == parentID)
            .map((item) => ({ text: item.name, value: item.id }));
        if (level > 2) {
            opts.unshift({ text: '', value: null });
        }
        return opts;
    };

    let getTaxonomy = async () => {
        let promises = [1, 2, 3, 4].map((lvl) => api.getTaxonomyByLevel(lvl, 'TAXONOMY'));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setTaxonomy({
                l1: res[0].data.categories,
                l2: res[1].data.categories,
                l3: res[2].data.categories,
                l4: res[3].data.categories,
            });
        }
    };

    let formFields = [
        { name: 'name', inputElement: 'textField', label: 'Name', gridValue: 6, inputProps: { required: true } },
        {
            name: 'type',
            inputElement: 'select',
            label: 'Type',
            gridValue: 6,
            inputProps: { required: true, opts: typeOptions },
        },
        {
            name: 'cube',
            inputElement: 'textField',
            label: 'Cube',
            gridValue: 6,
            inputProps: { required: true, type: 'number' },
        },
        {
            name: 'numPerRPC',
            inputElement: 'textField',
            label: 'Units Per RPC',
            gridValue: 6,
            inputProps: { required: true, type: 'number' },
        },
        {
            name: 'l1TaxonomyID',
            inputElement: 'select',
            label: 'Item Category One',
            gridValue: 6,
            inputProps: { required: true, opts: (formData) => getTaxonomyOptions(formData, 1) },
        },
        {
            name: 'l2TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Two',
            gridValue: 6,
            dependencies: ['l1TaxonomyID'],
            inputProps: { required: true, opts: (formData) => getTaxonomyOptions(formData, 2) },
        },
        {
            name: 'l3TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Three',
            gridValue: 6,
            dependencies: ['l1TaxonomyID', 'l2TaxonomyID'],
            inputProps: { opts: (formData) => getTaxonomyOptions(formData, 3) },
        },
        {
            name: 'l4TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Four',
            gridValue: 6,
            dependencies: ['l1TaxonomyID', 'l2TaxonomyID', 'l3TaxonomyID'],
            inputProps: {
                opts: (formData) => getTaxonomyOptions(formData, 4),
            },
        },
        {
            name: 'baseUnitSize',
            inputElement: 'textField',
            label: 'Base Unit Size',
            gridValue: 4,
            inputProps: { required: true, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'baseUnitUOM',
            inputElement: 'select',
            label: 'Base Unit UOM',
            gridValue: 4,
            initialValue: 'ct',
            inputProps: { required: true, opts: ['oz', 'ct', 'lb', 'floz', 'bunch'] },
        },
        {
            name: 'storageTempZone',
            inputElement: 'select',
            label: 'Storage Temp Zone',
            gridValue: 4,
            inputProps: { required: true, opts: tempZones.storage },
        },
        {
            name: 'shippingTempZone',
            inputElement: 'select',
            label: 'Shipping Temp Zone',
            gridValue: 4,
            inputProps: { required: true, opts: tempZones.shipping },
        },
        { name: 'bom', inputElement: () => <h2>Bill of Materials</h2> },
        // {name: "createBom", inputElement: "checkbox", label: "Create BOM", gridValue: 12},
        { name: 'laborCost', inputElement: 'textField', label: 'Labor Cost', inputProps: { type: 'number' } },
        {
            name: 'bomIngredients',
            initialValue: [],
            inputElement: ({ formData, setFormField }) => (
                <AddBOMIngredients
                    formData={formData}
                    setFormField={setFormField}
                    itemMasterOptions={itemMaster}
                    packagingOptions={packaging}
                    setNotification={setNotification}
                />
            ),
        },
    ];

    let createItemLevelTwo = async (formData) => {
        let sendParams = {
            name: formData.name,
            type: formData.type.toUpperCase(),
            cube: formData.cube,
            numPerRPC: formData.numPerRPC,
            l1TaxonomyID: formData.l1TaxonomyID,
            l2TaxonomyID: formData.l2TaxonomyID,
            l3TaxonomyID: formData.l3TaxonomyID,
            l4TaxonomyID: formData.l4TaxonomyID,
            baseUnitSize: formData.baseUnitSize,
            baseUnitUOM: formData.baseUnitUOM,
            storageTempZone: formData.storageTempZone,
            shippingTempZone: formData.shippingTempZone,
            createBom: true,
            laborCost: formData.laborCost,
            bomIngredients: formData.bomIngredients,
        };

        let response = await api.createItemLevelTwo(sendParams);
        if (response.status === true) {
            setNotification({ text: 'Item Level 2 created!', level: 'success' });
        } else {
            let message = 'Error creating item level 2';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }

        return response.status;
    };

    let getItemMasters = async () => {
        // DONT GET ITEM LEVEL TWOS
        let itemMasterRes = await api.getItemInformation(true, 'itemMaster');
        if (itemMasterRes.status === true) {
            let items = itemMasterRes.data.items.map((item) => {
                return {
                    text: `${item.externalSku} (${item.name})`,
                    value: item,
                };
            });
            setItemMaster(items);
        }
    };

    const getItemLevelTwoTypes = async () => {
        let res = await api.getItemLevelTwoTypes();
        if (!res.status) {
            setNotification({ text: 'Failed to get IL2 types', level: 'error' });
            return;
        }

        setTypeOptions(['Please Select'].concat(res.data.types));
    };

    let getPackaging = async () => {
        let packagingRes = await packagingApi.getPackaging();
        if (packagingRes.status) {
            let items = packagingRes.data.rows.map((item) => {
                return { text: `SKU ${item.model.masterSKU}: ${item.model.description}`, value: item.model };
            });
            setPackaging(items);
        }
    };

    useEffect(() => {
        getItemMasters();
        getPackaging();
        getTaxonomy();
        getTempZones();
        getItemLevelTwoTypes();
    }, []);

    return (
        <div>
            <h1>Create Item Level Two</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        console.log(formData);
                        let res = await createItemLevelTwo(formData);
                        if (res) {
                            resetForm();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export { CreateItemLevelTwo };
