import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const Expandable = ({ header, content, expandedNotices = [], initialExpandedValue = false }) => {
    const [expanded, setExpanded] = useState(initialExpandedValue);
    return (
        <div className="fam-expandable-container">
            <div className="fam-warning-item-one">
                <span className="grotesk-bold">{header}</span>
            </div>
            <div className="fam-warning-item-two">
                <div
                    className="fam-align-center"
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    {expanded ? <RemoveIcon /> : <AddIcon />}
                </div>
            </div>
            <div>{expanded && <div className="grotesk-regular">{content}</div>}</div>
        </div>
    );
};

export { Expandable };
