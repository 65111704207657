import React, { useState, useEffect } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { catalogApi as api } from '../../../api/catalog';
import { partnershipsApi } from '../../../api/partnerships';
// import { CreateItemMaster } from "./createItemMaster";
import { Grid, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

let CreateBundleModal = ({ setNotification, getBundles, setCreateBundleModalOpen }) => {
    let createBundleFormFields = [
        { label: 'Name', name: 'name', gridValue: 3, inputProps: { required: true } },
        { label: 'Price', name: 'price', gridValue: 2, inputProps: { required: true, type: 'number', step: '.01' } },
        {
            label: 'MSRP Price',
            name: 'msrpPrice',
            gridValue: 2,
            inputProps: { required: true, type: 'number', step: '.01' },
        },
        {
            label: 'Max Quantity',
            name: 'maxQuantity',
            gridValue: 2,
            inputProps: { required: true, type: 'number', integer: true, max: '10' },
        },
        { label: 'Description', name: 'description', gridValue: 12, inputProps: { multiline: true, required: true } },
    ];

    return (
        <Dialog
            open={true}
            maxWidth="md"
            fullWidth={true}
            onClose={() => {
                setCreateBundleModalOpen(false);
            }}
        >
            <DialogTitle>Create Bundle</DialogTitle>
            <DialogContent>
                <FormComponent
                    formFields={createBundleFormFields}
                    onSubmit={async (formData, resetForm) => {
                        let res = await api.createBundle(formData);
                        if (!res.status) {
                            setNotification({ text: 'Failed to create bundle. ' + res.msg, level: 'error' });
                            return;
                        }
                        getBundles();
                        setNotification({ text: 'Bundle Created', level: 'success' });
                        setCreateBundleModalOpen(false);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let EditAisleModal = ({ selectedAisle, setEditAisleModalOpen, taxonomy, getBundles, setNotification }) => {
    const getTaxonomyOptions = (row, level) => {
        let parentID = level > 1 ? row[`l${level - 1}AisleID`] : 0;
        let opts = taxonomy[`l${level}`]
            .filter((item) => item.parentID === Number(parentID))
            .map((item) => ({ text: item.name, value: item.id }));
        opts.unshift({ text: '', value: null });
        return opts;
    };

    let addAisleFields = [
        {
            name: 'l1AisleID',
            inputElement: 'select',
            label: 'Level 1 Aisle',
            gridValue: 3,
            inputProps: { required: true, opts: getTaxonomyOptions({}, 1) },
        },
        {
            name: 'l2AisleID',
            inputElement: 'select',
            label: 'Level 2 Aisle',
            gridValue: 3,
            dependencies: ['l1AisleID'],
            inputProps: {
                opts: (formData) => {
                    if (!formData.l1AisleID) {
                        return [];
                    }
                    return getTaxonomyOptions(formData, 2);
                },
            },
        },
        {
            name: 'l3AisleID',
            inputElement: 'select',
            label: 'Level 3 Aisle',
            gridValue: 3,
            dependencies: ['l1AisleID', 'l2AisleID'],
            inputProps: {
                opts: (formData) => {
                    if (!formData.l1AisleID || !formData.l2AisleID) {
                        return [];
                    }
                    return getTaxonomyOptions(formData, 3);
                },
            },
        },
        {
            name: 'l4AisleID',
            inputElement: 'select',
            label: 'Level 4 Aisle',
            gridValue: 3,
            dependencies: ['l1AisleID', 'l2AisleID', 'l3AisleID'],
            inputProps: {
                opts: (formData) => {
                    if (!formData.l1AisleID || !formData.l2AisleID || !formData.l3AisleID) {
                        return [];
                    }
                    return getTaxonomyOptions(formData, 4);
                },
            },
        },
    ];

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={true}
            onClose={() => {
                setEditAisleModalOpen(false);
            }}
        >
            <DialogTitle>Edit Aisle Taxonomy - Bundle ID: {selectedAisle.id}</DialogTitle>
            <DialogContent>
                <DataTable
                    editable={true}
                    columns={[
                        {
                            accessor: 'l1AisleID',
                            Header: 'L1 Aisle',
                            editable: true,
                            editProps: {
                                type: 'select',
                                options: (row) => getTaxonomyOptions(row, 1),
                            },
                        },
                        {
                            accessor: 'l2AisleID',
                            Header: 'L2 Aisle',
                            editable: true,
                            editProps: {
                                type: 'select',
                                options: (row) => getTaxonomyOptions(row, 2),
                            },
                        },
                        {
                            accessor: 'l3AisleID',
                            Header: 'L3 Aisle',
                            editable: true,
                            editProps: {
                                type: 'select',
                                options: (row) => getTaxonomyOptions(row, 3),
                            },
                        },
                        {
                            accessor: 'l4AisleID',
                            Header: 'L4 Aisle',
                            editable: true,
                            editProps: {
                                type: 'select',
                                options: (row) => getTaxonomyOptions(row, 4),
                            },
                        },
                        {
                            Header: 'Remove',
                            Cell: ({ row }) => {
                                return (
                                    <Button
                                        onClick={async () => {
                                            let res = await api.deleteExternalSkuAisleMapping(row.original.id);
                                            if (!res.status) {
                                                setNotification({
                                                    level: 'error',
                                                    text: `Failed to remove aisle mapping: ${res.msg}`,
                                                });
                                                return;
                                            }
                                            getBundles();
                                        }}
                                    >
                                        Remove
                                    </Button>
                                );
                            },
                        },
                    ]}
                    data={selectedAisle.aisles}
                    saveEdit={async ({ id, field, value }) => {
                        let res = await api.editExternalSkuAisleMapping(id, { [field]: value });
                        if (!res.status) {
                            setNotification({ level: 'error', text: `Failed to edit aisle mapping: ${res.msg}` });
                            return false;
                        }
                        getBundles();
                        return true;
                    }}
                />

                <h3>Add Aisle Mapping</h3>
                <FormComponent
                    formFields={addAisleFields}
                    onSubmit={async (formData, resetForm) => {
                        formData.bundleID = selectedAisle.id;
                        let res = await api.createExternalSkuAisleMapping(formData);
                        if (!res.status) {
                            setNotification({ level: 'error', text: `Failed to create aisle mapping: ${res.msg}` });
                            return;
                        }
                        getBundles();
                        resetForm();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let PhotoModal = ({ selectedPhoto, setPhotoModalOpen, updateItemPhoto }) => {
    let [file, setFile] = useState(null);
    let [source, setSource] = useState(null);
    let [title, setTitle] = useState('');
    let [photoType, setPhotoType] = useState('');
    let [loading, setLoading] = useState(false);

    let item = selectedPhoto.item;
    let key = selectedPhoto.key;

    useEffect(() => {
        if (key === 'photoURLSmall') {
            setTitle(`Edit Photo 1 for ${item.name}`);
            setPhotoType('photo1');
        } else if (key === 'photoTwoURLSmall') {
            setTitle(`Edit Photo 2 for ${item.name}`);
            setPhotoType('photo2');
        } else if (key === 'photoThreeURLSmall') {
            setTitle(`Edit Photo 3 for ${item.name}`);
            setPhotoType('photo3');
        } else if (key === 'photoFourURLSmall') {
            setTitle(`Edit Photo 4 for ${item.name}`);
            setPhotoType('photo4');
        } else if (key === 'photoFiveURLSmall') {
            setTitle(`Edit Photo 5 for ${item.name}`);
            setPhotoType('photo5');
        } else {
            console.log('unrecognized key', key, selectedPhoto);
        }
    }, []);

    return (
        <Dialog
            open={true}
            onClose={() => {
                setPhotoModalOpen(false);
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {item[key] && !source && <img src={`https://static.misfitsmarket.com/${item[key]}`} alt="Item" />}
                {source && (
                    <div style={{ textAlign: 'center' }}>
                        <img src={source} alt="Preview" />
                    </div>
                )}
                <Button style={{ textAlign: 'center' }} variant="outlined" component="label">
                    Choose File
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        name="Photo Upload"
                        id="photo-file-input"
                        accept="image/png, image/jpeg"
                        onChange={(event) => {
                            let file = event.target.files[0];
                            setFile(file);

                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = (e) => {
                                setSource(e.target.result);
                            };
                        }}
                    />
                </Button>
            </DialogContent>
            <DialogActions>
                {!!item[key] && (
                    <Button
                        color="secondary"
                        disabled={loading === true}
                        onClick={async () => {
                            setLoading(true);
                            let res = await updateItemPhoto(photoType, '', item.id);
                            setLoading(false);
                            if (res === true) {
                                setPhotoModalOpen(false);
                            }
                        }}
                    >
                        Remove Photo
                    </Button>
                )}
                <Button
                    disabled={file === null || loading === true}
                    onClick={async () => {
                        setLoading(true);
                        let res = await updateItemPhoto(photoType, file, item.id);
                        setLoading(false);
                        if (res === true) {
                            setPhotoModalOpen(false);
                        }
                    }}
                >
                    Confirm
                </Button>
                <Button disabled={loading} onClick={() => setPhotoModalOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

let PhotoCell = ({ row, column, setPhotoModalOpen, setSelectedPhoto }) => {
    let key = column.id;
    if (row.original[key]) {
        return (
            <div>
                <Button
                    onClick={() => {
                        setSelectedPhoto({ item: row.original, key });
                        setPhotoModalOpen(true);
                    }}
                >
                    <img
                        src={`https://static.misfitsmarket.com/${row.original[key]}`}
                        style={{ height: '100px', width: '100px' }}
                    />
                </Button>
            </div>
        );
    } else {
        return (
            <div>
                <IconButton
                    id={`${column.Header.replaceAll(' ', '')}_${row.id}_upload`}
                    onClick={() => {
                        setSelectedPhoto({ item: row.original, key });
                        setPhotoModalOpen(true);
                    }}
                >
                    <CloudUploadIcon />
                </IconButton>
            </div>
        );
    }
};

let AislesCell = ({ row, taxonomy, setSelectedAisle, setEditAisleModalOpen }) => {
    let aisles = row.original.aisles;

    return (
        <div>
            {aisles.map((item, index) => {
                return (
                    <div key={`${index}`} style={{ paddingTop: '2px' }}>
                        {!!item.l1AisleID && (
                            <span style={{ display: 'inline' }}>
                                {taxonomy?.l1?.find((tx) => tx.id === item.l1AisleID)?.name}
                            </span>
                        )}
                        {!!item.l2AisleID && (
                            <span style={{ display: 'inline' }}>
                                {' '}
                                -> {taxonomy?.l2?.find((tx) => tx.id === item.l2AisleID)?.name}
                            </span>
                        )}
                        {!!item.l3AisleID && (
                            <span style={{ display: 'inline' }}>
                                {' '}
                                -> {taxonomy?.l3?.find((tx) => tx.id === item.l3AisleID)?.name}
                            </span>
                        )}
                        {!!item.l4AisleID && (
                            <span style={{ display: 'inline' }}>
                                {' '}
                                -> {taxonomy?.l4?.find((tx) => tx.id === item.l4AisleID)?.name}
                            </span>
                        )}
                    </div>
                );
            })}
            <div>
                <Button
                    onClick={() => {
                        setSelectedAisle(row.original);
                        setEditAisleModalOpen(true);
                    }}
                >
                    EDIT
                </Button>
            </div>
        </div>
    );
};

let Bundles = () => {
    const [bundles, setBundles] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [photoModalOpen, setPhotoModalOpen] = useState(false);
    const [editAisleModalOpen, setEditAisleModalOpen] = useState(false);
    const [thirdPartyItems, setThirdPartyItems] = useState([]);
    const [createBundleModalOpen, setCreateBundleModalOpen] = useState(false);
    const [taxonomy, setTaxonomy] = useState({ l1: [], l2: [], l3: [], l4: [] });
    const [selectedAisle, setSelectedAisle] = useState({});

    useEffect(() => {
        getBundles();
        getThirdPartyItems();
        getTaxonomy();
    }, []);

    let getTaxonomy = async () => {
        let promises = [1, 2, 3, 4].map((lvl) => api.getTaxonomyByLevel(lvl, 'AISLE'));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setTaxonomy({
                l1: res[0].data.categories,
                l2: res[1].data.categories,
                l3: res[2].data.categories,
                l4: res[3].data.categories,
            });
        }
    };

    const bundleColumns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Name', accessor: 'name', editable: true },
        { Header: 'Active', accessor: 'active', editable: true, type: 'checkbox', editProps: { type: 'checkbox' } },
        { Header: 'Description', accessor: 'description', editable: true },
        {
            Header: 'Price',
            accessor: 'price',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            Header: 'MSRP Price',
            accessor: 'msrpPrice',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            Header: 'Max Quantity',
            accessor: 'maxQuantity',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true, min: '0', max: '10', required: true },
        },
        {
            accessor: 'photoURLSmall',
            width: '125px',
            Header: 'Photo',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'photoTwoURLSmall',
            width: '125px',
            Header: 'Photo 2',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'photoThreeURLSmall',
            width: '125px',
            Header: 'Photo 3',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'photoFourURLSmall',
            width: '125px',
            Header: 'Photo 4',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'photoFiveURLSmall',
            width: '125px',
            Header: 'Photo 5',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            Header: 'Aisles',
            width: '200px',
            Cell: ({ row, saveEdit, column }) => AislesCell({ row, taxonomy, setSelectedAisle, setEditAisleModalOpen }),
        },
    ];

    const getBundles = async () => {
        let res = await api.getBundles();
        if (!res.status || !res.data) {
            setNotification({ text: 'Failed to get bundles', level: 'error' });
            return;
        }

        // refresh selected aisle data if it exists
        res.data.bundles.forEach((item) => {
            if (selectedAisle && selectedAisle.hasOwnProperty('id')) {
                if (selectedAisle.id === item.id) {
                    setSelectedAisle(item);
                }
            }
        });

        setBundles(res.data.bundles);
    };

    const getThirdPartyItems = async () => {
        let res = await partnershipsApi.getDrinksItems();
        if (!res.status || !res.data) {
            setNotification({ text: 'Failed to get third party items', level: 'error' });
            return;
        }

        setThirdPartyItems(res.data.items.map((item) => ({ text: item.itemName, value: item.externalSku })));
    };

    let updateItemPhoto = async (key, file, bundleID) => {
        let sendParams = {};
        sendParams.id = bundleID;
        sendParams[key] = file;

        let res = await api.updateBundle(sendParams);
        if (res.status === true) {
            getBundles();
        } else {
            setNotification({ text: 'Error uploading photo: ' + res.msg, level: 'error' });
        }
        return res.status;
    };

    let toolbarActions = [
        {
            name: 'Create New Bundle',
            action: () => {
                console.log('modal open');
                setCreateBundleModalOpen(true);
            },
        },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            {photoModalOpen && (
                <PhotoModal
                    setPhotoModalOpen={setPhotoModalOpen}
                    selectedPhoto={selectedPhoto}
                    updateItemPhoto={updateItemPhoto}
                />
            )}
            {editAisleModalOpen && (
                <EditAisleModal
                    setEditAisleModalOpen={setEditAisleModalOpen}
                    selectedAisle={selectedAisle}
                    taxonomy={taxonomy}
                    getBundles={getBundles}
                    setNotification={setNotification}
                />
            )}
            {createBundleModalOpen && (
                <CreateBundleModal
                    getBundles={getBundles}
                    setNotification={setNotification}
                    setCreateBundleModalOpen={setCreateBundleModalOpen}
                />
            )}
            <DataTable
                columns={bundleColumns}
                data={bundles}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let sendParams = {};
                    sendParams.id = id;
                    sendParams[field] = value;

                    let res = await api.updateBundle(sendParams);
                    if (!res.status) {
                        setNotification({ level: 'error', text: `Failed to update bundle: ${res.msg}` });
                        return false;
                    }
                    getBundles();
                    return true;
                }}
                expandable={true}
                ExpansionComponent={
                    <ViewBundleItems
                        setNotification={setNotification}
                        getBundles={getBundles}
                        thirdPartyItems={thirdPartyItems}
                    />
                }
                toolbarActions={toolbarActions}
                title="Bundles"
            />
        </Grid>
    );
};

let ViewBundleItems = ({ parentRow, setExpandedRow, setNotification, getBundles, thirdPartyItems }) => {
    let formFields = [
        {
            label: 'Item',
            name: 'externalSku',
            gridValue: 6,
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: thirdPartyItems },
        },
        { label: 'Quantity', name: 'quantity', gridValue: 4 },
    ];

    let itemCols = [
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Quantity', accessor: 'quantity' },
        {
            Header: 'Delete',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={async () => {
                            let res = await api.removeItemFromBundle(parentRow.original.id, row.original.externalSku);
                            if (!res.status) {
                                setNotification({ text: 'Failed to remove item from bundle', level: 'error' });
                                return;
                            }

                            getBundles();
                            setNotification({ text: 'Item removed from bundle', level: 'success' });
                        }}
                    >
                        Delete
                    </Button>
                );
            },
        },
    ];
    return (
        <div>
            <h3>Add Item To Bundle</h3>
            <FormComponent
                formFields={formFields}
                button={{
                    gridValue: 2,
                    text: 'Add',
                }}
                onSubmit={async (formData, resetForm) => {
                    let res = await api.addItemToBundle(parentRow.original.id, formData);
                    if (!res.status) {
                        setNotification({ text: 'Failed to add item to bundle. ' + res.msg, level: 'error' });
                        return;
                    }
                    getBundles();
                    setNotification({ text: 'Item added to bundle', level: 'success' });
                    resetForm();
                }}
            />
            <DataTable columns={itemCols} data={parentRow.original.items || []} />
        </div>
    );
};

export { Bundles };
