import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Notifications, DataTable, FormComponent, TabPanel } from '../../../shared';
import { financeApi as api } from '../../../api/finance';
import { Button, Dialog, DialogContent, DialogTitle, Paper, Tabs, Tab } from '@material-ui/core';

const PaymentModal = ({ paymentItem, open, onClose, setNotification }) => {
    if (!paymentItem || !paymentItem.id) {
        return null;
    }

    let maxDate = moment(new Date()).format('YYYY-MM-DD');
    let formFields = [
        {
            name: 'amount',
            inputElement: 'textField',
            label: 'Amount',
            gridValue: 6,
            inputProps: { required: true, type: 'number' },
        },
        {
            name: 'datePaid',
            inputElement: 'date',
            label: 'Date Paid',
            gridValue: 6,
            inputProps: { required: true, max: maxDate },
        },
        { name: 'notes', inputElement: 'textField', label: 'Notes', inputProps: { multiline: true } },
    ];

    let payItem = async (formData, resetForm) => {
        let res;
        if (paymentItem.type === 'purchase order') {
            res = await api.payPurchaseOrder(paymentItem.id, formData);
        } else if (paymentItem.type === 'truck') {
            res = await api.payTruck(paymentItem.id, formData);
        }

        if (!res.status) {
            setNotification({ text: `Failed to pay ${paymentItem.type}. ${res.msg}`, level: 'error' });
            return false;
        }

        resetForm();
        paymentItem.onSuccess();
        onClose();
    };

    return (
        <Dialog maxWidth="md" fullWidth={true} open={open} onClose={onClose}>
            <DialogTitle>
                {paymentItem.type === 'purchase order'
                    ? `Pay Purchase Order ${paymentItem.id}`
                    : `Pay Truck ${paymentItem.id}`}
            </DialogTitle>
            <DialogContent>
                <FormComponent formFields={formFields} onSubmit={payItem} />
            </DialogContent>
        </Dialog>
    );
};

const Payments = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let [notification, setNotification] = useState({ level: null, text: null });

    let [paymentModalOpen, setPaymentModalOpen] = useState(false);
    let [paymentItem, setPaymentItem] = useState(null);

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    let openPaymentModal = (id, type, onSuccess) => {
        setPaymentItem({ id, type, onSuccess });
        setPaymentModalOpen(true);
    };

    return (
        <Paper>
            <Notifications options={notification} />
            <PaymentModal
                setNotification={setNotification}
                paymentItem={paymentItem}
                open={paymentModalOpen}
                onClose={() => {
                    setPaymentItem(null);
                    setPaymentModalOpen(false);
                }}
            />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Purchase Orders" value="0" />
                <Tab label="Inbound Trucks" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <PurchaseOrderPayments setNotification={setNotification} openPaymentModal={openPaymentModal} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <InboundTrucksPayments setNotification={setNotification} openPaymentModal={openPaymentModal} />
            </TabPanel>
        </Paper>
    );
};

const PurchaseOrderPayments = ({ setNotification, openPaymentModal }) => {
    let [purchaseOrders, setPurchaseOrders] = useState([]);

    let getPurchaseOrders = async () => {
        let res = await api.getPurchaseOrdersWithPaymentInfo();
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to get purchase orders. ' + res.msg });
        }

        let pos = res.data.rows.map((item) => {
            item.createdAt = moment(item.createdAt.date).format('MM/DD/YYYY HH:mm a');
            item.scheduledDeliveryDate = moment(item.scheduledDeliveryDate.date).format('MM/DD/YYYY');
            item.datePaid = item.datePaid ? moment(item.datePaid.date).format('MM/DD/YYYY') : null;
            return item;
        });

        setPurchaseOrders(pos);
    };

    useEffect(() => {
        getPurchaseOrders();
    }, []);

    let columns = [
        { accessor: 'poID', Header: 'Purchase Order ID' },
        { accessor: 'createdAt', Header: 'Created At' },
        { accessor: 'scheduledDeliveryDate', Header: 'Scheduled Delivery' },
        { accessor: 'vendorName', Header: 'Vendor' },
        { accessor: 'facilityName', Header: 'Facility' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'datePaid', Header: 'Date Paid' },
        { accessor: 'amount', Header: 'Amount Paid' },
        { accessor: 'paidBy', Header: 'Paid By' },
        { accessor: 'notes', Header: 'Notes' },
    ];

    return (
        <div>
            <DataTable columns={columns} data={purchaseOrders} />
        </div>
    );
};

const InboundTrucksPayments = ({ setNotification, openPaymentModal }) => {
    let [trucks, setTrucks] = useState([]);

    let getInboundTrucks = async () => {
        let res = await api.getTrucksWithPaymentInfo();
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to get inbound trucks. ' + res.msg });
        }

        let fobTrucks = res.data.rows.map((item) => {
            item.createdAt = moment(item.createdAt.date).format('MM/DD/YYYY HH:mm a');
            item.datePaid = item.datePaid ? moment(item.datePaid.date).format('MM/DD/YYYY') : null;
            return item;
        });

        setTrucks(fobTrucks);
    };

    useEffect(() => {
        getInboundTrucks();
    }, []);

    let columns = [
        { accessor: 'truckID', Header: 'Truck ID' },
        { accessor: 'createdAt', Header: 'Created At' },
        { accessor: 'carrierName', Header: 'Carrier' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'datePaid', Header: 'Date Paid' },
        { accessor: 'amount', Header: 'Amount Paid' },
        { accessor: 'paidBy', Header: 'Paid By' },
        { accessor: 'notes', Header: 'Notes' },
        {
            Header: 'Pay',
            Cell: ({ row }) => {
                if (row.original.status === 'PLACED') {
                    return (
                        <Button
                            onClick={async () => {
                                openPaymentModal(row.original.truckID, 'truck', getInboundTrucks);
                            }}
                        >
                            PAY
                        </Button>
                    );
                }
                return null;
            },
        },
    ];

    return (
        <div>
            <DataTable columns={columns} data={trucks} />
        </div>
    );
};

export { Payments };
