import React, { useState, useEffect } from 'react';
import { InputLabel, Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { FormComponent } from '../../../shared';

const ApplyCredit = ({ applyCredit, classes, setNotification }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const primaryReasons = [
        { value: 'CC_STANDARD_APOLOGY_CREDIT', text: 'CC Standard Apology' },
        { value: 'CC_ADDITIONAL_APOLOGY_CREDIT', text: 'CC Additional Apology' },
        { value: 'CC_REFERRAL_OR_COUPON_CREDIT_PROBLEM_CREDIT', text: 'CC Referral or coupon credit problem' },
        { value: 'CC_OTHER_CREDIT', text: 'CC Other' },
        { value: 'CC_CCSS_ONLY_CREDIT', text: 'CCSS Only' }
    ];

    const standardApologyReasons = [
        { value: 'DAMAGED_FOOD_SAFETY', text: 'Damaged food safety' },
        { value: 'FAILED_REDELIVERY', text: 'Failed redelivery' }
    ];

    const additionalApologyReasons = [
        { value: 'BOX_HEAVY_INJURY', text: 'Box heavy/injury' },
        { value: 'CLIMATE', text: 'Climate' },
        { value: 'DAMAGED_BY_ANOTHER_PRODUCT', text: 'Damaged by another product' },
        { value: 'DELIVERY_CANCEL', text: 'Delivery cancel' },
        { value: 'DELIVERY_DELAY', text: 'Delivery delay' },
        { value: 'DOWNGRADE_RETENTION', text: 'Downgrade retention' },
        { value: 'DRIVER_BEHAVIOR', text: 'Driver behavior' },
        { value: 'EXTRA_WRONG_ITEM_RECEIVED', text: 'Extra/wrong item received' },
        { value: 'ICEPACK_DAMAGE', text: 'Icepack damage' },
        { value: 'ITEM_DESCRIPTION_ISSUE', text: 'Item description issue' },
        { value: 'MINOR_PROPERTY_DAMAGE', text: 'Minor property damage' },
        { value: 'PACKAGING_RETURN_FAIL', text: 'Packaging return fail' },
        { value: 'PRODUCT_PACKAGING_ISSUES', text: 'Product packaging issues' },
        { value: 'RECURRING_DELIVERY_ISSUES', text: 'Recurring delivery issues' },
        { value: 'RECURRING_PACK_ISSUES', text: 'Recurring pack issues' },
        { value: 'RECURRING_QUALITY_ISSUES', text: 'Recurring quality issues' },
        { value: 'SHORTS_RELATED_ISSUE', text: 'Shorts related issue' },
        { value: 'WRONG_BOX_RECEIVED_FIRST_TIME', text: 'Wrong box received - first time' }
    ];

    const referralOrCouponCreditProblemReasons = [
        { value: 'DISCOUNTED_ORDER_ISSUES', text: 'Discounted order issues' },
        { value: 'EXPIRED_COUPON_CODES', text: 'Expired coupon codes' },
        { value: 'FORGOT_TO_USE', text: 'Forgot to use' },
        { value: 'REFERRAL_CREDIT_PROBLEM', text: 'Referral credit problem' }
    ];

    const otherReasons = [
        { value: 'CC_ERROR', text: 'CC error' },
        { value: 'EMPLOYEE_BOX_CREDIT', text: 'Employee box credit' },
        { value: 'FOODBORNE_ILLNESS', text: 'Foodborne illness' },
        { value: 'LATE_CANCEL', text: 'Late cancel' },
        { value: 'TAX_ISSUES', text: 'Tax issues' }
    ];
    
    const ccssOnlyReasons = [
        { value: 'CUSTOMER_REWARD_CREDIT', text: 'Customer reward credit' },
        { value: 'EMPLOYEE_REWARD', text: 'Employee reward' },
        { value: 'GIFT_CARD_REDEMPTION', text: 'Gift card redemption' },
        { value: 'PARTNER_CREDIT', text: 'Partner credit' }
    ];

    const handleTitleChangeAndDropdownOptions = (event, value) => {
        let find = primaryReasons.find((f) => f.value === value);
        let cleanedTitle = find.text;

        setTitle(cleanedTitle);
        getOptionsForReason(value);
    };

    const getOptionsForReason = (title) => {
        if(title === 'CC_STANDARD_APOLOGY_CREDIT') {
            setDropdownOptions(standardApologyReasons);
        } else if (title === 'CC_ADDITIONAL_APOLOGY_CREDIT') {
            setDropdownOptions(additionalApologyReasons);
        } else if (title === 'CC_REFERRAL_OR_COUPON_CREDIT_PROBLEM_CREDIT') {
            setDropdownOptions(referralOrCouponCreditProblemReasons);
        } else if (title === 'CC_OTHER_CREDIT') {
            setDropdownOptions(otherReasons);
        } else if (title === 'CC_CCSS_ONLY_CREDIT') {
            setDropdownOptions(ccssOnlyReasons);
        }
    }

    const OtherTextReasonFormField = ({ formData, setFormField }) => {
        return (
            <>
                <p>Reasons</p>
                <TextField
                    style={{ width: '100%' }}
                    inputProps={{ maxLength: 240 }}
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                    placeholder="Add other reason"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(event) => {
                        setFormField(event.target.value);
                    }}
                />
            </>
        );
    };

    return (
        <>
            <Dialog
                maxWidth="md"
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <DialogTitle>Apply {title} Credit</DialogTitle>
                <DialogContent>
                    <FormComponent
                        inputElementStyle={{ padding: '0px', margin: '0px' }}
                        formControlStyle={{ padding: '0px 3px' }}
                        formFields={[
                            {
                                name: 'primaryReason',
                                label: 'Credit Reason',
                                inputElement: 'select',
                                inputProps: {
                                    opts: primaryReasons,
                                    required: true,
                                    style: {
                                        background: '#FFFFFF',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        border: '1px solid lightgray',
                                    },
                                    onChange: handleTitleChangeAndDropdownOptions,
                                    disableUnderline: true,
                                },
                            },
                            {
                                name: 'secondaryReason',
                                label: 'Secondary Reason',
                                dependencies: ['primaryReason'],
                                inputElement: 'select',
                                inputProps: {
                                    opts: dropdownOptions,
                                    required: true,
                                    style: {
                                        background: '#FFFFFF',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        border: '1px solid lightgray',
                                    },
                                    disableUnderline: true,
                                },
                            },
                            {
                                name: 'otherTextReason',
                                initialValue: '',
                                dependencies: ['primaryReason'],
                                inputElement: ({ formData, setFormField }) => (
                                    <OtherTextReasonFormField formData={formData} setFormField={setFormField} />
                                ),
                            },
                            {
                                name: 'amount',
                                dependencies: ['primaryReason'],
                                inputElement: ({ formData, setFormField }) => (
                                    <TextField
                                        style={{ borderRadius: '8px', marginTop: '30px', width: '100%' }}
                                        placeholder="$0.00"
                                        variant="outlined"
                                        label="Amount"
                                        inputProps={{ required: true, type: 'number', step: '.01', max: 100 }}
                                        disableUnderline={true}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => {
                                            setFormField(e.target.value);
                                        }}
                                    />
                                ),
                            },
                            
                        ]}
                        button={{ style: { marginTop: '30px' } }}
                        onSubmit={async (formData, reset) => {
                            let sendParams = {
                                reason: formData.primaryReason,
                                secondaryReason: formData.secondaryReason,
                                otherTextReason: formData.otherTextReason,
                                amount: Number(formData.amount),
                                sourceType: formData.primaryReason,
                            };

                            if (Number(formData.amount) <= 0) {
                                setNotification({ text: 'Must provide amount', level: 'error' });
                            }

                            let res = await applyCredit(sendParams);
                            if (!res) {
                                setNotification({ text: 'Error applying credit: ' + res.msg, level: 'error' });
                            }
                            reset();
                            setModalOpen(false);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setModalOpen(false);
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <InputLabel className={classes.inputLabel}>Apply Credit</InputLabel>
            <Button
                style={{ marginTop: '15px' }}
                variant="outlined"
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                Apply Credit
            </Button>
        </>
    );
};

export { ApplyCredit };
