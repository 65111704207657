import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { FormComponent, Notifications } from '../../../shared';
import { productApi as api } from '../../../api/product';

let UploadLineItem = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [ffOps, setFFOps] = useState([]);

    let formFields = [
        {
            name: 'customerFile',
            inputElement: 'file',
            label: 'Subscription IDs',
            gridValue: 6,
            inputProps: { required: true },
        },
    ];

    return (
        <div>
            <h1>Assign customers to LIP (file just list of IDs, header of subscription_id)</h1>
            <Notifications options={notification} />
            {}
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let response = await api.uploadLineItem(formData.customerFile);
                        if (response.status === false) {
                            setNotification({ text: 'Unable to upload LIP ' + response.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        resetForm();
                    }}
                />
            </div>
        </div>
    );
};

export { UploadLineItem };
