import React, { useContext, useState, useEffect } from 'react';
import { Paper, TextField, Button, Select, MenuItem, Grid } from '@material-ui/core';
import { DataTable, Notifications, FacilityPicker } from '../../../../shared';
import { UserContext } from '../../../../contexts/UserContext';
import { fbmApi as api } from '../../../../api/fbm';
import moment from 'moment';

const ViewExternalSkuUsage = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [reportIsReady, setReportIsReady] = useState(false);
    let [reportButtonText, setReportButtonText] = useState('Loading...');
    let [facilityID, setFacilityID] = useState(0);
    const user = useContext(UserContext);

    let { fbmAccount } = useContext(UserContext);

    let handleRunReportClick = (event) => {
        getAndRenderReportData();
    };

    let columns = [
        { accessor: 'externalSku', Header: 'External Sku' },
        { accessor: 'itemName', Header: 'Item Name' },
        { accessor: 'averageUnitsPackedPerDay', Header: 'Average Units Packed Per Day' },
    ];

    let getAndRenderReportData = async () => {
        setReportIsReady(false);

        setReportButtonText('Loading...');
        setRows([]);

        let response = await api.getReportExternalSkuUsageView(fbmAccount.fbmAccountName, facilityID);

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportButtonText('Run Report');
            setReportIsReady(true);
            return;
        }

        setRows(response.data.rows);
        setReportButtonText('Run Report');
        setReportIsReady(true);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getAndRenderReportData();
        }
    }, [fbmAccount]);

    return (
        <Paper>
            <Notifications options={notification} />

            <Grid container>
                <Grid item xs={12}>
                    <h2>{fbmAccount.companyName} - Units Packed per Day (over last 90 days from today)</h2>
                    <FacilityPicker
                        defaultValue={fbmAccount.facilityIDs[0]}
                        whitelistedFacilities={fbmAccount.facilityIDs}
                        activeOnly={true}
                        onChange={(facilityID, text, facility) => {
                            setFacilityID(facilityID);
                        }}
                    />
                    <Grid item md={12} style={{ paddingRight: '35px' }}>
                        <Button
                            id="runReport"
                            onClick={handleRunReportClick}
                            color="secondary"
                            disabled={!reportIsReady}
                        >
                            {reportButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <span>Note: Report may take up to 2 minutes to run.</span>

            <br />

            <DataTable
                csvExport={reportIsReady}
                csvExportFileName="external-sku-usage"
                csvExportHeadersUseAccessor={true}
                columns={columns}
                data={rows}
                editable={false}
            />
        </Paper>
    );
};

export { ViewExternalSkuUsage };
