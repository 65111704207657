import React, { useState, useEffect } from 'react';
import { Button, Grid, IconButton, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Notifications, FormComponent, DataTable } from '../../../shared';
import { marketingApi as api } from '../../../api/marketing';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

let ViewVipCustomers = () => {
    let [vipCustomerInfo, setVipCustomerInfo] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let getVipCustomerInfo = async () => {
        let response = await api.getVipCustomerList();

        if (response.status === true) {
            setVipCustomerInfo(
                response.data.rows.map((row) => {
                    row.id = row.id;
                    return row;
                })
            );
        }
    };

    let deleteCustomer = async (id) => {
        let res = await api.deleteVipCustomer(id);
        if (!res.status) {
            setNotification({ text: 'Failed to delete VIP Customer', level: 'error' });
            return;
        }

        setNotification({ text: 'VIP Customer deleted', level: 'success' });
        getVipCustomerInfo();
    };

    useEffect(() => {
        getVipCustomerInfo();
    }, []);

    let columns = [
        { accessor: 'customerID', Header: 'Customer ID', width: 50 },
        { accessor: 'subscriptionID', Header: 'Subscription ID', width: 50 },
        { accessor: 'email', Header: 'Email', width: 150 },
        { accessor: 'insertedAt', Header: 'TimeStamp Added', width: 150 },
        {
            accessor: 'delete',
            Header: 'Delete',
            Cell: ({ cell: { value: initialValue }, row }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={async () => {
                                let response = await deleteCustomer(row.original.id);
                                if (response.status === false) {
                                    setNotification({
                                        text: 'Failed to delete VIP Customer ' + response.msg,
                                        level: 'error',
                                    });
                                    return;
                                }
                                setNotification({ text: 'Deleted VIP Customer', level: 'success' });
                                await getVipCustomerInfo();
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <h2>VIP Customers</h2>
            <Grid>
                <Notifications options={notification} />
                <DataTable editable={false} columns={columns} data={vipCustomerInfo} />
            </Grid>
        </div>
    );
};

export { ViewVipCustomers };
