import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { infoTechApi as api } from '../../../api/infoTech';
import { warehouseApi } from '../../../api/warehouse';
import { partnershipsApi } from '../../../api/partnerships';

let CreateJalapenoAccount = ({ accountType = 'MISFITS MARKET' }) => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [profiles, setProfiles] = useState([]);
    let [facilities, setFacilities] = useState([]);
    const [famAccounts, setFamAccounts] = useState([]);

    const getFamAccounts = async () => {
        const res = await partnershipsApi.getSources();
        if (!res.status) {
            return;
        }

        setFamAccounts(res.data.rows.map((row) => ({ text: row.name, value: row.id })));
    };

    let formFields = [
        { name: 'email', inputElement: 'textField', label: 'Email', gridValue: 6 },
        {
            name: 'profileID',
            inputElement: 'select',
            label: 'Profile',
            gridValue: 6,
            inputProps: { required: true, opts: profiles },
        },
        {
            name: 'orderSourceID',
            inputElement: 'select',
            label: 'FAM Account',
            display: accountType === 'FOOD AS MEDICINE',
            gridValue: 6,
            inputProps: { required: true, opts: famAccounts },
        },
        {
            name: 'facilityID',
            inputElement: 'select',
            label: 'Facility',
            display: accountType === 'MISFITS MARKET',
            gridValue: 6,
            inputProps: { required: true, opts: facilities },
        },
        {
            name: 'language',
            inputElement: 'select',
            label: 'Language',
            display: accountType === 'MISFITS MARKET',
            gridValue: 6,
            inputProps: {
                required: true,
                opts: [
                    { text: 'English', value: 'EN' },
                    { text: 'Spanish', value: 'ES' },
                ],
            },
        },
        {
            name: 'firstName',
            display: (formData) => !formData.createFromPicker,
            inputElement: 'textField',
            label: 'First Name',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'lastName',
            display: (formData) => !formData.createFromPicker,
            inputElement: 'textField',
            label: 'Last Name',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'badgeNumber',
            display: (formData) => accountType === 'MISFITS MARKET' && !formData.createFromPicker,
            inputElement: 'textField',
            label: 'Badge Number',
            gridValue: 4,
        },
    ];

    // Load profiles
    let getDefaultProfiles = async () => {
        let response = await api.getJalapenoProfiles(true, accountType);
        if (response.status === false) {
            setNotification({ text: 'No profiles found ' + response.msg, level: 'error' });
            setProfiles([]);
            return;
        }

        let profilesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setProfiles([pleaseSelect].concat(profilesAsOpts));
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setFacilities(facilitiesAsOpts);
    };

    useEffect(() => {
        getDefaultProfiles();
        getFacilities();
        getFamAccounts();
    }, []);

    let createAccount = async (formData) => {
        let response;
        if (accountType === 'FOOD AS MEDICINE') {
            response = await api.createFoodAsMedicineAccount(formData);
        } else if (accountType === 'MISFITS MARKET') {
            response = await api.createAccount(formData);
        } else {
            setNotification({ text: 'Invalid Account Type', level: 'error' });
        }
        if (response.status === true) {
            setNotification({ text: 'Account created!', level: 'success' });
        } else {
            let message = 'Error creating account';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
        return response.status;
    };

    return (
        <div>
            <h1>Create {accountType === 'MISFITS MARKET' ? 'Jalapeno' : 'Food as Medicine'} Account</h1>
            <Notifications options={notification} />
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        if (formData.badgeNumber === '' && formData.email === '') {
                            let message = 'Badge number and/or email required';
                            setNotification({ text: message, level: 'error' });
                        } else {
                            let res = await createAccount(formData);
                            if (res) {
                                resetForm();
                            }
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

export { CreateJalapenoAccount };
