import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Button } from '@material-ui/core';
import { procurementApi as api } from '../../../api/procurement';
import { inboundTruckApi } from '../../../api/inboundTrucks';
import { UserContext } from '../../../contexts/UserContext';

const AddCreditItems = ({
    formData,
    setFormField,
    poProducts,
    setNotification,
    accountingStatusOpts,
    reasons,
    initialValue,
}) => {
    const [productCost, setProductCost] = useState(0);
    const [dumpCost, setDumpCost] = useState(0);
    const [laborCost, setLaborCost] = useState(0);
    const [freightCost, setFreightCost] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [runningTotal, setRunningTotal] = useState(0);

    let initValue = [];
    if (initialValue && initialValue.additionalData) {
        initValue = initialValue.additionalData;
    }

    const [itemsOnCredit, setItemsOnCredit] = useState(initValue);

    let removeItemPressed = async (row) => {
        var array = [...itemsOnCredit];
        array.splice(row.index, 1);
        setItemsOnCredit(array);
    };

    useEffect(() => {
        setTotalAmount(
            parseFloat(productCost) + parseFloat(dumpCost) + parseFloat(laborCost) + parseFloat(freightCost)
        );
    }, [productCost, dumpCost, laborCost, freightCost]);

    useEffect(() => {
        setFormField(itemsOnCredit);
        setRunningTotal(0);
        itemsOnCredit.map((item) => setRunningTotal((tot) => (tot += item.totalAmount)));
    }, [itemsOnCredit]);

    useEffect(() => {
        if (itemsOnCredit.length && !formData.itemsOnCredit && !initValue.length) {
            setItemsOnCredit([]);
        }
    }, [formData]);

    let creditItemColumns = [
        { accessor: 'poMapID', Header: 'Product SKU' },
        {
            accessor: 'numberOfCasesAffected',
            Header: 'Number of Cases Affected',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '1', min: '0' },
        },
        {
            accessor: 'reasons',
            Header: 'Reasons',
            editable: true,
            filterValues: reasons,
            editProps: {
                type: 'autocomplete-multiple',
                options: reasons,
                multiple: true,
            },
        },
        {
            accessor: 'productCost',
            Header: 'Product Cost',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'dumpCost',
            Header: 'Dump Cost',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'laborCost',
            Header: 'Labor Cost',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'freightCost',
            Header: 'Freight Cost',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        { accessor: 'totalAmount', Header: 'Total Amount' },
        {
            accessor: 'inspectionCost',
            Header: 'USDA Inspection Cost',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'otherCosts',
            Header: 'Other Costs',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'accountingStatus',
            Header: 'Accounting Status',
            editable: true,
            editProps: {
                type: 'select',
                options: accountingStatusOpts,
            },
        },
        {
            accessor: 'readyForReview',
            Header: 'Ready for Review',
            editable: true,
            editProps: {
                type: 'select',
                options: ['YES', 'NO'],
            },
        },
        {
            accessor: 'internalNotes',
            Header: 'Internal Notes',
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        {
            accessor: 'externalNotes',
            Header: 'External Notes',
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    const addItemToCredit = (formData, resetForm) => {
        let item = {};
        item.poMapID = formData.poMapID;
        item.numberOfCasesAffected = formData.numberOfCasesAffected;
        item.reasons = formData.reasons.map((item) => item.value);
        item.accountingStatus = formData.accountingStatus;
        item.readyForReview = formData.readyForReview;
        item.productCost = productCost;
        item.dumpCost = dumpCost;
        item.laborCost = laborCost;
        item.freightCost = freightCost;
        item.inspectionCost = formData.inspectionCost;
        item.otherCosts = formData.otherCosts;
        item.totalAmount = totalAmount;
        item.internalNotes = formData.internalNotes;
        item.externalNotes = formData.externalNotes;

        if (totalAmount === 0) {
            setNotification({ text: 'Total amount on a credit item must be greater than zero', level: 'warning' });
            return;
        }

        if (formData.accountingStatus === 0 || formData.accountingStatus === '') {
            setNotification({ text: 'Please select an accounting status', level: 'warning' });
            return;
        }

        if (formData.productName === 0 || formData.productName === '') {
            setNotification({ text: 'Please select a product', level: 'warning' });
            return;
        }

        setTotalAmount(0);
        setProductCost(0);
        setDumpCost(0);
        setLaborCost(0);
        setFreightCost(0);

        setItemsOnCredit((items) => [...items, item]);
        resetForm();
    };

    let addCreditItemsFields = [
        {
            name: 'poMapID',
            inputElement: 'select',
            label: 'Product Name',
            gridValue: 3,
            inputProps: { required: true, opts: poProducts },
        },
        {
            name: 'numberOfCasesAffected',
            inputElement: 'textField',
            label: 'Number of Cases Affected',
            gridValue: 3,
            inputProps: {
                type: 'number',
                required: true,
            },
        },
        {
            name: 'reasons',
            inputElement: 'autocomplete',
            label: 'Reasons',
            gridValue: 3,
            inputProps: {
                required: true,
                multiple: true,
                opts: reasons.map((item) => ({ text: item, value: item })),
            },
        },
        {
            name: 'accountingStatus',
            inputElement: 'select',
            label: 'Accounting Status',
            gridValue: 3,
            inputProps: {
                required: true,
                opts: () => {
                    let opts = accountingStatusOpts.map((item) => ({ text: item, value: item }));
                    opts.unshift({ text: 'Please Select', value: 0 });
                    return opts;
                },
            },
        },
        {
            name: 'readyForReview',
            inputElement: 'select',
            label: 'Ready for Review',
            gridValue: 3,
            inputProps: {
                required: true,
                opts: [
                    { text: 'YES', value: 'YES' },
                    { text: 'NO', value: 'NO' },
                ],
            },
        },
        {
            name: 'productCost',
            inputElement: 'textField',
            label: 'Product Cost',
            gridValue: 3,
            inputProps: { type: 'number', onChange: (e) => setProductCost(e.target.value !== '' ? e.target.value : 0) },
        },
        {
            name: 'dumpCost',
            inputElement: 'textField',
            label: 'Dump Cost',
            gridValue: 3,
            inputProps: { type: 'number', onChange: (e) => setDumpCost(e.target.value !== '' ? e.target.value : 0) },
        },
        {
            name: 'laborCost',
            inputElement: 'textField',
            label: 'Labor Cost',
            gridValue: 3,
            inputProps: { type: 'number', onChange: (e) => setLaborCost(e.target.value !== '' ? e.target.value : 0) },
        },
        {
            name: 'freightCost',
            inputElement: 'textField',
            label: 'Freight Cost',
            gridValue: 3,
            inputProps: { type: 'number', onChange: (e) => setFreightCost(e.target.value !== '' ? e.target.value : 0) },
        },
        { name: 'totalAmount', inputElement: 'textField', label: 'Total Amount', gridValue: 3, value: totalAmount },
        {
            name: 'inspectionCost',
            inputElement: 'textField',
            label: 'USDA Inspection Cost',
            gridValue: 3,
            inputProps: { type: 'number' },
        },
        {
            name: 'otherCosts',
            inputElement: 'textField',
            label: 'Other Costs',
            gridValue: 3,
            inputProps: { type: 'number' },
        },
        {
            name: 'internalNotes',
            inputElement: 'textField',
            label: 'Internal Notes',
            gridValue: 6,
            inputProps: { multiline: true },
        },
        {
            name: 'externalNotes',
            inputElement: 'textField',
            label: 'External Notes',
            gridValue: 6,
            inputProps: { multiline: true },
        },
    ];

    return (
        <>
            <h2>Credit Items</h2>
            <FormComponent
                formFields={addCreditItemsFields}
                onSubmit={async (formData, resetForm) => {
                    addItemToCredit(formData, resetForm);
                }}
                button={{ text: 'Add', gridValue: 1 }}
            />
            <div>
                <h4 style={{ marginBottom: 0 }}>Running Total: {runningTotal}</h4>
            </div>
            <DataTable
                columns={creditItemColumns}
                data={itemsOnCredit}
                editable={true}
                saveEdit={({ id, field, value, row }) => {
                    let items = JSON.parse(JSON.stringify(itemsOnCredit)).map((item) => {
                        if (item.poMapID === row.poMapID) {
                            item[field] = value;
                            if (['productCost', 'dumpCost', 'laborCost', 'freightCost'].includes(field)) {
                                let difference = parseFloat(value) - parseFloat(row[field]);
                                item.totalAmount += difference;
                                setRunningTotal((tot) => {
                                    tot -= item.totalAmount;
                                    tot += difference;
                                    return tot;
                                });
                            }
                        }
                        return item;
                    });
                    setItemsOnCredit(items);
                }}
            />
        </>
    );
};

const CreateCreditRequest = ({ initialValues = {}, accountingStatusOpts, reasons, onSuccess }) => {
    const user = useContext(UserContext);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [vendors, setVendors] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [vendorCarrier, setVendorCarrier] = useState();
    const [creditFrom, setCreditFrom] = useState();
    const [creditOn, setCreditOn] = useState();
    const [poProducts, setPoProducts] = useState([]);
    const [selectedPO, setSelectedPO] = useState();
    const [selectedTruck, setSelectedTruck] = useState();
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [selectedPOs, setSelectedPOs] = useState([]);
    const [trucks, setTrucks] = useState([]);

    let getVendors = async () => {
        let response = await api.getVendors();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setVendors([]);
            return;
        }

        let vendorAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setVendors([pleaseSelect].concat(vendorAsOpts));
    };

    let getInboundCarriers = async () => {
        let res = await inboundTruckApi.getInboundOptions();
        if (!res.status) {
            return;
        }
        setCarriers(res.data.carriers.map((item) => ({ text: item.name, value: item.id })));
    };

    const getPurchaseOrders = async () => {
        let res = await api.getPurchaseOrders();
        if (res.status === false) {
            setNotification({ text: "Can't access POs: " + res.msg, level: 'error' });
            return;
        }

        setPurchaseOrders(
            res.data.rows.map((item) => ({ text: '' + item.id, value: '' + item.id, vendorID: '' + item.vendorID }))
        );
    };

    const getPOsPerTruck = async (truckID) => {
        let res = await inboundTruckApi.getTruckDetails(truckID, user.getFacilityID());
        if (res.status === false) {
            setNotification({ text: 'No purchase orders found ' + res.msg, level: 'error' });
            return;
        }

        setPurchaseOrders(res.data.output.pos.map((item) => ({ text: '' + item.id, value: '' + item.id })));
    };

    const getTrucks = async () => {
        let res = await inboundTruckApi.getTrucks(user.getFacilityID());
        if (res.status === false) {
            setNotification({ text: 'No trucks found ' + res.msg, level: 'error' });
            return;
        }

        setTrucks(
            res.data.rows.map((item) => ({
                text: '' + item.id,
                value: '' + item.id,
                inboundCarrierID: '' + item.inboundCarrierID,
            }))
        );
    };

    //ONE PO IS SELECTED//
    const getPurchaseOrderDetails = async (poID) => {
        let res = await api.getPurchaseOrderDetails(poID);
        if (!res.status) {
            setNotification({ text: 'Failed to lookup po: ' + res.msg, level: 'error' });
            return;
        }

        let products = res.data.output.items.map((item) => ({
            text: `SKU ${item.sku}: ${item.itemName}`,
            value: item.poMapID,
        }));
        products.unshift({ text: 'Please Select', value: 0 });

        setPoProducts(products);
    };

    //MULTIPLE POs SELECTED//
    const getMultiplePODetails = async (pos) => {
        let poArr = pos.map((item) => item.value);
        let promises = poArr.map((po) => api.getPurchaseOrderDetails(po));
        let res = await Promise.all(promises);

        let products = [];
        if (res.every((item) => item.status)) {
            res.forEach((r) => {
                let tempProducts = r.data.output.items.map((item) => ({
                    text: `SKU ${item.sku}: ${item.itemName}`,
                    value: item.poMapID,
                }));
                tempProducts.forEach((item) => products.push(item));
            });

            products.unshift({ text: 'Please Select', value: 0 });
            setPoProducts(products);
        }
    };

    const handlePOsPerTruck = (e, val) => {
        setSelectedPOs(val);
    };

    const handleTruckUpdate = (e, val) => {
        setSelectedTruck(val.value);
        setSelectedPOs([]);
    };

    useEffect(() => {
        getVendors();
        getInboundCarriers();
    }, []);

    useEffect(() => {
        if (!!initialValues && initialValues.truckID === '') {
            setCreditOn('poID');
        }
        if (!!initialValues && !!initialValues.truckID && initialValues.truckID !== '') {
            setCreditOn('truckID');
            getPOsPerTruck(initialValues.truckID);
        }
    }, [initialValues]);

    useEffect(() => {
        if (creditOn === 'poID') {
            getPurchaseOrders();
            setSelectedTruck();
            setSelectedPOs([]);
        } else if (creditOn === 'truckID') {
            getTrucks();
            setSelectedPO();
        }
    }, [creditOn]);

    useEffect(() => {
        if (selectedPO) {
            getPurchaseOrderDetails(selectedPO);
            setVendorCarrier(
                vendors.filter(
                    (item) =>
                        item.value === parseInt(purchaseOrders.filter((po) => po.value === selectedPO)[0].vendorID)
                )[0].name
            );
        }
    }, [selectedPO]);

    useEffect(() => {
        if (selectedTruck) {
            getPOsPerTruck(selectedTruck);
            setSelectedPOs([]);
            if (selectedTruck) {
                setVendorCarrier(
                    carriers.filter(
                        (item) =>
                            item.value ===
                            parseInt(trucks.filter((truck) => truck.value === selectedTruck)[0].inboundCarrierID)
                    )[0].text
                );
            }
        }
    }, [selectedTruck]);

    useEffect(() => {
        getMultiplePODetails(selectedPOs);
    }, [selectedPOs]);

    const createCreditRequest = async (formData, resetForm) => {
        if (formData.itemsOnCredit.length === 0) {
            setNotification({ text: 'Must add at least 1 item to Credit Request', level: 'error' });
            return;
        }

        let creditTotal = 0;
        formData.itemsOnCredit.forEach((item) => (creditTotal += item.totalAmount));

        let sendParams = {
            creditFrom: creditFrom || formData.creditFrom,
            poID: formData.poID,
            poIDs: formData.posPerTruckID,
            truckID: formData.truckID,
            vendorCarrier: vendorCarrier || formData.vendorCarrier,
            claimReleaseNumber: formData.claimReleaseNumber,
            itemsOnCredit: formData.itemsOnCredit,
            creditTotal: creditTotal,
        };

        let res = await api.createCreditRequest(sendParams);
        if (!res.status) {
            setNotification({ text: 'Failed to create credit request: ' + res.msg, level: 'error' });
            return;
        } else {
            setNotification({ text: 'Credit request created!', level: 'success' });
            resetForm();
            setVendorCarrier();

            if (onSuccess && onSuccess instanceof Function) {
                onSuccess();
            }
        }
    };

    let creditInfoFields = [
        {
            name: 'creditFrom',
            inputElement: 'select',
            label: 'Credit From',
            gridValue: 3,
            inputProps: {
                required: true,
                opts: [
                    { text: 'Vendor', value: 'vendor' },
                    { text: 'Carrier', value: 'carrier' },
                    { text: 'FC (Misfits)', value: 'misfits' },
                ],
                onChange: (e, val) => setCreditFrom(val),
            },
            initialValue: initialValues.creditFrom || undefined,
        },
        {
            name: 'creditOn',
            inputElement: 'select',
            label: 'Credit On',
            gridValue: 3,
            inputProps: {
                required: true,
                opts: [
                    { text: 'PO Number', value: 'poID' },
                    { text: 'Truck ID', value: 'truckID' },
                ],
                onChange: (e, val) => setCreditOn(val),
            },
            value: creditOn,
        },
        {
            name: 'poID',
            inputElement: 'autocomplete',
            label: 'PO Number',
            gridValue: 3,
            inputProps: {
                required: creditOn === 'poID',
                opts: purchaseOrders,
                onChange: (e, val) => setSelectedPO(val ? val.value : undefined),
            },
            display: creditOn === 'poID',
            initialValue: initialValues.purchaseOrderID
                ? { text: '' + initialValues.purchaseOrderID, value: '' + initialValues.purchaseOrderID, vendorID: '' }
                : undefined,
        },
        {
            name: 'truckID',
            inputElement: 'autocomplete',
            label: 'Truck ID',
            gridValue: 3,
            inputProps: {
                required: creditOn === 'truckID',
                opts: trucks,
                onChange: handleTruckUpdate,
            },
            display: creditOn === 'truckID',
            initialValue: initialValues.truckID
                ? { text: '' + initialValues.truckID, value: '' + initialValues.truckID, inboundCarrierID: '' }
                : undefined,
        },
        {
            name: 'posPerTruckID',
            inputElement: 'autocomplete',
            label: 'POs per Truck ID',
            gridValue: 3,
            inputProps: {
                required: creditOn === 'truckID',
                opts: purchaseOrders,
                multiple: true,
                onChange: handlePOsPerTruck,
            },
            display: creditOn === 'truckID',
            value: selectedPOs,
        },
        {
            name: 'vendorCarrier',
            inputElement: 'textField',
            label: 'Vendor or Carrier Name',
            gridValue: 3,
            inputProps: { required: true },
            value: vendorCarrier,
            initialValue: initialValues.vendorCarrier || undefined,
        },
        {
            name: 'claimReleaseNumber',
            inputElement: 'textField',
            label: 'Claim Release Number',
            gridValue: 3,
            initialValue: initialValues.claimReleaseNumber || undefined,
        },
        {
            name: 'itemsOnCredit',
            label: 'Add Credit Items',
            inputElement: ({ formData, setFormField }) => (
                <AddCreditItems
                    formData={formData}
                    setFormField={setFormField}
                    poProducts={poProducts}
                    setNotification={setNotification}
                    accountingStatusOpts={accountingStatusOpts}
                    reasons={reasons}
                    initialValue={initialValues}
                />
            ),
        },
    ];

    return (
        <>
            <h1>Create Credit Request</h1>
            <h2>Credit Information</h2>
            <Notifications options={notification} />
            <FormComponent
                formFields={creditInfoFields}
                onSubmit={async (formData, resetForm) => {
                    createCreditRequest(formData, resetForm);
                }}
            />
        </>
    );
};

export { CreateCreditRequest };
