import React, { useState, useEffect } from 'react';
import {
    TextField,
    Select,
    ButtonGroup,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Input,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
// filter display for different filter types

// text filter - type in value to filter column
const TextColumnFilter = ({ column }) => {
    let { filterValue, setFilter } = column;

    return (
        <TextField
            disabled={column.hasOwnProperty('filterDisabled') && column.filterDisabled === true}
            id={`${column.Header.replaceAll(' ', '')}_filter`}
            onClick={(event) => event.stopPropagation()}
            style={{ width: '80%', marginLeft: '5px' }}
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        />
    );
};

// checkbox filter
const CheckboxFilter = ({ column }) => {
    let { filterValue, setFilter } = column;
    let [value, setValue] = useState('all');

    useEffect(() => {
        setFilter(value);
    }, [value]);
    return (
        <div onClick={(event) => event.stopPropagation()}>
            <ButtonGroup style={{ padding: '0px 10px' }} size="small" aria-label="small outlined button group">
                <Button
                    id={`${column.Header.replaceAll(' ', '')}_all_filter`}
                    variant="contained"
                    color={value === 'all' ? 'primary' : 'default'}
                    onClick={(event) => {
                        event.stopPropagation();
                        setValue('all');
                    }}
                >
                    All
                </Button>
                <Button
                    id={`${column.Header.replaceAll(' ', '')}_true_filter`}
                    variant="contained"
                    color={value === true ? 'primary' : 'default'}
                    onClick={(event) => {
                        event.stopPropagation();
                        setValue(true);
                    }}
                >
                    T
                </Button>
                <Button
                    id={`${column.Header.replaceAll(' ', '')}_false_filter`}
                    variant="contained"
                    color={value === false ? 'primary' : 'default'}
                    onClick={(event) => {
                        event.stopPropagation();
                        setValue(false);
                    }}
                >
                    F
                </Button>
            </ButtonGroup>
        </div>
    );
};

const MultiSelectColumnFilter = (obj) => {
    let column = obj.column;
    let { filterValue, setFilter } = column;
    let [selected, setSelected] = useState([]);

    useEffect(() => {
        setFilter(selected);
    }, [selected]);

    let accessor = obj.column.id;
    let opts = [...new Set(obj.data.map((item) => item[accessor]))];

    let selectOptions = [];
    if (column?.editProps?.options) {
        // match with option values
        opts.forEach((opt) => {
            let optionFound = column.editProps.options.find((item) => item.value === opt);
            if (optionFound) {
                selectOptions.push(optionFound);
            } else {
                selectOptions.push({ text: opt, value: opt });
            }
        });
    } else {
        selectOptions = opts.map((opt) => ({ text: opt, value: opt }));
    }
    selectOptions.sort((a, b) => a.text.localeCompare(b.text));

    return (
        <Select
            multiple
            value={selected}
            size="small"
            style={{ width: 150 }}
            renderValue={(selected) => <span style={{ fontSize: '10px' }}>{selected.join(', ')}</span>}
            onChange={(event, value) => {
                console.log(event.target.value);
                setSelected(event.target.value);
            }}
            input={<Input />}
        >
            {selectOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={selected.includes(item.value)} />
                    <ListItemText primary={item.text} />
                </MenuItem>
            ))}
        </Select>
    );
};

// select filter - select value from all options from the column
const SelectColumnFilter = (obj) => {
    let column = obj.column;
    let { filterValue, setFilter } = column;

    let accessor = obj.column.id;
    let opts = [...new Set(obj.data.map((item) => item[accessor]))];

    let selectOptions = [];
    if (column?.editProps?.options) {
        // match with option values
        opts.forEach((opt) => {
            let optionFound = column.editProps.options.find((item) => item.value === opt);
            if (optionFound) {
                selectOptions.push(optionFound);
            } else {
                selectOptions.push({ text: opt, value: opt });
            }
        });
    } else {
        selectOptions = opts.map((opt) => ({ text: opt, value: opt }));
    }
    selectOptions.sort((a, b) => a.text.localeCompare(b.text));

    return (
        <Select
            onClick={(event) => event.stopPropagation()}
            value={filterValue || ''}
            style={{ width: '80%', marginLeft: '5px' }}
            onChange={(e) => {
                setFilter(e.target.value || '');
            }}
            native
        >
            <option></option>
            {selectOptions.map((item) => (
                <option key={item.value} value={item.value}>
                    {item.text}
                </option>
            ))}
        </Select>
    );
};

const DateFilter = ({ column }) => {
    let { filterValue, setFilter } = column;
    let [open, setOpen] = useState(false);
    let [filterType, setFilterType] = useState('all');
    let [date1, setDate1] = useState('');
    let [date2, setDate2] = useState('');
    let [canSubmit, setCanSubmit] = useState(false);

    useEffect(() => {
        if (filterType === 'all') {
            setCanSubmit(true);
            return;
        } else if (filterType === 'between') {
            if (!date1 || !date2) {
                setCanSubmit(false);
                return;
            }
        } else {
            if (!date1) {
                setCanSubmit(false);
                return;
            }
        }

        setCanSubmit(true);
    }, [filterType, date1, date2]);

    return (
        <>
            <Grid container>
                <Grid item xs={10}>
                    <Button
                        id={`${column.Header.replaceAll(' ', '')}_date_filter`}
                        color={filterType === 'all' ? 'default' : 'primary'}
                        variant={filterType === 'all' ? 'text' : 'contained'}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(true);
                        }}
                    >
                        Filter
                    </Button>
                </Grid>
            </Grid>
            <Dialog maxWidth="sm" fullWidth={true} open={open} onClick={(event) => event.stopPropagation()}>
                <DialogTitle>Filter Date Column: {column.Header}</DialogTitle>
                <DialogContent onClick={(event) => event.stopPropagation()}>
                    <Grid container onClick={(event) => event.stopPropagation()}>
                        <Grid item xs={12}>
                            <Select
                                id="DateFilterSelect"
                                onClick={(event) => event.stopPropagation()}
                                value={filterType}
                                style={{ width: '100%', margin: '5px' }}
                                onChange={(e) => {
                                    setFilterType(e.target.value);
                                }}
                                native
                            >
                                <option value="all">All</option>
                                <option value="equals">Equals</option>
                                <option value="greaterThan">After</option>
                                <option value="lessThan">Before</option>
                                <option value="between">Between (Inclusive)</option>
                            </Select>
                        </Grid>
                        {filterType !== 'all' && (
                            <Grid item xs={12}>
                                <TextField
                                    id="DateField_1"
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={(e) => {
                                        setDate1(e.target.value);
                                    }}
                                    style={{ width: '100%', margin: '5px' }}
                                    type="date"
                                    value={date1}
                                />
                            </Grid>
                        )}
                        {filterType === 'between' && (
                            <>
                                <Grid item xs={12}>
                                    <div style={{ textAlign: 'center' }}>AND</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="DateField_2"
                                        onChange={(e) => {
                                            setDate2(e.target.value);
                                        }}
                                        onClick={(event) => event.stopPropagation()}
                                        style={{ width: '100%', margin: '5px' }}
                                        type="date"
                                        value={date2}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Button
                                id="OK"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setFilter({
                                        filterType,
                                        date1,
                                        date2,
                                    });
                                    setOpen(false);
                                }}
                                disabled={!canSubmit}
                            >
                                OK
                            </Button>
                            <Button
                                onClick={(event) => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export { TextColumnFilter, SelectColumnFilter, CheckboxFilter, DateFilter, MultiSelectColumnFilter };
