import { mmFetchWithResponse } from './mmFetchWithResponse';

let userApi = {
    getUserAlerts: async () => {
        let url = 'api/alerts/v1/history';
        let res = await mmFetchWithResponse(url);
        return res;
    },
    getUserAlertSubscriptions: async (facilitiesID) => {
        let url = `api/alerts/v1/subs/${facilitiesID}`;
        let res = await mmFetchWithResponse(url);
        return res;
    },
    getUsersRoles: async () => {
        let url = 'api/admin/v1/check/roles';
        let res = await mmFetchWithResponse(url);
        return res;
    },
    subscribeToAlert: async (alertTypeID) => {
        let url = `api/alerts/v1/${alertTypeID}/subscribe`;
        let res = await mmFetchWithResponse(url, { method: 'PUT' });
        return res;
    },
    unsubscribeFromAlert: async (alertTypeID) => {
        let url = `api/alerts/v1/${alertTypeID}/unsubscribe`;
        let res = await mmFetchWithResponse(url, { method: 'PUT' });
        return res;
    },
    updateLanguage: async (userID, language) => {
        let url = `api/admin/v2/jalacounts/user/update`;
        let res = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                id: userID,
                language: language,
            }),
        });
        return res;
    },
    updateFacility: async (userID, facilityID) => {
        let url = `api/admin/v2/jalacounts/user/update`;
        let res = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                id: userID,
                facilityID: facilityID,
            }),
        });
        return res;
    },
};

export { userApi };
