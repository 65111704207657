import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';
import { Button, Dialog, DialogContent, DialogTitle, Paper, Tabs, Tab, Grid, TextField } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';

let ViewGoPuffInvoices = () => {
    let [rows, setRows] = useState([]);
    let [reportIsReady, setReportIsReady] = useState(false);
    let [reportButtonText, setReportButtonText] = useState('Loading...');
    const user = useContext(UserContext);
    const [notification, setNotification] = useState({ text: '', level: '' });

    const [reportStartDate, setReportStartDate] = React.useState(moment().subtract(14, 'days').format('YYYY-MM-DD'));
    let handleReportStartDateChange = (event) => {
        setReportStartDate(event.target.value);
    };

    const [reportEndDate, setReportEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    let handleReportEndDateChange = (event) => {
        setReportEndDate(event.target.value);
    };

    let handleRunReportClick = (event) => {
        getAndRenderReportData();
    };

    let getAndRenderReportData = async () => {
        setReportIsReady(false);

        setReportButtonText('Loading...');
        setRows([]);

        let response = await api.getInvoices(reportStartDate, reportEndDate, user.getFacilityID());

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportButtonText('Run Report');
            setReportIsReady(true);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY');
                row.deliveryDate = moment(row.deliveryDate.date).format('MM/DD/YYYY HH:mm:ss');
                row.sentAt = row.sentAt ? moment(row.sentAt.date).format('MM/DD/YYYY HH:mm:ss') : '';
                return row;
            });
        }

        setRows(response.data.rows);
        setReportButtonText('Run Report');
        setReportIsReady(true);
    };

    useEffect(() => {
        getAndRenderReportData();
    }, []);

    let cols = [
        { accessor: 'id', Header: 'Invoice ID' },
        { accessor: 'purchaseOrder', Header: 'Go Puff PO' },
        { accessor: 'orderID', Header: 'MM Order ID' },
        { accessor: 'microFC', Header: 'Micro FC' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'sentAt', Header: 'Sent on' },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Download`}
                        onClick={async () => {
                            let res = api.downloadInvoiceCsv(row.original.id);
                        }}
                    >
                        Download
                    </Button>
                );
            },
        },
        {
            accessor: 'sendIt',
            Header: 'Send',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Download`}
                        onClick={async () => {
                            let res = api.sendInvoice(row.original.id);

                            if (res.status === false) {
                                setNotification({ text: 'Failed to send invoice. ' + res.msg, level: 'error' });
                                return;
                            }
                            setNotification({ text: 'Invoice sent to Go Puff', level: 'success' });
                            getAndRenderReportData();
                        }}
                    >
                        Send to Go Puff
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h1>Go Puff Invoices</h1>
            <h3>Invoices downloaded in "NEED PRICING" status will return a csv of items needing pricing</h3>
            <Notifications options={notification} />
            <Grid item md={12}>
                <TextField
                    id="reportStartDate"
                    label="Start Date"
                    type="date"
                    value={reportStartDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportStartDateChange}
                    style={{ marginRight: '50px', zIndex: 1000, position: 'relative' }}
                />

                <TextField
                    id="reportEndDate"
                    label="End Date"
                    type="date"
                    value={reportEndDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportEndDateChange}
                    style={{ marginRight: '50px', zIndex: 1000, position: 'relative' }}
                />

                <Button id="runReport" onClick={handleRunReportClick} color="secondary" disabled={!reportIsReady}>
                    {reportButtonText}
                </Button>
            </Grid>
            <DataTable
                columns={cols}
                data={rows}
                csvExport={true}
                csvExportFileName="gopuff-invoices"
                csvExportHeadersUseAccessor={true}
            />
        </div>
    );
};

export { ViewGoPuffInvoices };
