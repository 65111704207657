import React, { useEffect, useState } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { DataTable, TabPanel, UploadCSVAndReviewComponent, Notifications } from '../../../shared';
import { financeApi as api } from '../../../api/finance.js';
import { warehouseApi } from '../../../api/warehouse.js';
import moment from 'moment';

const FinanceUploads = () => {
    const [selectedTab, setSelectedTab] = useState('0');
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [facilities, setFacilities] = useState([]);

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (!response.status) {
            return;
        }
        setFacilities(
            response.data.rows.map((item) => {
                return { text: item.name, value: item.id };
            })
        );
    };

    useEffect(() => {
        getFacilities();
    }, []);

    return (
        <Paper>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Upload" value="0" />
                <Tab label="Network Ramp Plan" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <UploadFinanceUploads />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <ViewVolumeProjectionUploads setNotification={setNotification} facilities={facilities} />
            </TabPanel>
        </Paper>
    );
};

const ViewVolumeProjectionUploads = ({ setNotification, facilities }) => {
    const [rows, setRows] = useState([]);

    const getUploads = async () => {
        let res = await api.getVolumeProjectionUploads();
        if (!res.status) {
            setNotification({ text: `Failed to get uploads`, level: 'error' });
            return;
        }

        setRows(
            res.data.rows.map((item) => {
                item.createdOn = moment(item.timestamp.date).format('MM/DD/YYYY');
                return item;
            })
        );
    };

    useEffect(() => {
        getUploads();
    }, []);

    let cols = [
        { Header: 'Type', accessor: 'companySource' },
        { Header: 'Fiscal Week', accessor: 'week' },
        { Header: 'Year', accessor: 'year' },
        { Header: 'FC', accessor: 'facilityID', editProps: { type: 'select', options: facilities } },
        { Header: 'Projection - Orders', accessor: 'orderProjection' },
        { Header: 'Projection - Boxes', accessor: 'boxProjection' },
        { Header: 'Created On', accessor: 'createdOn' },
        { Header: 'Created By', accessor: 'authUser' },
    ];

    return (
        <div>
            <DataTable
                data={rows}
                columns={cols}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let res = await api.updateVolumeProjection(id, { [field]: value });
                    if (!res.status) {
                        setNotification({ level: 'error', text: `Failed to update: ${res.msg}` });
                        return false;
                    }
                    return true;
                }}
            />
        </div>
    );
};

const UploadFinanceUploads = () => {
    let volumeForecastColumns = [
        { Header: 'Type', accessor: 'companySource' },
        { Header: 'Fiscal Week', accessor: 'week' },
        { Header: 'Year', accessor: 'year' },
        { Header: 'FC', accessor: 'fc' },
        { Header: 'Projection - Orders', accessor: 'orderProjection' },
        { Header: 'Projection - Boxes', accessor: 'boxProjection' },
        { Header: 'Errors', accessor: 'errors' },
    ];

    const uploadVolumeForecast = async (formData) => {
        const res = await api.uploadVolumeProjection(formData);
        return res;
    };

    const confirmVolumeProjection = async (key) => {
        const res = await api.confirmVolumeProjection(key);
        return res;
    };

    const downloadVolumeForecastTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Type,Fiscal Week,Year,FC,Projection - Orders,Projection-Boxes';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'volume_forecast_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <UploadCSVAndReviewComponent
                fileLabel="Item File"
                title="Network Ramp Plan"
                columns={volumeForecastColumns}
                upload={uploadVolumeForecast}
                confirm={confirmVolumeProjection}
                downloadTemplate={downloadVolumeForecastTemplate}
            />
        </div>
    );
};

export { FinanceUploads };
