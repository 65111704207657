import React from 'react';
import { FamAccountPicker } from '../nav/components/famAccountPicker';
import './header.css';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();

    return (
        <div className="fam-header">
            <img src="https://static.misfitsmarket.com/images/MM_nav-logo-desktop.svg" alt="Logo" />
            <span className="fam-partnerships-tag">Partnerships</span>
            {<FamAccountPicker readOnly={!location.pathname.includes('/orders')} />}
        </div>
    );
};

export { Header };
