import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Notifications, DataTable } from '../../../shared';
import { packagingApi as api } from '../../../api/packaging';

let AssignPackaging = () => {
    let [rows, setRows] = useState([]);
    let [insulation, setInsulation] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'carrier', Header: 'Carrier' },
        { accessor: 'hub', Header: 'Hub' },
        { accessor: 'arrivalDay', Header: 'Pull day' },
        { accessor: 'cubeLevelName', Header: 'Cube Level' },
        {
            accessor: 'insulationPackagingID',
            Header: 'Insulation',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => insulation.filter((opt) => opt.cubeLevelID === row.cubeLevelID || opt.value == 0),
            },
        },
        { accessor: 'numIcePacks', Header: 'Num Ice Packs', editable: true },
    ];

    let getAssignments = async () => {
        let response = await api.getAssignments();

        if (response.status === false) {
            setNotification({ text: 'No packaging found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    let getInsulation = async () => {
        let response = await api.getInsulation();

        if (response.status === false) {
            setNotification({ text: 'No insulation found ' + response.msg, level: 'error' });
            setInsulation([]);
            return;
        }

        let linersAsOpts = response.data.rows.map((row) => {
            row.text = row.itemNameInternal;
            row.value = row.id;
            return { text: row.itemNameInternal, value: row.id, cubeLevelID: row.cubeLevelID };
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setInsulation([pleaseSelect].concat(linersAsOpts));
    };

    useEffect(() => {
        getAssignments();
        getInsulation();
    }, []);

    return (
        <div>
            <h1>Assign Packaging</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        let update = {};
                        update[field] = value;

                        let response = await api.updatePackaging(id, update);
                        if (response.status === false) {
                            let message = 'Error modifying packaging';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Packaging modified!', level: 'success' });
                            getAssignments();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export { AssignPackaging };
