import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewVipCustomers } from './viewVipCustomers';
import { UploadVipCustomers } from './uploadVipCustomers';
import { TabPanel } from '../../../shared';

// import { Notifications, FormComponent, DataTable } from "../../../shared";
// import { marketingApi as api } from "../../../api/marketing";
// import DeleteIcon from "@material-ui/icons/DeleteOutline";
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const VipCustomers = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="View VIP Customers" value="0" />
                <Tab label="Upload" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewVipCustomers />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <UploadVipCustomers />
            </TabPanel>
        </Paper>
    );
};

export { VipCustomers };
