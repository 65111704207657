import React, { useState, useEffect } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { productionApi as api } from '../../../api/production';

let ViewPricing = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'segment', Header: 'Segment' },
        { accessor: 'taxonomyName', Header: 'Taxonomy Name' },
        { accessor: 'taxonomyLevel', Header: 'Taxonomy Level' },
        { accessor: 'parentName', Header: 'Parent Name' },
        { accessor: 'parentLevel', Header: 'Parent Level' },
        { accessor: 'purchaseType', Header: 'Purchase Type' },
        { accessor: 'targetProductMargin', Header: 'Target Product Margin', editable: true },
    ];

    let getRows = async () => {
        let response = await api.getPricing();
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        let filteredArr = response.data.rows.reduce((filtered, row) => {
            row.taxonomyLevel <= 2 && filtered.push(row);
            return filtered;
        }, []);

        setRows(filteredArr);
        // setRows(response.data.rows);
    };

    useEffect(() => {
        getRows();
    }, []);

    return (
        <div>
            <h1>Pricing</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value, row }) => {
                        let res = await api.updatePricing(id, field, value);
                        if (!res.status) {
                            setNotification({ text: res.msg, level: 'error' });
                            return false;
                        }

                        setNotification({ text: 'Pricing updated', level: 'success' });
                        getRows();
                        return true;
                    }}
                />
            </div>
        </div>
    );
};

export { ViewPricing };
