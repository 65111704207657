import React, { useState } from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@material-ui/core';
import { ViewJalapenoAccounts } from '../components/viewJalapenoAccounts.js';
import { CreateJalapenoAccount } from '../components/createJalapenoAccount.js';
import { SearchJalapenoRole } from '../components/searchJalapenoRole.js';
import { TabPanel } from '../../../shared';

const JalapenoAccounts = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="JalapenoAccounts_tab" label="Jalapeno Accounts" value="0" />
                <Tab id="CreateJalapenoAccount_tab" label="Create Jalapeno Account" value="1" />
                <Tab id="SearchByRole_tab" label="Search by role" value="2" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewJalapenoAccounts />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateJalapenoAccount />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <SearchJalapenoRole />
            </TabPanel>
        </Paper>
    );
};

export { JalapenoAccounts };
