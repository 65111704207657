import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Modal,
    Paper,
    Tab,
    Tabs,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { DataTable, FormComponent, Notifications, TabPanel } from '../../../shared';
import { logisticsApi } from '../../../api/logistics';
import { warehouseApi } from '../../../api/warehouse';
import { fbmApi } from '../../../api/fbm';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';
import { ViewHolidayOffsets } from './viewHolidayOffsets';

const LineHaulManagement = () => {
    const [selectedTab, setSelectedTab] = useState('0');
    const [fbmAccountOpts, setFbmAccountOpts] = useState([]);

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    useEffect(() => {
        async function getFBMAccounts() {
            const response = await fbmApi.getFBMAccounts();
            if (!response.status) {
                return;
            }

            let accounts = response.data.accounts.map((item) => ({
                text: item.companyName,
                value: item.fbmAccountName,
            }));
            accounts.unshift({ text: '', value: '' });
            setFbmAccountOpts(accounts);
        }

        getFBMAccounts();
    }, []);

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewLineHauls_Tab" label="View Line Hauls" value="0" />
                <Tab id="EasypostAccounts_Tab" label="Easypost Accounts" value="1" />
                <Tab id="AddLineHaul_Tab" label="Add Line Haul" value="2" />
                <Tab id="ShiftLineHauls_Tab" label="Shift Line Hauls" value="3" />
                <Tab id="HolidayOffsets_Tab" label="Holiday Offsets" value="4" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <LineHaulsComponent />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <EasypostAccounts fbmAccountOpts={fbmAccountOpts} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <AddLineHaulComponent fbmAccountOpts={fbmAccountOpts} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'3'}>
                <ShiftLineHauls />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index="4">
                <ViewHolidayOffsets />
            </TabPanel>
        </Paper>
    );
};

const EditPullTimeModal = ({ rowData, closeModal }) => {
    let [error, setError] = useState(null);
    let [time, setTime] = useState(null);

    let formFields = [
        {
            name: 'lineHaulID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'Line Haul ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'pullTime',
            initialValue: time,
            inputElement: 'time',
            label: 'Time',
            gridValue: 4,
            inputProps: { required: true },
        },
    ];

    useEffect(() => {
        setTime(moment(rowData.pullTime).format('HH:mm'));
    }, []);

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['pullTime'] = formData.pullTime;

                        let response = await logisticsApi.updateLineHaul(formData.lineHaulID, item);
                        if (response.status === false) {
                            let message = 'Error modifying line haul pull time';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                        } else {
                            closeModal();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

function SubDataTable({ parentRow, setExpandedRow, rows, ...props }) {
    return (
        <DataTable
            {...props}
            toolbarActions={[{ name: 'Deselect', action: () => setExpandedRow(null) }]}
            data={rows.filter((lineHaul) => lineHaul.id === parentRow.original.id)}
        />
    );
}

const EasypostSyncResultsModal = ({ syncResults, closeModal, open }) => {
    const columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Carrier Account ID', accessor: 'carrierAccountID' },
        { Header: 'Carrier', accessor: 'carrier' },
    ];

    if (!syncResults) {
        return null;
    }

    return (
        <Dialog open={open} onClose={closeModal} maxWidth="lg" fullWidth={true}>
            <DialogTitle>Easypost Sync Results</DialogTitle>
            <DialogContent>
                <h4>New Accounts</h4>
                {syncResults.created.length > 0 ? (
                    <DataTable columns={columns} data={syncResults.created} />
                ) : (
                    <div>No new accounts created</div>
                )}
                <h4>Updated Accounts</h4>
                {syncResults.updated.length > 0 ? (
                    <DataTable
                        columns={[
                            { Header: 'ID', accessor: 'id' },
                            { Header: 'Carrier Account ID', accessor: 'carrierAccountID' },
                            { Header: 'Old Name', accessor: 'oldValue' },
                            { Header: 'New Name', accessor: 'newValue' },
                        ]}
                        data={syncResults.updated}
                    />
                ) : (
                    <div>No existing accounts updated</div>
                )}
                <h4>Deactivated Accounts</h4>
                {syncResults.deactivated.length > 0 ? (
                    <DataTable columns={columns} data={syncResults.deactivated} />
                ) : (
                    <div>No existing accounts deactivated</div>
                )}
            </DialogContent>
        </Dialog>
    );
};

const EasypostAccounts = ({ fbmAccountOpts }) => {
    const [rows, setRows] = useState([]);
    const [syncResults, setSyncResults] = useState(null);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [facilities, setFacilities] = useState([]);
    const [syncingAccounts, setSyncingAccounts] = useState(false);

    const getEasypostAccounts = async () => {
        let res = await logisticsApi.getEasypostCarrierAccounts();
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to get EP accounts' });
            return;
        }

        setRows(
            res.data.rows.map((item) => {
                if (item.facilityID === 0) {
                    item.facilityID = null;
                }
                return item;
            })
        );
    };

    const syncAccounts = async () => {
        setSyncingAccounts(true);
        const res = await logisticsApi.syncEasypostAccounts();
        setSyncingAccounts(false);
        if (!res.status || !res.data) {
            setNotification({ text: 'Failed to sync EP Accounts', level: 'error' });
            return;
        }
        setSyncResults(res.data.results);
    };

    const editAccount = async ({ id, field, value }) => {
        const item = { [field]: value };
        const response = await logisticsApi.updateEasypostAccount(id, item);
        if (!response.status) {
            setNotification({ text: response.msg, level: 'error' });
        } else {
            setNotification({ text: 'Account modified', level: 'success' });
            getEasypostAccounts();
        }
    };

    useEffect(() => {
        async function getFacilities() {
            const res = await warehouseApi.getFacilities(true);
            if (!res.status || !res.data) {
                return;
            }

            let facs = res.data.rows.map((item) => ({ id: item.id, name: item.name }));
            facs.unshift({ id: 0, name: '' });
            setFacilities(facs);
        }

        getEasypostAccounts();
        getFacilities();
    }, []);

    const columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Carrier Account ID', accessor: 'carrierAccountID' },
        { Header: 'Carrier', accessor: 'carrier' },
        {
            Header: 'FBM Partner Name',
            accessor: 'fbmPartnerName',
            editable: true,
            editProps: { type: 'select', options: fbmAccountOpts },
        },
        {
            Header: 'Facility',
            accessor: 'facilityID',
            editable: true,
            editProps: { type: 'select', options: facilities },
        },
        { Header: 'Active', accessor: 'active', editable: true, type: 'checkbox', editProps: { type: 'checkbox' } },
        { Header: 'Notes', accessor: 'notes', editable: true },
        { Header: 'Created At', accessor: 'createdAt.date' },
        { Header: 'Last Updated At', accessor: 'updatedAt.date' },
        { Header: 'Last Updated By', accessor: 'updatedBy' },
    ];

    const toolbarActions = [
        {
            name: 'Sync Accounts',
            action: () => {
                syncAccounts();
            },
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            <EasypostSyncResultsModal
                open={!!syncResults}
                closeModal={() => {
                    setSyncResults(null);
                    getEasypostAccounts();
                }}
                syncResults={syncResults}
            />
            <DataTable
                title="Easypost Carrier Accounts"
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={editAccount}
                toolbarActions={!syncingAccounts ? toolbarActions : []}
            />
        </>
    );
};

let LineHaulsComponent = ({ minimal = false, canChangeViaCSV = false }) => {
    let [rows, setRows] = useState([]);
    let [selectedRow, setSelectedRow] = useState({});
    let [notification, setNotification] = useState({ text: null, level: null });
    let [editTimeModalOpen, setEditTimeModalOpen] = useState(false);
    let [epOpts, setEpOpts] = useState([]);
    const [activeOnly, setActiveOnly] = useState(true);
    const user = useContext(UserContext);

    let showTimeEditModal = (row) => {
        setSelectedRow(row);
        setEditTimeModalOpen(true);
    };

    let getEasypostAccounts = async () => {
        let res = await logisticsApi.getEasypostCarrierAccounts();
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to get EP accounts' });
            return;
        }

        setEpOpts(res.data.rows.filter((item) => item.active));
    };

    let saveEdit = async ({ id, field, value }) => {
        const item = { [field]: value };
        const response = await logisticsApi.updateLineHaul(id, item);
        if (response.status === false) {
            let message = 'Error modifying line haul';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            setNotification({ text: 'Line Haul modified!', level: 'success' });
            await getItemsInTable();
        }
        return response.status;
    };

    const getItemsInTable = async () => {
        const hauls = activeOnly ? 'activeonly' : 'all';
        const response = await logisticsApi.getLineHauls(hauls);

        if (response.status === false) {
            setNotification({ text: 'No hauls found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        let lhDetails = response.data;
        let lines = lhDetails.lineHauls.filter((line) => line.facilityID === user.getFacilityID());
        setRows(lines);
    };
    useEffect(() => {
        getItemsInTable();
        getEasypostAccounts();
    }, [activeOnly]);

    const columns = [
        { accessor: 'id', Header: 'ID', editable: false },
        {
            accessor: 'lhCarrier',
            Header: 'LH Carrier',
            editable: true,
            editProps: { type: 'input', inputType: 'text' },
        },
        { accessor: 'carrier', Header: 'LM Carrier', editable: false },
        { accessor: 'hub', Header: 'Hub', editable: !minimal, editProps: { type: 'input', inputType: 'text' } },
        { accessor: 'arrivalDay', Header: 'Ship Day', editable: false },
        !minimal && { accessor: 'pullTime', Header: 'Pull Time', editable: true, customEdit: showTimeEditModal },
        !minimal && {
            accessor: 'maxCapacity',
            Header: 'Max Capacity',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        !minimal && { accessor: 'currentCapacity', Header: 'Current Capacity', editable: false },
        { accessor: 'facility', Header: 'Facility', editable: false },
        !minimal && {
            accessor: 'cost',
            Header: 'Cost',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        !minimal && { accessor: 'expectedInjectionDay', Header: 'Exp. Injection Day', editable: false },
        !minimal && { accessor: 'expectedInjectionHourUTC', Header: 'Exp. Injection Hour', editable: true },
        !minimal && {
            accessor: 'sort',
            Header: 'Sort',
            editable: true,
            type: 'checkbox',
            editProps: { type: 'checkbox' },
        },
        { accessor: 'fbmPartnerName', Header: 'FBM Account', editable: false },
        {
            accessor: 'easypostCarrierAccountName',
            Header: 'Easypost Account',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => epOpts.filter((item) => item.carrier === row.carrier).map((item) => item.name),
            },
        },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            {editTimeModalOpen && (
                <EditPullTimeModal
                    rowData={selectedRow}
                    closeModal={() => {
                        setEditTimeModalOpen(false);
                        getItemsInTable();
                    }}
                />
            )}
            <FormControlLabel
                control={
                    <Checkbox
                        id="ActiveOnly_checkbox"
                        color="primary"
                        checked={activeOnly}
                        onChange={(event) => setActiveOnly(event.target.checked)}
                    />
                }
                label="Active Only"
            />
            <DataTable
                editable={true}
                title="Line Hauls"
                columns={columns}
                data={rows}
                saveEdit={saveEdit}
                csvExport={canChangeViaCSV}
                csvExportHeadersUseAccessor={canChangeViaCSV}
            />
        </Grid>
    );
};

let AddLineHaulComponent = ({ fbmAccountOpts }) => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const user = useContext(UserContext);
    const [carriers, setCarriers] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [epOpts, setEpOpts] = useState([]);

    useEffect(() => {
        async function getCarriers() {
            let response = await logisticsApi.getLineHaulCarriers();
            if (response.status === false) {
                return;
            }

            setCarriers(response.data.rows);
        }

        async function getEasypostAccounts() {
            const res = await logisticsApi.getEasypostCarrierAccounts();
            if (!res.status) {
                setNotification({ level: 'error', text: 'Failed to get EP accounts' });
                return;
            }

            setEpOpts(res.data.rows.filter((item) => item.active));
        }

        getCarriers();
        getEasypostAccounts();
    }, []);

    let formFields = [
        {
            name: 'carrier',
            inputElement: 'select',
            label: 'Carrier',
            gridValue: 4,
            inputProps: { required: true, opts: carriers },
        },
        { name: 'hub', inputElement: 'textField', label: 'Hub', gridValue: 3, inputProps: { required: true } },
        {
            name: 'arrivalDay',
            inputElement: 'select',
            label: 'Ship Day',
            gridValue: 2,
            inputProps: { required: true, opts: days },
        },
        { name: 'pullTime', inputElement: 'time', label: 'Pull Time', gridValue: 2, inputProps: { required: true } },
        {
            name: 'maxCapacity',
            inputElement: 'textField',
            label: 'Max Capacity',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'expectedInjectionDay',
            inputElement: 'select',
            label: 'Exp. Injection Day',
            gridValue: 3,
            inputProps: { required: true, opts: days },
        },
        {
            name: 'expectedInjectionHourUTC',
            inputElement: 'textField',
            label: 'Exp. Injection Hour',
            gridValue: 2,
            inputProps: { required: true },
        },
        { name: 'sort', inputElement: 'checkbox', label: 'Sort', gridValue: 2 },
        {
            name: 'fbmPartnerName',
            inputElement: 'select',
            label: 'FBM Partner',
            gridValue: 2,
            inputProps: { required: false, opts: fbmAccountOpts },
        },
        {
            name: 'easypostCarrierAccountName',
            inputElement: 'autocomplete',
            label: 'Easypost Account',
            gridValue: 3,
            inputProps: { opts: epOpts.map((item) => item.name) },
        },
        {
            name: 'lhCarrier',
            inputElement: 'textField',
            label: 'LH Carrier',
            gridValue: 3,
        },
    ];

    return (
        <div>
            <h1>Add Line Haul</h1>
            <Notifications options={notification} />
            <div>
                <h2 style={{ textAlign: 'left' }}>Create new line haul: </h2>
            </div>
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        formData.facility = user.getFacilityID();

                        let response = await logisticsApi.createLineHaul(formData);
                        if (response.status === false) {
                            setNotification({ text: 'Unable to create line haul ' + response.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        resetForm();
                    }}
                />
            </div>
        </div>
    );
};

let ShiftLineHauls = () => {
    let [week, setWeek] = useState('');
    let [year, setYear] = useState('');
    let [lineHaulShifts, setLineHaulShifts] = useState([]);
    let [activeLineHauls, setActiveLineHauls] = useState([]);
    let [notification, setNotification] = useState({ text: '', level: '' });

    let getActiveLineHauls = async () => {
        let res = await logisticsApi.getLineHauls('activeonly');
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to get line hauls' });
            return;
        }

        let lineHauls = res.data.lineHauls.map((item) => {
            return { text: `${item.id} - ${item.carrier} ${item.hub} ${item.arrivalDay}`, value: item.id };
        });

        setActiveLineHauls(lineHauls);
    };

    let getLineHaulShifts = async (newWeek, newYear) => {
        let res = await logisticsApi.getLineHaulShifts(newWeek || week, newYear || year);
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to get line haul shifts' });
            return;
        }

        setWeek(res.data.week);
        setYear(res.data.year);
        setLineHaulShifts(res.data.rows);
    };

    let shiftLineHauls = async (formData) => {
        let sendParams = {
            toLineHaulID: formData.toLineHaulID,
            week: week,
            year: year,
        };

        let res = await logisticsApi.shiftLineHaul(formData.fromLineHaulID, sendParams);
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to shift line haul for week. ' + res.msg });
            return;
        }

        getLineHaulShifts();
    };

    let clearLineHaulShift = async (fromLineHaulID) => {
        let res = await logisticsApi.clearLineHaulShift(fromLineHaulID, { week, year });
        if (!res.status) {
            setNotification({ level: 'error', text: 'Failed to clear line haul shift' });
            return;
        }

        getLineHaulShifts();
    };

    let formFields = [
        {
            name: 'week',
            initialValue: week,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { required: true, type: 'number', max: '53' },
        },
        {
            name: 'year',
            initialValue: year,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { required: true, type: 'number', maxLength: '4' },
        },
    ];

    let shiftFormFields = [
        {
            name: 'fromLineHaulID',
            label: 'From Line Haul ID',
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: activeLineHauls },
            gridValue: 5,
        },
        {
            name: 'toLineHaulID',
            label: 'To Line Haul ID',
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: activeLineHauls },
            gridValue: 5,
        },
    ];

    let columns = [
        {
            Header: 'From Line Haul',
            Cell: ({ row }) => {
                return (
                    <div style={{ padding: '10px' }}>
                        {row.original.fromLineHaulID} - {row.original.fromCarrier} {row.original.fromHub}{' '}
                        {row.original.fromArrivalDay}
                    </div>
                );
            },
        },
        {
            Header: 'To Line Haul',
            Cell: ({ row }) => {
                return (
                    <div style={{ padding: '10px' }}>
                        {row.original.toLineHaulID} - {row.original.toCarrier} {row.original.toHub}{' '}
                        {row.original.toArrivalDay}
                    </div>
                );
            },
        },
        {
            Header: 'Clear',
            Cell: ({ row }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={async () => {
                            if (window.confirm('Clear line haul shift?') === true) {
                                let fromLineHaulID = row.original.fromLineHaulID;
                                let res = await clearLineHaulShift(fromLineHaulID);
                                return res;
                            }
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        getLineHaulShifts();
        getActiveLineHauls();
    }, []);

    return (
        <div>
            <Notifications options={notification} />
            {week && year && (
                <>
                    <FormComponent
                        formFields={formFields}
                        button={{ gridValue: 2, text: 'Set Week and Year' }}
                        onSubmit={async (formData) => {
                            getLineHaulShifts(formData.week, formData.year);
                        }}
                    />
                    <DataTable title="Line Haul Shifts" columns={columns} data={lineHaulShifts} />
                    <h4>Add Line Haul Shift</h4>
                    <FormComponent
                        formFields={shiftFormFields}
                        button={{ gridValue: 2, text: 'Shift' }}
                        onSubmit={async (formData, resetForm) => {
                            let res = shiftLineHauls(formData);
                            if (res) {
                                resetForm();
                            }
                        }}
                    />
                </>
            )}
        </div>
    );
};

export { LineHaulManagement, LineHaulsComponent };
