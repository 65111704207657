import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { TabPanel, DataTable, Notifications } from '../../../shared';
import { infoTechApi as api } from '../../../api/infoTech';
import { partnershipsApi } from '../../../api/partnerships';
import { ViewJalapenoProfiles } from './viewJalapenoProfiles';
import { ViewJalapenoAccounts } from './viewJalapenoAccounts';
import { CreateJalapenoAccount } from './createJalapenoAccount';

const FoodAsMedicineAccounts = ({ baseUrl }) => {
    const [selectedTab, setSelectedTab] = useState('0');
    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="FAMAccounts_tab" label="Accounts" value="0" />
                <Tab id="CreateFAMAccount_tab" label="Create Account" value="1" />
                <Tab id="FAMProfiles_tab" label="Profiles" value="2" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewJalapenoAccounts accountType="FOOD AS MEDICINE" />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateJalapenoAccount accountType="FOOD AS MEDICINE" />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <ViewJalapenoProfiles accountType="FOOD AS MEDICINE" />
            </TabPanel>
        </Paper>
    );
};

export { FoodAsMedicineAccounts };
