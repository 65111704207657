import React, { useContext, useState, useEffect } from 'react';
import { Paper, TextField, Button, Select, MenuItem, Grid } from '@material-ui/core';
import { DataTable, Notifications } from '../../../../shared';
import { UserContext } from '../../../../contexts/UserContext';
import { fbmApi as api } from '../../../../api/fbm';
import moment from 'moment';

const ViewReceivingDiscrepancy = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [reportIsReady, setReportIsReady] = useState(false);

    const [reportStartDate, setReportStartDate] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    let handleReportStartDateChange = (event) => {
        setReportStartDate(event.target.value);
    };

    const [reportEndDate, setReportEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    let handleReportEndDateChange = (event) => {
        setReportEndDate(event.target.value);
    };

    const { fbmAccount } = useContext(UserContext);

    let handleRunReportClick = (event) => {
        getAndRenderReportData();
    };

    let columns = [
        {
            accessor: 'fbm_purchase_order_id',
            csvAccessor: 'fbm_purchase_order_id',
            Header: 'Partner PO ID',
            width: 150,
        },
        { accessor: 'fbm_sku', csvAccessor: 'fbm_sku', Header: 'Partner SKU', width: 150 },
        { accessor: 'item_name_internal', csvAccessor: 'item_name_internal', Header: 'Item Name Internal', width: 200 },
        { accessor: 'date_received', csvAccessor: 'date_received', Header: 'Date Recv', width: 125 },
        { accessor: 'cases_received_display', csvAccessor: 'cases_received', Header: 'Cases Recv', width: 125 },
        { accessor: 'units_received', csvAccessor: 'units_received', Header: 'Units Recv', width: 125 },
        {
            accessor: 'scheduled_delivery_date',
            csvAccessor: 'scheduled_delivery_date',
            Header: 'Sched Deliv Date',
            width: 150,
        },
        { accessor: 'cases_ordered_display', csvAccessor: 'cases_ordered', Header: 'Cases Ordered', width: 150 },
        { accessor: 'units_ordered_display', csvAccessor: 'units_ordered', Header: 'Units Ordered', width: 150 },
        { accessor: 'discrepancy_display', csvAccessor: 'discrepancy', Header: 'Discrepancy', width: 125 },
        { accessor: 'internal_sku', csvAccessor: 'internal_sku', Header: 'Int SKU', width: 100 },
        { accessor: 'external_sku', csvAccessor: 'external_sku', Header: 'Ext SKU', width: 100 },
        { accessor: 'vendor_name', csvAccessor: 'vendor_name', Header: 'Vendor Name', width: 150 },
        { accessor: 'auth_user', csvAccessor: 'auth_user', Header: 'Auth User', width: 150 },
        { accessor: 'facility', csvAccessor: 'facility', Header: 'Facility', width: 100 },
        { accessor: 'purchase_order_id', csvAccessor: 'purchase_order_id', Header: 'PO ID', width: 100 },
        { accessor: 'purchase_order_map_id', csvAccessor: 'purchase_order_map_id', Header: 'PO Map ID', width: 125 },
    ];

    let getAndRenderReportData = async () => {
        setReportIsReady(false);

        setRows([]);

        let response = await api.getReportReceivingDiscrepancyView(
            fbmAccount.fbmAccountName,
            reportStartDate,
            reportEndDate
        );

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportIsReady(true);
            return;
        }

        setRows(
            response.data.rows.map((item) => {
                item.discrepancy_display = Number(item.discrepancy * 100).toLocaleString() + '%';
                item.units_ordered_display = Number(item.units_ordered).toLocaleString();
                item.cases_ordered_display = Number(item.cases_ordered).toLocaleString();
                item.units_received_display = Number(item.units_received).toLocaleString();
                item.cases_received_display = Number(item.cases_received).toLocaleString();
                return item;
            })
        );

        setReportIsReady(true);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getAndRenderReportData();
        }
    }, [fbmAccount]);

    return (
        <Paper>
            <Notifications options={notification} />

            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <h2>{fbmAccount.companyName} - Receiving Discrepancy Report</h2>
                </Grid>

                <Grid item md={12} style={{ paddingRight: '35px' }}>
                    <TextField
                        id="reportStartDate"
                        label="Start Date"
                        type="date"
                        defaultValue={reportStartDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={!reportIsReady}
                        onChange={handleReportStartDateChange}
                        style={{ marginRight: '50px' }}
                    />

                    <TextField
                        id="reportEndDate"
                        label="End Date"
                        type="date"
                        defaultValue={reportEndDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={!reportIsReady}
                        onChange={handleReportEndDateChange}
                        style={{ marginRight: '50px' }}
                    />

                    <Button id="runReport" onClick={handleRunReportClick} color="secondary" disabled={!reportIsReady}>
                        Run Report
                    </Button>
                </Grid>

                <Grid item xs={12} style={{ paddingTop: '40px' }}>
                    <DataTable
                        csvExport={reportIsReady}
                        csvExportFileName="receiving-discrepancy"
                        csvExportHeadersUseAccessor={true}
                        csvExportAccessor="csvAccessor"
                        columns={columns}
                        data={rows}
                        editable={false}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export { ViewReceivingDiscrepancy };
