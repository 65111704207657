import React, { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import { Button, Card, CardContent, Grid, CardHeader, IconButton, Divider, Select, MenuItem } from '@material-ui/core';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { warehouseApi } from '../../../api/warehouse';
import { shippingApi as api } from '../../../api/shipping';
import { makeStyles, lighten, darken } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../../contexts/UserContext';

const BatchelorReprint = () => {
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [shipments, setShipments] = useState([]);
    const [resettingShipments, setResettingShipments] = useState(false);
    const user = useContext(UserContext);
    const [facilityID, setFacilityID] = useState(user.getFacilityID());

    const getPrintedNotPacked = async () => {
        let res = await api.getPrintedNotPacked(facilityID, 1);
        if (res.status) {
            res.data.shipments.forEach((item) => {
                item.id = item.shipmentID;
                item.printedAt = moment(item.printedAt.date).format('MM/DD/YYYY HH:mm:ss');
                item.createdOn = moment(item.createdAt.date).format('MM/DD/YYYY');
                item.customized = item.customized ? 'true' : 'false';
                item.addOns = item.addOns ? 'true' : 'false';
            });
            setShipments(res.data.shipments);
        }
    };

    useEffect(() => {
        if (facilityID) {
            getPrintedNotPacked();
        }
    }, [facilityID]);

    const columns = [
        { accessor: 'shipmentID', Header: 'Shipment ID' },
        { accessor: 'createdOn', Header: 'Order Created' },
        { accessor: 'shopOrderNum', Header: 'Order Number' },
        { accessor: 'cubeLevel', Header: 'Box Size' },
        { accessor: 'carrier', Header: 'Carrier' },
        { accessor: 'hub', Header: 'Hub' },
        { accessor: 'arrivalDay', Header: 'Arrival Day' },
        { accessor: 'printedAt', Header: 'Last Printed' },
    ];

    const bulkActions = [
        {
            name: 'Reset',
            disabled: resettingShipments,
            action: async (ids) => {
                setResettingShipments(true);
                let res = await api.resetShipmentsToLabelPurchased(ids);
                if (!res.status) {
                    setNotification({ text: 'Failed to reset shipments: ' + res.msg, level: 'error' });
                } else {
                    setNotification({ text: 'Shipments reset to label purchased', level: 'success' });
                    getPrintedNotPacked();
                }
                setResettingShipments(false);
            },
        },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item md={12} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                <h1>Batchelor Reprint 🌹</h1>
                <sub>
                    <b>Note:</b> Shows shipments created within the past 6 days with the status "LABEL_PRINTED", which
                    have not been printed (or reprinted) in the past hour.
                </sub>
            </Grid>
            <Grid item xs={12}>
                <DataTable data={shipments} columns={columns} bulkActions={bulkActions} selectMultiple={true} />
            </Grid>
        </Grid>
    );
};

export { BatchelorReprint };
