import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

let inboundTruckApi = {
    addItemsToInboundTruck: async (id, item) => {
        let url = `api/logistics/v1/inbound/trucks/${id}/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    cancelTruck: async (truckID) => {
        let url = `api/logistics/v1/inbound/trucks/${truckID}/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    createInboundCarrier: async (formData) => {
        let url = `api/logistics/v1/inbound/carriers`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(formData),
        });
        return response;
    },
    createTruck: async (item) => {
        let url = `api/logistics/v1/inbound/trucks`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createTruckTemperature: async (id, stopID, item) => {
        let url = `api/logistics/v1/inbound/trucks/${id}/stop/${stopID}/temperature/create`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    downloadTruckShipList: async (truckID, facilityID) => {
        let url = `api/logistics/v1/inbound/trucks/${truckID}/shipList?facilityID=${facilityID}`;
        let filename = 'ship_list_' + truckID + '.pdf';

        let res = await mmFetch(url);

        if (res.ok === true) {
            var blob = await res.blob();
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }

            var d = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.href = d;
            link.download = filename;
            link.click();

            setTimeout(function () {
                window.URL.revokeObjectURL(res);
            }, 200);
        } else {
            let decodedResponse = await res.json();
            throw new Error(decodedResponse.msg);
        }
    },
    downloadTruckTempSummary: async (truckID, stopID) => {
        let url = `api/logistics/v1/inbound/trucks/${truckID}/stop/${stopID}/tempSummary`;
        let filename = 'truck_temps_' + truckID + '.pdf';
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadTruckTempSummary] error', e.toString());
        }
    },
    editTruckDetails: async (truckID, item) => {
        let url = `api/logistics/v1/inbound/trucks/${truckID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    editTruckFacilityStopDetails: async (facilityStopID, item) => {
        let url = `api/logistics/v1/inbound/trucks/stops/${facilityStopID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    getPOsNotOnTruck: async (facilityID) => {
        let url = `api/logistics/v1/inbound/trucks/purchaseOrders/unassigned?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPOItemsOnPartialPOTrucks: async (truckID, poID, skusNotOnTruck) => {
        let url = `api/logistics/v1/inbound/trucks/purchaseOrders/partialPoTruckItems/${truckID}/${poID}`;

        if (skusNotOnTruck) {
            url += `?skusNotOnTruck=${skusNotOnTruck}`;
        }

        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTruckDetails: async (truckID, facilityID, poType = 'full') => {
        let url = `api/logistics/v1/inbound/trucks/${truckID}?facilityID=${facilityID}&poType=${poType}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getInboundOptions: async () => {
        let url = `api/logistics/v1/inbound/options`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTrucks: async (facilityID, searchObj) => {
        let url = `api/logistics/v1/inbound/trucks?facilityID=` + facilityID;
        for (let key in searchObj) {
            if (searchObj.hasOwnProperty(key)) {
                url += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(searchObj[key]);
            }
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTruckTemperatures: async (truckId, stopId) => {
        let url = `api/logistics/v1/inbound/trucks/${truckId}/temperatures?truckStopID=` + stopId;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    removeItemFromInboundTruck: async (id, poID) => {
        let url = `api/logistics/v1/inbound/trucks/${id}/item/${poID}/remove`;

        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    removeItemFromPartialPOTruck: async (truckID, poID, poMapID) => {
        let url = `api/logistics/v1/inbound/trucks/${truckID}/item/${poID}/${poMapID}/remove`;

        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    removePackagingItemFromInboundTruck: async (id, ppoID) => {
        let url = `api/logistics/v1/inbound/trucks/${id}/packaging/${ppoID}/remove`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    uploadTruckTemperatures: async (id, stopID, data) => {
        let formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        let url = `api/logistics/v1/inbound/trucks/${id}/stop/${stopID}/temperatures`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    getPartialTruckPOs: async (facilityID, deliveryType) => {
        let url = `api/logistics/v1/inbound/trucks/purchaseOrders/partialTruckPurchaseOrders/${facilityID}?deliveryType=${deliveryType}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPOItemsAvailableForPartialPOTrucks: async (poID) => {
        let url = `api/logistics/v1/inbound/trucks/purchaseOrders/partialPoTruckItems/${poID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
};

export { inboundTruckApi };
