import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewPackagingASN } from './viewPackagingASN.js';
import { CreatePackagingASN } from './createPackagingASN.js';
import { TabPanel, FBMAccountPicker } from '../../../shared';

const FBMPackagingAsn = () => {
    const [selectedTab, setSelectedTab] = useState('0');
    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <FBMAccountPicker />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewLittleSpoonPackagingASN_Tab" label="View" value="0" />
                <Tab id="CreateLittleSpoonPackagingASN_Tab" label="Create" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewPackagingASN />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreatePackagingASN />
            </TabPanel>
        </Paper>
    );
};

export { FBMPackagingAsn };
