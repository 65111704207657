import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Notifications, DataTable, FormComponent, TabPanel } from '../../../shared';
import { financeApi as api } from '../../../api/finance';
import { Button, Dialog, DialogContent, DialogTitle, Paper, Tabs, Tab, Grid, TextField } from '@material-ui/core';

const DriverTipsBackChargeReport = () => {
    let [rows, setRows] = useState([]);
    let [reportIsReady, setReportIsReady] = useState(false);
    let [reportButtonText, setReportButtonText] = useState('Loading...');
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [reportStartDate, setReportStartDate] = React.useState(moment().isoWeekday(1).format('YYYY-MM-DD'));

    let handleReportStartDateChange = (event) => {
        setReportStartDate(event.target.value);
    };

    let handleRunReportClick = (event) => {
        const selectedDate = new Date(reportStartDate);
        const dayOfWeek = selectedDate.getDay();

        if (dayOfWeek !== 0) {
            setNotification({ text: 'Must select a Monday', level: 'error' });
            return;
        }

        getAndRenderReportData();
    };

    let getAndRenderReportData = async () => {
        setReportIsReady(false);

        setReportButtonText('Loading...');
        setRows([]);

        let response = await api.getDriverTipsBackChargeReport(reportStartDate);

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportButtonText('Run Report');
            setReportIsReady(true);
            return;
        }

        setRows(response.data.rows);
        setReportButtonText('Run Report');
        setReportIsReady(true);
    };

    useEffect(() => {
        getAndRenderReportData();
    }, []);

    let columns = [
        { accessor: 'refundDate', Header: 'Refund Date' },
        { accessor: 'refundTimeStamp', Header: 'Refund Timestamp' },
        { accessor: 'orderID', Header: 'Order ID' },
        { accessor: 'poolType', Header: 'Tip Type' },
        { accessor: 'costCenter', Header: 'Cost Center (Location)' },
        { accessor: 'driverID', Header: 'Driver EEID' },
        { accessor: 'deliveredAt', Header: 'Delivery Date' },
        { accessor: 'tipAmount', Header: 'Tip Amount' },
        { accessor: 'tipRefunded', Header: 'Tip Refunded' },
        { accessor: 'netTip', Header: 'Net Tip' },
    ];

    return (
        <div>
            <Notifications options={notification} />
            <Grid item md={12} style={{ paddingRight: '35px' }}>
                <TextField
                    id="reportStartDate"
                    label="Start Date"
                    type="date"
                    value={reportStartDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportStartDateChange}
                    style={{ marginRight: '50px' }}
                />

                <Button id="runReport" onClick={handleRunReportClick} color="secondary" disabled={!reportIsReady}>
                    {reportButtonText}
                </Button>
            </Grid>
            <DataTable
                columns={columns}
                data={rows}
                csvExport={true}
                csvExportFileName="driver-back-charge-report"
                csvExportHeadersUseAccessor={true}
            />
        </div>
    );
};

export { DriverTipsBackChargeReport };
