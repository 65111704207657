import React, { useState, useEffect } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Button, Modal } from '@material-ui/core';
import { logisticsApi as api } from '../../../api/logistics';
import { Grid, Select, MenuItem } from '@material-ui/core';
import moment from 'moment';

let DBICrossDocks = ({ data }) => {
    let [rows, setRows] = useState(data || []);
    let [notification, setNotification] = useState({ text: null, level: null });

    const getRows = async () => {
        let res = await api.getDBICrossDocks();
        if (res.status) {
            setRows(res.data.rows);
        }
    };

    let saveEdit = async ({ id, field, value }) => {
        const item = { [field]: value };
        const response = await api.updateCrossDock(id, item);
        if (response.status === false) {
            let message = 'Error modifying cross dock';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            setNotification({ text: 'Cross dock modified!', level: 'success' });
            await getRows();
        }
        return response.status;
    };

    useEffect(() => {
        getRows();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'hub', Header: 'Hub' },
        { accessor: 'lineOne', Header: 'Line One' },
        { accessor: 'city', Header: 'City' },
        { accessor: 'province', Header: 'State' },
        { accessor: 'zip', Header: 'Zip Code' },
        { accessor: 'etos', Header: 'ETOS', editable: true },
    ];

    return (
        <div>
            <h1>DBI Cross Docks</h1>
            <Notifications options={notification} />
            <div>
                <DataTable columns={columns} data={rows} editable={true} saveEdit={saveEdit} />
            </div>
        </div>
    );
};

export { DBICrossDocks };
