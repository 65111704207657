import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogContent, Grid, InputLabel, TextField } from '@material-ui/core';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';

let EditCubeRangesModal = ({ rows, modalOpen, setModalOpen, setNotification, getCubeLevels }) => {
    let formFields = [];

    for (let row of rows) {
        formFields.push({ name: row.name, inputElement: 'textField', label: 'ID', gridValue: 3, value: row.id });
        formFields.push({
            name: `${row.name}Name`,
            inputElement: 'textField',
            label: 'Name',
            gridValue: 3,
            value: row.name,
        });
        formFields.push({
            name: `${row.name}MinRange`,
            inputElement: 'textField',
            label: 'Min',
            gridValue: 3,
            initialValue: '' + row.minRange,
            inputProps: { type: 'number', integer: true },
        });
        formFields.push({
            name: `${row.name}MaxRange`,
            inputElement: 'textField',
            label: 'Max',
            gridValue: 3,
            initialValue: '' + row.maxRange,
            inputProps: { type: 'number', integer: true },
        });
    }
    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={modalOpen}
            onClose={() => {
                setModalOpen(false);
            }}
        >
            <DialogContent>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let sendData = {
                            a1MinRange: formData.A1MinRange,
                            a1MaxRange: formData.A1MaxRange,
                            a2MinRange: formData.A2MinRange,
                            a2MaxRange: formData.A2MaxRange,
                            a3MinRange: formData.A3MinRange,
                            a3MaxRange: formData.A3MaxRange,
                            a4MinRange: formData.A4MinRange,
                            a4MaxRange: formData.A4MaxRange,
                            a5MinRange: formData.A5MinRange,
                            a5MaxRange: formData.A5MaxRange,
                        };

                        let res = await api.updateCubeRanges(sendData);
                        if (!res.status) {
                            setNotification({ text: res.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Cube ranges updated', level: 'success' });
                        resetForm();
                        getCubeLevels();
                        setModalOpen(false);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let CubeLevels = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [showEditCubeRangesModal, setShowEditCubeRangesModal] = useState(false);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'name', Header: 'Name' },
        {
            accessor: 'minRange',
            Header: 'Min Range',
            editable: true,
            customEdit: () => {
                setShowEditCubeRangesModal(true);
            },
        },
        {
            accessor: 'maxRange',
            Header: 'Max Range',
            editable: true,
            customEdit: () => {
                setShowEditCubeRangesModal(true);
            },
        },
    ];

    let getCubeLevels = async () => {
        let response = await api.getCubeLevels();

        if (response.status === false) {
            setNotification({ text: 'No cube levels found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.cubeLevels);
    };

    useEffect(() => {
        getCubeLevels();
    }, []);

    return (
        <div>
            <h1>Cube Levels</h1>
            <Notifications options={notification} />
            <EditCubeRangesModal
                modalOpen={showEditCubeRangesModal}
                setModalOpen={setShowEditCubeRangesModal}
                rows={rows}
                getCubeLevels={getCubeLevels}
                setNotification={setNotification}
            />
            <div>
                <DataTable columns={columns} data={rows} editable={true} />
            </div>
        </div>
    );
};

export { CubeLevels };
