import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { specialOrdersApi as api } from '../../../api/specialOrders';
import { procurementApi as procApi } from '../../../api/procurement';

let CreateSpecialOrder = () => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [prodOps, setProdOps] = useState([
        { text: 'Mischief', value: 'mischief' },
        { text: 'Madness', value: 'madness' },
    ]);
    let [typeOps, setTypeOps] = useState([
        { text: 'Influencer', value: 'Influencer' },
        { text: 'Partnership', value: 'Partnership' },
        { text: 'Affiliate', value: 'Affiliate' },
        { text: 'Donation', value: 'Donation' },
        { text: 'Press', value: 'Press' },
        { text: 'Overnight Non-produce', value: 'Overnight Non-produce' },
        { text: 'Payment/Quid pro quo', value: 'Payment/Quid pro quo' },
        { text: 'Customer', value: 'Customer' },
        { text: 'Employee', value: 'Employee' },
        { text: 'Testing', value: 'Testing' },
    ]);
    let [stateOpts, setStateOpts] = useState([]);

    let formFields = [
        { name: 'shipDate', inputElement: 'date', label: 'Ship Date', gridValue: 3, inputProps: { required: true } },
        {
            name: 'product',
            inputElement: 'select',
            label: 'Product',
            gridValue: 3,
            inputProps: { required: true, opts: prodOps },
        },
        {
            name: 'quantity',
            inputElement: 'textField',
            label: 'Num Boxes',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'type',
            inputElement: 'select',
            label: 'Type',
            gridValue: 3,
            inputProps: { required: true, opts: typeOps },
        },
        {
            name: 'firstName',
            inputElement: 'textField',
            label: 'First Name',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'lastName',
            inputElement: 'textField',
            label: 'Last Name',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'phoneNumber',
            inputElement: 'textField',
            label: 'Phone Number',
            gridValue: 6,
            inputProps: { required: true },
        },
        {
            name: 'recipientEmail',
            inputElement: 'textField',
            label: 'Recipient Email',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'street1',
            inputElement: 'textField',
            label: 'Address Line One',
            gridValue: 6,
            inputProps: { required: true },
        },
        { name: 'street2', inputElement: 'textField', label: 'Address Line Two', gridValue: 3 },
        { name: 'city', inputElement: 'textField', label: 'City', gridValue: 6, inputProps: { required: true } },
        {
            name: 'state',
            inputElement: 'select',
            label: 'State',
            gridValue: 3,
            inputProps: { required: true, opts: stateOpts },
        },
        { name: 'zip', inputElement: 'textField', label: 'Zip Code', gridValue: 3, inputProps: { required: true } },
        { name: 'notes', inputElement: 'textField', label: 'Notes', gridValue: 12 },
    ];

    // Load province
    let getStates = async (country) => {
        if (country === 'United States') {
            country = 'unitedstates';
        }

        if (country === undefined) {
            let pleaseSelect = { text: 'Please Select', value: 0 };
            setStateOpts([pleaseSelect]);
            return;
        }

        let response = await procApi.getProvinces(country);
        if (response.status === false) {
            setNotification({ text: 'No provinces found ' + response.msg, level: 'error' });
            let pleaseSelect = { text: 'Please Select', value: 0 };
            setStateOpts([pleaseSelect]);
            return;
        }

        let provinceOpts = response.data.rows.map((row) => {
            let out = {};
            out.text = row.name;
            out.value = row.twoLetter;
            return out;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setStateOpts([pleaseSelect].concat(provinceOpts));
    };

    useEffect(() => {
        getStates('United States');
    }, []);

    let createSpecialOrder = async (formData) => {
        let response = await api.createSpecialOrder(formData);
        if (response.status === true) {
            setNotification({ text: 'Special order created!', level: 'success' });
        } else {
            let message = 'Error creating special order';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
    };

    return (
        <div>
            <h1>Create Special Order</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        await createSpecialOrder(formData);
                        resetForm();
                    }}
                />
            </div>
        </div>
    );
};

export { CreateSpecialOrder };
