import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';
import { Modal, Button, Select, NativeSelect } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

let ViewGoPuffPricing = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    // Load  table
    let getRows = async () => {
        let response = await api.getPricing();
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY');
                row.effectiveDate = moment(row.effectiveDate.date).format('MM/DD/YYYY');
                return row;
            });
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getRows();
    }, []);

    let columns = [
        { Header: 'Pricing ID', accessor: 'id' },
        { Header: 'External SKU', accessor: 'externalSku' },
        { Header: 'Effective Date', accessor: 'effectiveDate' },
        { Header: 'Each Cost', accessor: 'eachCost' },
        { Header: 'Case Cost', accessor: 'caseCost' },
        { Header: 'Currency', accessor: 'currency' },
        { Header: 'Facility', accessor: 'facilityID' },
    ];

    return (
        <div>
            <h1>Go Puff Pricing</h1>
            <Link to={`/goPuff/pricing-upload`}>Upload</Link>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable columns={columns} data={rows} />
            </div>
        </div>
    );
};

export { ViewGoPuffPricing };
