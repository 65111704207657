import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { FormComponent } from '../../../shared';
import { customerApi as api } from '../../../api/customer';
import { RefundItemDetails } from './RefundItemDetails';
import { MultishipRefunds } from './MultishipRefunds';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';
import { deliveryReasons, deliveryReasonsMap, productReasons, productReasonsMap } from '../data/refundData';

const RefundModalLineItems = ({ open, setOpen, orderNumber, rowInfo, getSubscription, setNotification, getOrders }) => {
    let today = moment();
    const user = useContext(UserContext);

    const [order, setOrder] = useState(null);
    const [orderTotal, setOrderTotal] = useState(0);
    const [refundHistoryData, setRefundHistoryData] = useState(null);
    const [refundableItems, setRefundableItems] = useState([]);
    const [refundItemsWithTaxonomy, setRefundItemsWithTaxonomy] = useState([]);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [initialRemainingAmount, setInitialRemainingAmount] = useState(0);
    const [itemsSelected, setItemsSelected] = useState(null);
    const [itemsSelectedTotal, setItemsSelectedTotal] = useState(0);
    const [shippingChecked, setShippingChecked] = useState(false);
    const [shippingRefund, setShippingRefund] = useState(0);
    const [shippingRefunded, setShippingRefunded] = useState(false);
    const [coldKeeperFeeChecked, setColdKeeperFeeChecked] = useState(false);
    const [coldKeeperFeeRefund, setColdKeeperFeeRefund] = useState(0);
    const [coldKeeperFeeRefunded, setColdKeeperFeeRefunded] = useState(false);
    const [shippingTaxRefund, setShippingTaxRefund] = useState(0);
    const [coldKeeperFeeTaxRefund, setColdKeeperFeeTaxRefund] = useState(0);
    const [creditsRefunded, setCreditsRefunded] = useState(0);
    const [refundType, setRefundType] = useState('');
    const [primaryRefundReason, setPrimaryRefundReason] = useState('');
    const [secondaryRefundReason, setSecondaryRefundReason] = useState('');
    const [creditAmountForm, setCreditAmountForm] = useState(0);
    const [isProductSpill, setIsProductSpill] = useState(false);
    const [fullRefundChecked, setFullRefundChecked] = useState(false);
    const [refundableItemsByShipments, setRefundableItemsByShipments] = useState([]);
    const [shipmentBoxChecked, setShipmentBoxChecked] = useState([]);
    const [otherReasonForm, setOtherReasonForm] = useState('');
    const [shipmentDetails, setShipmentDetails] = useState([]);
    const [daysSinceDelivered, setDaysSinceDelivered] = useState(0);
    const [tipRefunded, setTipRefunded] = useState(0);
    const [tipRefund, setTipRefund] = useState(0);

    const getLIPOrderDetails = async () => {
        let res = await api.getOrderDetails(orderNumber);
        if (!res) {
            console.log('error getting order details');
            return;
        }

        let shipments = res.data.orderInfo.shipments;
        setShipmentDetails(shipments);

        // Set days since delivered. Use last box delivered (soonest)
        for (let i = 0; i < shipments.length; i++) {
            let days = today.diff(moment(rowInfo.deliverDate), 'days');
            if (days < daysSinceDelivered) {
                setDaysSinceDelivered(days);
            }
        }

        let orderInfo = res.data.orderInfo;
        setInitialRemainingAmount(orderInfo?.refundHistory?.amountRemaining);
        setRemainingAmount(orderInfo?.refundHistory?.amountRemaining);
        setOrder(orderInfo);

        let chargeDetails = res.data.orderInfo.charge;
        let sum =
            Number(chargeDetails.subtotal) +
            chargeDetails.shipping +
            chargeDetails.taxes +
            chargeDetails.coldKeeperFee +
            chargeDetails.tipAmount -
            chargeDetails.discountAmount;
        setOrderTotal(sum);

        let refundHistory = res.data.orderInfo.refundHistory;
        setRefundHistoryData(refundHistory);

        if (refundHistory.shipping > 0) {
            setShippingRefunded(true);
            setShippingRefund(refundHistory.shipping + refundHistory.shippingTaxRefund);
        } else {
            setShippingRefunded(false);
            setShippingRefund(0);
        }

        if (refundHistory.shippingTaxRefund > 0) {
            setShippingTaxRefund(refundHistory.shippingTaxRefund);
        } else {
            setShippingTaxRefund(0);
        }

        if (refundHistory.credits > 0) {
            setCreditsRefunded(refundHistory.credits);
        } else {
            setCreditsRefunded(0);
        }

        if (refundHistory.coldKeeperFeeRefund > 0) {
            setColdKeeperFeeRefunded(true);
            setColdKeeperFeeRefund(refundHistory.coldKeeperFeeRefund + refundHistory.coldKeeperFeeTaxRefund);
        } else {
            setColdKeeperFeeRefunded(false);
            setColdKeeperFeeRefund(0);
        }

        if (refundHistory.coldKeeperFeeTaxRefund > 0) {
            setColdKeeperFeeTaxRefund(refundHistory.coldKeeperFeeTaxRefund);
        } else {
            setColdKeeperFeeTaxRefund(0);
        }

        if (refundHistory.tipAmountRefunded > 0) {
            setTipRefunded(refundHistory.tipAmountRefunded);
        } else {
            setTipRefunded(0);
        }
    };

    const getRefundableItems = async () => {
        let res = await api.getRefundableItems(rowInfo.charge.chargeID);
        if (!res) {
            console.log('error getting refundable items');
            return;
        }

        let data = res.data.refundableItems;
        setRefundableItemsByShipments(data);
        setRefundItemsWithTaxonomy(data);

        let allRefundableItems = [];

        if (data.length < 1) {
            setRefundableItems([]);
        } else {
            for (let i = 0; i < data.length; i++) {
                let tmp = [];
                let itemSet = data[i].items;
                tmp = tmp.concat(itemSet);
                allRefundableItems = allRefundableItems.concat(tmp);
                tmp = [];
            }
            setRefundableItems(allRefundableItems);
        }
    };

    let getItemsSelectedTotal = (arr) => {
        if (arr !== null && arr.length > 0) {
            let total = arr.map(
                (item) => item.price * (item.qty - item.freeLoyaltyGiftAmount) + item.tax - item.discount
            );
            let amount = total.reduce((acc, currVal) => {
                return acc + currVal;
            });

            if ((isProductSpill && fullRefundChecked) || creditAmountForm > 0) {
                return;
            } else {
                setItemsSelectedTotal(amount);
            }
        } else {
            setItemsSelectedTotal(0);
        }
    };

    let handlePrimaryRefundReasonChange = (event, value) => {
        setPrimaryRefundReason(value);
        clearSummary();
    };

    let handleSecondaryRefundReasonChange = (event, value) => {
        setSecondaryRefundReason(value);

        const isTypeProductSpill =
            value === 'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_PRODUCT_SPILL_BROKEN_GROCERY_PACKAGING';
        setIsProductSpill(isTypeProductSpill);
    };

    let getShippingAmount = () => {
        let shipAmt = 0;
        if (order?.charge?.shipping > 0) {
            shipAmt = order?.charge?.shipping;
        }

        if (order?.charge?.shippingTaxAmount > 0) {
            shipAmt += order?.charge?.shippingTaxAmount;
        }

        setShippingRefund(shipAmt);
        return shipAmt;
    };

    let getColdKeeperFeeAmount = () => {
        let feeAmt = 0;
        if (order?.charge?.coldKeeperFee > 0) {
            feeAmt = order?.charge?.coldKeeperFee;
        }

        if (order?.charge?.coldKeeperFeeTaxAmount > 0) {
            feeAmt += order?.charge?.coldKeeperFeeTaxAmount;
        }

        setColdKeeperFeeRefund(feeAmt);
        return feeAmt;
    };

    let getTipAmount = () => {
        let tipAmt = 0;
        if (order?.charge?.tipAmount > 0) {
            tipAmt = order?.charge?.tipAmount;
        }

        setTipRefund(tipAmt);
        return tipAmt;
    };

    let handleFullRefundChange = (event, value) => {
        if (event.target.checked === true) {
            getFullRefundDetails();
            setRefundType('REFUND');
            setFullRefundChecked(true);
        } else {
            setRefundType('');
            setFullRefundChecked(false);
            setShippingRefund(0);
            setColdKeeperFeeRefund(0);
            setTipRefund(0);
            clearSummary();
        }
    };

    const getFullRefundDetails = () => {
        if (!shippingRefunded) {
            getShippingAmount();
        }

        if (!coldKeeperFeeRefunded) {
            getColdKeeperFeeAmount();
        }

        getTipAmount();

        const total = refundableItems.map(
            (item) =>
                item.sellableItemModel.price * (item.qty - item.freeLoyaltyGiftAmount) +
                item.taxAmount -
                item.discountAmount
        );
        const amt = total.reduce((acc, currVal) => {
            return acc + currVal;
        }, 0);
        setItemsSelectedTotal(amt);
    };

    let clearSummary = () => {
        setItemsSelected(null);
        setItemsSelectedTotal(0);
        setShippingRefund(0);
        setShippingChecked(false);
        setColdKeeperFeeRefund(0);
        setColdKeeperFeeChecked(false);
        setRemainingAmount(initialRemainingAmount);
        setCreditAmountForm(0);
        setShipmentBoxChecked([]);
        setTipRefund(0);
    };

    useEffect(() => {
        if (open) {
            getLIPOrderDetails();
            getRefundableItems();
        }
    }, [open]);

    useEffect(() => {
        getItemsSelectedTotal(itemsSelected);
    }, [itemsSelected]);

    if (!order) {
        return null;
    }

    const getDeliverySecondaryReasonsForOption = (formData) => {
        return deliveryReasonsMap[formData.deliveryReason];
    };

    const getProductSecondaryReasonsForOption = (formData) => {
        return productReasonsMap[formData.productReason];
    };

    const ShippingField = ({ formData, setFormField }) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        id="Shipping_checkbox"
                        onChange={async (event) => {
                            let ship = 0;
                            if (event.target.checked === true && formData.fullRefund.toString() === 'true') {
                                formData.refundShipping = false;
                                setFormField(!event.target.checked);
                                setShippingRefund(ship);
                            } else if (
                                (event.target.checked === true &&
                                    ((formData.deliveryReason === '' && formData.otherReason === '') ||
                                        formData.productReason !== '' ||
                                        refundHistoryData?.shipping > 0 ||
                                        refundType === 'APPLY_CREDIT')) ||
                                formData.refundColdKeeperFee.toString() === 'true'
                            ) {
                                formData.refundShipping = false;
                                setFormField(!event.target.checked);
                                setShippingRefund(ship);
                            } else if (event.target.checked === false) {
                                formData.refundShipping = false;
                                setShippingRefund(ship);
                                setFormField(event.target.checked);
                                setShippingChecked(false);
                            } else {
                                formData.refundShipping = true;
                                setFormField(event.target.checked);
                                getShippingAmount();
                                setShippingChecked(true);
                            }
                        }}
                        disabled={
                            shippingRefunded ||
                            formData.refundByShipments.length > 0 ||
                            (daysSinceDelivered > 7 && !user.user.canRefundPastDate)
                        }
                        checked={formData.refundShipping === true}
                        color="primary"
                    />
                }
                label={'Shipping'}
            />
        );
    };

    const ColdKeeperFeeField = ({ formData, setFormField }) => {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        id="cold_keeper_checkbox"
                        onChange={async (event) => {
                            let fee = 0;
                            if (event.target.checked === true && formData.fullRefund.toString() === 'true') {
                                formData.refundColdKeeperFee = false;
                                setFormField(!event.target.checked);
                                setColdKeeperFeeRefund(fee);
                            } else if (
                                (event.target.checked === true &&
                                    ((formData.deliveryReason === '' && formData.otherReason === '') ||
                                        formData.productReason !== '' ||
                                        refundHistoryData?.coldKeeperFeeRefund > 0 ||
                                        refundType === 'APPLY_CREDIT')) ||
                                formData.refundShipping.toString() === 'true'
                            ) {
                                formData.refundColdKeeperFee = false;
                                setFormField(!event.target.checked);
                                setColdKeeperFeeRefund(fee);
                            } else if (event.target.checked === false) {
                                formData.refundColdKeeperFee = false;
                                setColdKeeperFeeRefund(fee);
                                setFormField(event.target.checked);
                                setColdKeeperFeeChecked(false);
                            } else {
                                formData.refundColdKeeperFee = true;
                                setFormField(event.target.checked);
                                getColdKeeperFeeAmount();
                                setColdKeeperFeeChecked(true);
                            }
                        }}
                        disabled={
                            coldKeeperFeeRefunded ||
                            formData.refundByShipments.length > 0 ||
                            (daysSinceDelivered > 7 && !user.user.canRefundPastDate)
                        }
                        checked={formData.refundColdKeeperFee === true}
                        color="primary"
                    />
                }
                label={'Cold Keeper'}
            />
        );
    };

    let formFields = [
        {
            name: 'deliveryReason',
            label: 'Whole Order Issue',
            inputElement: 'select',
            gridValue: 11,
            inputProps: {
                opts: deliveryReasons.sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handlePrimaryRefundReasonChange,
                disableUnderline: true,
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
            dependencies: ['productReason', 'productReasonSecondary'],
        },
        {
            name: 'deliveryReasonSecondary',
            label: 'Secondary Reason (Required)',
            inputElement: 'select',
            gridValue: 11,
            dependencies: ['deliveryReason'],
            inputProps: {
                opts: (formData) =>
                    getDeliverySecondaryReasonsForOption(formData).sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handleSecondaryRefundReasonChange,
                disableUnderline: true,
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
            display: (formData) =>
                deliveryReasons
                    .filter((f) => f.value != 'DELIVERY_PRIMARY_OTHER')
                    .map((m) => m.value)
                    .includes(formData.deliveryReason),
        },
        {
            name: 'productReason',
            label: 'Item Specific Issue',
            inputElement: 'select',
            gridValue: 11,
            dependencies: ['deliveryReason', 'deliveryReasonSecondary'],
            inputProps: {
                opts: productReasons.sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handlePrimaryRefundReasonChange,
                disableUnderline: true,
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
        },
        {
            name: 'productReasonSecondary',
            label: 'Secondary Reason (Required)',
            inputElement: 'select',
            gridValue: 11,
            dependencies: ['productReason'],
            inputProps: {
                opts: (formData) =>
                    getProductSecondaryReasonsForOption(formData).sort((a, b) => (a.text > b.text ? 1 : -1)),
                onChange: handleSecondaryRefundReasonChange,
                disableUnderline: true,
                style: { background: '#F7F7F7', borderRadius: '8px', padding: '10px' },
            },
            display: (formData) =>
                productReasons
                    .filter((f) => f.value != 'PRODUCT_PRIMARY_OTHER')
                    .map((m) => m.value)
                    .includes(formData.productReason),
        },
        {
            name: 'otherReason',
            initialValue: '',
            gridValue: 11,
            dependencies: ['deliveryReason', 'productReason'],
            inputProps: {
                multiline: true,
                disableUnderline: true,
            },
            InputProps: { disableUnderline: true },
            display: (formData) =>
                formData.deliveryReason === 'DELIVERY_PRIMARY_OTHER' ||
                formData.productReason === 'PRODUCT_PRIMARY_OTHER'
                    ? true
                    : null,
            inputElement: ({ formData, setFormField }) => {
                if (
                    formData.otherReason === '' &&
                    formData.deliveryReason !== 'DELIVERY_PRIMARY_OTHER' &&
                    formData.productReason !== 'PRODUCT_PRIMARY_OTHER'
                ) {
                    return null;
                }

                return (
                    <div>
                        <h2>Other Reason</h2>
                        <TextField
                            style={{ width: '-webkit-fill-available' }}
                            inputProps={{ maxLength: 240 }}
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="outlined"
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                setOtherReasonForm(e.target.value);
                                setFormField(e.target.value);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            name: 'amountCredit',
            label: 'Amount',
            gridValue: 4,
            display: (formData) =>
                formData.fullRefund.toString() === 'true' ||
                (formData.deliveryReason === '' && formData.otherReason === '') ||
                refundType === 'REFUND'
                    ? null
                    : true,
            inputElement: ({ formData, setFormField }) => {
                if (formData.fullRefund.toString() === 'true' || formData.refundByShipments.length > 0) {
                    return null;
                }

                return (
                    <div>
                        <p style={{ marginBottom: '0' }}>Credit Amount Applied</p>
                        {creditAmountForm > initialRemainingAmount && <p style={{ color: 'red' }}>Invalid amount</p>}
                        <TextField
                            style={{ border: '1px solid lightgray', borderRadius: '8px', width: '100%' }}
                            placeholder="$0.00"
                            inputProps={{
                                type: 'number',
                                step: '.01',
                                min: 0,
                                max: initialRemainingAmount,
                                disableUnderline: true,
                                disabled: daysSinceDelivered <= 14 || user.user.canRefundPastDate ? false : true,
                            }}
                            InputProps={{ disableUnderline: true }}
                            disableUnderline={true}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                setCreditAmountForm(Number(e.target.value));
                                setFormField(Number(e.target.value));
                            }}
                        />
                    </div>
                );
            },
        },
        {
            name: 'refundShipping',
            initialValue: false,
            label: 'Shipping',
            gridValue: 3,
            dependencies: ['deliveryReason', 'otherReason', 'fullRefund', 'refundColdKeeperFee'],
            display: (formData) =>
                formData.fullRefund.toString() === 'true' ||
                (formData.deliveryReason === '' && formData.otherReason === '') ||
                refundHistoryData?.shipping > 0 ||
                refundType === 'APPLY_CREDIT' ||
                coldKeeperFeeChecked === true ||
                shippingRefunded === true
                    ? null
                    : true,
            inputElement: ({ formData, setFormField }) => (
                <ShippingField formData={formData} setFormField={setFormField} />
            ),
        },
        {
            name: 'refundColdKeeperFee',
            initialValue: false,
            label: 'Cold Keeper',
            gridValue: 3,
            dependencies: ['deliveryReason', 'otherReason', 'fullRefund', 'refundShipping'],
            display: (formData) =>
                formData.fullRefund.toString() === 'true' ||
                (formData.deliveryReason === '' && formData.otherReason === '') ||
                refundHistoryData?.coldKeeperFeeRefund > 0 ||
                refundType === 'APPLY_CREDIT' ||
                shippingChecked === true ||
                coldKeeperFeeRefunded === true
                    ? null
                    : true,
            inputElement: ({ formData, setFormField }) => (
                <ColdKeeperFeeField formData={formData} setFormField={setFormField} />
            ),
        },
        {
            name: 'fullRefund',
            initialValue: false,
            label: 'Full Refund',
            inputElement: 'checkbox',
            gridValue: 4,
            dependencies: [
                'deliveryReason',
                'productReason',
                'otherReason',
                'refundShipping',
                'refundByShipments',
                'refundColdKeeperFee',
            ],
            inputProps: {
                style: { paddingTop: '20px' },
                disabled: daysSinceDelivered <= 7 === true || user.user.canRefundPastDate ? false : true,
                onChange: handleFullRefundChange,
            },
            display: (formData) => {
                if (
                    (formData.deliveryReason === '' && formData.otherReason === '') ||
                    refundType === 'APPLY_CREDIT' ||
                    formData.refundByShipments.length > 0
                ) {
                    return null;
                } else {
                    return true;
                }
            },
        },
        {
            name: 'refundByShipments',
            gridValue: 12,
            dependencies: [
                'deliveryReason',
                'deliveryReasonSecondary',
                'productReason',
                'productReasonSecondary',
                'otherReason',
                'fullRefund',
            ],
            initialValue: [],
            display: (formData) => {
                if (
                    (formData.deliveryReason === '' && formData.otherReason === '') ||
                    refundType === 'APPLY_CREDIT' ||
                    formData.fullRefund.toString() === 'true' ||
                    formData.refundShipping === true ||
                    coldKeeperFeeChecked === true
                ) {
                    return null;
                } else {
                    return true;
                }
            },
            inputElement: ({ formData, setFormField }) => {
                return (
                    <MultishipRefunds
                        refundableItems={refundableItems}
                        refundableItemsByShipments={refundableItemsByShipments}
                        formData={formData}
                        setFormField={setFormField}
                        shipmentDetails={shipmentDetails}
                        shipmentBoxChecked={shipmentBoxChecked}
                        setShipmentBoxChecked={setShipmentBoxChecked}
                        setItemsSelectedTotal={setItemsSelectedTotal}
                        getShippingAmount={getShippingAmount}
                        user={user}
                    />
                );
            },
        },
        {
            name: 'itemsAffected',
            gridValue: 11,
            dependencies: ['deliveryReason', 'deliveryReasonSecondary', 'productReason', 'otherReason', 'fullRefund'],
            initialValue: [],
            display: (formData) =>
                formData.deliveryReasonSecondary ===
                    'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_PRODUCT_SPILL_BROKEN_GROCERY_PACKAGING' ||
                formData.productReason !== '',
            inputElement: ({ formData, setFormField }) => {
                if (
                    (formData.fullRefund.toString() === 'true' &&
                        formData.deliveryReasonSecondary !==
                            'DELIVERY_SECONDARY_QUALITY_ARRIVED_DAMAGED_PRODUCT_SPILL_BROKEN_GROCERY_PACKAGING') ||
                    (formData.productReason === '' &&
                        formData.deliveryReason !== 'DELIVERY_PRIMARY_QUALITY_ARRIVED_DAMAGED')
                ) {
                    setFormField([]);
                    return null;
                }
                return (
                    <RefundItemDetails
                        refundItemsWithTaxonomy={refundItemsWithTaxonomy}
                        refundableItems={refundableItems}
                        formData={formData}
                        setFormField={setFormField}
                        itemsSelected={itemsSelected}
                        setItemsSelected={setItemsSelected}
                        productSpill={isProductSpill}
                    />
                );
            },
        },
    ];

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogContent style={{ display: 'flex' }}>
                <Grid item xs={8}>
                    <h2>Select Refund Reason</h2>
                    <div style={{ paddingTop: '0px' }}>
                        <FormComponent
                            inputElementStyle={{ padding: '0px' }}
                            formControlStyle={{ paddingBottom: '10px' }}
                            formFields={formFields}
                            button={{
                                style: { bottom: '8px', right: '100px', position: 'absolute', padding: '6px 8px' },
                                disabled: (formData) => {
                                    if (
                                        formData.deliveryReason === '' &&
                                        formData.productReason === '' &&
                                        formData.otherReason === ''
                                    ) {
                                        return true;
                                    }

                                    if (refundType === '') {
                                        return true;
                                    }

                                    if (creditAmountForm > initialRemainingAmount) {
                                        return true;
                                    }

                                    return false;
                                },
                            }}
                            onSubmit={async (formData, resetForm) => {
                                let sendParams = {
                                    reason: primaryRefundReason,
                                    secondary: secondaryRefundReason,
                                };

                                let res;

                                sendParams.items = formData.itemsAffected;

                                if (sendParams.reason === '') {
                                    setNotification({ text: 'Must select a reason', level: 'error' });
                                    return false;
                                }

                                if (refundType === '') {
                                    setNotification({ text: 'Must select Refund Type', level: 'error' });
                                    return false;
                                }

                                // Optional message from Other Reason form field, if provided by agent
                                if (
                                    sendParams.reason === 'DELIVERY_PRIMARY_OTHER' ||
                                    sendParams.reason === 'PRODUCT_PRIMARY_OTHER'
                                ) {
                                    let otherSecondary = formData.otherReason;
                                    if (otherSecondary === '') {
                                        setNotification({
                                            text: 'Must provide reason for issue: ' + sendParams.reason,
                                            level: 'error',
                                        });
                                        return false;
                                    }

                                    sendParams.secondary = otherSecondary;
                                }

                                // Double check and clear any unnecessary items
                                if (formData.fullRefund.toString() === 'true') {
                                    sendParams.items = [];
                                }

                                // Prioritize applying store credit
                                // Note: This does not affect the refund details on the modal since it is
                                // applies credit to a subscription
                                if (refundType === 'APPLY_CREDIT') {
                                    // Check if there are items
                                    if (sendParams.items.length > 0) {
                                        // If there is an amount in the form, use that amount
                                        if (creditAmountForm > 0 && creditAmountForm <= initialRemainingAmount) {
                                            sendParams.amount = Number(creditAmountForm.toFixed(2));
                                        } else {
                                            sendParams.amount = Number(itemsSelectedTotal.toFixed(2));
                                        }

                                        // No items, just store credit
                                    } else {
                                        // No items to send
                                        // Check that credit amount to be applied is > 0
                                        if (creditAmountForm <= 0) {
                                            setNotification({ text: "Can't apply $0 amount", level: 'error' });
                                            return false;
                                        }

                                        sendParams.amount = Number(creditAmountForm.toFixed(2));
                                    }

                                    sendParams.sourceType = 'AGENT_DISCRETION_CREDIT';

                                    res = await api.applyStoreCredit(rowInfo.charge.chargeID, sendParams);

                                    // Regular Refund
                                } else if (refundType === 'REFUND') {
                                    // Check if full refund
                                    if (formData.fullRefund.toString() === 'true') {
                                        res = await api.refundOrderLineItem(rowInfo.charge.chargeID, sendParams);
                                        // Check if refund by shipment boxes are checked
                                    } else if (formData.refundByShipments.length > 0) {
                                        delete sendParams.items;
                                        sendParams.shipments = formData.refundByShipments;
                                        res = await api.refundByShipment(rowInfo.charge.chargeID, sendParams);
                                    } else {
                                        sendParams.refundShipping = formData.refundShipping.toString();
                                        sendParams.refundColdKeeperFee = formData.refundColdKeeperFee.toString();

                                        // No items, check if shipping or cold keeper fee refund
                                        if (sendParams.items.length < 1) {
                                            if (sendParams.refundShipping === 'true') {
                                                if (sendParams.refundColdKeeperFee === 'true') {
                                                    setNotification({
                                                        text: 'Cold keeper cannot be refunded at the same time as shipping',
                                                        level: 'error',
                                                    });
                                                    return false;
                                                }

                                                res = await api.refundShipping(rowInfo.charge.chargeID, sendParams);
                                            } else if (sendParams.refundColdKeeperFee === 'true') {
                                                if (sendParams.refundShipping === 'true') {
                                                    setNotification({
                                                        text: 'Shipping cannot be refunded at the same time as cold keeper',
                                                        level: 'error',
                                                    });
                                                    return false;
                                                }

                                                res = await api.refundColdKeeper(rowInfo.charge.chargeID, sendParams);
                                            }

                                            // Line item refund with/without shipping
                                        } else if (sendParams.items.length > 0) {
                                            res = await api.refundLineItems(rowInfo.charge.chargeID, sendParams);
                                        }
                                    }
                                }

                                if (!res.status) {
                                    setNotification({ text: 'Unable to refund charge: ' + res.msg, level: 'error' });
                                    return false;
                                }

                                setNotification({ text: 'Success!', level: 'success' });
                                getSubscription();
                                getOrders();
                                getRefundableItems();
                                clearSummary();
                                setRefundType('');
                                resetForm();
                                setOpen(false);
                                return true;
                            }}
                        />
                    </div>
                    {refundHistoryData?.credits > 0 ||
                    refundHistoryData?.totalRefund !== '0.00' ||
                    shippingRefunded ||
                    coldKeeperFeeRefunded ||
                    creditsRefunded > 0 ? (
                        <h2>Refunded Items</h2>
                    ) : null}
                    {shipmentDetails.map((ship) => {
                        return (
                            <Grid item xs={11}>
                                {ship.items?.map((item) => {
                                    return (
                                        <>
                                            {item.refundHistory?.map((miniItem) => {
                                                return (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'baseline',
                                                            padding: '5px 0',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                background: '#F7F7F7',
                                                                borderRadius: '8px',
                                                                padding: '5px',
                                                            }}
                                                        >
                                                            Refunded
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        <div key={item.id}>
                                                            {!!item.brand && `${item.brand} | `} {item.name} $
                                                            {miniItem.total} - x{miniItem.qty}
                                                            &nbsp;(Tax: ${(miniItem.tax || 0).toFixed(2)})
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                            </Grid>
                        );
                    })}
                    {refundHistoryData?.shipping && refundHistoryData?.shipping > 0 ? (
                        <Grid item xs={11}>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <span style={{ background: '#F7F7F7', borderRadius: '8px', padding: '5px' }}>
                                    Refunded
                                </span>
                                &nbsp;&nbsp;
                                <div>
                                    Shipping - ${refundHistoryData.shipping.toFixed(2)}
                                    &nbsp;(Tax: ${(refundHistoryData.shippingTaxRefund || 0).toFixed(2)})
                                </div>
                            </div>
                        </Grid>
                    ) : null}
                    {refundHistoryData?.coldKeeperFeeRefund && refundHistoryData?.coldKeeperFeeRefund > 0 ? (
                        <Grid item xs={11}>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <span style={{ background: '#F7F7F7', borderRadius: '8px', padding: '5px' }}>
                                    Refunded
                                </span>
                                &nbsp;&nbsp;
                                <div>
                                    Cold Keeper Fee - ${refundHistoryData.coldKeeperFeeRefund.toFixed(2)}
                                    &nbsp;(Tax: ${(refundHistoryData.coldKeeperFeeTaxRefund || 0).toFixed(2)})
                                </div>
                            </div>
                        </Grid>
                    ) : null}
                    {creditsRefunded > 0 && (
                        <Grid item xs={11}>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <span style={{ background: '#F7F7F7', borderRadius: '8px', padding: '5px' }}>
                                    Refunded
                                </span>
                                &nbsp;&nbsp;
                                <div>Credits - ${creditsRefunded.toFixed(2)}</div>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <h2>Refund Order</h2>
                    <Grid item xs={12} style={{ padding: '0 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Order Number</strong>
                            <span>{order.shopOrderNum}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 0 10px', borderBottom: '2px solid lightgray' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Charge ID</strong>
                            <span>{order.charge.chargeID}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '10px 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Subtotal + Tax</strong>
                            <span>${(Number(order?.charge?.subtotal) + order?.charge?.taxes).toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Shipping</strong>
                            <span>
                                ${(order?.charge?.shipping).toFixed(2)}{' '}
                                {order?.loyaltyRewardInfo?.freeShipping == true ? '(Free loyalty shipping)' : ''}
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Cold Keeper Fee</strong>
                            <span>${(order?.charge?.coldKeeperFee).toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 0 10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>
                                Tip
                                <button
                                    onClick={async () => {
                                        if (window.confirm('Are you sure you want to refund the tip?')) {
                                            let res = await api.refundTip(order?.charge?.chargeID);
                                            if (!res.status) {
                                                setNotification({
                                                    text: 'Failed to refund tip: ' + res.msg,
                                                    level: 'error',
                                                });
                                                return;
                                            }

                                            setNotification({ text: 'Tip refunded', level: 'success' });
                                        }
                                    }}
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        padding: 0,
                                        margin: 0,
                                        color: 'inherit',
                                        fontSize: 'inherit',
                                    }}
                                >
                                    (Refund Tip)
                                </button>
                            </strong>
                            <span>${(order?.charge?.tipAmount).toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 0 10px', borderBottom: '2px solid lightgray' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Order Total</strong>
                            <span>${orderTotal.toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '10px 0 10px', borderBottom: '2px solid lightgray' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Credits Used</strong>
                            <span>${(order?.charge?.credits || 0).toFixed(2)}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '10px 0 10px', borderBottom: '2px solid lightgray' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <strong>Loyalty Points Used</strong>
                            <span>
                                {order?.loyaltyRewardInfo?.dollarDiscountPointsSpent +
                                    order?.loyaltyRewardInfo?.freeGiftPointsSpent +
                                    order?.loyaltyRewardInfo?.freeShippingPointsSpent || 0}
                            </span>
                        </div>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        style={{ background: '#F7F7F7', borderRadius: '8px', marginTop: '15px', padding: '20px' }}
                    >
                        <h4 style={{ margin: '0 0 15px' }}>Refund Summary</h4>
                        {itemsSelected !== null &&
                            itemsSelected.length > 0 &&
                            itemsSelected.map((item) => {
                                return (
                                    <p key={item.id}>
                                        {item.name} x{item.qty}
                                    </p>
                                );
                            })}
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
                                <strong>Refunded Shipping</strong>
                                <span>${(shippingRefund || 0).toFixed(2)}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
                                <strong>Refunded Cold Keeper Fee</strong>
                                <span>${(coldKeeperFeeRefund || 0).toFixed(2)}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
                                <strong>Refunded Items</strong>
                                {Number(itemsSelectedTotal) <= 0 && (
                                    <span>
                                        $
                                        {(refundHistoryData?.totalRefund > 0
                                            ? refundHistoryData?.totalRefund
                                            : 0
                                        ).toFixed(2)}
                                    </span>
                                )}
                                {Number(itemsSelectedTotal) > 0 && (
                                    <span>${Number(itemsSelectedTotal).toFixed(2)}</span>
                                )}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
                                <strong>Refunded Loyalty Points</strong>
                                <span>{order?.loyaltyRewardInfo?.pointsRefunded}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
                                <strong>Refunded Tip</strong>
                                <span>{tipRefunded}</span>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <h4>
                                <strong>Refund Type</strong>
                            </h4>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="ApplyStoreCredit_checkbox"
                                            onChange={async (event) => {
                                                setShippingRefund(0);
                                                setColdKeeperFeeRefund(0);

                                                if (event.target.checked === true) {
                                                    setRefundType('APPLY_CREDIT');
                                                } else {
                                                    setRefundType('');
                                                }
                                            }}
                                            disabled={
                                                fullRefundChecked ||
                                                (!shippingRefunded && shippingRefund > 0) ||
                                                (!coldKeeperFeeRefunded && coldKeeperFeeRefund > 0) ||
                                                shipmentBoxChecked.length > 0 ||
                                                (daysSinceDelivered >= 15 && !user.user.canRefundPastDate)
                                            }
                                            checked={refundType === 'APPLY_CREDIT'}
                                            color="primary"
                                        />
                                    }
                                    label={'Apply Store Credit'}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="Refund_checkbox"
                                            onChange={async (event) => {
                                                if (event.target.checked === true) {
                                                    setRefundType('REFUND');
                                                } else {
                                                    setRefundType('');
                                                }
                                            }}
                                            disabled={
                                                creditAmountForm > 0 ||
                                                (daysSinceDelivered > 7 && !user.user.canRefundPastDate)
                                            }
                                            checked={refundType === 'REFUND'}
                                            color="primary"
                                        />
                                    }
                                    label={'Refund'}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    {initialRemainingAmount === 0 && (
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '15px',
                                    paddingTop: '10px',
                                    borderTop: '2px solid lightgray',
                                }}
                            >
                                <strong>Remaining Amount</strong>
                                <span>$0.00</span>
                            </div>
                        </Grid>
                    )}

                    {(itemsSelected !== null &&
                        itemsSelected.length > 0 &&
                        itemsSelectedTotal > 0 &&
                        initialRemainingAmount !== 0 &&
                        shipmentBoxChecked.length < 1) ||
                    fullRefundChecked ||
                    shippingChecked ||
                    coldKeeperFeeChecked ? (
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '15px',
                                    paddingTop: '10px',
                                    borderTop: '2px solid lightgray',
                                }}
                            >
                                <strong>Remaining Amount</strong>
                                <span>
                                    $
                                    {(
                                        remainingAmount -
                                        (shippingRefunded ? 0 : shippingRefund) -
                                        (coldKeeperFeeRefunded ? 0 : coldKeeperFeeRefund) -
                                        (tipRefund - tipRefunded) -
                                        itemsSelectedTotal
                                    ).toFixed(2)}
                                </span>
                            </div>
                        </Grid>
                    ) : (
                        <>
                            {shipmentBoxChecked.length > 0 ? (
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginTop: '15px',
                                            paddingTop: '10px',
                                            borderTop: '2px solid lightgray',
                                        }}
                                    >
                                        <strong>Remaining Amount</strong>
                                        <span>${(initialRemainingAmount - itemsSelectedTotal).toFixed(2)}</span>
                                    </div>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginTop: '15px',
                                            paddingTop: '10px',
                                            borderTop: '2px solid lightgray',
                                        }}
                                    >
                                        <strong>Remaining Amount</strong>
                                        <span>${initialRemainingAmount.toFixed(2)}</span>
                                    </div>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    style={{ border: '1px solid lightgray' }}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { RefundModalLineItems };
