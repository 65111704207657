import React, { useState } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { Button, Grid, IconButton, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { customerApi as api } from '../../../api/customer';

const CarrierSearch = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [searchResults, setSearchResults] = useState([]);

    let carrierSearch = async ({ zipCode }) => {
        setSearchResults([]);
        let results = await api.searchCarriers(zipCode);

        if (!results.status || !results.data || !results.data.carriers) {
            setNotification({ text: 'Error searching carriers', level: 'error' });
            return;
        }

        if (results.data.carriers.length < 1) {
            setNotification({ text: 'No carriers found for the entered zip code', level: 'warning' });
            return;
        }

        if (results.status === true) {
            setSearchResults(
                results.data.carriers.map((row) => {
                    // row.carrier = row.carrier.toUpperCase()
                    // console.log(row);
                    return row;
                })
            );
        }
    };

    let formFields = [
        {
            name: 'zipCode',
            inputElement: 'textField',
            label: 'Zip Code',
            gridValue: 5,
            inputProps: {
                required: true,
                maxLength: 5,
                integer: true,
            },
        },
    ];

    let columns = [
        { accessor: 'delivery_day', Header: 'Delivery Day' },
        { accessor: 'carrier', Header: 'Carrier' },
    ];

    return (
        <Grid>
            <Notifications options={notification} />
            <FormComponent
                formFields={formFields}
                button={{ text: 'Search', gridValue: 1 }}
                onSubmit={async (formData) => {
                    carrierSearch(formData);
                }}
            />
            {searchResults.length > 0 && (
                <DataTable editable={false} columns={columns} data={searchResults} showPagination={false} />
            )}
        </Grid>
    );
};

const CarrierLookup = () => {
    return (
        <div>
            <h1>Carrier Lookup Page</h1>
            <CarrierSearch />
        </div>
    );
};

export { CarrierLookup };
