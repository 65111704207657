import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { catalogApi as api } from '../../../api/catalog';
import { warehouseApi } from '../../../api/warehouse';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField, Grid, Button, CircularProgress } from '@material-ui/core';
const filter = createFilterOptions();

let CreateItemMaster = ({ initialValues = {}, onSuccess, taxonomy, tempZones, facilities, items, getItemMasters }) => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [externalSku, setExternalSku] = useState('');
    let [externalSkuInputValue, setExternalSkuInputValue] = useState('');
    let [autocompleteKey, setAutocompleteKey] = useState(1);
    let [ingredients, setIngredients] = useState([]);
    let [disableIngredient, setDisableIngredient] = useState(true);
    let productionMethodOpts = [
        { text: 'Conventional', value: 'CONVENTIONAL' },
        { text: 'Organic', value: 'ORGANIC' },
    ];

    const [taxonomyL1, setTaxonomyL1] = useState('');
    const [taxonomyL2, setTaxonomyL2] = useState('');
    const [barcodeType, setBarcodeType] = useState('');
    const [recTempZone, setRecTempZone] = useState('');
    const [storTempZone, setStorTempZone] = useState('');
    const [shipTempZone, setShipTempZone] = useState('');
    const [dbiShippingTempZone, setDbiShippingTempZone] = useState('');
    const [custGuarDays, setCustGuarDays] = useState();
    const [sellOnPo, setSellOnPo] = useState('');
    const [baseUnitsPerCase, setBaseUnitsPerCase] = useState();
    const [baseUnitsUOM, setBaseUnitsUOM] = useState();
    const [multipackIndividualUOM, setMultipackIndividualUOM] = useState('');
    const [packagingUnitType, setPackagingUnitType] = useState('');
    const [purchaseCaseUOM, setPurchaseCaseUOM] = useState();
    const [ti, setTI] = useState();
    const [hi, setHI] = useState();
    const [csPallet, setCsPallet] = useState();
    const [purchCasePackSize, setPurchCasePackSize] = useState();
    const [isProduce, setIsProduce] = useState(taxonomyL1 == '1');
    const unitOfMeasureOptions = ['oz', 'ct', 'lb', 'floz', 'pt'];
    const packagingUnitTypeOptions = ['clamshell', 'pack', 'bunch', 'bag', 'head'];
    const multipackIndividualOptions = ['oz', 'floz'];

    const getTaxonomyOptions = (row, level) => {
        let parentID = level > 1 ? Number(row[`l${level - 1}TaxonomyID`]) : 0;
        let opts = taxonomy[`l${level}`]
            .filter((item) => item.parentID == parentID)
            .map((item) => ({ text: item.name, value: item.id }));
        if (level > 2) {
            opts.unshift({ text: '', value: null });
        }
        return opts;
    };

    let getAllIngredients = async () => {
        let res = await api.getPropSixtyFiveIngredients();
        if (res.status === true) {
            setIngredients(res.data.rows);
        }
    };

    useEffect(() => {
        getAllIngredients();
    }, []);

    useEffect(() => {
        // taxonomy L1 1 = 'Produce'
        // taxonomy L1 455 = 'Grocery'
        // taxonomy L2 567 = 'Chocolate and Candy'
        setBarcodeType(taxonomyL1 && taxonomyL1 !== '1' ? 'UPC' : '');
        setRecTempZone(taxonomyL1 && taxonomyL1 === '455' ? 'Ambient' : '');
        setStorTempZone(taxonomyL1 && taxonomyL1 === '455' ? 'Ambient (>55)' : '');
        setShipTempZone(() => {
            if (taxonomyL1 && taxonomyL2 && taxonomyL1 === '455' && taxonomyL2 !== '567') {
                return 'Ambient';
            } else if (taxonomyL1 && taxonomyL1 == '1') {
                return 'Refrigerated';
            } else {
                return '';
            }
        });
        setPurchaseCaseUOM(purchaseCaseUOM);
    }, [taxonomyL1, taxonomyL2]);

    useEffect(() => {
        if (taxonomyL1 && taxonomyL1 === '455') {
            setPurchCasePackSize(baseUnitsPerCase);
        }
    }, [baseUnitsPerCase]);

    useEffect(() => {
        if (Object.keys(initialValues).length > 0) {
            setBarcodeType(initialValues.barcodeType);
            setShipTempZone(initialValues.shippingTempZone || '');
            setStorTempZone(initialValues.storageTempZone || '');
            setRecTempZone(initialValues.receivingTempZone || '');
            setDbiShippingTempZone(initialValues.dbiShippingTempZone || '');
            setBaseUnitsPerCase(initialValues.baseUnitsPerCase);
            setPurchCasePackSize(initialValues.purchasingCasePackSize);
            setCustGuarDays(initialValues.customerGuaranteeDays);
            setSellOnPo(initialValues.sellOnPoQty === true ? 'Yes' : 'No');
            setTI(initialValues.ti);
            setHI(initialValues.hi);
            if (initialValues.ti && initialValues.hi) {
                setCsPallet(Number(initialValues.ti) * Number(initialValues.hi));
            } else {
                //Not sure if this is possible, but its a catch all and maintains the previous logic in the unlikely case that ti and hi are not set
                setCsPallet(initialValues.csPallet);
            }
            setTaxonomyL1(initialValues.l1TaxonomyID);

            // Ensures we update the brand field if the taxonomy is produce
            setIsProduce(initialValues.l1TaxonomyID == '1');
        }
    }, [initialValues]);

    useEffect(() => {
        setTaxonomyL1(externalSku?.value?.l1TaxonomyID);
        setBaseUnitsUOM(externalSku?.value?.baseUnitUOM);
    }, [externalSku]);

    let formFieldsRearranged = [
        {
            name: 'itemNameInternal',
            inputElement: 'textField',
            label: 'Internal Name',
            gridValue: 6,
            inputProps: { required: true },
        },
        {
            name: 'brand',
            inputElement: 'textField',
            label: 'Brand',
            gridValue: 6,

            // value is used if you want to disable the form field and not let the user change it
            value: !!externalSku ? externalSku.value.brand : undefined,
            inputProps: !!externalSku ? { disabled: true } : { required: !isProduce },

            // if no existing external sku, and an initial value was passed in, set the initial value
            initialValue: !externalSku && initialValues.hasOwnProperty('brand') ? initialValues.brand : undefined,
        },
        {
            name: 'l1TaxonomyID',
            inputElement: 'select',
            label: 'Item Category One',
            gridValue: 6,

            // if existing external sku
            value: !!externalSku ? externalSku.value.l1TaxonomyID : undefined,
            inputProps: !!externalSku
                ? {
                      opts: (formData) => taxonomy.l1.map((item) => ({ text: item.name, value: item.id })),
                      disabled: true,
                  }
                : {
                      required: true,
                      opts: (formData) => getTaxonomyOptions(formData, 1),
                      onChange: (e) => {
                          setTaxonomyL1(e.target.value);
                          setIsProduce(e.target.value == '1');
                      },
                  },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('l1TaxonomyID') ? initialValues.l1TaxonomyID : undefined,
        },
        {
            name: 'l2TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Two',
            gridValue: 6,
            dependencies: ['l1TaxonomyID'],

            value: !!externalSku ? externalSku.value.l2TaxonomyID : undefined,
            inputProps: !!externalSku
                ? {
                      opts: (formData) => taxonomy.l2.map((item) => ({ text: item.name, value: item.id })),
                      disabled: true,
                  }
                : {
                      required: true,
                      opts: (formData) => getTaxonomyOptions(formData, 2),
                      onChange: (e) => setTaxonomyL2(e.target.value),
                  },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('l2TaxonomyID') ? initialValues.l2TaxonomyID : undefined,
        },
        {
            name: 'l3TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Three',
            gridValue: 6,
            dependencies: ['l1TaxonomyID', 'l2TaxonomyID'],

            value: !!externalSku ? externalSku.value.l3TaxonomyID : undefined,
            inputProps: !!externalSku
                ? {
                      opts: (formData) => taxonomy.l3.map((item) => ({ text: item.name, value: item.id })),
                      disabled: true,
                  }
                : { required: true, opts: (formData) => getTaxonomyOptions(formData, 3) },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('l3TaxonomyID') ? initialValues.l3TaxonomyID : undefined,
        },
        {
            name: 'l4TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Four',
            gridValue: 6,
            dependencies: ['l1TaxonomyID', 'l2TaxonomyID', 'l3TaxonomyID'],

            value: !!externalSku ? externalSku.value.l4TaxonomyID : undefined,
            inputProps: !!externalSku
                ? {
                      opts: (formData) => {
                          let taxOpts = taxonomy.l4.map((item) => ({ text: item.name, value: item.id }));
                          taxOpts.unshift({ text: '', value: null });
                          return taxOpts;
                      },
                      disabled: true,
                  }
                : {
                      opts: (formData) => getTaxonomyOptions(formData, 4),
                      required: isProduce ? true : false,
                  },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('l4TaxonomyID') ? initialValues.l4TaxonomyID : undefined,
        },
        {
            inputElement: () => <h3 style={{ textAlign: 'left' }}>SKU Attributes</h3>,
        },
        {
            name: 'barcode',
            inputElement: 'textField',
            label: 'Barcode',
            gridValue: 4,
            inputProps: { type: 'number', maxLength: 14 },
        },
        {
            name: 'barcodeType',
            inputElement: 'select',
            label: 'Barcode Type',
            gridValue: 4,
            value: !!externalSku ? '' : barcodeType,
            initialValue: initialValues.barcodeType || '',
            dependencies: ['barcode'],
            inputProps: { opts: ['UPC', 'PLU', 'EAN'], onChange: (e) => setBarcodeType(e.target.value) },
        },
        {
            name: 'msrpPrice',
            inputElement: 'textField',
            label: 'Base Price',
            gridValue: 4,

            value: !!externalSku ? externalSku.value.msrpPrice : undefined,
            inputProps: !!externalSku ? { disabled: true } : { type: 'number', max: 300, required: true },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('msrpPrice') ? initialValues.msrpPrice : undefined,
        },
        {
            name: 'prop65Warning',
            inputElement: 'checkbox',
            label: 'Prop65 Warning',
            gridValue: 2,

            value: !!externalSku ? externalSku.value.prop65Warning : undefined,
            inputProps: !!externalSku ? { disabled: true } : undefined,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('prop65Warning') ? initialValues.prop65Warning : undefined,
        },
        {
            name: 'prop65WarningCategoryAlcohol',
            inputElement: 'checkbox',
            label: 'Prop65 Alcohol Flag',
            gridValue: 2,

            value: !!externalSku ? externalSku.value.prop65WarningCategoryAlcohol : undefined,
            inputProps: !!externalSku ? { disabled: true } : undefined,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('prop65WarningCategoryAlcohol')
                    ? initialValues.prop65WarningCategoryAlcohol
                    : undefined,
        },
        {
            name: 'prop65WarningCategoryCanned',
            inputElement: 'checkbox',
            label: 'Prop65 Canned Flag',
            gridValue: 2,

            value: !!externalSku ? externalSku.value.prop65WarningCategoryCanned : undefined,
            inputProps: !!externalSku ? { disabled: true } : undefined,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('prop65WarningCategoryCanned')
                    ? initialValues.prop65WarningCategoryCanned
                    : undefined,
        },
        {
            name: 'prop65WarningCategoryFood',
            inputElement: 'checkbox',
            label: 'Prop65 Food Flag',
            gridValue: 2,

            value: !!externalSku ? externalSku.value.prop65WarningCategoryFood : undefined,
            inputProps: !!externalSku
                ? { disabled: true }
                : { onChange: (e) => setDisableIngredient(!e.target.checked) },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('prop65WarningCategoryFood')
                    ? initialValues.prop65WarningCategoryFood
                    : undefined,
        },
        {
            name: 'prop65WarningCategoryNonFood',
            inputElement: 'checkbox',
            label: 'Prop65 Non-Food Flag',
            gridValue: 2,

            value: !!externalSku ? externalSku.value.prop65WarningCategoryNonFood : undefined,
            inputProps: !!externalSku ? { disabled: true } : undefined,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('prop65WarningCategoryNonFood')
                    ? initialValues.prop65WarningCategoryNonFood
                    : undefined,
        },
        {
            name: 'prop65Ingredient',
            inputElement: 'select',
            label: 'Prop65 Ingredient',
            gridValue: 2,

            value: !!externalSku ? externalSku.value.prop65Ingredient : undefined,
            inputProps: !!externalSku
                ? {
                      opts: (formData) => ingredients.map((item) => ({ text: item.name, value: item.id })),
                      disabled: true,
                  }
                : {
                      disabled: disableIngredient,
                      opts: (formData) => ingredients.map((item) => ({ text: item.name, value: item.id })),
                  },
        },
        {
            name: 'portionCountMischief',
            inputElement: 'textField',
            label: 'Portion Count',
            gridValue: 3,
            initialValue: initialValues.portionCountMischief || undefined,
            inputProps: { required: true, type: 'number' },
        },
        {
            name: 'replenishFacilities',
            inputElement: 'autocomplete',
            label: 'Replenishment',
            gridValue: 3,
            inputProps: { opts: facilities.map((row) => row['name']), multiple: true },
        },
        {
            name: 'sellOnPoQty',
            inputElement: 'select',
            label: 'Sell On Po Qty',
            gridValue: 3,

            value: !!externalSku && externalSku.value.sellOnPoQty ? 'Yes' : sellOnPo,
            inputProps: !!externalSku
                ? { disabled: true, opts: ['Yes', 'No'] }
                : { opts: ['Yes', 'No'], onChange: (e) => setSellOnPo(e.target.value) },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('sellOnPoQty') && initialValues.sellOnPoQty === true
                    ? 'Yes'
                    : 'No',
        },
        {
            name: 'productionMethod',
            inputElement: 'select',
            label: 'OG/CV',
            gridValue: 3,

            value: !!externalSku ? externalSku.value.productionMethod : undefined,
            inputProps: !!externalSku
                ? { disabled: true, opts: productionMethodOpts }
                : { opts: productionMethodOpts, required: isProduce ? true : false },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('productionMethod') ? initialValues.productionMethod : '',
        },
        {
            name: 'dbiOnly',
            label: 'Fulfillable by DBI Only',
            inputElement: 'select',
            gridValue: 2,

            value: !!externalSku && externalSku.value.dbiOnly ? 'Yes' : undefined,
            inputProps: !!externalSku ? { disabled: true, opts: ['Yes', 'No'] } : { opts: ['Yes', 'No'] },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('dbiOnly') && initialValues.dbiOnly === true
                    ? 'Yes'
                    : 'No',
        },
        {
            //white space?
            inputElement: () => <div></div>,
        },
        {
            inputElement: () => <h3 style={{ textAlign: 'left' }}>Unit/Case Specs</h3>,
        },
        {
            name: 'ti',
            inputElement: 'textField',
            label: 'TI',
            gridValue: 4,
            initialValue: initialValues.ti || undefined,
            inputProps: {
                type: 'number',
                required: true,
                onChange: (e) => {
                    setTI(Number(e.target.value));
                    if (hi) {
                        setCsPallet(Number(hi) * Number(e.target.value));
                    }
                },
            },
        },
        {
            name: 'hi',
            inputElement: 'textField',
            label: 'HI',
            gridValue: 4,
            initialValue: initialValues.hi || undefined,
            inputProps: {
                type: 'number',
                required: true,
                onChange: (e) => {
                    setHI(Number(e.target.value));
                    if (ti) {
                        setCsPallet(Number(ti) * Number(e.target.value));
                    }
                },
            },
        },
        {
            name: 'baseUnitSize',
            inputElement: 'textField',
            label: 'Sellable Unit Size',
            gridValue: 4,

            value: !!externalSku ? externalSku.value.baseUnitSize : undefined,
            inputProps: !!externalSku ? { disabled: true } : { required: true, type: 'number', step: '.01', min: 0 },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('baseUnitSize') ? initialValues.baseUnitSize : undefined,
        },
        {
            name: 'baseUnitUOM',
            inputElement: 'select',
            label: 'Sellable Unit UOM',
            gridValue: 4,

            value: !!externalSku ? externalSku.value.baseUnitUOM : undefined,
            inputProps: !!externalSku
                ? { disabled: true, opts: unitOfMeasureOptions, onChange: (e) => setBaseUnitsUOM(e.target.value) }
                : { required: true, opts: unitOfMeasureOptions, onChange: (e) => setBaseUnitsUOM(e.target.value) },
            initialValue: !externalSku && initialValues.hasOwnProperty('baseUnitUOM') ? initialValues.baseUnitUOM : '',
        },
        {
            name: 'multipackIndividualUnitSize',
            inputElement: 'textField',
            label: 'MP Individual Unit Size',
            gridValue: 4,

            value: !!externalSku ? externalSku.value.multipackIndividualUnitSize : undefined,
            inputProps: !!externalSku ? { disabled: true } : { required: false, type: 'number', step: '.01', min: 0 },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('multipackIndividualUnitSize')
                    ? initialValues.multipackIndividualUnitSize
                    : undefined,
        },
        {
            name: 'multipackIndividualUOM',
            inputElement: 'select',
            label: 'MP Individual Unit UOM',
            gridValue: 4,

            value: !!externalSku ? externalSku.value.multipackIndividualUOM : undefined,
            inputProps: !!externalSku
                ? { opts: multipackIndividualOptions, disabled: true }
                : {
                      required: false,
                      opts: [''].concat(multipackIndividualOptions),
                      onChange: (e) => setMultipackIndividualUOM(e.target.value),
                  },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('multipackIndividualUOM')
                    ? initialValues.multipackIndividualUOM
                    : undefined,
        },
        {
            name: 'packagingUnitSize',
            inputElement: 'textField',
            label: 'Packaging Unit Size',
            gridValue: 6,

            value: !!externalSku ? externalSku.value.packagingUnitSize : undefined,
            inputProps: !!externalSku ? { disabled: true } : { required: false, type: 'number', step: '.01', min: 0 },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('packagingUnitSize')
                    ? initialValues.packagingUnitSize
                    : undefined,
        },
        {
            name: 'packagingUnitType',
            inputElement: 'select',
            label: 'Packaging Unit Type',
            gridValue: 6,

            value: !!externalSku ? externalSku.value.packagingUnitType : undefined,
            inputProps: !!externalSku
                ? { opts: packagingUnitTypeOptions, disabled: true }
                : {
                      required: false,
                      opts: [''].concat(packagingUnitTypeOptions),
                      onChange: (e) => setPackagingUnitType(e.target.value),
                  },
            initialValue:
                !externalSku &&
                initialValues.hasOwnProperty('packagingUnitType') &&
                initialValues.packagingUnitType !== null
                    ? initialValues.packagingUnitType
                    : undefined,
        },
        {
            name: 'baseUnitsPerCase',
            inputElement: 'textField',
            label: 'Sellable Units Per Case',
            gridValue: 4,
            value: !!externalSku ? '' : baseUnitsPerCase,
            initialValue: initialValues.baseUnitsPerCase || undefined,
            inputProps: {
                required: true,
                type: 'number',
                onChange: (e) => {
                    setBaseUnitsPerCase(e.target.value);
                    if (!isProduce) {
                        setPurchCasePackSize(e.target.value);
                    }
                },
            },
        },
        {
            name: 'purchasingCasePackSize',
            inputElement: 'textField',
            label: 'Purchasing Case Pack Size',
            gridValue: 6,
            value: !!isProduce ? '' : baseUnitsPerCase,
            initialValue: initialValues.hasOwnProperty('purchasingCasePackSize')
                ? initialValues.purchasingCasePackSize
                : undefined,
            inputProps: !!isProduce
                ? { required: true, type: 'number', onChange: (e) => setPurchCasePackSize(e.target.value) }
                : { disabled: true },
        },
        {
            name: 'purchasingCaseUOM',
            inputElement: 'select',
            label: 'Purchasing Case UOM',
            gridValue: 6,
            value: purchaseCaseUOM ? purchaseCaseUOM : undefined,
            initialValue: initialValues.hasOwnProperty('purchasingCaseUOM')
                ? initialValues.purchasingCaseUOM
                : undefined,
            inputProps: {
                required: true,
                opts: unitOfMeasureOptions,
                onChange: (e) => setPurchaseCaseUOM(e.target.value),
            },
        },
        {
            name: 'cubeLevelPerBaseUnit',
            inputElement: 'textField',
            label: 'Cube Level Per Sellable Unit',
            gridValue: 4,

            value: !!externalSku ? externalSku.value.cubeLevelPerBaseUnit : undefined,
            inputProps: !!externalSku ? { disabled: true } : { required: true, type: 'number', max: 100 },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('cubeLevelPerBaseUnit')
                    ? initialValues.cubeLevelPerBaseUnit
                    : undefined,
        },
        {
            name: 'csPallet',
            inputElement: 'textField',
            label: 'Cases per pallet (cs/pallet)',
            gridValue: 4,
            value: ti && hi ? csPallet : undefined,
            initialValue: !externalSku && initialValues.hasOwnProperty('csPallet') ? initialValues.csPallet : undefined,
            inputProps: { disabled: true, required: true, type: 'number' },
        },
        {
            name: 'lbCs',
            inputElement: 'textField',
            label: 'Pounds per case (lbs/cs) *',
            gridValue: 4,
            initialValue: !externalSku && initialValues.hasOwnProperty('lbCs') ? initialValues.lbCs : undefined,
            inputProps: { required: true, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'lengthInCs',
            inputElement: 'textField',
            label: 'Case length (in)',
            gridValue: 4,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('lengthInCs') ? initialValues.lengthInCs : undefined,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'widthInCs',
            inputElement: 'textField',
            label: 'Case width (in)',
            gridValue: 4,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('widthInCs') ? initialValues.widthInCs : undefined,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'heightInCs',
            inputElement: 'textField',
            label: 'Case height (in)',
            gridValue: 4,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('heightInCs') ? initialValues.heightInCs : undefined,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'lengthInUnit',
            inputElement: 'textField',
            label: 'Unit length (in)',
            gridValue: 4,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('lengthInUnit') ? initialValues.lengthInUnit : undefined,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'widthInUnit',
            inputElement: 'textField',
            label: 'Unit width (in)',
            gridValue: 4,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('widthInUnit') ? initialValues.widthInUnit : undefined,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            name: 'heightInUnit',
            inputElement: 'textField',
            label: 'Unit height (in)',
            gridValue: 4,
            initialValue:
                !externalSku && initialValues.hasOwnProperty('heightInUnit') ? initialValues.heightInUnit : undefined,
            inputProps: { required: false, type: 'number', step: '.01', min: 0 },
        },
        {
            inputElement: () => <h3 style={{ textAlign: 'left' }}>Expiration &amp; Temp</h3>,
        },
        {
            name: 'vendorGuaranteedDaysUponDelivery',
            inputElement: 'textField',
            label: 'Vendor Guaranteed Days Upon Delivery',
            gridValue: 6,
            initialValue: initialValues.vendorGuaranteedDaysUponDelivery || undefined,
            inputProps: { required: true, type: 'number' },
        },
        {
            name: 'customerGuaranteeDays',
            inputElement: 'textField',
            label: 'Customer Guarantee Days',
            gridValue: 6,

            value: !!externalSku ? externalSku.value.customerGuaranteeDays : custGuarDays,
            inputProps: !!externalSku
                ? { disabled: true, type: 'number' }
                : { required: true, type: 'number', onChange: (e) => setCustGuarDays(e.target.value) },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('customerGuaranteeDays')
                    ? initialValues.customerGuaranteeDays
                    : undefined,
        },
        {
            name: 'receivingTempZone',
            inputElement: 'select',
            label: 'Receiving Temp Zone',
            gridValue: 3,

            value: !!externalSku ? externalSku.value.receivingTempZone : recTempZone,
            inputProps: !!externalSku
                ? { disabled: true, opts: tempZones.receiving }
                : { required: true, opts: tempZones.receiving, onChange: (e) => setRecTempZone(e.target.value) },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('receivingTempZone')
                    ? initialValues.receivingTempZone
                    : undefined,
        },
        {
            name: 'storageTempZone',
            inputElement: 'select',
            label: 'Storage Temp Zone',
            gridValue: 3,

            value: !!externalSku ? externalSku.value.storageTempZone : storTempZone,
            inputProps: !!externalSku
                ? { disabled: true, opts: tempZones.storage }
                : { required: true, opts: tempZones.storage, onChange: (e) => setStorTempZone(e.target.value) },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('storageTempZone')
                    ? initialValues.storageTempZone
                    : undefined,
        },
        {
            name: 'shippingTempZone',
            inputElement: 'select',
            label: 'Carrier Shipping Temp Zone',
            gridValue: 3,

            value: !!externalSku ? externalSku.value.shippingTempZone : shipTempZone,
            inputProps: !!externalSku
                ? { disabled: true, opts: tempZones.shipping }
                : { required: true, opts: tempZones.shipping, onChange: (e) => setShipTempZone(e.target.value) },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('shippingTempZone')
                    ? initialValues.shippingTempZone
                    : undefined,
        },
        {
            name: 'dbiShippingTempZone',
            inputElement: 'select',
            label: 'DBI Shipping Temp Zone',
            gridValue: 3,

            value: !!externalSku ? externalSku.value.dbiShippingTempZone : undefined,
            inputProps: !!externalSku
                ? { disabled: true, opts: tempZones.dbiShipping }
                : { required: true, opts: tempZones.dbiShipping },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('dbiShippingTempZone')
                    ? initialValues.dbiShippingTempZone
                    : undefined,
        },
        // CUBE LEVEL PER BASE UNIT 4 - THIS IS ON HERE TWICE
        {
            name: 'expirationType',
            inputElement: 'select',
            label: 'SKU Date Type',
            gridValue: 4,

            value: !!externalSku ? externalSku.value.expirationType : undefined,
            inputProps: !!externalSku
                ? {
                      disabled: true,
                      opts: ['NO_EXPIRATION_DATE', 'EXPIRATION_DATE', 'PRODUCTION_DATE', 'DAYS_AFTER_RECEIPT'],
                      disableUnderline: true,
                  }
                : {
                      required: true,
                      opts: ['NO_EXPIRATION_DATE', 'EXPIRATION_DATE', 'PRODUCTION_DATE', 'DAYS_AFTER_RECEIPT'],
                  },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('expirationType')
                    ? initialValues.expirationType
                    : undefined,
        },
        {
            name: 'expireLifeDays',
            inputElement: 'textField',
            label: 'Expire Life Days',
            gridValue: 4,

            value: !!externalSku ? externalSku.value.expireLifeDays : undefined,
            inputProps: !!externalSku ? { disabled: true, type: 'number' } : { required: false, type: 'number' },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('expireLifeDays')
                    ? initialValues.expireLifeDays
                    : undefined,
        },
        {
            name: 'daysAfterThaw',
            inputElement: 'textField',
            label: 'Days After Thaw',
            gridValue: 6,
            dependencies: ['storageTempZone'],
            display: (formData) => formData.storageTempZone === 'Frozen (0-31)',

            value: !!externalSku ? externalSku.value.daysAfterThaw : undefined,
            inputProps: !!externalSku ? { disabled: true, type: 'number' } : { type: 'number' },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('daysAfterThaw') ? initialValues.daysAfterThaw : undefined,
        },
        {
            name: 'daysAfterOpening',
            inputElement: 'textField',
            label: 'Days After Opening',
            gridValue: 6,
            dependencies: ['storageTempZone'],
            display: (formData) => formData.storageTempZone === 'Frozen (0-31)',

            value: !!externalSku ? externalSku.value.daysAfterOpening : undefined,
            inputProps: !!externalSku ? { disabled: true, type: 'number' } : { type: 'number' },
            initialValue:
                !externalSku && initialValues.hasOwnProperty('daysAfterOpening')
                    ? initialValues.daysAfterOpening
                    : undefined,
        },
    ];

    let createItemMaster = async (formData) => {
        let extSku;
        if (!externalSku) {
            extSku = externalSkuInputValue;
        } else {
            extSku = externalSku.value.externalSku;
        }

        let sendParams = {
            externalSku: extSku,
            itemNameInternal: formData.itemNameInternal,
            purchasingCasePackSize: purchCasePackSize,
            purchasingCaseUOM: formData.purchasingCaseUOM,
            baseUnitSize: formData.baseUnitSize,
            baseUnitUOM: formData.baseUnitUOM,
            packagingUnitSize: formData.packagingUnitSize,
            packagingUnitType: formData.packagingUnitType,
            multipackIndividualUnitSize: formData.multipackIndividualUnitSize,
            multipackIndividualUOM: formData.multipackIndividualUOM,
            baseUnitsPerCase: formData.baseUnitsPerCase,
            cubeLevelPerBaseUnit: formData.cubeLevelPerBaseUnit,
            allergen: formData.allergen === 'Yes' ? true : false,
            expireLifeDays: formData.expireLifeDays,
            customerGuaranteeDays: custGuarDays,
            daysAfterThaw: formData.storageTempZone === 'Frozen (0-31)' ? formData.daysAfterThaw : null,
            daysAfterOpening: formData.daysAfterOpening === 'Frozen (0-31)' ? formData.daysAfterOpening : null,
            receivingTempZone: formData.receivingTempZone,
            storageTempZone: formData.storageTempZone,
            shippingTempZone: formData.shippingTempZone,
            dbiShippingTempZone: formData.dbiShippingTempZone,
            csPallet: csPallet,
            lbCs: formData.lbCs,
            lengthInCs: formData.lengthInCs,
            widthInCs: formData.widthInCs,
            heightInCs: formData.heightInCs,
            ozUnit: formData.ozUnit,
            lengthInUnit: formData.lengthInUnit,
            widthInUnit: formData.widthInUnit,
            heightInUnit: formData.heightInUnit,
            l1TaxonomyID: formData.l1TaxonomyID,
            l2TaxonomyID: formData.l2TaxonomyID,
            l3TaxonomyID: formData.l3TaxonomyID,
            l4TaxonomyID: formData.l4TaxonomyID,
            portionCountMischief: formData.portionCountMischief,
            barcode: formData.barcode,
            barcodeType: barcodeType,
            expirationType: formData.expirationType,
            msrpPrice: formData.msrpPrice,
            prop65Warning: formData.prop65Warning,
            prop65WarningCategoryAlcohol: formData.prop65WarningCategoryAlcohol,
            prop65WarningCategoryCanned: formData.prop65WarningCategoryCanned,
            prop65WarningCategoryFood: formData.prop65WarningCategoryFood,
            prop65WarningCategoryNonFood: formData.prop65WarningCategoryNonFood,
            prop65Ingredient: formData.prop65Ingredient,
            brand: formData.brand,
            segment: formData.segment,
            productionMethod: formData.productionMethod,
            dbiOnly: formData.dbiOnly === 'Yes' ? true : false,
            replenishFacilities: facilities
                .map((item) => {
                    if (formData.replenishFacilities.map((row) => row['text']).includes(item['name'])) {
                        return item['id'];
                    }
                })
                .filter((item) => item !== undefined),
            sellOnPoQty: sellOnPo === 'Yes' ? true : false,
            hi: formData.hi,
            ti: formData.ti,
            vendorGuaranteedDaysUponDelivery: formData.vendorGuaranteedDaysUponDelivery,
        };

        let response = await api.createItemMaster(sendParams);
        if (response.status === true) {
            setNotification({ text: 'Item Master created!', level: 'success' });
        } else {
            let message = 'Error creating item master';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
        return response.status;
    };

    return (
        <div>
            <h1>Create Item Master</h1>
            <Notifications options={notification} />
            {items.length > 0 && (
                <div style={{ outline: 0, backgroundColor: 'white' }}>
                    <Grid container>
                        {
                            <>
                                <Grid item xs={11}>
                                    <h3 style={{ textAlign: 'left' }}>Naming/Taxonomy</h3>
                                    <Autocomplete
                                        id="ExternalSku_autocomplete"
                                        key={autocompleteKey}
                                        onBlur={() => {
                                            if (externalSku && externalSku.value) {
                                                return;
                                            }
                                            if (externalSkuInputValue) {
                                                // check if this is an existing sku that has not been selected from the options
                                                let item = items.find(
                                                    (item) => item.value.externalSku === Number(externalSkuInputValue)
                                                );
                                                if (item) {
                                                    setExternalSku(item);
                                                }
                                            }
                                        }}
                                        disabled={!!externalSku && !!externalSku.value}
                                        value={externalSku}
                                        onChange={(event, newValue) => {
                                            if (newValue && newValue.inputValue) {
                                                setExternalSku({
                                                    text: newValue.inputValue,
                                                });

                                                return;
                                            }

                                            setExternalSku(newValue);
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            setExternalSkuInputValue(params.inputValue);

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        options={items}
                                        freeSolo
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.text;
                                        }}
                                        renderOption={(option) => option.text}
                                        renderInput={(params) => <TextField {...params} label="External Sku" />}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {!!externalSku && !!externalSku.value && (
                                        <Button
                                            onClick={() => {
                                                setExternalSku('');
                                            }}
                                        >
                                            CLEAR
                                        </Button>
                                    )}
                                </Grid>
                            </>
                        }
                    </Grid>
                    <FormComponent
                        formFields={formFieldsRearranged}
                        onSubmit={async (formData, resetForm) => {
                            let res = await createItemMaster(formData);
                            if (res === true) {
                                setAutocompleteKey(autocompleteKey + 1);
                                resetForm();
                                setExternalSku('');
                                setExternalSkuInputValue('');
                                setBarcodeType('');
                                setRecTempZone('');
                                setStorTempZone('');
                                setShipTempZone('');
                                setCustGuarDays();
                                setSellOnPo('');
                                setBaseUnitsPerCase();
                                setPurchCasePackSize();
                                setCsPallet();
                                if (onSuccess && onSuccess instanceof Function) {
                                    onSuccess();
                                } else {
                                    getItemMasters();
                                }
                            }
                        }}
                    />
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>
            )}
        </div>
    );
};

export { CreateItemMaster };
