import React, { useState, useContext, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { fbmApi as api } from '../../../api/fbm';
import { procurementApi as procurementApi } from '../../../api/procurement';
import { Notifications, DataTable } from '../../../shared';
import { UserContext } from '../../../contexts/UserContext';

const FBMManageAccounts = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [shipperIdOptions, setShipperIdOptions] = useState([]);

    const { user, roles } = useContext(UserContext);

    const isFBMUser = user.fbm || !user.id;

    let canEditFBMAccounts = roles?.filter((role) => role.name.endsWith('EDIT_FBM_ACCOUNTS')).length >= 1;

    let getShipperIdOptions = async () => {
        let response = await procurementApi.getShippers();
        if (!response.status) {
            setNotification({ text: 'Failed to get shippers', level: 'error' });
            return;
        }

        setShipperIdOptions(
            response.data.rows.map((row) => {
                return { text: row.name, value: row.id };
            })
        );
    };

    let columns = [
        { accessor: 'companyName', Header: 'Company Name' },
        { accessor: 'fbmAccountName', Header: 'Account Name' },
        {
            accessor: 'inboundShipperIDs',
            Header: 'Inbound Shipper IDs',
            editable: true,
            editProps: {
                type: 'autocomplete-multiple',
                options: shipperIdOptions,
                multiple: true,
            },
        },
    ];

    let getRows = async () => {
        let response = await api.getFBMAccounts();

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.accounts);
    };

    useEffect(() => {
        if (!isFBMUser) {
            getShipperIdOptions();
            getRows();
        }
    }, [user]);

    if (isFBMUser) {
        return (
            <Paper>
                <h2>FBM Partner Accounts Management</h2>
                <p>Sorry this page is not available</p>
            </Paper>
        );
    }

    return (
        <Paper>
            <h2>FBM Partner Accounts Management</h2>

            <Notifications options={notification} />

            <DataTable
                columns={columns}
                data={rows}
                editable={canEditFBMAccounts}
                saveEdit={async ({ id, field, value, row }) => {
                    let res = await api.updateFBMAccountConfig(id, { [field]: value });
                    if (!res.status) {
                        setNotification({ text: res.msg, level: 'error' });
                        return false;
                    }

                    return true;
                }}
            />
        </Paper>
    );
};

export { FBMManageAccounts };
