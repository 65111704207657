import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { Button, Grid, InputLabel } from '@material-ui/core';
import { DataTable, Notifications, FormComponent } from '../../../../shared';
import { fbmApi as api } from '../../../../api/fbm';

const ViewOrderDeepDive = () => {
    const [deepDiveResults, setDeepDiveResults] = useState({});
    const [notification, setNotification] = useState({ text: '', level: '' });
    const { fbmAccount } = useContext(UserContext);

    // Load the page
    const loadData = async (id) => {
        const response = await api.getOrderDetails(id, fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'Error retrieving order ' + response.msg, level: 'error' });
            return;
        }

        setDeepDiveResults(response.data.data);

        setNotification({ text: 'Success', level: 'success' });
    };

    let formFields = [
        {
            name: 'lsOrderNum',
            label: `${fbmAccount.companyName} Order Number`,
            inputElement: 'textField',
            gridValue: 8,
        },
    ];

    return (
        <div>
            <h1>{fbmAccount.companyName} - Order Deep Dive</h1>
            <Notifications options={notification} />
            {!!fbmAccount.id && (
                <div>
                    <Grid container>
                        {Object.keys(deepDiveResults).length > 0 && (
                            <Grid item xs={4}>
                                <Button
                                    id={`MarkShippedButton`}
                                    onClick={async () => {
                                        let response = await api.markOrderShipped(
                                            deepDiveResults.shipmentID,
                                            fbmAccount.fbmAccountName
                                        );
                                        if (response.status === false) {
                                            setNotification({
                                                text: 'Error marking shipped ' + response.msg,
                                                level: 'error',
                                            });
                                            return;
                                        }

                                        setNotification({ text: 'Success', level: 'success' });
                                    }}
                                >
                                    Mark Order Shipped
                                </Button>
                            </Grid>
                        )}
                        {Object.keys(deepDiveResults).length > 0 && (
                            <Grid item xs={4}>
                                <Button
                                    id={`IngestButton`}
                                    onClick={async () => {
                                        let response = await api.ingestSingleOrder(
                                            deepDiveResults.orderNum,
                                            fbmAccount.fbmAccountName
                                        );
                                        if (response.status === false) {
                                            setNotification({
                                                text: 'Unable to ingest ' + response.msg,
                                                level: 'error',
                                            });
                                            return;
                                        }

                                        setNotification({ text: 'Success', level: 'success' });
                                    }}
                                >
                                    Ingest
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <br />
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData) => {
                            loadData(formData.lsOrderNum);
                        }}
                    />
                    <br />
                    <br />
                    {Object.keys(deepDiveResults).length > 0 && (
                        <DeepDiveResults companyName={fbmAccount.companyName} deepDiveResults={deepDiveResults} />
                    )}
                </div>
            )}
        </div>
    );
};

const DeepDiveResults = ({ deepDiveResults }) => {
    const [thirdPartyInfoColumns, setThirdPartyInfoColumns] = useState([]);
    const { fbmAccount } = useContext(UserContext);
    const [shipToCompare, setShipToCompare] = useState({});

    let ingestColumn = [
        { accessor: 'insertedAt', Header: 'Recorded At' },
        { accessor: 'type', Header: 'Type' },
        { accessor: 'details', Header: 'Details' },
    ];

    let eventColumn = [
        { accessor: 'timestamp', Header: 'Timestamp' },
        { accessor: 'type', Header: 'Event' },
        { accessor: 'details', Header: 'Details' },
    ];

    let itemMMCol = [
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'thirdPartyItemID', Header: `${fbmAccount.companyName} SKU` },
        { accessor: 'qty', Header: 'Amount' },
    ];

    let thirdPartyItemsColumns = [
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'thirdPartyItemID', Header: `${fbmAccount.companyName} SKU` },
        { accessor: 'qty', Header: 'Amount' },
    ];

    useEffect(() => {
        if (fbmAccount.fbmAccountName === 'littlespoon') {
            setThirdPartyInfoColumns([
                { accessor: 'createdOn', Header: 'Ordered At' },
                { accessor: 'ssOrderID', Header: 'ShipStation Order ID' },
                { accessor: 'status', Header: 'Status' },
                { accessor: 'warehouse', Header: 'Warehouse' },
                { accessor: 'isFulfilledByMisfits', Header: 'In Misfits System?' },
            ]);
        }
    }, [fbmAccount]);

    useEffect(() => {
        const shipToInternal = deepDiveResults.shipToInternal;
        const shipToExternal = deepDiveResults.shipToExternal;
        setShipToCompare({
            lineOne: shipToInternal.lineOne?.toLowerCase() === shipToExternal.lineOne?.toLowerCase(),
            lineTwo: shipToInternal.lineTwo?.toLowerCase() === shipToExternal.lineTwo?.toLowerCase(),
            city: shipToInternal.city?.toLowerCase() === shipToExternal.city?.toLowerCase(),
            province: shipToInternal.province?.toLowerCase() === shipToExternal.province?.toLowerCase(),
            zip: shipToInternal.zip === shipToExternal.zip,
        });
    }, [deepDiveResults]);

    const addressTextColor = (flag) => {
        return flag ? 'black' : 'red';
    };

    return (
        <>
            <h2>Summary Information</h2>
            <Grid container>
                <Grid item xs={4}>
                    <InputLabel>{fbmAccount.companyName} Order Number</InputLabel>
                    {deepDiveResults.orderNum}
                </Grid>
                <Grid item xs={4}>
                    <InputLabel>Misfits Order Number</InputLabel>
                    {deepDiveResults.mmOrderNum}
                </Grid>
                <Grid item xs={4}>
                    <InputLabel>Misfits Shipment ID</InputLabel>
                    {deepDiveResults.shipmentID}
                </Grid>
            </Grid>
            <br />
            <Grid container>
                <Grid item xs={3}>
                    <InputLabel>Shipment Status</InputLabel>
                    {deepDiveResults.shipStatus}
                </Grid>
                <Grid item xs={3}>
                    <InputLabel>Tracking URL</InputLabel>
                    {deepDiveResults.trackingUrl}
                </Grid>
                <Grid item xs={3}>
                    <InputLabel>Carrier</InputLabel>
                    {deepDiveResults.carrier}
                </Grid>
                <Grid item xs={3}>
                    <InputLabel>Hub</InputLabel>
                    {deepDiveResults.hub}
                </Grid>
            </Grid>
            <br />
            <h2>Misfits Address Information</h2>
            <Grid container>
                <Grid item xs={true}>
                    <InputLabel>Line One</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.lineOne) }}>
                        {deepDiveResults.shipToInternal.lineOne}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>Line Two</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.lineTwo) }}>
                        {deepDiveResults.shipToInternal.lineTwo}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>City</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.city) }}>
                        {deepDiveResults.shipToInternal.city}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>State</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.province) }}>
                        {deepDiveResults.shipToInternal.province}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>Zip</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.zip) }}>
                        {deepDiveResults.shipToInternal.zip}
                    </span>
                </Grid>
            </Grid>
            <br />
            <h2>{fbmAccount.companyName} Address Information</h2>
            <Grid container>
                <Grid item xs={true}>
                    <InputLabel>Line One</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.lineOne) }}>
                        {deepDiveResults.shipToExternal.lineOne}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>Line Two</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.lineTwo) }}>
                        {deepDiveResults.shipToExternal.lineTwo}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>City</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.city) }}>
                        {deepDiveResults.shipToExternal.city}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>State</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.province) }}>
                        {deepDiveResults.shipToExternal.province}
                    </span>
                </Grid>
                <Grid item xs={true}>
                    <InputLabel>Zip</InputLabel>
                    <span style={{ color: addressTextColor(shipToCompare.zip) }}>
                        {deepDiveResults.shipToExternal.zip}
                    </span>
                </Grid>
            </Grid>
            <br />
            <h2>{deepDiveResults.thirdPartyName} Records</h2>
            <DataTable columns={thirdPartyInfoColumns} data={deepDiveResults.thirdPartyInfo} />
            <br />
            <h2>Ingest Log</h2>
            <DataTable columns={ingestColumn} data={deepDiveResults.digestEvents} />
            <br />
            <Grid container>
                <Grid item xs={6}>
                    <h2>Items ({deepDiveResults.thirdPartyName})</h2>
                    <DataTable columns={thirdPartyItemsColumns} data={deepDiveResults.thirdPartyItems} />
                </Grid>
                <Grid item xs={6}>
                    <h2>Items (Misfits)</h2>
                    <DataTable columns={itemMMCol} data={deepDiveResults.mmItems} />
                </Grid>
            </Grid>
            <h2>Event Log</h2>
            <DataTable columns={eventColumn} data={deepDiveResults.shipEvents} />
        </>
    );
};

export { ViewOrderDeepDive };
