import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, FacilityPicker, FBMAccountPicker } from '../../../shared';
import { fbmApi as api } from '../../../api/fbm';
import { UserContext } from '../../../contexts/UserContext';
import { Grid } from '@material-ui/core';

const InventoryDetails = ({ parentRow }) => {
    let cols = [
        { Header: 'Lot Number', accessor: 'lotID' },
        { Header: 'Partner Lot', accessor: 'partnerLotNumber' },
        { Header: 'Received On', accessor: 'receivedAt' },
        { Header: 'Expires On', accessor: 'expiresOn' },
        { Header: 'Num Units', accessor: 'numUnits' },
    ];
    return <DataTable columns={cols} data={parentRow.original.lotDetails} />;
};

const FBMInventory = () => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [facilityID, setFacilityID] = useState(null);
    const { user, fbmAccount } = useContext(UserContext);

    // Load rows
    const getRows = async () => {
        const response = await api.getInventory(facilityID, fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'Failed to get inventory ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(
            response.data.rows.map((row) => {
                return { ...row, risk: handleAtRisk(row.numUnits, row.inventoryRiskThreshold) };
            })
        );
    };

    useEffect(() => {
        if (!!user.id && !user.fbm) {
            setFacilityID(user.facilityID);
        }
    }, [user]);

    useEffect(() => {
        if (!!facilityID && !!fbmAccount.id) {
            getRows();
        }
    }, [facilityID, fbmAccount]);

    const handleAtRisk = (numUnits, inventoryRiskThreshold) => {
        if (!inventoryRiskThreshold) {
            return '';
        }

        if (numUnits <= inventoryRiskThreshold) {
            return 'AT RISK';
        }

        return '';
    };

    const exportData = (rows) => {
        let csvContent = 'Item ID,Name,Lot Number,Partner Lot,Received On,Expires On,Num Units,Risk Threshold,Risk';
        csvContent += '\r\n';

        for (let row of rows) {
            for (let lotDetailsRow of row.original.lotDetails) {
                const partnerLotNumber = lotDetailsRow.partnerLotNumber ?? '';

                csvContent += `"${row.original.id}","${row.original.name}","${
                    lotDetailsRow.lotID
                }","${partnerLotNumber}","${lotDetailsRow.receivedAt}","${lotDetailsRow.expiresOn}","${
                    lotDetailsRow.numUnits
                }","${row.original.inventoryRiskThreshold ?? ''}", ${handleAtRisk(
                    row.original.numUnits,
                    row.original.inventoryRiskThreshold
                )}`;
                csvContent += '\r\n';
            }
        }

        let filename = `inventory.csv`;
        var file = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (window.navigator.msSaveOrOpenBlob)
            // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else {
            // Others
            var a = document.createElement('a'),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    };

    let cols = [
        { accessor: 'id', Header: 'Item ID' },
        { accessor: 'type', Header: 'Type' },
        { accessor: 'name', Header: 'Name' },
        { accessor: 'numUnits', Header: 'Units' },
        {
            accessor: 'inventoryRiskThreshold',
            Header: 'Risk Threshold',
            editable: true,
            editProps: { type: 'input', inputType: 'number', min: 0, integer: true },
        },
        { accessor: 'risk', Header: 'Risk' },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item xs={12}>
                <FBMAccountPicker />
            </Grid>
            {!!fbmAccount.id && (
                <>
                    <Grid item md={6} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                        <h1>{fbmAccount.companyName} Inventory</h1>
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', paddingRight: '35px' }}>
                        {user.fbm && (
                            <FacilityPicker
                                defaultValue={fbmAccount.facilityIDs[0]}
                                whitelistedFacilities={fbmAccount.facilityIDs}
                                activeOnly={true}
                                onChange={(facilityID, text, facility) => {
                                    setFacilityID(facilityID);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            editable={true}
                            expandable={true}
                            ExpansionComponent={<InventoryDetails />}
                            csvExport={true}
                            columns={cols}
                            data={rows}
                            customExport={exportData}
                            saveEdit={async ({ field, value, row }) => {
                                const res = await api.updateItemInventoryRiskThreshold(
                                    fbmAccount.fbmAccountName,
                                    row.id,
                                    value,
                                    facilityID,
                                    row.type
                                );
                                if (res.status === false) {
                                    setNotification({ text: 'Error updating Inventory', level: 'error' });
                                } else {
                                    setNotification({ text: 'Inventory Updated!', level: 'success' });
                                    await getRows();
                                }
                                return res.status;
                            }}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export { FBMInventory };
