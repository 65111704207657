import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { Paper, Tabs, Tab, Button, Dialog, DialogContent } from '@material-ui/core';
import { Notifications, DataTable, TabPanel, SearchBar } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';
import { ActionModal } from './palletAndCaseActions';
import { ViewCases } from './viewCases';

const ViewCaseInfo = ({ cases, facilities, locations, setNotification, reloadData }) => {
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    let caseRows = cases.map((item) => {
        if (!!item.lastMovementTimestamp && !!item.lastMovementTimestamp.date) {
            item.lastMovementTimestamp = moment(item.lastMovementTimestamp.date + item.lastMovementTimestamp.timezone)
                .local()
                .format('MM/DD/YYYY h:mm a');
        }
        return item;
    });

    let columns = [
        { accessor: 'caseName', Header: 'Case Name' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'caseType', Header: 'Case Type' },
        { accessor: 'sku', Header: 'External Sku' },
        { accessor: 'itemName', Header: 'Item' },
        { accessor: 'numUnits', Header: 'Units' },
        { accessor: 'receiveDate', Header: 'Received Date' },
        { accessor: 'expiresOn', Header: 'Expiration Date' },
        { accessor: 'lastSellableDate', Header: 'Last Sellable' },
        { accessor: 'lotID', Header: 'Lot' },
        { accessor: 'partnerLotNumber', Header: 'Partner Lot' },
        { accessor: 'currentPalletName', Header: 'Current Pallet' },
        { accessor: 'purchaseOrderID', Header: 'PO' },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                setSelectedRow(row.original);
                                setActionModalOpen(true);
                            }}
                        >
                            Actions
                        </Button>
                    </div>
                );
            },
        },
        { accessor: 'lastMovementTimestamp', Header: 'Last Moved' },
        { accessor: 'lastMovementAuthUser', Header: 'Last Moved By' },
    ];

    return (
        <>
            <ActionModal
                type="case"
                reloadData={reloadData}
                open={actionModalOpen}
                row={selectedRow}
                facilities={facilities}
                setNotification={setNotification}
                locations={locations}
                closeModal={() => {
                    setActionModalOpen(false);
                }}
            />
            <DataTable columns={columns} data={caseRows} />
        </>
    );
};

let ViewCasesModal = ({ rowData, onClose }) => {
    return (
        <div>
            <Dialog
                open={true}
                onClose={onClose}
                maxWidth="lg"
                fullWidth={true}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogContent>
                    <ViewCases palletID={rowData.palletID} lotID={rowData.lotID} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

const ViewPalletInfo = ({ pallets, facilities, setNotification, reloadData, il2V2, locations }) => {
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [viewCasesModalOpen, setViewCasesModalOpen] = useState(false);

    let palletRows = pallets.map((item) => {
        if (!!item.lastMovementTimestamp && !!item.lastMovementTimestamp.date) {
            item.lastMovementTimestamp = moment(item.lastMovementTimestamp.date + item.lastMovementTimestamp.timezone)
                .local()
                .format('MM/DD/YYYY h:mm a');
        }
        return item;
    });

    let columns = [
        { accessor: 'palletName', Header: 'Pallet Name' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'sku', Header: 'External Sku' },
        { accessor: 'itemName', Header: 'Name' },
        { accessor: 'numFullCases', Header: '# Full Cases' },
        { accessor: 'numPartialCases', Header: '# Partial Cases' },
        { accessor: 'numUnits', Header: 'Units' },
        { accessor: 'receiveDate', Header: 'Received Date' },
        { accessor: 'expiresOn', Header: 'Expiration Date' },
        { accessor: 'lastSellableDate', Header: 'Last Sellable' },
        { accessor: 'lotID', Header: 'Lot' },
        { accessor: 'partnerLotNumber', Header: 'Partner Lot' },
        { accessor: 'purchaseOrderID', Header: 'PO' },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                setSelectedRow(row.original);
                                setActionModalOpen(true);
                            }}
                        >
                            Actions
                        </Button>
                    </div>
                );
            },
        },
        { accessor: 'lastMovementTimestamp', Header: 'Last Moved' },
        { accessor: 'lastMovementAuthUser', Header: 'Last Moved By' },
    ];

    if (pallets.length && pallets[0].itemType !== 'Packaging') {
        columns.push({
            accessor: 'viewCases',
            Header: 'View Cases',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={() => {
                            setSelectedRow(row.original);
                            setViewCasesModalOpen(true);
                        }}
                    >
                        View Cases
                    </Button>
                );
            },
        });
    }

    return (
        <>
            <ActionModal
                type="pallet"
                open={actionModalOpen}
                row={selectedRow}
                facilities={facilities}
                setNotification={setNotification}
                reloadData={reloadData}
                locations={locations}
                closeModal={() => {
                    setActionModalOpen(false);
                }}
                il2V2={il2V2}
            />
            {viewCasesModalOpen && (
                <ViewCasesModal
                    rowData={selectedRow}
                    onClose={() => {
                        setViewCasesModalOpen(false);
                    }}
                />
            )}
            <DataTable columns={columns} data={palletRows} />
        </>
    );
};

const ViewPalletsAndCases = ({ parentRow, setNotification, searchObj, facilities, il2V2, locations }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    const user = useContext(UserContext);
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    const [pallets, setPallets] = useState([]);
    const [cases, setCases] = useState([]);

    const getPallets = async () => {
        setPallets([]);

        let obj = searchObj;
        obj.groupBy = 'pallets';
        obj.locationID = parentRow.original.locationID;
        obj.sku = parentRow.original.sku;

        let results = await api.searchInventory(obj);
        if (!results.status || !results.data || !results.data.results) {
            setNotification({ text: 'Failed to find pallets in location', level: 'error' });
            return;
        }
        setPallets(results.data.results);
    };

    const getCases = async () => {
        setCases([]);
        let obj = searchObj;
        obj.groupBy = 'cases';
        obj.locationID = parentRow.original.locationID;
        obj.sku = parentRow.original.sku;

        let results = await api.searchInventory(obj);
        if (!results.status || !results.data || !results.data.results) {
            setNotification({ text: 'Failed to find cases in location', level: 'error' });
            return;
        }
        setCases(results.data.results);
    };

    const reloadData = () => {
        getPallets();
        getCases();
    };

    useEffect(() => {
        getPallets();
        getCases();
    }, []);

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Pallets" value="0" />
                {parentRow.original.itemType !== 'Packaging' && <Tab label="Cases" value="1" />}
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewPalletInfo
                    pallets={pallets}
                    facilities={facilities}
                    setNotification={setNotification}
                    reloadData={reloadData}
                    il2V2={il2V2}
                    locations={locations}
                />
            </TabPanel>
            {parentRow.original.itemType !== 'Packaging' && (
                <TabPanel selectedTab={selectedTab} index={'1'}>
                    <ViewCaseInfo
                        cases={cases}
                        locations={locations}
                        facilities={facilities}
                        setNotification={setNotification}
                        reloadData={reloadData}
                    />
                </TabPanel>
            )}
        </Paper>
    );
};

const LocationInventory = () => {
    let [notification, setNotification] = useState({ text: '', level: '' });
    let [searchResults, setSearchResults] = useState([]);
    let [searching, setSearching] = useState(false);
    let [facilities, setFacilities] = useState([]);
    let [locations, setLocations] = useState([]);

    let [il2V2, setIl2V2] = useState(false);

    const [searchObj, setSearchObj] = useState({});

    const user = useContext(UserContext);

    useEffect(() => {
        getFacilities();
        getLocations();
        searchInventory({});
    }, []);

    let getLocations = async () => {
        let response = await api.getActiveLocations(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No facilities found ' + response.msg, level: 'error' });
            setLocations([]);
            return;
        }

        let locationsAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setLocations([pleaseSelect].concat(locationsAsOpts));
    };

    let getFacilities = async () => {
        let response = await api.getFacilities();
        if (response.status === false) {
            setNotification({ text: 'No facilities found ' + response.msg, level: 'error' });
            setFacilities([]);
            return;
        }

        setFacilities(
            response.data.rows
                .filter((item) => item.active)
                .map((row) => {
                    if (row.id === user.getFacilityID()) {
                        setIl2V2(row.il2V2);
                    }

                    row.text = row.name;
                    row.value = row.id;
                    return row;
                })
        );
    };

    let searchInventory = async ({ searchBy, searchTerm }) => {
        setSearching(true);
        setSearchResults([]);

        let searchObj = {
            facilityID: user.getFacilityID(),
        };

        if (!!searchBy && !!searchTerm) {
            searchObj[searchBy] = searchTerm;
        }

        setSearchObj(searchObj);
        let results = await api.searchInventory(searchObj);
        setSearching(false);
        if (!results.status || !results.data || !results.data.results) {
            setNotification({ text: 'Error searching inventory. ' + results.msg, level: 'error' });
            return;
        }
        setSearchResults(results.data.results);
    };

    let columns = [
        { accessor: 'locationName', Header: 'Location' },
        { accessor: 'itemType', Header: 'Type' },
        { accessor: 'sku', Header: 'External Sku' },
        { accessor: 'fbmPartnerSKU', Header: 'Partner Sku' },
        { accessor: 'companySource', Header: 'Partner Name' },
        { accessor: 'itemName', Header: 'Item Name' },
        { accessor: 'numPallets', Header: 'Pallets' },
        { accessor: 'numCases', Header: 'Total Cases' },
        { accessor: 'numFullCases', Header: 'Full Cases' },
        { accessor: 'numPartialCases', Header: 'Partial Cases' },
        { accessor: 'numUnits', Header: 'Units' },
        { accessor: 'earliestExpiresOn', Header: 'Earliest Expiration Date' },
    ];

    let inventorySearchByOptions = [
        { value: 'palletName', text: 'Pallet' },
        { value: 'caseName', text: 'Case' },
        { value: 'lotID', text: 'Lot' },
        { value: 'sku', text: 'External Sku' },
        { value: 'fbmPartnerSKU', text: 'Partner Sku' },
        { value: 'poID', text: 'Purchase Order' },
        { value: 'locationName', text: 'Location Name' },
    ];

    return (
        <Paper style={{ minHeight: '80vh' }}>
            <Notifications options={notification} />
            <h1>Location Inventory</h1>
            <SearchBar onSubmit={searchInventory} searchByOptions={inventorySearchByOptions} />
            {searchResults.length === 0 && searching && <div>Searching</div>}
            {!searching && (
                <div>
                    <DataTable
                        columns={columns}
                        data={searchResults}
                        editable={false}
                        expandable={true}
                        ExpansionComponent={
                            <ViewPalletsAndCases
                                il2V2={il2V2}
                                setNotification={setNotification}
                                searchObj={searchObj}
                                facilities={facilities}
                                locations={locations}
                            />
                        }
                    />
                </div>
            )}
        </Paper>
    );
};

export { LocationInventory };
