import React, { useEffect, useState, useContext } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { TabPanel, DataTable, Notifications, FormComponent } from '../../../shared';
import { UserContext } from '../../../contexts/UserContext';
import { logisticsApi as api } from '../../../api/logistics';


const ViewInboundCrossDocks = ({ canEdit }) => {
    const [crossDocks, setCrossDocks] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });

    const getCrossDocks = async () => {
        const response = await api.getInboundCrossDocks(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get cross docks', level: 'error' });
            setCrossDocks([]);
            return;
        }

        setCrossDocks(response.data.rows);
    }

    useEffect(() => {
        getCrossDocks();
    }, []);


    let columns = [
        { accessor: 'name', Header: 'Name', editable: canEdit },
        { accessor: 'lineOne', Header: 'Address', editable: canEdit },
        { accessor: 'city', Header: 'City', editable: canEdit },
        { accessor: 'province', Header: 'State', editable: canEdit },
        { accessor: 'zip', Header: 'Zip', editable: canEdit, editProps: { type: 'input', inputType: 'number', min: '0' } },
        { accessor: 'contactInfo', Header: 'Contact Info', editable: canEdit },
        { accessor: 'offsite', Header: 'Offsite', type: 'checkbox', editable: canEdit, editProps: { type: 'checkbox' } },
        { accessor: 'netsuiteId', Header: 'Netsuite ID' },
    ]

    return (
        <div>
            <h1>View Inbound Cross Docks</h1>
            <Notifications options={notification} />
            <br />
            <DataTable 
                columns={columns}
                data={crossDocks}
                editable={true}
                saveEdit={async ({ id, field, value, row }) => {
                    let item = {};
                    item[field] = value;

                    let res = await api.updateInboundCrossDocks(id, item);
                    if (res.status === false) {
                        setNotification({ text: 'Failed to update cross dock', level: 'error' });
                    } else {
                        setNotification({ text: 'Cross dock modified!', level: 'success' });
                        getCrossDocks();
                    }
                }}
            />
        </div>
    )
}

const CreateInboundCrossDocks = () => {
    const [notification, setNotification] = useState({ text: null, level: null });

    let formFields = [
        {
            name: 'name',
            inputElement: 'textField',
            label: 'Name',
            gridValue: 4,
            inputProps: { required: true }
        },
        {
            name: 'lineOne',
            inputElement: 'textField',
            label: 'Address',
            gridValue: 4,
            inputProps: { required: true }
        },
        {
            name: 'city',
            inputElement: 'textField',
            label: 'City',
            gridValue: 3,
            inputProps: { required: true }
        },
        {
            name: 'province',
            inputElement: 'textField',
            label: 'State',
            gridValue: 1,
            inputProps: { required: true }
        },
        {
            name: 'zip',
            inputElement: 'textField',
            label: 'Zip',
            gridValue: 4,
            inputProps: { type: 'number', integer: true, max: '99999', required: true }
        },
        {
            name: 'contactInfo',
            inputElement: 'textField',
            label: 'Contact Info',
            gridValue: 6
        },
        {
            name: 'offsite',
            inputElement: 'checkbox',
            label: 'Offsite',
            gridValue: 2
        },
    ];

    return (
        <div>
            <h1>Create Inbound Cross Docks</h1>
            <Notifications options={notification} />
            <br />
            <FormComponent 
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    let res = await api.createInboundCrossDocks(formData);
                    if (res.status === false) {
                        setNotification({ text: 'Unable to create cross dock ' + res.msg, level: 'error' });
                        return;
                    }

                    setNotification({ text: 'Success!', level: 'success' });
                    resetForm();
                }}
            />
        </div>
    )
}

const InboundCrossDocks = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    const { roles } = useContext(UserContext);
    const canEdit = roles?.filter((role) => role.name.endsWith('CREATE_INBOUND_CROSS_DOCKS')).length >= 1;

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewInboundCrossDocks_Tab" label="View Inbound Cross Docks" value="0" />
                {canEdit && <Tab id="CreateInboundCrossDocks_Tab" label="Create Inbound Cross Docks" value="1" />}
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewInboundCrossDocks canEdit={canEdit} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateInboundCrossDocks />
            </TabPanel>
        </Paper>
    )
}

export { InboundCrossDocks }