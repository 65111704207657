import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { Button } from '@material-ui/core';
import { packagingApi as api } from '../../../api/packaging';
import { UserContext } from '../../../contexts/UserContext';

let CreateAutoAssignPackaging = () => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [boxOpts, setBoxOpts] = useState([]);
    let [insulationOpts, setInsulationOpts] = useState([]);
    const user = useContext(UserContext);

    let getBoxes = async () => {
        let response = await api.getBoxes();
        if (response.status === false) {
            setNotification({ text: 'No Boxes found ' + response.msg, level: 'error' });
            setBoxOpts([]);
            return;
        }

        let boxOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setBoxOpts(boxOpts);
    };

    let getInsulation = async (cubeLevelID) => {
        let response = await api.getInsulation(cubeLevelID);

        if (response.status === false) {
            setNotification({ text: 'No insulation found ' + response.msg, level: 'error' });
            setInsulationOpts([]);
            return;
        }

        let linersAsOpts = response.data.rows.map((row) => {
            row.text = row.itemNameInternal;
            row.value = row.id;
            return { text: row.itemNameInternal, value: row.id, cubeLevelID: row.cubeLevelID };
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setInsulationOpts([pleaseSelect].concat(linersAsOpts));
    };

    useEffect(() => {
        getBoxes();
    }, []);

    // Define the form
    let formFields = [
        {
            name: 'cubeLevelID',
            inputElement: 'select',
            label: 'Box Size',
            gridValue: 4,
            inputProps: {
                opts: boxOpts,
                onChange: (event) => {
                    getInsulation(event.target.value);
                },
            },
        },
        {
            name: 'heatIndexMin',
            inputElement: 'textField',
            label: 'Heat Index Min',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'heatIndexMax',
            inputElement: 'textField',
            label: 'Heat Index Max',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'insulationID',
            inputElement: 'select',
            label: 'Insulation',
            gridValue: 4,
            inputProps: { opts: insulationOpts },
        },
        { name: 'numIce', inputElement: 'textField', label: 'Num Ice', gridValue: 4, inputProps: { required: true } },
    ];

    return (
        <div>
            <h1>Create Packaging Auto Config</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        formData.facilityID = user.getFacilityID();
                        let response = await api.createAutoAssignPackaging(formData);
                        if (response.status === false) {
                            let message = 'Error creating config';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Config created!', level: 'success' });
                            resetForm();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

export { CreateAutoAssignPackaging };
