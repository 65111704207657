import React from 'react';
import ReactDOM from 'react-dom';
import { initialize as initializeSentry } from './utils/sentryUtils';
import './index.css';
import { App } from './components';
import * as serviceWorker from './serviceWorker';

const baseUrl = process.env.NODE_ENV === 'development' ? 'https://timothyorourke-dev.misfitsmarket.com' : '';

const { host } = window.location;
const { NODE_ENV: nodeEnv, REACT_APP_VERSION: reactAppVersion } = process.env;
const version = reactAppVersion || 'development';
const jalapenoSite = host.match(/^(.+)\.misfitsmarket\.com$/);

let env = 'development';

if (jalapenoSite) {
    const [, jalapenoSiteName] = jalapenoSite;
    if (jalapenoSiteName === 'jalapeno') {
        env = 'production';
    } else {
        env = jalapenoSiteName;
    }
}

initializeSentry(env, version);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
