import React, { useState } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { catalogApi as api } from '../../../api/catalog';
import { CreateItemMaster } from './createItemMaster';
import { Checkbox, FormControlLabel } from '@material-ui/core';

let ViewItemMaster = ({ taxonomy, tempZones, facilities, rows, items, getItems, getItemMasters }) => {
    let [notification, setNotification] = useState({ text: null, level: null });

    const getTaxonomyOptions = (row, level) => {
        let parentID = level > 1 ? row[`l${level - 1}TaxonomyID`] : 0;
        let opts = taxonomy[`l${level}`]
            .filter((item) => item.parentID === parentID)
            .map((item) => ({ text: item.name, value: item.id }));
        opts.unshift({ text: '', value: null });
        return opts;
    };

    rows = rows.map((item) => {
        item.replFacilities = item.replFacilities.map((fac) => ({ ...fac, text: fac.name, value: fac.facilitiesId }));
        return item;
    });

    let columns = [
        { accessor: 'id', Header: 'Item Master ID' },
        {
            accessor: 'itemNameInternal',
            Header: 'Internal Name',
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        { accessor: 'sku', Header: 'SKU' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'netsuiteID', Header: 'Netsuite ID' },
        { accessor: 'barcode', Header: 'Barcode', editable: true },
        {
            accessor: 'barcodeType',
            Header: 'Barcode Type',
            editable: true,
            editProps: { type: 'select', options: ['UPC', 'PLU', 'EAN'] },
        },
        {
            accessor: 'msrpPrice',
            Header: 'Base Price',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.1', min: '0' },
        },
        {
            accessor: 'csPallet',
            Header: 'Cases per pallet',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'lbCs',
            Header: 'Pounds per case (lbs/cs)',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'lengthInCs',
            Header: 'Case Length (in)',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'widthInCs',
            Header: 'Case Width (in)',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'heightInCs',
            Header: 'Case Height (in)',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'lengthInUnit',
            Header: 'Unit Length (in)',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'widthInUnit',
            Header: 'Unit Width (in)',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'heightInUnit',
            Header: 'Unit Height (in)',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'purchasingCasePackSize',
            Header: 'Purchasing Case Pack Size',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'purchasingCaseUOM',
            Header: 'Purchasing Case UOM',
            editable: true,
            editProps: { type: 'select', options: ['ct', 'lb'] },
        },
        {
            accessor: 'baseUnitsPerCase',
            Header: 'Sellable Units Per Case',
            editable: (row) => row.original.externalSku !== 116377,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'cubeLevelPerBaseUnit',
            Header: 'Cube Level Per Sellable Unit',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true, max: 100 },
        },
        {
            accessor: 'baseUnitSize',
            Header: 'Sellable Unit Size',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'baseUnitUOM',
            Header: 'Sellable Unit UOM',
            editable: true,
            editProps: { type: 'select', options: ['oz', 'ct', 'lb', 'floz', 'pt'] },
        },
        {
            accessor: 'multipackIndividualUnitSize',
            Header: 'MP Individual Unit Size',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'multipackIndividualUOM',
            Header: 'MP Individual Unit UOM',
            editable: true,
            editProps: { type: 'select', options: ['oz', 'floz'] },
        },
        {
            accessor: 'packagingUnitSize',
            Header: 'Packaging Unit Size',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true, min: 0 },
        },
        {
            accessor: 'packagingUnitType',
            Header: 'Packaging Unit Type',
            editable: true,
            editProps: { type: 'select', options: ['clamshell', 'pack', 'bunch', 'bag', 'head'] },
        },
        {
            accessor: 'l1TaxonomyID',
            Header: 'Category One',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => getTaxonomyOptions(row, 1),
            },
        },
        {
            accessor: 'l2TaxonomyID',
            Header: 'Category Two',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => getTaxonomyOptions(row, 2),
            },
        },
        {
            accessor: 'l3TaxonomyID',
            Header: 'Category Three',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => getTaxonomyOptions(row, 3),
            },
        },
        {
            accessor: 'l4TaxonomyID',
            Header: 'Category Four',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => getTaxonomyOptions(row, 4),
            },
        },
        { accessor: 'brand', Header: 'Brand', editable: true },
        {
            accessor: 'receivingTempZone',
            Header: 'Receiving Temp Zone',
            editable: true,
            editProps: { type: 'select', options: tempZones.receiving },
        },
        {
            accessor: 'storageTempZone',
            Header: 'Storage Temp Zone',
            editable: true,
            editProps: { type: 'select', options: tempZones.storage },
        },
        {
            accessor: 'shippingTempZone',
            Header: 'Carrier Shipping Temp Zone',
            editable: true,
            editProps: { type: 'select', options: tempZones.shipping },
        },
        {
            accessor: 'dbiShippingTempZone',
            Header: 'DBI Shipping Temp Zone',
            editable: true,
            editProps: { type: 'select', options: tempZones.dbiShipping },
        },
        {
            accessor: 'customerGuaranteeDays',
            Header: 'Customer Guarantee Days',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'daysAfterThaw',
            Header: 'Days After Thaw',
            editable: (row) => row.original.storageTempZone === 'Frozen (0-31)',
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'daysAfterOpening',
            Header: 'Days After Opening',
            editable: (row) => row.original.storageTempZone === 'Frozen (0-31)',
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'portionCountMischief',
            Header: 'Portion Count',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        { accessor: 'active', Header: 'Active', type: 'checkbox', editable: true, editProps: { type: 'checkbox' } },
        {
            accessor: 'priceSensitive',
            Header: 'Price Sensitive',
            type: 'checkbox',
            editable: true,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'sellOnPoQty',
            Header: 'Sell On Po Qty',
            type: 'checkbox',
            editable: true,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'productionMethod',
            Header: 'OG/CV',
            editable: true,
            editProps: {
                type: 'select',
                options: [
                    { text: 'Conventional', value: 'CONVENTIONAL' },
                    { text: 'Organic', value: 'ORGANIC' },
                ],
            },
        },
        {
            accessor: 'ti',
            Header: 'TI',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'hi',
            Header: 'HI',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'vendorGuaranteedDaysUponDelivery',
            Header: 'Vendor Guaranteed Days Upon Delivery',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'expirationType',
            Header: 'Expiration Type',
            editable: true,
            editProps: {
                type: 'select',
                options: [
                    { text: 'NO_EXPIRATION_DATE', value: 'NO_EXPIRATION_DATE' },
                    { text: 'EXPIRATION_DATE', value: 'EXPIRATION_DATE' },
                    { text: 'PRODUCTION_DATE', value: 'PRODUCTION_DATE' },
                    { text: 'DAYS_AFTER_RECEIPT', value: 'DAYS_AFTER_RECEIPT' },
                ],
            },
        },
        {
            accessor: 'expireLifeDays',
            Header: 'Expire Life Days',
            editable: true,
            editProps: { type: 'input', inputType: 'number', min: '0' },
        },
        {
            accessor: 'replFacilities',
            Header: 'Replenishment',
            editable: true,
            filterValues: (row) => row.original.replFacilities.map((item) => `${item.name}`),
            editProps: {
                type: 'autocomplete-multiple-chips',
                options: facilities.map((item) => ({ text: item.name, value: item.id })),
                modalHeader: 'Add a facility for replenishment for this item',
                addButtonText: 'Add Facilities',
                addItem: async ({ values, row }) => {
                    for (let value of values) {
                        let res = await api.createItemMasterReplenishmentFacility(value, row.id);
                        if (!res.status) {
                            setNotification({ text: res.msg, level: 'error' });
                            return false;
                        }
                    }
                    getItems();
                    return true;
                },
                removeItem: async ({ item, row }) => {
                    let res = await api.removeItemMasterReplenishmentFacility(item.id);
                    if (res.status === true) {
                        getItems();
                    }
                },
            },
        },
    ];

    return (
        <div>
            <h1>Item Master</h1>
            <Notifications options={notification} />
            {}
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="ActiveOnly_checkbox"
                            onChange={(event) => {
                                getItems(event.target.checked);
                            }}
                            color="primary"
                            defaultChecked={true}
                        />
                    }
                    label="Active Only"
                />
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    customizableColumns={true}
                    tableName="viewItemMaster"
                    saveEdit={async ({ id, field, value, row }) => {
                        let item = {};
                        item[field] = value;

                        let response = { status: false };

                        if (
                            [
                                'l1TaxonomyID',
                                'l2TaxonomyID',
                                'l3TaxonomyID',
                                'l4TaxonomyID',
                                'brand',
                                'receivingTempZone',
                                'storageTempZone',
                                'shippingTempZone',
                                'dbiShippingTempZone',
                                'customerGuaranteeDays',
                                'daysAfterThaw',
                                'daysAfterOpening',
                                'cubeLevelPerBaseUnit',
                                'baseUnitUOM',
                                'baseUnitSize',
                                'packagingUnitSize',
                                'packagingUnitType',
                                'multipackIndividualUnitSize',
                                'multipackIndividualUOM',
                                'priceSensitive',
                                'sellOnPoQty',
                                'productionMethod',
                                'expireLifeDays',
                                'expirationType',
                                'msrpPrice',
                            ].includes(field)
                        ) {
                            let formData = new FormData();
                            formData.append(field, value);
                            formData.append('externalSku', row.externalSku);
                            response = await api.updateItemInformation(formData);
                        } else {
                            response = await api.updateItemMaster(id, item);
                        }
                        if (response.status === false) {
                            let message = 'Error modifying item master';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                            return false;
                        } else {
                            getItems();
                            setNotification({ text: 'Item modified!', level: 'success' });
                        }
                    }}
                    duplicateRowSettings={{
                        enable: true,
                        CustomFormComponent: (
                            <CreateItemMaster
                                taxonomy={taxonomy}
                                tempZones={tempZones}
                                facilities={facilities}
                                getItemMasters={getItemMasters}
                                items={items}
                            />
                        ),
                        onSuccess: () => {
                            getItems();
                            setNotification({ text: 'New item created', level: 'success' });
                        },
                    }}
                />
            </div>
        </div>
    );
};

export { ViewItemMaster };
