import React, { useEffect, useContext } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Header } from '../shared';
import { Login, Lists, CreateList, Orders, ManageSubscriptions, FAQs } from '../pages';
import { UserContext } from '../../contexts/UserContext';
import './app.css';
import '../../fonts/SharpGroteskSmBold20.woff2';
import '../../fonts/SharpGroteskSmBold20.woff';

function FamApp() {
    const location = useLocation();
    const { user, roles } = useContext(UserContext);

    useEffect(() => {
        if (location.pathname === '/' && roles.length) {
            let roleNames = roles.map((role) => role.name);
            if (roleNames.includes('VIEW_IMPORTED_ORDERS')) {
                window.location.href = '/lettuce/partners/orders';
            } else if (roleNames.includes('VIEW_CURATED_LISTS')) {
                window.location.href = '/lettuce/partners/lists';
            }
        }
    }, [location.pathname, roles]);

    return (
        <div className="fam-app">
            {/* <BrowserRouter> */}
            <Header />
            {!!user.id && (
                <>
                    <Route path="/partners/lists">
                        <Lists />
                    </Route>
                    <Route path="/partners/orders">
                        <Orders />
                    </Route>
                    <Route path="/partners/create-list">
                        <CreateList />
                    </Route>
                    <Route path="/partners/subscriptions">
                        <ManageSubscriptions />
                    </Route>
                    <Route path="/partners/faq">
                        <FAQs />
                    </Route>
                </>
            )}
            {/* <Route path="/partners/login">
                <Login />
            </Route> */}
            {/* </BrowserRouter> */}
        </div>
    );
}

export default FamApp;
