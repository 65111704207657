import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { partnershipsApi } from '../../../../api/partnerships.js';
import { UserContext } from '../../../../contexts/UserContext.js';
import { OrderInfoCard } from '../../orders/components/orderInfoCard.js';
import { SubscriptionFailedOrdersDataTable } from './subscriptionFailedOrdersDataTable.js';

const SubscriptionInfoCard = ({
    sub,
    canEditSubs,
    source,
    openEditSubModal,
    setSuccessMessage,
    setErrorMessage,
    listOptions,
    getData,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [modalInfo, setModalInfo] = useState({});

    const handleCancelOrder = (orderID) => {
        setModalInfo({
            type: 'cancelOrder',
            orderID: orderID,
        });
    };

    const cancelOrder = async () => {
        let result = {
            status: false,
            error: '',
        };
        const res = await partnershipsApi.cancelOrder(source, sub.id, modalInfo.orderID);
        if (!res.status) {
            result.error = res.msg;
            return result;
        }

        getData();
        closeModal();
        result.status = true;
        return result;
    };

    const handleCancelSubscription = async () => {
        setModalInfo({
            type: 'cancelSubscription',
        });
    };

    const cancelSubscription = async () => {
        let result = {
            status: false,
            error: '',
        };
        const res = await partnershipsApi.cancelSubscription(source, sub.id);
        if (!res.status) {
            result.error = res.msg;
            return result;
        }

        getData();
        closeModal();
        result.status = true;
        return result;
    };

    const closeModal = () => {
        setModalInfo({});
    };

    return (
        <div className="curated-list-container">
            {/* Modal for canceling orders and subscriptions */}
            <CancelModal
                open={!!modalInfo?.type}
                type={modalInfo?.type}
                closeModal={closeModal}
                cancelSubscription={cancelSubscription}
                cancelOrder={cancelOrder}
                onSuccess={(msg) => {
                    setSuccessMessage(msg);
                }}
            />

            <div className="fam-header-container">
                <div className="fam-header-item-one">
                    <div className="grotesk-bold small-header-text">
                        ID {sub.id}: {sub.firstName} {sub.lastName} {sub.status === 'CANCELLED' && ' - CANCELLED'}
                    </div>
                </div>
                <div className="fam-header-item-two">
                    {canEditSubs && sub.status !== 'CANCELLED' && (
                        <>
                            <button
                                onClick={() => {
                                    openEditSubModal(sub);
                                }}
                                className="fam-button fam-button-white grotesk-bold"
                            >
                                Edit Subscription
                            </button>
                            <button
                                className="fam-button fam-button-red grotesk-bold"
                                onClick={() => {
                                    handleCancelSubscription();
                                }}
                            >
                                Cancel Subscription
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className="curated-list-divider"></div>

            <div className="list-details-container">
                <div className="card-details-container">
                    <div>
                        <div className="card-details-info-container">
                            <span className="grotesk-bold">Member Information</span>
                            <span></span>

                            <span className="grotesk-bold">Name</span>
                            <span className="grotesk-regular">
                                {sub.firstName} {sub.lastName}
                            </span>

                            <span className="grotesk-bold">Member ID</span>
                            <span className="grotesk-regular">{sub.memberID}</span>

                            <span className="grotesk-bold">Address</span>
                            <span className="grotesk-regular">
                                {sub.address1},{sub.address2 ? `${sub.address2}` : ''} {sub.city}, {sub.province}{' '}
                                {sub.zipCode}
                            </span>

                            <span className="grotesk-bold">Email</span>
                            <span className="grotesk-regular">{sub.email}</span>

                            <span className="grotesk-bold">Allergens</span>
                            <span className="grotesk-regular">{sub.allergens?.join(', ')}</span>

                            <span className="grotesk-bold">Dislikes</span>
                            <span className="grotesk-regular">
                                {sub.dislikes?.map((item) => item.name)?.join(', ')}
                            </span>
                        </div>
                    </div>
                    <div className="card-details-info-container">
                        <span className="grotesk-bold">Subscription Information</span>
                        <span></span>

                        <span className="grotesk-bold">Status</span>
                        <span className="grotesk-regular">{sub.status}</span>

                        <span className="grotesk-bold">Frequency</span>
                        <span className="grotesk-regular">{sub.frequency}</span>

                        <span className="grotesk-bold">Delivery Day</span>
                        <span className="grotesk-regular">
                            {sub.deliveryDay.charAt(0).toUpperCase() + sub.deliveryDay.slice(1)}
                        </span>

                        <span className="grotesk-bold">Active Since</span>
                        <span className="grotesk-regular">{sub.createdAt.date.split(' ')[0]}</span>

                        {sub.failedOrders.length > 0 && (
                            <>
                                <span className="grotesk-bold fam-warning-text">Failed Orders</span>
                                <span className="grotesk-regular">{sub.failedOrders.length}</span>
                            </>
                        )}

                        <span className="grotesk-bold">Past Orders</span>
                        <span className="grotesk-regular">{sub.previousOrders.length}</span>

                        <span className="grotesk-bold">Upcoming Orders</span>
                        <span className="grotesk-regular">{sub.upcomingOrders.length}</span>
                    </div>
                </div>
                &nbsp;&nbsp;
                {expanded && (
                    <div>
                        <div className="curated-list-divider"></div>

                        {/* Failed orders */}
                        {sub.failedOrders.length > 0 && (
                            <>
                                <div className="fam-warning-item-one">
                                    <WarningIcon className="fam-warning-icon" />
                                    <span className="fam-warning-text grotesk-bold">Needs Attention!</span>
                                </div>
                                <SubscriptionFailedOrdersDataTable
                                    failedOrders={sub.failedOrders}
                                    listOptions={listOptions}
                                    source={source}
                                    getData={getData}
                                    setErrorMessage={setErrorMessage}
                                    setSuccessMessage={setSuccessMessage}
                                />
                                <div className="curated-list-divider"></div>
                            </>
                        )}

                        {/* Upcoming Orders */}
                        <div>
                            <div className="grotesk-bold">Upcoming Orders</div>
                            <div>
                                {sub.upcomingOrders.map((item) => {
                                    if (!item.orderID) {
                                        return (
                                            <UnprocessedOrder
                                                key={item.scheduledOrderID}
                                                order={item}
                                                sub={sub}
                                                handleCancelOrder={handleCancelOrder}
                                            />
                                        );
                                    } else {
                                        return (
                                            <PastOrder
                                                key={item.scheduledOrderID}
                                                order={item}
                                                sub={sub}
                                                orderID={item.orderID}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>

                        <div className="curated-list-divider"></div>

                        {/* Past Orders */}
                        <div>
                            <div className="grotesk-bold">Past Orders</div>
                            <div>
                                {sub.previousOrders.map((item) => (
                                    <PastOrder order={item} sub={sub} orderID={item.orderID} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="curated-list-divider"></div>

            <div
                className="fam-warning-expand-button grotesk-regular align-center"
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? 'Collapse' : 'Expand'}
            </div>
        </div>
    );
};

const CancelModal = ({ type, cancelOrder, cancelSubscription, open, closeModal, onSuccess }) => {
    const [error, setError] = useState('');

    const modalTypes = {
        cancelOrder: {
            text: 'Cancel Order?',
            action: async () => {
                const result = await cancelOrder();
                if (!result.status) {
                    setError(result.error);
                    return;
                }
                if (onSuccess instanceof Function) {
                    onSuccess('Order Cancelled');
                }
            },
        },
        cancelSubscription: {
            text: 'Cancel Subscription?',
            action: async () => {
                const result = await cancelSubscription();
                if (!result.status) {
                    setError(result.error);
                    return;
                }
                if (onSuccess instanceof Function) {
                    onSuccess('Subscription Cancelled');
                }
            },
        },
    };

    return (
        <Dialog key="cancel" open={open} maxWidth="sm" fullWidth={true} onClose={closeModal}>
            <DialogContent>
                <div>
                    <div>{modalTypes?.[type]?.text}</div>
                    &nbsp;&nbsp;
                    <div>
                        <button
                            onClick={modalTypes?.[type]?.action}
                            className="fam-button fam-button-yellow grotesk-bold"
                        >
                            Confirm
                        </button>
                    </div>
                    {!!error && <div className="fam-warning-text grotesk-regular">{error}</div>}
                </div>
            </DialogContent>
        </Dialog>
    );
};

const PastOrder = ({ orderID, order, sub }) => {
    const [expanded, setExpanded] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const { famAccount, roles } = useContext(UserContext);

    const [canCancelOrders, setCanCancelOrders] = useState(false);

    useEffect(() => {
        setCanCancelOrders(roles.map((role) => role.name).includes('IMPORT_ORDERS'));
    }, [roles]);

    const getOrderDetails = async () => {
        const res = await partnershipsApi.getPartnershipOrders(famAccount.partnershipSource, 0, '', {
            orderID: orderID,
        });
        if (!res.status || !res.data) {
            // setLoading(false);
            return;
        }

        if (res.data.rows.length !== 1) {
            return;
        }

        setOrderDetails(res.data.rows[0]);
    };

    useEffect(() => {
        if (expanded && Object.keys(orderDetails).length === 0) {
            getOrderDetails();
        }
    }, [expanded]);
    return (
        <div>
            <div className="fam-upcoming-order-summary-container">
                <div className="fam-upcoming-order-info-container">
                    <div>
                        <span className="grotesk-bold">List</span>
                        &nbsp;&nbsp;
                        <span>{order.listName}</span>
                    </div>
                    <div>
                        <span className="grotesk-bold">Order Number</span>
                        &nbsp;&nbsp;
                        <span>{orderID}</span>
                    </div>
                    <div>
                        <span className="grotesk-bold">Status</span>
                        &nbsp;&nbsp;
                        <span>{order?.shipments?.map((sh) => sh.status).join(', ')}</span>
                    </div>
                </div>
                <div className="fam-upcoming-order-button-container">
                    <div
                        className="fam-warning-expand-button grotesk-regular align-center"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? 'Collapse Order' : 'Expand'}
                    </div>
                </div>
            </div>

            {expanded && Object.keys(orderDetails).length > 0 && (
                <OrderInfoCard
                    key={orderID}
                    order={orderDetails}
                    getOrders={getOrderDetails}
                    canCancelOrders={canCancelOrders}
                    setSuccessMessage={() => {}}
                    collapsable={false}
                    hideHeader={true}
                    containerClass="fam-sub-order-info-container"
                />
            )}
        </div>
    );
};

const UnprocessedOrder = ({ order, sub, handleCancelOrder }) => {
    return (
        <div className="fam-upcoming-order-summary-container">
            <div className="fam-upcoming-order-info-container">
                <div>
                    <span className="grotesk-bold">List</span>
                    &nbsp;&nbsp;
                    <span>{order.listName}</span>
                </div>
                <div>
                    <span className="grotesk-bold">Qty</span>
                    &nbsp;&nbsp;
                    <span>{order.quantity}</span>
                </div>
                <div>
                    <span className="grotesk-bold">Delivery Date</span>
                    &nbsp;&nbsp;
                    <span>{order.scheduledDeliveryDate}</span>
                </div>
            </div>
            <div className="fam-upcoming-order-button-container">
                {order.scheduledOrderStatus !== 'CANCELLED' ? (
                    <button
                        className="fam-button fam-button-red grotesk-bold"
                        onClick={() => {
                            handleCancelOrder(order.scheduledOrderID);
                        }}
                    >
                        Cancel Order
                    </button>
                ) : (
                    <span>Order Cancelled</span>
                )}
            </div>
            {order.supplementalItems.map((suppItem) => {
                return (
                    <div>
                        <span className="grotesk-bold">
                            + {suppItem.scheduleModel?.quantity} item{suppItem.scheduleModel?.quantity > 1 && 's'} from:
                        </span>
                        &nbsp;&nbsp;
                        {suppItem.taxonomyNames.length > 0 && <span>{suppItem.taxonomyNames.join(', OR ')}</span>}
                    </div>
                );
            })}
        </div>
    );
};

export { SubscriptionInfoCard };
