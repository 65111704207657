import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, FBMAccountPicker } from '../../../shared';
import { fbmApi as api } from '../../../api/fbm';
import { UserContext } from '../../../contexts/UserContext';
import { Grid } from '@material-ui/core';

let FBMIngestionResults = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const { fbmAccount } = useContext(UserContext);

    // Load rows
    let getRows = async () => {
        let response = await api.getBulkOrderIngestionDetails(fbmAccount.fbmAccountName);
        if (response.status === false || !response.data) {
            setNotification({ text: `No entries found ${response.msg}`, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.data);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getRows();
        }
    }, [fbmAccount]);

    let cols = [
        { accessor: 'ingestDate.date', Header: 'Ingest Date' },
        { accessor: 'numberOfOrdersIngested', Header: 'Number of Orders Ingested' },
        { accessor: 'status', Header: 'Status / Result' },
        { accessor: 'user', Header: 'User' },
        { accessor: 'notes', Header: 'Notes' },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item xs={12}>
                <FBMAccountPicker />
            </Grid>
            {!!fbmAccount.id && (
                <>
                    <Grid item md={6} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                        <h1>{fbmAccount.companyName} Bulk Order Ingestion</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable columns={cols} data={rows} />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export { FBMIngestionResults };
