import React, { useState, useEffect } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { Modal, Button } from '@material-ui/core';
import { CreateCreditRequest } from './createCreditRequest';
import { procurementApi as api } from '../../../api/procurement';
import moment from 'moment';
import { warehouseApi } from '../../../api/warehouse';

const ViewCreditItems = ({
    parentRow,
    setNotification,
    itemMaster,
    accountingStatusOpts,
    getCreditRequests,
    reasons,
}) => {
    const [rows, setRows] = useState([]);

    let getCreditItems = async () => {
        if (parentRow.original.id == null) {
            return;
        }

        let res = await api.getCreditRequestItems(parentRow.original.id);

        if (res.status === false) {
            setNotification({ text: 'No items found ' + res.msg, level: 'error' });
            setRows([]);
            return;
        }

        let creditItems = res.data.creditItems;

        setRows(
            creditItems.map((row) => {
                let productName = itemMaster.filter((item) => item.sku === row.sku)[0];
                row.productName = productName.productName;
                row.productCost = parseFloat(row.productCost).toFixed(2);
                row.dumpCost = parseFloat(row.dumpCost).toFixed(2);
                row.laborCost = parseFloat(row.laborCost).toFixed(2);
                row.freightCost = parseFloat(row.freightCost).toFixed(2);
                row.totalAmount = parseFloat(row.totalAmount).toFixed(2);
                row.inspectionCost = parseFloat(row.inspectionCost).toFixed(2);
                row.otherCosts = parseFloat(row.otherCosts).toFixed(2);
                return row;
            })
        );
    };

    useEffect(() => {
        getCreditItems();
    }, [parentRow]);

    let creditItemColumns = [
        { accessor: 'productName', Header: 'Product Name' },
        {
            accessor: 'numberOfCasesAffected',
            Header: 'Number of Cases Affected',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', inputType: 'number', step: '1', min: '0' },
        },
        {
            accessor: 'reasons',
            Header: 'Reasons',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            filterValues: (row) => row.original.reasons.map((item) => item),
            editProps: { type: 'autocomplete-multiple', options: reasons, multiple: true },
        },
        {
            accessor: 'productCost',
            Header: 'Product Cost',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'dumpCost',
            Header: 'Dump Cost',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'laborCost',
            Header: 'Labor Cost',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'freightCost',
            Header: 'Freight Cost',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        { accessor: 'totalAmount', Header: 'Total Amount' },
        {
            accessor: 'inspectionCost',
            Header: 'USDA Inspection Cost',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'otherCosts',
            Header: 'Other Costs',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', required: true },
        },
        {
            accessor: 'accountingStatus',
            Header: 'Accounting Status',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: {
                type: 'select',
                options: accountingStatusOpts,
            },
        },
        {
            accessor: 'internalNotes',
            Header: 'Internal Notes',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', multiline: true },
        },
        {
            accessor: 'externalNotes',
            Header: 'External Notes',
            editable: parentRow.original.status !== 'RECEIVED' && parentRow.original.status !== 'CANCELLED',
            editProps: { type: 'input', multiline: true },
        },
    ];

    return (
        <DataTable
            columns={creditItemColumns}
            data={rows}
            editable={true}
            saveEdit={async ({ id, field, value, row }) => {
                let updates = { [field]: value };
                let res = await api.updateCreditRequestItem(parentRow.original.id, row.itemID, updates);
                if (res.status === false) {
                    let message = 'Error modifying credit request';
                    if (res) {
                        message += ': ' + res.msg;
                    }
                    setNotification({ text: message, level: 'error' });
                } else {
                    setNotification({ text: 'Credit request modified!', level: 'success' });

                    if (['productCost', 'dumpCost', 'laborCost', 'freightCost'].includes(field)) {
                        getCreditRequests();
                    }

                    return res.status;
                }
            }}
        />
    );
};

const CancelCloseCreditModal = ({ rowData, closeModal, setNotification, cancelling, closing }) => {
    return (
        <Modal open={true} onClose={closeModal}>
            <div
                style={{
                    outline: 0,
                    backgroundColor: 'white',
                    width: '300px',
                    margin: '10% auto',
                    padding: '10px',
                    textAlign: 'center',
                }}
            >
                <div style={{ alignItems: 'center' }}>
                    <h2>Are you sure you want to {cancelling ? 'CANCEL' : 'RECEIVE'} this Credit Request?</h2>
                    <br />
                    <div>
                        <Button
                            onClick={async () => {
                                let res;
                                if (cancelling) {
                                    res = await api.cancelCreditRequest(rowData.original.id);
                                } else {
                                    res = await api.receiveCreditRequest(rowData.original.id);
                                }
                                if (res.status === false) {
                                    setNotification({
                                        text: `Failed to ${cancelling ? 'cancel' : 'receive'} credit request ${
                                            res.msg
                                        }`,
                                        level: 'error',
                                    });
                                    return;
                                }
                                setNotification({
                                    text: `Credit request ${cancelling ? 'cancelled' : 'received'}`,
                                    level: 'success',
                                });
                                closeModal();
                            }}
                        >
                            YES
                        </Button>
                        <Button onClick={() => closeModal()}>NO</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const ViewCreditRequests = ({ vendors, carriers, accountingStatusOpts, reasons }) => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [cancelCreditModalOpen, setCancelCreditModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [itemMaster, setItemMaster] = useState([]);
    const [cancelling, setCancelling] = useState(false);
    const [closing, setClosing] = useState(false);

    const getCreditRequests = async () => {
        let response = await api.getCreditRequests();

        if (response.status === false) {
            setNotification({ text: "Can't access credit requests: " + response.msg, level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY');
                if (row.truckID === 0) {
                    row.truckID = '';
                }
                return row;
            });
        }

        setRows(response.data.rows);
    };

    let getItemMaster = async () => {
        let res = await warehouseApi.getItemMaster(true, false);
        if (res.status === false) {
            setNotification({ text: 'Could not get item masters. ' + res.msg, level: 'error' });
            setRows([]);
            return;
        }

        setItemMaster(res.data.rows.map((item) => ({ sku: item.sku, productName: item.itemNameInternal })));
    };

    useEffect(() => {
        getCreditRequests();
        getItemMaster();
    }, []);

    let cancelCreditModal = (row) => {
        setSelectedRow(row);
        setCancelCreditModalOpen(true);
        setCancelling(true);
    };

    let closeCreditModal = (row) => {
        setSelectedRow(row);
        setCancelCreditModalOpen(true);
        setClosing(true);
    };

    let columns = [
        { accessor: 'createdAt', Header: 'Created On' },
        { accessor: 'purchaseOrderID', Header: 'PO Number' },
        { accessor: 'truckID', Header: 'Truck ID' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'creditFrom', Header: 'Credit From' },
        { accessor: 'vendorCarrier', Header: 'Vendor/Carrier Name' },
        { accessor: 'creditTotal', Header: 'Credit Total' },
        { accessor: 'authUser', Header: 'Submitter' },
        {
            accessor: 'cancel',
            Header: 'Cancel',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Button
                            disabled={row.original.status === 'CANCELLED' || row.original.status === 'RECEIVED'}
                            id={`Cancel_${row.id}`}
                            onClick={async () => {
                                cancelCreditModal(row);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'receive',
            Header: 'Receive',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Button
                            disabled={row.original.status === 'CANCELLED' || row.original.status === 'RECEIVED'}
                            id={`Receive_${row.id}`}
                            onClick={async () => {
                                closeCreditModal(row);
                            }}
                        >
                            RECEIVE
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <h1>Credit Requests</h1>
            <Notifications options={notification} />
            {cancelCreditModalOpen && (
                <CancelCloseCreditModal
                    rowData={selectedRow}
                    setNotification={setNotification}
                    cancelling={cancelling}
                    closing={closing}
                    closeModal={() => {
                        setCancelCreditModalOpen(false);
                        setCancelling(false);
                        setClosing(false);
                        getCreditRequests();
                    }}
                />
            )}
            <DataTable
                columns={columns}
                data={rows}
                expandable={true}
                ExpansionComponent={
                    <ViewCreditItems
                        setNotification={setNotification}
                        itemMaster={itemMaster}
                        accountingStatusOpts={accountingStatusOpts}
                        getCreditRequests={getCreditRequests}
                        reasons={reasons}
                    />
                }
                duplicateRowSettings={{
                    enable: true,
                    CustomFormComponent: (
                        <CreateCreditRequest reasons={reasons} accountingStatusOpts={accountingStatusOpts} />
                    ),
                    fetchAdditionalData: async (row) => {
                        let res = await api.getCreditRequestItems(row.id);
                        if (!res.status) {
                            setNotification({ text: 'Failed to get items in credit request', level: 'error' });
                            return [];
                        }

                        let creditRequestItems = res.data.creditItems.map((row) => {
                            row.productCost = parseFloat(row.productCost);
                            row.dumpCost = parseFloat(row.dumpCost);
                            row.laborCost = parseFloat(row.laborCost);
                            row.freightCost = parseFloat(row.freightCost);
                            row.totalAmount = parseFloat(row.totalAmount);
                            row.inspectionCost = parseFloat(row.inspectionCost);
                            row.otherCosts = parseFloat(row.otherCosts);
                            row.id = row.itemID;
                            return row;
                        });
                        return creditRequestItems;
                    },
                    onSuccess: () => {
                        getCreditRequests();
                        setNotification({ text: 'Credit Request Created', level: 'success' });
                    },
                }}
            />
        </>
    );
};

export { ViewCreditRequests };
