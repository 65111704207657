import React from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    makeStyles
} from '@material-ui/core';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
    tableCellField: {
        width: '10%'
    },
    tableCellValue: {
        width: '45%'
    }
}));

/**
 * Add data in the following format to any 'upload' 
 * step to use this modal 
 * 
 * changedData: [
 *      key: 12345,
 *      action: 'action name'
 *      updates: [
 *          {
 *              field: 'field name',
 *              currentValue: 'current value',
 *              newValue: 'uploaded value'
 *          }
 *      ]
 * ]
 */
const ComparisonModal = ({ data, confirmationModalKeyName }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(true);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth="lg"
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle>Review the following catalog changes</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        {
                            data.map(row => {
                                return (
                                    <>
                                        <TableRow>
                                            <TableCell>
                                                <strong>{confirmationModalKeyName}:</strong> {row.key} <strong>Action:</strong> {row.action}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <Table>
                                                <TableHead>
                                                    <TableRow style={{backgroundColor: 'rgba(169, 169, 169, 0.2)'}}>
                                                        <TableCell className={classes.tableCellField} align="center">Field</TableCell>
                                                        <TableCell className={classes.tableCellValue} align="center">Previous Value</TableCell>
                                                        <TableCell className={classes.tableCellValue} align="center">New Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        row.updates.map((item, i) => {
                                                            return (
                                                                <TableRow key={i}>
                                                                    <TableCell 
                                                                        align="center" 
                                                                        className={classes.tableCellField}
                                                                    >
                                                                        <strong>{item.field}</strong>
                                                                    </TableCell>
                                                                    <TableCell 
                                                                        align="center" 
                                                                        className={classes.tableCellValue}
                                                                    >
                                                                        {item.currentValue}
                                                                    </TableCell>
                                                                    <TableCell 
                                                                        align="center" 
                                                                        className={classes.tableCellValue}
                                                                    >
                                                                        {item.newValue}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableRow>
                                        <br/>
                                    </>
                                )
                            })
                        }
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close Preview
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export { ComparisonModal };