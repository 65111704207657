import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, TabPanel } from '../../../shared';
import { systemApi as api } from '../../../api/system';
import { productionApi as productionApi } from '../../../api/production';
import {
    NativeSelect,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    CircularProgress,
} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';

let LiveInventory = () => {
    let [packGroups, setPackGroups] = useState([]);
    let [selectedPackGroup, setSelectedPackGroup] = useState('');
    let [notification, setNotification] = useState({ text: null, level: null });
    let [rows, setRows] = useState([]);
    let [packDateColumns, setPackDateColumns] = useState([]);
    let [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext);

    let getPackGroups = async () => {
        let response = await productionApi.getPackGroupsRecent(user.facilityID);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            return;
        }

        console.log(response.data.rows.length);

        let mmPackGroups = response.data.rows
            .filter((item) => !item.name.includes('ST') && !item.name.includes('LS'))
            .slice(0, 2);

        console.log(mmPackGroups);

        let fbmPackGroups = response.data.rows
            .filter((item) => item.name.includes('ST') || item.name.includes('LS'))
            .slice(0, 2);

        setPackGroups([...mmPackGroups, ...fbmPackGroups]);

        let pgInFacility = response.data.rows.find((pg) => pg.facilityID === user.facilityID);
        if (pgInFacility) {
            setSelectedPackGroup(pgInFacility.id);
        }
    };

    let getPackGroupInventory = async () => {
        setLoading(true);
        setRows([]);
        setPackDateColumns([]);
        let response = await api.getLiveInventoryForPackGroup(selectedPackGroup);
        if (response.status === false) {
            setNotification({ text: 'Failed to get inventory for pack group ' + response.msg, level: 'error' });
            setLoading(false);
            return;
        }

        setPackDateColumns(
            response.data.inventory.packDates.map((item) => {
                return {
                    Header: item,
                    accessor: `${item}.unitsRemaining`,
                };
            })
        );
        setRows(response.data.inventory.sellableItems);
        setLoading(false);
    };

    useEffect(() => {
        getPackGroups();
    }, []);

    useEffect(() => {
        // get data
        if (!!selectedPackGroup) {
            getPackGroupInventory();
        }
    }, [selectedPackGroup]);

    return (
        <div>
            <h1>Live Inventory Units By Pack Date</h1>
            <Notifications options={notification} />

            <NativeSelect
                className="input-element-select"
                value={selectedPackGroup}
                label="Pack Group Select"
                onChange={(event) => {
                    setSelectedPackGroup(event.target.value);
                }}
            >
                {!selectedPackGroup && <option value={selectedPackGroup}>Please Select</option>}
                {packGroups.map((opt) => {
                    return (
                        <option key={opt.id} value={opt.id}>
                            {opt.name + ' ' + opt.week}
                        </option>
                    );
                })}
                ;
            </NativeSelect>
            <div>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <div>
                        {rows.length > 0 && packDateColumns.length > 0 ? (
                            <DataTable
                                columns={[
                                    { Header: 'ID', accessor: 'id' },
                                    { Header: 'Name', accessor: 'name' },
                                    { Header: 'Item Type', accessor: 'itemType' },
                                    { Header: 'External Sku', accessor: 'externalSku' },
                                    ...packDateColumns,
                                ]}
                                data={rows}
                                expandable={true}
                                ExpansionComponent={
                                    <ViewLiveInventoryDetails
                                        setNotification={setNotification}
                                        packDateColumns={packDateColumns}
                                    />
                                }
                            />
                        ) : (
                            <div style={{ paddingTop: '8px' }}>No inventory found for this pack group.</div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

let ViewLiveInventoryDetails = ({ parentRow, setNotification, packDateColumns }) => {
    let [selectedTab, setSelectedTab] = useState(packDateColumns[0].Header);

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <>
            <h2>Inventory Buckets</h2>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">Pack Date</TableCell>
                            <TableCell variant="head">ATS, Not Reserved</TableCell>
                            <TableCell variant="head">On PO, Not Reserved</TableCell>
                            <TableCell variant="head">Reserved</TableCell>
                            <TableCell variant="head">Not ATS</TableCell>
                            <TableCell variant="head">On Hand</TableCell>
                            <TableCell variant="head">Total</TableCell>
                        </TableRow>
                        {packDateColumns.map((item) => {
                            let parentRowData = parentRow.original[item.Header];

                            return (
                                <TableRow key={item.Header}>
                                    {/* Pack Date */}
                                    <TableCell>{item.Header}</TableCell>

                                    {/* ATS, Not Reserved */}
                                    <TableCell>
                                        {parentRow.original.itemType === 'Item Master' ? (
                                            <div>{parentRowData.atsNotReserved.total}</div>
                                        ) : (
                                            <div>
                                                Total: {parentRowData.atsNotReserved.total}
                                                <div>
                                                    Item Level Two Units: {parentRowData.atsNotReserved.itemLevelTwo}
                                                </div>
                                                <div>
                                                    Item Master Units: {parentRowData.atsNotReserved.itemMaster}
                                                    {parentRowData.itemLevelTwoMultiplier > 1 &&
                                                        ` (${parentRowData.itemLevelTwoMultiplier} per Il2)`}
                                                </div>
                                            </div>
                                        )}
                                    </TableCell>

                                    {/* On PO, Not Reserved */}
                                    <TableCell>{parentRowData.onPONotReserved}</TableCell>

                                    {/* Reserved */}
                                    <TableCell>
                                        {
                                            <>
                                                <div>Total: {parentRowData.reserved.total}</div>
                                                {parentRow.original.itemType === 'Item Master' ? (
                                                    <div>From Lots: {parentRowData.reserved.itemMaster}</div>
                                                ) : (
                                                    <>
                                                        <div>
                                                            Item Level Two Units: {parentRowData.reserved.itemLevelTwo}
                                                        </div>
                                                        <div>
                                                            Item Master Units: {parentRowData.reserved.itemMaster}
                                                            {parentRowData.itemLevelTwoMultiplier > 1 &&
                                                                ` (${parentRowData.itemLevelTwoMultiplier} per Il2)`}
                                                        </div>
                                                    </>
                                                )}
                                                <div>From POs: {parentRowData.reserved.onPO}</div>
                                            </>
                                        }
                                    </TableCell>

                                    {/* Not ATS */}
                                    <TableCell>
                                        {parentRow.original.itemType === 'Item Master' ? (
                                            <div>{parentRowData.notAts.total}</div>
                                        ) : (
                                            <>
                                                <div>Total: {parentRowData.notAts.total}</div>
                                                <div>Item Level Two Units: {parentRowData.notAts.itemLevelTwo}</div>
                                                <div>
                                                    Item Master Units: {parentRowData.notAts.itemMaster}
                                                    {parentRowData.itemLevelTwoMultiplier > 1 &&
                                                        ` (${parentRowData.itemLevelTwoMultiplier} per Il2)`}
                                                </div>
                                            </>
                                        )}
                                    </TableCell>

                                    {/* On Hand */}
                                    <TableCell>
                                        <div>{parentRowData.onHand}</div>
                                    </TableCell>

                                    {/* Total */}
                                    <TableCell>
                                        <div>{parentRowData.total}</div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <h2>Lots And POs</h2>
            {
                <>
                    <Tabs
                        onChange={handleChange}
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        {packDateColumns.map((item) => {
                            return <Tab key={item.Header} label={item.Header} value={item.Header} />;
                        })}
                    </Tabs>
                    {packDateColumns.map((item) => {
                        let data = parentRow.original[item.Header];
                        let lotsAndPos = [
                            ...data.lots.map((item) => {
                                item.lotID = item.id;
                                item.type = item.externalSku >= 200000 ? 'Item Level Two' : 'Item Master';
                                return item;
                            }),
                            ...data.pos,
                        ];

                        return (
                            <TabPanel selectedTab={selectedTab} index={item.Header} key={item.Header}>
                                <>
                                    {lotsAndPos.length > 0 ? (
                                        <DataTable
                                            columns={[
                                                { Header: 'Lot ID', accessor: 'lotID' },
                                                { Header: 'Po ID', accessor: 'poID' },
                                                { Header: 'Type', accessor: 'type' },
                                                {
                                                    Header: 'Expires On',
                                                    accessor: 'expiresOn',
                                                    sortType: 'datetimeString',
                                                },
                                                {
                                                    Header: 'Last Packable',
                                                    accessor: 'lastPackableDate',
                                                    sortType: 'datetimeString',
                                                },
                                                { Header: 'Num Units', accessor: 'numUnits' },
                                                { Header: 'Num Units Reserved', accessor: 'numBaseUnitsClaimed' },
                                            ]}
                                            data={lotsAndPos}
                                            rowStyle={(row) => {
                                                if (!!row.lotID && row.ats == 'f') {
                                                    return { backgroundColor: '#ffafaf' };
                                                }
                                                return {};
                                            }}
                                        />
                                    ) : (
                                        <div>No data exists for this pack date.</div>
                                    )}
                                </>
                            </TabPanel>
                        );
                    })}
                </>
            }
        </>
    );
};

export { LiveInventory };
