import { mmFetchWithResponse } from './mmFetchWithResponse';

let growthApi = {
    cancelCampaign: async (campaignID) => {
        let url = `api/email/v1/campaigns/${campaignID}/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    cancelRecurringCampaign: async (campaignID) => {
        let url = `api/email/v1/campaigns/recurring/${campaignID}/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    updateEmailCampaignSegment: async (campaignID, segmentName, data) => {
        let formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key] || '');
        });

        let url = `api/email/v1/campaigns/${campaignID}/segments/${segmentName}`;

        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    createEmailCampaign: async (data) => {
        let formData = new FormData();

        let cleanedSegments = [];
        Object.keys(data).forEach((key) => {
            // stringify the segments for the formData
            let segmentKeys = ['segment1', 'segment2', 'segment3'];
            if (segmentKeys.includes(key)) {
                // extract files from the segments
                let segment = data[key];
                if (!segment || !segment.name) {
                    return { status: false, msg: 'Segment incomplete' };
                }

                let cleanedSegment = {};
                Object.keys(segment).forEach((key) => {
                    if (typeof segment[key] == 'object') {
                        console.log(`segment_${segment.name}_${key}`, segment[key]);
                        formData.set(`segment_${segment.name}_${key}`, segment[key]);
                    } else {
                        cleanedSegment[key] = segment[key];
                    }
                });
                cleanedSegments.push(cleanedSegment);
            } else {
                formData.set(key, data[key]);
            }
        });
        formData.set('segments', JSON.stringify(cleanedSegments));

        let url = `api/email/v1/campaigns`;

        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    getRecurringCampaigns: async () => {
        let url = 'api/email/v1/campaigns/recurring';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCampaigns: async (recurringID = null) => {
        let url = 'api/email/v1/campaigns';
        if (recurringID) {
            url += `?recurringID=${recurringID}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getNPSResults: async () => {
        let url = `api/growth/v1/nps`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateNPSResult: async (npsId, id, sendParams) => {
        let url = `api/growth/v1/nps/${npsId}/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    sendTestEmail: async (email, campaignID, segment = '') => {
        let url = `api/email/v1/campaigns/${campaignID}/test?email=${email}`;
        if (segment) {
            url += `&segment=${segment}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getOrderScheduledMerchandisedTemplateData: async (notifyServiceID) => {
        let url = `api/email/v1/campaigns/autopopulate?notifyServiceID=${notifyServiceID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getNPSSentimentCategories: async (npsID) => {
        let url = `api/growth/v1/nps/${npsID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getNPSCategories: async () => {
        let url = `api/growth/v1/nps/categories`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    createNPSSentCatCombo: async (npsID, data) => {
        let url = `api/growth/v1/nps/${npsID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    },
    deleteSentCatCombo: async (id) => {
        let url = `api/growth/v1/nps/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    createNPSCategory: async (category) => {
        let url = `api/growth/v1/nps/categories`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ category }),
        });
        return response;
    },
};

export { growthApi };
