import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import './nav.css';
import { UserContext } from '../../../contexts/UserContext';

const Nav = () => {
    const { user, clearUserAndLogOut, roles } = useContext(UserContext);
    return (
        <div className="nav-container">
            <div className="icon">
                <img src="https://static.misfitsmarket.com/images/MM_icon-orders.jpg" />
            </div>

            {roles.map((role) => role.name).includes('VIEW_IMPORTED_ORDERS') && (
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={{ pathname: '/partners/orders' }}>
                    <div className="grotesk-bold">My Orders</div>
                </Link>
            )}

            {roles.map((role) => role.name).includes('VIEW_CURATED_LISTS') && (
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={{ pathname: '/partners/lists' }}>
                    <div className="grotesk-bold">Curated Lists</div>
                </Link>
            )}

            {roles.map((role) => role.name).includes('VIEW_FAM_SUBSCRIPTIONS') && (
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={{ pathname: '/partners/subscriptions' }}>
                    <div className="grotesk-bold">Manage Subscriptions</div>
                </Link>
            )}

            <Link style={{ textDecoration: 'none', color: 'inherit' }} to={{ pathname: '/partners/faq' }}>
                <div className="grotesk-bold">FAQs</div>
            </Link>

            <div className="divider"></div>

            <div
                className="grotesk-bold logout clickable"
                onClick={() => {
                    clearUserAndLogOut();
                }}
            >
                Log Out
            </div>

            <div className="nav-help">
                <div className="grotesk-bold">Need Help?</div>
                <div className="grotesk-regular">
                    Please reach out to
                    <br />
                    <a href="mailto: partners@misfitsmarket.com">partners</a>
                    <br />
                    <span>
                        <a href="mailto: partners@misfitsmarket.com">@misfitsmarket.com</a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export { Nav };
