import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { Notifications, DataTable } from '../../../shared';
import { productionApi } from '../../../api/production';

let PhotoModal = ({ selectedPhoto, setPhotoModalOpen, currentBanner, getAisleBanners, setNotification }) => {
    const [file, setFile] = useState(null);
    const [source, setSource] = useState(null);

    let item = selectedPhoto.item;
    let key = selectedPhoto.key;

    return (
        <Dialog
            open={true}
            onClose={() => {
                setPhotoModalOpen(false);
            }}
        >
            <DialogTitle>Update Photo</DialogTitle>
            <DialogContent>
                {item[key] && !source && (
                    <img
                        src={`https://static.misfitsmarket.com/${item[key]}`}
                        alt="Item"
                        style={{ width: '500px', height: '500px' }}
                    />
                )}
                {source && (
                    <div style={{ textAlign: 'center' }}>
                        <img src={source} alt="Preview" style={{ width: '300px', height: '300px' }} />
                    </div>
                )}
                <Button id="ChooseFile" style={{ textAlign: 'center' }} variant="outlined" component="label">
                    Choose File
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        name="Photo Upload"
                        id="photo-file-input"
                        accept="image/png, image/jpeg"
                        onChange={(event) => {
                            let file = event.target.files[0];
                            setFile(file);

                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = (e) => {
                                setSource(e.target.result);
                            };
                        }}
                    />
                </Button>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={file === null}
                    onClick={async () => {
                        let sendParams = {};
                        sendParams.id = currentBanner.id;
                        sendParams.aisleID = currentBanner.aisleID;
                        sendParams[key] = file;

                        if (file.size > 300000) {
                            setNotification({ text: "Image size can't exceed 300KB", level: 'warning' });
                        } else {
                            let res = await productionApi.updateAisleBanner(sendParams);
                            setPhotoModalOpen(false);

                            if (res.status === true) {
                                setNotification({ text: 'Image updated!', level: 'success' });
                                getAisleBanners();
                            } else {
                                setNotification({ text: 'Error uploading image: ' + res.msg, level: 'error' });
                            }
                            return res.status;
                        }
                    }}
                >
                    Confirm
                </Button>
                <Button id="Cancel" onClick={() => setPhotoModalOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ColorModal = ({ setColorModalOpen, currentBanner, getAisleBanners, setNotification }) => {
    const [newColor, setNewColor] = useState();

    return (
        <Dialog
            open={true}
            onClose={() => {
                setColorModalOpen(false);
            }}
        >
            <DialogContent>
                <div style={{ textAlign: 'center' }}>
                    <h4>Choose Background Color</h4>
                    <input
                        type="color"
                        name="colorModal"
                        id=""
                        onChange={(e) => {
                            setNewColor(e.target.value);
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={async () => {
                        let sendParams = {};
                        sendParams.id = currentBanner.id;
                        sendParams.backgroundColor = newColor;
                        let res = await productionApi.updateAisleBanner(sendParams);

                        if (!res.status) {
                            setNotification({ text: 'Error updating background color: ' + res.msg, level: 'error' });
                            return false;
                        }

                        getAisleBanners();
                        setColorModalOpen(false);
                        return true;
                    }}
                >
                    Confirm
                </Button>
                <Button id="Cancel" onClick={() => setColorModalOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AppClickThroughModal = ({ setClickThroughModalOpen, currentBanner, getAisleBanners, setNotification }) => {
    const [newClickThrough, setNewClickThrough] = useState();

    const updateMobileInfo = async () => {
        let sendParams = {};
        sendParams.id = currentBanner.id;
        sendParams.mobileApp = true;
        sendParams.appClickThrough = newClickThrough;

        let res = await productionApi.updateAisleBanner(sendParams);
        if (!res.status) {
            setNotification({ text: 'Error updating mobile app data: ' + res.msg, level: 'error' });
            return false;
        }

        getAisleBanners();
        setClickThroughModalOpen(false);
        return true;
    };

    return (
        <Dialog
            open={true}
            onClose={() => {
                setClickThroughModalOpen(false);
            }}
        >
            <DialogContent>
                <FormControl style={{ width: '250px' }}>
                    <InputLabel>App Click Through Route</InputLabel>
                    <Select value={newClickThrough || 0} onChange={(e) => setNewClickThrough(e.target.value)} native>
                        <option key={0} value={0}>
                            Please Select
                        </option>
                        <option key={1} value={'/shop/recipe-inspiration'}>
                            Recipe Inspiration
                        </option>
                        <option key={2} value={'/shop/aisle/all'}>
                            All Items
                        </option>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={updateMobileInfo}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

const ViewAisleBanners = ({
    item,
    setNotification,
    aisleBannerColumns,
    aisleBanners,
    aisles,
    getAisleBanners,
    setPhotoModalOpen,
    photoModalOpen,
    currentBanner,
    selectedPhoto,
    colorModalOpen,
    setColorModalOpen,
    clickThroughModalOpen,
    setClickThroughModalOpen,
}) => {
    if (Array.isArray(aisleBannerColumns)) {
        aisleBannerColumns.push({
            accessor: 'remove',
            Header: 'Delete',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={async (event) => {
                            event.stopPropagation();
                            let res = await productionApi.deleteAisleBanner(row.original.id);
                            if (!res.status) {
                                setNotification({ text: 'Failed to delete aisle banner', level: 'error' });
                                return;
                            }
                            getAisleBanners();
                            setNotification({ text: 'Aisle banner deleted', level: 'success' });
                        }}
                    >
                        Delete
                    </Button>
                );
            },
        });
    }

    return (
        <>
            {!!clickThroughModalOpen && (
                <AppClickThroughModal
                    setClickThroughModalOpen={setClickThroughModalOpen}
                    currentBanner={currentBanner}
                    getAisleBanners={getAisleBanners}
                    setNotification={setNotification}
                />
            )}
            {!!photoModalOpen && (
                <PhotoModal
                    setPhotoModalOpen={setPhotoModalOpen}
                    selectedPhoto={selectedPhoto}
                    currentBanner={currentBanner}
                    getAisleBanners={getAisleBanners}
                    setNotification={setNotification}
                />
            )}
            {!!colorModalOpen && (
                <ColorModal
                    setColorModalOpen={setColorModalOpen}
                    currentBanner={currentBanner}
                    getAisleBanners={getAisleBanners}
                    setNotification={setNotification}
                />
            )}
            <DataTable
                editable={true}
                columns={aisleBannerColumns}
                data={aisleBanners}
                saveEdit={async ({ id, field, value }) => {
                    let sendParams = {};
                    sendParams.id = id;
                    sendParams[field] = value;
                    let res = await productionApi.updateAisleBanner(sendParams);
                    if (!res.status) {
                        setNotification({ level: 'error', text: `Failed to edit aisle banner: ${res.msg}` });
                        return false;
                    }
                    setNotification({ level: 'success', text: `Aisle banner updated` });
                    getAisleBanners();
                    return true;
                }}
            />
        </>
    );
};

export { ViewAisleBanners };
