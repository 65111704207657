import React, { useState, useEffect } from 'react';
import { DataTable, FormComponent, Notifications } from '../../../shared';
import { procurementApi as api } from '../../../api/procurement';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { addressesApi } from '../../../api/addresses';
import validEmail from '../../../shared/utils/validEmail';

let StateCountryModal = ({
    editing,
    setEditing,
    row,
    countryOpts,
    provinceOpts,
    handleProvChange,
    country,
    setNotification,
    getVendors,
    mainCountryCodes,
    getProvinces,
}) => {
    useEffect(() => {
        getProvinces(country.text);
    }, []);

    return (
        <Dialog open={editing} onClose={() => setEditing(false)} fullWidth width="400px">
            <DialogContent>
                <FormComponent
                    formFields={[
                        {
                            name: 'province',
                            label: 'State/Province',
                            inputElement: 'select',
                            gridValue: 6,
                            inputProps: {
                                opts: provinceOpts,
                                required: mainCountryCodes.includes(country.value) ? true : false,
                            },
                            initialValue: row.original.province,
                        },
                        {
                            name: 'country',
                            label: 'Country',
                            inputElement: 'autocomplete',
                            gridValue: 6,
                            inputProps: { opts: countryOpts, onChange: handleProvChange },
                            initialValue: country,
                        },
                    ]}
                    onSubmit={async (formData) => {
                        let sendParams = {
                            country: formData.country,
                            province: mainCountryCodes.includes(formData.country) ? formData.province : 'N/A',
                        };

                        let response = await api.updateVendor(row.original.id, sendParams);
                        if (response.status === false) {
                            let message = 'Error modifying shipper';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Shipper modified!', level: 'success' });
                            getVendors();
                        }
                        setEditing(false);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let StateCountryCell = ({ row, countryOpts, getVendors, setNotification }) => {
    const [editing, setEditing] = useState(false);

    let OGstate = row.original.province;
    let OGcountry = row.original.country;

    const mainCountryCodes = ['US', 'MX', 'CA'];

    let [provinceOpts, setProvinceOpts] = useState([]);
    const [country, setCountry] = useState(countryOpts.find((c) => c.value === row.original.country));

    // Load state options
    let getProvinces = async (country) => {
        if (country === 'United States') {
            country = 'unitedstates';
        }

        if (country === undefined) {
            let pleaseSelect = { text: 'Please Select Country', value: 0 };
            setProvinceOpts([pleaseSelect]);
            return;
        }

        let response = await api.getProvinces(country);
        if (response.status === false) {
            setNotification({ text: 'No provinces found ' + response.msg, level: 'error' });
            let pleaseSelect = { text: 'Please Select Country', value: 0 };
            setProvinceOpts([pleaseSelect]);
            return;
        }

        let miniOpts = response.data.rows.map((row) => {
            let out = {};
            out.text = row.name;
            out.value = row.twoLetter;
            return out;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setProvinceOpts([pleaseSelect].concat(miniOpts));
    };

    let handleProvChange = async (e, newValue) => {
        e.preventDefault();
        if (newValue && newValue.value) {
            setCountry(newValue);
            if (mainCountryCodes.includes(newValue.value)) {
                getProvinces(newValue.text);
            } else {
                setProvinceOpts([]);
            }
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ margin: 'auto' }}>{!!OGstate ? `${OGstate}, ${OGcountry}` : OGcountry}</div>
            <div>
                <Button id={`StateCountry_Edit_${row.id}`} onClick={() => setEditing(true)}>
                    Edit
                </Button>
            </div>
            {editing && (
                <StateCountryModal
                    editing={editing}
                    setEditing={setEditing}
                    row={row}
                    country={country}
                    countryOpts={countryOpts}
                    provinceOpts={provinceOpts}
                    handleProvChange={handleProvChange}
                    setNotification={setNotification}
                    getVendors={getVendors}
                    mainCountryCodes={mainCountryCodes}
                    getProvinces={getProvinces}
                />
            )}
        </div>
    );
};

// Vendors
let ViewVendor = () => {
    let [rows, setRows] = useState([]);
    let [countryOpts, setCountryOpts] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    // Load vendors
    let getVendors = async () => {
        let response = await api.getVendors();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        setRows(response.data.rows);
    };

    // Load country options
    let getCountries = async () => {
        let response = await addressesApi.getCountries();
        if (response.status === false) {
            setNotification({ text: 'No countries found ' + response.msg, level: 'error' });
            setCountryOpts([]);
            return;
        }

        let countryAsOpts = response.data.rows.map((row) => {
            let out = {};
            out.text = row.name;
            out.value = row.countryCode;
            return out;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setCountryOpts([pleaseSelect].concat(countryAsOpts));
    };

    useEffect(() => {
        getVendors();
        getCountries();
    }, []);

    let moqForOpts = [
        { text: 'Delivery', value: 'delivery' },
        { text: 'Pickup', value: 'pickup' },
    ];

    let uomOpts = [
        { text: 'lb', value: 'lb' },
        { text: 'pallet', value: 'pallet' },
        { text: 'case', value: 'case' },
    ];

    let columns = [
        { accessor: 'id', Header: 'Vendor ID' },
        { accessor: 'name', Header: 'Name', editable: false },
        { accessor: 'email', Header: 'MPOC Email', editable: true },
        { accessor: 'email1', Header: 'Email 1', editable: true },
        { accessor: 'email2', Header: 'Email 2', editable: true },
        { accessor: 'email3', Header: 'Email 3', editable: true },
        { accessor: 'email4', Header: 'Email 4', editable: true },
        { accessor: 'office', Header: 'Office', editable: true },
        { accessor: 'cell', Header: 'Cell', editable: true },
        { accessor: 'leadTime', Header: 'Lead Time', editable: true },
        { accessor: 'lineOne', Header: 'Line One', editable: true },
        { accessor: 'lineTwo', Header: 'Line Two', editable: true },
        { accessor: 'city', Header: 'City', editable: true },
        { accessor: 'paymentTerms', Header: 'Payment Terms', editable: false },
        { accessor: 'internalLogisticsEmail', Header: 'Internal Logistics Email', editable: true },
        {
            Header: 'State/Country',
            width: '150px',
            Cell: ({ row }) => StateCountryCell({ row, countryOpts, getVendors, setNotification }),
        },
        { accessor: 'zip', Header: 'Zip', editable: true },
        {
            accessor: 'moq1For',
            Header: 'MOQ 1 For',
            editable: true,
            editProps: {
                type: 'select',
                options: moqForOpts,
            },
        },
        {
            accessor: 'moq1Uom',
            Header: 'MOQ 1 UOM',
            editable: true,
            editProps: {
                type: 'select',
                options: uomOpts,
            },
        },
        { accessor: 'moq1Number', Header: 'MOQ 1 #', editable: true },
        {
            accessor: 'moq2For',
            Header: 'MOQ 2 For',
            editable: true,
            editProps: { type: 'select', options: moqForOpts },
        },
        { accessor: 'moq2Uom', Header: 'MOQ 2 UOM', editable: true, editProps: { type: 'select', options: uomOpts } },
        { accessor: 'moq2Number', Header: 'MOQ 2 #', editable: true },
    ];

    return (
        <div>
            <h1>Vendors</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        if (field.startsWith('email') && !validEmail(value)) {
                            setNotification({ text: 'Invalid email address', level: 'error' });
                            return;
                        }

                        let update = {};
                        update[field] = value;

                        let response = await api.updateVendor(id, update);
                        if (response.status === false) {
                            let message = 'Error modifying vendor';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Vendor modified!', level: 'success' });
                            getVendors();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export { ViewVendor };
