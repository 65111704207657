import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { systemApi as api } from '../../../api/system';

let MarketplaceDrinksInventory = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    // Load table
    let getRows = async () => {
        let response = await api.getMarketplaceDrinksInventory();
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getRows();
    }, []);

    let columns = [
        { accessor: 'drinksID', Header: 'Drinks Item ID' },
        { accessor: 'name', Header: 'Name' },
        { accessor: 'warehouse', Header: 'Drinks Warehouse' },
        { accessor: 'amountOrderedNotCharged', Header: 'Amount Ordered Not Charged' },
        { accessor: 'amountLeft', Header: 'Drinks Amount Left' },
    ];

    return (
        <div>
            <h1>Drinks Inventory</h1>
            <Notifications options={notification} />
            <div>
                <DataTable columns={columns} data={rows} />
            </div>
        </div>
    );
};

export { MarketplaceDrinksInventory };
