import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { infoTechApi as api } from '../../../api/infoTech';
import { Modal, Button } from '@material-ui/core';

// Table to show who has what role
const ViewUsersWithRoleTable = ({ parentRow, setExpandedRow }) => {
    let [roleID, setRoleID] = useState(parentRow.original.id);
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let columns = [
        { accessor: 'id', Header: 'User ID' },
        { accessor: 'email', Header: 'Email' },
        {
            accessor: 'revoke',
            Header: 'Revoke',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={async () => {
                                let response = await api.revokePermission(row.original.id, roleID);
                                if (response.status === false) {
                                    setNotification({
                                        text: 'Unable to revoke permissions ' + response.msg,
                                        level: 'error',
                                    });
                                    return;
                                }

                                setNotification({ text: 'Permission revoked', level: 'success' });
                            }}
                        >
                            REVOKE
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getItemsInTable = async () => {
        let response = await api.getUsersWithRole(roleID);

        if (response.status === false) {
            setNotification({ text: 'No roles found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getItemsInTable();
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            {}
            <DataTable columns={columns} data={rows} />
        </div>
    );
};

let SearchJalapenoRole = () => {
    let [rows, setRows] = useState([]);
    let [facilities, setFacilities] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let getRoles = async () => {
        let response = await api.getRoles('MISFITS MARKET');

        if (response.status === false) {
            setNotification({ text: 'No roles found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getRoles('MISFITS MARKET');
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'description', Header: 'Role' },
    ];

    return (
        <div>
            <h1>Jalapeno Accounts</h1>
            <Notifications options={notification} />

            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    expandable={true}
                    ExpansionComponent={<ViewUsersWithRoleTable />}
                />
            </div>
        </div>
    );
};

export { SearchJalapenoRole };
