import { mmFetchWithResponse } from './mmFetchWithResponse';

let shopperMarketingApi = {
    getVendorPromotionOptions: async () => {
        let url = 'api/marketing/v1/shopperMarketing/vendorPromotions/options';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getVendorPromotions: async () => {
        let url = 'api/marketing/v1/shopperMarketing/vendorPromotions';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getVendorPromotionStatuses: async () => {
        let url = 'api/marketing/v1/shopperMarketing/vendorPromotions/statuses';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getVendorPromotionSkus: async (id) => {
        let url = `api/marketing/v1/shopperMarketing/vendorPromotions/${id}/skus`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getVendorPromotionTypes: async () => {
        let url = 'api/marketing/v1/shopperMarketing/vendorPromotions/types';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    confirmVendorPromotions: async (key) => {
        let url = 'api/marketing/v1/shopperMarketing/vendorPromotions/skus/confirm';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    createVendorPromotion: async (formData) => {
        let url = 'api/marketing/v1/shopperMarketing/vendorPromotions';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(formData),
        });
        return response;
    },
    cloneVendorPromotion: async (id, formData) => {
        let url = `api/marketing/v1/shopperMarketing/vendorPromotions/${id}/clone`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(formData),
        });
        return response;
    },
    uploadVendorPromotions: async (vendorPromotionID, data) => {
        let formData = new FormData();
        formData.append('vendorPromotionID', vendorPromotionID);
        formData.append('file', data.file);

        let url = 'api/marketing/v1/shopperMarketing/vendorPromotions/skus/upload';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    updateVendorPromotion: async (id, sendParams) => {
        let url = `api/marketing/v1/shopperMarketing/vendorPromotions/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updateVendorPromotionSku: async (id, skuID, sendParams) => {
        let url = `api/marketing/v1/shopperMarketing/vendorPromotions/${id}/skus/${skuID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    removeVendorPromotionSku: async (id, skuID) => {
        let url = `api/marketing/v1/shopperMarketing/vendorPromotions/${id}/skus/${skuID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
};

export { shopperMarketingApi };
