import moment from 'moment';
import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let specialOrdersApi = {
    cancelSpecialOrder: async (id) => {
        let url = `api/order/v1/special/${id}/cancel`;
        try {
            let response = await mmFetch(url, { method: 'PUT' });
            if (response.ok === true) {
                return true;
            }
            return false;
        } catch (e) {
            console.log('[cancelSpecialOrder] error', e.toString());
            return false;
        }
    },
    createSpecialOrder: async (sendParams) => {
        let url = `api/order/v1/special`;
        try {
            let response = await mmFetchWithResponse(url, {
                method: 'POST',
                body: JSON.stringify(sendParams),
            });
            return response;
        } catch (e) {
            console.log('[createSpecialOrder] error', e.toString());
            return false;
        }
    },
    downloadPDF: async (id) => {
        let url = `api/order/v1/special/${id}/pdf`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'special-order-' + id + '.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadPDF] error', e.toString());
        }
    },
    getSpecialOrders: async (status) => {
        let url = `api/order/v1/special?status=${status}`;
        try {
            let response = await mmFetch(url);
            if (response.ok === true) {
                response = await response.json();
                return response.data.orders.map((item) => {
                    item.createdAt = moment(item.createdAt.date).format('MM/DD/YYYY');
                    item.shipDate = moment(item.shipDate.date).format('MM/DD/YYYY');
                    return item;
                });
            }
            return [];
        } catch (e) {
            console.log('[getSpecialOrders] error', e.toString());
            return [];
        }
    },
    markShipped: async (id) => {
        let url = `api/order/v1/special/${id}/ship`;
        try {
            let response = await mmFetch(url, { method: 'PUT' });
            if (response.ok === true) {
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    },
    purchaseLabel: async (id) => {
        let url = `api/order/v1/special/${id}/purchase`;
        try {
            let response = await mmFetch(url, { method: 'POST' });
            if (response.ok === true) {
                return true;
            }
            return false;
        } catch (e) {
            console.log('[purchaseLabel] error', e.toString());
            return false;
        }
    },
    updateSpecialOrder: async ({ id, field, value }) => {
        let sendParams = field === 'address' ? value : { [field]: value };
        let url = `api/order/v1/special/${id}/update`;
        try {
            await mmFetch(url, {
                method: 'PUT',
                body: JSON.stringify(sendParams),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return true;
        } catch (e) {
            console.log('[updateSpecialOrder] error', e.toString());
            return false;
        }
    },
};

export { specialOrdersApi };
