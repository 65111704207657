import React from 'react';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { productionApi as api } from '../../../api/production.js';

const CarouselUpload = () => {
    let columns = [
        { Header: 'Carousel ID', accessor: 'carouselID' },
        { Header: 'Rank', accessor: 'rank' },
        { Header: 'External SKU', accessor: 'externalSku' },
        { Header: 'Sellable Bundle ID', accessor: 'sellableBundleID' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.confirmCarouselUpload(key);
        return res;
    }

    let uploadCarousels = async (formData) => {
        let res = await api.uploadCarousels(formData);
        return res;
    };

    let downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Carousel ID,Rank,External SKU,Sellable Bundle ID';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'carousel_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    }

    return (
        <div>
            <UploadCSVAndReviewComponent 
                title="Upload Carousels"
                fileLabel="Item File"
                columns={columns}
                upload={uploadCarousels}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
            />
        </div>
    );
};

export { CarouselUpload };
