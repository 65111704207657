import React from 'react';
import { InputLabel } from '@material-ui/core';

import { DataTable } from '../../../shared';
import moment from 'moment';

const Donations = ({ classes, subscription }) => {
    let columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Date', accessor: 'donatedAt' },
        { Header: 'Status', accessor: 'status' },
    ];
    let data = subscription.charges
        .filter((charge) => {
            return charge.status === 'DONATED' || charge.status === 'FAILED_TO_DONATE';
        })
        .map((charge) => {
            charge.donatedAt = moment(charge.billedAt.date).format('MM/DD/YYYY');
            return charge;
        });

    return (
        <div className={classes.card}>
            <InputLabel className={classes.inputLabel}>Donations</InputLabel>
            <DataTable columns={columns} data={data} showPagination={data.length > 10} />
        </div>
    );
};

export { Donations };
