import React, { useState, useEffect } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { catalogApi as api } from '../../../api/catalog';
import { Button } from '@material-ui/core';
import { packagingApi } from '../../../api/packaging';

let ViewBomForItemLevelTwo = ({ parentRow, setExpandedRow, columns, setNotification }) => {
    let [bom, setBom] = useState(null);
    let [addingItem, setAddingItem] = useState(false);
    let [itemMasterOptions, setItemMasterOptions] = useState([]);
    let [packagingOptions, setPackagingOptions] = useState([]);

    let bomIngredientFormFields = [
        {
            name: 'ingredientType',
            label: 'Ingredient Type',
            inputElement: 'select',
            inputProps: { opts: ['Item Master', 'Packaging'] },
            gridValue: 3,
        },
        {
            name: 'itemMasterExternalSku',
            label: 'Item Master',
            display: (formData) => formData.ingredientType === 'Item Master',
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: itemMasterOptions },
            gridValue: 3,
        },
        {
            name: 'packagingMasterSku',
            label: 'Packaging',
            display: (formData) => formData.ingredientType === 'Packaging',
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: packagingOptions },
            gridValue: 3,
        },
        {
            name: 'qty',
            label: 'Quantity',
            inputElement: 'textField',
            display: (formData) => !!formData.ingredientType,
            inputProps: { required: true, type: 'number' },
            gridValue: 2,
        },
        {
            name: 'uom',
            label: 'UOM',
            display: (formData) => formData.ingredientType === 'Item Master',
            inputElement: 'select',
            inputProps: { opts: ['ct', 'oz', 'lb'], required: true },
            gridValue: 2,
        },
    ];

    let addItemToBOM = async (formData) => {
        let sendParams = {
            itemMasterExternalSku: formData.itemMasterExternalSku ? formData.itemMasterExternalSku.externalSku : null,
            packagingMasterSku: formData.packagingMasterSku ? formData.packagingMasterSku.masterSKU : null,
            qty: formData.qty,
            uom: formData.itemMasterExternalSku ? formData.uom : 'ct',
        };

        let res = await api.addItemToBOM(bom.id, sendParams);
        if (!res.status) {
            setNotification({ text: 'Failed to add item to BOM ' + res.msg, level: 'error' });
            return;
        }

        getBomForItemLevelTwo(parentRow.original.id);
    };

    let removeItemFromBOM = async (itemID) => {
        let res = await api.removeItemFromBOM(bom.id, itemID);
        if (!res.status) {
            setNotification({ text: 'Failed to remove item from BOM' + res.msg, level: 'error' });
            return;
        }

        getBomForItemLevelTwo(parentRow.original.id);
    };

    let getItemMaster = async () => {
        let itemMasterRes = await api.getItemInformation(true, 'itemMaster');
        if (itemMasterRes.status === true) {
            let items = itemMasterRes.data.items.map((item) => {
                return {
                    text: `${item.externalSku} (${item.name})`,
                    value: item,
                };
            });
            setItemMasterOptions(items);
        }
    };

    let getPackaging = async () => {
        let packagingRes = await packagingApi.getPackaging();
        if (packagingRes.status) {
            let items = packagingRes.data.rows.map((item) => {
                return { text: `SKU ${item.model.masterSKU}: ${item.model.description}`, value: item.model };
            });
            setPackagingOptions(items);
        }
    };
    let getBomForItemLevelTwo = async (id) => {
        let res = await api.getBomForItemLevelTwo(id);
        if (res.status) {
            let billOfM = res.data.bom;
            billOfM.ingredients = billOfM.ingredients.map((item) => {
                if (item.itemMasterExternalSku > 0) {
                    item.ingredientType = 'Item Master';
                    item.packagingMasterSku = null;
                } else {
                    item.ingredientType = 'Packaging';
                    item.itemMasterExternalSku = null;
                }
                return item;
            });
            setBom(billOfM);
        } else {
            setNotification({ level: 'error', text: 'Failed to get BOM' });
        }
    };

    useEffect(() => {
        getBomForItemLevelTwo(parentRow.original.id);
        getItemMaster();
        getPackaging();
    }, []);

    return (
        <div>
            {bom && bom.id && (
                <>
                    <h2 style={{ textAlign: 'center' }}>Bill of Materials</h2>
                    <div style={{ padding: 20 }}>
                        <strong>Labor Cost:</strong> {bom.laborCost}
                    </div>
                    <DataTable
                        title="Ingredients"
                        editable={true}
                        columns={[
                            { accessor: 'ingredientType', Header: 'Ingredient Type' },
                            { accessor: 'itemMasterExternalSku', Header: 'Item Master External SKU' },
                            { accessor: 'packagingMasterSku', Header: 'Packaging Master SKU' },
                            { accessor: 'name', Header: 'Name' },
                            {
                                accessor: 'qty',
                                Header: 'Quantity',
                                editable: true,
                                editProps: { type: 'input', inputType: 'number' },
                            },
                            {
                                accessor: 'uom',
                                Header: 'UOM',
                                editable: true,
                                editProps: { type: 'select', options: ['ct', 'oz', 'lb'] },
                            },
                            {
                                accessor: 'remove',
                                Header: 'Remove',
                                Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                                    return (
                                        <Button
                                            id={`Remove_${row.id}`}
                                            onClick={() => {
                                                if (window.confirm('Remove item from bom?')) {
                                                    removeItemFromBOM(row.original.id);
                                                }
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    );
                                },
                            },
                        ]}
                        saveEdit={async ({ id, field, value }) => {
                            let res = await api.updateBomIngredient(bom.id, id, { [field]: value });
                            if (!res.status) {
                                setNotification({
                                    text: 'Failed to update BOM ingredient. ' + res.msg,
                                    level: 'error',
                                });
                            }
                            return res.status;
                        }}
                        data={bom.ingredients}
                    />
                    <FormComponent
                        formFields={bomIngredientFormFields}
                        onSubmit={async (formData, resetForm) => {
                            addItemToBOM(formData);
                        }}
                        button={{
                            text: 'Add',
                            gridValue: 1,
                            disabled: (formData) => {
                                if (!formData.itemMasterExternalSku && !formData.packagingMasterSku) {
                                    return true;
                                }
                                return false;
                            },
                        }}
                    />
                </>
            )}
        </div>
    );
};

let ViewItemLevelTwo = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [taxonomy, setTaxonomy] = useState({ l1: [], l2: [], l3: [], l4: [] });
    let [tempZones, setTempZones] = useState({ shipping: [], storage: [], receiving: [] });
    let [typeOptions, setTypeOptions] = useState([]);

    const getTempZones = async () => {
        let res = await api.getItemTempZones();
        if (!res.status) {
            setNotification({ text: 'Failed to get item temp zones', level: 'error' });
            return;
        }

        setTempZones(res.data);
    };

    const getItemLevelTwoTypes = async () => {
        let res = await api.getItemLevelTwoTypes();
        if (!res.status) {
            setNotification({ text: 'Failed to get IL2 types', level: 'error' });
            return;
        }

        setTypeOptions(res.data.types);
    };

    const getTaxonomyOptions = (row, level) => {
        let parentID = level > 1 ? row[`l${level - 1}TaxonomyID`] : 0;
        let opts = taxonomy[`l${level}`]
            .filter((item) => item.parentID === parentID)
            .map((item) => ({ text: item.name, value: item.id }));
        opts.unshift({ text: '', value: null });
        return opts;
    };

    let columns = [
        { accessor: 'id', Header: 'Item Level 2 ID' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'name', Header: 'Name', editable: true },
        { accessor: 'type', Header: 'Type', editable: true, editProps: { type: 'select', options: typeOptions } },
        { accessor: 'unitsPerRPC', Header: 'Units per RPC', editable: true },
        {
            accessor: 'storageTempZone',
            Header: 'Storage Temp Zone',
            editable: true,
            editProps: { type: 'select', options: tempZones.storage },
        },
        {
            accessor: 'shippingTempZone',
            Header: 'Shipping Temp Zone',
            editable: true,
            editProps: { type: 'select', options: tempZones.shipping },
        },
        { accessor: 'cubeLevelPerBaseUnit', Header: 'Cube Level Per Base Unit', editable: true },
        {
            accessor: 'baseUnitSize',
            Header: 'Base Unit Size',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'baseUnitUOM',
            Header: 'Base Unit UOM',
            editable: true,
            editProps: { type: 'select', options: ['oz', 'ct', 'lb'] },
        },
        {
            accessor: 'l1TaxonomyID',
            Header: 'Category One',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => getTaxonomyOptions(row, 1),
            },
        },
        {
            accessor: 'l2TaxonomyID',
            Header: 'Category Two',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => getTaxonomyOptions(row, 2),
            },
        },
        {
            accessor: 'l3TaxonomyID',
            Header: 'Category Three',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => getTaxonomyOptions(row, 3),
            },
        },
        {
            accessor: 'l4TaxonomyID',
            Header: 'Category Four',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) => getTaxonomyOptions(row, 4),
            },
        },
        { accessor: 'productionMethod', Header: 'OG/CV' },
        { accessor: 'active', Header: 'Active', type: 'checkbox', editable: true, editProps: { type: 'checkbox' } },
    ];

    // Load
    let getItems = async () => {
        let response = await api.getItemLevelTwo();
        if (response.status === false) {
            setNotification({ text: 'No items found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        setRows(response.data.rows);
    };

    let getTaxonomy = async () => {
        let promises = [1, 2, 3, 4].map((lvl) => api.getTaxonomyByLevel(lvl, 'TAXONOMY'));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setTaxonomy({
                l1: res[0].data.categories,
                l2: res[1].data.categories,
                l3: res[2].data.categories,
                l4: res[3].data.categories,
            });
        }
    };
    useEffect(() => {
        getItems();
        getTaxonomy();
        getTempZones();
        getItemLevelTwoTypes();
    }, []);

    return (
        <div>
            <h1>Item Level Two</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value, row }) => {
                        let item = {};
                        item[field] = value;
                        if (field === 'active') {
                            item[field] = value ? 't' : 'f';
                        }

                        let response;
                        if (
                            [
                                'l1TaxonomyID',
                                'l2TaxonomyID',
                                'l3TaxonomyID',
                                'l4TaxonomyID',
                                'storageTempZone',
                                'shippingTempZone',
                                'cubeLevelPerBaseUnit',
                                'baseUnitSize',
                                'baseUnitUOM',
                            ].includes(field)
                        ) {
                            let formData = new FormData();
                            formData.append(field, value);
                            formData.append('externalSku', row.externalSku);
                            response = await api.updateItemInformation(formData);
                        } else {
                            response = await api.updateItemLevelTwo(id, item);
                        }

                        if (response.status === false) {
                            let message = 'Error modifying item level 2';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            await getItems();
                            setNotification({ text: 'Item modified!', level: 'success' });
                        }
                        return response.status;
                    }}
                    expandable={true}
                    ExpansionComponent={<ViewBomForItemLevelTwo columns={columns} setNotification={setNotification} />}
                />
            </div>
        </div>
    );
};

export { ViewItemLevelTwo };
