import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { PartnershipsPagination } from '../../../shared';
import '../createList.css';

const PackGroupList = ({
    handleRemoveItem,
    packGroupID,
    handleRepositionItem,
    handleUpdateQuantity,
    showItemDetails,
    positions = [],
}) => {
    const [page, setPage] = useState(0);
    const rowsPerPage = 8;

    return (
        <>
            <div>
                {positions
                    .sort((a, b) => (a.position < b.position ? -1 : 1))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => {
                        return (
                            <div key={item.position} className="slot-list-item grotesk-regular">
                                <select
                                    value={item.position}
                                    onChange={(e) => {
                                        handleRepositionItem(packGroupID, item.position, e.target.value);
                                    }}
                                >
                                    {positions.map((p) => {
                                        return (
                                            <option key={p.position} value={p.position}>
                                                {p.position}
                                            </option>
                                        );
                                    })}
                                </select>
                                <div
                                    onClick={() => {
                                        showItemDetails(item);
                                    }}
                                >
                                    <a className="clickable">{item.name}</a>
                                </div>
                                <div>
                                    <span
                                        onClick={() => {
                                            if (item.qty === 1) {
                                                handleRemoveItem(packGroupID, item);
                                            } else {
                                                handleUpdateQuantity(packGroupID, item, item.qty - 1);
                                            }
                                        }}
                                    >
                                        {' '}
                                        <RemoveRoundedIcon className="slot-list-item-icon" />{' '}
                                    </span>
                                    &nbsp;&nbsp;
                                    {item.qty}
                                    &nbsp;&nbsp;
                                    <span
                                        onClick={() => {
                                            handleUpdateQuantity(packGroupID, item, item.qty + 1);
                                        }}
                                    >
                                        <AddRoundedIcon className="slot-list-item-icon" />
                                    </span>
                                </div>
                                <div>${(item.price * item.qty).toFixed(2)}</div>
                                <div
                                    onClick={() => {
                                        handleRemoveItem(packGroupID, item);
                                    }}
                                >
                                    <DeleteIcon className="partnerships-gray-icon" />
                                </div>
                            </div>
                        );
                    })}
            </div>
            <PartnershipsPagination
                count={positions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(page) => setPage(page)}
            />
        </>
    );
};

export { PackGroupList };
