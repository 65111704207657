import React, { useState } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { marketingApi as api } from '../../../api/marketing';

const UploadVipCustomers = () => {
    let [notification, setNotification] = useState({ text: null, level: null });

    let formFields = [
        {
            name: 'customerFile',
            inputElement: 'file',
            label: 'Subscription IDs',
            gridValue: 6,
            inputProps: { required: true },
        },
    ];

    return (
        <div>
            <h2>Upload VIP Customers (CSV file needs header of subscription_id)</h2>
            <Notifications options={notification} />
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    let response = await api.uploadVipCustomers(formData.customerFile);
                    if (response.status === false) {
                        setNotification({ text: 'Unable to upload file ' + response.msg, level: 'error' });
                        return;
                    }

                    setNotification({ text: 'Success!', level: 'success' });
                    resetForm();
                }}
            />
        </div>
    );
};

export { UploadVipCustomers };
