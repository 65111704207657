import React from 'react';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { catalogApi as api } from '../../../api/catalog';

let ItemInformationUpload = () => {
    let columns = [
        { Header: 'External sku', accessor: 'externalSku' },
        { Header: 'Action', accessor: 'action' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Description', accessor: 'description' },
        { Header: 'Allergens', accessor: 'allergens' },
        { Header: 'Cross Allergens', accessor: 'crossAllergens' },
        { Header: 'Ingredients', accessor: 'ingredients' },
        { Header: 'Aisle One (L1;L2)', accessor: 'aisleOne' },
        { Header: 'Aisle Two (L1;L2)', accessor: 'aisleTwo' },
        { Header: 'Aisle Three (L1;L2)', accessor: 'aisleThree' },
        { Header: 'Vanity Pad Name', accessor: 'vanityPadName' },
        { Header: 'Tags', accessor: 'tags' },
        { Header: 'Brand', accessor: 'brand' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.confirmItemUpload(key);
        return res;
    };

    let uploadItemInformation = async (formData) => {
        let res = await api.uploadItemInformation(formData);
        return res;
    };

    let downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,External Sku,Action,Name,';
        csvContent += 'Description,Allergens,Cross allergens (may contain),Ingredients,AisleOne(L1;L2)';
        csvContent += ',AisleTwo(L1;L2),AisleThree(L1;L2),VanityPadName,Tags(Tag1;Tag2;..;Tag12),Brand';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'item_information_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <UploadCSVAndReviewComponent
                title="Upload Item Information"
                fileLabel="Item File"
                columns={columns}
                upload={uploadItemInformation}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
                showConfirmationModal={true}
                confirmationModalKeyName={'External SKU'}
            />
        </div>
    );
};

export { ItemInformationUpload };
