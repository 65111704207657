import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { packagingApi as api } from '../../../api/packaging';
import { UserContext } from '../../../contexts/UserContext';

let ViewAutoAssignPackaging = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [insulationOpts, setInsulationOpts] = useState([]);
    const user = useContext(UserContext);

    // Load
    let getRows = async () => {
        let response = await api.getAutoAssignPackaging(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No auto assign config found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        setRows(response.data.rows);
    };

    let getInsulation = async () => {
        let response = await api.getInsulation();

        if (response.status === false) {
            setNotification({ text: 'No insulation found ' + response.msg, level: 'error' });
            setInsulationOpts([]);
            return;
        }

        let linersAsOpts = response.data.rows.map((row) => {
            row.text = row.itemNameInternal;
            row.value = row.id;
            return { text: row.itemNameInternal, value: row.id, cubeLevelID: row.cubeLevelID };
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setInsulationOpts([pleaseSelect].concat(linersAsOpts));
    };

    useEffect(() => {
        getRows();
        getInsulation();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'cubeLevel', Header: 'Box Size' },
        { accessor: 'minHeat', Header: 'Min Heat Index', editable: true },
        { accessor: 'maxHeat', Header: 'Max Heat Index', editable: true },
        {
            accessor: 'liner',
            Header: 'Insulation',
            editable: true,
            editProps: {
                type: 'select',
                options: (row) =>
                    insulationOpts.filter((opt) => opt.cubeLevelID === row.cubeLevelID || opt.value === 0),
            },
        },
        { accessor: 'numIce', Header: 'Ice Packs', editable: true },
    ];

    return (
        <div>
            <h1>Auto Assign Packaging Config</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        let update = {};
                        update[field] = value;

                        let response = await api.updateAutoAssignConfig(id, update);
                        if (response.status === false) {
                            let message = 'Error modifying config';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Config modified!', level: 'success' });
                            getRows();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export { ViewAutoAssignPackaging };
