import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@material-ui/core';
import { BulkCreateLocations } from '../components/bulkCreateLocations.js';
import { ViewLocations } from '../components/viewLocations.js';
import { TabPanel } from '../../../shared';
import { UserContext } from '../../../contexts/UserContext';
import { warehouseApi as api } from '../../../api/warehouse';

const LocationsV2 = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };
    let [v2Locations, setV2Locations] = useState(false);

    const user = useContext(UserContext);

    let getFacilities = async () => {
        let response = await api.getFacilities();
        if (response.status === false) {
            return;
        }

        let facilities = response.data.rows;
        for (let facility of facilities) {
            if (facility.id === user.getFacilityID()) {
                setV2Locations(facility.v2Locations);
            }
        }
    };

    useEffect(() => {
        getFacilities();
    }, []);

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="View Locations" value="0" />
                <Tab label="Create Locations" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewLocations v2Locations={v2Locations} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <BulkCreateLocations v2Locations={v2Locations} />
            </TabPanel>
        </Paper>
    );
};

export { LocationsV2 };
