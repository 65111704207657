import React, { useState, useEffect, useRef, useContext } from 'react';
import { Notifications, DataTable, FacilityPicker, FBMAccountPicker } from '../../../shared';
import { fbmApi as api } from '../../../api/fbm';
import { shippingApi } from '../../../api/shipping';
import { UserContext } from '../../../contexts/UserContext';
import { Button, Grid } from '@material-ui/core';

let ViewFBMShipmentHospital = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const [facilityID, setFacilityID] = useState(null);
    const { user, fbmAccount } = useContext(UserContext);

    // Load rows
    let getRows = async () => {
        let response = await api.getHospitalShipments(facilityID, fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        if (!!user.id && !user.fbm) {
            setFacilityID(user.facilityID);
        }
    }, [user]);

    useEffect(() => {
        if (!!facilityID && !!fbmAccount.id) {
            getRows();
        }
    }, [facilityID, fbmAccount]);

    let purchaseShipment = async (id) => {
        let res = await api.purchaseShipment(id, fbmAccount.fbmAccountName);
        if (res.status) {
            getRows();
            setNotification({ text: 'Order ready for packing!', level: 'success' });
        } else {
            setNotification({ text: 'Failed: ' + res.msg, level: 'error' });
        }
    };

    let cols = [
        { accessor: 'id', Header: 'Misfits Shipment ID' },
        { accessor: 'createdAt', Header: `Created at` },
        { accessor: 'fbmOrderNum', Header: 'Order Number' },
        {
            accessor: 'purchase',
            Header: 'Retry',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                let [purchasing, setPurchasing] = useState(false);
                return (
                    <Button
                        disabled={purchasing}
                        onClick={async () => {
                            setPurchasing(true);
                            await purchaseShipment(row.original.id);
                            setPurchasing(false);
                        }}
                    >
                        Retry
                    </Button>
                );
            },
        },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item xs={12}>
                <FBMAccountPicker />
            </Grid>
            {!!fbmAccount.id && (
                <>
                    <Grid item md={6} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                        <h1>{fbmAccount.companyName} Hospital Shipments</h1>
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', paddingRight: '35px' }}>
                        {user.fbm && (
                            <FacilityPicker
                                defaultValue={fbmAccount.facilityIDs[0]}
                                whitelistedFacilities={fbmAccount.facilityIDs}
                                activeOnly={true}
                                onChange={(facilityID, text, facility) => {
                                    setFacilityID(facilityID);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable columns={cols} data={rows} />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export { ViewFBMShipmentHospital };
