import moment from 'moment';
import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    Tabs,
    Tab,
    Paper,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
} from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent, TabPanel } from '../../../shared';
import { procurementApi as procurementApi } from '../../../api/procurement';
import { inboundTruckApi as inboundTruckApi } from '../../../api/inboundTrucks';
import { qualityApi as api } from '../../../api/quality';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../../../contexts/UserContext';

// Modal to upload truck temperatures and summary PDF
const TruckTemperaturesModal = ({ rowData, closeModal }) => {
    let [notification, setNotification] = useState({ level: null, text: null });
    let [truckID, setTruckID] = useState(rowData.original.truckID);
    let [truck, setTruck] = useState(null);

    const user = useContext(UserContext);

    const formFields = [
        { name: 'temperatures', inputElement: 'file', label: 'Truck Temperatures CSV', accept: ['.csv'] },
        { name: 'summary', inputElement: 'file', label: 'Summary PDF', accept: ['.pdf'] },
    ];

    const submitTruckTemperatures = async (formData, resetForm) => {
        if (!formData.temperatures || !formData.summary) {
            setNotification({ text: 'Temperature CSV and Summary PDF are both required', level: 'error' });
            return false;
        }
        let response = await inboundTruckApi.uploadTruckTemperatures(truckID, formData);
        if (response.status === false) {
            let message = 'Error uploading truck temperatures';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            closeModal();
            setNotification({ text: 'Success', level: 'success' });
        }
        return response.status;
    };

    const getTruckInfo = async () => {
        let response = await inboundTruckApi.getTruckDetails(truckID, user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No truck found ' + response.msg, level: 'error' });
            return;
        }

        setTruck(response.data.output.model);
    };

    useEffect(() => {
        getTruckInfo();
    }, []);

    return (
        <Dialog fullWidth={true} maxWidth="md" open={true} onClose={closeModal}>
            <DialogContent>
                <Grid container>
                    <Notifications options={notification} />
                    {truck && (
                        <>
                            <Grid item xs={7}>
                                <h2>Truck {truckID}</h2>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Created At</td>
                                            <td>
                                                {moment(truck.createdAt.date + truck.createdAt.timezone)
                                                    .local()
                                                    .format('MM/DD/YYYY h:mm a')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Carrier</td>
                                            <td>{truck.inboundCarrierName}</td>
                                        </tr>
                                        <tr>
                                            <td>Freight Cost</td>
                                            <td>{truck.freightCost}</td>
                                        </tr>
                                        <tr>
                                            <td>Additional Shipping Cost</td>
                                            <td>{truck.additionalShipCost}</td>
                                        </tr>
                                        <tr>
                                            <td>Additional Pallet Cost</td>
                                            <td>{truck.additionalPalletCost}</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>{truck.type}</td>
                                        </tr>
                                        <tr>
                                            <td>Min Temperature</td>
                                            <td>{truck.minTemperature}</td>
                                        </tr>
                                        <tr>
                                            <td>Max Temperature</td>
                                            <td>{truck.maxTemperature}</td>
                                        </tr>
                                        <tr>
                                            <td>Ship List</td>
                                            <td>
                                                <Button
                                                    onClick={() => {
                                                        inboundTruckApi.downloadTruckShipList(truck.id);
                                                    }}
                                                >
                                                    Download
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Temperature Summary</td>
                                            <td>
                                                {truck.tempSummaryPdfUrl ? (
                                                    <Button
                                                        onClick={() => {
                                                            inboundTruckApi.downloadTruckTempSummary(truck.id);
                                                        }}
                                                    >
                                                        Download
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                            <Grid item xs={5}>
                                <h2>Upload Truck Temperatures</h2>
                                <FormComponent formFields={formFields} onSubmit={submitTruckTemperatures} />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const DirectedReceivingItemModal = ({ rowData, closeModal, setNotification, issueCodes, editEnabled }) => {
    let [error, setError] = useState(null);
    let [pageIdx, setPageIdx] = useState(rowData.initialPageIdx);
    let [poMapID, setPoMapID] = useState(rowData.poMapID);
    let [poID, setPoID] = useState(rowData.poID);
    let [qualityReceivingData, setQualityReceivingData] = useState(rowData.qualityReceivingData);
    let [selectedInternalInspectionTab, setSelectedInternalInspectionTab] = useState('0');

    let handleInternalInspectionTabChange = (event, value) => {
        setSelectedInternalInspectionTab(value);
    };

    useEffect(() => {
        // we dont need the audit count page if it's not purchased by weight and sold by count
        if (
            rowData.initialPageIdx == 0 &&
            !(
                qualityReceivingData.purchasingCaseUom.toLowerCase() == 'lb' &&
                qualityReceivingData.baseUnitUom.toLowerCase() == 'ct'
            )
        ) {
            setPageIdx(1);
        } else if (
            qualityReceivingData.purchasingCaseUom.toLowerCase() == 'lb' &&
            qualityReceivingData.baseUnitUom.toLowerCase() == 'ct' &&
            qualityReceivingData.caseAuditCountComplete == false
        ) {
            setPageIdx(0);
        } else {
            setPageIdx(rowData.initialPageIdx);
        }
    }, []);

    let auditCountBaseUnder100Columns = [
        {
            accessor: 'case1AuditCount',
            Header: 'Case 1 Count',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'case2AuditCount',
            Header: 'Case 2 Count',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'case3AuditCount',
            Header: 'Case 3 Count',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
    ];

    let auditCountBaseOver100Columns = [
        {
            accessor: 'case1AuditCount',
            Header: 'Case 1 Weight (4 units in oz)',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'case2AuditCount',
            Header: 'Case 2 Weight (4 units in oz)',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'case3AuditCount',
            Header: 'Case 3 Weight (4 units in oz)',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
    ];

    let getExternalSummaryTableData = () => {
        let formattedSummaryData = {};
        Object.keys(qualityReceivingData.externalIssues).forEach((key) => {
            let issues = qualityReceivingData.externalIssues[key].issues;
            issues.forEach((issue) => {
                if (!formattedSummaryData[issue.issueType]) {
                    formattedSummaryData[issue.issueType] = {
                        cases: 0,
                        units: 0,
                    };
                }
                formattedSummaryData[issue.issueType].cases++;
                formattedSummaryData[issue.issueType].units += parseFloat(issue.unitsDamaged);
            });
        });

        let formattedDataArray = [];
        Object.keys(formattedSummaryData).forEach((key) => {
            formattedDataArray.push({
                id: key,
                issueType: key,
                casesInspected: formattedSummaryData[key].cases,
                unitsDamaged: formattedSummaryData[key].units,
            });
        });

        return formattedDataArray;
    };

    let summaryDataTableCols = [
        { accessor: 'issueType', Header: 'Damage Reason', editable: false, useFilter: false, sortable: false },
        { accessor: 'casesInspected', Header: 'Number of Cases', editable: false, useFilter: false, sortable: false },
        { accessor: 'unitsDamaged', Header: 'Unit Qty', editable: false, useFilter: false, sortable: false },
    ];

    let getInternalSummaryTableData = () => {
        let formattedSummaryData = {};
        Object.keys(qualityReceivingData.internalIssues).forEach((key) => {
            let issues = qualityReceivingData.internalIssues[key].issues;
            issues.forEach((issue) => {
                if (!formattedSummaryData[issue.issueType]) {
                    formattedSummaryData[issue.issueType] = {
                        cases: 0,
                        units: 0,
                    };
                }
                formattedSummaryData[issue.issueType].cases++;
                formattedSummaryData[issue.issueType].units += parseFloat(issue.unitsDamaged);
            });
        });

        let formattedDataArray = [];
        Object.keys(formattedSummaryData).forEach((key) => {
            formattedDataArray.push({
                id: key,
                issueType: key,
                casesInspected: formattedSummaryData[key].cases,
                unitsDamaged: formattedSummaryData[key].units,
            });
        });

        return formattedDataArray;
    };
    let photoCodes = [
        { text: 'FRONT', value: 'FRONT' },
        { text: 'SIDE', value: 'SIDE' },
        { text: 'PULP_TEMP', value: 'PULP_TEMP' },
        { text: 'UPC', value: 'UPC' },
        { text: 'INGREDIENT_LABEL', value: 'INGREDIENT_LABEL' },
        { text: 'EXPIRATION_PRODUCTION_DATE', value: 'EXPIRATION_PRODUCTION_DATE' },
        { text: 'CASE_PHOTO', value: 'CASE_PHOTO' },
        { text: 'QUALITY_DEFECT', value: 'QUALITY_DEFECT' },
        { text: 'OTHER', value: 'OTHER' },
    ];

    let photoFormFields = [
        {
            name: 'poID',
            initialValue: poID,
            inputElement: 'textField',
            label: 'PO ID',
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'poMapID',
            initialValue: poMapID,
            inputElement: 'textField',
            label: 'PO Map ID',
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'notes',
            inputElement: 'select',
            label: 'Notes',
            gridValue: 3,
            inputProps: { required: true, opts: photoCodes },
        },
        { name: 'document', inputElement: 'file', gridValue: 3, label: 'Document', accept: ['.png', '.jpeg', '.pdf'] },
    ];

    let getExternalUnitsDamagedPercent = () => {
        let unitsDamaged = 0;
        let unitsInspected = 0;
        Object.keys(qualityReceivingData.externalIssues).forEach((key) => {
            unitsInspected += qualityReceivingData.externalIssues[key].unitsInspected;
            let issues = qualityReceivingData.externalIssues[key].issues;
            issues.forEach((issue) => {
                unitsDamaged += parseFloat(issue.unitsDamaged);
            });
        });
        if (unitsInspected == 0) {
            return 0;
        }
        return unitsDamaged / unitsInspected;
    };

    let getInternalUnitsDamagedPercent = () => {
        let unitsDamaged = 0;
        let unitsInspected = 0;
        Object.keys(qualityReceivingData.internalIssues).forEach((key) => {
            unitsInspected += qualityReceivingData.internalIssues[key].unitsInspected;
            let issues = qualityReceivingData.internalIssues[key].issues;
            issues.forEach((issue) => {
                unitsDamaged += parseFloat(issue.unitsDamaged);
            });
        });
        if (unitsInspected == 0) {
            return 0;
        }
        return unitsDamaged / unitsInspected;
    };

    let auditCountHeader = qualityReceivingData.baseUnitsPerCase >= 100 ? 'Weights' : 'Case Unit Counts';

    let inspectionTable = [
        {
            id: 'pallets',
            label: '# of PALLETS Inspected',
            value: qualityReceivingData.palletsInspected,
        },
        {
            id: 'cases',
            label: '# of CASES Inspected',
            value: qualityReceivingData.casesInspected,
        },
    ];

    let inspectionTableInternal = [
        {
            id: 'pallets',
            label: '# of PALLETS Inspected',
            value: qualityReceivingData.palletsInspected,
        },
        {
            id: 'cases',
            label: '# of CASES Inspected',
            value: qualityReceivingData.casesInspected,
        },
        {
            id: 'pulpTemp',
            label: 'Pulp Temp(˚F)',
            value: qualityReceivingData.levelTwoPulpTemp,
        },
    ];

    let inspectionCols = [
        { accessor: 'label', Header: '', editable: false, useFilter: false, sortable: false },
        { accessor: 'value', Header: '', editable: editEnabled, useFilter: false, sortable: false },
    ];

    return (
        <Dialog fullWidth={true} maxWidth="md" open={true} onClose={closeModal}>
            {(pageIdx == 1 || pageIdx == 2) && (
                <DialogTitle
                    style={{
                        backgroundColor: pageIdx == 1 ? '#3F51B5' : '#9F0000',
                        color: 'white',
                        textAlign: 'center',
                    }}
                >
                    {pageIdx == 1 ? 'External Inspection' : 'Internal Inspection'}
                </DialogTitle>
            )}

            <DialogContent>
                <div
                    style={{
                        outline: 0,
                        backgroundColor: 'white',
                        width: '700px',
                        margin: '10% auto',
                        padding: '10px',
                    }}
                >
                    {pageIdx == 0 && (
                        <div>
                            <h4>{auditCountHeader}</h4>
                            <DataTable
                                data={[qualityReceivingData]}
                                columns={
                                    qualityReceivingData.baseUnitsPerCase >= 100
                                        ? auditCountBaseOver100Columns
                                        : auditCountBaseUnder100Columns
                                }
                                editable={true}
                                showPagination={false}
                                saveEdit={async ({ id, field, value }) => {
                                    let updates = {
                                        key: field,
                                        value: value,
                                    };
                                    let response = await api.recordQualityReceivingV2(id, updates);
                                    if (response.status === false) {
                                        let message = 'Error';
                                        if (response) {
                                            message += ': ' + response.msg;
                                        }
                                        setNotification({ text: message, level: 'error' });
                                    } else {
                                        setNotification({ text: 'Audit Count Recorded!', level: 'success' });
                                        setQualityReceivingData(response.data.itemInspected);
                                    }
                                }}
                            />
                            <div>
                                <Button
                                    style={{ float: 'center' }}
                                    onClick={async () => {
                                        let response = await api.recordQualityReceivingCompleteV2(poMapID);
                                        if (response.status === false) {
                                            let message = 'Error';
                                            if (response) {
                                                message += ': ' + response.msg;
                                            }
                                            setNotification({ text: message, level: 'error' });
                                        } else {
                                            setNotification({ text: 'Audit count completed', level: 'success' });
                                            if (response.data.hasVarianceError == true) {
                                                setNotification({
                                                    text: 'Warning: Receiving with discrepant input audits',
                                                    level: 'warning',
                                                });
                                            }
                                            setQualityReceivingData(response.data.itemInspected);
                                        }
                                    }}
                                >
                                    Finish Case Count Audit
                                </Button>
                                {editEnabled && (
                                    <Button
                                        style={{ float: 'right' }}
                                        disabled={qualityReceivingData.caseAuditCountComplete == false}
                                        onClick={() => {
                                            let newPageIdx = pageIdx + 1;
                                            setPageIdx(newPageIdx);
                                        }}
                                    >
                                        Next: External Issues
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                    {pageIdx == 1 && (
                        <div>
                            <div>
                                <h3>% ITEMS DAMAGED: {(getExternalUnitsDamagedPercent() * 100).toFixed(2)}</h3>
                            </div>
                            <DataTable
                                data={inspectionTable}
                                columns={inspectionCols}
                                editable={editEnabled}
                                showPagination={false}
                                saveEdit={async ({ id, field, value }) => {
                                    if (id == 'cases') {
                                        let response = await api.recordCasesInspected(qualityReceivingData.id, value);
                                        if (response.status === false) {
                                            let message = 'Error';
                                            if (response) {
                                                message += ': ' + response.msg;
                                            }
                                            setNotification({ text: message, level: 'error' });
                                        } else {
                                            setNotification({ text: 'Cases Inspected recorded', level: 'success' });
                                            setQualityReceivingData(response.data.itemInspected);
                                            return;
                                        }
                                    } else if (id == 'pallets') {
                                        let response = await api.recordPalletsInspected(qualityReceivingData.id, value);
                                        if (response.status === false) {
                                            let message = 'Error';
                                            if (response) {
                                                message += ': ' + response.msg;
                                            }
                                            setNotification({ text: message, level: 'error' });
                                        } else {
                                            setNotification({ text: 'Pallets Inspected recorded', level: 'success' });
                                            setQualityReceivingData(response.data.itemInspected);
                                            return;
                                        }
                                    }
                                }}
                            />
                            <DataTable
                                data={getExternalSummaryTableData()}
                                columns={summaryDataTableCols}
                                editable={true}
                                showPagination={false}
                                saveEdit={async ({ id, field, value }) => {
                                    let updates = {
                                        key: field,
                                        value: value,
                                    };
                                    if (id == '') {
                                        id = 0;
                                    }
                                    let response = await api.recordQualityReceivingIssueV2(
                                        id,
                                        updates,
                                        poMapID,
                                        'external'
                                    );
                                    if (response.status === false) {
                                        let message = 'Error';
                                        if (response) {
                                            message += ': ' + response.msg;
                                        }
                                        setNotification({ text: message, level: 'error' });
                                    } else {
                                        setNotification({ text: 'External issue recorded!', level: 'success' });
                                        setQualityReceivingData(response.data.itemInspected);
                                    }
                                }}
                            />
                            {editEnabled && (
                                <Button
                                    style={{ float: 'right', color: 'white', backgroundColor: '#3F51B5' }}
                                    onClick={() => {
                                        let newPageIdx = pageIdx + 1;
                                        setPageIdx(newPageIdx);
                                    }}
                                >
                                    Next Step
                                </Button>
                            )}
                        </div>
                    )}
                    {pageIdx == 2 && (
                        <div>
                            <Paper>
                                <Tabs
                                    onChange={handleInternalInspectionTabChange}
                                    value={selectedInternalInspectionTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Summary" value="0" />
                                    <Tab label="5 Unit Inspection" value="1" />
                                </Tabs>
                                <TabPanel selectedTab={selectedInternalInspectionTab} index={'0'}>
                                    <div>
                                        <h3>% ITEMS DAMAGED: {(getInternalUnitsDamagedPercent() * 100).toFixed(2)}</h3>
                                    </div>
                                    <DataTable
                                        data={inspectionTableInternal}
                                        columns={inspectionCols}
                                        editable={editEnabled}
                                        showPagination={false}
                                        saveEdit={async ({ id, field, value }) => {
                                            if (id == 'cases') {
                                                let response = await api.recordCasesInspected(
                                                    qualityReceivingData.id,
                                                    value
                                                );
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({
                                                        text: 'Cases Inspected recorded',
                                                        level: 'success',
                                                    });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                    return;
                                                }
                                            } else if (id == 'pallets') {
                                                let response = await api.recordPalletsInspected(
                                                    qualityReceivingData.id,
                                                    value
                                                );
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({
                                                        text: 'Pallets Inspected recorded',
                                                        level: 'success',
                                                    });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                    return;
                                                }
                                            } else if (id == 'pulpTemp') {
                                                let updates = {
                                                    key: 'levelTwoPulpTemp',
                                                    value: value,
                                                };
                                                let response = await api.recordQualityReceiving(id, updates);
                                                if (response.status === false) {
                                                    let message = 'Error';
                                                    if (response) {
                                                        message += ': ' + response.msg;
                                                    }
                                                    setNotification({ text: message, level: 'error' });
                                                } else {
                                                    setNotification({ text: 'Pulp temp recorded', level: 'success' });
                                                    setQualityReceivingData(response.data.itemInspected);
                                                }
                                            }
                                        }}
                                    />
                                    <DataTable
                                        data={getInternalSummaryTableData()}
                                        columns={summaryDataTableCols}
                                        editable={true}
                                        showPagination={false}
                                        saveEdit={async ({ id, field, value }) => {
                                            let updates = {
                                                key: field,
                                                value: value,
                                            };
                                            if (id == '') {
                                                id = 0;
                                            }
                                            let response = await api.recordQualityReceivingIssueV2(
                                                id,
                                                updates,
                                                poMapID,
                                                'external'
                                            );
                                            if (response.status === false) {
                                                let message = 'Error';
                                                if (response) {
                                                    message += ': ' + response.msg;
                                                }
                                                setNotification({ text: message, level: 'error' });
                                            } else {
                                                setNotification({ text: 'External issue recorded!', level: 'success' });
                                                setQualityReceivingData(response.data.itemInspected);
                                            }
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel selectedTab={selectedInternalInspectionTab} index={'1'}>
                                    <Grid container>
                                        {qualityReceivingData.internalUnitInspection &&
                                            Object.keys(qualityReceivingData.internalUnitInspection).map((key) => {
                                                return (
                                                    <Grid item xs={3} key={key}>
                                                        <FormControl>
                                                            <InputLabel>{`Unit ${key}`}</InputLabel>
                                                            <Select
                                                                value={qualityReceivingData.internalUnitInspection[key]}
                                                                disabled={!editEnabled}
                                                                onChange={(e) => {
                                                                    if (!editEnabled) {
                                                                        return;
                                                                    }
                                                                    let res = api.recordQualityUnitInspection(
                                                                        qualityReceivingData.id,
                                                                        { index: key, value: e.target.value }
                                                                    );
                                                                    if (!res.status) {
                                                                        let message = 'Error';
                                                                        if (res) {
                                                                            message += ': ' + res.msg;
                                                                        }
                                                                        setNotification({
                                                                            text: message,
                                                                            level: 'error',
                                                                        });
                                                                        return;
                                                                    }
                                                                    setQualityReceivingData(res.data.itemInspected);
                                                                    setNotification({
                                                                        text: 'Unit Inspection recorded',
                                                                        level: 'success',
                                                                    });
                                                                }}
                                                            >
                                                                {issueCodes.map((code) => {
                                                                    return (
                                                                        <MenuItem
                                                                            disabled={!editEnabled}
                                                                            value={code}
                                                                            key={code}
                                                                        >
                                                                            {code}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </TabPanel>
                            </Paper>
                            {editEnabled && (
                                <Button
                                    style={{ float: 'right', backgroundColor: '#9F0000', color: 'white' }}
                                    onClick={() => {
                                        let newPageIdx = pageIdx + 1;
                                        setPageIdx(newPageIdx);
                                    }}
                                >
                                    Next Step
                                </Button>
                            )}
                        </div>
                    )}
                    {pageIdx == 3 && (
                        <div>
                            <FormComponent
                                formFields={photoFormFields}
                                onSubmit={async (formData) => {
                                    if (!formData.document) {
                                        setError('Document is required');
                                        return false;
                                    }

                                    let response = await procurementApi.uploadPODocument(poID, formData);

                                    if (response.status === false) {
                                        setError(response.msg);
                                        return;
                                    }

                                    closeModal();
                                }}
                            />
                        </div>
                    )}
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    {}
                </div>
            </DialogContent>
        </Dialog>
    );
};

//modal for documentation of counts and issues
const ItemDocumentationModal = ({ rowData, closeModal, setNotification, issueCodes }) => {
    let [error, setError] = useState(null);
    let [pageIdx, setPageIdx] = useState(rowData.initialPageIdx);
    let [poMapID, setPoMapID] = useState(rowData.poMapID);
    let [varianceError, setVarianceError] = useState(false);
    let [poID, setPoID] = useState(rowData.poID);
    let [qualityReceivingData, setQualityReceivingData] = useState(rowData.qualityReceivingData);
    let [issueOpts, setIssueOpts] = useState(issueCodes);

    useEffect(() => {
        // we dont need the audit count page if it's not purchased by weight and sold by count
        if (
            rowData.initialPageIdx == 0 &&
            !(
                qualityReceivingData.purchasingCaseUom.toLowerCase() == 'lb' &&
                qualityReceivingData.baseUnitUom.toLowerCase() == 'ct'
            )
        ) {
            setPageIdx(1);
        } else if (
            qualityReceivingData.purchasingCaseUom.toLowerCase() == 'lb' &&
            qualityReceivingData.baseUnitUom.toLowerCase() == 'ct' &&
            qualityReceivingData.caseAuditCountComplete == false
        ) {
            setPageIdx(0);
        } else {
            setPageIdx(rowData.initialPageIdx);
        }
    }, []);

    let photoCodes = [
        { text: 'FRONT', value: 'FRONT' },
        { text: 'SIDE', value: 'SIDE' },
        { text: 'PULP_TEMP', value: 'PULP_TEMP' },
        { text: 'UPC', value: 'UPC' },
        { text: 'INGREDIENT_LABEL', value: 'INGREDIENT_LABEL' },
        { text: 'EXPIRATION_PRODUCTION_DATE', value: 'EXPIRATION_PRODUCTION_DATE' },
        { text: 'CASE_PHOTO', value: 'CASE_PHOTO' },
        { text: 'QUALITY_DEFECT', value: 'QUALITY_DEFECT' },
        { text: 'OTHER', value: 'OTHER' },
    ];

    let auditCountBaseUnder100Columns = [
        {
            accessor: 'case1AuditCount',
            Header: 'Case 1 Count',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'case2AuditCount',
            Header: 'Case 2 Count',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'case3AuditCount',
            Header: 'Case 3 Count',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
    ];

    let auditCountBaseOver100Columns = [
        {
            accessor: 'case1AuditCount',
            Header: 'Case 1 Weight (4 units in oz)',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'case2AuditCount',
            Header: 'Case 2 Weight (4 units in oz)',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'case3AuditCount',
            Header: 'Case 3 Weight (4 units in oz)',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'input', inputType: 'number' },
        },
    ];

    let externalIssuesColumns = [
        {
            accessor: 'issueType',
            Header: 'Type',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'select', options: issueOpts },
        },
        {
            accessor: 'percent',
            Header: 'Percent',
            useFilter: false,
            sortable: false,
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'pallets',
            Header: 'Num Pallets',
            useFilter: false,
            sortable: false,
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'delete',
            Header: '',
            useFilter: false,
            sortable: false,
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={async () => {
                                let response = await api.deleteIssueV2(row.original.id, poMapID);
                                if (response.status === false) {
                                    let message = 'Error';
                                    if (response) {
                                        message += ': ' + response.msg;
                                    }
                                    setNotification({ text: message, level: 'error' });
                                } else {
                                    setQualityReceivingData(response.data.itemInspected);
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                );
            },
        },
    ];

    let internalIssuesColumns = [
        {
            accessor: 'issueType',
            Header: 'Type',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: { type: 'select', options: issueOpts },
        },
        {
            accessor: 'percent',
            Header: 'Percent',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'pallets',
            Header: 'Num Pallets',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'pulpTemp',
            Header: 'Pulp Temp',
            editable: true,
            useFilter: false,
            sortable: false,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'delete',
            Header: '',
            useFilter: false,
            sortable: false,
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={async () => {
                                let response = await api.deleteIssueV2(row.original.id, poMapID);
                                if (response.status === false) {
                                    let message = 'Error';
                                    if (response) {
                                        message += ': ' + response.msg;
                                    }
                                    setNotification({ text: message, level: 'error' });
                                } else {
                                    setQualityReceivingData(response.data.itemInspected);
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                );
            },
        },
        { accessor: 'poMapID', Header: '' },
    ];

    let photoFormFields = [
        {
            name: 'poID',
            initialValue: poID,
            inputElement: 'textField',
            label: 'PO ID',
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'poMapID',
            initialValue: poMapID,
            inputElement: 'textField',
            label: 'PO Map ID',
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'notes',
            inputElement: 'select',
            label: 'Notes',
            gridValue: 3,
            inputProps: { required: true, opts: photoCodes },
        },
        { name: 'document', inputElement: 'file', gridValue: 3, label: 'Document', accept: ['.png', '.jpeg', '.pdf'] },
    ];

    let auditCountHeader = qualityReceivingData.baseUnitsPerCase >= 100 ? 'Weights' : 'Case Unit Counts';

    let totalExternalPercent = 0;
    let totalInternalPercent = 0;
    qualityReceivingData.externalIssues.forEach((issue) => {
        totalExternalPercent += issue.percent;
    });
    qualityReceivingData.internalIssues.forEach((issue) => {
        totalInternalPercent += issue.percent;
    });
    return (
        <Dialog fullWidth={true} maxWidth="md" open={true} onClose={closeModal}>
            <DialogContent>
                <div
                    style={{
                        outline: 0,
                        backgroundColor: 'white',
                        width: '700px',
                        margin: '10% auto',
                        padding: '10px',
                    }}
                >
                    {pageIdx == 0 && (
                        <div>
                            <h4>{auditCountHeader}</h4>
                            <DataTable
                                data={[qualityReceivingData]}
                                columns={
                                    qualityReceivingData.baseUnitsPerCase >= 100
                                        ? auditCountBaseOver100Columns
                                        : auditCountBaseUnder100Columns
                                }
                                editable={true}
                                showPagination={false}
                                saveEdit={async ({ id, field, value }) => {
                                    let updates = {
                                        key: field,
                                        value: value,
                                    };
                                    let response = await api.recordQualityReceivingV2(id, updates);
                                    if (response.status === false) {
                                        let message = 'Error';
                                        if (response) {
                                            message += ': ' + response.msg;
                                        }
                                        setNotification({ text: message, level: 'error' });
                                    } else {
                                        setNotification({ text: 'Audit Count Recorded!', level: 'success' });
                                        setQualityReceivingData(response.data.itemInspected);
                                    }
                                }}
                            />
                            <div>
                                <Button
                                    style={{ float: 'center' }}
                                    onClick={async () => {
                                        let response = await api.recordQualityReceivingCompleteV2(poMapID);
                                        if (response.status === false) {
                                            let message = 'Error';
                                            if (response) {
                                                message += ': ' + response.msg;
                                            }
                                            setNotification({ text: message, level: 'error' });
                                        } else {
                                            setNotification({ text: 'Audit count completed', level: 'success' });
                                            if (response.data.hasVarianceError == true) {
                                                setNotification({
                                                    text: 'Warning: Receiving with discrepant input audits',
                                                    level: 'warning',
                                                });
                                            }
                                            setQualityReceivingData(response.data.itemInspected);
                                        }
                                    }}
                                >
                                    Finish Case Count Audit
                                </Button>
                                <Button
                                    style={{ float: 'right' }}
                                    disabled={qualityReceivingData.caseAuditCountComplete == false}
                                    onClick={() => {
                                        let newPageIdx = pageIdx + 1;
                                        setPageIdx(newPageIdx);
                                    }}
                                >
                                    Next: External Issues
                                </Button>
                            </div>
                        </div>
                    )}
                    {pageIdx == 1 && (
                        <div>
                            <h4>External Issues</h4>
                            <DataTable
                                data={qualityReceivingData.externalIssues}
                                columns={externalIssuesColumns}
                                editable={true}
                                showPagination={false}
                                saveEdit={async ({ id, field, value }) => {
                                    let updates = {
                                        key: field,
                                        value: value,
                                    };
                                    if (id == '') {
                                        id = 0;
                                    }
                                    let response = await api.recordQualityReceivingIssueV2(
                                        id,
                                        updates,
                                        poMapID,
                                        'external'
                                    );
                                    if (response.status === false) {
                                        let message = 'Error';
                                        if (response) {
                                            message += ': ' + response.msg;
                                        }
                                        setNotification({ text: message, level: 'error' });
                                    } else {
                                        setNotification({ text: 'External issue recorded!', level: 'success' });
                                        setQualityReceivingData(response.data.itemInspected);
                                    }
                                }}
                            />
                            <div>{`Total Percent Affected: ${totalExternalPercent}`}</div>
                            <div>
                                <Button
                                    onClick={async () => {
                                        let response = await api.createNewIssueV2(poMapID, 'external');
                                        if (response.status === false) {
                                            let message = 'Error';
                                            if (response) {
                                                message += ': ' + response.msg;
                                            }
                                            setNotification({ text: message, level: 'error' });
                                        } else {
                                            setQualityReceivingData(response.data.itemInspected);
                                        }
                                    }}
                                >
                                    Additional External Issue
                                </Button>
                            </div>
                            {qualityReceivingData.purchasingCaseUom.toLowerCase() == 'lb' &&
                                qualityReceivingData.baseUnitUom.toLowerCase() == 'ct' && (
                                    <Button
                                        style={{ float: 'left' }}
                                        onClick={() => {
                                            let newPageIdx = pageIdx - 1;
                                            setPageIdx(newPageIdx);
                                        }}
                                    >
                                        Back
                                    </Button>
                                )}

                            <Button
                                style={{ float: 'right' }}
                                onClick={() => {
                                    let newPageIdx = pageIdx + 1;
                                    setPageIdx(newPageIdx);
                                }}
                            >
                                Next: Internal Issues
                            </Button>
                        </div>
                    )}
                    {pageIdx == 2 && (
                        <div>
                            <h4>Internal Issues</h4>
                            <DataTable
                                data={qualityReceivingData.internalIssues}
                                columns={internalIssuesColumns}
                                editable={true}
                                showPagination={false}
                                saveEdit={async ({ id, field, value }) => {
                                    let updates = {
                                        key: field,
                                        value: value,
                                    };
                                    if (id == '') {
                                        id = 0;
                                    }

                                    let response = await api.recordQualityReceivingIssueV2(
                                        id,
                                        updates,
                                        poMapID,
                                        'internal'
                                    );
                                    if (response.status === false) {
                                        let message = 'Error';
                                        if (response) {
                                            message += ': ' + response.msg;
                                        }
                                        setNotification({ text: message, level: 'error' });
                                    } else {
                                        setNotification({ text: 'Internal issue Recorded!', level: 'success' });
                                        setQualityReceivingData(response.data.itemInspected);
                                    }
                                }}
                            />
                            <div>{`Total Percent Affected: ${totalInternalPercent}`}</div>
                            <div>
                                <Button
                                    onClick={async () => {
                                        let response = await api.createNewIssueV2(poMapID, 'internal');
                                        if (response.status === false) {
                                            let message = 'Error';
                                            if (response) {
                                                message += ': ' + response.msg;
                                            }
                                            setNotification({ text: message, level: 'error' });
                                        } else {
                                            setQualityReceivingData(response.data.itemInspected);
                                        }
                                    }}
                                >
                                    Additional Internal Issue
                                </Button>
                            </div>

                            <Button
                                style={{ float: 'left' }}
                                onClick={() => {
                                    let newPageIdx = pageIdx - 1;
                                    setPageIdx(newPageIdx);
                                }}
                            >
                                Back
                            </Button>

                            <Button
                                style={{ float: 'right' }}
                                onClick={() => {
                                    let newPageIdx = pageIdx + 1;
                                    setPageIdx(newPageIdx);
                                }}
                            >
                                Next: Photo Upload
                            </Button>
                        </div>
                    )}
                    {pageIdx == 3 && (
                        <div>
                            <FormComponent
                                formFields={photoFormFields}
                                onSubmit={async (formData) => {
                                    if (!formData.document) {
                                        setError('Document is required');
                                        return false;
                                    }

                                    let response = await procurementApi.uploadPODocument(poID, formData);

                                    if (response.status === false) {
                                        setError(response.msg);
                                        return;
                                    }

                                    closeModal();
                                }}
                            />
                            <Button
                                style={{ float: 'left' }}
                                onClick={() => {
                                    let newPageIdx = pageIdx - 1;
                                    setPageIdx(newPageIdx);
                                }}
                            >
                                Back
                            </Button>
                        </div>
                    )}
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    {}
                </div>
            </DialogContent>
        </Dialog>
    );
};

// Modal for uploading photos
const DocumentUploadModal = ({ rowData, closeModal }) => {
    let [error, setError] = useState(null);
    let [poID, setPoID] = useState(rowData.poID);
    let [poMapID, setPoMapID] = useState(rowData.poMapID);

    let photoCodes = [
        { text: 'FRONT', value: 'FRONT' },
        { text: 'SIDE', value: 'SIDE' },
        { text: 'PULP_TEMP', value: 'PULP_TEMP' },
        { text: 'UPC', value: 'UPC' },
        { text: 'INGREDIENT_LABEL', value: 'INGREDIENT_LABEL' },
        { text: 'EXPIRATION_PRODUCTION_DATE', value: 'EXPIRATION_PRODUCTION_DATE' },
        { text: 'CASE_PHOTO', value: 'CASE_PHOTO' },
        { text: 'QUALITY_DEFECT', value: 'QUALITY_DEFECT' },
        { text: 'OTHER', value: 'OTHER' },
    ];

    let formFields = [
        {
            name: 'poID',
            initialValue: poID,
            inputElement: 'textField',
            label: 'PO ID',
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'poMapID',
            initialValue: poMapID,
            inputElement: 'textField',
            label: 'PO Map ID',
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'notes',
            inputElement: 'select',
            label: 'Notes',
            gridValue: 3,
            inputProps: { required: true, opts: photoCodes },
        },
        { name: 'document', inputElement: 'file', gridValue: 3, label: 'Document', accept: ['.png', '.jpeg', '.pdf'] },
    ];

    return (
        <Dialog fullWidth={true} maxWidth="md" open={true} onClose={closeModal}>
            <DialogContent>
                <div
                    style={{
                        outline: 0,
                        backgroundColor: 'white',
                        width: '700px',
                        margin: '10% auto',
                        padding: '10px',
                    }}
                >
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData) => {
                            if (!formData.document) {
                                setError('Document is required');
                                return false;
                            }

                            let response = await procurementApi.uploadPODocument(poID, formData);

                            if (response.status === false) {
                                setError(response.msg);
                                return;
                            }

                            closeModal();
                        }}
                    />
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>
            </DialogContent>
        </Dialog>
    );
};

// The table to show items
const ViewItemsTable = ({ parentRow, setExpandedRow, issueCodes, directedReceivingEnabled }) => {
    let [poID, setPOID] = useState(parentRow.original.id);
    let [rows, setRows] = useState([]);
    let [photoRows, setPhotoRows] = useState([]);
    let [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
    let [modalLaunch, setModalLaunch] = useState({ poID: parentRow.original.id, poMapID: null });
    let [notification, setNotification] = useState({ text: null, level: null });
    let [issueOpts, setIssueOpts] = useState(issueCodes);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'itemMasterSKU', Header: 'Item Master SKU' },
        { accessor: 'itemMasterName', Header: 'Name' },
        { accessor: 'qty', Header: 'Quantity' },
        {
            accessor: 'levelOneIssue',
            Header: 'Level 1: Type',
            editable: true,
            editProps: { type: 'select', options: issueOpts },
        },
        {
            accessor: 'levelOnePercent',
            Header: 'Level 1: Percent',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'levelOnePallets',
            Header: 'Level 1: Num Pallets',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'levelTwoIssue',
            Header: 'Level 2: Type',
            editable: true,
            editProps: { type: 'select', options: issueOpts },
        },
        {
            accessor: 'levelTwoPercent',
            Header: 'Level 2: Percent',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'levelTwoPallets',
            Header: 'Level 2: Num Pallets',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'levelTwoPulpTemp',
            Header: 'Level 2: Pulp Temp',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
            },
        },
        {
            accessor: 'photos',
            Header: 'Photos',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                setModalLaunch({ poID: parentRow.original.id, poMapID: row.original.id });
                                setDocumentUploadModalOpen(true);
                            }}
                        >
                            Photo Upload
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getRows = async () => {
        let response = await api.getQualityPODetails(poID);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    let getPhotoRows = async () => {
        let response = await procurementApi.getPurchaseOrderDocuments(poID);

        if (response.status === false) {
            setNotification({ text: "Can't access photos: " + response.msg, level: 'error' });
            return;
        }

        setPhotoRows(response.data.rows);
    };

    let photoCols = [
        { accessor: 'id', Header: 'Document ID' },
        { accessor: 'sku', Header: 'SKU' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'notes', Header: 'Photo Label' },
        {
            accessor: 'documentURL',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                if (!row.original.documentURL) {
                    return '';
                }
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                procurementApi.downloadPODocument(
                                    poID,
                                    row.original.id,
                                    row.original.documentURL.replace(/^.*[\\\/]/, '')
                                );
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                procurementApi.removePODocument(poID, row.original.id);
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getRows();
        getPhotoRows();
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            {documentUploadModalOpen && (
                <DocumentUploadModal
                    rowData={modalLaunch}
                    closeModal={() => {
                        setDocumentUploadModalOpen(false);
                        getRows();
                    }}
                />
            )}
            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let updates = {
                        key: field,
                        value: value,
                    };
                    let response = await api.recordQualityReceiving(id, updates);
                    if (response.status === false) {
                        let message = 'Error';
                        if (response) {
                            message += ': ' + response.msg;
                        }
                        setNotification({ text: message, level: 'error' });
                    } else {
                        setNotification({ text: 'Issue recorded!', level: 'success' });
                        getRows();
                    }
                }}
            />
            <DataTable columns={photoCols} data={photoRows} />
        </div>
    );
};

// The table to show items v2
const ViewItemsTableV2 = ({ parentRow, setExpandedRow, issueCodes }) => {
    let [poID, setPOID] = useState(parentRow.original.id);
    let [rows, setRows] = useState([]);
    let [photoRows, setPhotoRows] = useState([]);
    let [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
    let [modalLaunch, setModalLaunch] = useState({
        poID: parentRow.original.id,
        poMapID: null,
        qualityReceivingData: null,
        initialPageIdx: 0,
    });
    let [itemDocumentationModalOpen, setItemDocumentationModalOpen] = useState(false);
    let [directedReceivingEnabled, setDirectedReceivingEnabled] = useState(false);

    let [notification, setNotification] = useState({ text: null, level: null });
    let [editEnabled, setEditEnabled] = useState(false);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'itemMasterSKU', Header: 'Item Master SKU' },
        { accessor: 'itemMasterName', Header: 'Name' },
        { accessor: 'qty', Header: 'Quantity' },
        {
            accessor: 'auditCounts',
            Header: 'Audit Counts',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                setModalLaunch({
                                    poID: parentRow.original.id,
                                    poMapID: row.original.id,
                                    qualityReceivingData: row.original,
                                    initialPageIdx: 0,
                                });
                                setItemDocumentationModalOpen(true);
                            }}
                        >
                            {directedReceivingEnabled ? 'View' : 'View/Edit'}
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'externalIssues',
            Header: 'External Issues',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                setModalLaunch({
                                    poID: parentRow.original.id,
                                    poMapID: row.original.id,
                                    qualityReceivingData: row.original,
                                    initialPageIdx: 1,
                                });
                                setItemDocumentationModalOpen(true);
                            }}
                        >
                            {directedReceivingEnabled ? 'View' : 'View/Edit'}
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'internalIssues',
            Header: 'Internal Issues',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                setModalLaunch({
                                    poID: parentRow.original.id,
                                    poMapID: row.original.id,
                                    qualityReceivingData: row.original,
                                    initialPageIdx: 2,
                                });
                                setItemDocumentationModalOpen(true);
                            }}
                        >
                            {directedReceivingEnabled ? 'View' : 'View/Edit'}
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'photos',
            Header: 'Photos',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                setModalLaunch({
                                    poID: parentRow.original.id,
                                    poMapID: row.original.id,
                                    qualityReceivingData: row.original,
                                    initialPageIdx: 3,
                                });
                                setItemDocumentationModalOpen(true);
                            }}
                        >
                            Photo Upload
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getRows = async () => {
        let response = await api.getQualityPODetailsV2(poID, parentRow.original.poType === 'PACKAGING');
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
        setDirectedReceivingEnabled(response.data.directedReceivingEnabled);
        setEditEnabled(response.data.jalapenoReceivingEditEnabled);
    };

    let getPhotoRows = async () => {
        let response = await procurementApi.getPurchaseOrderDocuments(poID);

        if (response.status === false) {
            setNotification({ text: "Can't access photos: " + response.msg, level: 'error' });
            return;
        }

        setPhotoRows(response.data.rows);
    };

    let photoCols = [
        { accessor: 'id', Header: 'Document ID' },
        { accessor: 'sku', Header: 'SKU' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'name', Header: 'Item Name' },
        { accessor: 'notes', Header: 'Photo Label' },
        {
            accessor: 'documentURL',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                if (!row.original.documentURL) {
                    return '';
                }
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                procurementApi.downloadPODocument(
                                    poID,
                                    row.original.id,
                                    row.original.documentURL.replace(/^.*[\\\/]/, '')
                                );
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                procurementApi.removePODocument(poID, row.original.id);
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getRows();
        getPhotoRows();
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            {itemDocumentationModalOpen && directedReceivingEnabled == false && (
                <ItemDocumentationModal
                    rowData={modalLaunch}
                    setNotification={setNotification}
                    closeModal={() => {
                        setItemDocumentationModalOpen(false);
                        getRows();
                    }}
                    issueCodes={issueCodes}
                />
            )}
            {itemDocumentationModalOpen && directedReceivingEnabled == true && (
                <DirectedReceivingItemModal
                    rowData={modalLaunch}
                    setNotification={setNotification}
                    closeModal={() => {
                        setItemDocumentationModalOpen(false);
                        getRows();
                    }}
                    issueCodes={issueCodes}
                    editEnabled={editEnabled}
                />
            )}

            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let updates = {
                        key: field,
                        value: value,
                    };
                    let response = await api.recordQualityReceiving(id, updates);
                    if (response.status === false) {
                        let message = 'Error';
                        if (response) {
                            message += ': ' + response.msg;
                        }
                        setNotification({ text: message, level: 'error' });
                    } else {
                        setNotification({ text: 'Issue recorded!', level: 'success' });
                        getRows();
                    }
                }}
            />
            <DataTable columns={photoCols} data={photoRows} />
        </div>
    );
};

let QualityControlReceiving = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [truckTempModalOpen, setTruckTempModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});
    let [issueCodes, setIssueCodes] = useState([]);
    const [directedReceivingEnabled, setDirectedReceivingEnabled] = useState(false);

    const user = useContext(UserContext);

    let columns = [
        { accessor: 'id', Header: 'Purchase Order' },
        { accessor: 'vendorName', Header: 'Vendor' },
        { accessor: 'truckID', Header: 'Truck ID' },
        { accessor: 'buyers', Header: 'Buyers' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        { accessor: 'minTemperature', Header: 'Min Temp' },
        { accessor: 'maxTemperature', Header: 'Max Temp' },
        {
            accessor: 'truckTemp',
            Header: 'Truck Temp Upload',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        {!(row.original.truckID > 0) ? (
                            <></>
                        ) : (
                            <Button
                                onClick={() => {
                                    showTruckTempModal(row);
                                }}
                            >
                                Truck Temp
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    let getRows = async () => {
        let response = await api.getQualityPOs(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.deliveryDate = moment(row.deliveryDate.date).format('MM/DD/YYYY');
                row.buyers = row.buyers.map((buyer) => buyer.name).join(', ');
                return row;
            });
        }

        setRows(response.data.rows);
        setDirectedReceivingEnabled(response.data.directedReceivingEnabled);
    };

    let getQualityIssueCodes = async () => {
        let response = await api.getQualityIssueCodes();
        if (response.status === false) {
            setNotification({ text: 'No Issue codes found ' + response.msg, level: 'error' });
            setIssueCodes([]);
        }
        setIssueCodes(response.data.issueCodes);
    };

    let showTruckTempModal = (row) => {
        setSelectedRow(row);
        setTruckTempModalOpen(true);
    };

    useEffect(() => {
        getRows();
        getQualityIssueCodes();
    }, []);

    if (directedReceivingEnabled) {
        return (
            <div>
                This page is deprecated, plase navigate to the Inbound Receiving page to view Quality Control Receiving
                information
            </div>
        );
    }
    return (
        <div>
            <h1>Quality Control Receiving</h1>
            <Notifications options={notification} />
            {}
            <div>
                {truckTempModalOpen && (
                    <TruckTemperaturesModal
                        rowData={selectedRow}
                        closeModal={() => {
                            setTruckTempModalOpen(false);
                        }}
                    />
                )}
                <DataTable
                    columns={columns}
                    data={rows}
                    expandable={true}
                    ExpansionComponent={<ViewItemsTableV2 issueCodes={issueCodes} />}
                />
            </div>
        </div>
    );
};

export { QualityControlReceiving };
