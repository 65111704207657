import moment from 'moment';
import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let trainingApi = {
    getAllTrainingScenarios: async (facilityID) => {
        let url = `api/warehouse/v1/training?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTrainingLPN: async (id) => {
        let url = `api/warehouse/v1/training/lpn/${id}`;
        try {
            let response = await mmFetch(url);
            if (response.ok === true) {
                var blob = await response.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = 'training-' + id + '.pdf';
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(response);
                }, 200);
            }
            return response.ok;
        } catch (e) {
            console.log('[downloadPDF] error', e.toString());
        }
    },
};

export { trainingApi };
