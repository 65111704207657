import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

let outboundTruckApi = {
    createTruck: async (item) => {
        let url = `api/logistics/v1/outbound/trucks`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    getDoors: async (facilityID) => {
        let url = `api/logistics/v1/outbound/doors?facility=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getManifestableTrucks: async (facilityID) => {
        let url = `api/logistics/v1/outbound/manifestableTrucks?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTrucks: async (facilityID) => {
        let url = `api/logistics/v1/outbound/trucks?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getTruckDelayReasons: async () => {
        let url = `api/logistics/v1/outbound/truckDelayReasons`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDoorTag: async (id) => {
        let url = `api/logistics/v1/outbound/doors/${id}/label`;
        let filename = 'outbound_door_' + id + '.pdf';
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[getTruckTag] error', e.toString());
        }
    },
    manifestTruck: async (outboundTruckID) => {
        let url = `api/logistics/v1/outbound/manifestableTrucks`;
        let sendParams = {
            outboundTruckID: outboundTruckID,
        };
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updateTruck: async (id, sendParams) => {
        let url = `api/logistics/v1/outbound/trucks/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
};

export { outboundTruckApi };
