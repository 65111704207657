import { mmFetchWithResponse } from './mmFetchWithResponse';

let holidayOffsetApi = {
    getHolidayOffsets: async () => {
        const url = 'api/logistics/v1/holiday-offsets';
        return mmFetchWithResponse(url);
    },
    createHolidayOffset: async (sendParams) => {
        const url = `api/logistics/v1/holiday-offset`;
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
    },
    bulkUploadHolidayOffset: async (data) => {
        let formData = new FormData();
        formData.append('file', data.file);

        const url = 'api/logistics/v1/holiday-offset/import';
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
    },
    confirmBulkUpload: async (key) => {
        const url = 'api/logistics/v1/holiday-offset/import/confirm';
        return mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
    },
    updateHolidayOffset: async (id, sendParams) => {
        const url = `api/logistics/v1/holiday-offset/${id}`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
    },
    cancelHolidayOffset: async (id) => {
        const url = `api/logistics/v1/holiday-offset/${id}/cancel`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
        });
    },
    activateHolidayOffset: async (id) => {
        const url = `api/logistics/v1/holiday-offset/${id}/activate`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
        });
    },
};

export { holidayOffsetApi };
