import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, lighten, darken } from '@material-ui/core/styles';
import { warehouseApi as api } from '../../../api/warehouse';
import { Grid, Card, CardHeader, Divider, CardContent, IconButton, Paper } from '@material-ui/core';
import { Notifications, DataTable, FacilityPicker } from '../../../shared';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../../contexts/UserContext';

const PackLineCardSmall = ({ packLine, setFocusedCard }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '10px',
            minWidth: 275,
            margin: '20px',
            minHeight: 310,
            boxShadow: '20px',
            backgroundColor: theme.palette.bone.main,
            transition: 'all .5s',
            '&:hover': {
                transition: 'all .5s',
                cursor: 'pointer',
                backgroundColor: lighten(theme.palette.mustard.main, 0.3),
            },
        },
        cardTitle: {
            fontWeight: '500',
        },
    }));
    const classes = useStyles();

    return (
        <Card
            className={classes.root}
            onClick={() => {
                setFocusedCard(packLine.id);
            }}
        >
            <CardHeader
                classes={{
                    title: classes.cardTitle,
                }}
                title={`LINE ${packLine.lineNumber}`}
            />
            <Divider />
            <CardContent>
                <Grid container style={{ paddingTop: '10px' }}>
                    <div style={{ fontSize: '20px', textAlign: 'center', width: '100%', padding: '10px' }}>
                        Show configuration
                    </div>
                </Grid>
            </CardContent>
        </Card>
    );
};

let PackLineZones = () => {
    let [lines, setLines] = useState([]);
    let [zoneConfigs, setZoneConfigs] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [focusedCard, setFocusedCard] = useState(null);
    let [facilityID, setFacilityID] = useState('');

    const user = useContext(UserContext);

    useEffect(() => {
        setFocusedCard(null);
        getPackLines();
    }, [facilityID]);

    let getPackLines = async () => {
        if (!facilityID) {
            return;
        }
        let response = await api.getPackLines(facilityID);
        if (response.status === true) {
            let packLines = response.data.packLines.sort((a, b) => (a.lineNumber > b.lineNumber ? 1 : -1));
            setLines(packLines);
        }
    };

    useEffect(() => {
        getPackLineZoneConfig(focusedCard);
    }, [focusedCard]);

    let getPackLineZoneConfig = async (packLineID) => {
        if (packLineID === 0 || !packLineID) {
            return;
        }
        let response = await api.getPackLineZoneConfig(packLineID);
        if (response.status === true) {
            let zoneConfigs = response.data.rows.sort((a, b) => (a.zoneNumber > b.zoneNumber ? 1 : -1));
            setZoneConfigs(zoneConfigs);
        }
    };

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item md={6} style={{ textAlign: 'left', paddingLeft: '40px', paddingRight: '10px' }}>
                <h1>Pack Line Zones</h1>
            </Grid>
            <Grid item md={6} style={{ textAlign: 'right', paddingRight: '35px' }}>
                <FacilityPicker
                    defaultValue={user.getFacilityID()}
                    activeOnly={true}
                    onChange={(facilityID) => {
                        setFacilityID(facilityID);
                    }}
                />
            </Grid>
            {focusedCard === null ? (
                <>
                    {lines.map((item) => {
                        return (
                            <Grid key={item.lineNumber} item md={4} sm={6} xs={12}>
                                <PackLineCardSmall packLine={item} setFocusedCard={setFocusedCard} />
                            </Grid>
                        );
                    })}
                </>
            ) : (
                <Grid item xs={12}>
                    <PackLineCardExpanded
                        packLine={lines.find((item) => item.id === focusedCard)}
                        setFocusedCard={setFocusedCard}
                        setNotification={setNotification}
                        getPackLineZoneConfig={getPackLineZoneConfig}
                        zoneConfigs={zoneConfigs}
                    />
                </Grid>
            )}
        </Grid>
    );
};

const PackLineCardExpanded = ({ packLine, setFocusedCard, setNotification, getPackLineZoneConfig, zoneConfigs }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '10px',
            minWidth: 275,
            margin: '20px',
            minHeight: 275,
            boxShadow: '20px',
            backgroundColor: theme.palette.bone.main,
        },
        greenText: {
            color: theme.palette.ivy.main,
        },
        redText: {
            color: theme.palette.tomato.main,
        },
        waveParamKey: {
            fontWeight: 'bold',
        },
        cardTitle: {
            fontWeight: '500',
        },
        header: {
            color: darken(theme.palette.ivy.main, 0.3),
        },
    }));
    let classes = useStyles();

    let columns = [
        { accessor: 'zoneNumber', Header: 'Zone Number' },
        { accessor: 'config.totalSlots', Header: 'Total Slots' },
        {
            accessor: 'config.slotMapping',
            Header: 'Slot Mapping',
            Cell: (rows) => {
                let totalPickSlots = rows.row.values['config.totalSlots'];
                let layout = [];
                if (totalPickSlots === 4) {
                    layout.push([1, 3]);
                    layout.push([2, 4]);
                } else if (totalPickSlots === 6) {
                    layout.push([1, 3, 5]);
                    layout.push([2, 4, 6]);
                } else if (totalPickSlots === 8) {
                    layout.push([1, 3, 5, 7]);
                    layout.push([2, 4, 6, 8]);
                } else if (totalPickSlots === 9) {
                    layout.push([1, 4, 7]);
                    layout.push([2, 5, 8]);
                    layout.push([3, 6, 9]);
                } else if (totalPickSlots === 10) {
                    layout.push([1, 3, 5, 7, 9]);
                    layout.push([2, 4, 6, 8, 10]);
                } else if (totalPickSlots === 12) {
                    layout.push([1, 3, 5, 7, 9, 11]);
                    layout.push([2, 4, 6, 8, 10, 12]);
                } else if (totalPickSlots === 14) {
                    layout.push([1, 3, 5, 7, 9, 11, 13]);
                    layout.push([2, 4, 6, 8, 10, 12, 14]);
                } else if (totalPickSlots === 21) {
                    layout.push([1, 4, 7, 10, 13, 16, 19]);
                    layout.push([2, 5, 8, 11, 14, 17, 20]);
                    layout.push([3, 6, 9, 12, 15, 18, 21]);
                } else if (totalPickSlots === 28) {
                    layout.push([1, 5, 9, 13, 17, 21, 25]);
                    layout.push([2, 6, 10, 14, 18, 22, 26]);
                    layout.push([3, 7, 11, 15, 19, 23, 27]);
                    layout.push([4, 8, 12, 16, 20, 24, 28]);
                }

                let output = layout.map((row, index) => (
                    <div key={index} style={{ padding: 5 }}>
                        {
                            <Grid container spacing={2}>
                                {' '}
                                {row.map((slotNum) => {
                                    let localMapped = rows.row.values['config.slotMapping'][slotNum];
                                    if (typeof localMapped === 'undefined') {
                                        localMapped = '❌';
                                    }
                                    return (
                                        <Grid item xs>
                                            <Paper style={{ textAlign: 'center' }}>{localMapped}</Paper>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        }
                        <br />
                    </div>
                ));

                return output;
            },
        },
    ];

    return (
        <Card className={classes.root}>
            <CardHeader
                classes={{ title: classes.cardTitle }}
                action={
                    <IconButton
                        onClick={(e) => {
                            setFocusedCard(null);
                            e.stopPropagation();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                title={`Line ${packLine.lineNumber}`}
            />
            <CardContent style={{}}>
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable editable={false} columns={columns} data={zoneConfigs} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export { PackLineZones };
