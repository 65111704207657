import { mmFetchWithResponse } from './mmFetchWithResponse';

let creditApi = {
    massCredit: async (creditValue, reason, customerFile) => {
        let formData = new FormData();
        formData.append('creditValue', creditValue);
        formData.append('reason', reason);
        formData.append('customerFile', customerFile);

        let url = `api/credits/v1/mass`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
};

export { creditApi };
