import React, { useState, useEffect } from 'react';
import { Grid, Button, Dialog, DialogActions, DialogContent, TextField, InputLabel } from '@material-ui/core';
import { FormComponent } from '../../../shared';
import { customerApi as api } from '../../../api/customer';

const PlusMembership = ({ customerID, classes, membership, getMembershipSummary, setNotification }) => {
    const [isOptOutDialogOpen, setIsOptOutDialogOpen] = useState(false);
    const [isPlusRefundDialogOpen, setIsPlusRefundDialogOpen] = useState(false);
    const [refundType, setRefundType] = useState('');
    const [refundReason, setRefundReason] = useState('');
    const [refundReasonSecondary, setRefundReasonSecondary] = useState('');
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
    const [isMarketCloseDialogOpen, setIsMarketCloseDialogOpen] = useState(false);
    const [marketCloseDate, setMarketCloseDate] = useState('');
    const [isCancelTrialDialogOpen, setIsCancelTrialDialogOpen] = useState(false);

    const toggleMembershipAutoRenew = async () => {
        let newAutoRenewValue = membership.term.isAutoRenew === 't' ? 0 : 1;

        let res = await api.updateMembershipAutoRenew(customerID, newAutoRenewValue);
        if (!res.status) {
            setNotification('Error when attempting to toggle Membership+ auto-renew');
        }

        getMembershipSummary(customerID);
    };

    const cancelMembership = async () => {
        let res = await api.cancelMembership(customerID);
        if (!res.status) {
            setNotification('Error when attempting to cancel Membership+ membership');
        }

        getMembershipSummary(customerID);
    };

    const cancelTrial = async () => {
        let res = await api.cancelTrial(customerID);
        if (!res.status) {
            setNotification('Error when attempting to cancel Membership+ membership trial');
        }
        setNotification('Membership+ trial has been cancelled');
        getMembershipSummary(customerID);
    };

    const refundMembership = async (plusMembershipChargeID, refundType, refundReason, refundReasonSecondary) => {
        let res = await api.refundMembership(
            customerID,
            plusMembershipChargeID,
            refundType,
            refundReason,
            refundReasonSecondary
        );
        if (!res.status) {
            setNotification('Error when attempting to refund Membership+ membership');
        }

        getMembershipSummary(customerID);
    };

    const grantFreeTrial = async (endDate) => {
        let res = await api.grantFreeTrial(customerID, endDate);
        if (!res.status) {
            setNotification('Error when attempting to grant Membership+ free-trial');
        }

        getMembershipSummary(customerID);
    };

    useEffect(() => {
        // get sub and account info
        getMembershipSummary(customerID);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid item xs={12} md={4}>
            <div className={classes.card}>
                <InputLabel className={classes.inputLabel}>Paid Membership</InputLabel>
                <div style={{ textAlign: 'left' }}>
                    <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                        Membership State
                    </InputLabel>
                    <span>
                        {membership.term.isActive === 't' ? 'ACTIVE' : 'IN-active'}: {membership.term.typeOfMembership}
                    </span>
                </div>
                <div style={{ textAlign: 'left' }}>
                    <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                        Active Shipping Perk
                    </InputLabel>
                    <span>
                        {membership.carrier}:{' '}
                        {membership.carrier === 'CS'
                            ? '$' + membership.variant.csShippingFeeDiscount + ' off shipping'
                            : ''}
                        {membership.carrier === 'DBI' ? '$' + membership.variant.dbiShippingFee + ' shipping' : ''}
                    </span>
                </div>
                <div style={{ textAlign: 'left' }}>
                    <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                        Latest membership charge date
                    </InputLabel>
                    <span>{membership.charge.chargeDate}</span>
                </div>
                <div style={{ textAlign: 'left' }}>
                    <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                        Latest membership charge amount
                    </InputLabel>
                    <span>${membership.charge.amountCharged}</span>
                </div>
                <div style={{ textAlign: 'left' }}>
                    <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                        Amount refunded on the latest charge
                    </InputLabel>
                    <span>${membership.charge.amountRefunded}</span>
                </div>
                <div style={{ textAlign: 'left' }}>
                    <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                        Next charge date
                    </InputLabel>
                    <span>{membership.term.endDate}</span>
                </div>

                {membership.term.typeOfMembership !== 'PAID' || membership.term.isActive === 'f' ? (
                    <>
                        <InputLabel className={classes.inputLabel}>Change free trial:</InputLabel>

                        <Dialog
                            open={isMarketCloseDialogOpen}
                            onClose={() => {
                                setIsMarketCloseDialogOpen(false);
                            }}
                        >
                            <DialogContent style={{ display: 'flex' }}>
                                Are you sure you want to grant a free trial until {marketCloseDate}?
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    style={{ border: '1px solid lightgray' }}
                                    onClick={() => {
                                        setIsMarketCloseDialogOpen(false);

                                        grantFreeTrial(marketCloseDate);
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    style={{ border: '1px solid lightgray' }}
                                    onClick={() => {
                                        setIsMarketCloseDialogOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <FormComponent
                            inputElementStyle={{ padding: '0px', margin: '0px' }}
                            formControlStyle={{ padding: '0px 3px' }}
                            formFields={[
                                {
                                    name: 'endDate',
                                    inputElement: ({ formData, setFormField }) => (
                                        <TextField
                                            style={{ borderRadius: '8px', marginTop: '30px', width: '100%' }}
                                            placeholder=""
                                            variant="outlined"
                                            label="New End Date [YYYY-MM-DD]"
                                            inputProps={{ required: true }}
                                            disableUnderline={true}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => {
                                                setFormField(e.target.value);

                                                setMarketCloseDate(e.target.value);
                                            }}
                                        />
                                    ),
                                },
                            ]}
                            button={{ style: { marginTop: '30px' } }}
                            onSubmit={async (formData, reset) => {
                                setIsMarketCloseDialogOpen(true);
                            }}
                        />

                        <div style={{ textAlign: 'left' }}>
                            <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                                Most recent free trial start date
                            </InputLabel>
                            <span>{membership.term.startDate}</span>
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                                Most recent free trial end date
                            </InputLabel>
                            <span>{membership.term.endDate}</span>
                        </div>
                        {membership.term.isActive === 't' ? (
                            <div style={{ marginTop: '25px' }}>
                                <Dialog
                                    open={isCancelTrialDialogOpen}
                                    onClose={() => {
                                        setIsCancelTrialDialogOpen(false);
                                    }}
                                >
                                    <DialogContent style={{ display: 'flex' }}>
                                        Are you sure you want to cancel this free trial?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            style={{ border: '1px solid lightgray' }}
                                            onClick={() => {
                                                setIsCancelTrialDialogOpen(false);
                                                cancelTrial();
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            style={{ border: '1px solid lightgray' }}
                                            onClick={() => {
                                                setIsCancelTrialDialogOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Button
                                    onClick={() => {
                                        setIsCancelTrialDialogOpen(true);
                                    }}
                                    style={{ color: 'red', borderColor: 'red' }}
                                    variant="outlined"
                                >
                                    Cancel free trial now
                                </Button>
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <></>
                )}
                {membership.term.typeOfMembership === 'PAID' ? (
                    <>
                        <InputLabel className={classes.inputLabel}>Manage Renewal</InputLabel>

                        <Dialog
                            open={isOptOutDialogOpen}
                            onClose={() => {
                                setIsOptOutDialogOpen(false);
                            }}
                        >
                            <DialogContent style={{ display: 'flex' }}>
                                Are you sure you want to Opt{' '}
                                {membership.term.isActive === 't' && membership.term.isAutoRenew === 't'
                                    ? 'OUT of'
                                    : 'INTO'}{' '}
                                auto-renewal?
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    style={{ border: '1px solid lightgray' }}
                                    onClick={() => {
                                        setIsOptOutDialogOpen(false);

                                        toggleMembershipAutoRenew();
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    style={{ border: '1px solid lightgray' }}
                                    onClick={() => {
                                        setIsOptOutDialogOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Button
                            onClick={() => {
                                setIsOptOutDialogOpen(true);
                            }}
                            style={{ color: 'red', borderColor: 'red' }}
                            variant="outlined"
                        >
                            Opt{' '}
                            {membership.term.isActive === 't' && membership.term.isAutoRenew === 't'
                                ? 'OUT of'
                                : 'INTO'}{' '}
                            auto-renewal
                        </Button>
                        <div style={{ textAlign: 'left' }}>
                            <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                                Will auto-renew?
                            </InputLabel>
                            <span>
                                {membership.term.isActive === 't' && membership.term.isAutoRenew === 't' ? 'Yes' : 'No'}
                            </span>
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                                Next renewal date
                            </InputLabel>
                            <span>
                                {membership.term.isActive === 't' && membership.term.isAutoRenew === 't'
                                    ? membership.term.endDate
                                    : 'none'}
                            </span>
                        </div>

                        <InputLabel className={classes.inputLabel}>Issue paid membership refund</InputLabel>

                        <Dialog
                            open={isPlusRefundDialogOpen}
                            onClose={() => {
                                setIsPlusRefundDialogOpen(false);
                            }}
                        >
                            <DialogContent style={{ display: 'flex' }}>
                                Are you sure you want to {refundType === 'full_refund' ? 'FULL' : ''}
                                {refundType === 'prorated_refund' ? 'PRORATED' : ''} refund
                                {refundType === 'full_refund'
                                    ? '$' + membership.charge.refundCalculations.fullRefundAmount
                                    : ''}
                                {refundType === 'prorated_refund'
                                    ? '$' + membership.charge.refundCalculations.proRatedRefundAmount
                                    : ''}
                                ?
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    style={{ border: '1px solid lightgray' }}
                                    onClick={() => {
                                        setIsPlusRefundDialogOpen(false);

                                        refundMembership(
                                            membership.charge.plusMembershipChargeID,
                                            refundType,
                                            refundReason,
                                            refundReasonSecondary
                                        );
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    style={{ border: '1px solid lightgray' }}
                                    onClick={() => {
                                        setIsPlusRefundDialogOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <FormComponent
                            inputElementStyle={{ padding: '0px', margin: '0px' }}
                            formControlStyle={{ padding: '0px 3px' }}
                            formFields={[
                                {
                                    name: 'refundReason',
                                    inputElement: 'select',
                                    label: 'Refund Reason',
                                    gridValue: 12,
                                    inputProps: {
                                        required: true,
                                        opts: [
                                            { value: 'meant_to_cancel_free_trial', text: 'Meant to cancel free trial' },
                                            {
                                                value: 'not_valuable_enough_too_expensive',
                                                text: 'Not valuable enough / too expensive',
                                            },
                                            { value: 'paid_membership_confusion', text: 'Paid Membership confusion' },
                                            { value: 'moving', text: 'Moving' },
                                            { value: 'account_canceled', text: 'Account canceled' },
                                            { value: 'other', text: 'Other' },
                                        ],
                                    },
                                },
                                {
                                    name: 'refundType',
                                    inputElement: 'select',
                                    label: 'Refund Amount',
                                    gridValue: 12,
                                    inputProps: {
                                        required: true,
                                        opts: [
                                            {
                                                value: 'full_refund',
                                                text:
                                                    'Issue full refund ($' +
                                                    membership.charge.refundCalculations.fullRefundAmount +
                                                    ')',
                                            },
                                            {
                                                value: 'prorated_refund',
                                                text:
                                                    'Issue prorated refund ($' +
                                                    membership.charge.refundCalculations.proRatedRefundAmount +
                                                    ')',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'refundReasonSecondary',
                                    gridValue: 12,
                                    inputProps: { required: false },
                                    inputElement: 'textField',
                                    label: 'Refund Reason: Secondary',
                                },
                            ]}
                            button={{ style: { marginTop: '30px' } }}
                            onSubmit={async (formData, reset) => {
                                setRefundReason(formData.refundReason);
                                setRefundReasonSecondary(formData.refundReasonSecondary);
                                setRefundType(formData.refundType);
                                setIsPlusRefundDialogOpen(true);
                            }}
                        />

                        <hr />

                        <Dialog
                            open={isCancelDialogOpen}
                            onClose={() => {
                                setIsCancelDialogOpen(false);
                            }}
                        >
                            <DialogContent style={{ display: 'flex' }}>
                                Are you sure you want to cancel this membership?
                                <InputLabel
                                    className={classes.inputFormLabel}
                                    style={{ paddingTop: '10px' }}
                                    shrink={true}
                                >
                                    Note: cancelling via this button does not issue any kind of refund
                                </InputLabel>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    style={{ border: '1px solid lightgray' }}
                                    onClick={() => {
                                        setIsCancelDialogOpen(false);

                                        cancelMembership();
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    style={{ border: '1px solid lightgray' }}
                                    onClick={() => {
                                        setIsCancelDialogOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <div style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => {
                                    setIsCancelDialogOpen(true);
                                }}
                                style={{ color: 'red', borderColor: 'red' }}
                                variant="outlined"
                            >
                                Cancel membership now
                            </Button>
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            <InputLabel className={classes.inputFormLabel} style={{ paddingTop: '10px' }} shrink={true}>
                                Note: cancelling via this button does not issue any kind of refund
                            </InputLabel>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </Grid>
    );
};

export { PlusMembership };
