import React, { useState, useContext } from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import { ReceivablePurchaseOrders } from './receivablePurchaseOrders.js';
import { TabPanel } from '../../../shared';

const Receiving = ({ baseUrl }) => {
    const user = useContext(UserContext);
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Receive Purchase Order" value="0" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ReceivablePurchaseOrders />
            </TabPanel>
        </Paper>
    );
};

export { Receiving };
