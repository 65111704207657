import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Modal, Button, Select, NativeSelect, Grid } from '@material-ui/core';
import { fbmApi as api } from '../../../api/fbm';
import { packagingApi as packageApi } from '../../../api/packaging';
import { UserContext } from '../../../contexts/UserContext';

const ViewItemsTable = ({ parentRow, setExpandedRow, fbmAccount, onRefresh }) => {
    let [poID, setPOID] = useState(parentRow.original.id);
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [addItemModalOpen, setAddItemModalOpen] = useState(false);
    let [packagingOptions, setPackagingOptions] = useState([]);

    let getPackagingOptions = async () => {
        let itemMasterRes = await api.getPackaging(fbmAccount.fbmAccountName);
        if (itemMasterRes.status === false) {
            return;
        } else {
            let items = itemMasterRes.data.rows.map((item) => {
                return { text: `SKU ${item.model.materialNumber}: ${item.model.description}`, value: item.model.id };
            });
            setPackagingOptions(items);
        }
    };

    let columns = [
        { accessor: 'vendorSku', Header: 'Item SKU' },
        { accessor: 'description', Header: 'Description' },
        {
            accessor: 'numPallets',
            Header: 'Pallets per SKU',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'unitsPerCase',
            Header: 'Units Per Case',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        { accessor: 'unitsPerCaseUOM', Header: 'Unit of Measure' },
        {
            accessor: 'qty',
            Header: 'Quantity',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'partnerLotNumber',
            Header: 'Partner Lot  Number',
            editable: true,
            editProps: { type: 'input' },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        onClick={async () => {
                            let response = await api.removePackagingASNItem(
                                poID,
                                row.original.id,
                                fbmAccount.fbmAccountName
                            );

                            if (response.status === false) {
                                setNotification({ text: "Can't remove item " + response.msg, level: 'error' });
                                return;
                            }
                            await onRefresh();
                            getItemsInTable();
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    let getItemsInTable = async () => {
        let response = await api.getPackagingASNDetails(poID, fbmAccount.fbmAccountName);

        if (response.status === false) {
            setNotification({ text: 'No items found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        let poDetails = response.data.output;

        // Need to add id which will populate in saveEdit
        setRows(
            poDetails.items.map((row) => {
                row.id = row.poMapID;
                row.unitPrice = parseFloat(row.unitPrice).toFixed(2);
                return row;
            })
        );
    };

    useEffect(() => {
        getItemsInTable();
        getPackagingOptions();
    }, []);

    return (
        <div>
            <Notifications options={notification} />
            {addItemModalOpen && (
                <AddItemModal
                    fbmAccount={fbmAccount}
                    poID={poID}
                    packagingOptions={packagingOptions}
                    closeModal={() => {
                        setAddItemModalOpen(false);
                        getItemsInTable();
                    }}
                    onRefresh={onRefresh}
                />
            )}
            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let updates = { [field]: value };
                    updates.fbmAccount = fbmAccount.fbmAccountName;
                    let response = await api.modifyPackagingASNItem(poID, id, updates);
                    if (response.status === false) {
                        let message = 'Error modifying ASN';
                        if (response) {
                            message += ': ' + response.msg;
                        }
                        setNotification({ text: message, level: 'error' });
                    } else {
                        setNotification({ text: 'Packaging ASN modified!', level: 'success' });
                    }
                    await onRefresh();
                    getItemsInTable();
                }}
            />
            <Button
                onClick={() => {
                    setAddItemModalOpen(true);
                }}
            >
                Add Item
            </Button>
        </div>
    );
};

const AddItemModal = ({ poID, closeModal, packagingOptions, fbmAccount, onRefresh }) => {
    let [error, setError] = useState(null);

    let packagingFormFields = [
        {
            name: 'id',
            inputElement: 'autocomplete',
            label: 'Packaging',
            gridValue: 8,
            inputProps: { required: true, opts: packagingOptions },
        },
        {
            name: 'qty',
            inputElement: 'textField',
            label: 'Quantity (cases)',
            gridValue: 2,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'unitsPerCase',
            inputElement: 'textField',
            label: 'Units Per Case',
            gridValue: 3,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'numPallets',
            inputElement: 'textField',
            label: 'Number of pallets',
            gridValue: 3,
            inputProps: { type: 'number', required: true },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={packagingFormFields}
                    onSubmit={async (formData) => {
                        let items = {};
                        items['items'] = [formData];
                        items['fbmAccount'] = fbmAccount.fbmAccountName;

                        let response = await api.addItemToPackagingASN(poID, items);
                        if (response.status === false) {
                            let message = 'Error adding item';
                            if (response.msg) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                            return;
                        }

                        closeModal();
                        onRefresh();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const EditDeliveryModal = ({ rowData, closeModal, fbmAccount }) => {
    let [error, setError] = useState(null);

    let date = moment(rowData.scheduledDeliveryDate).format('YYYY-MM-DD');
    let time = moment(rowData.scheduledDeliveryDate).format('HH:mm');

    let formFields = [
        {
            name: 'poID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'Purchase Order ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'scheduledDeliveryDate',
            initialValue: date,
            inputElement: 'date',
            disableTextInput: true,
            label: 'Scheduled Delivery Date',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'scheduledTime',
            initialValue: time,
            inputElement: 'time',
            label: 'Delivery time',
            gridValue: 4,
            inputProps: { required: true },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['fbmAccount'] = fbmAccount.fbmAccountName;
                        item['scheduledDeliveryDate'] = formData.scheduledDeliveryDate;
                        item['deliverTime'] = formData.scheduledTime;

                        let response = await api.modifyPackagingASN(formData.poID, item);
                        if (response.status === false) {
                            let message = 'Error modifying purchase order';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                        } else {
                            closeModal();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

// The main view
let ViewPackagingASN = () => {
    const { user, fbmAccount } = useContext(UserContext);
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [editDeliveryModalOpen, setEditDeliveryModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});

    // Load purchase orders
    let getRows = async () => {
        let response = await api.getPackagingASNs(fbmAccount.fbmAccountName);

        if (response.status === false) {
            setNotification({ text: "Can't access ASNs: " + response.msg, level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY');
                row.scheduledDeliveryDate = moment(row.scheduledDeliveryDate.date).format('MM/DD/YYYY HH:mm:ss');
                return row;
            });
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        if (fbmAccount.fbmAccountName) {
            getRows();
        }
    }, [fbmAccount]);

    let closeDeliveryEditModal = () => {
        setEditDeliveryModalOpen(false);
    };

    let showDeliveryEditModal = (row, closeDeliveryEditModal) => {
        setSelectedRow(row);
        setEditDeliveryModalOpen(true);
    };

    let columns = [
        { accessor: 'id', Header: 'ASN ID' },
        { accessor: 'createdAt', Header: 'Created On' },
        { accessor: 'facilityName', Header: 'Facility' },
        { accessor: 'status', Header: 'Status' },
        {
            accessor: 'scheduledDeliveryDate',
            Header: 'Scheduled Delivery',
            editable: true,
            customEdit: showDeliveryEditModal,
        },
        { accessor: 'referenceNumber', Header: 'Reference Number', editable: true },
        { accessor: 'totalPallets', Header: 'Total Pallets' },
        {
            accessor: 'download',
            hiddenColumn: user.fbm,
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                const [downloadOpt, setDownloadOpt] = useState('pdf');
                const downloadOptions = ['pdf', 'ship-list'];
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <NativeSelect
                            id={`Download_Select_${row.id}`}
                            className="input-element-select"
                            value={downloadOpt}
                            label="Download"
                            onChange={(event) => {
                                setDownloadOpt(event.target.value);
                            }}
                        >
                            {downloadOptions.map((value, i) => {
                                return (
                                    <option key={i} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                            ;
                        </NativeSelect>
                        <Button
                            id={`Download_${row.id}`}
                            onClick={() => {
                                if (downloadOpt === 'pdf') {
                                    packageApi.downloadPackagingPOPDF(row.original.id, fbmAccount.companyName);
                                } else if (downloadOpt === 'ship-list') {
                                    packageApi.downloadPackagingPOShipList(row.original.id);
                                } else {
                                    console.log('Unrecognized download option ' + downloadOpt);
                                }
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'cancel',
            Header: 'Cancel',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Cancel_${row.id}`}
                            onClick={async () => {
                                let response = await api.cancelPackagingASN(row.original.id, fbmAccount.fbmAccountName);
                                if (response.status === false) {
                                    setNotification({ text: 'Failed to cancel ' + response.msg, level: 'error' });
                                    return;
                                }
                                setNotification({ text: 'ASN cancelled', level: 'success' });
                                await getRows();
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <h1>{fbmAccount.companyName} - Packaging Advance Shipping Notifications</h1>
            <Notifications options={notification} />
            <div>
                {editDeliveryModalOpen && (
                    <EditDeliveryModal
                        fbmAccount={fbmAccount}
                        rowData={selectedRow}
                        closeModal={() => {
                            setEditDeliveryModalOpen(false);
                            getRows();
                        }}
                    />
                )}
                <DataTable
                    columns={columns}
                    data={rows}
                    expandable={true}
                    ExpansionComponent={
                        <ViewItemsTable
                            fbmAccount={fbmAccount}
                            onRefresh={async () => {
                                getRows();
                            }}
                        />
                    }
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        let item = {};
                        item[field] = value;
                        item.fbmAccount = fbmAccount.fbmAccountName;
                        let response = await api.modifyPackagingASN(id, item);
                        if (response.status === false) {
                            let message = 'Error modifying purchase order';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'ASN modified!', level: 'success' });
                            await getRows();
                        }
                        return response.status;
                    }}
                />
            </div>
        </div>
    );
};

export { ViewPackagingASN };
