import React, { useState, useEffect, useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Notifications, DataTable } from '../../../shared';
import { qualityApi as api } from '../../../api/quality';
import { UserContext } from '../../../contexts/UserContext';

const ViewQCAuditTasks = () => {
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [rows, setRows] = useState([]);
    const user = useContext(UserContext);

    const getRows = async () => {
        let res = await api.getQualityControlTasks('open', user.getFacilityID());
        if (res.status) {
            setRows(res.data.tasks);
        }
    };

    useEffect(() => {
        getRows();
    }, []);

    const columns = [
        { accessor: 'id', Header: 'Task ID' },
        { accessor: 'itemName', Header: 'Item' },
        { accessor: 'lotID', Header: 'Lot' },
        { accessor: 'palletName', Header: 'Pallet Name' },
        { accessor: 'caseName', Header: 'Case Name' },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item md={12} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                <h1>Open QC Audit tasks</h1>
            </Grid>
            <Grid item xs={12}>
                <DataTable data={rows} columns={columns} />
            </Grid>
        </Grid>
    );
};

export { ViewQCAuditTasks };
