import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { infoTechApi as api } from '../../../api/infoTech';
import { warehouseApi } from '../../../api/warehouse';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import { partnershipsApi } from '../../../api/partnerships';

// The table to show roles
const ViewJalaRolesTable = ({ parentRow, setExpandedRow }) => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'description', Header: 'Role' },
    ];

    let getItemsInTable = async () => {
        let response = await api.getUserRoles(parentRow.original.id);

        if (response.status === false) {
            setNotification({ text: 'No roles found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getItemsInTable();
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            <DataTable columns={columns} data={rows} />
        </div>
    );
};

let ViewJalapenoAccounts = ({ accountType = 'MISFITS MARKET' }) => {
    let [rows, setRows] = useState([]);
    let [facilities, setFacilities] = useState([]);
    let [profiles, setProfiles] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const [activeOnly, setActiveOnly] = useState(true);
    const [famAccounts, setFamAccounts] = useState([]);

    // Load accounts
    let getAccounts = async () => {
        let response = await api.getAccounts(activeOnly, accountType);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.profilesWithNames = row.profiles;
                row.profiles = row.profiles.map((item) => item.id);
                return row;
            });
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getAccounts();
    }, [activeOnly]);

    const getFamAccounts = async () => {
        const res = await partnershipsApi.getSources();
        if (!res.status) {
            return;
        }

        setFamAccounts(res.data.rows.map((row) => ({ text: row.name, value: row.id })));
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities();
        if (!response.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            row.disabled = !row.active;
            return row;
        });

        setFacilities(facilitiesAsOpts);
    };

    let getProfiles = async () => {
        let response = await api.getJalapenoProfiles(true, accountType);
        if (!response.status) {
            setNotification({ text: 'Failed to get profiles', level: 'error' });
            return;
        }

        let profileOpts = response.data.rows.map((row) => {
            return { text: row.name, value: row.id };
        });
        setProfiles(profileOpts);
    };

    useEffect(() => {
        getAccounts(true);
        getFacilities();
        getProfiles();
        getFamAccounts();
    }, []);

    let languages = [
        { text: 'English', value: 'EN' },
        { text: 'Spanish', value: 'ES' },
    ];

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'email', Header: 'Email', editable: true },
        {
            accessor: 'orderSourceID',
            Header: 'FAM Account',
            // editable: true,
            editProps: { type: 'select', options: famAccounts },
            hide: accountType !== 'FOOD AS MEDICINE',
        },
        { accessor: 'firstName', Header: 'First Name', editable: true },
        { accessor: 'lastName', Header: 'Last Name', editable: true },
        { accessor: 'badgeNumber', Header: 'Badge Number', editable: true, hide: accountType !== 'MISFITS MARKET' },
        {
            accessor: 'language',
            Header: 'Language',
            editable: true,
            hide: accountType !== 'MISFITS MARKET',
            editProps: { type: 'select', options: languages },
        },
        {
            accessor: 'facilityID',
            Header: 'Facility',
            editable: true,
            editProps: { type: 'select', options: facilities },
            hide: accountType !== 'MISFITS MARKET',
        },
        {
            accessor: 'profiles',
            Header: 'Profiles',
            editable: true,
            filterValues: (row) => row.original.profilesWithNames.map((item) => item.name),
            editProps: {
                type: 'autocomplete-multiple',
                options: profiles,
                multiple: true,
            },
        },
    ];

    return (
        <div>
            <h1>{accountType === 'MISFITS MARKET' ? 'Jalapeno' : 'Food As Medicine'} Accounts</h1>
            <Notifications options={notification} />

            <div>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="ActiveOnly_checkbox"
                                onChange={(event) => {
                                    setActiveOnly(event.target.checked);
                                }}
                                color="primary"
                                defaultChecked={true}
                            />
                        }
                        label="Active Only"
                    />
                </Grid>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    expandable={true}
                    ExpansionComponent={<ViewJalaRolesTable />}
                    saveEdit={async ({ id, field, value, row }) => {
                        let res = await api.updateAccount({ id: row.id, field, value });
                        if (!res.status) {
                            setNotification({ text: res.msg, level: 'error' });
                            return false;
                        }

                        setNotification({ text: 'Account updated', level: 'success' });
                        getAccounts();
                        return true;
                    }}
                />
            </div>
        </div>
    );
};

export { ViewJalapenoAccounts };
