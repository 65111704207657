import React, { useState } from 'react';
import { Typography, Box } from '@material-ui/core';

function TabPanel(props) {
    const { children, selectedTab, index, style, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {selectedTab === index && (
                <Box style={style ? style : {}} p={3}>
                    {children}
                </Box>
            )}
        </Typography>
    );
}

export { TabPanel };
