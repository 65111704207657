import { mmFetchWithResponse } from './mmFetchWithResponse';

let addressesApi = {
    getCountries: async () => {
        let url = `api/addresses/v1/countries?type=vendor`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
};

export { addressesApi };
