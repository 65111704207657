import React, { useState } from 'react';
import { FormComponent } from '../../../shared';
import { makeStyles } from '@material-ui/core/styles';

const custSearchByOptions = [
    { value: 'email', text: 'Email' },
    { value: 'name', text: 'Name' },
    { value: 'orderNumber', text: 'Order Number' },
    { value: 'subID', text: 'Subscription ID' },
    { value: 'custID', text: 'Customer ID' },
    { value: 'refCode', text: 'Referral Code' },
    { value: 'phoneNumber', text: 'Phone Number' },
    { value: 'customerAddress', text: 'Shipping Address' },
];

const CustomerSearchBar = ({ searchCustomers, searchType, searchValue }) => {
    const [searchBy, setSearchBy] = useState('');

    const useStyles = makeStyles((theme) => ({
        card: {
            backgroundClip: 'padding-box',
            background: '#fff',
            borderRadius: '5px',
            boxShadow: '0px 0px 25px rgba(45, 45, 45, 0.1)',
            height: '100%',
            marginBottom: '20px',
        },
    }));
    const classes = useStyles();

    const custSearchFormFields = [
        {
            name: 'searchBy',
            inputElement: 'select',
            initialValue: searchType ? searchType : 'email',
            gridValue: 3,
            inputProps: {
                required: true,
                opts: custSearchByOptions,
                onChange: (event, value) => {
                    setSearchBy(value);
                },
            },
        },
        {
            name: 'searchTerm',
            label: '',
            initialValue: searchValue ? searchValue : '',
            inputElement: 'textField',
            gridValue: 7,
            dependencies: ['searchBy'],
            inputProps:
                searchBy === 'phoneNumber'
                    ? {
                          required: true,
                          type: 'tel',
                          maxLength: 10,
                          integer: true,
                          onKeyPress: (event) => {
                              if ((event.charCode >= 48 && event.charCode <= 57) === false) {
                                  event.preventDefault();
                                  return false;
                              }
                          },
                      }
                    : { required: true },
        },
    ];

    return (
        <div className={classes.card}>
            <FormComponent
                formFields={custSearchFormFields}
                button={{
                    text: 'Search',
                    gridValue: 2,
                    style: {
                        margin: '10px',
                    },
                }}
                onSubmit={async (formData) => {
                    searchCustomers(formData);
                }}
            />
        </div>
    );
};

export { CustomerSearchBar };
