import React, { useState, useEffect, useContext } from 'react';
import { fbmApi as api } from '../../api/fbm';
import { Select, MenuItem } from '@material-ui/core';
import { UserContext } from '../../contexts/UserContext';

const FBMAccountPicker = ({ onChange, defaultValue = '', fromReportScreen = false }) => {
    const [fbmAccounts, setFBMAccounts] = useState([]);
    const [fbmAccountIDInternal, setFbmAccountIDInternal] = useState(defaultValue);

    const { user, fbmAccount, setFbmAccount } = useContext(UserContext);

    useEffect(() => {
        if (!user.id) {
            return;
        }
        if (fbmAccount?.id) {
            setFbmAccountIDInternal(fbmAccount.id);
        }

        if (user.fbm) {
            let accountName = user.fbmAccountName;
            getFBMAccount(accountName);
        } else {
            getFBMAccounts();
        }
    }, [user]);

    const getFBMAccount = async (accountName) => {
        const res = await api.getFBMAccounts(accountName, fromReportScreen);
        if (!res.status) {
            return;
        }
        setFBMAccounts([res.data.account]);
        setFbmAccountIDInternal(res.data.account.id);
    };

    const getFBMAccounts = async () => {
        const res = await api.getFBMAccounts('', fromReportScreen);
        if (!res.status) {
            return;
        }

        setFBMAccounts(res.data.accounts);
        if (!fbmAccount.id && res.data.accounts.length > 0) {
            setFbmAccountIDInternal(res.data.accounts[0].id);
        }
    };

    useEffect(() => {
        if (!!fbmAccountIDInternal) {
            let account = fbmAccounts.find((item) => item.id === fbmAccountIDInternal);
            if (account) {
                setFbmAccount(account);
            }
        }
    }, [fbmAccountIDInternal]);

    // This will be invisible for FBM users, it will just set the account
    if (user.fbm || !user.id) {
        return null;
    }

    return (
        <span>
            <h2>
                FBM Account &nbsp;&nbsp;
                <Select
                    id="Fbm_account_select"
                    value={fbmAccountIDInternal}
                    onChange={(event) => {
                        setFbmAccountIDInternal(event.target.value);
                    }}
                >
                    {fbmAccounts.map((item) => (
                        <MenuItem key={item.companyName} value={item.id}>
                            {item.companyName}
                        </MenuItem>
                    ))}
                </Select>
            </h2>
        </span>
    );
};

export { FBMAccountPicker };
