import React, { useState, useEffect } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

import { Snackbar, SnackbarContent, Grid } from '@material-ui/core';

const Notifications = function ({ options }) {
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [autoHide, setAutoHide] = useState(true);

    useEffect(() => {
        if (options.text && options.text !== '') {
            setSeverity(options.level);
            setMessage(options.text);
            if (options.autoHide != null) {
                setAutoHide(options.autoHide);
            }
            setOpen(true);
        }
    }, [options]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway' && autoHide) {
            return;
        }

        setOpen(false);
    };

    const classes = {
        error: {
            backgroundColor: '#f44336',
        },
        success: {
            backgroundColor: '#4caf50',
        },
        info: {
            backgroundColor: '#2196f3',
        },
        warning: {
            backgroundColor: '#ff9800',
        },
    };

    const renderIcon = (severity) => {
        switch (severity) {
            case 'error':
                return <ErrorOutlineIcon />;
            case 'warning':
                return <WarningIcon />;
            case 'info':
                return <InfoIcon />;
            case 'success':
                return <CheckCircleOutlineIcon />;
            default:
                return null;
        }
    };

    return (
        <div>
            <Snackbar
                id={`AlertNotification_${severity}`}
                open={open}
                autoHideDuration={autoHide ? 3000 : null}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    classes: {
                        root: classes.root,
                    },
                }}
            >
                <SnackbarContent
                    style={{ ...classes[severity], width: '500px' }}
                    message={
                        <Grid container direction="row" alignItems="center">
                            <Grid item style={{ paddingRight: '10px' }}>
                                {renderIcon(severity)}
                            </Grid>
                            <Grid item>{message}</Grid>
                        </Grid>
                    }
                />
            </Snackbar>
        </div>
    );
};

export { Notifications };
