import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { marketingApi as api } from '../../../api/marketing';

let CreateV2Discount = () => {
    let [notification, setNotification] = useState({ text: null, level: null });

    let [claimTypeOptions, setClaimTypeOptions] = useState([
        { text: 'Anyone', value: 'ANYONE' },
        { text: 'Auto Claim', value: 'AUTO_CLAIM' },
        { text: 'New Subscription', value: 'NEW_USER' },
        { text: 'Customer List', value: 'CUSTOMER_LIST' },
    ]);

    let [applyTypeOptions, setApplyTypeOptions] = useState([{ text: 'Time', value: 'TIME' }]);

    let [awardTypeOptions, setAwardTypeOptions] = useState([{ text: 'Threshold', value: 'THRESHOLD' }]);

    const PLACEHOLDER_TEXT = {
        NANOBAR_TEXT: 'Use code [promo] for [discount] off your [num_boxes] order',
        NANOBAR_TERMS:
            'Offer valid only for first-time customers and must be claimed by [expiration_date] at 11:59 p.m. ET with code [promo]. $30 order minimum. Discount will be applied at checkout towards your first order. Offer not valid for past purchases and may not be combined with other offers. Misfits Market is not yet available in all zip codes but is coming soon.',
    };

    let formFields = [
        /**
         * Common Form Section, used for all the discount types
         */
        { name: 'code', inputElement: 'textField', label: 'Code', gridValue: 12, inputProps: { required: true } },
        {
            name: 'claimStartDate',
            inputElement: 'date',
            label: 'Claim Start Date',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'claimByDate',
            inputElement: 'date',
            label: 'Claim By Date',
            gridValue: 4,
            inputProps: { required: true },
        },
        { name: 'useByDate', inputElement: 'date', label: 'Use By Date', gridValue: 4, inputProps: { required: true } },

        /**
         * *********************************************************************
         * * Claim Type Section, changes based on which claim type is selected *
         * *********************************************************************
         */
        {
            name: 'claimType',
            inputElement: 'select',
            label: 'Claim Type',
            gridValue: 12,
            inputProps: { required: true, opts: claimTypeOptions },
        },

        /**
         * Auto Claim form
         */
        {
            name: 'autoClaimFirstMarketplaceOpenDate',
            inputElement: 'date',
            label: 'First Marketplace Open Date',
            gridValue: 6,
            inputProps: { required: false },
            display: (formData) => (formData.claimType === 'AUTO_CLAIM' ? true : null),
            dependencies: ['claimType'],
        },
        {
            name: 'autoClaimLastMarketplaceOpenDate',
            inputElement: 'date',
            label: 'Last Marketplace Open Date',
            gridValue: 6,
            inputProps: { required: false },
            display: (formData) => (formData.claimType === 'AUTO_CLAIM' ? true : null),
            dependencies: ['claimType'],
        },

        /**
         * Customer List form
         */
        {
            name: 'customerListIsAutoClaim',
            inputElement: 'checkbox',
            label: 'Is Auto Deposited',
            gridValue: 6,
            inputProps: { required: false },
            initialValue: false,
            display: (formData) => (formData.claimType === 'CUSTOMER_LIST' ? true : null),
            dependencies: ['claimType'],
        },

        /**
         * *********************************************************************
         * * Apply Type Section, changes based on which apply type is selected *
         * *********************************************************************
         */
        {
            name: 'applyType',
            inputElement: 'select',
            label: 'Apply Type',
            gridValue: 12,
            inputProps: { required: true, opts: applyTypeOptions },
        },

        /**
         * Time Apply form
         */
        {
            name: 'timeMaxApplicationCount',
            inputElement: 'textField',
            label: 'Maximum Number Of Orders A Subscription Can Apply This Discount Too',
            gridValue: 12,
            inputProps: { required: false },
            display: (formData) => (formData.applyType === 'TIME' ? true : null),
            dependencies: ['applyType'],
        },

        /**
         * *********************************************************************
         * * Award Type Section, changes based on which award type is selected *
         * *********************************************************************
         */
        {
            name: 'awardType',
            inputElement: 'select',
            label: 'Award Type',
            gridValue: 12,
            inputProps: { required: true, opts: awardTypeOptions },
        },

        /**
         * Threshold Award form
         */
        {
            name: 'thresholdThresholdDollars',
            inputElement: 'textField',
            label: 'Threshold',
            gridValue: 12,
            inputProps: { required: false },
            display: (formData) => (formData.awardType === 'THRESHOLD' ? true : null),
            dependencies: ['awardType'],
        },
        {
            name: 'thresholdDollarDiscount',
            inputElement: 'textField',
            label: 'Dollar Discount',
            gridValue: 12,
            inputProps: { required: false },
            display: (formData) => (formData.awardType === 'THRESHOLD' ? true : null),
            dependencies: ['awardType'],
        },
        {
            name: 'thresholdPercentShippingDiscount',
            inputElement: 'textField',
            label: 'Percent Shipping Discount',
            gridValue: 12,
            inputProps: { required: false },
            display: (formData) => (formData.awardType === 'THRESHOLD' ? true : null),
            dependencies: ['awardType'],
        },
        {
            name: 'thresholdPercentDiscount',
            inputElement: 'textField',
            label: 'Percent Discount',
            gridValue: 12,
            inputProps: { required: false },
            display: (formData) => (formData.awardType === 'THRESHOLD' ? true : null),
            dependencies: ['awardType'],
        },
        {
            name: 'thresholdPercentDiscountDollarMaximum',
            inputElement: 'textField',
            label: 'Percent Discount Dollar Maximum',
            gridValue: 12,
            inputProps: { required: false },
            display: (formData) => (formData.awardType === 'THRESHOLD' ? true : null),
            dependencies: ['awardType'],
        },
        {
            name: 'nanobarText',
            inputElement: 'textField',
            label: 'Nanobar Text',
            gridValue: 12,
            inputProps: { required: false },
            initialValue: PLACEHOLDER_TEXT.NANOBAR_TEXT,
            display: (formData) =>
                formData.awardType === 'THRESHOLD' &&
                ['ANYONE', 'NEW_USER', 'CUSTOMER_LIST'].includes(formData.claimType)
                    ? true
                    : null,
            dependencies: ['claimType', 'awardType'],
        },
        {
            name: 'nanobarTerms',
            inputElement: 'textField',
            label: 'Nanobar Terms',
            gridValue: 12,
            inputProps: { required: false, multiline: true },
            initialValue: PLACEHOLDER_TEXT.NANOBAR_TERMS,
            display: (formData) =>
                formData.awardType === 'THRESHOLD' &&
                ['ANYONE', 'NEW_USER', 'CUSTOMER_LIST'].includes(formData.claimType)
                    ? true
                    : null,
            dependencies: ['claimType', 'awardType'],
        },
    ];

    return (
        <div>
            <h1>Create Discount Code</h1>
            <Notifications options={notification} />
            {}
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let response = await api.createV2Discount(formData);
                        if (response.status === false) {
                            setNotification({ text: 'Unable to create discount ' + response.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        resetForm();
                    }}
                />
            </div>
        </div>
    );
};

export { CreateV2Discount };
