import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import { DirectedReceivablePurchaseOrders } from './directedReceivablePurchaseOrders';
import { qualityApi } from '../../../api/quality';
import { TabPanel } from '../../../shared';

const InboundReceiving = ({ baseUrl }) => {
    const [directedReceivingPhase3Enabled, setDirectedReceivingPhase3Enabled] = useState(false);
    const user = useContext(UserContext);
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    useEffect(() => {
        let getDirectedRecPhase3Enabled = async () => {
            const res = await qualityApi.getDirectedRecPhase3Enabled(user.getFacilityID());
            if (!res.status) {
                return;
            }
            setDirectedReceivingPhase3Enabled(res.data.directedReceivingPhase3Enabled);
        };
        getDirectedRecPhase3Enabled();
    }, []);

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Receivable Purchase Orders" value="0" />
                <Tab label="Completed Purchase Orders" value="1" />
                {directedReceivingPhase3Enabled && <Tab label="Pending Actions" value="2" />}
                {directedReceivingPhase3Enabled && <Tab label="Issue Line Items" value="3" />}
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <DirectedReceivablePurchaseOrders showReceived={false} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <DirectedReceivablePurchaseOrders showReceived={true} />
            </TabPanel>
            {directedReceivingPhase3Enabled && (
                <TabPanel selectedTab={selectedTab} index={'2'}>
                    <DirectedReceivablePurchaseOrders showPendingActions={true} />
                </TabPanel>
            )}
            {directedReceivingPhase3Enabled && (
                <TabPanel selectedTab={selectedTab} index={'3'}>
                    <DirectedReceivablePurchaseOrders showPendingIssues={true} />
                </TabPanel>
            )}
        </Paper>
    );
};

export { InboundReceiving };
