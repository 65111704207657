import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { Notifications } from '../../../shared';
import { qualityApi } from '../../../api/quality';

const ItemDidNotArriveModal = ({ purchaseOrderMap, purchaseOrderId, open, handleClose }) => {
    const [notification, setNotification] = useState(null);

    let displaySku = 'unknown';

    if (purchaseOrderMap) {
        if (purchaseOrderMap.itemMasterSKU) {
            displaySku = purchaseOrderMap.itemMasterSKU;
        } else if (purchaseOrderMap.vendorSku) {
            displaySku = purchaseOrderMap.vendorSku;
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg" height="90%">
            <DialogTitle>Item Did Not Arrive</DialogTitle>
            <DialogContent>
                {notification && <Notifications options={notification} />}

                <h4>
                    Confirm that SKU "{displaySku}" on PO "{purchaseOrderId}" did not arrive.
                </h4>

                <Button
                    onClick={async () => {
                        let response = await qualityApi.didNotArrive(purchaseOrderMap.id);

                        if (response.status === false) {
                            setNotification({ text: response.msg, level: 'error' });
                            return;
                        }

                        handleClose();
                    }}
                >
                    Yes
                </Button>

                <Button onClick={handleClose}>No</Button>
            </DialogContent>
        </Dialog>
    );
};

export { ItemDidNotArriveModal };
