import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { infoTechApi as api } from '../../../api/infoTech';

let CreateEmployeeBox = () => {
    let [error, setError] = useState(null);
    let [notification, setNotification] = useState({ text: null, level: null });
    let formFields = [
        { name: 'email', inputElement: 'textField', label: 'Email', gridValue: 8, inputProps: { required: true } },
        {
            name: 'pickupLocationType',
            inputElement: 'select',
            label: 'Type',
            gridValue: 4,
            inputProps: {
                required: true,
                opts: [
                    { value: 'NO_PICKUP', text: 'No pickup' },
                    { value: 'FACILITY', text: 'Facility' },
                    { value: 'CROSS_DOCK', text: 'Cross Dock' },
                ],
            },
        },
    ];

    let createEmployeeBox = async (formData) => {
        let response = await api.createEmployeeBox(formData);
        if (response.status === true) {
            setNotification({ text: 'Box created!', level: 'success' });
        } else {
            let message = 'Error creating box';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
    };

    return (
        <div>
            <h1>Create Employee box</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        createEmployeeBox(formData);
                        resetForm();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

export { CreateEmployeeBox };
