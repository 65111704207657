const { REACT_APP_MAGIC_TOKEN = '' } = process.env;
let baseURL = '';

let { REACT_APP_BACKEND: apiBackend = '' } = process.env;

apiBackend = apiBackend.trim();

if (apiBackend) {
    baseURL = `https://${apiBackend}-dev.misfitsmarket.com`;
}

const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
    },
};

if (REACT_APP_MAGIC_TOKEN) {
    defaultOptions.headers['x-mm-dev-lettuce'] = REACT_APP_MAGIC_TOKEN;
}

const mmFetch = (url, options) => fetch(`${baseURL}/${url}`, { ...defaultOptions, ...options });

export { mmFetch };
