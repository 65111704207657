import React, { useState, useEffect } from 'react';
import { DataTable, FormComponent, Notifications } from '../../../shared';
import { procurementApi as api } from '../../../api/procurement';
import { addressesApi } from '../../../api/addresses';
import { catalogApi } from '../../../api/catalog';
import { Button, Dialog, DialogContent } from '@material-ui/core';

let StateCountryModal = ({
    editing,
    setEditing,
    row,
    countryOpts,
    provinceOpts,
    handleProvChange,
    country,
    setNotification,
    getShippers,
    mainCountryCodes,
    getProvinces,
}) => {
    useEffect(() => {
        getProvinces(country.text);
    }, []);

    return (
        <Dialog open={editing} onClose={() => setEditing(false)} fullWidth width="400px">
            <DialogContent>
                <FormComponent
                    formFields={[
                        {
                            name: 'province',
                            label: 'State/Province',
                            inputElement: 'select',
                            gridValue: 6,
                            inputProps: {
                                opts: provinceOpts,
                                required: mainCountryCodes.includes(country.value) ? true : false,
                            },
                            initialValue: row.original.province,
                        },
                        {
                            name: 'country',
                            label: 'Country',
                            inputElement: 'autocomplete',
                            gridValue: 6,
                            inputProps: { opts: countryOpts, onChange: handleProvChange },
                            initialValue: country,
                        },
                    ]}
                    onSubmit={async (formData) => {
                        let sendParams = {
                            country: formData.country,
                            province: mainCountryCodes.includes(formData.country) ? formData.province : 'N/A',
                        };

                        let response = await api.updateShipper(row.original.id, sendParams);
                        if (response.status === false) {
                            let message = 'Error modifying shipper';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Shipper modified!', level: 'success' });
                            getShippers();
                        }

                        setEditing(false);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let StateCountryCell = ({ row, countryOpts, getShippers, setNotification }) => {
    const [editing, setEditing] = useState(false);

    let OGstate = row.original.province;
    let OGcountry = row.original.country;

    const mainCountryCodes = ['US', 'MX', 'CA'];

    let [provinceOpts, setProvinceOpts] = useState([]);
    const [country, setCountry] = useState(countryOpts.find((c) => c.value === row.original.country));

    // Load state options
    let getProvinces = async (country) => {
        if (country === 'United States') {
            country = 'unitedstates';
        }

        if (country === undefined) {
            let pleaseSelect = { text: 'Please Select Country', value: 0 };
            setProvinceOpts([pleaseSelect]);
            return;
        }

        let response = await api.getProvinces(country);
        if (response.status === false) {
            setNotification({ text: 'No provinces found ' + response.msg, level: 'error' });
            let pleaseSelect = { text: 'Please Select Country', value: 0 };
            setProvinceOpts([pleaseSelect]);
            return;
        }

        let miniOpts = response.data.rows.map((row) => {
            let out = {};
            out.text = row.name;
            out.value = row.twoLetter;
            return out;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setProvinceOpts([pleaseSelect].concat(miniOpts));
    };

    let handleProvChange = async (e, newValue) => {
        e.preventDefault();
        if (newValue && newValue.value) {
            setCountry(newValue);
            if (mainCountryCodes.includes(newValue.value)) {
                getProvinces(newValue.text);
            } else {
                setProvinceOpts([]);
            }
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ margin: 'auto' }}>{!!OGstate ? `${OGstate}, ${OGcountry}` : OGcountry}</div>
            <div>
                <Button id={`StateCountry_Edit_${row.id}`} onClick={() => setEditing(true)}>
                    Edit
                </Button>
            </div>
            {editing && (
                <StateCountryModal
                    editing={editing}
                    setEditing={setEditing}
                    row={row}
                    country={country}
                    countryOpts={countryOpts}
                    provinceOpts={provinceOpts}
                    handleProvChange={handleProvChange}
                    setNotification={setNotification}
                    getShippers={getShippers}
                    mainCountryCodes={mainCountryCodes}
                    getProvinces={getProvinces}
                />
            )}
        </div>
    );
};

// Vendors
let ViewShipper = () => {
    let [rows, setRows] = useState([]);
    let [countryOpts, setCountryOpts] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let [levelOneTaxonomyOptions, setLevelOneTaxonomyOptions] = useState([]);

    // Load country options
    let getCountries = async () => {
        let response = await addressesApi.getCountries();
        if (response.status === false) {
            setNotification({ text: 'No countries found ' + response.msg, level: 'error' });
            let pleaseSelect = { text: 'Please Select', value: 0 };
            setCountryOpts([pleaseSelect]);
            return;
        }

        let countryAsOpts = response.data.rows.map((row) => {
            let out = {};
            out.text = row.name;
            out.value = row.countryCode;
            return out;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setCountryOpts([pleaseSelect].concat(countryAsOpts));
    };

    let getTaxonomy = async () => {
        let response = await catalogApi.getTaxonomyByLevel(1, 'TAXONOMY');

        if (!response.status) {
            setNotification({ text: 'Could not load taxonomy ' + response.msg, level: 'error' });
            return;
        }

        let options = response.data.categories.map((row) => {
            return {
                text: row.name,
                value: row.id,
            };
        });

        setLevelOneTaxonomyOptions([{ text: '[None]', value: 0 }].concat(options));
    };

    // Load
    let getShippers = async () => {
        let response = await api.getShippers();
        if (response.status === false) {
            setNotification({ text: 'No shippers found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        setRows(response.data.rows);
    };

    useEffect(() => {
        getTaxonomy();
        getShippers();
        getCountries();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'Shipper ID' },
        { accessor: 'name', Header: 'Name', editable: true },
        { accessor: 'email', Header: 'Email', editable: true },
        { accessor: 'firstName', Header: 'Rep First Name', editable: true },
        { accessor: 'lastName', Header: 'Rep Last Name', editable: true },
        { accessor: 'office', Header: 'Office', editable: true },
        { accessor: 'cell', Header: 'Cell', editable: true },
        { accessor: 'lineOne', Header: 'Line One', editable: true },
        { accessor: 'lineTwo', Header: 'Line Two', editable: true },
        { accessor: 'city', Header: 'City', editable: true },
        {
            Header: 'State/Country',
            width: '150px',
            Cell: ({ row }) => StateCountryCell({ row, countryOpts, getShippers, setNotification }),
        },
        { accessor: 'zip', Header: 'Zip', editable: true },
        {
            accessor: 'l1TaxonomyId',
            Header: 'Category',
            editable: true,
            editProps: {
                type: 'select',
                options: levelOneTaxonomyOptions,
            },
        },
    ];

    return (
        <div>
            <h1>Shippers</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        let update = {};
                        update[field] = value;

                        let response = await api.updateShipper(id, update);
                        if (response.status === false) {
                            let message = 'Error modifying shipper';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Shipper modified!', level: 'success' });
                            getShippers();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export { ViewShipper };
