import { mmFetchWithResponse } from './mmFetchWithResponse';

let opsVisibilityApi = {
    getOrderSources: async () => {
        let url = `api/operations/visibility/v1/orderSources`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getShipmentStatusFlowControl: async (facilityID) => {
        let url = `api/operations/visibility/v1/flowControl?facilityID=${facilityID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getShipmentStatusFlowControlWithFilters: async (facilityID, startDate, endDate, sendParams) => {
        let url = `api/operations/visibility/v1/flowControlFiltered?facilityID=${facilityID}&startDate=${startDate}&endDate=${endDate}`;

        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });

        return response;
    },
};

export { opsVisibilityApi };
