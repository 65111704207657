import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { FormComponent, Notifications } from '../../../shared';
import { productApi as api } from '../../../api/product';

let UploadFeatureFlag = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [ffOps, setFFOps] = useState([]);

    let formFields = [
        {
            name: 'featureFlag',
            inputElement: 'select',
            label: 'Value',
            gridValue: 4,
            inputProps: { required: true, opts: ffOps },
        },
        { name: 'wipeList', inputElement: 'checkbox', label: 'Wipe List', gridValue: 2 },
        {
            name: 'customerFile',
            inputElement: 'file',
            label: 'Customer IDs',
            gridValue: 6,
            inputProps: { required: true },
        },
    ];

    let getFeatureFlags = async () => {
        let response = await api.getAssignableFeatureFlags();
        if (response.status === false) {
            setNotification({ text: 'No features found ' + response.msg, level: 'error' });
            let pleaseSelect = { text: 'Please Select', value: 0 };
            setFFOps([pleaseSelect]);
            return;
        }

        let ops = response.data.rows.map((row) => {
            let out = {};
            out.text = row.feature;
            out.value = row.id;
            return out;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setFFOps([pleaseSelect].concat(ops));
    };

    useEffect(() => {
        getFeatureFlags();
    }, []);

    return (
        <div>
            <h1>Assign customers</h1>
            <Notifications options={notification} />
            {}
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let response = await api.uploadFeatureFlag(
                            formData.featureFlag,
                            formData.wipeList,
                            formData.customerFile
                        );
                        if (response.status === false) {
                            setNotification({ text: 'Unable to upload feature flag ' + response.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        resetForm();
                    }}
                />
            </div>
        </div>
    );
};

export { UploadFeatureFlag };
