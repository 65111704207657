import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { procurementApi } from '../../../api/procurement';
import { packagingApi as api } from '../../../api/packaging';

let CreatePackaging = () => {
    let [error, setError] = useState(null);
    let [vendors, setVendors] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [packagingTypesOpts, setPackagingTypesOpts] = useState([]);
    let [uomOps, setUOMOps] = useState(['ct', 'ft']);
    let [boxOpts, setBoxOpts] = useState([]);
    let [shippingMethodOpts, setShippingMethodOpts] = useState([]);

    let getPackagingTypes = async () => {
        let response = await api.getPackagingTypes();
        if (response.status === false) {
            setNotification({ text: 'No packaging types found ' + response.msg, level: 'error' });
            setPackagingTypesOpts([]);
            return;
        }

        setPackagingTypesOpts(
            response.data.rows.map((row) => {
                return {
                    text: row.display_name,
                    value: row.system_name,
                };
            })
        );
    };

    // Load vendors
    let getVendors = async () => {
        let response = await procurementApi.getVendors();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setVendors([]);
            return;
        }

        let vendorAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setVendors(vendorAsOpts);
    };

    useEffect(() => {
        getPackagingTypes();
        getVendors();
    }, []);

    let getBoxes = async () => {
        let response = await api.getBoxes('MISFITS_MARKET');
        if (response.status === false) {
            setNotification({ text: 'No Boxes found ' + response.msg, level: 'error' });
            setBoxOpts([]);
            return;
        }

        let boxOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setBoxOpts(boxOpts);
    };

    useEffect(() => {
        getBoxes();
    }, []);

    let getShippingMethods = async () => {
        let response = await api.getShippingMethods();
        if (response.status === false) {
            setNotification({ text: 'No shipping methods found ' + response.msg, level: 'error' });
            setShippingMethodOpts([]);
            return;
        }

        let shippingMethodOpts = response.data.shippingMethods.map((row) => {
            let opt = {};
            opt.text = row;
            opt.value = row;
            return opt;
        });

        setShippingMethodOpts(shippingMethodOpts);
    };

    useEffect(() => {
        getShippingMethods();
    }, []);

    // Define the form
    let formFields = [
        {
            name: 'vendorID',
            inputElement: 'autocomplete',
            label: 'Vendor',
            gridValue: 4,
            inputProps: { required: true, opts: vendors },
        },
        {
            name: 'materialNumber',
            inputElement: 'textField',
            label: 'Material Number',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'type',
            inputElement: 'select',
            label: 'Type',
            gridValue: 4,
            inputProps: { required: true, opts: packagingTypesOpts },
        },
        { name: 'cube', inputElement: 'textField', label: 'Cube', gridValue: 2, inputProps: { required: true } },
        {
            name: 'cubeLevelID',
            inputElement: 'select',
            label: 'Box Size',
            gridValue: 3,
            inputProps: { opts: boxOpts, required: true },
        },
        { name: 'size', inputElement: 'textField', label: 'Size', gridValue: 2, inputProps: { required: true } },
        {
            name: 'description',
            inputElement: 'textField',
            label: 'Description',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'shippingMethod',
            inputElement: 'select',
            label: 'Shipping Method',
            gridValue: 3,
            inputProps: { opts: shippingMethodOpts, required: true },
        },
        { name: 'sku', inputElement: 'textField', label: 'SKU', gridValue: 3, inputProps: { required: true } },
        {
            name: 'csPallet',
            inputElement: 'textField',
            label: 'Cases per Pallet',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'unitsPerCase',
            inputElement: 'textField',
            label: 'Units per Case',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'itemNameInternal',
            inputElement: 'textField',
            label: 'Internal Name',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'itemNameExternal',
            inputElement: 'textField',
            label: 'External Name',
            gridValue: 3,
            inputProps: { required: true },
        },
    ];

    return (
        <div>
            <h1>Create Packaging</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let response = await api.createPackaging(formData);
                        if (response.status === false) {
                            let message = 'Error creating packaging';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Packaging created!', level: 'success' });
                            resetForm();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

export { CreatePackaging };
