import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { TabPanel, Notifications } from '../../../shared';
import { ViewDriverTipsSettings } from '../components/viewDriverTipsSettings.js';
import { UploadDriverTipsSettings } from '../components/uploadDriverTipsSettings.js';

const DriverTipsSettings = () => {
    const [selectedTab, setSelectedTab] = useState('0');
    const [notification, setNotification] = useState({ text: '', level: '' });

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Driver Tips Settings" value="0" />
                <Tab label="Upload" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewDriverTipsSettings setNotification={setNotification} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <UploadDriverTipsSettings />
            </TabPanel>
        </Paper>
    );
};

export { DriverTipsSettings };
