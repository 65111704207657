import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { infoTechApi as api } from '../../../api/infoTech';
import { FormControlLabel, Checkbox, Grid, Modal, Button, Select, NativeSelect } from '@material-ui/core';

// The table to show roles
const ViewJalaProfileTable = ({ parentRow, setExpandedRow, accountType }) => {
    let [profileID, setProfileID] = useState(parentRow.original.id);
    let [rows, setRows] = useState([]);
    let [addRoleModalOpen, setAddRoleModalOpen] = useState(false);
    let [notification, setNotification] = useState({ text: null, level: null });
    const [roles, setRoles] = useState([]);

    const getRoles = async () => {
        let response = await api.getRoles(accountType);
        if (response.status === false) {
            setNotification({ text: 'No roles ' + response.msg, level: 'error' });
            setRoles([]);
            return;
        }

        let rolesAsOpts = response.data.rows.map((row) => {
            row.text = row.description;
            row.value = row.id;
            return row;
        });

        setRoles(rolesAsOpts);
    };

    let columns = [
        { accessor: 'id', Header: 'RoleID' },
        { accessor: 'description', Header: 'Name' },
        { accessor: 'longDescription', Header: 'Description' },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={async () => {
                                let response = await api.deleteRoleFromProfile(profileID, row.original.id);

                                if (response.status === false) {
                                    let message = 'Error removing role';
                                    if (response.msg) {
                                        message += ': ' + response.msg;
                                    }
                                    setNotification({ text: message, level: 'error' });
                                    return;
                                }

                                getItemsInTable();
                            }}
                        >
                            REMOVE
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getItemsInTable = async () => {
        let response = await api.getProfileRoles(profileID);

        if (response.status === false) {
            setNotification({ text: 'No roles found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getItemsInTable();
        getRoles();
    }, []);

    return (
        <div>
            <Notifications options={notification} />
            {addRoleModalOpen && (
                <AddRoleModal
                    profileID={profileID}
                    accountType={accountType}
                    closeModal={() => {
                        setAddRoleModalOpen(false);
                        getItemsInTable();
                    }}
                    refreshRoles={getItemsInTable}
                    roles={roles.filter((item) => !rows.map((row) => row.id).includes(item.value))}
                />
            )}
            <DataTable columns={columns} data={rows} />
            <Button
                onClick={() => {
                    setAddRoleModalOpen(true);
                }}
            >
                Add Role
            </Button>
        </div>
    );
};

// The modal to add roles to a profile
const AddRoleModal = ({ profileID, closeModal, roles, refreshRoles }) => {
    let [error, setError] = useState(null);

    let formFields = [
        {
            name: 'roleID',
            inputElement: 'autocomplete',
            label: 'Role',
            gridValue: 6,
            inputProps: { required: true, opts: [{ text: 'Please Select', value: 0 }, ...roles] },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '500px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let response = await api.addRoleToProfile(profileID, formData);
                        if (response.status === false) {
                            let message = 'Error adding item';
                            if (response.msg) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                            return;
                        }

                        refreshRoles();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

let ViewJalapenoProfiles = ({ accountType = 'MISFITS MARKET' }) => {
    let [rows, setRows] = useState([]);
    let [activeOnly, setActiveOnly] = useState(true);
    let [approvers, setApprovers] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    // Load  ttable
    let getRows = async (activeFlag) => {
        let response = await api.getJalapenoProfiles(activeFlag, accountType);
        if (response.status === false) {
            setNotification({ text: 'No profiles found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.approversWithNames = row.approvers;
                row.approvers = row.approvers.map((item) => item.id);
                return row;
            });
        }

        setRows(response.data.rows);
    };

    let getProfileApprovers = async () => {
        let response = await api.getProfileApprovers();
        if (!response.status) {
            setNotification({ text: 'Failed to get approvers', level: 'error' });
            return;
        }

        let approverOpts = response.data.rows.map((row) => {
            return { text: row.name, value: row.id };
        });
        setApprovers(approverOpts);
    };

    useEffect(() => {
        getRows(activeOnly);
        getProfileApprovers();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        {
            accessor: 'name',
            Header: 'Name',
            editable: true,
            editProps: { type: 'input', inputType: 'text', required: true },
        },
        {
            accessor: 'description',
            Header: 'Description',
            editable: true,
            editProps: { type: 'input', inputType: 'text', required: true },
        },
        {
            accessor: 'approvers',
            Header: 'Approvers',
            editable: true,
            filterValues: (row) => row.original.approversWithNames.map((item) => item.name),
            editProps: {
                type: 'autocomplete-multiple',
                options: approvers,
                multiple: true,
            },
        },
        { accessor: 'active', Header: 'Active', type: 'checkbox', editable: true, editProps: { type: 'checkbox' } },
    ];

    let formFields = [
        { name: 'name', inputElement: 'textField', label: 'Name', gridValue: 6, inputProps: { required: true } },
        {
            name: 'description',
            inputElement: 'textField',
            label: 'Description',
            gridValue: 6,
            inputProps: { required: true },
        },
    ];

    return (
        <div>
            <h1>Profiles</h1>
            <Notifications options={notification} />
            {}
            <div>
                <Grid item xs={12}>
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData, resetForm) => {
                            formData.accountType = accountType;
                            let response = await api.createProfile(formData);
                            if (response.status === false) {
                                let message = 'Error creating profile';
                                if (response.msg) {
                                    message += ': ' + response.msg;
                                }
                                setNotification({ text: message, level: 'error' });
                                return;
                            }
                            getRows(activeOnly);
                            resetForm();
                        }}
                    />
                </Grid>
                <br />
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="ActiveOnly_checkbox"
                                onChange={(event) => {
                                    setActiveOnly(event.target.checked);
                                    getRows(event.target.checked);
                                }}
                                color="primary"
                                defaultChecked={activeOnly}
                            />
                        }
                        label="Active Only"
                    />
                </Grid>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ row, field, value }) => {
                        let update = {};
                        update[field] = value;

                        let res = await api.updateProfile(row.id, update);
                        if (!res.status) {
                            setNotification({ text: res.msg, level: 'error' });
                            return false;
                        }

                        setNotification({ text: 'Profile updated', level: 'success' });
                        getRows(activeOnly);
                        return true;
                    }}
                    expandable={true}
                    ExpansionComponent={<ViewJalaProfileTable accountType={accountType} />}
                />
            </div>
        </div>
    );
};

export { ViewJalapenoProfiles };
