import React, { useState, useEffect } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { procurementApi as api } from '../../../api/procurement';
import { addressesApi } from '../../../api/addresses';
import { catalogApi } from '../../../api/catalog';

let CreateShipper = () => {
    let [error, setError] = useState(null);
    let [countryOpts, setCountryOpts] = useState([]);
    let [stateOpts, setStateOpts] = useState([]);
    let [country, setCountry] = useState('US');
    let [notification, setNotification] = useState({ text: null, level: null });
    let [levelOneTaxonomyOptions, setLevelOneTaxonomyOptions] = useState([]);

    const mainCountries = ['US', 'CA', 'MX'];

    // Load country options
    let getCountries = async () => {
        let response = await addressesApi.getCountries();
        if (response.status === false) {
            setNotification({ text: 'No countries found ' + response.msg, level: 'error' });
            setCountryOpts([]);
            return;
        }

        let countryAsOpts = response.data.rows.map((row) => {
            let out = {};
            out.text = row.name;
            out.value = row.countryCode;
            return out;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setCountryOpts([pleaseSelect].concat(countryAsOpts));
    };

    // Load country options
    let getStates = async (country) => {
        if (country === 'US') {
            country = 'unitedstates';
        }

        if (country === undefined) {
            let pleaseSelect = { text: 'Please Select Country', value: 0 };
            setStateOpts([pleaseSelect]);
            return;
        }

        let response = await api.getProvinces(country);
        if (response.status === false) {
            setNotification({ text: 'No provinces found ' + response.msg, level: 'error' });
            let pleaseSelect = { text: 'Please Select Country', value: 0 };
            setStateOpts([pleaseSelect]);
            return;
        }

        let provinceOpts = response.data.rows.map((row) => {
            let out = {};
            out.text = row.name;
            out.value = row.twoLetter;
            return out;
        });

        setStateOpts(provinceOpts);
    };

    let getTaxonomy = async () => {
        let response = await catalogApi.getTaxonomyByLevel(1, 'TAXONOMY');

        if (!response.status) {
            setNotification({ text: 'Could not load taxonomy ' + response.msg, level: 'error' });
            return;
        }

        const options = response.data.categories.map((row) => {
            return {
                text: row.name,
                value: row.id,
            };
        });

        setLevelOneTaxonomyOptions([{ text: 'Please Select', value: '' }].concat(options));
    };

    useEffect(() => {
        getTaxonomy();
        getCountries();
        getStates('US');
    }, []);

    let handleProvChange = async (event, newValue) => {
        if (newValue && newValue.value) {
            setCountry(newValue.value);
            if (mainCountries.includes(newValue.value)) {
                getStates(newValue.text);
            } else {
                setStateOpts([]);
            }
        }
    };

    let formFields = [
        {
            name: 'name',
            inputElement: 'textField',
            label: 'Shipper Name',
            gridValue: 6,
            inputProps: { required: true },
        },
        {
            name: 'firstName',
            inputElement: 'textField',
            label: 'Rep First Name',
            gridValue: 3,
            inputProps: { required: false },
        },
        {
            name: 'lastName',
            inputElement: 'textField',
            label: 'Rep Last Name',
            gridValue: 3,
            inputProps: { required: false },
        },
        { name: 'email', inputElement: 'textField', label: 'Email', gridValue: 4, inputProps: { required: false } },
        {
            name: 'office',
            inputElement: 'textField',
            label: 'Office',
            inputProps: { type: 'number', max: '99999999999', min: '1111111111' },
            gridValue: 4,
        },
        {
            name: 'cell',
            inputElement: 'textField',
            label: 'Cell',
            inputProps: { type: 'number', max: '99999999999', min: '1111111111' },
            gridValue: 4,
        },
        {
            name: 'lineOne',
            inputElement: 'textField',
            label: 'Address 1',
            gridValue: 12,
            inputProps: { required: true },
        },
        { name: 'lineTwo', inputElement: 'textField', label: 'Address 2', gridValue: 12 },
        { name: 'city', inputElement: 'textField', label: 'City', gridValue: 3, inputProps: { required: true } },
        {
            name: 'province',
            inputElement: 'select',
            label: 'State',
            gridValue: 3,
            inputProps: { required: mainCountries.includes(country) ? true : false, opts: stateOpts },
        },
        {
            name: 'country',
            initialValue: { text: 'United States', value: 'US' },
            inputElement: 'autocomplete',
            label: 'Country',
            gridValue: 3,
            inputProps: { required: true, opts: countryOpts, onChange: handleProvChange },
        },
        {
            name: 'zip',
            inputElement: 'textField',
            label: 'Zip Code',
            inputProps: { maxLength: 10, required: mainCountries.includes(country) ? true : false },
            gridValue: 3,
        },
        {
            name: 'l1TaxonomyId',
            inputElement: 'select',
            label: 'Category',
            inputProps: { required: false, opts: levelOneTaxonomyOptions },
            gridValue: 4,
        },
    ];

    let createShipper = async (formData, resetForm) => {
        let sendParams = {
            name: formData.name,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            office: formData.office,
            cell: formData.cell,
            address1: formData.lineOne,
            address2: formData.lineTwo,
            city: formData.city,
            country: formData.country,
            province: mainCountries.includes(country) ? formData.province : 'N/A',
            zip: formData.zip ? formData.zip : '99999',
            l1TaxonomyId: formData.l1TaxonomyId,
        };

        let response = await api.createShipper(sendParams);
        if (response.status === true) {
            setNotification({ text: 'Shipper created!', level: 'success' });
            resetForm();
        } else {
            let message = 'Error creating shipper';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        }
    };

    return (
        <div>
            <h1>Create Shipper</h1>
            <Notifications options={notification} />
            {}
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        createShipper(formData, resetForm);
                        setCountry('US');
                        getStates('US');
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
};

export { CreateShipper };
