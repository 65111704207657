import React, { useState, useEffect, useRef, useContext } from 'react';
import { Notifications, DataTable, FacilityPicker, FBMAccountPicker } from '../../../shared';
import { fbmApi as api } from '../../../api/fbm';
import { UserContext } from '../../../contexts/UserContext';
import { Grid } from '@material-ui/core';

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

let FBMViewShipments = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const [facilityID, setFacilityID] = useState(null);
    const { user, fbmAccount } = useContext(UserContext);

    useInterval(async () => {
        if (!!facilityID && !!fbmAccount.id) {
            getRows();
        }
    }, 30000);

    // Load rows
    let getRows = async () => {
        let response = await api.getShipmentStatus(facilityID, fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        if (!!user.id && !user.fbm) {
            setFacilityID(user.facilityID);
        }
    }, [user]);

    useEffect(() => {
        if (!!facilityID && !!fbmAccount.id) {
            getRows();
        }
    }, [facilityID, fbmAccount]);

    let cols = [
        { accessor: 'lineHaul', Header: 'Line Haul' },
        { accessor: 'expectedPackDay', Header: 'Expected Pack Day' },
        { accessor: 'expectedPullTime', Header: 'Expected Ship Time' },
        { accessor: 'PRE_PURCHASE', Header: 'Pre Purchase' },
        { accessor: 'LABEL_PURCHASED', Header: 'Label Purchased' },
        { accessor: 'CANCELLED', Header: 'Cancelled' },
        { accessor: 'PACKED', Header: 'Packed' },
        { accessor: 'IN_TRANSIT', Header: 'In Transit' },
        { accessor: 'DELIVERED', Header: 'Delivered' },
        { accessor: 'ISSUE', Header: 'Issue' },
        { accessor: 'totalShipments', Header: 'Total' },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item xs={12}>
                <FBMAccountPicker />
            </Grid>
            {!!fbmAccount.id && (
                <>
                    <Grid item md={6} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                        <h1>{fbmAccount.companyName} Shipment Status Report</h1>
                        <p>Note: This page refreshes automatically every 30 seconds</p>
                    </Grid>
                    <Grid item md={6} style={{ textAlign: 'right', paddingRight: '35px' }}>
                        {user.fbm && (
                            <FacilityPicker
                                defaultValue={fbmAccount.facilityIDs[0]}
                                whitelistedFacilities={fbmAccount.facilityIDs}
                                activeOnly={true}
                                onChange={(facilityID, text, facility) => {
                                    setFacilityID(facilityID);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable columns={cols} data={rows} />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export { FBMViewShipments };
