import moment from 'moment';

const dateHelper = {
    getWeekNumber: (dateString, format) => {
        const dateObject = moment(dateString, format);
        let currentWeekNumber = dateObject.format('W');
        if (dateObject.day() === 0 && currentWeekNumber < 53) currentWeekNumber++;
        return currentWeekNumber;
    },
};

export { dateHelper };
