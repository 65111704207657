import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { logisticsApi } from '../../../api/logistics';
import { UserContext } from '../../../contexts/UserContext';
import { Button, Modal } from '@material-ui/core';

const ResyncModal = ({ lineHaulID, hub, closeModal }) => {
    return (
        <div>
            <Modal open={true} onClose={closeModal}>
                <div>
                    <div
                        style={{
                            outline: 0,
                            backgroundColor: 'white',
                            width: '400px',
                            margin: '10% auto',
                            padding: '10px',
                        }}
                    >
                        You are about to re-sync {hub} ID {lineHaulID} to bring - Do you wish to proceed?
                        <div>
                            <Button
                                onClick={async () => {
                                    let res = await logisticsApi.resyncLineHaul(lineHaulID);
                                    console.log(res);
                                    if (res.status == false) {
                                        closeModal({ text: 'Error re-syncing line haul: ' + res.msg, level: 'error' });
                                        return;
                                    }
                                    closeModal({
                                        text: 'Successfully submitted re-synced line haul job. You will be emailed when the re-sync is complete',
                                        level: 'success',
                                    });
                                }}
                            >
                                Confirm
                            </Button>
                            <Button onClick={() => closeModal({})}>Cancel</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
const ResyncLineHaul = () => {
    const user = useContext(UserContext);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [showResyncModal, setShowResyncModal] = useState(false);
    let [modalParams, setModalParams] = useState({});
    let [rows, setRows] = useState([]);

    const getRecentLineHaulShippingDays = async () => {
        let response = await logisticsApi.getRecentLineHaulShippingDays(user.getFacilityID(), true);

        if (response.status === false) {
            setNotification({ text: 'Error getting recent shipping day: '.response.msg, level: 'error' });
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getRecentLineHaulShippingDays();
    }, []);

    const columns = [
        { accessor: 'lineHaulID', Header: 'ID' },
        { accessor: 'hub', Header: 'Hub' },
        { accessor: 'preferredDay', Header: 'Preferred Day' },
        {
            accessor: 'resync',
            Header: 'Resync',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={() => {
                            setModalParams({
                                lineHaulID: row.original.lineHaulID,
                                preferredDay: row.original.preferredDay,
                                hub: row.original.hub,
                            });
                            setShowResyncModal(true);
                        }}
                    >
                        Resync
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            {showResyncModal && (
                <ResyncModal
                    lineHaulID={modalParams.lineHaulID}
                    hub={modalParams.hub}
                    closeModal={(notificationPassback) => {
                        setModalParams({});
                        setShowResyncModal(false);
                        setNotification(notificationPassback);
                    }}
                />
            )}
            <DataTable columns={columns} data={rows} />
        </div>
    );
};

export { ResyncLineHaul };
