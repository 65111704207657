import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, TabPanel, FormComponent } from '../../../shared';
import { growthApi as api } from '../../../api/growth';
import {
    Button,
    Grid,
    TextField,
    Paper,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Tabs,
    Modal,
    Tab,
} from '@material-ui/core';

let CategoriesComponent = ({ categories, getCategories }) => {
    let [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
    let [notification, setNotification] = useState({ text: null, level: null });

    let createCategory = async (category) => {
        let res = await api.createNPSCategory(category);
        if (res.status === true) {
            getCategories();
        } else {
            setNotification({ text: 'Failed to create category', level: 'error' });
        }
    };

    let columns = [
        { accessor: 'id', Header: 'id', editable: false },
        { accessor: 'category', Header: 'Category', editable: false },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            {addCategoryModalOpen === true && (
                <AddCategoryModal createCategory={createCategory} setAddCategoryModalOpen={setAddCategoryModalOpen} />
            )}
            <DataTable
                columns={columns}
                data={categories}
                toolbarActions={[
                    {
                        name: 'Add Category',
                        action: () => {
                            setAddCategoryModalOpen(true);
                        },
                    },
                ]}
            />
        </Grid>
    );
};

let AddCategoryModal = ({ setAddCategoryModalOpen, createCategory }) => {
    let [category, setCategory] = useState('');
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={true}
            onClose={() => {
                setAddCategoryModalOpen(false);
            }}
        >
            <DialogTitle>Add New Category</DialogTitle>
            <DialogContent>
                <TextField
                    label="Category"
                    value={category}
                    onChange={(event) => {
                        setCategory(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={async () => {
                        await createCategory(category);
                        setAddCategoryModalOpen(false);
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

let AddSentimentCategoryModal = ({ npsID, categories, closeModal, setNotification }) => {
    let formFields = [
        {
            name: 'sentiment',
            inputElement: 'select',
            label: 'Sentiment',
            gridValue: 4,
            inputProps: { opts: ['POSITIVE', 'NEGATIVE', 'NEUTRAL'], required: true },
        },
        {
            name: 'category',
            inputElement: 'select',
            label: 'Category',
            gridValue: 6,
            inputProps: { opts: categories, required: true },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let res = await api.createNPSSentCatCombo(npsID, formData);

                        if (!res.status) {
                            setNotification({
                                text: 'Failed to add sentiment/category combo. ' + res.msg,
                                level: 'error',
                            });
                            return false;
                        }

                        closeModal();
                    }}
                />
            </div>
        </Modal>
    );
};

let SentimentCategoryTable = ({ parentRow, categories }) => {
    let [rows, setRows] = useState([]);
    let [npsID, setNpsID] = useState(parentRow.original.id);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [addSentCatModalOpen, setAddSentCatModalOpen] = useState(false);

    //get sentiment and categories for expandable rows
    let getSentimentCategories = async () => {
        let response = await api.getNPSSentimentCategories(npsID);
        if (response.status === false) {
            setNotification({ text: 'Failed to get category/sentiment combos', level: 'error' });
            return;
        }
        setRows(response.data.rows);
    };

    let columns = [
        {
            Header: 'Sentiment',
            accessor: 'sentiment',
            editable: true,
            editProps: {
                type: 'select',
                options: ['POSITIVE', 'NEGATIVE', 'NEUTRAL'],
            },
        },
        {
            Header: 'Category',
            accessor: 'category',
            editable: true,
            width: '350px',
            editProps: {
                type: 'select',
                options: categories,
            },
        },
        {
            accessor: 'remove',
            Header: 'Delete',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={async () => {
                            let response = await api.deleteSentCatCombo(row.original.id);

                            if (response.status === false) {
                                setNotification({ text: "Can't delete item " + response.msg, level: 'error' });
                                return;
                            }

                            setNotification({ text: 'Sentiment/Category combo deleted.', level: 'success' });
                            getSentimentCategories();
                        }}
                    >
                        Delete
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        getSentimentCategories();
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            {addSentCatModalOpen && (
                <AddSentimentCategoryModal
                    npsID={npsID}
                    categories={categories}
                    setNotification={setNotification}
                    closeModal={() => {
                        setAddSentCatModalOpen(false);
                        getSentimentCategories();
                    }}
                />
            )}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value, row }) => {
                        let data = { [field]: value };

                        if ([field] == 'sentiment') {
                            data['unchangedVal'] = row['category'];
                        } else {
                            data['unchangedVal'] = row['sentiment'];
                        }

                        let res = await api.updateNPSResult(npsID, id, data);
                        if (!res.status) {
                            setNotification({ text: 'Failed to update. ' + res.msg, level: 'error' });
                            return false;
                        }

                        setNotification({ text: 'Sentiment/Category combo updated.', level: 'success' });
                        getSentimentCategories();
                        return true;
                    }}
                />
            </div>
            <Button
                onClick={() => {
                    setAddSentCatModalOpen(true);
                }}
            >
                Add Line
            </Button>
        </div>
    );
};

let NPSScores = ({ categories }) => {
    const [npsRows, setNpsRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });

    let getNPSResults = async () => {
        let res = await api.getNPSResults();
        if (!res.status) {
            setNotification({ text: 'Failed to get nps results', level: 'error' });
            return;
        }

        setNpsRows(res.data.rows);
    };

    useEffect(() => {
        getNPSResults();
    }, []);

    let columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Score', accessor: 'npsScore' },
        { Header: 'Reason', accessor: 'reason' },
    ];

    return (
        <>
            <Notifications options={notification} />
            <DataTable
                data={npsRows}
                columns={columns}
                expandable={true}
                ExpansionComponent={
                    <SentimentCategoryTable setNotification={setNotification} categories={categories} />
                }
            />
        </>
    );
};

let NPSResults = () => {
    let [categories, setCategories] = useState([]);
    let [selectedTab, setSelectedTab] = useState('0');
    let [categoryOptions, setCategoryOptions] = useState([]);

    let getCategories = async () => {
        let res = await api.getNPSCategories();
        if (res.status === true) {
            setCategories(res.data.rows);
            setCategoryOptions(res.data.rows.map((element) => element.category));
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="NPS Scores" value="0" />
                <Tab label="Categories" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <NPSScores categories={categoryOptions} getCategories={getCategories} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CategoriesComponent categories={categories} getCategories={getCategories} />
            </TabPanel>
        </Paper>
    );
};

export { NPSResults };
