import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Grid,
    TextField,
    Tabs,
    Tab,
    Paper,
    InputLabel,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
} from '@material-ui/core';
import { Notifications, TabPanel, DataTable, FormComponent } from '../../../shared';
import { catalogApi as api } from '../../../api/catalog';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/DoneOutline';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../../contexts/UserContext';

const ItemNameIcon = ({ item, selectedItemID, setSelectedItem }) => {
    if (item.level === 4) {
        return null;
    }
    if (item.id === selectedItemID) {
        return <KeyboardArrowDownIcon id={`${item.name.replaceAll(' ', '')}_DownArrow`} />;
    }
    return <KeyboardArrowRightIcon id={`${item.name.replaceAll(' ', '')}_RightArrow`} />;
};

const CreateTaxonomy = ({ createTaxonomy, level, cancel, type, setNotification }) => {
    const [input, setInput] = useState('');
    const [targetProductMarginInput, setTargetProductMarginInput] = useState('');
    const [aisleImageValue, setAisleImageValue] = useState('');
    const [aisleImageSource, setAisleImageSource] = useState(null);
    const [rankInput, setRankInput] = useState('');
    const [submitting, setSubmitting] = useState(false);

    return (
        <div style={{ paddingLeft: '30px' }}>
            <TextField
                id="Name_TextField"
                label="Name"
                onClick={(e) => e.stopPropagation()}
                style={{ marginRight: '10px' }}
                value={input}
                onChange={(event) => {
                    setInput(event.target.value);
                }}
            />
            {level >= 1 && type === 'TAXONOMY' && (
                <>
                    <TextField
                        id="TargetProductMargin_TextField"
                        label="Target Product Margin"
                        type="number"
                        inputProps={{
                            min: 0,
                            max: 1,
                        }}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            marginRight: '10px',
                            width: '170px',
                        }}
                        value={targetProductMarginInput}
                        onChange={(event) => {
                            setTargetProductMarginInput(event.target.value);
                        }}
                    />
                </>
            )}
            {type === 'AISLE' && (
                <TextField
                    label="Rank Number"
                    onClick={(e) => e.stopPropagation()}
                    style={{ marginRight: '10px' }}
                    value={rankInput}
                    onChange={(event) => {
                        setRankInput(event.target.value);
                    }}
                    onKeyPress={(event) => {
                        if ((event.charCode >= 48 && event.charCode <= 57) === false) {
                            event.preventDefault();
                            return false;
                        }
                    }}
                />
            )}
            {type === 'AISLE' && (
                <div style={{ display: 'inline-block' }}>
                    <div>Aisle Icon</div>
                    {aisleImageSource && (
                        <div style={{ textAlign: 'center' }}>
                            <img src={aisleImageSource} style={{ width: '100px', height: '100px' }} alt="Preview" />
                        </div>
                    )}
                    <Button id="ChooseFile" style={{ textAlign: 'center' }} variant="outlined" component="label">
                        Choose File
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            name="Photo Upload"
                            id="photo-file-input"
                            accept="image/png, image/jpeg"
                            onChange={(event) => {
                                let file = event.target.files[0];
                                setAisleImageValue(file);

                                let reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = (e) => {
                                    setAisleImageSource(e.target.result);
                                };
                            }}
                        />
                    </Button>
                </div>
            )}
            <Button
                id="Complete"
                variant="outlined"
                color="primary"
                disabled={submitting}
                onClick={async (e) => {
                    e.stopPropagation();
                    if (targetProductMarginInput > 1 || targetProductMarginInput < 0) {
                        setNotification({ text: 'Target Product Margin must be between 0 and 1', level: 'error' });
                        return;
                    }
                    setSubmitting(true);
                    let res = await createTaxonomy(input, rankInput, targetProductMarginInput, aisleImageValue);
                    if (!res) {
                        setSubmitting(false);
                        return;
                    }
                }}
            >
                <CheckIcon />
            </Button>
            <Button
                id="Clear"
                variant="outlined"
                color="secondary"
                disabled={submitting}
                onClick={() => {
                    cancel();
                }}
            >
                <ClearIcon />
            </Button>
        </div>
    );
};

const DeleteAisleModal = ({ setNotification, open, aisle, setDeleteModalOpen, getItemsByParentID }) => {
    const [taxonomyDetails, setTaxonomyDetails] = useState(null);
    const [taxonomy, setTaxonomy] = useState({ l1: [], l2: [], l3: [], l4: [] });
    const dataTableRef = React.createRef();
    const { user } = useContext(UserContext);

    const getTaxonomyDetails = async () => {
        let res = await api.getTaxonomyDetailsByID(aisle.id);
        if (!res.status) {
            setNotification({ text: 'Failed to get taxonomy details', level: 'error' });
        }
        setTaxonomyDetails(res.data);
    };

    const deleteAisle = async () => {
        let res = await api.deleteTaxonomy(aisle.id);
        if (!res.status) {
            setNotification({ text: 'Failed to delete taxonomy. ' + res.msg, level: 'error' });
            return;
        }

        setNotification({ text: 'Taxonomy deleted', level: 'success' });
        getItemsByParentID();
        setDeleteModalOpen(false);
    };

    useEffect(() => {
        getTaxonomyDetails();
        getTaxonomy();
    }, []);

    const getTaxonomyOptions = (row, level) => {
        let parentID = level > 1 ? Number(row[`l${level - 1}TaxonomyID`]) : 0;
        let opts = taxonomy[`l${level}`]
            .filter((item) => item.parentID == parentID)
            .map((item) => ({ text: item.name, value: item.id }));
        if (level > 2) {
            opts.unshift({ text: '', value: null });
        }
        return opts;
    };

    let getTaxonomy = async () => {
        let promises = [1, 2, 3, 4].map((lvl) => api.getTaxonomyByLevel(lvl, 'TAXONOMY'));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setTaxonomy({
                l1: res[0].data.categories,
                l2: res[1].data.categories,
                l3: res[2].data.categories,
                l4: res[3].data.categories,
            });
        }
    };

    const reassignTaxonomyFormFields = [
        {
            name: 'l1TaxonomyID',
            inputElement: 'select',
            label: 'Item Category One',
            gridValue: 3,
            inputProps: { required: true, opts: (formData) => getTaxonomyOptions(formData, 1) },
        },
        {
            name: 'l2TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Two',
            gridValue: 3,
            dependencies: ['l1TaxonomyID'],
            inputProps: { required: true, opts: (formData) => getTaxonomyOptions(formData, 2) },
        },
        {
            name: 'l3TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Three',
            gridValue: 3,
            dependencies: ['l1TaxonomyID', 'l2TaxonomyID'],
            inputProps: { required: true, opts: (formData) => getTaxonomyOptions(formData, 3) },
        },
        {
            name: 'l4TaxonomyID',
            inputElement: 'select',
            label: 'Item Category Four',
            gridValue: 3,
            dependencies: ['l1TaxonomyID', 'l2TaxonomyID', 'l3TaxonomyID'],
            inputProps: {
                opts: (formData) => getTaxonomyOptions(formData, 4),
            },
        },
    ];

    return (
        <Dialog
            open={open}
            onClose={() => {
                setDeleteModalOpen(false);
            }}
            fullWidth={true}
            maxWidth="lg"
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle>
                DELETE {aisle.type}: {aisle.name}
            </DialogTitle>
            <DialogContent>
                {taxonomyDetails !== null && (
                    <>
                        {taxonomyDetails.itemsInTaxonomy.length > 0 ? (
                            <>
                                {[2, 3, 4].includes(aisle.level) &&
                                    aisle.type === 'TAXONOMY' &&
                                    user.canBulkUpdateTaxonomy && (
                                        <>
                                            <h3>Reassign All Items</h3>
                                            <FormComponent
                                                formFields={reassignTaxonomyFormFields}
                                                button={{ text: 'Reassign' }}
                                                onSubmit={async (formData, resetForm) => {
                                                    /**
                                                     * Any skus un-selected in the DataTable should not be reassigned
                                                     */
                                                    const selectedRows = dataTableRef?.current?.getSelectedRows() || [];
                                                    const excludedExternalSkus = taxonomyDetails.itemsInTaxonomy
                                                        .filter((item) => !selectedRows.includes(item.externalSku))
                                                        .map((item) => item.externalSku);

                                                    const sendParams = {
                                                        l1TaxonomyID: formData.l1TaxonomyID,
                                                        l2TaxonomyID: formData.l2TaxonomyID,
                                                        l3TaxonomyID: formData.l3TaxonomyID,
                                                        l4TaxonomyID: formData.l4TaxonomyID,
                                                        originalTaxonomyID: aisle.id,
                                                        excludedExternalSkus: excludedExternalSkus,
                                                    };

                                                    const res = await api.bulkUpdateItemInfoTaxonomy(sendParams);
                                                    if (!res.status) {
                                                        setNotification({
                                                            level: 'error',
                                                            text: `Failed to update taxonomy. ${res.msg}`,
                                                        });
                                                        return;
                                                    }
                                                    getTaxonomyDetails();
                                                    resetForm();
                                                }}
                                            />
                                        </>
                                    )}
                                <div style={{ color: 'red', textAlign: 'center' }}>
                                    These items must be reassigned before deleting {aisle.name}
                                </div>
                                <DataTable
                                    ref={dataTableRef}
                                    csvExport={true}
                                    selectMultiple={
                                        [2, 3, 4].includes(aisle.level) &&
                                        aisle.type === 'TAXONOMY' &&
                                        user.canBulkUpdateTaxonomy
                                    }
                                    preSelectedRowIDs={
                                        [2, 3, 4].includes(aisle.level) &&
                                        aisle.type === 'TAXONOMY' &&
                                        user.canBulkUpdateTaxonomy
                                            ? taxonomyDetails.itemsInTaxonomy.map((item) => item.externalSku)
                                            : []
                                    }
                                    title={`Skus assigned to ${aisle.name}`}
                                    data={taxonomyDetails.itemsInTaxonomy.map((item) => ({
                                        id: item.externalSku,
                                        ...item,
                                    }))}
                                    columns={[
                                        { Header: 'External Sku', accessor: 'externalSku' },
                                        { Header: 'Name', accessor: 'name' },
                                    ]}
                                />
                            </>
                        ) : (
                            <div>No skus assigned to this taxonomy 🙂</div>
                        )}
                        {taxonomyDetails.subTaxonomies.length > 0 && (
                            <>
                                <div style={{ textAlign: 'center' }}>
                                    Deleting {aisle.name} will also delete these taxonomies
                                </div>
                                <DataTable
                                    title="Sub Taxonomies"
                                    data={taxonomyDetails.subTaxonomies}
                                    columns={[
                                        { Header: 'ID', accessor: 'id' },
                                        { Header: 'Name', accessor: 'name' },
                                    ]}
                                />
                            </>
                        )}
                    </>
                )}
            </DialogContent>
            {taxonomyDetails !== null && (
                <DialogActions>
                    <Button
                        id={`${aisle.name.replaceAll(' ', '')}_Delete`}
                        disabled={taxonomyDetails.itemsInTaxonomy.length > 0}
                        onClick={() => {
                            deleteAisle();
                        }}
                    >
                        Delete {aisle.name}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

const TaxonomyItem = ({
    item,
    selectedItemID,
    setSelectedItem,
    level,
    setNotification,
    editTaxonomy,
    type,
    setDeleteModalOpen,
    setAisleForDelete,
}) => {
    const [editing, setEditing] = useState(false);
    const [nameValue, setNameValue] = useState(item.name);
    const [rankNumValue, setRankNumValue] = useState(item.rankNumber);
    const [aisleImageValue, setAisleImageValue] = useState('');
    const [aisleImageSource, setAisleImageSource] = useState(null);
    const [removeAisleImage, setRemoveAisleImage] = useState(false);

    return (
        <Grid
            item
            container
            style={{ fontSize: '20px', color: selectedItemID === item.id || !selectedItemID ? 'black' : 'darkgrey' }}
            onClick={(e) => {
                e.stopPropagation();
                if (editing) {
                    return;
                }
                if (item.level === 4) {
                    return;
                }
                setSelectedItem(selectedItemID === item.id ? {} : item);
            }}
        >
            <ItemNameIcon item={item} selectedItemID={selectedItemID} xs={1} />
            <div xs={3} style={{ width: '300px' }}>
                {editing ? (
                    <>
                        <TextField
                            id={`${item.name.replaceAll(' ', '')}_TextField`}
                            label="Name"
                            value={nameValue}
                            onChange={(event) => {
                                setNameValue(event.target.value);
                            }}
                        />
                    </>
                ) : (
                    <>
                        <InputLabel shrink={true}>Name</InputLabel>
                        <span>{item.name}</span>
                    </>
                )}
            </div>
            {item.type === 'AISLE' && (
                <div xs={3} style={{ marginLeft: 20 }}>
                    {editing && item.type === 'AISLE' ? (
                        <TextField
                            id="RankNumber_TextField"
                            label="Rank Number"
                            value={rankNumValue}
                            onChange={(event) => {
                                setRankNumValue(event.target.value);
                            }}
                        />
                    ) : (
                        <>
                            <InputLabel shrink={true}>Rank</InputLabel>
                            <span>{item.rankNumber}</span>
                        </>
                    )}
                </div>
            )}
            {item.type === 'AISLE' && (
                <div xs={3} style={{ marginLeft: 20 }}>
                    {editing ? (
                        <div>
                            {item.aisleImageUrl && !aisleImageSource && !removeAisleImage && (
                                <img src={item.aisleImageUrl} style={{ width: '100px', height: '100px' }} alt="Item" />
                            )}
                            {aisleImageSource && (
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        src={aisleImageSource}
                                        style={{ width: '100px', height: '100px' }}
                                        alt="Preview"
                                    />
                                </div>
                            )}
                            <div>
                                <Button
                                    id="ChooseFile"
                                    style={{ textAlign: 'center' }}
                                    variant="outlined"
                                    component="label"
                                >
                                    Aisle Icon
                                    <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="Photo Upload"
                                        id="photo-file-input"
                                        accept="image/png, image/jpeg"
                                        onChange={(event) => {
                                            let file = event.target.files[0];
                                            setAisleImageValue(file);

                                            let reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            reader.onload = (e) => {
                                                setAisleImageSource(e.target.result);
                                            };
                                        }}
                                    />
                                </Button>
                            </div>
                            {item.aisleImageUrl && !aisleImageSource && (
                                <div>
                                    <Button
                                        color="secondary"
                                        style={{ textAlign: 'center' }}
                                        variant="outlined"
                                        component="label"
                                        onClick={() => {
                                            setRemoveAisleImage(true);
                                        }}
                                    >
                                        Remove Aisle Icon
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {!!item.aisleImageUrl && (
                                <img src={item.aisleImageUrl} style={{ height: '100px', width: '100px' }} />
                            )}
                        </div>
                    )}
                </div>
            )}
            {(selectedItemID === item.id || !selectedItemID) && (
                <span>
                    {editing ? (
                        <>
                            <span
                                style={{ marginLeft: '20px', padding: '5px', cursor: 'pointer' }}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    let res;
                                    if (nameValue !== item.name) {
                                        res = await editTaxonomy(item.id, 'name', nameValue);
                                    }
                                    if (item.type === 'AISLE' && Number(rankNumValue) !== item.rankNumber) {
                                        res = await editTaxonomy(item.id, 'rankNumber', rankNumValue);
                                    }
                                    if (item.type === 'AISLE' && !!aisleImageValue) {
                                        res = await editTaxonomy(item.id, 'aisleImage', aisleImageValue);
                                        setAisleImageSource(null);
                                        setAisleImageValue('');
                                    }
                                    if (item.type === 'AISLE' && removeAisleImage) {
                                        res = await editTaxonomy(item.id, 'aisleImage', '');
                                        setRemoveAisleImage(false);
                                    }
                                    if (res) {
                                        setEditing(false);
                                    }
                                }}
                            >
                                <DoneIcon
                                    id={`${item.name.replaceAll(' ', '')}_Done`}
                                    style={{ fontSize: '15px', color: 'lightgray' }}
                                />
                            </span>
                            <span
                                style={{ marginLeft: '15px', padding: '5px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditing(false);
                                    setNameValue(item.name);
                                    setRankNumValue(item.rankNumber);
                                    setAisleImageValue('');
                                    setAisleImageSource(null);
                                    setRemoveAisleImage(false);
                                }}
                            >
                                <CloseIcon
                                    id={`${item.name.replaceAll(' ', '')}_Close`}
                                    style={{ fontSize: '15px', color: 'lightgray', marginLeft: '10px' }}
                                />
                            </span>
                        </>
                    ) : (
                        <>
                            <span
                                style={{ marginLeft: '20px', padding: '5px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditing(true);
                                }}
                            >
                                <EditIcon
                                    id={`${item.name.replaceAll(' ', '')}_Edit`}
                                    style={{ fontSize: '15px', color: 'lightgray' }}
                                />
                            </span>
                            <Button
                                id={`${item.name.replaceAll(' ', '')}_Delete`}
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setAisleForDelete(item);
                                    setDeleteModalOpen(true);
                                }}
                            >
                                DELETE
                            </Button>
                        </>
                    )}
                </span>
            )}
            {selectedItemID === item.id && (
                <TaxonomyLevel
                    level={level + 1}
                    parentID={selectedItemID}
                    setNotification={setNotification}
                    type={type}
                    setDeleteModalOpen={setDeleteModalOpen}
                    setAisleForDelete={setAisleForDelete}
                />
            )}
        </Grid>
    );
};

const TaxonomyLevel = ({ level = 1, parentID = 0, setNotification, type }) => {
    const [selectedItem, setSelectedItem] = useState({});
    const [categories, setCategories] = useState([]);
    const [creatingCategory, setCreatingCategory] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [aisleForDelete, setAisleForDelete] = useState(null);

    useEffect(() => {
        if (!deleteModalOpen) {
            setAisleForDelete(null);
        }
    }, [deleteModalOpen]);

    const createTaxonomy = async (name, rankNumber, targetProductMargin, aisleImage) => {
        let res = await api.createTaxonomy({
            name,
            level,
            parentID,
            type,
            rankNumber,
            targetProductMargin,
            aisleImage,
        });
        if (!res.status) {
            setNotification({ text: 'Failed to create new category ' + res.msg, level: 'error' });
            return false;
        }
        setCreatingCategory(false);
        getItemsByParentID();
        return true;
    };

    const editTaxonomy = async (id, field, value) => {
        let res = await api.editTaxonomy(id, field, value);
        if (!res.status) {
            setNotification({ text: 'Error updating taxonomy: ' + res.msg, level: 'error' });
            return false;
        }
        setNotification({ text: 'Item updated', level: 'success' });
        getItemsByParentID();
        return true;
    };

    const getItemsByParentID = async () => {
        let res = await api.getTaxonomyByParentID(parentID, type);
        if (!res.status) {
            setNotification({ text: `Failed to get level ${level} categories`, level: 'error' });
            return;
        }

        setCategories(res.data.categories);
    };

    useEffect(() => {
        getItemsByParentID();
    }, []);

    useEffect(() => {
        if (selectedItem.id) {
            setCreatingCategory(false);
        }
    }, [selectedItem]);

    if (level > 4) {
        return null;
    }

    return (
        <Grid container style={{ paddingLeft: '50px', textAlign: 'left' }}>
            {!!aisleForDelete && (
                <DeleteAisleModal
                    setNotification={setNotification}
                    open={deleteModalOpen}
                    aisle={aisleForDelete}
                    setDeleteModalOpen={setDeleteModalOpen}
                    getItemsByParentID={getItemsByParentID}
                />
            )}
            {categories.map((item) => (
                <TaxonomyItem
                    type={type}
                    key={item.id}
                    item={item}
                    selectedItemID={selectedItem.id}
                    setSelectedItem={setSelectedItem}
                    level={level}
                    setNotification={setNotification}
                    editTaxonomy={editTaxonomy}
                    setDeleteModalOpen={setDeleteModalOpen}
                    setAisleForDelete={setAisleForDelete}
                />
            ))}
            {creatingCategory && (
                <CreateTaxonomy
                    type={type}
                    level={level}
                    createTaxonomy={createTaxonomy}
                    setNotification={setNotification}
                    cancel={() => {
                        setCreatingCategory(false);
                    }}
                />
            )}
            {!selectedItem.id && !creatingCategory && (
                <Button
                    id="NewLevel"
                    onClick={(e) => {
                        e.stopPropagation();
                        setCreatingCategory(true);
                    }}
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: '10px', marginLeft: '40px' }}
                >
                    New Level {level}
                </Button>
            )}
        </Grid>
    );
};

const Taxonomy = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    const [notification, setNotification] = useState({ text: null, level: null });

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ItemMasterTaxonomy_Tab" label="Item Master Taxonomy" value="0" />
                <Tab id="Aisles_Tab" label="Aisles" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <TaxonomyLevel setNotification={setNotification} type="TAXONOMY" />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <TaxonomyLevel setNotification={setNotification} type="AISLE" />
            </TabPanel>
        </Paper>
    );
};

export { Taxonomy };
