import { captureException, captureMessage, init as initSentry, setUser, withScope } from '@sentry/react';

// List of errors to ignore
const ignoreErrors = [];

/**
 * Initializes Sentry with dynamic environment setting
 * @param {string} environment -The environment of the application
 * @param {string} release - The version of the application
 *
 *
 */
function initialize(environment, release) {
    initSentry({
        dsn: 'https://6e093c88a0684cf70d39fbceae3338fb@o4506614985261056.ingest.us.sentry.io/4507669913141248',
        tracesSampleRate: 0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
        environment,
        release,
        ignoreErrors,
        integrations: [],
    });
}

/**
 * Logs an error to Sentry
 * @param {Error} error - The error object to log
 * @param {Object} [context=null] - Additional context information for the error
 */
function logError(error, context = null) {
    withScope((scope) => {
        if (context) {
            Object.keys(context).forEach((key) => {
                scope.setExtra(key, context[key]);
            });
        }
        captureException(error);
    });
}

/**
 * Logs a message to Sentry
 * Additional data can be included using the extra property.
 * @param {String} message - The message to log
 * @param {Object} context Additional context information for the message
 * @param {string} context.level The level of the message, "fatal", "error", "warning", "log", "info", and "debug"
 * @param {Object} context.extra Additional data to include in the message
 * @example logMessage('This is a message', { level: 'warning', extra: { foo: 'bar' } })
 */
function logMessage(message, context = { level: 'info' }) {
    captureMessage(message, context);
}

/**
 * Sets the user ID for Sentry
 * @param {string} id - The ID of the user
 * @param {string} email - The email of the user
 */
function setSentryUser(id, email) {
    setUser({ id, email });
}

/**
 * Clears the user information in Sentry
 */
function clearSentryUser() {
    setUser(null);
}

export { initialize, logError, logMessage, setSentryUser, clearSentryUser, ignoreErrors };
