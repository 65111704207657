import { mmFetchWithResponse } from './mmFetchWithResponse';
import { mmFetch } from './mmFetch';

let logisticsApi = {
    confirmFBMZipUpload: async (key) => {
        let url = 'api/logistics/v1/fbm/zipMap/confirm';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    getEasypostCarrierAccounts: async () => {
        let url = 'api/logistics/v1/easypostAccounts';
        return mmFetchWithResponse(url);
    },
    getLineHauls: async (status) => {
        let url = 'api/logistics/v1/lineHauls?status=' + status;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getLineHaulShifts: async (week, year) => {
        let url = `api/logistics/v1/lineHauls/shifts`;
        if (week && year) {
            url += `?week=${week}&year=${year}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getRecentLineHaulShippingDays: async (facilityID, excludeCarrierShip = false) => {
        let url = `api/logistics/v1/lineHauls/getRecentShippingDays/${facilityID}?excludeCarrierShip=${excludeCarrierShip}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getZipMapResults: async () => {
        let url = 'api/logistics/v1/zipMapUploads';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getSubsOnInvalidLineHauls: async () => {
        let url = `api/logistics/v1/zipMapReassign/invalidLineHauls`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getZipMapReassignmentResults: async () => {
        let url = 'api/logistics/v1/zipMapReassign/results';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    reassignSubsOnInvalidLineHauls: async (day) => {
        let url = `api/logistics/v1/zipMapReassign?day=${day}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    resetLineHaulRouting: async (lineHaulID) => {
        let url = `api/logistics/v1/lineHauls/${lineHaulID}/resetRouting`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    resyncLineHaul: async (lineHaulID) => {
        let url = `api/logistics/v1/lineHauls/${lineHaulID}/resync`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    updateEasypostAccount: async (id, item) => {
        let url = `api/logistics/v1/easypostAccounts/${id}`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
    },
    updateCrossDock: async (id, item) => {
        let url = `api/logistics/v1/dbiCrossDocks/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updateLineHaul: async (id, item) => {
        let url = `api/logistics/v1/lineHauls/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    reassignPurchasedShipments: async ({
        fromID,
        toID,
        quantity,
        restrictZips,
        includeMystery,
        includeCustom,
        restrictZipsFile,
        readOnly,
    }) => {
        let formData = new FormData();

        formData.append('fromID', fromID);
        formData.append('toID', toID);
        formData.append('quantity', quantity);
        formData.append('restrictZips', restrictZips);
        formData.append('includeMystery', includeMystery);
        formData.append('includeCustom', includeCustom);
        formData.append('readOnly', readOnly);
        if (restrictZips === true) {
            formData.append('restrictZipsFile', restrictZipsFile);
        }

        let url = 'api/logistics/v1/transfer';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    clearLineHaulShift: async (fromLineHaulID, sendParams) => {
        let url = `api/logistics/v1/lineHauls/${fromLineHaulID}/shifts/clear`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    shiftLineHaul: async (fromLineHaulID, sendParams) => {
        let url = `api/logistics/v1/lineHauls/${fromLineHaulID}/shift`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    submitTransitDayFile: async ({ transitDayChanges }) => {
        let formData = new FormData();
        formData.append('transitDayChanges', transitDayChanges);

        let url = 'api/logistics/v1/transitDays';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    submitZipFiles: async ({ backupFile, optimalFile, deliveryDay, notes, fbmPartnerName }) => {
        let formData = new FormData();
        formData.append('allZips', backupFile);
        formData.append('optimalZips', optimalFile);
        formData.append('day', deliveryDay);
        formData.append('notes', notes);
        formData.append('fbmPartnerName', fbmPartnerName);

        let url = 'api/logistics/v1/zipMap';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    syncEasypostAccounts: async () => {
        let url = `api/logistics/v1/easypostAccounts/sync`;
        return mmFetchWithResponse(url, { method: 'POST' });
    },
    createLineHaul: async (linehaul) => {
        let url = `api/logistics/v1/lineHauls`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(linehaul),
        });
        return response;
    },
    getValidDaysForUpload: async () => {
        let url = `api/logistics/v1/zipMapUploads/days`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getReassignmentFile: async (id, type) => {
        let url = `api/logistics/v1/zipMapReassign/${id}/csv?type=${type}`;
        let filename = `zip_map_reassign_${id}_${type}.csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[getTruckTag] error', e.toString());
        }
    },
    getZipMapFile: async (uploadID, fileType, uploadDate, deliveryDay) => {
        let url = `api/logistics/v1/zipMapUploads/${uploadID}/file?type=${fileType}`;
        let filename = `zip_map_upload_${uploadID}-${deliveryDay}-${uploadDate}-${fileType}.csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[getTruckTag] error', e.toString());
        }
    },
    getLineHaulCarriers: async () => {
        let url = 'api/logistics/v1/carriers';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDBICrossDocks: async () => {
        let url = 'api/logistics/v1/dbiCrossDocks';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getInboundCrossDocks: async () => {
        let url = 'api/logistics/v1/inboundCrossDocks';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateInboundCrossDocks: async (id, item) => {
        let url = `api/logistics/v1/inboundCrossDocks/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    createInboundCrossDocks: async (crossDock) => {
        let url = `api/logistics/v1/inboundCrossDocks`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(crossDock),
        });
        return response;
    },
    getCurrentShippingRates: async () => {
        let url = 'api/logistics/v1/rates';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    uploadFBMZipMap: async (data) => {
        let formData = new FormData();
        formData.append('itemFile', data.file);

        let url = 'api/logistics/v1/fbm/zipMap/upload';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadShippingRates: async (data) => {
        let formData = new FormData();
        formData.append('shippingRateFile', data.file);

        let url = 'api/logistics/v1/rates/upload';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    confirmShippingRates: async (key) => {
        let url = 'api/logistics/v1/rates/confirm';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    getCartExperiences: async () => {
        let url = 'api/logistics/v1/cartExperiences';
        let response = await mmFetchWithResponse(url);
        return response;
    },
    uploadCartExperience: async (data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));

        const url = 'api/logistics/v1/cartExperience';
        return await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
    },
};

export { logisticsApi };
