import React, { useState, useEffect, useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import { DataTable, Notifications, FormComponent, FacilityPicker } from '../../../../shared';
import { fbmApi as api } from '../../../../api/fbm';
import { UserContext } from '../../../../contexts/UserContext';

let LittleSpoonSubList = () => {
    const [rows, setRows] = useState([]);
    const [week, setWeek] = useState(-1);
    const [year, setYear] = useState(-1);
    const [subOptions, setSubOptions] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [facilityID, setFacilityID] = useState(null);
    const [extSkuOut, setExtSkuOut] = useState(null);
    const [extSkuIn, setExtSkuIn] = useState(null);
    let { user, fbmAccount } = useContext(UserContext);

    useEffect(() => {
        if (!user.fbm) {
            setFacilityID(user.facilityID);
        }
    }, [user]);

    let weekYearformFields = [
        {
            name: 'week',
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { required: true, type: 'number', max: '53' },
        },
        {
            name: 'year',
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { required: true, type: 'number', maxLength: '4' },
        },
    ];

    let addSubFormFields = [
        {
            name: 'extSkuOut',
            inputElement: 'autocomplete',
            label: 'Sku out of stock',
            gridValue: 5,
            inputProps: {
                required: true,
                opts: extSkuIn !== null ? subOptions.filter((item) => item.externalSku !== extSkuIn.value) : subOptions,
                onChange: (e, val) => setExtSkuOut(val),
            },
        },
        {
            name: 'extSkuIn',
            inputElement: 'autocomplete',
            label: 'Sku to sub in',
            gridValue: 5,
            inputProps: {
                required: true,
                opts:
                    extSkuOut !== null ? subOptions.filter((item) => item.externalSku !== extSkuOut.value) : subOptions,
                onChange: (e, val) => setExtSkuIn(val),
            },
        },
    ];

    // Load rows
    let getRows = async (week, year) => {
        let response = await api.getSubList(week, year, facilityID);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setWeek(response.data.data.week);
        setYear(response.data.data.year);
        setRows(response.data.data.items);
    };

    // Load items
    let getItems = async () => {
        let response = await api.getItems('littlespoon');
        if (response.status === false) {
            setNotification({ text: 'No items found ' + response.msg, level: 'error' });
            setSubOptions([]);
            return;
        }

        let subAsOpts = response.data.rows.map((row) => {
            row.text = `SKU: ${row.externalSku} ${row.name}`;
            row.value = row.externalSku;
            return row;
        });

        setSubOptions(subAsOpts);
    };

    // Add a sub
    let addItemPressed = async (formData) => {
        let sendParams = {};
        sendParams.week = week;
        sendParams.year = year;
        sendParams.extSkuOut = formData.extSkuOut;
        sendParams.extSkuIn = formData.extSkuIn;
        sendParams.facilityID = facilityID;

        let response = await api.createSub(sendParams);
        if (response.status === false) {
            setNotification({ text: 'Could not execute sub ' + response.msg, level: 'error' });
            return;
        }

        let alertText = 'Sucess';
        if (response.data.shipmentsToSub > 0) {
            alertText =
                'Successfully added substitution. Currently executing the substitution on orders placed with the out of stock item. You will be emailed when this is complete.';
        }

        setNotification({ text: alertText, level: 'success' });
        getRows(week, year);
    };

    // Remove an item
    let removeItemPressed = async (row) => {
        console.log(row);
        let response = await api.removeSub(row.original.id);
        if (response.status === false) {
            setNotification({ text: 'Could not remove sub ' + response.msg, level: 'error' });
            return;
        }

        setNotification({ text: 'Success', level: 'success' });
        getRows(week, year);
    };

    useEffect(() => {
        if (!!facilityID) {
            getRows(-1, -1);
        }
    }, [facilityID]);

    useEffect(() => {
        getItems();
    }, []);

    let cols = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'extOutSku', Header: '(Out) External SKU' },
        { accessor: 'extOutName', Header: '(Out) Item Name' },
        { accessor: 'extOutLSSku', Header: '(Out) LS SKU' },
        { accessor: 'extInSku', Header: '(Sub) External SKU' },
        { accessor: 'extInName', Header: '(Sub) Item Name' },
        { accessor: 'extInLSSku', Header: '(Sub) LS SKU' },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            <h2>
                Setup {fbmAccount.companyName} Substitutions - Week {week}, {year}
            </h2>
            <Grid container>
                <Grid item md={9}>
                    <FormComponent
                        formFields={weekYearformFields}
                        button={{ gridValue: 2, text: 'Change Week/Year' }}
                        onSubmit={async (formData) => {
                            await getRows(formData.week, formData.year);
                        }}
                    />
                </Grid>
                <Grid item md={3}>
                    {user.fbm && (
                        <FacilityPicker
                            defaultValue={fbmAccount.facilityIDs[0]}
                            whitelistedFacilities={fbmAccount.facilityIDs}
                            activeOnly={true}
                            onChange={(facilityID, text, facility) => {
                                setFacilityID(facilityID);
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <br />
            <br />
            <FormComponent
                formFields={addSubFormFields}
                button={{ gridValue: 2, text: 'Add Sub' }}
                onSubmit={async (formData) => {
                    await addItemPressed(formData);
                }}
            />
            <br />
            <div>
                <DataTable columns={cols} data={rows} />
            </div>
        </div>
    );
};

export { LittleSpoonSubList };
