import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let packagingApi = {
    addItemToPurchaseOrder: async (poID, items) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder/${poID}/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(items),
        });
        return response;
    },
    cancelPurchaseOrder: async (id) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder/` + id + `/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    createAutoAssignPackaging: async (item) => {
        let url = `api/warehouse/v1/packaging/auto/config`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createPackaging: async (sendParams) => {
        let url = `api/warehouse/v1/packaging`;
        try {
            let response = await mmFetchWithResponse(url, {
                method: 'POST',
                body: JSON.stringify(sendParams),
            });
            return response;
        } catch (e) {
            return false;
        }
    },
    createPurchaseOrder: async (item) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    getAutoAssignPackaging: async (facilityID) => {
        let url = `api/warehouse/v1/packaging/auto/config?facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getAssignments: async () => {
        let url = `api/warehouse/v1/packaging/assignments`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getBoxes: async (companySource) => {
        let url = `api/warehouse/v1/packaging/boxes?companySource=${companySource}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getInsulation: async (cubeLevelID = 0) => {
        let url = `api/warehouse/v1/packaging/insulation`;
        if (cubeLevelID > 0) {
            url += '?cubeLevelID=' + cubeLevelID;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPackagingTypes: async () => {
        return await mmFetchWithResponse(`api/warehouse/v1/packaging/types`);
    },
    getPackaging: async () => {
        let url = `api/warehouse/v1/packaging`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrderDetails: async (poID) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder/` + poID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPurchaseOrders: async (status, facilityID) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder?facilityID=${facilityID}`;
        if (status != '') {
            url += `&status=${status}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getShippingMethods: async () => {
        let url = `api/warehouse/v1/packaging/shippingMethods`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    removeItemFromPO: async (poID, poMapID) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder/${poID}/item/${poMapID}/remove`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    sendPurchaseOrderToVendor: async (poID) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder/${poID}/send`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    updateAutoAssignConfig: async (id, item) => {
        let url = `api/warehouse/v1/packaging/auto/config/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updatePackaging: async (id, item) => {
        let url = `api/warehouse/v1/packaging/assign/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updatePurchaseOrder: async (id, item) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    updatePurchaseOrderMap: async (poID, poMapID, updates) => {
        let url = `api/warehouse/v1/packaging/purchaseOrder/${poID}/item/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(updates),
        });
        return response;
    },
    editPackaging: async (id, item) => {
        let url = `api/warehouse/v1/packaging/edit/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    downloadPackagingPOPDF: async (poID, vendorName) => {
        let filename = 'PO#' + poID + '-' + vendorName + '.pdf';
        let url = `api/warehouse/v1/packaging/purchaseOrder/` + poID + `/pdf`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadPOPDF] error', e.toString());
        }
    },
    downloadPackagingPOShipList: async (poID) => {
        let filename = 'packaging_ship_list_' + poID + '.pdf';
        let url = `api/warehouse/v1/packaging/purchaseOrder/` + poID + `/shipList/pdf`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadPackagingPOCSV] error', e.toString());
        }
    },
};

export { packagingApi };
