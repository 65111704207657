import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Notifications, DataTable, FormComponent, TabPanel } from '../../../shared';
import { growthApi as api } from '../../../api/growth';
import {
    Modal,
    Button,
    Select,
    NativeSelect,
    Dialog,
    DialogContent,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid,
    InputLabel,
    Tab,
    Tabs,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => {
    return {
        panelContainer: {
            borderColor: theme.palette.ivy.main,
            borderWidth: '3px',
            borderStyle: 'solid',
            borderRadius: '5px',
            margin: '10px 10px',
            padding: '0px',
        },
        panelSummary: {
            backgroundColor: theme.palette.aloe.main,
            height: '70px',
            fontSize: '16px',
            padding: '25px',
        },
    };
});

let SendTestEmailModal = ({ closeModal, campaignID, open, sendTestEmail, segments }) => {
    let formFields = [
        { name: 'email', inputElement: 'textField', label: 'Email', inputProps: { required: true }, gridValue: 9 },
        {
            name: 'segment',
            inputElement: 'select',
            label: 'Segment',
            inputProps: { required: true, opts: Object.keys(segments) },
            gridValue: 3,
        },
    ];
    return (
        <Dialog open={open} onClose={closeModal}>
            <DialogTitle>Send Test Email</DialogTitle>
            <DialogContent>
                <FormComponent
                    formFields={formFields}
                    button={{ text: 'Send Test Email' }}
                    onSubmit={(formData) => {
                        sendTestEmail(formData.email, campaignID, formData.segment);
                        closeModal();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let CloneCampaignModal = ({ closeModal, campaign, open, setNotification, getRows }) => {
    return (
        <Dialog open={open} onClose={closeModal} fullWidth={true} maxWidth="lg">
            <DialogTitle>Clone Campaign</DialogTitle>
            <DialogContent>
                <CreateCampaign
                    initialValues={campaign}
                    getRows={getRows}
                    setNotification={setNotification}
                    onSuccess={() => {
                        getRows();
                        closeModal();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let getTemplateSpecificFormFields = (templateID, initialValues = {}) => {
    let formFields = [];

    if (templateID === 'Single Column Template') {
        formFields = [
            { label: 'Header Image 1', name: 'headerImage1', inputElement: 'file', accept: ['.png', '.jpg', '.gif'] },
            {
                label: 'Header Image Alt Text 1',
                initialValue: initialValues.headerImage1AltText || undefined,
                name: 'headerImage1AltText',
                inputElement: 'textField',
            },
            {
                label: 'Header Image 1 Url',
                initialValue: initialValues.headerImage1Url || undefined,
                name: 'headerImage1Url',
                inputElement: 'textField',
            },
            { label: 'Headline 1', name: 'headline1', inputElement: 'textField' },
            { label: 'Body Text 1', name: 'bodyText1', inputElement: 'textEditor' },
            { label: 'Button Text 1', name: 'button1Text', inputElement: 'textField' },
            { label: 'Button Url 1', name: 'button1Url', inputElement: 'textField' },
            { label: 'Body Text 2', name: 'bodyText2', inputElement: 'textEditor' },
            { label: 'Header Image 2', name: 'headerImage2', inputElement: 'file', accept: ['.png', '.jpg', '.gif'] },
            { label: 'Header Image Alt Text 2', name: 'headerImage2AltText', inputElement: 'textField' },
            {
                label: 'Header Image 2 Url',
                initialValue: initialValues.headerImage2Url || undefined,
                name: 'headerImage2Url',
                inputElement: 'textField',
            },
        ];
    } else if (templateID === 'Single Column Template Merchandised') {
        formFields = [
            { label: 'Header Text', name: 'headerText', inputElement: 'textField', inputProps: { required: true } },
            { label: 'Body Text 1', name: 'bodyText1', inputElement: 'textEditor', inputProps: { required: true } },
            { label: 'Button Text 1', name: 'button1Text', inputElement: 'textField' },
            { label: 'Button Url 1', name: 'button1Url', inputElement: 'textField' },
            {
                label: 'Image 1',
                name: 'image1',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 1 Header', name: 'image1Header', inputElement: 'textField' },
            { label: 'Image 1 Link', name: 'image1Url', inputElement: 'textField' },
            { label: 'Image1 Alt Text', name: 'image1AltText', inputElement: 'textField' },
            {
                label: 'Image 2',
                name: 'image2',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 2 Header', name: 'image2Header', inputElement: 'textField' },
            { label: 'Image 2 Link', name: 'image2Url', inputElement: 'textField' },
            { label: 'Image2 Alt Text', name: 'image2AltText', inputElement: 'textField' },
            {
                label: 'Image 3',
                name: 'image3',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 3 Header', name: 'image3Header', inputElement: 'textField' },
            { label: 'Image 3 Link', name: 'image3Url', inputElement: 'textField' },
            { label: 'Image3 Alt Text', name: 'image3AltText', inputElement: 'textField' },
            {
                label: 'Image 4',
                name: 'image4',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 4 Header', name: 'image4Header', inputElement: 'textField' },
            { label: 'Image 4 Link', name: 'image4Url', inputElement: 'textField' },
            { label: 'Image4 Alt Text', name: 'image4AltText', inputElement: 'textField' },
            { label: 'Body Text 2', name: 'bodyText2', inputElement: 'textEditor' },
            { label: 'Button Text 2', name: 'button2Text', inputElement: 'textField' },
            { label: 'Button Url 2', name: 'button2Url', inputElement: 'textField' },
        ];
    } else if (templateID === 'Double Column Template') {
        formFields = [
            { label: 'Header Image 1', name: 'headerImage1', inputElement: 'file', accept: ['.png', '.jpg', '.gif'] },
            { label: 'Header Image Alt Text 1', name: 'headerImage1AltText', inputElement: 'textField' },
            {
                label: 'Header Image 1 Url',
                initialValue: initialValues.headerImage1Url || undefined,
                name: 'headerImage1Url',
                inputElement: 'textField',
            },
            { label: 'Headline 1', name: 'headline1', inputElement: 'textField' },
            { label: 'Body Text 1', name: 'bodyText1', inputElement: 'textEditor' },
            { label: 'Button Text 1', name: 'button1Text', inputElement: 'textField' },
            { label: 'Button Url 1', name: 'button1Url', inputElement: 'textField' },
            {
                label: 'Image 1',
                name: 'image1',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 1 Header', name: 'image1Header', inputElement: 'textField' },
            { label: 'Image 1 Text', name: 'image1Text', inputElement: 'textField' },
            { label: 'Image 1 Button Text', name: 'image1ButtonText', inputElement: 'textField' },
            { label: 'Image 1 Button Url', name: 'image1ButtonUrl', inputElement: 'textField' },
            {
                label: 'Image 1 Url',
                initialValue: initialValues.image1Url || undefined,
                name: 'image1Url',
                inputElement: 'textField',
            },
            {
                label: 'Image 2',
                name: 'image2',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 2 Header', name: 'image2Header', inputElement: 'textField' },
            { label: 'Image 2 Text', name: 'image2Text', inputElement: 'textField' },
            { label: 'Image 2 Button Text', name: 'image2ButtonText', inputElement: 'textField' },
            { label: 'Image 2 Button Url', name: 'image2ButtonUrl', inputElement: 'textField' },
            {
                label: 'Image 2 Url',
                initialValue: initialValues.image2Url || undefined,
                name: 'image2Url',
                inputElement: 'textField',
            },
            { label: 'Button Text 2', name: 'button2Text', inputElement: 'textField' },
            { label: 'Button Url 2', name: 'button2Url', inputElement: 'textField' },
            { label: 'Body Text 2', name: 'bodyText2', inputElement: 'textEditor' },
            { label: 'Header Image 2', name: 'headerImage2', inputElement: 'file', accept: ['.png', '.jpg', '.gif'] },
            { label: 'Header Image Alt Text 2', name: 'headerImage2AltText', inputElement: 'textField' },
            {
                label: 'Header Image 2 Url',
                initialValue: initialValues.headerImage2Url || undefined,
                name: 'headerImage2Url',
                inputElement: 'textField',
            },
        ];
    } else if (templateID === 'Triple Column Template' || !templateID) {
        formFields = [
            { label: 'Header Image 1', name: 'headerImage1', inputElement: 'file', accept: ['.png', '.jpg', '.gif'] },
            { label: 'Header Image Alt Text 1', name: 'headerImage1AltText', inputElement: 'textField' },
            {
                label: 'Header Image 1 Url',
                initialValue: initialValues.headerImage1Url || undefined,
                name: 'headerImage1Url',
                inputElement: 'textField',
            },
            { label: 'Headline 1', name: 'headline1', inputElement: 'textField' },
            { label: 'Body Text 1', name: 'bodyText1', inputElement: 'textEditor' },
            { label: 'Button Text 1', name: 'button1Text', inputElement: 'textField' },
            { label: 'Button Url 1', name: 'button1Url', inputElement: 'textField' },
            {
                label: 'Image 1',
                name: 'image1',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 1 Header', name: 'image1Header', inputElement: 'textField' },
            { label: 'Image 1 Text', name: 'image1Text', inputElement: 'textField' },
            { label: 'Image 1 Button Text', name: 'image1ButtonText', inputElement: 'textField' },
            { label: 'Image 1 Button Url', name: 'image1ButtonUrl', inputElement: 'textField' },
            {
                label: 'Image 1 Url',
                initialValue: initialValues.image1Url || undefined,
                name: 'image1Url',
                inputElement: 'textField',
            },
            {
                label: 'Image 2',
                name: 'image2',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 2 Header', name: 'image2Header', inputElement: 'textField' },
            { label: 'Image 2 Text', name: 'image2Text', inputElement: 'textField' },
            { label: 'Image 2 Button Text', name: 'image2ButtonText', inputElement: 'textField' },
            { label: 'Image 2 Button Url', name: 'image2ButtonUrl', inputElement: 'textField' },
            {
                label: 'Image 2 Url',
                initialValue: initialValues.image2Url || undefined,
                name: 'image2Url',
                inputElement: 'textField',
            },
            {
                label: 'Image 3',
                name: 'image3',
                inputElement: 'file',
                accept: ['.png', '.jpg', '.gif'],
                inputProps: { required: true },
            },
            { label: 'Image 3 Header', name: 'image3Header', inputElement: 'textField' },
            { label: 'Image 3 Text', name: 'image3Text', inputElement: 'textField' },
            { label: 'Image 3 Button Text', name: 'image3ButtonText', inputElement: 'textField' },
            { label: 'Image 3 Button Url', name: 'image3ButtonUrl', inputElement: 'textField' },
            {
                label: 'Image 3 Url',
                initialValue: initialValues.image3Url || undefined,
                name: 'image3Url',
                inputElement: 'textField',
            },
            { label: 'Button Text 2', name: 'button2Text', inputElement: 'textField' },
            { label: 'Button Url 2', name: 'button2Url', inputElement: 'textField' },
            { label: 'Body Text 2', name: 'bodyText2', inputElement: 'textEditor' },
            { label: 'Header Image 2', name: 'headerImage2', inputElement: 'file', accept: ['.png', '.jpg', '.gif'] },
            { label: 'Header Image Alt Text 2', name: 'headerImage2AltText', inputElement: 'textField' },
            {
                label: 'Header Image 2 Url',
                initialValue: initialValues.headerImage1Url || undefined,
                name: 'headerImage2Url',
                inputElement: 'textField',
            },
        ];
    } else if (templateID === 'Order Scheduled') {
        return getAutoPopulateTemplateData(templateID);
    }

    formFields = formFields.map((item) => {
        if (initialValues && initialValues[item.name]) {
            item.initialValue = initialValues[item.name];
        }
        item.dependencies = ['templateID'];
        return item;
    });

    return formFields;
};

let getAutoPopulateTemplateData = (templateID, autoPopulateAPIData = {}) => {
    let formFields = [];

    if (templateID === 'Order Scheduled') {
        formFields = [
            {
                label: 'Image 1',
                name: 'image1',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image1,
                value: autoPopulateAPIData.image1,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 1 Header',
                name: 'image1Header',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image1Header,
                value: autoPopulateAPIData.image1Header,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 1 Price',
                name: 'image1price',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image1price,
                value: autoPopulateAPIData.image1price,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 1 MSRP',
                name: 'image1msrp',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image1msrp,
                value: autoPopulateAPIData.image1msrp,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 1 Savings',
                name: 'image1savings',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image1savings,
                value: autoPopulateAPIData.image1savings,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 2',
                name: 'image2',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image2,
                value: autoPopulateAPIData.image2,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 2 Header',
                name: 'image2Header',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image2Header,
                value: autoPopulateAPIData.image2Header,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 2 Price',
                name: 'image2price',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image2price,
                value: autoPopulateAPIData.image2price,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 2 MSRP',
                name: 'image2msrp',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image2msrp,
                value: autoPopulateAPIData.image2msrp,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 2 Savings',
                name: 'image2savings',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image2savings,
                value: autoPopulateAPIData.image2savings,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 3',
                name: 'image3',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image3,
                value: autoPopulateAPIData.image3,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 3 Header',
                name: 'image3Header',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image3Header,
                value: autoPopulateAPIData.image3Header,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 3 Price',
                name: 'image3price',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image3price,
                value: autoPopulateAPIData.image3price,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 3 MSRP',
                name: 'image3msrp',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image3msrp,
                value: autoPopulateAPIData.image3msrp,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 3 Savings',
                name: 'image3savings',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image3savings,
                value: autoPopulateAPIData.image3savings,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 4',
                name: 'image4',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image4,
                value: autoPopulateAPIData.image4,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 4 Header',
                name: 'image4Header',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image4Header,
                value: autoPopulateAPIData.image4Header,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 4 Price',
                name: 'image4price',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image4price,
                value: autoPopulateAPIData.image4price,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 4 MSRP',
                name: 'image4msrp',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image4msrp,
                value: autoPopulateAPIData.image4msrp,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
            {
                label: 'Image 4 Savings',
                name: 'image4savings',
                inputElement: 'textField',
                initialValue: autoPopulateAPIData.image4savings,
                value: autoPopulateAPIData.image4savings,
                inputProps: { readOnly: true },
                InputLabelProps: { shrink: true },
            },
        ];
    }

    formFields = formFields.map((item) => {
        item.dependencies = ['templateID'];

        return item;
    });

    return formFields;
};

let SegmentForm = ({
    segmentNumber,
    setSegmentFormData,
    expandedSegmentForm,
    handleExpandedSegmentFormChange,
    formData,
    initialValues = {},
    segmentCounts,
    setNotification,
    notifyServiceID,
}) => {
    const classes = useStyles();
    const theme = useTheme();

    let [templateID, setTemplateID] = useState('');

    let initialTemplateSpecificFormFields = [];

    if (initialValues && initialValues.template) {
        initialTemplateSpecificFormFields = getTemplateSpecificFormFields(initialValues.template, initialValues);
    }
    let [templateSpecificFormFields, setTemplateSpecificFormFields] = useState(initialTemplateSpecificFormFields);

    let initialAutoPopulateTemplateSpecificFormFields = [];
    let [autoPopulateAPIData, setAutoPopulateAPIData] = useState(null);
    let [autoPopulateTemplateData, setAutoPopulateTemplateData] = useState(
        initialAutoPopulateTemplateSpecificFormFields
    );

    let initialSegmentNameVal = '';
    if (segmentNumber == '1') {
        initialSegmentNameVal = 'A';
    } else if (segmentNumber == '2') {
        initialSegmentNameVal = 'B';
    } else if (segmentNumber == '3') {
        initialSegmentNameVal = 'C';
    }

    let getOrderScheduledMerchandisedAPIData = async (notifyServiceID) => {
        let res = await api.getOrderScheduledMerchandisedTemplateData(notifyServiceID);
        if (!res.status) {
            setNotification({ text: 'Failed to get template data. ' + res.msg, level: 'error' });
            return;
        }

        setAutoPopulateAPIData(res.data.rows);

        if (res.data.rows.length <= 0) {
            setAutoPopulateAPIData(null);
        }
    };

    let segmentFormFields = [
        {
            name: 'name',
            initialValue: initialValues.name || initialSegmentNameVal,
            inputElement: 'textField',
            label: 'Segment Name',
            gridValue: 12,
            inputProps: { readOnly: true },
        },
        {
            name: 'templateID',
            initialValue: initialValues.template || undefined,
            inputElement: 'select',
            label: 'Grapefruit Template',
            gridValue: 12,
            inputProps: {
                required: true,
                opts:
                    notifyServiceID === 27 || notifyServiceID === 28 || notifyServiceID === 29
                        ? ['Order Scheduled']
                        : [
                              'Single Column Template',
                              'Single Column Template Merchandised',
                              'Double Column Template',
                              'Triple Column Template',
                          ],
                onChange: async (event, value) => {
                    setTemplateID(value);

                    if (value === 'Order Scheduled') {
                        getOrderScheduledMerchandisedAPIData(notifyServiceID);
                    } else {
                        let formFields = getTemplateSpecificFormFields(value, initialValues);
                        setTemplateSpecificFormFields(formFields);
                        setAutoPopulateTemplateData([]);
                        setAutoPopulateAPIData(null);
                    }
                },
            },
        },
        {
            name: 'preview',
            value: null,
            inputElement: ({ formData }) => {
                if (!formData.templateID) {
                    return null;
                }
                if (formData.templateID === 'Single Column Template') {
                    return (
                        <a target="_blank" href={require('../../../shared/single-column.png')}>
                            Preview Template
                        </a>
                    );
                } else if (formData.templateID === 'Single Column Template Merchandised') {
                    return (
                        <a target="_blank" href={require('../../../shared/single-column-merchandised.png')}>
                            Preview Template
                        </a>
                    );
                } else if (formData.templateID === 'Double Column Template') {
                    return (
                        <a target="_blank" href={require('../../../shared/double-column.png')}>
                            Preview Template
                        </a>
                    );
                } else if (formData.templateID === 'Triple Column Template') {
                    return (
                        <a target="_blank" href={require('../../../shared/triple-column.png')}>
                            Preview Template
                        </a>
                    );
                }
                return null;
            },
        },
        {
            name: 'segmentWeight',
            initialValue: initialValues.segmentWeight || undefined,
            inputElement: 'textField',
            label: 'Segment %',
            gridValue: 12,
            inputProps: { type: 'number', integer: true },
        },
        {
            name: 'subject',
            initialValue: initialValues.subject || undefined,
            inputElement: 'textField',
            label: 'Subject',
            gridValue: 12,
            inputProps: { required: true },
        },
        {
            name: 'preheader',
            initialValue: initialValues.preheader || undefined,
            inputElement: 'textField',
            label: 'Preheader',
            gridValue: 12,
            inputProps: { required: true },
        },
        {
            name: 'sender',
            inputElement: 'select',
            label: 'Sender',
            gridValue: 12,
            initialValue: 'donotreply@misfitsmarket.com',
            inputProps: {
                required: true,
                opts: ['donotreply@misfitsmarket.com', 'contact@misfitsmarket.com', 'marley@misfitsmarket.com'],
            },
        },
        {
            name: 'replyTo',
            inputElement: 'select',
            label: 'Reply To',
            gridValue: 12,
            initialValue: 'donotreply@misfitsmarket.com',
            inputProps: {
                required: true,
                opts: ['donotreply@misfitsmarket.com', 'contact@misfitsmarket.com', 'marley@misfitsmarket.com'],
            },
        },
        ...(autoPopulateAPIData !== null && autoPopulateTemplateData.length > 0
            ? autoPopulateTemplateData
            : templateSpecificFormFields),
    ];

    useEffect(() => {
        if (autoPopulateAPIData !== null) {
            let formFields = getAutoPopulateTemplateData(templateID, autoPopulateAPIData);
            setAutoPopulateTemplateData(formFields);
        } else {
            setAutoPopulateAPIData(null);
            setAutoPopulateTemplateData([]);
        }
    }, [autoPopulateAPIData]);

    useEffect(() => {
        if (notifyServiceID === 27 || notifyServiceID === 28 || notifyServiceID === 29) {
            getOrderScheduledMerchandisedAPIData(notifyServiceID);
        } else {
            setAutoPopulateAPIData(null);
            setAutoPopulateTemplateData([]);
        }
    }, [notifyServiceID]);

    return (
        <ExpansionPanel
            className={classes.panelContainer}
            key={segmentNumber}
            expanded={expandedSegmentForm === segmentNumber}
            onChange={() => handleExpandedSegmentFormChange(segmentNumber)}
        >
            <ExpansionPanelSummary className={classes.panelSummary}>
                <Grid container>
                    <Grid item xs={6}>
                        <h3>Segment {segmentNumber}</h3>
                    </Grid>
                    <Grid item xs={6}>
                        {formData[`segment${segmentNumber}`].templateID && (
                            <h3>
                                <div>Template: {formData[`segment${segmentNumber}`].templateID}</div>
                                <div>Weight {formData[`segment${segmentNumber}`].segmentWeight}</div>
                            </h3>
                        )}
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    {formData[`segment${segmentNumber}`].templateID ? (
                        <>
                            {segmentCounts && segmentCounts[initialSegmentNameVal] && (
                                <div>
                                    <strong>List Size: </strong> {segmentCounts[initialSegmentNameVal]}
                                </div>
                            )}
                            <FormWithDisplay
                                formFields={segmentFormFields.map((item) => {
                                    if (autoPopulateAPIData !== null && autoPopulateTemplateData.length > 0) {
                                        if (item.value) {
                                            formData[`segment${segmentNumber}`][item.name] = item.value;
                                            item.initialValue = formData[`segment${segmentNumber}`][item.name];
                                        } else {
                                            item.initialValue = formData[`segment${segmentNumber}`][item.name];
                                        }
                                    } else {
                                        item.initialValue = formData[`segment${segmentNumber}`][item.name];
                                    }

                                    return item;
                                })}
                                editable={true}
                                onSubmit={async (formData) => {
                                    setSegmentFormData(formData);
                                    return true;
                                }}
                            />
                        </>
                    ) : (
                        <FormComponent
                            formFields={segmentFormFields}
                            button={{ text: 'Done' }}
                            onSubmit={setSegmentFormData}
                        />
                    )}
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

let CreateCampaign = ({ getRows, setNotification, initialValues = {}, onSuccess }) => {
    let [error, setError] = useState(null);
    let [notifyServiceID, setNotifyServiceID] = useState(5);

    let [segmentFormData, setSegmentFormData] = useState([]);
    let [expandedSegmentForm, setExpandedSegmentForm] = useState(null);
    let segmentArr = [];
    if (initialValues) {
        if (initialValues.parameters && initialValues.parameters.segments) {
            let keys = Object.keys(initialValues.parameters.segments);
            Object.keys(initialValues.parameters.segments).forEach((key) => {
                segmentArr.push(initialValues.parameters.segments[key]);
            });
        }
    }

    let formFields = [
        {
            name: 'name',
            initialValue: initialValues.name || undefined,
            inputElement: 'textField',
            label: 'Campaign Name',
            gridValue: 12,
        },
        {
            name: 'notifyServiceID',
            initialValue: initialValues.notifyServiceID || undefined,
            inputElement: 'select',
            label: 'Query',
            gridValue: 6,
            inputProps: {
                opts: [
                    { text: 'VIP Winback - Not Enough Value', value: 7 },
                    { text: 'VIP Winback - Poor CS', value: 6 },
                    { text: 'Pause Winback', value: 5 },
                    { text: 'LIP Nudge - Day 2', value: 19 },
                    { text: 'LIP Nudge - Day 3', value: 20 },
                    { text: 'Order Scheduled', value: 1 },
                    { text: 'Soft Cancels - 30 days', value: 23 },
                    { text: 'Protein Reminder - Email(3/3)', value: 25 },
                    { text: 'Marketplace Reminder Day 2', value: 26 },
                    { text: 'Order Scheduled - DEL', value: 27 },
                    { text: 'Order Scheduled - SLC', value: 28 },
                    { text: 'Order Scheduled - DAL', value: 29 },
                ],
                required: true,
                onChange: (event) => {
                    setNotifyServiceID(Number(event.target.value));
                    setSegmentFormData([]);
                },
            },
        },
        {
            name: 'maxRecipients',
            initialValue: initialValues?.parameters?.maxSend || undefined,
            inputElement: 'textField',
            label: 'Max Recipients',
            gridValue: 2,
            inputProps: { type: 'number', integer: true },
        },
        {
            name: 'holdoutPercent',
            initialValue: initialValues?.parameters?.holdoutPercent || undefined,
            inputElement: 'textField',
            label: 'Holdout %',
            gridValue: 2,
            inputProps: { type: 'number', integer: true },
        },
        {
            name: 'numSegments',
            initialValue: segmentArr.length || undefined,
            inputElement: 'select',
            label: 'Num Segments',
            gridValue: 2,
            inputProps: {
                opts: [1, 2, 3],
                required: true,
            },
        },
        {
            name: 'recurring',
            initialValue: 'no',
            inputElement: 'select',
            label: 'Recurring',
            inputProps: { opts: ['yes', 'no'], required: true },
            gridValue: 12,
        },

        // fields to display if recurring is true
        {
            name: 'scheduledTimeRecurring',
            inputElement: 'time',
            label: 'Send Time',
            gridValue: 2,
            inputProps: { required: true },
            display: (formData) => formData.recurring === 'yes',
            dependencies: ['recurring'],
        },
        {
            name: 'startDate',
            inputElement: 'date',
            label: 'Start Date',
            gridValue: 3,
            inputProps: { required: true },
            display: (formData) => formData.recurring === 'yes',
            dependencies: ['recurring'],
        },
        {
            name: 'endDate',
            inputElement: 'date',
            label: 'End Date',
            gridValue: 3,
            inputProps: { required: true },
            display: (formData) => formData.recurring === 'yes',
            dependencies: ['recurring'],
        },
        {
            name: 'frequency',
            inputElement: 'select',
            label: 'Frequency',
            gridValue: 2,
            inputProps: {
                opts: ['weekly', 'daily'],
                required: true,
            },
            display: (formData) => formData.recurring === 'yes',
            dependencies: ['recurring'],
        },
        {
            name: 'dayOfWeek',
            inputElement: 'select',
            label: 'Day of Week',
            gridValue: 2,
            inputProps: {
                opts: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                required: true,
            },
            display: (formData) => formData.recurring === 'yes' && formData.frequency === 'weekly',
            dependencies: ['recurring'],
        },

        // fields to display if recurring is false
        {
            name: 'scheduledTime',
            inputElement: 'time',
            label: 'Send Time',
            gridValue: 2,
            inputProps: { required: true },
            display: (formData) => formData.recurring === 'no',
            dependencies: ['recurring'],
        },
        {
            name: 'scheduledDate',
            inputElement: 'date',
            label: 'Send Date',
            gridValue: 4,
            inputProps: { required: true },
            display: (formData) => formData.recurring === 'no',
            dependencies: ['recurring'],
        },

        // segment forms
        {
            name: 'segment1',
            dependencies: ['numSegments'],
            initialValue: [],
            inputElement: ({ formData, setFormField }) => {
                if (formData.numSegments < 1) {
                    return null;
                }
                return (
                    <SegmentForm
                        expandedSegmentForm={expandedSegmentForm}
                        handleExpandedSegmentFormChange={() => handleExpandedSegmentFormChange('1')}
                        segmentNumber="1"
                        initialValues={segmentArr[0]}
                        setSegmentFormData={(segmentFormData) => {
                            setFormField(segmentFormData);
                            setExpandedSegmentForm(null);
                        }}
                        formData={formData}
                        setNotification={setNotification}
                        notifyServiceID={notifyServiceID}
                    />
                );
            },
        },
        {
            name: 'segment2',
            dependencies: ['numSegments'],
            initialValue: [],
            inputElement: ({ formData, setFormField }) => {
                if (formData.numSegments < 2) {
                    return null;
                }
                return (
                    <SegmentForm
                        expandedSegmentForm={expandedSegmentForm}
                        handleExpandedSegmentFormChange={() => handleExpandedSegmentFormChange('2')}
                        segmentNumber="2"
                        initialValues={segmentArr[1]}
                        setSegmentFormData={(segmentFormData) => {
                            setFormField(segmentFormData);
                            setExpandedSegmentForm(null);
                        }}
                        formData={formData}
                        setNotification={setNotification}
                        notifyServiceID={notifyServiceID}
                    />
                );
            },
        },
        {
            name: 'segment3',
            dependencies: ['numSegments'],
            initialValue: [],
            inputElement: ({ formData, setFormField }) => {
                if (formData.numSegments < 3) {
                    return null;
                }
                return (
                    <SegmentForm
                        expandedSegmentForm={expandedSegmentForm}
                        handleExpandedSegmentFormChange={() => handleExpandedSegmentFormChange('3')}
                        segmentNumber="3"
                        initialValues={segmentArr[2]}
                        setSegmentFormData={(segmentFormData) => {
                            setFormField(segmentFormData);
                            setExpandedSegmentForm(null);
                        }}
                        formData={formData}
                        setNotification={setNotification}
                        notifyServiceID={notifyServiceID}
                    />
                );
            },
        },
    ];

    let handleExpandedSegmentFormChange = (index) => {
        if (expandedSegmentForm === index) {
            setExpandedSegmentForm(null);
        } else {
            setExpandedSegmentForm(index);
        }
    };

    return (
        <div>
            <div>
                <div style={{ width: '90%', margin: '0 auto' }}>
                    <h2>Create Email Campaign</h2>
                    <FormComponent
                        formFields={formFields}
                        button={{
                            text: 'Create Campaign',
                            disabled: (formData) => {
                                if (!formData.numSegments) {
                                    return true;
                                }

                                if (formData.numSegments == 1 && !formData.segment1.name) {
                                    return true;
                                }

                                if (formData.numSegments == 2 && (!formData.segment1.name || !formData.segment2.name)) {
                                    return true;
                                }

                                if (
                                    formData.numSegments == 3 &&
                                    (!formData.segment1.name || !formData.segment2.name || !formData.segment3.name)
                                ) {
                                    return true;
                                }

                                return false;
                            },
                        }}
                        onSubmit={async (formData, resetForm) => {
                            if (formData.scheduledTimeRecurring) {
                                formData.scheduledTime = formData.scheduledTimeRecurring;
                            }

                            let res = await api.createEmailCampaign(formData);
                            if (!res.status) {
                                setNotification({ text: 'Failed to create campaign. ' + res.msg, level: 'error' });
                                return;
                            }

                            setNotification({ text: 'Campaign created', level: 'success' });
                            getRows();
                            resetForm();

                            if (onSuccess && onSuccess instanceof Function) {
                                onSuccess();
                            }
                        }}
                    />
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>
            </div>
        </div>
    );
};

let EmailCampaigns = () => {
    let [rows, setRows] = useState([]);
    let [recurringRows, setRecurringRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [createCampaignModal, setCreateCampaignModal] = useState(false);
    let [selectedCampaign, setSelectedCampaign] = useState(null);
    let [sendTestEmailModalOpen, setSendTestEmailModalOpen] = useState(false);
    let [cloneCampaignModalOpen, setCloneCampaignModalOpen] = useState(false);
    let [selectedTab, setSelectedTab] = useState('1');

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    let sendTestEmail = (email, campaignID, segment) => {
        api.sendTestEmail(email, campaignID, segment);
    };

    let getRecurringRows = async () => {
        let response = await api.getRecurringCampaigns();
        if (!response.status) {
            setNotification({ text: 'Failed to get recurring campaigns', level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.startDate = moment(row.startDate.date).format('MM/DD/YYYY');
                row.endDate = moment(row.endDate.date).format('MM/DD/YYYY');
                return row;
            });
        }
        setRecurringRows(response.data.rows);
    };

    let getNonRecurringCampaigns = async () => {
        let response = await api.getCampaigns();
        if (!response.status) {
            setNotification({ text: 'Failed to get campaigns', level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.scheduledAt = moment(row.scheduledAt.date + row.scheduledAt.timezone)
                    .local()
                    .format('MM/DD/YYYY h:mm a');

                if (row.segmentCounts) {
                    row.totalListSize = 0;
                    Object.keys(row.segmentCounts).forEach((key) => {
                        row.totalListSize += row.segmentCounts[key];
                    });
                }
                row.holdoutPercent = row.parameters.holdoutPercent;
                row.maxSend = row.parameters.maxSend;

                return row;
            });
        }
        setRows(response.data.rows);
    };

    // Load table
    let getRows = async () => {
        getRecurringRows();
        getNonRecurringCampaigns();
    };

    useEffect(() => {
        getRows();
    }, []);

    let recurringColumns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'type', Header: 'Type' },
        { accessor: 'name', Header: 'Name' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'startDate', Header: 'Start Date' },
        { accessor: 'endDate', Header: 'End Date' },
        { accessor: 'sendTime', Header: 'Send Time' },
        { accessor: 'frequency', Header: 'Frequency' },
        { accessor: 'dayOfWeek', Header: 'dayOfWeek' },
        {
            Header: 'Cancel',
            Cell: ({ row }) => {
                if (row.original.status === 'SCHEDULED' || row.original.status === 'IN_PROGRESS') {
                    return (
                        <Button
                            onClick={async (e) => {
                                e.stopPropagation();
                                if (window.confirm('Cancel recurring campaign?')) {
                                    let response = await api.cancelRecurringCampaign(row.original.id);
                                    if (response.status === false) {
                                        setNotification({
                                            text: 'Failed to cancel recurring campaign ' + response.msg,
                                            level: 'error',
                                        });
                                        return;
                                    }

                                    setNotification({ text: 'Recurring Campaign cancelled', level: 'success' });
                                    getRows();
                                }
                            }}
                        >
                            CANCEL
                        </Button>
                    );
                }
                return null;
            },
        },
    ];

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'type', Header: 'Type' },
        { accessor: 'name', Header: 'Name' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'scheduledAt', Header: 'Scheduled' },
        { accessor: 'totalListSize', Header: 'Total List Size' },
        { accessor: 'holdoutPercent', Header: 'Holdout Percent' },
        { accessor: 'maxSend', Header: 'Max Send' },
        {
            Header: 'Test',
            Cell: ({ row }) => {
                return (
                    <div
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => {
                            setSelectedCampaign(row.original);
                            setSendTestEmailModalOpen(true);
                        }}
                    >
                        Send Test Email
                    </div>
                );
            },
        },
        {
            Header: 'Cancel',
            Cell: ({ row }) => {
                if (row.original.status === 'SCHEDULED' || row.original.status === 'CONFIGURED') {
                    return (
                        <Button
                            onClick={async () => {
                                if (window.confirm('Cancel campaign?')) {
                                    let response = await api.cancelCampaign(row.original.id);
                                    if (response.status === false) {
                                        setNotification({
                                            text: 'Failed to cancel campaign ' + response.msg,
                                            level: 'error',
                                        });
                                        return;
                                    }

                                    setNotification({ text: 'Campaign cancelled', level: 'success' });
                                    getRows();
                                }
                            }}
                        >
                            CANCEL
                        </Button>
                    );
                }
                return null;
            },
        },
        {
            Header: 'Clone',
            Cell: ({ row }) => {
                let [configuring, setConfiguring] = useState(false);
                return row.original.notifyServiceID === 27 ||
                    row.original.notifyServiceID === 28 ||
                    row.original.notifyServiceID === 29 ? (
                    <></>
                ) : (
                    <Button
                        onClick={async () => {
                            setSelectedCampaign(row.original);
                            setCloneCampaignModalOpen(true);
                        }}
                    >
                        Clone
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            {selectedCampaign && sendTestEmailModalOpen && (
                <SendTestEmailModal
                    open={sendTestEmailModalOpen}
                    closeModal={() => {
                        setSendTestEmailModalOpen(false);
                    }}
                    campaignID={selectedCampaign.id}
                    segments={selectedCampaign.parameters.segments}
                    sendTestEmail={sendTestEmail}
                />
            )}
            {selectedCampaign && cloneCampaignModalOpen && (
                <CloneCampaignModal
                    open={cloneCampaignModalOpen}
                    closeModal={() => {
                        setCloneCampaignModalOpen(false);
                        setSelectedCampaign(null);
                    }}
                    campaign={selectedCampaign}
                    getRows={getRows}
                    setNotification={setNotification}
                />
            )}
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab value="1" label="View Campaigns" />
                <Tab value="2" label="View Recurring Campaigns" />
                <Tab value="3" label="Create Campaign" />
            </Tabs>
            <TabPanel index="1" selectedTab={selectedTab}>
                <ViewChildrenEmailCampaigns setNotification={setNotification} columns={columns} />
            </TabPanel>
            <TabPanel index="2" selectedTab={selectedTab}>
                <DataTable
                    columns={recurringColumns}
                    data={recurringRows}
                    expandable={true}
                    ExpansionComponent={
                        <ViewChildrenEmailCampaigns columns={columns} setNotification={setNotification} />
                    }
                />
            </TabPanel>
            <TabPanel index="3" selectedTab={selectedTab}>
                <CreateCampaign getRows={getRows} setNotification={setNotification} />
            </TabPanel>
        </div>
    );
};

let ViewChildrenEmailCampaigns = ({ parentRow, setExpandedRow, columns, setNotification }) => {
    let [rows, setRows] = useState([]);

    let getRows = async () => {
        let recurringCampaignID = null;
        if (parentRow) {
            recurringCampaignID = parentRow.original.id;
        }
        let response = await api.getCampaigns(recurringCampaignID);
        if (!response.status) {
            setNotification({ text: 'Failed to get campaigns', level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.scheduledAt = moment(row.scheduledAt.date + row.scheduledAt.timezone)
                    .local()
                    .format('MM/DD/YYYY h:mm a');

                if (row.segmentCounts) {
                    row.totalListSize = 0;
                    Object.keys(row.segmentCounts).forEach((key) => {
                        row.totalListSize += row.segmentCounts[key];
                    });
                }
                row.holdoutPercent = row.parameters.holdoutPercent;
                row.maxSend = row.parameters.maxSend;

                return row;
            });
        }
        setRows(response.data.rows);
    };

    useEffect(() => {
        getRows();
    }, []);

    return (
        <div style={{ paddingLeft: 50 }}>
            <DataTable
                title={parentRow ? `Recurring Campaign ${parentRow.original.id}` : ''}
                columns={columns}
                data={rows}
                expandable={true}
                ExpansionComponent={<ViewSegmentDetails setNotification={setNotification} getRows={getRows} />}
            />
        </div>
    );
};

const ViewSegmentDetails = ({ parentRow, setExpandedRow, setNotification, getRows }) => {
    let segments = [];
    let segmentsObj = parentRow?.original?.parameters?.segments;
    if (segmentsObj) {
        Object.keys(segmentsObj).forEach((key) => {
            let name = segmentsObj[key].name;
            if (!name) {
                return;
            }
            let listSize = parentRow.original?.segmentCounts?.[name];
            segmentsObj[key].segmentListSize = listSize;
            segments.push(segmentsObj[key]);
        });
    }
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    if (!segments) {
        return <div>No Segment Details</div>;
    }

    return (
        <div style={{ paddingLeft: 20 }}>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                {segments.map((item, index) => (
                    <Tab key={index} value={`${index}`} label={`Segment ${item.name}`} />
                ))}
            </Tabs>
            {segments.map((item, index) => {
                let formFields = [];
                let allFormFields = [
                    {
                        name: 'name',
                        inputElement: 'textField',
                        label: 'Segment Name',
                        gridValue: 12,
                        inputProps: { readOnly: true },
                    },
                    {
                        name: 'segmentWeight',
                        inputElement: 'textField',
                        label: 'Segment %',
                        gridValue: 12,
                        inputProps: { readOnly: true },
                    },
                    {
                        name: 'subject',
                        inputElement: 'textField',
                        label: 'Subject',
                        gridValue: 12,
                        inputProps: { required: true },
                    },
                    {
                        name: 'preheader',
                        inputElement: 'textField',
                        label: 'Preheader',
                        gridValue: 12,
                        inputProps: { required: true },
                    },
                    {
                        name: 'sender',
                        inputElement: 'select',
                        label: 'Sender',
                        gridValue: 12,
                        initialValue: 'donotreply@misfitsmarket.com',
                        inputProps: {
                            required: true,
                            opts: [
                                'donotreply@misfitsmarket.com',
                                'contact@misfitsmarket.com',
                                'marley@misfitsmarket.com',
                            ],
                        },
                    },
                    {
                        name: 'replyTo',
                        inputElement: 'select',
                        label: 'Reply To',
                        gridValue: 12,
                        initialValue: 'donotreply@misfitsmarket.com',
                        inputProps: {
                            required: true,
                            opts: [
                                'donotreply@misfitsmarket.com',
                                'contact@misfitsmarket.com',
                                'marley@misfitsmarket.com',
                            ],
                        },
                    },
                    ...getTemplateSpecificFormFields(item.template),
                ];
                allFormFields.forEach((formField) => {
                    formField.initialValue = item[formField.name];
                    formFields.push(formField);
                });

                return (
                    <TabPanel key={index} index={`${index}`} selectedTab={selectedTab}>
                        <FormWithDisplay
                            formFields={formFields}
                            title={item.template}
                            editable={!['SENT', 'CANCELLED'].includes(parentRow.original.status)}
                            onSubmit={async (formData) => {
                                let updatedFields = {};
                                Object.keys(formData).forEach((key) => {
                                    if (formData[key] !== item[key]) {
                                        updatedFields[key] = formData[key];
                                    }
                                });

                                if (!Object.keys(updatedFields).length) {
                                    return true;
                                }

                                let res = await api.updateEmailCampaignSegment(
                                    parentRow.original.id,
                                    item.name,
                                    updatedFields
                                );
                                if (!res.status) {
                                    setNotification({
                                        level: 'error',
                                        text: 'Failed to update campaign segment: ' + res.msg,
                                    });
                                }
                                getRows();
                                return res.status;
                            }}
                        />
                    </TabPanel>
                );
            })}
        </div>
    );
};

const FormWithDisplay = ({ formFields, title, onSubmit, editable = true, button }) => {
    const useStyles = makeStyles((theme) => ({
        inputLabel: {
            paddingBottom: '10px',
            color: 'black',
            fontWeight: 'bold',
            position: 'relative!important',
            '& .MuiInputLabel-formControl': {
                position: 'relative!important',
            },
        },
        inputFormLabel: {
            position: 'relative!important',
            '& .MuiInputLabel-formControl': {
                position: 'relative!important',
            },
        },
    }));
    const classes = useStyles();

    const [editing, setEditing] = useState(false);

    const getMatchingOption = (item) => {
        let options = item.inputProps.opts;
        let match = options.find((opt) => !!opt.value && String(opt.value) === String(item.initialValue));
        if (match) {
            return match.text;
        }
        return item.initialValue;
    };

    return (
        <>
            <InputLabel
                className={classes.inputLabel}
                onClick={() => {
                    if (!editable) {
                        return;
                    }
                    setEditing(!editing);
                }}
            >
                <span style={{ paddingRight: '20px' }}>{title}</span>
                {editable && (
                    <span style={{ cursor: 'pointer' }}>
                        {editing ? (
                            <CloseIcon style={{ fontSize: '16px' }} />
                        ) : (
                            <EditIcon style={{ fontSize: '16px' }} />
                        )}
                    </span>
                )}
            </InputLabel>
            {editing ? (
                <FormComponent
                    button={button || null}
                    formFields={formFields}
                    inputElementStyle={{ padding: '0px', margin: '0px' }}
                    formControlStyle={{ padding: '0px 3px' }}
                    onSubmit={async (formData, resetForm) => {
                        let res = await onSubmit(formData);
                        if (res) {
                            setEditing(false);
                        }
                        return res;
                    }}
                />
            ) : (
                <Grid container alignItems="center" style={{ textAlign: 'left' }}>
                    {formFields.map((item) => (
                        <Grid item xs={12} md={item.gridValue || 12} key={item.name} style={{ padding: '5px' }}>
                            <InputLabel className={classes.inputFormLabel} shrink={true}>
                                {item.label}
                            </InputLabel>
                            <span>{item.inputProps?.opts ? `${getMatchingOption(item)}` : `${item.initialValue}`}</span>
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

export { EmailCampaigns };
