import React, { useEffect, useState } from 'react';
import { Notifications, FormComponent, FileUpload } from '../../../shared';
import { customerApi as api } from '../../../api/customer';
import { logisticsApi } from '../../../api/logistics';

import './CreateServiceDelayBanners.css';

const CreateServiceDelayBanners = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    const [routesTextArea, setRoutesTextArea] = useState('');
    const [zipTextArea, setZipTextArea] = useState('');
    const [zipCodeFile, setZipCodeFile] = useState();
    const [selectedLineHauls, setSelectedLineHauls] = useState([]);
    const [lineHauls, setLineHauls] = useState([]);

    let getLineHauls = async () => {
        let res = await logisticsApi.getLineHauls('activeonly');

        if (res.status === false) {
            setNotification({ text: 'Failed to get line hauls. ' + res.msg, level: 'error' });
            return;
        }

        setLineHauls(res.data.lineHauls);
    };

    useEffect(() => {
        getLineHauls();
    }, []);

    let formFields = [
        {
            name: 'bannerName',
            gridValue: 12,
            inputElement: 'textField',
            inputProps: { required: true },
            label: 'Banner Name',
        },
        {
            name: 'active',
            formControlId: 'service-delay-banner-active-form-control',
            gridValue: 12,
            inputElement: 'checkbox',
            label: 'Active',
        },
        {
            name: 'startDate',
            gridValue: 12,
            inputElement: 'date',
            inputProps: { required: true },
            label: 'Start Date',
        },
        { name: 'endDate', inputElement: 'date', label: 'End Date', gridValue: 12, inputProps: { required: true } },
        {
            name: 'copy',
            gridValue: 12,
            inputElement: 'textField',
            inputProps: { required: true, multiline: true },
            label: 'Copy (multiline)',
        },
        {
            name: 'copyDescription',
            gridValue: 12,
            ignoreOnSubmit: true,
            inputElement: () => (
                <p className="field-description">
                    {
                        'Copy can include HTML elements. There are various custom tags available, such as <Link> and <Bold>, for ease of use.'
                    }
                    <br />
                    <br />
                    For more information, visit the{' '}
                    <a
                        href="https://misfitsmarket.atlassian.net/wiki/spaces/TECH/pages/3125903367/Service+Delay+Banners"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Confluence doc
                    </a>
                    .
                </p>
            ),
        },
        {
            name: 'routes',
            gridValue: 12,
            inputElement: () => {
                return (
                    <>
                        <h5 className="text-area-header">Routes to display on (separate by line break)</h5>
                        <textarea
                            className="text-area"
                            value={routesTextArea}
                            onChange={(e) => setRoutesTextArea(e.target.value)}
                            rows={6}
                            placeholder={'Example:\n\n^/account\n^/shop/aisle/deli$\n^/shop(?!/product/)'}
                        ></textarea>
                    </>
                );
            },
        },
        {
            name: 'routesDescription',
            gridValue: 12,
            ignoreOnSubmit: true,
            inputElement: () => (
                <p className="field-description">
                    If no routes are specified, the banner will target all valid routes.
                    <br />
                    Routes can include regular expressions (regex).
                    <br />
                    <br />
                    For more information, visit the{' '}
                    <a
                        href="https://misfitsmarket.atlassian.net/wiki/spaces/TECH/pages/3125903367/Service+Delay+Banners"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Confluence doc
                    </a>
                    .
                </p>
            ),
        },
        {
            name: 'subscribedBeforeDate',
            gridValue: 12,
            inputElement: 'date',
            label: 'Subscribed Before Date',
        },
        {
            name: 'lineHaulIDs',
            disabled: !!zipCodeFile || zipTextArea.length > 0,
            gridValue: 12,
            inputElement: 'autocomplete',
            inputProps: {
                multiple: true,
                opts: lineHauls?.map((item) => ({ text: '' + item.id, value: '' + item.id })),
                onChange: (e, val) => setSelectedLineHauls(val),
            },
            label: 'Line Haul IDs',
        },
        {
            name: 'zipCodeText',
            gridValue: 12,
            inputElement: () => {
                return (
                    <div style={{ opacity: !!zipCodeFile || selectedLineHauls.length > 0 ? 0.5 : 1 }}>
                        <h5 className="text-area-header">Zip Codes Affected (separate by line break)</h5>
                        <textarea
                            className="text-area"
                            disabled={!!zipCodeFile || selectedLineHauls.length > 0}
                            onChange={(e) => setZipTextArea(e.target.value)}
                            placeholder={'Example:\n\n63141\n92614\n74012'}
                            rows={6}
                            value={zipTextArea}
                        ></textarea>
                    </div>
                );
            },
        },
        {
            name: 'zipCodeFile',
            gridValue: 12,
            inputElement: () => {
                return (
                    <div style={{ opacity: zipTextArea.length > 0 || selectedLineHauls.length > 0 ? 0.5 : 1 }}>
                        <h5 className="zip-code-file-header">
                            For CSV format - list zip codes in a single column with no header
                        </h5>
                        <FileUpload
                            accept={['.csv']}
                            disabled={zipTextArea.length > 0 || selectedLineHauls.length > 0}
                            file={zipCodeFile}
                            onFileChange={setZipCodeFile}
                            text={'Zip Code Upload'}
                        />
                    </div>
                );
            },
        },
        {
            name: 'locationTargetingDescription',
            gridValue: 12,
            ignoreOnSubmit: true,
            inputElement: () => (
                <p className="field-description location-targeting">
                    Either line haul ids or zip codes can be provided; not both.
                    <br />
                    If no line haul ids or zip codes are specified, the banner will target all users.
                    <br />
                    <br />
                    For more information, visit the{' '}
                    <a
                        href="https://misfitsmarket.atlassian.net/wiki/spaces/TECH/pages/3125903367/Service+Delay+Banners"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Confluence doc
                    </a>
                    .
                </p>
            ),
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            <div className="service-delay-banners-form">
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        formData.routes = routesTextArea.split(/\n|\r\n?|,/).map((route) => route.trim());
                        formData.zipCodeText = zipTextArea;
                        formData.zipCodeFile = zipCodeFile;
                        formData.lineHaulIDs = formData.lineHaulIDs.map((item) => item.value);
                        let res = await api.createDelayBanner(formData);
                        if (!res.status) {
                            setNotification({ text: 'Failed to create delay banner. ' + res.msg, level: 'error' });
                            return;
                        }
                        setNotification({ text: 'Delay banner created!', level: 'success' });
                        resetForm();
                        setRoutesTextArea('');
                        setZipTextArea('');
                        setZipCodeFile();
                    }}
                />
            </div>
        </>
    );
};

export { CreateServiceDelayBanners };
