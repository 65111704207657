import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Dialog, DialogContent } from '@material-ui/core';
import { partnershipsApi as api } from '../../../../api/partnerships';
import '../orders.css';

const OrderInfoCard = ({
    order,
    getOrders,
    setSuccessMessage,
    canCancelOrders,
    collapsable = true,
    hideHeader = false,
    containerClass = 'curated-list-container',
}) => {
    const [expanded, setExpanded] = useState(!collapsable);
    const [cancelOrderDialogOpen, setCancelOrderDialogOpen] = useState(false);
    const [cancelOrderError, setCancelOrderError] = useState('');
    const [shipmentDetails, setShipmentDetails] = useState([]);

    let orderTotal = 0;
    order.slotItems.forEach((item) => {
        orderTotal += item.price * item.qty;
    });
    orderTotal = orderTotal.toFixed(2);

    const getShipmentDetails = async () => {
        let res = await api.getShipmentDetailsForOrder(order.mmOrderNumber);
        if (!res.status) {
            return;
        }
        setShipmentDetails(res.data.rows);
    };

    useEffect(() => {
        if (expanded && !shipmentDetails.length) {
            getShipmentDetails();
        }
    }, [expanded]);

    const cancelOrder = async () => {
        let promises = order.shipments.map((sh) => api.cancelImportedShipment(sh.mmShipmentID));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status) == false) {
            setCancelOrderError(`Failed to cancel order. Contact partners@misfitsmarket.com for support`);
            return;
        }

        setCancelOrderDialogOpen(false);
        setSuccessMessage(`Order #${order.mmOrderNumber} cancelled`);
        getOrders({});
    };

    return (
        <div className={containerClass}>
            <Dialog
                open={cancelOrderDialogOpen}
                maxWidth="sm"
                fullWidth={true}
                onClose={() => {
                    setCancelOrderDialogOpen(false);
                }}
            >
                <DialogContent>
                    <div className="cancel-order-container">
                        <div>Cancel Order #{order.mmOrderNumber}?</div>
                        <div>
                            <button
                                onClick={() => {
                                    cancelOrder();
                                }}
                                className="fam-button fam-button-yellow grotesk-bold"
                            >
                                Confirm Cancel
                            </button>
                        </div>
                        {cancelOrderError && <div className="fam-warning-text grotesk-regular">{cancelOrderError}</div>}
                    </div>
                </DialogContent>
            </Dialog>
            {!hideHeader && (
                <>
                    <div className="orders-header-container">
                        <div>
                            <span className="grotesk-bold small-header-text">MM Order Number</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="grotesk-regular small-header-text">
                                #{order.mmOrderNumber}{' '}
                                {moment(order.createdAt.date).local().format('MM/DD/YYYY h:mm a')}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="grotesk-bold small-header-text">
                                {order.firstName} {order.lastName}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="grotesk-regular small-header-text">{order.listName}</span>
                        </div>
                        <div>
                            {canCancelOrders && order.cancellable && (
                                <button
                                    onClick={() => {
                                        setCancelOrderDialogOpen(true);
                                    }}
                                    className="fam-button fam-button-white grotesk-bold"
                                >
                                    Cancel
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}

            {expanded && (
                <div className="list-details-container">
                    {!hideHeader && <div className="curated-list-divider"></div>}
                    <div>
                        <span className="grotesk-bold">Order Details</span>
                    </div>
                    <div className="card-details-container">
                        <div>
                            <div className="order-details-order-items-container">
                                {order.slotItems.map((item) => {
                                    return (
                                        <React.Fragment key={item.slot}>
                                            <span className="grotesk-bold">Slot {item.slot}</span>
                                            <span className="grotesk-regular">
                                                {item.name} x{item.qty}
                                            </span>
                                            <span className="grotesk-regular">
                                                ${(item.price * item.qty).toFixed(2)}
                                            </span>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            {order.slotsNotFilled.length > 0 && (
                                <>
                                    <div className="order-details-order-items-missing-container">
                                        <div className="grotesk-bold">Slots Not Filled</div>
                                        {order.slotsNotFilled.map((item) => {
                                            return (
                                                <div className="grotesk-regular" key={item}>
                                                    Slot {item}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="card-details-info-container">
                            <span className="grotesk-bold">Member ID</span>
                            <span className="grotesk-regular">{order.partnerMemberID}</span>

                            <span className="grotesk-bold">Order ID</span>
                            <span className="grotesk-regular">{order.partnerOrderID}</span>

                            <span className="grotesk-bold">Import Number</span>
                            <span className="grotesk-regular">{order.importID}</span>

                            <span className="grotesk-bold">Fulfillment Center</span>
                            <span className="grotesk-regular">{order.facilityName}</span>

                            <span className="grotesk-bold">Shipment Status{order.shipments.length > 1 && 'es'}</span>
                            <span className="grotesk-regular">{order.shipments.map((sh) => sh.status).join(', ')}</span>

                            <span className="grotesk-bold">Address</span>
                            <span className="grotesk-regular">
                                {order.lineOne}
                                {order.lineTwo && ` ${order.lineTwo}`}, {order.city}, {order.state} {order.zip}
                            </span>

                            <span className="grotesk-bold">Delivery Day</span>
                            <span className="grotesk-regular">{order.deliveryDay}</span>

                            <span className="grotesk-bold">Notes</span>
                            <span className="grotesk-regular">{order.notes}</span>
                        </div>
                    </div>
                    <div className="curated-list-divider"></div>
                    <div className="grotesk-bold">Order Total &nbsp;&nbsp; ${orderTotal}</div>
                    <div className="curated-list-divider"></div>
                    <div>
                        {shipmentDetails.map((sh) => {
                            return (
                                <React.Fragment key={sh.shipmentID}>
                                    <div className="card-details-info-container">
                                        <span className="grotesk-bold">Shipment ID</span>
                                        <span className="grotesk-regular">{sh.shipmentID}</span>

                                        <span className="grotesk-bold">Status</span>
                                        <span className="grotesk-regular">{sh.status}</span>

                                        <span className="grotesk-bold">Tracking</span>
                                        <span className="grotesk-regular">
                                            {!!sh.trackingUrl && (
                                                <a target="_blank" href={sh.trackingUrl}>
                                                    Tracking Link
                                                </a>
                                            )}
                                        </span>

                                        <span className="grotesk-bold">Items</span>
                                        <span className="grotesk-regular">
                                            {sh.items
                                                .map((it) => {
                                                    let itemStr = it.name;
                                                    if (it.qty > 1) {
                                                        itemStr += ` x${it.qty}`;
                                                    }
                                                    return itemStr;
                                                })
                                                .join(', ')}
                                        </span>
                                    </div>
                                    <div className="curated-list-divider"></div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className="curated-list-divider"></div>

            {collapsable && (
                <div
                    className="fam-warning-expand-button grotesk-regular align-center"
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? 'Collapse' : 'Expand'}
                </div>
            )}
        </div>
    );
};

export { OrderInfoCard };
