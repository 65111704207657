import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';
import { Modal, Button, Select, NativeSelect } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';

let ViewGoPuffPackout = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const user = useContext(UserContext);

    let getRows = async () => {
        let response = await api.getPackoutRows(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows ? response.data.rows : []);
    };

    useEffect(() => {
        getRows();
    }, []);

    let cols = [
        { accessor: 'packDate', Header: 'Pack Date' },
        { accessor: 'count', Header: 'Num Shipments' },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Download`}
                        onClick={async () => {
                            let res = await api.sendPackoutFile(user.getFacilityID(), row.original.packDate);
                            if (!res.status) {
                                setNotification({ text: 'Failed to send packout file. ' + res.msg, level: 'error' });
                                return;
                            }
                            setNotification({ text: 'Pack out file processing. Wait for email', level: 'success' });
                        }}
                    >
                        Send File
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h1>Go Puff Packout files</h1>
            <h2>Your file will be emailed to you. Please wait 10-15 minutes for completion.</h2>
            <Notifications options={notification} />
            <div>
                <DataTable columns={cols} data={rows} />
            </div>
        </div>
    );
};

export { ViewGoPuffPackout };
