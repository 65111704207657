import React from 'react';
import { Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import { customerApi as api } from '../../../api/customer';

const Notifications = ({ classes, getNotificationPreferences, preferences, handleRes, customerID }) => {
    let updateEmailNotificationPreferences = async (notifyServiceID, optOutFlag) => {
        let res = await api.updateEmailNotificationPreferences(customerID, { notifyServiceID, optOutFlag });
        return handleRes(res, 'Notification preferences updated', 'Failed to update notification preferences');
    };

    return (
        <div className={classes.card}>
            {preferences && (
                <>
                    <InputLabel className={classes.inputLabel} style={{ marginBottom: '15px' }}>
                        Notifications
                    </InputLabel>
                    <h4>Email</h4>
                    {preferences.notifications
                        .filter((n) => n.name !== 'REFERRAL REMINDER')
                        .map((item) => {
                            return (
                                <div key={item.id} style={{ textAlign: 'left' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={`${item.name.replaceAll(' ', '')}_checkbox`}
                                                onChange={async (event) => {
                                                    let res = await updateEmailNotificationPreferences(
                                                        item.id,
                                                        !event.target.checked
                                                    );
                                                    getNotificationPreferences();
                                                }}
                                                color="primary"
                                                defaultChecked={item.optIn}
                                            />
                                        }
                                        label={item.name}
                                    />
                                </div>
                            );
                        })}
                </>
            )}
        </div>
    );
};

export { Notifications };
