import { mmFetchWithResponse } from './mmFetchWithResponse';

let marketplaceApi = {
    editCuratedListInfo: async (id, sendParams) => {
        let url = `api/marketplace/v1/curatedLists/info/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
};

export { marketplaceApi };
