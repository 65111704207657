import React, { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';

const FAMAlert = function ({ severity, message, alignTop = false, autohide = true, duration = 5000 }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!!message) {
            setOpen(true);

            if (autohide) {
                setTimeout(() => {
                    setOpen(false);
                }, duration);
            }
        }
    }, [message]);

    const baseStyle = {
        minWidth: '50%',
    };

    const topStyle = {
        position: 'sticky',
        top: '20px',
        zIndex: 1000,
    };

    let style = alignTop ? { ...baseStyle, ...topStyle } : baseStyle;

    if (open) {
        return (
            <Alert style={style} severity={severity} variant={alignTop ? 'filled' : 'standard'}>
                {message}
            </Alert>
        );
    } else {
        return null;
    }
};

export { FAMAlert };
