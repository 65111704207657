import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let giftCodeApi = {
    createCodes: async (formData) => {
        let url = `api/giftCodes/v1/system/batches`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(formData),
        });
        return response;
    },
    downloadCodes: async (id) => {
        let filename = 'gift_codes_batch_' + id + '.csv';
        let url = `api/giftCodes/v1/system/batches/` + id + `/csv`;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadCodes] error', e.toString());
        }
    },
    getSystemCodeBatches: async () => {
        let url = `api/giftCodes/v1/system/batches`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
};

export { giftCodeApi };
