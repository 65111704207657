import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { shippingApi as api } from '../../../api/shipping';
import { Grid, InputLabel, Button } from '@material-ui/core';

let DBIDeepDive = () => {
    let [orderNum, setOrderNum] = useState(0);
    let [bringgID, setBringgID] = useState('');
    let [externalID, setExternalID] = useState('');
    let [title, setTitle] = useState('');
    let [crossDockAddress, setCrossDockAddress] = useState('');
    let [crossDockScheduled, setCrossDockScheduled] = useState('');
    let [customerAddress, setCustomerAddress] = useState('');
    let [customerScheduled, setCustomerScheduled] = useState('');
    let [deliveryNotes, setDeliveryNotes] = useState('');
    let [customerExternalID, setCustomerExternalID] = useState('');

    let [notification, setNotification] = useState({ text: null, level: null });
    const [searchBy, setSearchBy] = useState('orderNumber');

    let searchByOptions = [
        { value: 'orderNumber', text: 'Order Number' },
        { value: 'shipmentID', text: 'Shipment ID' },
    ];

    let formFields = [
        {
            name: 'searchBy',
            inputElement: 'select',
            initialValue: 'orderNumber',
            gridValue: 3,
            inputProps: {
                required: true,
                opts: searchByOptions,
                onChange: (event, value) => {
                    setSearchBy(value);
                },
            },
        },
        {
            name: 'searchTerm',
            label: ' ',
            inputElement: 'textField',
            gridValue: 8,
            dependencies: ['searchBy'],
        },
    ];

    // Load the page
    let loadData = async (id) => {
        if (searchBy == 'orderNumber') {
            var response = await api.dbiDeepDive(id, null);
            if (response.status === false) {
                setNotification({ text: 'Error retrieving info ' + response.msg, level: 'error' });
                return;
            }
        } else if (searchBy == 'shipmentID') {
            var response = await api.dbiDeepDive(null, id);
            if (response.status === false) {
                setNotification({ text: 'Error retrieving info ' + response.msg, level: 'error' });
                return;
            }
        } else {
            setNotification({ text: 'Error retrieving information. Contact engineering', level: 'error' });
            return;
        }

        setOrderNum(response.data.data.orderNum);
        setBringgID(response.data.data.id);
        setTitle(response.data.data.title);
        setExternalID(response.data.data.externalID);
        setCrossDockAddress(response.data.data.crossDockAddress);
        setCrossDockScheduled(response.data.data.crossDockScheduled);
        setCustomerAddress(response.data.data.customerAddress);
        setCustomerScheduled(response.data.data.customerScheduled);
        setCustomerExternalID(response.data.data.customerExternalID);
        setDeliveryNotes(response.data.data.deliveryNote);
    };

    return (
        <div>
            <h1>DBI Deep Dive</h1>
            <Notifications options={notification} />
            {}
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        loadData(formData.searchTerm);
                    }}
                />
                <br />
                <br />
                <h2>Summary Information</h2>
                <Grid container>
                    <Grid item xs={3}>
                        <InputLabel>Bringg ID</InputLabel>
                        {bringgID}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>External ID (as seen in Bringg)</InputLabel>
                        {externalID}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Title</InputLabel>
                        {title}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Order number</InputLabel>
                        {orderNum}
                    </Grid>
                </Grid>
                <br />
                <h2>Waypoints</h2>
                <Grid container>
                    <Grid item xs={3}>
                        <InputLabel>Cross Dock Address</InputLabel>
                        {crossDockAddress}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Cross Dock Scheduled At</InputLabel>
                        {crossDockScheduled}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Customer Address</InputLabel>
                        {customerAddress}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Customer Scheduled At</InputLabel>
                        {customerScheduled}
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel>Customer ID</InputLabel>
                        {customerExternalID}
                    </Grid>
                    <Grid item xs={9}>
                        <InputLabel>Delivery notes</InputLabel>
                        {deliveryNotes}
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Button
                        onClick={async () => {
                            var response = await api.resyncBringg(orderNum);
                            if (response.status === false) {
                                setNotification({ text: 'Error ' + response.msg, level: 'error' });
                                return;
                            }
                        }}
                    >
                        Resync
                    </Button>
                </Grid>
            </div>
        </div>
    );
};

export { DBIDeepDive };
