import { mmFetchWithResponse } from './mmFetchWithResponse';

let productApi = {
    bulkUploadDriverTipsSettings: async (data) => {
        let formData = new FormData();
        formData.append('driverTipsSettingsFile', data.file);

        let url = `api/product/v1/driverTipsSettings`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    createFeatureFlag: async (item) => {
        let url = `api/product/v1/featureFlag`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    confirmUploadDriverTipsSettings: async (key) => {
        let url = 'api/product/v1/driverTipsSettings/confirmUpload';
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({ key }),
        });
        return response;
    },
    getAssignableFeatureFlags: async () => {
        let url = `api/product/v1/featureFlag/assignable`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDriverTipsSettings: async () => {
        let url = `api/product/v1/driverTipsSettings`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getFeatureFlagCustomers: async () => {
        let url = `api/product/v1/featureFlag/customers`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getFeatureFlags: async () => {
        let url = `api/product/v1/featureFlag`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateDriverTipsSettings: async (driverTipsSettingsID, updates) => {
        let url = `api/product/v1/driverTipsSettings/${driverTipsSettingsID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(updates),
        });
        return response;  
    },
    updateFeatureFlag: async (featureFlagID, updates) => {
        let url = `api/product/v1/featureFlag/${featureFlagID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(updates),
        });
        return response;
    },
    uploadFeatureFlag: async (featureFlag, wipeList, customerFile) => {
        let formData = new FormData();
        formData.append('featureFlagID', featureFlag);
        formData.append('wipeList', wipeList);
        formData.append('customerFile', customerFile);

        let url = `api/product/v1/featureFlag/customers`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    uploadLineItem: async (customerFile) => {
        let formData = new FormData();
        formData.append('customerFile', customerFile);

        let url = `api/product/v1/lineItem/customers`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
};

export { productApi };
