import React, { useState, useEffect } from 'react';
import { InputLabel } from '@material-ui/core';
import { deliveryFrequencies } from '../data/data';
import { FormComponent } from '../../../shared';
import { formatDateString } from '../../../utils/dateUtils/dateUtils';
import { customerApi as api } from '../../../api/customer';

const UpdateDeliveryFrequency = ({ classes, subscription, handleRes }) => {
    const { deliveryFrequencyOptions, orderUnitInterval, preferredDeliveryDay } = subscription ?? {};
    const selectedOrderInterval = orderUnitInterval === 1 ? 1 : 2;
    const selectedBiWeeklyOrderInterval = orderUnitInterval === 1 ? 0 : orderUnitInterval;

    const [newOrderInterval, setNewOrderInterval] = useState(selectedOrderInterval);
    const [newBiWeeklyOrderInterval, setNewBiWeeklyOrderInterval] = useState(selectedBiWeeklyOrderInterval);
    const [biWeeklyDisabled, setBiWeeklyDisabled] = useState(Number(newOrderInterval) !== 1);

    const buildBiWeekyDeliveryFrequencyOptions = () => {
        const evenWeekDate = deliveryFrequencyOptions?.find((option) => option.orderUnitInterval === 3);
        const oddWeekDate = deliveryFrequencyOptions?.find((option) => option.orderUnitInterval === 4);

        return [
            {
                value: 0,
                text: ``,
            },
            {
                value: 3,
                text: `Biweekly, Even Weeks: Beginning on ${formatDateString(
                    evenWeekDate?.estimatedDeliveryDate?.date
                )}`,
            },
            {
                value: 4,
                text: `Biweekly, Odd Weeks: Beginning on ${formatDateString(oddWeekDate?.estimatedDeliveryDate?.date)}`,
            },
        ];
    };

    const handleDeliveryDaySubmit = async () => {
        const newOrderIntervalNumber = Number(newOrderInterval);
        const newBiWeeklyOrderIntervalNumber = Number(newBiWeeklyOrderInterval);
        const newUserOrderInterval =
            newOrderIntervalNumber === 1 ? newOrderIntervalNumber : newBiWeeklyOrderIntervalNumber;

        const sendParams = {
            prefDeliveryDay: preferredDeliveryDay,
            orderInterval: newUserOrderInterval,
        };

        let res = await api.changeDeliveryInformation(subscription.id, sendParams);
        return handleRes(res, 'Delivery information changed', 'Failed to change delivery information');
    };

    const deliveryFrequencyInputOpts = [
        {
            name: 'frequency',
            label: 'Frequency',
            inputElement: 'select',
            initialValue: selectedOrderInterval,
            inputProps: {
                opts: deliveryFrequencies,
                onChange: (event) => {
                    const defaultBiweeklyFrequency = event.target.value === '1' ? '0' : '3';

                    setNewBiWeeklyOrderInterval(defaultBiweeklyFrequency);
                    setNewOrderInterval(event.target.value);
                },
            },
        },
        {
            name: 'deliveryStartDate',
            label: 'Delivery Start Date (Biweekly Only)',
            inputElement: 'select',
            value: newBiWeeklyOrderInterval,
            inputProps: {
                disabled: biWeeklyDisabled,
                opts: buildBiWeekyDeliveryFrequencyOptions(),
                onChange: (event) => {
                    setNewBiWeeklyOrderInterval(event.target.value);
                },
            },
        },
    ];

    useEffect(() => {
        setBiWeeklyDisabled(Number(newBiWeeklyOrderInterval) === 0);
    }, [newBiWeeklyOrderInterval]);

    return (
        <div style={{ paddingTop: '20px' }}>
            <InputLabel className={classes.inputLabel}>Update Delivery Frequency</InputLabel>

            <FormComponent
                inputElementStyle={{ padding: '0px' }}
                formControlStyle={{ padding: '0px' }}
                formFields={deliveryFrequencyInputOpts}
                button={{
                    text: 'Update Delivery Frequency',
                }}
                onSubmit={() => {
                    handleDeliveryDaySubmit();
                }}
            />
        </div>
    );
};

export { UpdateDeliveryFrequency };
