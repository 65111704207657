import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Button } from '@material-ui/core';
import { fbmApi as api } from '../../../api/fbm';
import { warehouseApi } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';

let CreatePackagingASN = ({ initialValues = {}, onSuccess }) => {
    let [facilities, setFacilities] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [schedDeliveryDate, setSchedDeliveryDate] = useState();
    const { user, fbmAccount } = useContext(UserContext);

    // Master  form submit function
    let createPurchaseOrder = async (formData, resetForm) => {
        if (formData.itemsInPO.length === 0) {
            setNotification({ text: 'Must provide at least 1 item', level: 'error' });
            return;
        }

        for (let item of formData.itemsInPO) {
            if (!item.qty) {
                setNotification({ text: 'Please complete all required item fields', level: 'error' });
                return;
            }
        }

        let sendParams = {
            deliverDate: formData.scheduledDeliveryDate,
            deliverTime: formData.scheduledTime,
            referenceNumber: formData.referenceNumber,
            facilityID: formData.facility,
            items: formData.itemsInPO,
        };

        if (!user.fbm) {
            sendParams.fbmAccount = fbmAccount.fbmAccountName;
        }

        let response = await api.createPackagingASN(sendParams);
        if (response.status === false) {
            let message = 'Error creating ASN';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            setNotification({ text: 'ASN created!', level: 'success' });
            resetForm();

            if (onSuccess && onSuccess instanceof Function) {
                onSuccess();
            }
        }
    };

    useEffect(() => {
        getFacilities();
    }, [fbmAccount]);

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let allowedFacilityIDs = fbmAccount.facilityIDs;

        let facilitiesAsOpts = response.data.rows
            .map((row) => {
                row.text = row.name;
                row.value = row.id;
                return row;
            })
            .filter((facility) => {
                return allowedFacilityIDs.includes(facility.value);
            });

        setFacilities(facilitiesAsOpts);
    };

    // Define the form
    let formFields = [
        {
            name: 'scheduledDeliveryDate',
            inputElement: 'date',
            label: 'Scheduled Delivery Date',
            gridValue: 3,
            inputProps: { required: true, onChange: (e) => setSchedDeliveryDate(e.target.value) },
        },
        {
            name: 'scheduledTime',
            inputElement: 'time',
            label: 'Scheduled delivery time',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'facility',
            inputElement: 'select',
            label: 'Facility',
            gridValue: 2,
            inputProps: { required: true, opts: facilities },
        },
        { name: 'referenceNumber', inputElement: 'textField', label: 'Reference number', gridValue: 4 },
        {
            name: 'itemsInPO',
            label: 'Items',
            inputElement: ({ formData, setFormField }) => (
                <AddPackagingItems
                    setNotification={setNotification}
                    initialValue={initialValues}
                    formData={formData}
                    fbmAccount={fbmAccount}
                    setFormField={setFormField}
                />
            ),
        },
    ];

    return (
        <div>
            <h1>Create {fbmAccount.companyName} Packaging ASN</h1>
            <Notifications options={notification} />
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        createPurchaseOrder(formData, resetForm);
                    }}
                />
            </div>
        </div>
    );
};

let AddPackagingItems = ({ setNotification, setFormField, formData, initialValue, fbmAccount }) => {
    let [packagingOptions, setPackagingOptions] = useState([]);

    let [currentPackagingSku, setCurrentPackagingSku] = useState(null);
    let [autoPopUnitsPerCase, setAutoPopUnitsPerCase] = useState(null);
    let [autoPopItemQty, setAutoPopItemQty] = useState(null);

    let initValue = [];
    if (initialValue && initialValue.additionalData) {
        initValue = initialValue.additionalData.map((item) => {
            item.id = item.packagingID;
            return item;
        });
    }
    let [itemsInPO, setItemsInPO] = useState(initValue);

    let clearItems = () => {
        setItemsInPO([]);
    };

    useEffect(() => {
        // reset form (after submit) clears formData.itemsInPO, but does not reset internal itemsInPO
        // we need to do this manually when the form data has been cleared and there is no initial value
        if (itemsInPO.length && !formData.itemsInPO && !initValue.length) {
            clearItems();
        }
    }, [formData]);

    let getRows = async () => {
        let itemMasterRes = await api.getPackaging(fbmAccount.fbmAccountName);
        if (itemMasterRes.status === false) {
            return;
        } else {
            let items = itemMasterRes.data.rows.map((item) => {
                return { text: `SKU ${item.model.materialNumber}: ${item.model.description}`, value: item.model };
            });
            setPackagingOptions(items);
        }
    };

    // Add item submit function
    let addItemSubmit = async (formData) => {
        let item = {};
        item.id = formData.packagingSKU.id;
        item.description = formData.packagingSKU.description;
        item.sku = formData.packagingSKU.materialNumber;
        item.qty = formData.itemQty;
        item.unitsPerCase = formData.unitsPerCase;
        item.numPallets = formData.numPallets;
        item.partnerLotNumber = formData.partnerLotNumber;
        let items = itemsInPO.concat(item);
        setItemsInPO(items);
    };
    // Add item submit function

    // Remove an item from the PO
    let removeItemPressed = async (row) => {
        var array = [...itemsInPO];
        array.splice(row.index, 1);
        setItemsInPO(array);
    };

    useEffect(() => {
        getRows();
        if (initialValue.length) {
            setFormField(initialValue);
        }
    }, []);

    useEffect(() => {
        setFormField(itemsInPO);
    }, [itemsInPO]);

    let numPalletsOnChange = (csPallet, numPallets, fromData) => {
        csPallet = parseInt(csPallet);
        if (csPallet <= 0) return;

        if (!numPallets) return;
        numPallets = parseInt(numPallets);
        if (numPallets <= 0) return;

        let itemQty = numPallets * csPallet;

        if (!fromData.itemQty || parseInt(fromData.itemQty) == autoPopItemQty) {
            fromData.itemQty = itemQty;
        }

        setAutoPopItemQty(itemQty);
    };

    let packagingSKUOnChange = (value, formData) => {
        if (!value) {
            setCurrentPackagingSku(null);
        } else {
            setCurrentPackagingSku(value.value);

            if (!formData.unitsPerCase || parseInt(formData.unitsPerCase) == parseInt(autoPopUnitsPerCase)) {
                formData.unitsPerCase = value.value.unitsPerCase;
            }

            numPalletsOnChange(value.value.csPallet, formData.numPallets, formData);

            setAutoPopUnitsPerCase(value.value.unitsPerCase);
        }
    };

    let packagingFormFields = [
        {
            name: 'packagingSKU',
            inputElement: 'autocomplete',
            label: 'Packaging',
            gridValue: 3,
            inputProps: {
                required: true,
                onChange: (e, value, formData) => packagingSKUOnChange(value, formData),
                opts: packagingOptions,
            },
        },
        {
            name: 'numPallets',
            inputElement: 'textField',
            label: 'Pallets per SKU',
            gridValue: 2,
            inputProps: {
                type: 'number',
                required: true,
                onChange: (e, fromData) => {
                    if (!currentPackagingSku) return;
                    numPalletsOnChange(currentPackagingSku.csPallet, e.target.value, fromData);
                },
            },
        },
        {
            name: 'itemQty',
            inputElement: 'textField',
            label: 'Quantity (cases)',
            gridValue: 2,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'unitsPerCase',
            inputElement: 'textField',
            label: 'Units per Case',
            gridValue: 2,
            inputProps: { type: 'number', required: true },
        },
        {
            name: 'partnerLotNumber',
            inputElement: 'textField',
            label: 'Partner Lot Number',
            gridValue: 2,
        },
    ];

    let itemsInPOColumns = [
        { accessor: 'sku', Header: `${fbmAccount.companyName} SKU` },
        { accessor: 'description', Header: 'Description' },
        {
            accessor: 'numPallets',
            Header: 'Pallets per SKU',
            editable: true,
            inputProps: { type: 'number', inputType: 'number', required: true },
        },
        {
            accessor: 'qty',
            Header: 'Quantity',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        {
            accessor: 'unitsPerCase',
            Header: 'Units Per Case',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'partnerLotNumber',
            Header: 'Partner Lot Number',
            editable: true,
            editProps: { type: 'input' },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h3>Items In ASN</h3>
            <FormComponent
                formFields={packagingFormFields}
                onSubmit={async (formData, resetForm) => {
                    addItemSubmit(formData);
                    resetForm();
                }}
                button={{
                    text: 'Add',
                    gridValue: 1,
                    disabled: (formData) => {
                        if (!formData.packagingSKU) {
                            return true;
                        }
                        return false;
                    },
                }}
            />
            <DataTable
                columns={itemsInPOColumns}
                data={itemsInPO}
                editable={true}
                saveEdit={({ id, field, value, row }) => {
                    let items = JSON.parse(JSON.stringify(itemsInPO)).map((item) => {
                        if (item.id === id) {
                            item[field] = value;
                        }
                        return item;
                    });
                    setItemsInPO(items);
                }}
            />
        </div>
    );
};

export { CreatePackagingASN };
