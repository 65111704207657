import React, { useContext, useEffect, useState } from 'react';
import { DataTable, TabPanel, Notifications, UploadCSVAndReviewComponent } from '../../../shared';
import { productApi as api} from '../../../api/product';

const UploadDriverTipsSettings = () => {
    const columns = [
        { accessor: 'id', Header: 'ID'},
        { accessor: 'zipCode', Header: 'Zip Code'},
        { accessor: 'default', Header: 'Default'},
        { accessor: 'dollarAmount1', Header: 'Dollar Amount 1'},
        { accessor: 'dollarAmount2', Header: 'Dollar Amount 2'},
        { accessor: 'dollarAmount3', Header: 'Dollar Amount 3'},
        { accessor: 'percent1', Header: 'Percent 1'},
        { accessor: 'percent2', Header: 'Percent 2'},
        { accessor: 'percent3', Header: 'Percent 3'},
        { accessor: 'enabled', Header: 'Enabled'},
        { accessor: 'poolType', Header: 'Pool Type'},
        { accessor: 'createdAt', Header: 'Created at'},
        { accessor: 'updatedAt', Header: 'Updated at'},
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    const confirmUpload = async (key) => {
        const res = await api.confirmUploadDriverTipsSettings(key);
        return res;
    };

    const uploadDriverTipsSettings = async (formData) => {
        const res = await api.bulkUploadDriverTipsSettings(formData);
        return res;
    };
    
    const downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,zipCode,';
        csvContent +=
            'default,dollarAmount1,dollarAmount2,dollarAmount3,percent1,percent2,percent3,zipCodeEnabled(Y/N),poolType';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'driver_tips_settings_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <UploadCSVAndReviewComponent
                title="Upload Driver Tips Settings"
                fileLabel="Select File"
                upload={uploadDriverTipsSettings}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
                columns={columns}
            />
        </div>
    );
};

export { UploadDriverTipsSettings };