const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
const FULL_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const FULL_MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

/**
 * Returns day of the month with correct suffix (st, nd, rd, th, ex 3rd of the month)
 * @param {num} day - The day of the month you wish the append the suffix to
 * @example
 * const getOrdinal = getOrdinal('3'); --> 3rd
 */
const getOrdinal = (num) => {
    if (num > 3 && num < 21) return 'th';
    switch (num % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

/**
 * Returns new javascript Date from inputted date string
 * @param {dateString} - Date string ex: '2023-04-12 16:00:00.000000'
 * @example
 * const parseDate = parseDate('2023-04-12 16:00:00.000000'); --> Wed Apr 12 2023 00:00:00 GMT-0600 (Mountain Daylight Time)
 */
const parseDate = (dateString) => {
    if (!dateString) return undefined;

    const a = dateString?.split(/[^0-9]/);
    const d = new Date(a[0], a[1] - 1, a[2]);
    return d;
};

/**
 * Returns input date string in a Jan 1, 2023 format
 * @param {dateString} date - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {includeDay} bool - true/false - append day of the week
 * @param {includeYear} bool - true/false - append year
 * @param {fullDay} bool - true/false - include full weekday string
 * @param {fullMonth} bool - true/false - include full month value
 * @example
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000'); --> Apr 12th
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000', true); --> Wed, Apr 12th
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000', true, true); --> Wed, Apr 12th, 2023
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000', true, true, true); --> Wednesday, Apr 12th, 2023
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000', true, true, true, true); --> Wed, April 12th, 2023
 */
const formatDateString = (dateString, includeDay, includeYear, fullDay, fullMonth) => {
    if (!dateString) return undefined;

    const date = parseDate(dateString);
    const day = `${date.getDate()}${getOrdinal(date.getDate())}`;
    const month = fullMonth ? FULL_MONTHS[date.getMonth()] : MONTHS[date.getMonth()];

    let returnDate = `${month} ${day}`;

    if (includeDay) {
        const weekDay = fullDay ? FULL_DAYS[date.getDay()] : DAYS[date.getDay()];
        returnDate = `${weekDay}, ${returnDate}`;
    }

    if (includeYear) {
        returnDate = `${returnDate}, ${date.getFullYear()}`;
    }

    return returnDate;
};

export { formatDateString, parseDate };
