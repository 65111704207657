import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Modal,
    Grid,
    Select,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogContent,
} from '@material-ui/core';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { catalogApi as invApi } from '../../../api/catalog';
import { UserContext } from '../../../contexts/UserContext';
import { ViewCases } from './viewCases';
import printJS from 'print-js';
import {
    ConvertPalletOrCaseToItemLevelTwo,
    TransferPallet,
    DumpDonateRTVPalletOrCase,
    PrintLabels,
    CreateNewPallet,
    ConsumePackagingPalletToStaging,
    ReverseConsumePackagingPallet,
} from './palletAndCaseActions';

const fileReaderCheck = (fr, tmpPDF) => {
    if (tmpPDF instanceof Blob) {
        fr.readAsDataURL(tmpPDF);
    } else {
        alert('There was an issue printing the pdf');
    }
};

// The table to show items
const ViewLotEventTable = ({ parentRow, locations, getLots, showViewCasesModal, facilities, il2V2 }) => {
    let [consumePalletModalOpen, setConsumePalletModalOpen] = useState(false);
    let [lotID, setLotID] = useState(parentRow.original.id);
    let [eventRows, setEventRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [modalLaunch, setModalLaunch] = useState({ lotID: lotID, currentlocMapID: 0 });

    let [palletRows, setPalletRows] = useState([]);
    let [selectedPallet, setSelectedPallet] = useState(null);

    let [clickedIds, setClickedIds] = useState([]);

    let closeConsumePalletModal = () => {
        setConsumePalletModalOpen(false);
    };

    let showConsumePalletModal = (row, closeConsumePalletModal) => {
        setModalLaunch({
            palletID: row.original.id,
            lotID: row.original.lotID,
            lot: parentRow.original,
            itemType: parentRow.original.lotType,
            palletRows,
        });
        setConsumePalletModalOpen(true);
    };

    let eventColumn = [
        { accessor: 'timestamp.date', Header: 'Timestamp' },
        { accessor: 'eventType', Header: 'Event' },
        { accessor: 'palletName', Header: 'Pallet Name' },
        { accessor: 'details', Header: 'Details' },
        { accessor: 'n1', Header: 'Num Cases' },
        { accessor: 'authUser', Header: 'User' },
    ];

    let palletColumns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'name', Header: 'Pallet Name' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'numCases', Header: 'Num Cases' },
        { accessor: 'facilityName', Header: 'Facility' },
        { accessor: 'locationName', Header: 'Location' },
        {
            Header: 'Pallet Tag',
            Cell: ({ row }) => {
                let buttonColor1 = clickedIds.includes(`1_${row.original.id}`) ? 'default' : 'primary';
                let buttonColor4 = clickedIds.includes(`4_${row.original.id}`) ? 'default' : 'primary';
                let buttonColor16 = clickedIds.includes(`16_${row.original.id}`) ? 'default' : 'primary';
                return (
                    <>
                        <div style={{ padding: '5px' }}>
                            <Button
                                size="small"
                                color={buttonColor1}
                                variant="contained"
                                onClick={async () => {
                                    let ids = [...clickedIds, `1_${row.original.id}`];
                                    setClickedIds(ids);
                                    let tmpPDF = await api.downloadPalletTag(row.original.id, row.original.name, 1);
                                    var fr = new FileReader();
                                    fr.addEventListener(
                                        'load',
                                        () => {
                                            let tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                                            printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                                        },
                                        false
                                    );
                                    fileReaderCheck(fr, tmpPDF);
                                }}
                            >
                                🖨 1
                            </Button>
                        </div>
                        <div style={{ padding: '5px' }}>
                            <Button
                                size="small"
                                color={buttonColor4}
                                variant="contained"
                                onClick={async () => {
                                    let ids = [...clickedIds, `4_${row.original.id}`];
                                    setClickedIds(ids);
                                    let tmpPDF = await api.downloadPalletTag(row.original.id, row.original.name, 4);
                                    var fr = new FileReader();
                                    fr.addEventListener(
                                        'load',
                                        () => {
                                            let tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                                            printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                                        },
                                        false
                                    );
                                    fileReaderCheck(fr, tmpPDF);
                                }}
                            >
                                🖨 4
                            </Button>
                        </div>
                        <div style={{ padding: '5px' }}>
                            <Button
                                classes={{ label: 'buttonpad' }}
                                size="small"
                                color={buttonColor16}
                                variant="contained"
                                onClick={async () => {
                                    let ids = [...clickedIds, `16_${row.original.id}`];
                                    setClickedIds(ids);
                                    let tmpPDF = await api.downloadPalletTag(row.original.id, row.original.name, 16);
                                    var fr = new FileReader();
                                    fr.addEventListener(
                                        'load',
                                        () => {
                                            let tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                                            printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                                        },
                                        false
                                    );
                                    fileReaderCheck(fr, tmpPDF);
                                }}
                            >
                                🖨 16
                            </Button>
                        </div>
                    </>
                );
            },
        },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                showConsumePalletModal(row, closeConsumePalletModal);
                            }}
                        >
                            Actions
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'reverseDD',
            Header: 'Reverse Dump',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        onClick={async () => {
                            if (row.values.status !== 'EXPIRED_DUMP_DONATE') {
                                setNotification({ text: 'Pallet not in the right status', level: 'error' });
                                return;
                            }

                            let response = await api.reverseDumpDonate(row.values.id);
                            if (!response.status) {
                                setNotification({
                                    text: 'Failed to reverse dump donate ' + response.msg,
                                    level: 'error',
                                });
                                return;
                            }

                            setNotification({
                                text: 'Successfully reversed dump/donate ' + response.msg,
                                level: 'success',
                            });
                        }}
                    >
                        Reverse Dump
                    </Button>
                );
            },
        },
        {
            Header: 'View Cases',
            Cell: ({ row }) => {
                if (parentRow.original.lotType === 'Packaging') {
                    return null;
                }
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                showViewCasesModal('PALLET', row);
                            }}
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
        {
            Header: 'Case Tags',
            Cell: ({ row }) => {
                if (parentRow.original.lotType === 'Packaging') {
                    return null;
                }
                let buttonColorCaseTags = clickedIds.includes(`case_tags_${row.original.id}`) ? 'default' : 'primary';
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            variant="contained"
                            color={buttonColorCaseTags}
                            onClick={async () => {
                                let ids = [...clickedIds, `case_tags_${row.original.id}`];
                                setClickedIds(ids);
                                let tmpPDF = await api.downloadCaseTags({ palletID: row.original.id });
                                var fr = new FileReader();
                                fr.addEventListener(
                                    'load',
                                    () => {
                                        let tmpPDFdata = fr.result.substr(fr.result.indexOf('base64,') + 7);
                                        printJS({ printable: tmpPDFdata, type: 'pdf', base64: true });
                                    },
                                    false
                                );
                                fileReaderCheck(fr, tmpPDF);
                            }}
                        >
                            Print {row.original.numCases} case tags
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getItemsInTable = async () => {
        let response = await api.getLotEvents(lotID);

        if (response.status === false) {
            setNotification({ text: 'No lot events found ' + response.msg, level: 'error' });
            setEventRows([]);
            return;
        }

        let rows = response.data.rows
            .map((item) => {
                if (item.palletID == 0) {
                    item.palletID = '';
                }
                return item;
            })
            .filter((item) => !['MOVED_TO', 'MOVED_FROM'].includes(item.eventType));

        setEventRows(rows);
    };

    let getPallets = async () => {
        let response = await api.getPalletsInLot(lotID);
        if (!response.status) {
            setNotification({ text: 'Failed to get pallets in lot', level: 'error' });
            return;
        }

        setPalletRows(response.data.rows);
    };

    useEffect(() => {
        getItemsInTable();
        getPallets();
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            {consumePalletModalOpen && (
                <ConsumePalletModal
                    facilities={facilities}
                    rowData={modalLaunch}
                    il2V2={il2V2}
                    locations={locations}
                    closeModal={() => {
                        setConsumePalletModalOpen(false);
                        getItemsInTable();
                        getLots();
                        getPallets();
                    }}
                />
            )}
            <div>
                <Grid container>
                    <Grid item xs={6} style={{ paddingRight: '5px' }}>
                        <DataTable title="Pallets" editable={true} columns={palletColumns} data={palletRows} />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '5px' }}>
                        <DataTable title="Events" columns={eventColumn} data={eventRows} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

let ViewCasesModal = ({ rowData, closeModal }) => {
    return (
        <div>
            <Dialog
                open={true}
                onClose={closeModal}
                maxWidth="lg"
                fullWidth={true}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogContent>
                    <ViewCases palletID={rowData.palletID} lotID={rowData.lotID} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

// Modal for consuming lots
let ConsumePalletModal = ({ rowData, closeModal, facilities, il2V2, locations }) => {
    let [error, setError] = useState(null);
    let [consumeType, setConsumeType] = useState('');

    let [notification, setNotification] = useState({ text: null, level: null });
    let [palletID, setPalletID] = useState(rowData.palletID);
    let [pallet, setPallet] = useState(rowData.palletRows.find((item) => item.id === rowData.palletID));

    let lotID = rowData.lotID;
    const user = useContext(UserContext);

    useEffect(() => {
        let pallet = rowData.palletRows.find((item) => item.id === rowData.palletID);
        let facilityID = pallet.facilityID;
    }, []);

    // Handle when the user picks a consumption option
    let handleConsumeOptionChange = async (event) => {
        setConsumeType('');

        let option = event.target.value;
        setConsumeType(option);
    };

    return (
        <div>
            <Dialog
                open={true}
                onClose={closeModal}
                maxWidth="lg"
                fullWidth={true}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogContent>
                    <div>
                        <div style={{ width: '90%', margin: '0 auto' }}>
                            <h3>Actions For Pallet {pallet.name}</h3>
                            <div>
                                <InputLabel shrink={true}>Option</InputLabel>
                                <Select native className="input-element-select" onChange={handleConsumeOptionChange}>
                                    <option key="ps" value="ps">
                                        Please Select
                                    </option>
                                    <option key="il2" value="il2">
                                        Item Level 2
                                    </option>
                                    <option key="dump" value="dump">
                                        Dump
                                    </option>
                                    <option key="donate" value="donate">
                                        Donate
                                    </option>
                                    <option key="vendor" value="vendor">
                                        Return to vendor
                                    </option>
                                    <option key="transfer" value="transfer">
                                        Transfer Pallet
                                    </option>
                                    <option key="createNew" value="createNew">
                                        Create New Pallet
                                    </option>
                                    <option key="printLabels" value="printLabels">
                                        Print Pallet Labels
                                    </option>
                                    {pallet.locationType !== 'INTERLINE_STAGING' &&
                                        rowData.itemType === 'Packaging' && (
                                            <option key="consumeToStaging" value="consumeToStaging">
                                                Consume To Staging
                                            </option>
                                        )}
                                    {rowData.itemType === 'Packaging' &&
                                        (pallet.locationType === 'INTERLINE_STAGING' ||
                                            pallet.locationType === 'RED_ZONE' ||
                                            pallet.locationType === 'SUPERMARKET') && (
                                            <option key="reverseConsume" value="reverseConsume">
                                                Reverse Consume
                                            </option>
                                        )}
                                </Select>
                            </div>
                            {consumeType === 'il2' && (
                                <ConvertPalletOrCaseToItemLevelTwo
                                    il2V2={true}
                                    lotID={lotID}
                                    palletID={rowData.palletID}
                                    pallets={rowData.palletRows}
                                    onError={setError}
                                />
                            )}
                            {['dump', 'donate', 'vendor'].includes(consumeType) && (
                                <DumpDonateRTVPalletOrCase
                                    action={consumeType}
                                    palletID={rowData.palletID}
                                    onSuccess={() => {
                                        closeModal();
                                    }}
                                    onError={(message) => {
                                        setError(message);
                                    }}
                                />
                            )}
                            {consumeType === 'transfer' && (
                                <TransferPallet
                                    lotID={lotID}
                                    palletID={palletID}
                                    facilities={facilities}
                                    onError={setError}
                                    palletID={rowData.palletID}
                                    pallets={rowData.palletRows}
                                />
                            )}
                            {consumeType === 'createNew' && (
                                <CreateNewPallet palletID={palletID} lotID={lotID} onError={setError} />
                            )}
                            {consumeType === 'printLabels' && (
                                <PrintLabels palletID={palletID} type="pallet" name={pallet.name} />
                            )}
                            {consumeType === 'consumeToStaging' && (
                                <ConsumePackagingPalletToStaging
                                    palletID={palletID}
                                    pallet={pallet}
                                    onError={setError}
                                    onSuccess={() => {
                                        closeModal();
                                    }}
                                    locations={locations}
                                />
                            )}
                            {consumeType === 'reverseConsume' && (
                                <ReverseConsumePackagingPallet
                                    palletID={palletID}
                                    pallet={pallet}
                                    onError={(err) => {
                                        setNotification({ text: err, level: 'error' });
                                    }}
                                    onSuccess={() => {
                                        closeModal();
                                    }}
                                    locations={locations}
                                />
                            )}
                            {error && <div style={{ color: 'red' }}>{error}</div>}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const EditExpiresOnModal = ({ rowData, closeModal }) => {
    let [error, setError] = useState(null);

    let date = moment(rowData.expiresOn).format('YYYY-MM-DD');

    let formFields = [
        {
            name: 'lotID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'Lot ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'expiresOn',
            initialValue: date,
            inputElement: 'date',
            label: 'Expires On',
            gridValue: 4,
            inputProps: { required: true },
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let item = {};
                        item['expiresOn'] = formData.expiresOn;

                        let response = await api.updateLot(formData.lotID, item);
                        if (response.status === false) {
                            let message = 'Error modifying lot';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                        } else {
                            closeModal();
                        }
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

let ViewLotV2 = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [modalLaunch, setModalLaunch] = useState({ lotID: 0 });
    let [editExpiresOnModalOpen, setEditExpiresOnModalOpen] = useState(false);
    let [viewCasesModalOpen, setViewCasesModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});
    let [activeOnly, setActiveOnly] = useState(true);
    let [clickedIds, setClickedIds] = useState([]);
    let [il2V2, setIl2V2] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [locations, setLocations] = useState([]);

    const user = useContext(UserContext);

    let closeExpiresOnEditModal = () => {
        setEditExpiresOnModalOpen(false);
    };

    let showExpiresOnEditModal = (row, closeExpiresOnEditModal) => {
        setSelectedRow(row);
        setEditExpiresOnModalOpen(true);
    };

    let showViewCasesModal = (type, row) => {
        if (type === 'PALLET') {
            setModalLaunch({ palletID: row.original.id });
        } else if (type === 'LOT') {
            setModalLaunch({ lotID: row.original.id });
        }
        setViewCasesModalOpen(true);
    };

    let columns = [
        { accessor: 'id', Header: 'Lot Number' },
        { accessor: 'lotType', Header: 'Type' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'fbmPartnerSKU', Header: 'Partner SKU' },
        { accessor: 'partnerLotNumber', Header: 'Partner Lot Number', editable: true },
        { accessor: 'receivedDate', Header: 'Received Date' },
        { accessor: 'itemName', Header: 'Item' },
        { accessor: 'numCases', Header: 'Number of cases' },
        { accessor: 'expiresOn', Header: 'Expires', editable: true, customEdit: showExpiresOnEditModal },
        { accessor: 'purchaseOrderID', Header: 'PO' },
        {
            Header: 'View Cases',
            Cell: ({ row }) => {
                if (row.original.lotType === 'Packaging') {
                    return null;
                }
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                showViewCasesModal('LOT', row);
                            }}
                        >
                            VIEW
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getLots = async () => {
        setRows([]);
        let facilityID = user.getFacilityID();
        let response = await api.getLots(activeOnly, facilityID);

        if (response.status === false) {
            setNotification({ text: 'No lots found ' + response.msg, level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.receivedDate = moment(row.receivedDate.date).format('YYYY-MM-DD');
                return row;
            });
        }

        setRows(response.data.rows);
    };

    let getLocations = async () => {
        let response = await api.getActiveLocations(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No facilities found ' + response.msg, level: 'error' });
            setLocations([]);
            return;
        }

        let locationsAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setLocations([pleaseSelect].concat(locationsAsOpts));
    };

    useEffect(() => {
        getLots();
        getFacilities();
        getLocations();
    }, [activeOnly]);

    let getFacilities = async () => {
        let response = await api.getFacilities();
        if (response.status === false) {
            setNotification({ text: 'No facilities found ' + response.msg, level: 'error' });
            setFacilities([]);
            return;
        }

        setFacilities(
            response.data.rows
                .filter((item) => item.active)
                .map((row) => {
                    if (row.id === user.getFacilityID()) {
                        setIl2V2(row.il2V2);
                    }
                    row.text = row.name;
                    row.value = row.id;
                    return row;
                })
        );
    };

    return (
        <div>
            <h1>Lots</h1>
            <Notifications options={notification} />
            {viewCasesModalOpen && (
                <ViewCasesModal
                    rowData={modalLaunch}
                    closeModal={() => {
                        setViewCasesModalOpen(false);
                    }}
                />
            )}
            {editExpiresOnModalOpen && (
                <EditExpiresOnModal
                    rowData={selectedRow}
                    closeModal={() => {
                        setEditExpiresOnModalOpen(false);
                        getLots();
                    }}
                />
            )}
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="ActiveOnly_checkbox"
                            onChange={(event) => {
                                setActiveOnly(!activeOnly);
                            }}
                            color="primary"
                            checked={activeOnly}
                        />
                    }
                    label="Active Only"
                />
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value }) => {
                        let item = {};
                        item[field] = value;

                        let response = await api.updateLot(id, item);
                        if (response.status === false) {
                            setNotification({ text: 'Error modifying lot ' + response.msg, level: 'error' });
                        } else {
                            setNotification({ text: 'Lot updated', level: 'success' });
                            getLots();
                        }
                    }}
                    expandable={true}
                    ExpansionComponent={
                        <ViewLotEventTable
                            il2V2={il2V2}
                            facilities={facilities}
                            locations={locations}
                            getLots={getLots}
                            showViewCasesModal={showViewCasesModal}
                        />
                    }
                />
            </div>
        </div>
    );
};

export { ViewLotV2 };
