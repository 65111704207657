import { mmFetch } from './mmFetch';
import { mmFetchWithResponse } from './mmFetchWithResponse';

let infoTechApi = {
    addRoleToProfile: async (id, item) => {
        let url = `api/admin/v1/jalaprofiles/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
    createAccount: async (item) => {
        let url = `api/admin/v1/jalacounts`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createFoodAsMedicineAccount: async (item) => {
        let url = `api/admin/v1/fam/account`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createEmployeeBox: async (item) => {
        let url = `api/admin/v1/discountff`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createPicker: async (item) => {
        let url = `api/warehouse/v1/pickers`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    createPickerForJalapenoAccount: async (formData) => {
        let url = `api/admin/v1/jalacounts/picker`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(formData),
        });
        return response;
    },
    createProfile: async (item) => {
        let url = `api/admin/v1/jalaprofiles`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    downloadBadge: async (pickerID) => {
        let filename = pickerID + '_badge.png';
        let url = `api/warehouse/v1/pickers/getBadge?pickerId=` + pickerID;
        try {
            let res = await mmFetch(url);
            if (res.ok === true) {
                var blob = await res.blob();
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }

                var d = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = d;
                link.download = filename;
                link.click();

                setTimeout(function () {
                    window.URL.revokeObjectURL(res);
                }, 200);
            }
        } catch (e) {
            console.log('[downloadBadge] error', e.toString());
        }
    },
    deleteRoleFromProfile: async (profileID, roleID) => {
        let url = `api/admin/v1/jalaprofiles/` + profileID + `/` + roleID;
        let response = await mmFetchWithResponse(url, {
            method: 'DELETE',
        });
        return response;
    },
    getAccounts: async (activeFlag, accountType = '') => {
        let url = `api/admin/v1/jalacounts?active=${activeFlag}`;
        if (!!accountType) {
            url += `&accountType=${accountType}`;
        }
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDiscountFriendFamily: async () => {
        let url = `api/admin/v1/discountff`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getJalapenoProfiles: async (activeFlag, accountType) => {
        let url = `api/admin/v1/jalaprofiles?active=${activeFlag}&accountType=${accountType}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPickers: async () => {
        let url = `api/warehouse/v1/pickers`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getProfileApprovers: async () => {
        let url = `api/admin/v1/jalaprovers`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getProfileRoles: async (id) => {
        let url = `api/admin/v1/jalaprofiles/` + id;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getRoles: async (accountType) => {
        let url = `api/admin/v1/jalaroles?accountType=${accountType}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getUserRoles: async (id) => {
        let url = `api/admin/v1/jalacounts/` + id;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getUsersWithRole: async (roleID) => {
        let url = `api/admin/v1/jalacounts/roles/users?roleID=` + roleID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    revokeEmployeeBox: async (id) => {
        let url = `api/admin/v1/discountff/` + id + `/revoke`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    updateAccount: async ({ id, field, value }) => {
        let url = `api/admin/v2/jalacounts/user/update`;
        let res = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({
                id: id,
                [field]: value,
            }),
        });
        return res;
    },
    updateEmployeeBox: async (id, sendParams) => {
        let url = `api/admin/v1/discountff/` + id;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updateProfile: async (profileID, item) => {
        let url = `api/admin/v1/jalaprofiles/update/` + profileID;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(item),
        });
        return response;
    },
};

export { infoTechApi };
