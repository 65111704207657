import React, { useState, useEffect } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { packagingApi as api } from '../../../api/packaging';
import { procurementApi as procurementApi } from '../../../api/procurement';

let ViewPackaging = () => {
    let [rows, setRows] = useState([]);
    let [packagingTypesOpts, setPackagingTypesOpts] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [vendors, setVendors] = useState([]);
    let [boxOpts, setBoxOpts] = useState([]);
    let [shippingMethodOpts, setShippingMethodOpts] = useState([]);

    let getPackagingTypes = async () => {
        let response = await api.getPackagingTypes();
        if (response.status === false) {
            setNotification({ text: 'No packaging types found ' + response.msg, level: 'error' });
            setPackagingTypesOpts([]);
            return;
        }

        let packagingTypesOpts = response.data.rows.map((row) => {
            return {
                text: row.display_name,
                value: row.system_name,
            };
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setPackagingTypesOpts([pleaseSelect].concat(packagingTypesOpts));
    };

    // Load
    let getPackaging = async () => {
        let response = await api.getPackaging();
        if (response.status === false) {
            setNotification({ text: 'No packaging found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        setRows(
            response.data.rows.map((item) => {
                let keys = Object.keys(item.model);
                for (let key of keys) {
                    item[key] = item.model[key];
                }
                return item;
            })
        );
    };

    let getVendors = async () => {
        let response = await procurementApi.getVendors();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setVendors([]);
            return;
        }

        let vendorAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setVendors([pleaseSelect].concat(vendorAsOpts));
    };

    let getBoxes = async () => {
        let response = await api.getBoxes();
        if (response.status === false) {
            setNotification({ text: 'No Boxes found ' + response.msg, level: 'error' });
            setBoxOpts([]);
            return;
        }

        let boxOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setBoxOpts([pleaseSelect].concat(boxOpts));
    };

    let getShippingMethods = async () => {
        let response = await api.getShippingMethods();
        if (response.status === false) {
            setNotification({ text: 'No shipping methods found ' + response.msg, level: 'error' });
            setShippingMethodOpts([]);
            return;
        }

        let shippingMethodOpts = response.data.shippingMethods.map((row) => {
            let opt = {};
            opt.text = row;
            opt.value = row;
            return opt;
        });

        setShippingMethodOpts(shippingMethodOpts);
    };

    let filterString = (string) => {
        if (string.includes('model')) {
            return string.substr(6);
        }
        return string;
    };

    useEffect(() => {
        getPackagingTypes();
        getPackaging();
        getVendors();
        getBoxes();
        getShippingMethods();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        {
            accessor: 'materialNumber',
            Header: 'Material Number',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'type',
            Header: 'Type',
            editable: true,
            editProps: { type: 'select', options: packagingTypesOpts },
        },
        {
            accessor: 'shippingMethod',
            Header: 'Shipping Method',
            editable: true,
            editProps: { type: 'select', options: shippingMethodOpts },
        },
        { accessor: 'description', Header: 'Description', editable: true },
        { accessor: 'vendor', Header: 'Vendor', editable: true, editProps: { type: 'select', options: vendors } },
        {
            accessor: 'cube',
            Header: 'Cube',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        { accessor: 'boxSize', Header: 'For Box', editable: true, editProps: { type: 'select', options: boxOpts } },
        {
            accessor: 'masterSKU',
            Header: 'SKU',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'csPallet',
            Header: 'Cases per Pallet',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'unitsPerCase',
            Header: 'Units per Case',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        { accessor: 'itemNameInternal', Header: 'Internal Name', editable: true },
        { accessor: 'itemNameExternal', Header: 'External Name', editable: true },
    ];

    return (
        <div>
            <h1>Packaging</h1>
            <Notifications options={notification} />
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    tableName="viewPackaging"
                    saveEdit={async ({ row, field, value }) => {
                        let update = {};
                        update[filterString(field)] = value;

                        let response = await api.editPackaging(row.id, update);
                        if (response.status === false) {
                            let message = 'Error modifying packaging';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Packaging modified!', level: 'success' });
                            getPackaging();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export { ViewPackaging };
