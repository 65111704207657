import React, { useState, useEffect } from 'react';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { Button, Modal } from '@material-ui/core';
import { logisticsApi as api } from '../../../api/logistics';
import { Grid, Select, MenuItem } from '@material-ui/core';
import moment from 'moment';

let NoLineHaulZips = ({ data, deliveryDay }) => {
    let [rows, setRows] = useState(data || []);

    let columns = [
        { accessor: 'subCount', Header: 'Sub Count' },
        { accessor: 'zip', Header: 'Zip Code' },
        { accessor: 'state', Header: 'State' },
    ];

    return (
        <div>
            <h1>Zips with No Line Haul</h1>
            <div>This upload removed the {deliveryDay} delivery option for the following zip codes</div>
            <div>
                <DataTable
                    toolbarActions={[
                        {
                            name: 'Download',
                            action: () => {
                                let csvContent = 'data:text/csv;charset=utf-8,Sub Count,Zip Code,State\r\n';
                                for (let row of rows) {
                                    csvContent += `${row.subCount},${row.zip},${row.state}\r\n`;
                                }
                                var encodedUri = encodeURI(csvContent);
                                var link = document.createElement('a');
                                link.setAttribute('href', encodedUri);
                                link.setAttribute('download', `zips_no_linehaul_on_${deliveryDay}.csv`);
                                document.body.appendChild(link);
                                link.click();
                            },
                        },
                    ]}
                    columns={columns}
                    data={rows}
                    editable={false}
                />
            </div>
        </div>
    );
};

export { NoLineHaulZips };
