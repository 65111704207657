import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { CreateFeatureFlag } from '../components/createFeatureFlag.js';
import { ViewFeatureFlag } from '../components/viewFeatureFlag.js';
import { UploadFeatureFlag } from '../components/uploadFeatureFlag.js';
import { UploadLineItem } from '../components/uploadLineItem.js';
import { TabPanel } from '../../../shared';

const FeatureFlag = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="View Feature Flag" value="0" />
                <Tab label="Upload" value="1" />
                <Tab label="Create" value="2" />
                <Tab label="Line Item" value="3" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewFeatureFlag />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <UploadFeatureFlag />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <CreateFeatureFlag />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'3'}>
                <UploadLineItem />
            </TabPanel>
        </Paper>
    );
};

export { FeatureFlag };
