import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications, FormComponent, SearchBar } from '../../../shared';
import { inboundTruckApi as api } from '../../../api/inboundTrucks';
import { procurementApi as procurementApi } from '../../../api/procurement';
import { packagingApi } from '../../../api/packaging';
import { warehouseApi } from '../../../api/warehouse';
import { fbmApi } from '../../../api/fbm';
import {
    Button,
    Modal,
    Dialog,
    DialogContent,
    CircularProgress,
    Checkbox,
    TextField,
    Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { UserContext } from '../../../contexts/UserContext';
import { Autocomplete } from '@material-ui/lab';
import { logisticsApi } from '../../../api/logistics';

// The table to show items
const ViewItemsTable = ({ parentRow, setSelectedRow, setSelectedPO, setViewSkusModalOpen }) => {
    let [truckID, setTruckID] = useState(parentRow.original.id);
    let [rows, setRows] = useState([]);
    let [packagingRows, setPackagingRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [addItemModalOpen, setAddItemModalOpen] = useState(false);
    let [pos, setPos] = useState([]);
    let [transferASNs, setTransferASNs] = useState([]);
    let [packagingPos, setPackagingPos] = useState([]);
    let [partnerASNs, setPartnerASNs] = useState([]);
    let [partnerPackagingASNs, setPartnerPackagingASNs] = useState([]);
    const [multiTruckPO, setMultiTruckPO] = useState(false);

    const user = useContext(UserContext);

    let packagingColumns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'scheduledDeliveryDate', Header: 'Scheduled Delivery' },
        { accessor: 'status', Header: 'PO Status' },
        { accessor: 'vendorName', Header: 'Vendor' },
        { accessor: 'numPallets', Header: 'Num Pallets' },
        { accessor: 'totalWeight', Header: 'Weight' },
        { accessor: 'referenceNumber', Header: 'Reference' },
        { accessor: 'estimatedFreightCost', Header: 'Estimated Freight Cost' },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ row }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={async () => {
                            let response = await api.removePackagingItemFromInboundTruck(
                                parentRow.original.id,
                                row.original.id
                            );

                            if (response.status === false) {
                                setNotification({ text: "Can't remove item " + response.msg, level: 'error' });
                                return;
                            }

                            getItemsInTable();
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    let columns = [
        { accessor: 'id', Header: 'Purchase Order ID' },
        { accessor: 'priority', Header: 'Priority', type: 'checkbox' },
        { accessor: 'scheduledDeliveryDate', Header: 'Scheduled Delivery' },
        { accessor: 'shipperName', Header: 'Shipper' },
        { accessor: 'numSkus', Header: 'Num SKUs', hide: !multiTruckPO },
        { accessor: 'vendorName', Header: 'Vendor' },
        { accessor: 'buyers', Header: 'Buyers' },
        { accessor: 'numPallets', Header: 'Num Pallets' },
        { accessor: 'totalWeight', Header: 'Weight' },
        { accessor: 'pickupDate', Header: 'Pickup Date' },
        { accessor: 'status', Header: 'PO Status' },
        { accessor: 'deliveryType', Header: 'Delivery Type' },
        { accessor: 'pickupNumber', Header: 'Pickup Number' },
        { accessor: 'weekNumber', Header: 'Week Number' },
        { accessor: 'estimatedFreightCost', Header: 'Estimated Freight Cost', hide: multiTruckPO },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={async () => {
                            let response = await api.removeItemFromInboundTruck(parentRow.original.id, row.original.id);

                            if (response.status === false) {
                                setNotification({ text: "Can't remove item " + response.msg, level: 'error' });
                                return;
                            }

                            getItemsInTable();
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    if (parentRow.original.poType === 'partial') {
        columns.push({
            accessor: 'viewSkus',
            Header: 'View SKUs',
            Cell: ({ row }) => {
                return (
                    <Button
                        id={`ViewSkus_${row.id}`}
                        onClick={() => {
                            setSelectedPO(row.original.id);
                            setSelectedRow(parentRow);
                            setViewSkusModalOpen(true);
                        }}
                    >
                        View
                    </Button>
                );
            },
        });
    }

    let getItemsInTable = async () => {
        let poType;
        if (parentRow.original.poType === 'partial') {
            poType = 'partial';
        }
        let response = await api.getTruckDetails(truckID, user.getFacilityID(), poType);

        if (response.status === false) {
            setNotification({ text: 'No purchase orders found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(
            response.data.output.pos.map((row) => {
                row.scheduledDeliveryDate = moment(row.scheduledDeliveryDate.date).format('MM/DD/YYYY');
                row.pickupDate = !!row.pickupDate ? moment(row.pickupDate.date).format('MM/DD/YYYY') : '';
                if (row.buyers && Array.isArray(row.buyers)) {
                    row.buyers = row.buyers.map((item) => item.name).join(', ');
                }
                return row;
            })
        );
        setPackagingRows(
            response.data.output.packagingPos.map((row) => {
                row.scheduledDeliveryDate = moment(row.scheduledDeliveryDate.date).format('MM/DD/YYYY');
                return row;
            })
        );
    };

    let getPos = async () => {
        let pos = await procurementApi.getPOsPlaced(user.getFacilityID());
        if (!pos.status) {
            setNotification({ level: 'error', text: 'Failed to get POs' });
        }
        let filteredRows = pos.data.rows.filter((item) => {
            if (!item.truckID || item.truckID === 'N/A') {
                return true;
            }
            return false;
        });
        setPos(filteredRows);
    };

    let getTransferASNs = async () => {
        let asns = await warehouseApi.getInternalTransfers();
        if (!asns.status) {
            setNotification({ level: 'error', text: 'Failed to get POs' });
            return;
        }
        let filteredRows = asns.data.rows.filter((item) => {
            if (item.destinationFacilityID !== user.getFacilityID()) {
                return false;
            }
            if (!!item.truckID && item.truckID !== 'N/A') {
                return false;
            }
            return true;
        });
        setTransferASNs(filteredRows);
    };

    let getParnerASNs = async () => {
        if (parentRow.original.fbmPartner == '') {
            return;
        }
        let asns = await fbmApi.getASNs(parentRow.original.fbmPartner);
        if (!asns.status) {
            setNotification({ level: 'error', text: 'Failed to get FBM ASNs' });
            return;
        }
        let filteredRows = asns.data.rows.filter((item) => {
            if (item.facilityID != user.getFacilityID()) {
                return false;
            }
            if (item.status != 'PLACED') {
                return false;
            }
            return true;
        });
        setPartnerASNs(filteredRows);
    };

    let getPartnerPackagingASNs = async () => {
        if (parentRow.original.fbmPartner == '') {
            return;
        }

        let packASNs = await fbmApi.getPackagingASNs(parentRow.original.fbmPartner);
        if (!packASNs.status) {
            setNotification({ level: 'error', text: 'Failed to get FBM packaging ASNs' });
            return;
        }
        let filteredRows = packASNs.data.rows.filter((item) => {
            if (item.facilityID != user.getFacilityID()) {
                return false;
            }
            if (item.status != 'PLACED') {
                return false;
            }
            return true;
        });
        setPartnerPackagingASNs(filteredRows);
    };

    let getPackagingPos = async () => {
        let ppos = await packagingApi.getPurchaseOrders('PLACED', user.getFacilityID());
        if (!ppos.status) {
            setNotification({ level: 'error', text: 'Failed to get packaging POs' });
            return;
        }
        let filteredRows = ppos.data.rows.filter((item) => {
            if (!item.truckID) {
                return true;
            }
            return false;
        });
        setPackagingPos(filteredRows);
    };

    useEffect(() => {
        getItemsInTable();
        getPos();
        getTransferASNs();
        getPackagingPos();
        getParnerASNs();
        getPartnerPackagingASNs();

        if (parentRow.original.poType === 'partial') {
            setMultiTruckPO(true);
        }
    }, []);

    return (
        <div className="batch-table">
            <Notifications options={notification} />
            {addItemModalOpen && (
                <AddItemModal
                    transfer={parentRow.original.transfer}
                    poType={parentRow.original.poType}
                    deliveryType={parentRow.original.deliveryType}
                    truckID={truckID}
                    pos={pos}
                    transferASNs={transferASNs}
                    packagingPos={packagingPos}
                    partnerASNs={partnerASNs}
                    partnerPackagingASNs={partnerPackagingASNs}
                    isFbmPartnerTruck={parentRow.original.fbmPartner}
                    closeModal={() => {
                        setAddItemModalOpen(false);
                        getItemsInTable();
                    }}
                    setNotification={setNotification}
                />
            )}
            {rows.length > 0 && (
                <DataTable
                    title={parentRow.original.transfer ? 'Grocery Transfer ASNs' : 'Grocery Purchase Orders'}
                    columns={columns}
                    data={rows}
                />
            )}
            {packagingRows.length > 0 && (
                <DataTable title="Packaging Purchase Orders" data={packagingRows} columns={packagingColumns} />
            )}
            <Button
                id="AddPO"
                onClick={() => {
                    setAddItemModalOpen(true);
                }}
            >
                {parentRow.original.transfer || parentRow.original.fbmPartner ? 'Add ASN' : 'Add PO'}
            </Button>
        </div>
    );
};

// The modal to add items to an inbound truck
const AddItemModal = ({
    truckID,
    deliveryType,
    pos,
    packagingPos,
    closeModal,
    transfer,
    poType,
    transferASNs,
    isFbmPartnerTruck,
    partnerASNs,
    partnerPackagingASNs,
    setNotification,
}) => {
    const user = useContext(UserContext);
    let [error, setError] = useState(null);
    const [partialPoItems, setPartialPoItems] = useState([]);
    const [selectedPartialPoSkus, setSelectedPartialPoSkus] = useState([]);
    const [partialPoOptions, setPartialPoOptions] = useState([]);

    const getPartialPoOptions = async (deliveryType) => {
        let res = await api.getPartialTruckPOs(user.getFacilityID(), deliveryType);
        setPartialPoOptions(res.data.rows);
    };

    let dropDownOptions = ['Purchase Order'];

    useEffect(() => {
        if (poType !== 'partial') {
            dropDownOptions = dropDownOptions.push('Packaging Purchase Order');
        } else {
            getPartialPoOptions(deliveryType);
        }
    }, []);

    let groceryPOsOrASNs = isFbmPartnerTruck
        ? partnerASNs
        : transfer
        ? transferASNs
        : poType === 'partial'
        ? partialPoOptions
        : pos;
    let packagingPOsOrASNs = isFbmPartnerTruck ? partnerPackagingASNs : packagingPos;
    let formFields = [
        {
            name: 'type',
            label: 'Type',
            inputElement: 'select',
            display: !transfer,
            inputProps: { opts: dropDownOptions },
        },
        {
            name: 'poIDs',
            label: transfer || isFbmPartnerTruck ? 'ASNs' : 'POs',
            inputElement: 'autocomplete',
            inputProps: {
                opts: groceryPOsOrASNs
                    .filter((item) => {
                        if (isFbmPartnerTruck) {
                            return true;
                        }

                        //For partial only use server side filter
                        if (poType == 'partial') return true;

                        /**
                         * If the truck is DELIVERED, allow PO's that are delivered and do not have a cross dock
                         * If the truck is PICK UP, allow PO's that are pick up, AND PO's that are delivered with a cross dock
                         */
                        if (deliveryType === 'DELIVERED') {
                            return item.deliveryType === deliveryType && !item.crossDockID;
                        } else if (deliveryType === 'PICK UP') {
                            return item.deliveryType === deliveryType || item.crossDockID > 0;
                        }
                        return false;
                    })
                    .map((item) => {
                        if (transfer || isFbmPartnerTruck) {
                            return { value: item.id, text: `ASN ${item.id}` };
                        } else {
                            return { value: item.id, text: `PO ${item.id} - ${item.vendorName}` };
                        }
                    }),
                multiple: true,
                onChange: async (e, val, formData, reason, detail) => {
                    if (reason === 'select-option') {
                        let res = await api.getPOItemsAvailableForPartialPOTrucks(detail.option.value);
                        if (!res.data) {
                            setNotification({ level: 'error', text: 'Failed to get PO details' });
                            return;
                        }

                        let poItems = res.data.rows;
                        setPartialPoItems((items) => [...items, ...poItems]);
                    } else if (reason === 'remove-option') {
                        let poID = detail.option.value;

                        setSelectedPartialPoSkus((items) => items.filter((item) => item.poID !== poID));
                        setPartialPoItems((items) => items.filter((item) => item.poID !== poID));
                    }
                },
            },
            display: (formData) => formData.type === 'Purchase Order' || transfer,
            dependencies: ['type'],
        },
        {
            name: 'packagingPoIDs',
            label: 'Packaging POs',
            inputElement: 'autocomplete',
            inputProps: {
                opts: packagingPOsOrASNs.map((item) => ({
                    value: item.id,
                    text: `PO ${item.id} - ${item.vendorName}`,
                })),
                multiple: true,
            },
            display: (formData) => formData.type === 'Packaging Purchase Order',
            dependencies: ['type'],
        },
        {
            name: 'partialPoItems',
            label: 'PO Items',
            display: (formData) => !transfer && poType === 'partial' && formData.type === 'Purchase Order',
            inputElement: ({ formData, setFormField }) => (
                <Autocomplete
                    multiple
                    options={partialPoItems}
                    value={selectedPartialPoSkus}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                        `PO ${option.poID} - ${option.externalSku}: ${option.itemName}; Case Qty: ${option.qty}; Cases/Pallet: ${option.csPallet}`
                    }
                    getOptionSelected={(option, value) => option.poMapID === value.poMapID}
                    renderOption={(option, { selected }) => {
                        return (
                            <li>
                                <Checkbox checked={selected} style={{ marginRight: 4 }} color="primary" />
                                {`PO ${option.poID} - ${option.externalSku}: ${option.itemName}; Case Qty: ${option.qty}; Cases/Pallet: ${option.csPallet}`}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Select PO Items" />}
                    onChange={(e, val, reason, detail) => {
                        let option;
                        let partialSkuOption;

                        if (!!detail) {
                            option = detail.option;
                            partialSkuOption = {
                                poID: option.poID,
                                externalSku: option.externalSku,
                                itemName: option.itemName,
                                qty: option.qty,
                                csPallet: option.csPallet,
                                poMapID: option.poMapID,
                            };
                        }

                        if (reason === 'select-option') {
                            setSelectedPartialPoSkus((items) => [...items, partialSkuOption]);
                        } else if (reason === 'remove-option') {
                            setSelectedPartialPoSkus((items) =>
                                items.filter((item) => item.poMapID !== partialSkuOption.poMapID)
                            );
                        } else if (reason === 'clear') {
                            setSelectedPartialPoSkus([]);
                        }

                        setFormField(selectedPartialPoSkus);
                    }}
                />
            ),
        },
    ];

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        if (poType === 'partial') {
                            //make sure each PO has at least one item
                            let numberOfPurchaseOrders = formData.poIDs.length;
                            let counter = 0;

                            if (numberOfPurchaseOrders === 0) {
                                setNotification({ text: 'Must select at least one PO', level: 'error' });
                                return;
                            }

                            formData.poIDs.forEach((po) => {
                                selectedPartialPoSkus.every((item) => {
                                    if (item.poID === po.value) {
                                        counter += 1;
                                        return false;
                                    }
                                    return true;
                                });
                                return true;
                            });

                            if (counter !== numberOfPurchaseOrders) {
                                setNotification({
                                    text: 'Each PO must have at least one item selected',
                                    level: 'error',
                                });
                                return;
                            }
                        }

                        formData.poIDs = formData.poIDs.map((item) => item.value);
                        formData.packagingPoIDs = formData.packagingPoIDs.map((item) => item.value);
                        formData.partialPoItems = selectedPartialPoSkus.map((item) => item.poMapID);

                        if (!formData.poIDs.length && !formData.packagingPoIDs.length) {
                            return;
                        }
                        formData.facilityID = user.getFacilityID();

                        let response = await api.addItemsToInboundTruck(truckID, formData);

                        if (response.status === false) {
                            let message = 'Error adding item';
                            if (response.msg) {
                                message += ': ' + response.msg;
                            }
                            setError('Error updating ' + message);
                            return;
                        }

                        closeModal();
                    }}
                />
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

const EditDateModal = ({ rowData, closeModal, flag }) => {
    let [error, setError] = useState(null);
    let [date, setDate] = useState(null);
    let [time, setTime] = useState(null);
    let [loading, setLoading] = useState(false);

    let formFields = [
        {
            name: 'truckID',
            initialValue: rowData.id,
            inputElement: 'textField',
            label: 'Truck ID',
            gridValue: 4,
            inputProps: { readOnly: true },
        },
        {
            name: 'date',
            initialValue: date,
            inputElement: 'date',
            label: 'Date',
            gridValue: 4,
            inputProps: { required: true },
        },
        {
            name: 'time',
            initialValue: time,
            inputElement: 'time',
            label: 'Time',
            gridValue: 4,
            inputProps: { required: true },
        },
    ];

    useEffect(() => {
        setDate(moment(rowData[flag]).format('YYYY-MM-DD'));
        setTime(moment(rowData[flag]).format('HH:mm'));
    }, []);

    return (
        <Modal open={true} onClose={closeModal}>
            <div style={{ outline: 0, backgroundColor: 'white', width: '700px', margin: '10% auto', padding: '10px' }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData) => {
                            setLoading(true);
                            let item = {};

                            item[`${flag}Date`] = formData.date;
                            item[`${flag}Time`] = formData.time;

                            let response = await api.editTruckFacilityStopDetails(rowData.facilityStopID, item);
                            if (response.status === false) {
                                let message = 'Error modifying truck';
                                if (response) {
                                    message += ': ' + response.msg;
                                }
                                setError('Error updating ' + message);
                            } else {
                                closeModal();
                            }
                            setLoading(false);
                        }}
                    />
                )}
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </Modal>
    );
};

// Modal to upload truck temperatures and summary PDF
const TruckTemperaturesModal = ({ rowData, closeModal }) => {
    let [notification, setNotification] = useState({ level: null, text: null });
    let [truckTemperatures, setTruckTemperatures] = useState([]);
    const formFields = [
        {
            name: 'temperatures',
            inputElement: 'file',
            label: 'Truck Temperatures CSV',
            accept: ['.csv'],
        },
        {
            name: 'summary',
            inputElement: 'file',
            label: 'Summary PDF',
            accept: ['.pdf'],
        },
        {
            name: 'degreesFahrenheit',
            inputElement: 'textField',
            label: 'Temperature Truck Arrived At (F)',
            inputProps: {
                required: false,
                type: 'number',
                step: '0.1',
            },
        },
    ];

    const submitTruckTemperatures = async (formData, resetForm) => {
        try {
            if (!formData.temperatures && !formData.summary && !formData.degreesFahrenheit) {
                setNotification({ text: 'Must provide at least one temperature for this truck', level: 'error' });
                return false;
            }

            if (!formData.temperatures && formData.summary) {
                setNotification({ text: 'Summary PDF was provided but Temperature CSV is missing', level: 'error' });
                return false;
            }

            if (formData.temperatures && !formData.summary) {
                setNotification({ text: 'Temperature CSV was provided but Summary PDF is missing', level: 'error' });
                return false;
            }

            if (formData.temperatures && formData.summary) {
                const uploadResponse = await api.uploadTruckTemperatures(
                    rowData.original.id,
                    rowData.original.facilityStopID,
                    formData
                );
                if (uploadResponse?.status === false) {
                    let message = 'Error uploading truck temperatures:';
                    if (uploadResponse) {
                        message += ' ' + uploadResponse?.msg + '.';
                    }
                    setNotification({ text: message, level: 'error' });
                    return false;
                }
            }

            if (formData.degreesFahrenheit) {
                const body = {
                    degreesFahrenheit: formData.degreesFahrenheit,
                };
                const editResponse = await api.createTruckTemperature(
                    rowData.original.id,
                    rowData.original.facilityStopID,
                    body
                );
                if (editResponse.status === false) {
                    let message = 'Error creating manual truck temperature:';
                    if (editResponse) {
                        message += ' ' + editResponse.msg + '.';
                    }
                    setNotification({ text: message, level: 'error' });
                    return false;
                }
            }

            closeModal();
            setNotification({ text: 'Success', level: 'success' });
            return true;
        } catch (error) {
            setNotification({ text: 'Error uploading truck temperatures: ' + error.message, level: 'error' });
        }
    };

    let getTruckTemperatures = async (truckId, stopId) => {
        let response = await api.getTruckTemperatures(truckId, stopId);
        if (!response.status) {
            setNotification({ level: 'error', text: 'Failed to get truck temperatures' });
            return false;
        }
        setTruckTemperatures(response?.data?.rows);
    };

    useEffect(() => {
        getTruckTemperatures(rowData.original.id, rowData.original.facilityStopID);
    }, []);

    return (
        <Dialog fullWidth={true} maxWidth="md" open={true} onClose={closeModal}>
            <DialogContent>
                <>
                    <Notifications options={notification} />
                    <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
                        Upload Truck Temperatures - Truck {rowData.original.id}
                        {truckTemperatures.length ? (
                            <span>Last Recorded Temperature - {truckTemperatures[0]?.degreesFahrenheit}°F</span>
                        ) : (
                            ''
                        )}
                    </h2>
                    <FormComponent formFields={formFields} onSubmit={submitTruckTemperatures} />
                </>
            </DialogContent>
        </Dialog>
    );
};

const ViewPartialPoSkus = ({
    viewSkusModalOpen,
    setViewSkusModalOpen,
    selectedPO,
    setNotification,
    rowData,
    skusNotOnTruck = false,
}) => {
    const [tableData, setTableData] = useState([]);
    const removeDisabledMsg = 'Return to main screen to remove PO from this truck';
    const [modalMessage, setModalMessage] = useState('');

    const getPartialPODetails = async () => {
        let res;
        if (skusNotOnTruck) {
            res = await api.getPOItemsAvailableForPartialPOTrucks(rowData.original.id);
            setModalMessage('PO SKUs not on a truck');
        } else {
            res = await api.getPOItemsOnPartialPOTrucks(rowData.original.id, selectedPO, skusNotOnTruck);
            setModalMessage('PO SKUs on truck');
        }
        if (res.status === false) {
            setNotification({ text: 'No items found ' + res.msg, level: 'error' });
            return;
        }

        setTableData(res.data.rows);
    };

    useEffect(() => {
        getPartialPODetails();
    }, []);

    let colums = [
        { accessor: 'sku', Header: 'Item Master SKU' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'itemName', Header: 'Name' },
        { accessor: 'qty', Header: 'Case Quantity' },
        { accessor: 'csPallet', Header: 'Cases per Pallet' },
    ];

    if (!skusNotOnTruck) {
        colums.push({
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ row }) => {
                return (
                    <Tooltip title={tableData.length === 1 ? removeDisabledMsg : ''} placement="top" arrow>
                        <span>
                            <Button
                                id={`Remove_${row.id}`}
                                disabled={tableData.length === 1}
                                onClick={async () => {
                                    let cancelled = false;

                                    if (tableData.length === 1) {
                                        if (
                                            !window.confirm(
                                                'Removing the last item from a PO will remove the whole PO from this truck. Proceed?'
                                            )
                                        ) {
                                            cancelled = true;
                                        }
                                    }

                                    if (!cancelled) {
                                        let response = await api.removeItemFromPartialPOTruck(
                                            rowData.original.id,
                                            selectedPO,
                                            row.original.poMapID
                                        );

                                        if (response.status === false) {
                                            setNotification({
                                                text: "Can't remove item " + response.msg,
                                                level: 'error',
                                            });
                                            return;
                                        }

                                        getPartialPODetails();
                                    }
                                }}
                            >
                                Remove
                            </Button>
                        </span>
                    </Tooltip>
                );
            },
        });
    }

    return (
        <Dialog fullWidth={true} maxWidth="md" open={viewSkusModalOpen} onClose={() => setViewSkusModalOpen(false)}>
            <DialogContent>
                <div>
                    <h2>{modalMessage}</h2>
                </div>
                <div>
                    <DataTable data={tableData} columns={colums} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

let ViewInboundTrucks = () => {
    let [selectedRow, setSelectedRow] = useState({});
    let [flag, setFlag] = useState({});
    let [editDeliveryModalOpen, setEditDeliveryModalOpen] = useState(false);
    let [truckTempModalOpen, setTruckTempModalOpen] = useState(false);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [carriers, setCarriers] = useState([]);
    let [searchResults, setSearchResults] = useState([]);
    let [searchObj, setSearchObj] = useState({});
    let [typeOpts, setTypeOpts] = useState([]);
    let [additionalCostTypes, setAdditionalCostTypes] = useState([]);
    const [viewSkusModalOpen, setViewSkusModalOpen] = useState(false);
    const [selectedPO, setSelectedPO] = useState();
    const [crossDocks, setCrossDocks] = useState([]);
    const user = useContext(UserContext);

    let showTimeEditModal = (row, field) => {
        setSelectedRow(row);
        setFlag(field);
        setEditDeliveryModalOpen(true);
    };

    const getCrossDocks = async () => {
        const response = await logisticsApi.getInboundCrossDocks(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get cross docks', level: 'error' });
            setCrossDocks([]);
            return;
        }

        let crossDocksAsOpts = response.data.rows.map((item) => ({ text: item.name, value: item.id }));
        crossDocksAsOpts.unshift({ text: '', value: '' });
        setCrossDocks(crossDocksAsOpts);
    };

    let columns = [
        { accessor: 'id', Header: 'Truck ID' },
        {
            Header: 'Ship List',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`ShipList_Download_${row.id}`}
                            onClick={async () => {
                                try {
                                    await api.downloadTruckShipList(row.original.id, user.getFacilityID());
                                } catch (e) {
                                    setNotification({ text: e.toString(), level: 'error' });
                                }
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        { accessor: 'multiStopTruck', Header: 'Multi Stop Truck', type: 'checkbox', editable: false },
        { accessor: 'transfer', Header: 'Transfer Truck', type: 'checkbox', editable: false },
        { accessor: 'scheduled', Header: 'Scheduled?', type: 'checkbox', editable: false },
        { accessor: 'deliveryType', Header: 'Delivery Type' },
        { accessor: 'referenceNumber', Header: 'Release #', editable: true },
        {
            accessor: 'shipDate',
            Header: 'Ship Date',
            editable: (row) => row.original.deliveryType !== 'DELIVERED',
        },
        { accessor: 'truckCode', Header: 'Truck Code' },
        { accessor: 'shippingClass', Header: 'Shipping Class' },
        { accessor: 'poType', Header: 'PO Type' },
        {
            accessor: 'scheduledDelivery',
            Header: 'Scheduled Delivery',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'scheduledDelivery'),
        },
        {
            accessor: 'isTruckOnTime',
            Header: 'Truck On-Time for Delivery?',
            cellStyle: (row) => {
                if (row.isTruckOnTime == 'Yes') {
                    return { color: 'green' };
                }
                return { color: 'darkred' };
            },
        },
        {
            accessor: 'inboundCarrierID',
            Header: 'Carrier',
            editable: true,
            editProps: { type: 'select', options: carriers },
        },
        {
            accessor: 'freightCost',
            Header: 'Rate',
            editable: (row) =>
                row.original.deliveryType !== 'DELIVERED' ||
                (row.original.deliveryType == 'DELIVERED' && row.original.freightCost > 0),
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'additionalShipCost',
            Header: 'Additional Shipping Cost',
            editable: (row) => row.original.deliveryType !== 'DELIVERED',
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'minTemperature',
            Header: 'Min Temperature',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'maxTemperature',
            Header: 'Max Temperature',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'dockDoor',
            Header: 'Dock Door',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'numPallets',
            Header: 'Num Pallets',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'totalWeight',
            Header: 'Total Weight',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'checkIn',
            Header: 'Check In Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'checkIn'),
        },
        {
            accessor: 'checkOut',
            Header: 'Check Out Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'checkOut'),
        },
        {
            accessor: 'dockIn',
            Header: 'Dock In Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'dockIn'),
        },
        {
            accessor: 'unloadStart',
            Header: 'Unload Start Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'unloadStart'),
        },
        {
            accessor: 'unloadEnd',
            Header: 'Unload End Time',
            editable: true,
            customEdit: (row) => showTimeEditModal(row, 'unloadEnd'),
        },
        { accessor: 'hoursToUnload', Header: 'Unload Time' },
        { accessor: 'notes', Header: 'Notes', editable: true },
        { accessor: 'status', Header: 'Truck Status' },
        { accessor: 'fbmPartner', Header: 'Partner' },
        {
            accessor: 'crossDockID',
            Header: 'Cross Dock',
            editable: true,
            editProps: { type: 'select', options: crossDocks, required: false },
        },
        {
            Header: 'Cancel',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                if (row.original.status === 'CANCELLED') {
                    return null;
                }
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Cancel_${row.id}`}
                            onClick={async () => {
                                let msg = 'Cancel truck?';
                                if (row.original.multiStopTruck) {
                                    msg += ` This will cancel all stops for multi stop truck ${row.original.id}`;
                                }
                                if (window.confirm(msg)) {
                                    let response = await api.cancelTruck(row.original.id);
                                    if (response.status === false) {
                                        setNotification({
                                            text: 'Failed to cancel truck ' + response.msg,
                                            level: 'error',
                                        });
                                        return;
                                    }
                                    setNotification({ text: 'Truck cancelled', level: 'success' });
                                    searchInboundTrucks(searchObj);
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                );
            },
        },
        { accessor: 'createdAt', Header: 'Created At', type: 'date' },
        {
            Header: 'Upload Temp Summary',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`TempSummary_Upload_${row.id}`}
                            onClick={() => {
                                setSelectedRow(row);
                                setTruckTempModalOpen(true);
                            }}
                        >
                            Upload
                        </Button>
                    </div>
                );
            },
        },
        {
            Header: 'Download Temp Summary',
            Cell: ({ row }) => {
                if (!row.original.tempSummaryPdfUrl) {
                    return null;
                }
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`TempSummary_Download_${row.id}`}
                            onClick={() => {
                                api.downloadTruckTempSummary(row.original.id, row.original.facilityStopID);
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
    ];

    // Load
    let searchInboundTrucks = async ({ searchBy, searchTerm }) => {
        let search = {};

        if (!!searchBy && !!searchTerm) {
            search[searchBy] = searchTerm;
        }

        setSearchObj(search);

        let response = await api.getTrucks(user.getFacilityID(), search);

        if (response.status === false) {
            setNotification({ text: 'No trucks found ' + response.msg, level: 'error' });
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.scheduled = !row.unscheduled;
                row.scheduledDelivery = !!row.scheduledDeliveryTime
                    ? moment(row.scheduledDeliveryTime.date).format('MM/DD/YYYY HH:mm:ss')
                    : null;
                row.checkIn = !!row.checkInTime ? moment(row.checkInTime.date).format('MM/DD/YYYY HH:mm:ss') : null;
                row.checkOut = !!row.checkOutTime ? moment(row.checkOutTime.date).format('MM/DD/YYYY HH:mm:ss') : null;
                row.dockIn = !!row.dockInTime ? moment(row.dockInTime.date).format('MM/DD/YYYY HH:mm:ss') : null;
                row.unloadStart = !!row.unloadStartTime
                    ? moment(row.unloadStartTime.date).format('MM/DD/YYYY HH:mm:ss')
                    : null;
                row.unloadEnd = !!row.unloadEndTime
                    ? moment(row.unloadEndTime.date).format('MM/DD/YYYY HH:mm:ss')
                    : null;

                const twoHoursInMs = 2 * (60 * 60 * 1000);
                const scheduledDeliveryTimeAsDate =
                    !!row.scheduledDeliveryTime && row.scheduledDeliveryTime.date
                        ? new Date(row.scheduledDeliveryTime.date)
                        : new Date();
                const timeDifference = new Date() - scheduledDeliveryTimeAsDate;
                row.isTruckOnTime =
                    !!row.checkInTime || timeDifference < twoHoursInMs || timeDifference < 0 ? 'Yes' : 'No';

                return row;
            });
        }

        setSearchResults(response.data.rows);
    };

    let getOptionsForInboundTrucks = async () => {
        let res = await api.getInboundOptions();
        if (!res.status) {
            return;
        }
        setCarriers(res.data.carriers.map((item) => ({ text: item.name, value: item.id })));
        setTypeOpts(res.data.types);
        setAdditionalCostTypes(res.data.additionalCostTypes);
    };

    useEffect(() => {
        searchInboundTrucks({});
        getOptionsForInboundTrucks();
        getCrossDocks();
    }, []);

    let searchByOptions = [
        { value: 'poID', text: 'Purchase Order ID' },
        { value: 'packagingPoID', text: 'Packaging Purchase Order ID' },
    ];

    return (
        <div>
            <h1>Inbound Trucks</h1>
            <Notifications options={notification} />

            <SearchBar onSubmit={searchInboundTrucks} searchByOptions={searchByOptions} />
            <div>
                {editDeliveryModalOpen && (
                    <EditDateModal
                        rowData={selectedRow}
                        flag={flag}
                        closeModal={() => {
                            setEditDeliveryModalOpen(false);
                            searchInboundTrucks(searchObj);
                        }}
                    />
                )}
                {truckTempModalOpen && (
                    <TruckTemperaturesModal
                        rowData={selectedRow}
                        closeModal={() => {
                            setTruckTempModalOpen(false);
                            setSelectedRow({});
                            searchInboundTrucks(searchObj);
                        }}
                    />
                )}
                {viewSkusModalOpen && (
                    <ViewPartialPoSkus
                        viewSkusModalOpen={viewSkusModalOpen}
                        setViewSkusModalOpen={setViewSkusModalOpen}
                        selectedPO={selectedPO}
                        rowData={selectedRow}
                    />
                )}
                <DataTable
                    columns={columns}
                    data={searchResults}
                    expandable={true}
                    csvExport={true}
                    ExpansionComponent={
                        <ViewItemsTable
                            setSelectedRow={setSelectedRow}
                            setSelectedPO={setSelectedPO}
                            setViewSkusModalOpen={setViewSkusModalOpen}
                        />
                    }
                    editable={true}
                    rowStyle={(row) => {
                        if (row.status === 'CANCELLED') {
                            return { backgroundColor: 'lightgray' };
                        }
                        return {};
                    }}
                    saveEdit={async ({ id, field, value, row }) => {
                        let item = {};
                        item[field] = value;

                        const facilityStopFields = ['dockDoor', 'notes', 'totalWeight', 'numPallets'];
                        let response;
                        if (facilityStopFields.includes(field)) {
                            response = await api.editTruckFacilityStopDetails(row.facilityStopID, item);
                        } else {
                            response = await api.editTruckDetails(id, item);
                        }
                        if (response.status === false) {
                            let message = 'Error modifying truck';
                            if (response.msg) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                            return false;
                        } else {
                            setNotification({ text: 'Truck modified!', level: 'success' });
                            searchInboundTrucks({});
                            return true;
                        }
                    }}
                />
            </div>
        </div>
    );
};

const POsNotOnTrucks = () => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [viewSkusModalOpen, setViewSkusModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const user = useContext(UserContext);

    const getPOsNotOnTruck = async () => {
        const response = await api.getPOsNotOnTruck(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No trucks found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        setRows(
            response.data.rows.map((row) => {
                row.scheduledDeliveryDate = moment(row.scheduledDeliveryDate.date).format('MM/DD/YYYY');
                row.pickupDate = moment(row.pickupDate.date).format('MM/DD/YYYY');
                if (row.buyers && Array.isArray(row.buyers)) {
                    row.buyers = row.buyers.map((item) => item.name).join(', ');
                }
                return row;
            })
        );
    };

    useEffect(() => {
        getPOsNotOnTruck();
    }, []);

    const columns = [
        { Header: 'PO ID', accessor: 'id' },
        { Header: 'PO Type', accessor: 'poType' },
        { Header: 'Buyers', accessor: 'buyers' },
        { Header: 'Pick Up Date', accessor: 'pickupDate' },
        { Header: 'Scheduled Delivery', accessor: 'scheduledDeliveryDate' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Delivery Type', accessor: 'deliveryType' },
        { Header: 'Truck Type', accessor: 'fobPoType' },
        { Header: 'Num SKUs', accessor: 'numSkus' },
        { Header: 'Pickup Number', accesor: 'pickupNumber' },
        { Header: 'Shipper', accessor: 'shipperName' },
        { Header: 'Partner', accessor: 'partnerName' },
        {
            Header: 'SKUs Remaining',
            Cell: ({ row }) => {
                if (row.original.fobPoType === 'partial') {
                    return (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Button
                                id={`View_SKUs_${row.id}`}
                                onClick={() => {
                                    setSelectedRow(row);
                                    setViewSkusModalOpen(true);
                                }}
                            >
                                View SKUs
                            </Button>
                        </div>
                    );
                } else {
                    return <></>;
                }
            },
        },
    ];

    return (
        <div>
            {viewSkusModalOpen && (
                <ViewPartialPoSkus
                    viewSkusModalOpen={viewSkusModalOpen}
                    setViewSkusModalOpen={setViewSkusModalOpen}
                    setNotification={setNotification}
                    rowData={selectedRow}
                    skusNotOnTruck={true}
                />
            )}
            <Notifications options={notification} />
            <DataTable columns={columns} data={rows} />
        </div>
    );
};

export { ViewInboundTrucks, POsNotOnTrucks };
