import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { DataTable, Notifications } from '../../../shared';
import { customerApi as api } from '../../../api/customer';
import moment from 'moment';

const ZipCodeModal = ({ modalOpen, setModalOpen, zipCodes, setZipCodes, lineHaulIDs, setLineHaulIDs }) => {
    return (
        <Dialog
            open={modalOpen}
            onClose={() => {
                setModalOpen(false);
                setLineHaulIDs([]);
                setZipCodes([]);
            }}
            fullWidth={true}
            maxWidth="sm"
            onClick={(e) => e.stopPropagation()}
        >
            {zipCodes.length > 0 && <DialogTitle>Zip Codes</DialogTitle>}
            {lineHaulIDs.length > 0 && <DialogTitle>Line Haul IDs</DialogTitle>}
            <DialogContent>
                <ul>
                    {zipCodes.length > 0 && zipCodes.map((zip, i) => <li key={i}>{zip}</li>)}
                    {lineHaulIDs.length > 0 && lineHaulIDs.map((lineHaulID, i) => <li key={i}>{lineHaulID}</li>)}
                </ul>
            </DialogContent>
        </Dialog>
    );
};

const ViewServiceDelayBanners = () => {
    const [rows, setRows] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [zipCodes, setZipCodes] = useState([]);
    const [lineHaulIDs, setLineHaulIDs] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    const getBanners = async () => {
        let res = await api.getDelayBanners();
        if (!res.status) {
            setNotification({ text: 'Failed to get delay banner. ' + res.msg, level: 'error' });
            return;
        }

        setRows(
            res.data.banners.map((item) => {
                item.startDate = moment(item.startDate.date).format('MM/DD/YYYY');
                item.endDate = moment(item.endDate.date).format('MM/DD/YYYY');
                item.subscribedBeforeDate = item.subscribedBeforeDate
                    ? moment(item.subscribedBeforeDate.date).format('MM/DD/YYYY')
                    : null;
                return item;
            })
        );
    };

    useEffect(() => {
        getBanners();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'bannerName', Header: 'Banner Name', editable: true },
        { accessor: 'active', Header: 'Active', editable: true, type: 'checkbox' },
        {
            accessor: 'startDate',
            Header: 'Start Date',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'date',
                required: true,
            },
        },
        {
            accessor: 'endDate',
            Header: 'End Date',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'date',
                required: true,
            },
        },
        {
            accessor: 'copy',
            Header: 'Banner Copy',
            cellStyle: () => ({ minWidth: 136 }),
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        {
            accessor: 'routes',
            Header: 'Routes',
            cellStyle: () => ({ renderArrayAsList: true, minWidth: 136 }),
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        {
            accessor: 'subscribedBeforeDate',
            Header: 'Subscribed Before Date',
            editable: true,
            editProps: { type: 'input', inputType: 'date' },
        },
        {
            accessor: 'zipCodes',
            Header: 'Zip Codes',
            Cell: ({ row }) => {
                return (
                    <Button
                        disabled={row.original.zipCodes.length === 0}
                        onClick={async () => {
                            setModalOpen(true);
                            setZipCodes(row.original.zipCodes);
                            // setLineHaulIDs([]);
                        }}
                    >
                        View
                    </Button>
                );
            },
        },
        {
            accessor: 'lineHaulIDs',
            Header: 'Line Haul IDs',
            Cell: ({ row }) => {
                return (
                    <Button
                        disabled={row.original.lineHaulIDs.length === 0}
                        onClick={async () => {
                            setModalOpen(true);
                            setLineHaulIDs(row.original.lineHaulIDs);
                            // setZipCodes([]);
                        }}
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            {modalOpen && (
                <ZipCodeModal
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    zipCodes={zipCodes}
                    setZipCodes={setZipCodes}
                    lineHaulIDs={lineHaulIDs}
                    setLineHaulIDs={setLineHaulIDs}
                />
            )}
            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={async ({ id, field, value }) => {
                    let sendParams = {};
                    sendParams.id = id;

                    if (field === 'routes') {
                        sendParams[field] = value.split(/,/).map((route) => route.trim());
                    } else {
                        sendParams[field] = value;
                    }

                    let res = await api.updateDelayBanners(sendParams);
                    if (!res.status) {
                        setNotification({ level: 'error', text: `Failed to edit aisle banner: ${res.msg}` });
                        return false;
                    }
                    getBanners();
                    return true;
                }}
            />
        </>
    );
};

export { ViewServiceDelayBanners };
