import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DataTable, FormComponent, Notifications } from '../../../shared';
import { marketingApi as api } from '../../../api/marketing';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';

let ViewDiscountModal = ({ closeModal, discountID, open, discountData, setNotification }) => {
    let headerList = null;
    let extraForms = null;
    if (discountData !== null) {
        let foundCustomerListType = false;

        headerList = Object.keys(discountData).map((index) => {
            if (index === 'CUSTOMER_LIST') {
                foundCustomerListType = true;
            }

            let subList = Object.keys(discountData[index]).map((subKey) => {
                if (
                    typeof discountData[index][subKey] === 'object' &&
                    discountData[index][subKey] !== null &&
                    'date' in discountData[index][subKey]
                ) {
                    let formattedDate = moment(discountData[index][subKey]['date']).format('MM/DD/YYYY');

                    return (
                        <li>
                            <b>{subKey}:</b> {formattedDate}
                        </li>
                    );
                } else {
                    return (
                        <li>
                            <b>{subKey}:</b> {discountData[index][subKey]}
                        </li>
                    );
                }
            });

            return (
                <div>
                    <h1>{index}</h1>
                    <ul>{subList}</ul>
                </div>
            );
        });

        if (foundCustomerListType === true) {
            let formFields = [
                {
                    name: 'customerIDList',
                    inputElement: 'file',
                    accept: ['.csv'],
                    label: 'Choose Customer ID CSV',
                    inputProps: { required: true },
                    gridValue: 2,
                },
            ];

            extraForms = (
                <div>
                    <h1>Append Additional Customer IDs To Claiming List</h1>
                    <FormComponent
                        formFields={formFields}
                        onSubmit={async (formData, resetForm) => {
                            let response = await api.uploadCustomerIdCsvFileForDiscountCode(
                                formData.customerIDList,
                                discountID
                            );
                            if (response.status === false) {
                                setNotification({
                                    text: 'Unable to append customer ids: ' + response.msg,
                                    level: 'error',
                                });
                                return;
                            }

                            setNotification({ text: 'Success!', level: 'success' });
                            resetForm();
                        }}
                    />
                    <h1>Download Targeted Customers</h1>
                    <Button
                        onClick={() => {
                            api.downloadCustomerIdCsvFileForDiscountCode(discountID);
                        }}
                    >
                        Download CSV
                    </Button>
                </div>
            );
        }
    }

    return (
        <Dialog open={open} onClose={closeModal}>
            <DialogTitle>Discount {discountID}</DialogTitle>
            <DialogContent>
                {headerList}
                {extraForms}
            </DialogContent>
        </Dialog>
    );
};

let DeactivateDiscountModal = ({ closeModal, discountID, open, disableDiscount }) => {
    let formFields = [];
    return (
        <Dialog open={open} onClose={closeModal}>
            <DialogTitle>
                Are you sure you want to de-activate discount <b>{discountID}</b>?
            </DialogTitle>
            <DialogContent>
                <FormComponent
                    formFields={formFields}
                    button={{ text: 'Yes' }}
                    onSubmit={(formData) => {
                        disableDiscount(discountID);
                        closeModal();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

const ViewV2Discounts = ({ canEditDiscounts }) => {
    const [rows, setRows] = useState([]);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [discountData, setDiscountData] = useState(null);
    const [viewDiscountModalOpen, setViewDiscountModalOpen] = useState(false);
    const [deactivateDiscountModalOpen, setDeactivateDiscountModalOpen] = useState(false);
    const [notification, setNotification] = useState({ text: null, level: null });

    const baseColumns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'code', Header: 'Code' },
        { accessor: 'claimType', Header: 'Claim Type' },
        { accessor: 'applyType', Header: 'Apply Type' },
        { accessor: 'awardType', Header: 'Award Type' },
        { accessor: 'startsOn', Header: 'Starts On' },
        {
            accessor: 'nanobarText',
            Header: 'Nanobar Text',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'text',
                required: true,
                multiline: true,
            },
        },
        {
            accessor: 'nanobarTerms',
            Header: 'Nanobar Terms',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'text',
                required: true,
                multiline: true,
            },
        },
        {
            accessor: 'claimBy',
            Header: 'Claim By',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'date',
                required: true,
            },
        },
        {
            accessor: 'useBy',
            Header: 'Use By',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'date',
                required: true,
            },
        },
        {
            Header: 'View',
            Cell: ({ row }) => {
                return (
                    <div
                        id={`View_${row.id}`}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => {
                            setSelectedDiscount(row.original);
                            getDiscount(row.original.id);
                            setViewDiscountModalOpen(true);
                        }}
                    >
                        View
                    </div>
                );
            },
        },
    ];

    const columns = [
        ...baseColumns,
        ...(canEditDiscounts
            ? [
                  {
                      Header: 'De-activate',
                      Cell: ({ row }) => {
                          return (
                              <div
                                  id={`DeActivate_${row.id}`}
                                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                  onClick={() => {
                                      setSelectedDiscount(row.original);
                                      setDeactivateDiscountModalOpen(true);
                                  }}
                              >
                                  De-activate
                              </div>
                          );
                      },
                  },
              ]
            : []),
    ];

    let getDiscount = async (discountID) => {
        let response = await api.getDiscount(discountID);
        if (response.status === false) {
            setNotification({
                text: 'No discount found for discount ' + discountID + ': ' + response.msg,
                level: 'error',
            });
            setDiscountData(null);
            return;
        }

        setDiscountData(response.data);
    };

    let getDiscounts = async () => {
        let response = await api.getDiscounts();
        if (response.status === false) {
            setNotification({ text: 'No discounts found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                if (row.startsOn == null) {
                    row.startsOn = '';
                } else {
                    row.startsOn = moment(row.startsOn.date).format('MM/DD/YYYY');
                }
                if (row.claimBy == null) {
                    row.claimBy = '';
                } else {
                    row.claimBy = moment(row.claimBy.date).format('MM/DD/YYYY');
                }
                if (row.useBy == null) {
                    row.useBy = '';
                } else {
                    row.useBy = moment(row.useBy.date).format('MM/DD/YYYY');
                }

                return row;
            });
        }

        setRows(response.data.rows);
    };

    let disableDiscount = async (discountID) => {
        let response = await api.disableDiscount(discountID);
        if (response.status === false) {
            setNotification({ text: 'Error disabling discount ' + response.msg, level: 'error' });

            return;
        }

        setNotification({ text: 'Successfully disabled discount', level: 'success' });

        getDiscounts();
    };

    useEffect(() => {
        getDiscounts();
    }, []);

    return (
        <div>
            <h1>Discounts</h1>
            <Notifications options={notification} />
            {selectedDiscount && viewDiscountModalOpen && (
                <ViewDiscountModal
                    open={viewDiscountModalOpen}
                    closeModal={() => {
                        setViewDiscountModalOpen(false);
                    }}
                    discountID={selectedDiscount.id}
                    discountData={discountData}
                    setNotification={setNotification}
                />
            )}
            {selectedDiscount && deactivateDiscountModalOpen && (
                <DeactivateDiscountModal
                    open={deactivateDiscountModalOpen}
                    closeModal={() => {
                        setDeactivateDiscountModalOpen(false);
                    }}
                    discountID={selectedDiscount.id}
                    disableDiscount={(tempDiscountID) => disableDiscount(tempDiscountID)}
                />
            )}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={canEditDiscounts}
                    saveEdit={async ({ id, field, value }) => {
                        let claimByValue = '';
                        if (field == 'claimBy') {
                            claimByValue = value;
                        }
                        let useByValue = '';
                        if (field == 'useBy') {
                            useByValue = value;
                        }
                        let nanobarTextValue = '';
                        if (field == 'nanobarText') {
                            nanobarTextValue = value;
                        }
                        let nanobarTermsValue = '';
                        if (field == 'nanobarTerms') {
                            nanobarTermsValue = value;
                        }
                        let response = await api.updateV2Discount(
                            id,
                            claimByValue,
                            useByValue,
                            nanobarTextValue,
                            nanobarTermsValue
                        );
                        if (response.status === false) {
                            setNotification({ text: 'Unable to update terms ' + response.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Terms updated', level: 'success' });
                        getDiscounts();
                    }}
                />
            </div>
        </div>
    );
};

export { ViewV2Discounts };
