import React, { useEffect } from 'react';

const Login = () => {
    useEffect(() => {
        let url =
            'https://auth.misfitsmarket.com/login?response_type=code&client_id=1sb044rkknbjep7p1f3b3nn0gh&redirect_uri=https://jalapeno.misfitsmarket.com/login/validateAuthCode.php';
        window.location.assign(url);
    });

    return <div></div>;
};

export { Login };
