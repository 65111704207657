import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { warehouseApi } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { productionApi } from '../../../api/production';
import { ImportLoadsheetDialog } from './importLoadsheetDialog';

const EditSkuModal = ({ setNotification, setShowEditModal, availableSkus, getData, row, packGroupId }) => {
    const [warningMsg, setWarningMsg] = useState('');

    let formFields = [
        {
            name: 'externalSku',
            inputElement: 'autocomplete',
            label: 'SKU',
            gridValue: 12,
            inputProps: {
                opts: availableSkus,
                onChange: (e, val) => {
                    setWarningMsg(`Moving this sku into ${row.lineSlot} will remove it from it's current slot.`);
                },
            },
        },
    ];

    return (
        <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={() => setShowEditModal(false)}>
            <DialogTitle>
                Edit SKU For Line Slot {row.lineSlot}
                <br />
                Leave blank to remove SKU from this Line Slot
            </DialogTitle>
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                {warningMsg}
            </div>
            <DialogContent>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        formData.mlpSlotID = row.mlpSlotID;
                        formData.packLineID = row.packLineID;
                        formData.packGroupID = packGroupId;

                        if (formData.externalSku === '') {
                            let res = await warehouseApi.deleteLoadSheet(row.id);
                            if (res.status === false) {
                                setNotification({ text: 'Failed to delete loadsheet ' + res.msg, level: 'error' });
                                return;
                            }
                        } else {
                            let res = await warehouseApi.updateLoadsheet(formData);
                            if (res.status === false) {
                                setNotification({ text: 'Failed to update loadsheet ' + res.msg, level: 'error' });
                                return;
                            }
                        }

                        getData();
                        setShowEditModal(false);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let Slotting = ({}) => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [week, setWeek] = useState('');
    let [year, setYear] = useState('');
    let [packGroupId, setPackGroupId] = useState();
    let [lastUploadedAt, setLastUploadedAt] = useState('');
    let [lastUploadedBy, setLastUploadedBy] = useState('');
    let [slotMap, setSlotMap] = useState([]);
    const [featureFlagEnabled, setFeatureFlagEnabled] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [row, setRow] = useState();
    const [availableSkus, setAvailableSkus] = useState([]);
    const [uploadLoadsheetModalOpen, setUploadLoadsheetModalOpen] = useState(false);
    const [sellableItemWarning, setSellableItemWarning] = useState(false);
    const user = useContext(UserContext);

    let getData = async (newWeek, newYear) => {
        let res = await warehouseApi.getLoadsheetByWeekYear(newWeek || week, newYear || year, user.getFacilityID());
        if (res.status == false) {
            setNotification({ text: 'Failed to get loadsheet ' + res.msg, level: 'error' });
            return;
        }

        const slotMap = res.data.loadsheet;
        setSlotMap(slotMap);
        setFeatureFlagEnabled(res.data.featureFlag);

        let filteredValues = slotMap.filter(item => item.id > 0);

        if(filteredValues.length > 0) {
            //check if blank sellable item IDs
            if(filteredValues.filter(item => item.sellableItemID === 0).length > 0) {
                setSellableItemWarning(true);
            }
        } else {
            setSellableItemWarning(false);
        }

        setWeek(res.data.week);
        setYear(res.data.year);
        setPackGroupId(res.data.packGroupId);
        setLastUploadedBy(slotMap.length > 0 ? slotMap[0].uploadedBy : '');
        const uploadedAt = slotMap.length > 0 ? slotMap[0].uploadedAt : null;
        if (uploadedAt) {
            const formattedUpload = new Date(uploadedAt).toLocaleString();
            setLastUploadedAt(formattedUpload);
        }
    };

    let getSellableItems = async () => {
        if (packGroupId !== undefined) {
            let res = await productionApi.getSellableItems(packGroupId);

            if (res.status === false) {
                setNotification({ text: 'Failed to get sellable items' + res.msg, level: 'error' });
                return;
            }

            let featureFlag = res.data.featureFlag;
            setFeatureFlagEnabled(featureFlag)

            setAvailableSkus(
                res.data.sellableItems.map((item) => ({
                    text: `SKU: ${user.getFacilityName().match(/^[A-Z]+/g)[0]}-${featureFlag ? item.externalSku : item.ifSku} - Item Name: ${item.name}`,
                    value: item.externalSku,
                }))
            );
        }
    };

    const onSuccess = () => {
        setUploadLoadsheetModalOpen(false);
        setNotification({ text: 'Upload successful!', level: 'success', autoHide: true });
        getData(week, year);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (packGroupId !== 0) {
            getSellableItems();
        }
    }, [packGroupId]);

    const handleEditModal = (row) => {
        setRow(row);
        setShowEditModal(true);
    };

    let formFields = [
        {
            name: 'week',
            initialValue: week,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { 
                required: true, 
                type: 'number', 
                max: '53', 
                onChange: e => setWeek(e.target.value)
            },
            value: week
        },
        {
            name: 'year',
            initialValue: year,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { 
                required: true, 
                type: 'number', 
                maxLength: '4',
                onChange: e => setYear(e.target.value)
            },
            value: year
        },
    ];

    let columns = [
        { accessor: 'lineSlot', Header: 'Location' },
        { accessor: featureFlagEnabled ? 'mmSku' : 'ifSku', Header: 'SKU', editable: true, customEdit: (row) => handleEditModal(row) },
        { accessor: 'itemName', Header: 'Product Name' },
    ];

    let toolbarActions = [
        {
            name: 'Upload to Lightning Pick',
            color: 'primary',
            action: async () => {
                if (!packGroupId) {
                    setNotification({
                        level: 'error',
                        text: `Unable to find pack group`,
                    });
                    return;
                }

                let res = await warehouseApi.uploadLoadsheet(packGroupId);
                console.log(res)
                if (!res.status) {
                    setNotification({
                        level: 'error',
                        text: `Failed to upload load sheet to LP: ${res.msg}`,
                    });
                } else {
                    setNotification({
                        level: 'success',
                        text: 'Data successfully uploaded to Lightning Pick!',
                    });
                    getData(week, year);
                }
            },
        },
    ];

    return (
        <div>
            {showEditModal && (
                <EditSkuModal
                    setNotification={setNotification}
                    setShowEditModal={setShowEditModal}
                    availableSkus={availableSkus}
                    slotMap={slotMap}
                    getData={getData}
                    row={row}
                    packGroupId={packGroupId}
                />
            )}
            <h3>
                {user.getFacilityName()} Slotting {week ? `- Week ${week} ${year}` : ''}
            </h3>
            <Notifications options={notification} />
            <FormComponent
                formFields={formFields}
                button={{ gridValue: 2, text: 'Set Week/Year' }}
                onSubmit={async (formData) => {
                    getData(formData.week, formData.year);
                }}
            />
            {lastUploadedBy && (
                <p style={{ textAlign: 'right', marginRight: '20px' }}>
                    <b>Last successfully updated by:</b> {lastUploadedBy}{' '}
                </p>
            )}
            {lastUploadedAt && (
                <p style={{ textAlign: 'right', marginRight: '20px' }}>
                    <b>Last successfully updated at:</b> {lastUploadedAt}{' '}
                </p>
            )}
            <Button
                color="primary"
                variant="contained"
                disabled={!packGroupId}
                style={{ margin: '15px 0', float: 'left' }}
                onClick={() => setUploadLoadsheetModalOpen(true)}
            >
                Import CSV to Jalapeno
            </Button>
            {
                sellableItemWarning && 
                <h5 style={{ float: 'left', padding: '5px 15px' }}>
                    The pack group's slotting contains items that are not in Sellable Items
                </h5>
            }
            <DataTable
                columns={columns}
                data={slotMap}
                editable={true}
                csvExport={true}
                toolbarActions={toolbarActions}
                customExport={() => warehouseApi.downloadLoadsheet(week, year, user.getFacilityID())}
            />
            <ImportLoadsheetDialog
                packGroup={packGroupId}
                open={uploadLoadsheetModalOpen}
                onSuccess={onSuccess}
                setNotification={setNotification}
                onClose={() => setUploadLoadsheetModalOpen(false)}
            />
        </div>
    );
};

export { Slotting };
