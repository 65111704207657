import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { systemApi as api } from '../../../api/system';
import { productionApi as productionApi } from '../../../api/production';
import { Modal, Button, Select, NativeSelect } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';

let MarketplaceInventory = () => {
    let [packGroupOpt, setPackGroupOpt] = useState([]);
    let [selectedPackGroup, setSelectedPackGroup] = useState('');
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const { user } = useContext(UserContext);

    let getPackGroups = async () => {
        let response = await productionApi.getPackGroupsRecent(user.facilityID, 'true');
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setPackGroupOpt(response.data.rows);

        if (response.data.rows.length > 0) {
            setSelectedPackGroup(response.data.rows[0].id);
            getRows(response.data.rows[0].id);
        }
    };

    // Load table
    let getRows = async (packGroupID) => {
        let response = await api.getMarketplaceInventory(packGroupID);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getPackGroups();
    }, []);

    let columns = [
        { accessor: 'name', Header: 'Name' },
        { accessor: 'id', Header: 'Sellable Item ID' },
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'ifVariantID', Header: 'IF Variant ID' },
        { accessor: 'amountLeft', Header: 'Starting Amount' },
        { accessor: 'amountOrderedRedis', Header: 'Amount Ordered (MM)' },
        { accessor: 'amountOrderedIF', Header: 'Amount Ordered (IF)' },
        { accessor: 'amountOrderedReIF', Header: 'Amount Ordered (Combined)' },
        { accessor: 'soldOut', Header: 'Sold Out', type: 'checkbox' },
    ];

    let rowStyle = (row) => {
        if (Number(row.amountOrderedRedis) !== Number(row.amountOrderedCss)) {
            return {
                backgroundColor: '#ffdcdc',
            };
        }
        return {};
    };

    return (
        <div>
            <h1>Marketplace Inventory</h1>
            <Notifications options={notification} />
            {}
            <div>
                <div style={{ marginBottom: '40px' }}>
                    <NativeSelect
                        className="input-element-select"
                        value={selectedPackGroup}
                        label="Pack Group Select"
                        onChange={(event) => {
                            getRows(event.target.value);
                            setSelectedPackGroup(event.target.value);
                        }}
                    >
                        {packGroupOpt.map((opt) => {
                            return (
                                <option key={opt.id} value={opt.id}>
                                    {opt.name + ' ' + opt.week}
                                </option>
                            );
                        })}
                        ;
                    </NativeSelect>
                    <Button
                        onClick={() => {
                            getRows(selectedPackGroup);
                        }}
                    >
                        Refresh Data
                    </Button>
                </div>

                <DataTable columns={columns} data={rows} rowStyle={rowStyle} csvExport={true} />
            </div>
        </div>
    );
};

export { MarketplaceInventory };
