import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { DataTable, Notifications } from '../../../shared';
import { giftCodeApi as api } from '../../../api/giftCodes';

let ViewSystemGiftCodes = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'numCodes', Header: 'Num Codes' },
        { accessor: 'amount', Header: 'Amount' },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Download_${row.id}`}
                            onClick={() => {
                                api.downloadCodes(row.original.id);
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getSystemBatches = async () => {
        let response = await api.getSystemCodeBatches();
        if (response.status === false) {
            setNotification({ text: 'No system batches found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getSystemBatches();
    }, []);

    return (
        <div>
            <h1>System Gift Code Batches</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable columns={columns} data={rows} />
            </div>
        </div>
    );
};

export { ViewSystemGiftCodes };
