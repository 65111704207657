import React, { useState, useEffect, useContext } from 'react';
import { NativeSelect, FormControlLabel, Button } from '@material-ui/core';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { shippingApi as api } from '../../../api/shipping';
import { packagingApi as packagingApi } from '../../../api/packaging';
import { warehouseApi as warehouseApi } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';

let TestLabel = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [facilities, setFacilities] = useState([]);

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (response.status === false) {
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setFacilities(facilitiesAsOpts);
    };

    useEffect(() => {
        getFacilities();
    }, []);

    let formFields = [
        {
            name: 'carrier',
            label: 'Carrier',
            inputElement: 'select',
            gridValue: 6,
            inputProps: {
                required: true,
                opts: [
                    { value: 'lasership', text: 'Lasership' },
                    { value: 'axlehirev3', text: 'Axlehire' },
                    { value: 'fedex', text: 'Fedex' },
                ],
            },
        },
        {
            name: 'facilityID',
            label: 'Facility',
            inputElement: 'select',
            gridValue: 6,
            inputProps: {
                required: true,
                opts: facilities,
            },
        },
        { name: 'firstName', label: 'First Name', gridValue: 6, inputProps: { required: true } },
        { name: 'lastName', label: 'Last Name', gridValue: 6, inputProps: { required: true } },
        { name: 'address1', label: 'Address', inputProps: { required: true } },
        { name: 'address2', label: 'Address 2' },
        { name: 'city', label: 'City', inputProps: { required: true } },
        {
            name: 'province',
            inputElement: 'select',
            gridValue: 6,
            label: 'State',
            inputProps: {
                required: true,
                opts: [
                    { value: 'AL', text: 'Alabama' },
                    { value: 'AK', text: 'Alaska' },
                    { value: 'AZ', text: 'Arizona' },
                    { value: 'AR', text: 'Arkansas' },
                    { value: 'CA', text: 'California' },
                    { value: 'CO', text: 'Colorado' },
                    { value: 'CT', text: 'Connecticut' },
                    { value: 'DE', text: 'Delaware' },
                    { value: 'DC', text: 'District of Columbia' },
                    { value: 'FL', text: 'Florida' },
                    { value: 'GA', text: 'Georgia' },
                    { value: 'HI', text: 'Hawaii' },
                    { value: 'ID', text: 'Idaho' },
                    { value: 'IL', text: 'Illinois' },
                    { value: 'IN', text: 'Indiana' },
                    { value: 'IA', text: 'Iowa' },
                    { value: 'KS', text: 'Kansas' },
                    { value: 'KY', text: 'Kentucky' },
                    { value: 'LA', text: 'Louisiana' },
                    { value: 'ME', text: 'Maine' },
                    { value: 'MD', text: 'Maryland' },
                    { value: 'MA', text: 'Massachusetts' },
                    { value: 'MI', text: 'Michigan' },
                    { value: 'MN', text: 'Minnesota' },
                    { value: 'MS', text: 'Mississippi' },
                    { value: 'MO', text: 'Missouri' },
                    { value: 'MT', text: 'Montana' },
                    { value: 'NE', text: 'Nebraska' },
                    { value: 'NV', text: 'Nevada' },
                    { value: 'NH', text: 'New Hampshire' },
                    { value: 'NJ', text: 'New Jersey' },
                    { value: 'NM', text: 'New Mexico' },
                    { value: 'NY', text: 'New York' },
                    { value: 'NC', text: 'North Carolina' },
                    { value: 'ND', text: 'North Dakota' },
                    { value: 'OH', text: 'Ohio' },
                    { value: 'OK', text: 'Oklahoma' },
                    { value: 'OR', text: 'Oregon' },
                    { value: 'PA', text: 'Pennsylvania' },
                    { value: 'RI', text: 'Rhode Island' },
                    { value: 'SC', text: 'South Carolina' },
                    { value: 'SD', text: 'South Dakota' },
                    { value: 'TN', text: 'Tennessee' },
                    { value: 'TX', text: 'Texas' },
                    { value: 'UT', text: 'Utah' },
                    { value: 'VT', text: 'Vermont' },
                    { value: 'VA', text: 'Virginia' },
                    { value: 'WA', text: 'Washington' },
                    { value: 'WV', text: 'West Virginia' },
                    { value: 'WI', text: 'Wisconsin' },
                    { value: 'WY', text: 'Wyoming' },
                ],
            },
        },
        {
            name: 'country',
            gridValue: 3,
            label: 'Country',
            initialValue: 'United States',
            inputProps: { readOnly: true },
        },
        { name: 'zip', gridValue: 3, label: 'Zip', inputProps: { required: true } },
        { name: 'phone', gridValue: 12, label: 'Phone' },
    ];

    return (
        <div style={{ height: '100%' }}>
            <Notifications options={notification} />
            <h3>Generate test shipping label</h3>
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    let res = await api.purchaseTestLabel(formData);
                }}
            />
        </div>
    );
};

let AdditionalBox = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [boxOpts, setBoxOpts] = useState([]);
    let [rows, setRows] = useState([]);
    const user = useContext(UserContext);

    let formFields = [
        {
            name: 'shipmentID',
            label: 'Shipment ID',
            inputElement: 'textField',
            gridValue: 8,
            inputProps: { required: true },
        },
        { name: 'cubeLevelID', inputElement: 'select', label: 'Box Size', gridValue: 4, inputProps: { opts: boxOpts } },
    ];

    let getBoxes = async () => {
        let response = await packagingApi.getBoxes();
        if (response.status === false) {
            setNotification({ text: 'No Boxes found ' + response.msg, level: 'error' });
            setBoxOpts([]);
            return;
        }

        let boxOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setBoxOpts(boxOpts);
    };

    let getRows = async () => {
        let response = await api.getSpawnedShipments(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No spawned shipments found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getBoxes();
        getRows();
    }, []);

    let columns = [
        { accessor: 'timestamp', Header: 'Spawned at' },
        { accessor: 'id', Header: 'ID' },
        { accessor: 'shopOrderNum', Header: 'Order Number' },
        { accessor: 'shipmentID', Header: 'Shipment ID' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'boxSize', Header: 'Box Size' },
        { accessor: 'userName', Header: 'User' },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`DownloadLPN_${row.id}`}
                        onClick={async () => {
                            api.downloadLPN(row.original.shipmentID);
                        }}
                    >
                        Download LPN
                    </Button>
                );
            },
        },
        {
            accessor: 'cancel',
            Header: 'Cancel',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Cancel_${row.id}`}
                        onClick={async () => {
                            let res = await api.cancelAddlLabel(row.original.shipmentID);

                            if (!res.status) {
                                setNotification({ text: 'Failed to cancel ' + res.msg, level: 'error' });
                                return;
                            }

                            setNotification({ text: 'Shipment canceled', level: 'success' });
                        }}
                    >
                        Cancel
                    </Button>
                );
            },
        },
        {
            accessor: 'reset',
            Header: 'Reset',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Reset_${row.id}`}
                        disabled={row.original.status !== 'LABEL_PRINTED'}
                        onClick={async () => {
                            let res = await api.resetShipmentsToLabelPurchased([row.original.shipmentID]);

                            if (!res.status) {
                                setNotification({ text: 'Failed to reset ' + res.msg, level: 'error' });
                                return;
                            }

                            getRows();
                            setNotification({ text: 'Shipment reset to LABEL_PURCHASED status', level: 'success' });
                        }}
                    >
                        Reset
                    </Button>
                );
            },
        },
    ];

    return (
        <div style={{ height: '100%' }}>
            <Notifications options={notification} />
            <h3>Create additional box</h3>
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    let res = await api.spawnNewShipment(
                        formData.shipmentID,
                        formData.cubeLevelID,
                        user.getFacilityID()
                    );
                    if (!res.status) {
                        setNotification({ text: `Failed to spawn shipment. ${res.msg}`, level: 'error' });
                        return;
                    }

                    setNotification({ text: 'Shipment created!', level: 'success' });
                    getRows();
                }}
            />
            <DataTable columns={columns} data={rows} editable={false} />
        </div>
    );
};

let ReplaceShippingLabel = () => {
    let [showSubForm, setShowSubForm] = useState(false);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [shipModel, setShipModel] = useState({});
    let [newCarrierOpts, setNewCarrierOpts] = useState([]);

    let formFields = [
        {
            name: 'shipmentID',
            label: 'Shipment ID',
            inputElement: 'textField',
            gridValue: 12,
            inputProps: { required: true },
        },
    ];

    let subForm = [
        {
            name: 'shipmentID',
            label: 'Shipment ID',
            inputElement: 'textField',
            initialValue: shipModel.id,
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'currentCarrier',
            label: 'Current Carrier',
            inputElement: 'textField',
            initialValue: shipModel.carrier,
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'currentTracking',
            label: 'Current Tracking',
            inputElement: 'textField',
            initialValue: shipModel.trackingNumber,
            gridValue: 3,
            inputProps: { readOnly: true },
        },
        {
            name: 'newCarrier',
            label: 'New Carrier',
            inputElement: 'select',
            gridValue: 3,
            inputProps: { required: true, opts: newCarrierOpts },
        },
    ];

    return (
        <div style={{ height: '100%' }}>
            <Notifications options={notification} />
            <h3>Replace Shipping Label</h3>
            <p>This will overwrite the original shipping label!</p>
            {!showSubForm && (
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let res = await api.getShipmentInfo(formData.shipmentID);
                        if (!res.status) {
                            setNotification({ text: 'Failed! ' + res.msg, level: 'error' });
                            return;
                        }

                        let carRes = await api.getCarriersForOrder(res.data.model.shopifyOrderNumber);
                        if (!res.status) {
                            setNotification({ text: 'Failed to find carrier options', level: 'error' });
                            return;
                        }

                        let carOpt = [];
                        for (let i = 0; i < carRes.data.rows.length; i++) {
                            let carrier = carRes.data.rows[i].carrier;
                            let hub = carRes.data.rows[i].hub;
                            let str = carrier + '-' + hub;
                            if (carOpt.includes(str) == true) {
                                continue;
                            }
                            carOpt.push(str);
                        }

                        setNewCarrierOpts(carOpt);
                        setShipModel(res.data.model);
                        setShowSubForm(true);
                    }}
                />
            )}
            {showSubForm && (
                <FormComponent
                    formFields={subForm}
                    onSubmit={async (formData, resetForm) => {
                        let split = formData.newCarrier.split('-');
                        let res = await api.replaceShippingLabel(formData.shipmentID, split[0], split[1]);
                        if (!res.status) {
                            setNotification({ text: 'Failed! ' + res.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Label replaced!', level: 'success' });
                    }}
                />
            )}
        </div>
    );
};

let PurchaseLabels = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [currOpt, setCurrOpt] = useState('Please select');
    let [showReplaceShipLabel, setShowReplaceShipLabel] = useState(false);
    let [showAddlBox, setShowAddlBox] = useState(false);
    let [showTestLabel, setShowTestLabel] = useState(false);

    const labelOpts = ['Please select', 'Replace shipping label', 'Additional box', 'Test label'];

    return (
        <div style={{ height: '100%' }}>
            <Notifications options={notification} />
            <h3>Additional Label</h3>
            <FormControlLabel
                control={
                    <NativeSelect
                        id="AdditionalReason_select"
                        className="input-element-select"
                        value={currOpt}
                        label="Select option"
                        onChange={(event) => {
                            setCurrOpt(event.target.value);
                            setShowReplaceShipLabel(false);
                            setShowAddlBox(false);
                            setShowTestLabel(false);
                            if (event.target.value == 'Replace shipping label') {
                                setShowReplaceShipLabel(true);
                            } else if (event.target.value == 'Additional box') {
                                setShowAddlBox(true);
                            } else if (event.target.value == 'Test label') {
                                setShowTestLabel(true);
                            }
                        }}
                    >
                        {labelOpts.map((value, i) => {
                            return (
                                <option key={i} value={value}>
                                    {value}
                                </option>
                            );
                        })}
                        ;
                    </NativeSelect>
                }
                label="Select reason"
            />
            {showReplaceShipLabel && <ReplaceShippingLabel />}
            {showAddlBox && <AdditionalBox />}
            {showTestLabel && <TestLabel />}
        </div>
    );
};

export { PurchaseLabels };
