import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { outboundTruckApi as api } from '../../../api/outboundTrucks';
import { logisticsApi } from '../../../api/logistics';
import { Button, TextField, Dialog, DialogTitle, DialogContent, Grid, IconButton } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';

let CreateTruckModal = ({ open, onClose, lineHauls, setNotification, getOutboundTrucks, doors, getDoors }) => {
    let formFields = [
        {
            name: 'lineHaulID',
            label: 'Line Haul',
            inputElement: 'autocomplete',
            inputProps: {
                opts: lineHauls.map((item) => ({ value: item.id, text: formatLineHaulForDisplay(item) })),
            },
        },
        {
            name: 'additionalLineHauls',
            label: 'Additional Line Hauls',
            inputElement: 'autocomplete',
            display: (formData) => {
                let lineHaul = lineHauls.find((item) => item.id === formData?.lineHaulID?.value);
                if (lineHaul) {
                    return lineHaul.sort;
                }
                return false;
            },
            dependencies: ['lineHaulID'],
            inputProps: {
                opts: (formData) =>
                    lineHauls
                        .filter((lh) => {
                            let selectedLineHaul = lineHauls.find((item) => item.id === formData.lineHaulID.value);
                            return selectedLineHaul.arrivalDay === lh.arrivalDay && lh.id !== formData.lineHaulID.value;
                        })
                        .map((item) => ({ value: item.id, text: formatLineHaulForDisplay(item) })),
                multiple: true,
            },
        },
        {
            name: 'outboundDoorID',
            label: 'Door',
            inputElement: 'select',
            inputProps: {
                opts: doors
                    .filter((item) => !item.outboundTruckID)
                    .map((item) => ({ text: item.name, value: item.id })),
            },
        },
        { name: 'trailerNumber', label: 'Trailer Number', inputElement: 'textField' },
        { 
          name: 'loadType', 
          label: 'Load Type', 
          inputElement: 'select', 
          inputProps: {
                opts: ["DROP_TRAILER","LIVE_LOAD"]
          },
       }
    ];
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
            <DialogTitle>Create Outbound Truck</DialogTitle>
            <DialogContent>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let sendParams = {
                            lineHaulID: formData.lineHaulID,
                            outboundDoorID: formData.outboundDoorID,
                            trailerNumber: formData.trailerNumber,
                            additionalLineHaulIDs: formData.additionalLineHauls.map((item) => item.value),
                            loadType: formData.loadType,
                        };
                        let res = await api.createTruck(sendParams);
                        if (!res.status) {
                            setNotification({ text: 'Failed to create truck. ' + res.msg, level: 'error' });
                            return false;
                        }
                        getOutboundTrucks();
                        getDoors();
                        onClose();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let formatLineHaulForDisplay = (lh) => (!lh ? '' : `${lh.carrier} ${lh.hub} ${lh.arrivalDay}`);

let OutboundTrucks = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [outboundTrucks, setOutboundTrucks] = useState([]);
    let [createTruckModalOpen, setCreateTruckModalOpen] = useState(false);
    let [lineHauls, setLineHauls] = useState([]);
    let [doors, setDoors] = useState([]);
    let [delayReasons, setDelayReasons] = useState([]);
    const user = useContext(UserContext);

    let getDoors = async () => {
        let res = await api.getDoors(user.getFacilityID());
        if (!res.status) {
            setNotification({ text: 'Failed to get doors. ' + res.msg, level: 'error' });
            return;
        }
        setDoors(res.data.doors);
    };

    let getOutboundTrucks = async () => {
        let res = await api.getTrucks(user.getFacilityID());
        if (!res.status) {
            setNotification({ text: 'Failed to get outbound trucks. ' + res.msg, level: 'error' });
            return;
        }
        setOutboundTrucks(
            res.data.rows.map((row) => {
                row.arrivalTime = moment(row.arrivalTime.date + row.arrivalTime.timezone)
                    .local()
                    .format('MM/DD/YYYY hh:mm a');
                if (row.departureTime) {
                    row.departureTime = moment(row.departureTime.date + row.departureTime.timezone)
                        .local()
                        .format('MM/DD/YYYY hh:mm a');
                }
                return row;
            })
        );
    };

    let getLineHauls = async () => {
        let res = await logisticsApi.getLineHauls('activeonly');

        if (res.status === false) {
            setNotification({ text: 'Failed to get line hauls. ' + res.msg, level: 'error' });
            return;
        }

        let data = res.data.lineHauls.filter((line) => line.facilityID === user.getFacilityID());
        setLineHauls(data);
    };

    let getDelayedReasons = async () => {
        let res = await api.getTruckDelayReasons();

        if (res.status === false) {
            setNotification({ text: 'Failed to get delayed reasons. ' + res.msg, level: 'error' });
            return;
        }

        setDelayReasons(res.data.rows);
    };

    let columns = [
        { Header: 'ID', accessor: 'id' },
        {
            Header: 'Load Type',
            accessor: 'loadType',
            editable: (row) => row.original.status === 'LOADING',
            editProps: {
                type: 'select',
                options: ["DROP_TRAILER","LIVE_LOAD"],
            },
        },
        { Header: 'Arrival Time', accessor: 'arrivalTime', type: 'date' },
        { Header: 'Departure Time', accessor: 'departureTime', type: 'date' },
        {
            Header: 'Status',
            accessor: 'status',
            editable: true,
            editProps: {
                type: 'select',
                options: ['LOADING', 'DEPARTED'],
            },
        },
        {
            Header: 'Trailer Number',
            accessor: 'trailerNumber',
            editable: (row) => row.original.status === 'LOADING',
            editProps: { type: 'input' },
        },
        {
            Header: 'Door',
            accessor: 'outboundDoorID',
            editable: true,
            editProps: {
                type: 'select',
                options: doors.map((item) => ({ text: item.name, value: item.id, disabled: !!item.outboundTruckID })),
            },
        },
        { Header: 'Line Haul ID', accessor: 'lineHaulID' },
        { Header: 'LM Carrier', accessor: 'carrier' },
        { Header: 'Hub', accessor: 'hub' },
        { Header: 'LH Carrier', accessor: 'outboundCarrier'},
        { Header: 'Emails', accessor: 'outboundContacts', editable: (row) => row.original.status === 'LOADING' },
        { Header: 'Ship Day', accessor: 'arrivalDay' },
        { Header: 'Pallet Count', accessor: 'palletCount' },
        { Header: 'Shipment Count', accessor: 'shipmentCount' },
        {
            Header: 'Additional Line Hauls',
            accessor: 'additionalLineHaulIDs',
            editable: (row) =>
                row.original.status === 'LOADING' &&
                lineHauls
                    .filter((item) => item.sort)
                    .map((item) => item.id)
                    .includes(row.original.lineHaulID),
            editProps: {
                type: 'autocomplete-multiple',
                options: (row) =>
                    lineHauls
                        .filter((lh) => {
                            return (
                                lh.id !== row.lineHaulID &&
                                lineHauls.find((lineHaul) => lineHaul.id === row.lineHaulID)?.arrivalDay ===
                                    lh.arrivalDay
                            );
                        })
                        .map((item) => ({ value: item.id, text: formatLineHaulForDisplay(item) })),
                multiple: true,
            },
        },
        { Header: 'Cost', accessor: 'cost' },
        { Header: 'Delayed?', accessor: 'delayed' },
        {
            Header: 'Delayed Reason',
            accessor: 'delayedReason',
            editable: (row) => row.original.delayed === 'YES',
            editProps: {
                type: 'select',
                options: delayReasons,
            },
        },
    ];

    let loadData = async () => {
        getDoors();
        getLineHauls();
        getDelayedReasons();
    };

    useEffect(() => {
        if (lineHauls.length) {
            getOutboundTrucks();
        }
    }, [lineHauls]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div style={{ marginTop: 50 }}>
            <Notifications options={notification} />
            <CreateTruckModal
                open={createTruckModalOpen}
                onClose={() => {
                    setCreateTruckModalOpen(false);
                }}
                doors={doors}
                getDoors={getDoors}
                lineHauls={lineHauls}
                setNotification={setNotification}
                getOutboundTrucks={getOutboundTrucks}
            />
            <Grid container style={{ margin: 20 }}>
                {doors.map((door) => {
                    return (
                        <Grid key={door.id} item xs={12} sm={6} md={2}>
                            <h2>
                                {door.name}
                                <IconButton
                                    id={`${door.name.replace(' ', '')}_download`}
                                    onClick={() => {
                                        api.getDoorTag(door.id);
                                    }}
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </h2>
                            {door.outboundTruckID ? (
                                <div style={{ fontSize: 15, fontWeight: 'bold' }}>
                                    <div>Truck: {door.outboundTruckID}</div>
                                    <div>Carrier: {door.carrier.toUpperCase()}</div>
                                    <div>Hub: {door.hub.toUpperCase()}</div>
                                    <div>Day: {door.arrivalDay.toUpperCase()}</div>
                                    {door.additionalLineHaulIDs?.length > 0 && (
                                        <div>
                                            Additional Line Hauls:{' '}
                                            {door.additionalLineHaulIDs
                                                .map((lhID) => {
                                                    return formatLineHaulForDisplay(
                                                        lineHauls.find((item) => item.id === lhID)
                                                    );
                                                })
                                                .join(', ')}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div style={{ fontSize: 15, fontWeight: 'bold', color: 'green' }}>OPEN</div>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
            <DataTable
                title="Outbound Trucks"
                columns={columns}
                editable={true}
                data={outboundTrucks}
                csvExport={true}
                toolbarActions={[
                    {
                        name: 'Create Truck',
                        action: () => {
                            setCreateTruckModalOpen(true);
                        },
                    },
                ]}
                saveEdit={async ({ id, field, value, row }) => {
                    let sendParams = { [field]: value };
                    sendParams['lineHaulID'] = row.lineHaulID;
                    let res = await api.updateTruck(id, sendParams);
                    if (!res.status) {
                        setNotification({ text: 'Failed to update truck. ' + res.msg, level: 'error' });
                        return;
                    }
                    getOutboundTrucks();
                    getDoors();
                }}
            />
        </div>
    );
};

export { OutboundTrucks };
