import React from 'react';
import { Link } from 'react-router-dom';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';

let GoPuffOrderUpload = () => {
    let columns = [
        { Header: 'Delivery Date', accessor: 'deliveryDate' },
        { Header: 'Market', accessor: 'market' },
        { Header: 'Micro FC', accessor: 'microFC' },
        { Header: 'PO Num', accessor: 'poNum' },
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Go Puff Sku', accessor: 'goPuffSku' },
        { Header: 'Product Name', accessor: 'productName' },
        { Header: 'Qty', accessor: 'qty' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.confirmOrderUpload(key);
        return res;
    };

    let uploadOrders = async (formData) => {
        let res = await api.uploadOrders(formData);
        return res;
    };

    let downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Delivery Date,Market,';
        csvContent += 'Micro FC,PO Num,External Sku,Go Puff Sku,Product Name,Qty';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'go_puff_order_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <Link to={`/goPuff/orders`}>View Orders</Link>
            <UploadCSVAndReviewComponent
                title="Upload Go Puff Orders"
                fileLabel="Order File"
                columns={columns}
                upload={uploadOrders}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
            />
        </div>
    );
};

export { GoPuffOrderUpload };
