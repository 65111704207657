import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable } from '../../../shared';
import { fbmApi as api } from '../../../api/fbm';
import { UserContext } from '../../../contexts/UserContext';
import { packagingApi } from '../../../api/packaging';

let ViewFBMPackaging = ({ typeOpts, canEditFBMItems }) => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [boxOpts, setBoxOpts] = useState([]);
    const { fbmAccount } = useContext(UserContext);

    // Load rows
    let getRows = async () => {
        let response = await api.getPackaging(fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    let filterString = (string) => {
        if (string.includes('model')) {
            return string.substr(6);
        }
        return string;
    };

    let getBoxes = async () => {
        let response = await packagingApi.getBoxes(fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No Boxes found ' + response.msg, level: 'error' });
            setBoxOpts([]);
            return;
        }

        let boxOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setBoxOpts(boxOpts);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getRows();
            getBoxes();
        }
    }, [fbmAccount]);

    let cols = [
        { accessor: 'model.id', Header: 'ID' },
        { accessor: 'model.materialNumber', Header: `${fbmAccount.companyName} SKU` },
        { accessor: 'model.itemNameInternal', Header: 'Name', editable: canEditFBMItems },
        {
            accessor: 'model.type',
            Header: 'Type',
            editable: canEditFBMItems,
            editProps: { type: 'select', options: typeOpts },
        },
        { accessor: 'model.description', Header: 'Description', editable: canEditFBMItems },
        {
            accessor: 'model.csPallet',
            Header: 'Cases per Pallet',
            editable: canEditFBMItems,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'model.unitsPerCase',
            Header: 'Units per Case',
            editable: canEditFBMItems,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'model.lengthInches',
            Header: 'Length (in)',
            editable: canEditFBMItems,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'model.widthInches',
            Header: 'Width (in)',
            editable: canEditFBMItems,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'model.heightInches',
            Header: 'Height (in)',
            editable: canEditFBMItems,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        {
            accessor: 'model.totalCubicInches',
            Header: 'Cubic Volume (in)',
        },
        {
            accessor: 'boxSize',
            Header: 'Box Size',
            editable: canEditFBMItems,
            editProps: { type: 'select', options: boxOpts },
        },
    ];

    return (
        <div>
            <h1>{fbmAccount.companyName} Packaging Catalog</h1>
            <Notifications options={notification} />
            <div>
                <DataTable
                    editable={true}
                    columns={cols}
                    data={rows}
                    saveEdit={async ({ row, field, value }) => {
                        let item = {};
                        item[filterString(field)] = value;

                        let res = await packagingApi.editPackaging(row.model.id, item);
                        if (res.status === false) {
                            let message = 'Error modifying FBM packaging';
                            if (res) {
                                message += ': ' + res.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Packaging modified!', level: 'success' });
                            getRows();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export { ViewFBMPackaging };
