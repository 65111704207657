import React, { useState, useEffect, useContext } from 'react';
import { Button, Checkbox, Paper, Tabs, Tab } from '@material-ui/core';
import { DataTable, TabPanel, FormComponent, Notifications } from '../../../shared';
import { shippingApi } from '../../../api/shipping';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';

let PrintLabels = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let [notification, setNotification] = useState({ text: '', level: null });
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Notifications options={notification} />
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="VIPShipments_Tab" label="VIP shipments" value="0" />
                <Tab id="PrintLabel_Tab" label="Print Label" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <VipOrders setNotification={setNotification} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <PrintLabel setNotification={setNotification} />
            </TabPanel>
        </Paper>
    );
};

let PrintLabel = ({ setNotification }) => {
    return (
        <div style={{ height: '100%' }}>
            <h3>Print Label</h3>
            <FormComponent
                formFields={[
                    {
                        name: 'shipmentID',
                        inputElement: 'textField',
                        label: 'Shipment ID',
                        gridValue: 12,
                        inputProps: { required: true },
                    },
                ]}
                onSubmit={async (formData, resetForm) => {
                    let res = await shippingApi.downloadLPN(formData.shipmentID);
                    if (res === true) {
                        resetForm();
                    } else {
                        setNotification({ text: 'Error getting shipping label', level: 'error' });
                    }
                    return res;
                }}
            />
        </div>
    );
};

let VipOrders = ({ setNotification }) => {
    const [unprintedRows, setUnprintedRows] = useState([]);
    const [printedRows, setPrintedRows] = useState([]);
    const user = useContext(UserContext);
    const [facilityID, setFacilityID] = useState(user.getFacilityID());

    let getUnprintedVIPOrders = async () => {
        let result = await shippingApi.getVipShipments(true, facilityID);
        if (result.status === true) {
            setUnprintedRows(
                result.data.shipments.map((item) => {
                    item.shipment.createdAt = moment(item.shipment.createdAt.date).local().format('MM/DD/YYYY');
                    return item;
                })
            );
        }
    };
    let getPrintedVIPOrders = async () => {
        let result = await shippingApi.getVipShipments(false, facilityID);
        if (result.status === true) {
            setPrintedRows(
                result.data.shipments.map((item) => {
                    item.shipment.createdAt = moment(item.shipment.createdAt.date).local().format('MM/DD/YYYY');
                    return item;
                })
            );
        }
    };
    let handlePrintedChange = async (printed, id) => {
        if (printed) {
            let res = await shippingApi.markVipOrderPrinted(id);
            if (res.status === true) {
                getPrintedVIPOrders();
                getUnprintedVIPOrders();
            }
        }
    };

    useEffect(() => {
        getUnprintedVIPOrders();
        getPrintedVIPOrders();
    }, []);

    let columns = [
        { accessor: 'shipment.id', Header: 'ID' },
        { accessor: 'shipment.status', Header: 'Status' },
        { accessor: 'shipment.createdAt', Header: 'Created Date' },
        { accessor: 'shipment.shopifyOrderNumber', Header: 'Order Number' },
        { accessor: 'lineHaul.carrier', Header: 'Carrier' },
        { accessor: 'lineHaul.hub', Header: 'Hub' },
        { accessor: 'lineHaul.arrivalDay', Header: 'Pack Day' },
        { accessor: 'lineHaul.arrivalHour', Header: 'Arrival Hour' },
        {
            accessor: 'linkToPDF',
            Header: 'PDF',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        className="download-link"
                        onClick={async () => {
                            let res = await shippingApi.downloadLPN(row.original.shipment.id);
                            if (res !== true) {
                                setNotification({ text: 'Error getting shipping label', level: 'error' });
                            }
                            getUnprintedVIPOrders();
                            getPrintedVIPOrders();
                        }}
                    >
                        Download
                    </Button>
                );
            },
        },
        {
            accessor: 'printed',
            Header: 'Printed',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                let printed = row.original.shipment.status === 'LABEL_PURCHASED' ? false : true;
                return (
                    <div>
                        <Checkbox
                            checked={printed}
                            onChange={(event) => {
                                handlePrintedChange(!printed, row.original.shipment.id);
                            }}
                            value="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                );
            },
        },
    ];
    return (
        <div>
            <DataTable title="Ready To Print" columns={columns} data={unprintedRows} editable={false} />

            <DataTable title="Printed" columns={columns} data={printedRows} editable={false} />
        </div>
    );
};

export { PrintLabels };
