const csvUtil = {
    cleanCsv: (csvData) => {
        return new Promise((resolve) => {
            try {
                const ERROR_LEVEL = 'error';
                let notificationParams = {
                    text: null,
                    level: null,
                };

                let csvContents = null;
                const rows = csvData.replace(/\r/g, '').split(`\n`);
                csvContents = rows.map((row) => row.split(','));
                if (csvContents.length <= 1) {
                    notificationParams.text = 'File Uploaded is Empty';
                    notificationParams.level = ERROR_LEVEL;
                    resolve(notificationParams);
                    return;
                }

                resolve(csvContents);
            } catch(error) {
                PromiseRejectionEvent(error);
            }
        });
    },
    csvColumnIntegerValidation: (csvContents, columnName) => {
        return new Promise((resolve) => {
            try {
                const ERROR_LEVEL = 'error';
                let notificationParams = {
                    text: null,
                    level: null,
                };
                const columnIndex = csvContents[0].indexOf(columnName);
                if (columnIndex === -1) {
                    notificationParams.text = `The ${columnName} column could not be found in the uploaded file`;
                    notificationParams.level = ERROR_LEVEL;
                    resolve(notificationParams);
                    return;
                }

                let invalidColumnValues = [];
                for (let r = 1; r < csvContents.length; r++) {
                    if (!Number.isInteger(Number(csvContents[r][columnIndex]))) {
                        invalidColumnValues.push(csvContents[r][columnIndex]);
                    }

                    if (Number(csvContents[r][columnIndex]) < 0) {
                        invalidColumnValues.push(csvContents[r][columnIndex]);
                    }
                }

                if (invalidColumnValues.length > 0) {
                    const errorList = invalidColumnValues.join(', ');
                    notificationParams.text = `The following values for column "${columnName}" are invalid due to not being positive integers: ${errorList}`;
                    notificationParams.level = ERROR_LEVEL;
                    resolve(notificationParams);
                    return;
                }

                resolve();
            } catch(error) {
                PromiseRejectionEvent(error);
            }
        });
    },
    csvColumnUSCurrencyValidation: (csvContents, columnName) => {
        return new Promise((resolve) => {
            try {
                const FULL_AMOUNT_VALUE = 'FULL';
                const ERROR_LEVEL = 'error';
                let notificationParams = {
                    text: null,
                    level: null,
                };
                const columnIndex = csvContents[0].indexOf(columnName);
                if (columnIndex === -1) {
                    notificationParams.text = `The ${columnName} column could not be found in the uploaded file`;
                    notificationParams.level = ERROR_LEVEL;
                    resolve(notificationParams);
                    return;
                }

                let invalidColumnValues = [];
                const regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
                for (let r = 1; r < csvContents.length; r++) {
                    if (csvContents[r][columnIndex] == null) {
                        invalidColumnValues.push(csvContents[r][columnIndex]);
                        continue;
                    }

                    if (csvContents[r][columnIndex] == FULL_AMOUNT_VALUE) {
                        continue;
                    }

                    if (regex.test(csvContents[r][columnIndex]) == true) {
                        continue;
                    } else {
                        invalidColumnValues.push(csvContents[r][columnIndex]);
                    }
                }

                if (invalidColumnValues.length > 0) {
                    const errorList = invalidColumnValues.join(', ');
                    notificationParams.text = `The following values for column "${columnName}" are invalid due to not being in correct US currency format: ${errorList}`;
                    notificationParams.level = ERROR_LEVEL;
                    resolve(notificationParams);
                    return;
                }

                resolve();
            } catch(error) {
                PromiseRejectionEvent(error);
            }
        });
    },
};

export { csvUtil };