import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DataTable, Notifications } from '../../../shared';
import { productApi as api } from '../../../api/product';

let ViewFeatureFlag = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'feature', Header: 'Feature Flag', editable: true },
        { accessor: 'enabled', Header: 'Enabled', editable: true, type: 'checkbox', editProps: { type: 'checkbox' } },
    ];

    let getFeatureFlag = async () => {
        let response = await api.getAssignableFeatureFlags();
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getFeatureFlag();
    }, []);

    return (
        <div>
            <h1>Feature Flags</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value, row }) => {
                        const payload = field === 'feature' ? { name: value } : { [field]: value };
                        let res = await api.updateFeatureFlag(id, payload);
                        if (!res.status) {
                            setNotification({ text: res.msg, level: 'error' });
                            return false;
                        }

                        setNotification({ text: 'Flag updated', level: 'success' });
                        getFeatureFlag();
                        return true;
                    }}
                />
            </div>
        </div>
    );
};

export { ViewFeatureFlag };
