import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import { FormComponent, Notifications, DataTable, TabPanel, SearchBar } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';

const InventoryHistory = () => {
    let [notification, setNotification] = useState({ text: '', level: '' });
    const [rows, setRows] = useState([]);
    const [searching, setSearching] = useState(false);
    const [eventTypes, setEventTypes] = useState([]);

    const user = useContext(UserContext);
    const getInventoryHistory = async (params) => {
        setSearching(true);
        params.facilityID = user.getFacilityID();
        const res = await api.getInventoryHistory(params);
        if (!res.status) {
            setNotification({ text: res.msg || 'Failed to get inventory history', level: 'error' });
        }
        setRows(
            res.data.rows.map((item) => {
                item.partialCases = item.partialCaseIDs.map((c) => c.name).join(', ');
                item.timestamp = moment(item.timestamp.date).format('MM/DD/YYYY h:mm a');
                if (item.eventType === 'REPLENISHED') {
                    item.eventType = 'IL2_LOT_REPLENISHED';
                } else if (item.eventType === 'CREATED') {
                    item.eventType = 'IL2_LOT_CREATED';
                }
                if (item.isPackaging) {
                    item.endingNumUnits = '';
                    item.originalNumUnits = '';
                    item.changeInUnits = '';
                    item.fullCases = [];
                }
                return item;
            })
        );
        setEventTypes(res.data.eventTypes);
        setSearching(false);
    };

    useEffect(() => {
        getInventoryHistory({});
    }, []);

    const searchFormFields = [
        { label: 'Start Date', name: 'startDate', inputElement: 'date', gridValue: 3 },
        { label: 'Start Time', name: 'startTime', inputElement: 'time', gridValue: 3 },
        { label: 'End Date', name: 'endDate', inputElement: 'date', gridValue: 3 },
        { label: 'End Time', name: 'endTime', inputElement: 'time', gridValue: 3 },
        { label: 'Sku', name: 'sku', inputProps: { type: 'number' }, gridValue: 3 },
        { label: 'Location', name: 'locationName', gridValue: 3 },
        {
            label: 'Event Type',
            name: 'eventTypes',
            inputElement: 'autocomplete',
            inputProps: { multiple: true, opts: eventTypes },
            gridValue: 3,
        },
        { label: 'Lot', name: 'lotID', inputProps: { type: 'number' }, gridValue: 3 },
        { label: 'Pallet', name: 'palletName', gridValue: 3 },
        { label: 'Case', name: 'caseName', gridValue: 3 },
        { label: 'User', name: 'user', gridValue: 3 },
        { label: 'Show Non Qty Change Events', name: 'allEvents', inputElement: 'checkbox', gridValue: 3 },
    ];

    const columns = [
        { Header: 'Timestamp', accessor: 'timestamp' },
        { Header: 'Sku', accessor: 'sku' },
        { Header: 'Name', accessor: 'itemName' },
        { Header: 'Location', accessor: 'warehouseLocationName' },
        { Header: 'Event Type', accessor: 'eventType' },
        { Header: 'Lot', accessor: 'lotID' },
        { Header: 'Pallet', accessor: 'palletName' },
        {
            Header: 'Full Cases',
            Cell: ({ row }) => {
                let fullCaseNames = {};
                row.original.fullCases.forEach((c) => {
                    if (!fullCaseNames.hasOwnProperty(c.name)) {
                        fullCaseNames[c.name] = 0;
                    }
                    fullCaseNames[c.name]++;
                });
                return (
                    <div>
                        {Object.keys(fullCaseNames).map((key) => {
                            return (
                                <div>
                                    {fullCaseNames[key]} X {key}
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        { Header: 'Partial Cases', accessor: 'partialCases' },
        { Header: 'Original Num Cases', accessor: 'originalNumCases' },
        { Header: 'Original Num Units', accessor: 'originalNumUnits' },
        { Header: 'Ending Num Cases', accessor: 'endingNumCases' },
        { Header: 'Ending Num Units', accessor: 'endingNumUnits' },
        { Header: 'Change In Cases', accessor: 'changeInCases' },
        { Header: 'Change In Units', accessor: 'changeInUnits' },
        { Header: 'User', accessor: 'user' },
    ];

    return (
        <Paper style={{ minHeight: '80vh' }}>
            <Notifications options={notification} />
            <h1>Inventory History</h1>
            <div
                style={{
                    padding: '20px',
                    marginLeft: '10px',
                    marginRight: '10px',
                }}
            >
                <FormComponent
                    compact={true}
                    formFields={searchFormFields}
                    onSubmit={(formData, resetForm) => {
                        const params = {};
                        Object.keys(formData).forEach((key) => {
                            if (formData[key] == '') {
                                return false;
                            }
                            if (Array.isArray(formData[key])) {
                                // return false;
                                params[key] = formData[key].map((i) => i.value).join(',');
                            } else {
                                params[key] = formData[key];
                            }
                        });
                        console.log('submit', params);
                        getInventoryHistory(params);
                    }}
                    button={{ text: 'Search Inventory History' }}
                />
            </div>
            {!searching && (
                <div>
                    <DataTable columns={columns} data={rows} editable={false} />
                </div>
            )}
        </Paper>
    );
};

export { InventoryHistory };
