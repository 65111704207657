import React, { useState, useEffect } from 'react';
import {
    Button,
    Grid,
    TextField,
    Paper,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Tabs,
    Tab,
    FormControlLabel,
    Checkbox,
    NativeSelect,
} from '@material-ui/core';
import { Notifications, DataTable, TabPanel, FormComponent } from '../../../shared';
import { catalogApi as api } from '../../../api/catalog';
import { warehouseApi } from '../../../api/warehouse';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { partnershipsApi } from '../../../api/partnerships';

const ItemInformation = () => {
    let [tags, setTags] = useState([]);
    let [selectedTab, setSelectedTab] = useState('0');
    const [importedOrderSources, setImportedOrderSources] = useState([]);

    let getTags = async () => {
        let res = await api.getItemInformationTags();
        if (res.status === true) {
            setTags(res.data.tags);
        }
    };

    const getImportedOrderSources = async () => {
        const res = await partnershipsApi.getSources();
        if (res.status) {
            setImportedOrderSources(res?.data?.rows);
        }
    };

    useEffect(() => {
        getTags();
        getImportedOrderSources();
    }, []);

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="Items_Tab" label="Items" value="0" />
                <Tab id="Tags_Tab" label="Tags" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ItemsComponent tags={tags} importedOrderSources={importedOrderSources} />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <TagsComponent tags={tags} setTags={setTags} getTags={getTags} />
            </TabPanel>
        </Paper>
    );
};

let EditPriceOverridesModal = ({ selectedRow, setOpen, getItemInformation, setNotification, facilities }) => {
    let facilitiesWithDefaultPrice = selectedRow.priceOverrides.map((item) => item.facilityID);
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={true}
            onClose={() => {
                setOpen(false);
            }}
        >
            <DialogTitle>Edit Price Overrides - External Sku: {selectedRow.externalSku}</DialogTitle>
            <DialogContent>
                <DataTable
                    editable={true}
                    columns={[
                        { accessor: 'facility', Header: 'Facility', editable: false },
                        {
                            accessor: 'defaultPrice',
                            Header: 'Default Price',
                            editable: true,
                            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
                        },
                        {
                            Header: 'Remove',
                            Cell: ({ row }) => {
                                return (
                                    <Button
                                        id={`Remove_${row.id}`}
                                        onClick={async () => {
                                            let res = await api.updateDefaultPriceByExternalSkuFacilityID({
                                                externalSku: selectedRow.externalSku,
                                                facilityID: row.original.facilityID,
                                                defaultPrice: null,
                                            });
                                            if (!res.status) {
                                                setNotification({
                                                    level: 'error',
                                                    text: `Failed to update: ${res.msg}`,
                                                });
                                                return;
                                            }
                                            getItemInformation();
                                        }}
                                    >
                                        Remove
                                    </Button>
                                );
                            },
                        },
                    ]}
                    data={selectedRow.priceOverrides}
                    saveEdit={async ({ row, value }) => {
                        let res = await api.updateDefaultPriceByExternalSkuFacilityID({
                            externalSku: selectedRow.externalSku,
                            facilityID: row.facilityID,
                            defaultPrice: value,
                        });
                        if (!res.status) {
                            setNotification({ level: 'error', text: `Failed to update: ${res.msg}` });
                            return false;
                        }
                        getItemInformation();
                        return true;
                    }}
                />

                <h3>Add Default Price For Facility</h3>
                <FormComponent
                    formFields={[
                        {
                            name: 'facilityID',
                            label: 'Facility',
                            inputElement: 'select',
                            inputProps: {
                                required: true,
                                opts: facilities
                                    .filter((item) => !facilitiesWithDefaultPrice.includes(item.id))
                                    .map((item) => ({ text: item.name, value: item.id })),
                            },
                        },
                        {
                            name: 'defaultPrice',
                            label: 'Default Price',
                            inputProps: { required: true, type: 'number' },
                        },
                    ]}
                    onSubmit={async (formData, resetForm) => {
                        let res = await api.updateDefaultPriceByExternalSkuFacilityID({
                            externalSku: selectedRow.externalSku,
                            facilityID: formData.facilityID,
                            defaultPrice: formData.defaultPrice,
                        });
                        if (!res.status) {
                            setNotification({ level: 'error', text: `Failed to create aisle mapping: ${res.msg}` });
                            return;
                        }
                        getItemInformation();
                        resetForm();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let EditAisleModal = ({ selectedAisle, setEditAisleModalOpen, taxonomy, getItemInformation, setNotification }) => {
    const getTaxonomyOptions = (row, level) => {
        let parentID = level > 1 ? row[`l${level - 1}AisleID`] : 0;
        let opts = taxonomy[`l${level}`]
            .filter((item) => item.parentID === Number(parentID))
            .map((item) => ({ text: item.name, value: item.id }));
        opts.unshift({ text: '', value: null });
        return opts;
    };

    let addAisleFields = [
        {
            name: 'l1AisleID',
            inputElement: 'select',
            label: 'Level 1 Aisle',
            gridValue: 3,
            inputProps: { required: true, opts: getTaxonomyOptions({}, 1) },
        },
        {
            name: 'l2AisleID',
            inputElement: 'select',
            label: 'Level 2 Aisle',
            gridValue: 3,
            dependencies: ['l1AisleID'],
            inputProps: {
                opts: (formData) => {
                    if (!formData.l1AisleID) {
                        return [];
                    }
                    return getTaxonomyOptions(formData, 2);
                },
            },
        },
        {
            name: 'l3AisleID',
            inputElement: 'select',
            label: 'Level 3 Aisle',
            gridValue: 3,
            dependencies: ['l1AisleID', 'l2AisleID'],
            inputProps: {
                opts: (formData) => {
                    if (!formData.l1AisleID || !formData.l2AisleID) {
                        return [];
                    }
                    return getTaxonomyOptions(formData, 3);
                },
            },
        },
        {
            name: 'l4AisleID',
            inputElement: 'select',
            label: 'Level 4 Aisle',
            gridValue: 3,
            dependencies: ['l1AisleID', 'l2AisleID', 'l3AisleID'],
            inputProps: {
                opts: (formData) => {
                    if (!formData.l1AisleID || !formData.l2AisleID || !formData.l3AisleID) {
                        return [];
                    }
                    return getTaxonomyOptions(formData, 4);
                },
            },
        },
    ];

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={true}
            onClose={() => {
                setEditAisleModalOpen(false);
            }}
        >
            <DialogTitle>Edit Aisle Taxonomy - External Sku: {selectedAisle.externalSku}</DialogTitle>
            <DialogContent>
                <DataTable
                    editable={true}
                    columns={[
                        {
                            accessor: 'l1AisleID',
                            Header: 'L1 Aisle',
                            editable: true,
                            editProps: {
                                type: 'select',
                                options: (row) => getTaxonomyOptions(row, 1),
                            },
                        },
                        {
                            accessor: 'l2AisleID',
                            Header: 'L2 Aisle',
                            editable: true,
                            editProps: {
                                type: 'select',
                                options: (row) => getTaxonomyOptions(row, 2),
                            },
                        },
                        {
                            accessor: 'l3AisleID',
                            Header: 'L3 Aisle',
                            editable: true,
                            editProps: {
                                type: 'select',
                                options: (row) => getTaxonomyOptions(row, 3),
                            },
                        },
                        {
                            accessor: 'l4AisleID',
                            Header: 'L4 Aisle',
                            editable: true,
                            editProps: {
                                type: 'select',
                                options: (row) => getTaxonomyOptions(row, 4),
                            },
                        },
                        {
                            Header: 'Remove',
                            Cell: ({ row }) => {
                                return (
                                    <Button
                                        id={`Remove_${row.id}`}
                                        onClick={async () => {
                                            let res = await api.deleteExternalSkuAisleMapping(row.original.id);
                                            if (!res.status) {
                                                setNotification({
                                                    level: 'error',
                                                    text: `Failed to remove aisle mapping: ${res.msg}`,
                                                });
                                                return;
                                            }
                                            getItemInformation();
                                        }}
                                    >
                                        Remove
                                    </Button>
                                );
                            },
                        },
                    ]}
                    data={selectedAisle.aisles}
                    saveEdit={async ({ id, field, value }) => {
                        let res = await api.editExternalSkuAisleMapping(id, { [field]: value });
                        if (!res.status) {
                            setNotification({ level: 'error', text: `Failed to edit aisle mapping: ${res.msg}` });
                            return false;
                        }
                        getItemInformation();
                        return true;
                    }}
                />

                <h3>Add Aisle Mapping</h3>
                <FormComponent
                    formFields={addAisleFields}
                    onSubmit={async (formData, resetForm) => {
                        formData.externalSku = selectedAisle.externalSku;
                        let res = await api.createExternalSkuAisleMapping(formData);
                        if (!res.status) {
                            setNotification({ level: 'error', text: `Failed to create aisle mapping: ${res.msg}` });
                            return;
                        }
                        getItemInformation();
                        resetForm();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

let PhotoModal = ({ selectedPhoto, setPhotoModalOpen, updateItemPhoto }) => {
    let [file, setFile] = useState(null);
    let [source, setSource] = useState(null);
    let [title, setTitle] = useState('');
    let [photoType, setPhotoType] = useState('');
    let [loading, setLoading] = useState(false);

    let item = selectedPhoto.item;
    let key = selectedPhoto.key;

    useEffect(() => {
        if (key === 'photoURLNutritionalInfo') {
            setTitle(`Edit Nutritional Info Image for ${item.name}`);
            setPhotoType('nutritional');
        } else if (key === 'photoTwoURLSmall') {
            setTitle(`Edit Photo 2 for ${item.name}`);
            setPhotoType('photo2');
        } else if (key === 'photoThreeURLSmall') {
            setTitle(`Edit Photo 3 for ${item.name}`);
            setPhotoType('photo3');
        } else if (key === 'photoFourURLSmall') {
            setTitle(`Edit Photo 4 for ${item.name}`);
            setPhotoType('photo4');
        } else if (key === 'photoFiveURLSmall') {
            setTitle(`Edit Photo 5 for ${item.name}`);
            setPhotoType('photo5');
        } else {
            setPhotoType('product');
            setTitle(`Edit Product Image for ${item.name}`);
        }
    }, []);

    return (
        <Dialog
            open={true}
            onClose={() => {
                setPhotoModalOpen(false);
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {item[key] && !source && <img src={`https://static.misfitsmarket.com/${item[key]}`} alt="Item" />}
                {source && (
                    <div style={{ textAlign: 'center' }}>
                        <img src={source} alt="Preview" />
                    </div>
                )}
                <Button id="ChooseFile" style={{ textAlign: 'center' }} variant="outlined" component="label">
                    Choose File
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        name="Photo Upload"
                        id="photo-file-input"
                        accept="image/png, image/jpeg"
                        onChange={(event) => {
                            let file = event.target.files[0];
                            setFile(file);

                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = (e) => {
                                setSource(e.target.result);
                            };
                        }}
                    />
                </Button>
            </DialogContent>
            <DialogActions>
                {(key === 'photoURLNutritionalInfo' ||
                    key == 'photoTwoURLSmall' ||
                    key == 'photoThreeURLSmall' ||
                    key == 'photoFourURLSmall' ||
                    key == 'photoFiveURLSmall') &&
                    !!item[key] && (
                        <Button
                            id="RemovePhoto"
                            color="secondary"
                            disabled={loading === true}
                            onClick={async () => {
                                setLoading(true);
                                let res = await updateItemPhoto(photoType, '', item.externalSku);
                                setLoading(false);
                                if (res === true) {
                                    setPhotoModalOpen(false);
                                }
                            }}
                        >
                            Remove Photo
                        </Button>
                    )}
                <Button
                    id="Confirm"
                    disabled={file === null || loading === true}
                    onClick={async () => {
                        setLoading(true);
                        let res = await updateItemPhoto(photoType, file, item.externalSku);
                        setLoading(false);
                        if (res === true) {
                            setPhotoModalOpen(false);
                        }
                    }}
                >
                    Confirm
                </Button>
                <Button id="Cancel" disabled={loading} onClick={() => setPhotoModalOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

let PriceOverridesCell = ({ row, setSelectedRow, setEditPriceOverridesModalOpen }) => {
    let priceOverrides = row.original.priceOverrides;

    return (
        <div>
            {priceOverrides.map((item, index) => {
                return (
                    <div key={`${index}`}>
                        <span>
                            {item.facility} - {item.defaultPrice}
                        </span>
                    </div>
                );
            })}
            <div>
                <Button
                    id={`PriceOverride_${row.id}`}
                    onClick={() => {
                        setSelectedRow(row.original);
                        setEditPriceOverridesModalOpen(true);
                    }}
                >
                    EDIT
                </Button>
            </div>
        </div>
    );
};

let AislesCell = ({ row, taxonomy, setSelectedRow, setEditAisleModalOpen }) => {
    let aisles = row.original.aisles;

    return (
        <div>
            {aisles.map((item, index) => {
                return (
                    <div key={`${index}`} style={{ paddingTop: '2px' }}>
                        {!!item.l1AisleID && (
                            <span style={{ display: 'inline' }}>
                                {taxonomy?.l1?.find((tx) => tx.id === item.l1AisleID)?.name}
                            </span>
                        )}
                        {!!item.l2AisleID && (
                            <span style={{ display: 'inline' }}>
                                {' '}
                                -> {taxonomy?.l2?.find((tx) => tx.id === item.l2AisleID)?.name}
                            </span>
                        )}
                        {!!item.l3AisleID && (
                            <span style={{ display: 'inline' }}>
                                {' '}
                                -> {taxonomy?.l3?.find((tx) => tx.id === item.l3AisleID)?.name}
                            </span>
                        )}
                        {!!item.l4AisleID && (
                            <span style={{ display: 'inline' }}>
                                {' '}
                                -> {taxonomy?.l4?.find((tx) => tx.id === item.l4AisleID)?.name}
                            </span>
                        )}
                    </div>
                );
            })}
            <div>
                <Button
                    id={`EditAisles_${row.id}`}
                    onClick={() => {
                        setSelectedRow(row.original);
                        setEditAisleModalOpen(true);
                    }}
                >
                    EDIT
                </Button>
            </div>
        </div>
    );
};

let PhotoCell = ({ row, column, setPhotoModalOpen, setSelectedPhoto }) => {
    let key = column.id;
    if (row.original[key]) {
        return (
            <div>
                <Button
                    id={`${column.Header.replaceAll(' ', '')}_${row.id}`}
                    onClick={() => {
                        setSelectedPhoto({ item: row.original, key });
                        setPhotoModalOpen(true);
                    }}
                >
                    <img
                        src={`https://static.misfitsmarket.com/${row.original[key]}`}
                        style={{ height: '100px', width: '100px' }}
                    />
                </Button>
            </div>
        );
    } else {
        return (
            <div>
                <IconButton
                    id={`${column.Header.replaceAll(' ', '')}_${row.id}_upload`}
                    onClick={() => {
                        setSelectedPhoto({ item: row.original, key });
                        setPhotoModalOpen(true);
                    }}
                >
                    <CloudUploadIcon />
                </IconButton>
            </div>
        );
    }
};

// Defines the Prop65 table cell
let Prop65Cell = ({ row, setEditProp65ModalOpen, setSelectedRow }) => {
    return (
        <div>
            <span style={{ display: 'inline' }}>{row.original.prop65Warning ? 'Yes' : 'No'}</span>
            <Button
                id={`EditProp65_${row.id}`}
                onClick={() => {
                    setSelectedRow(row.original);
                    setEditProp65ModalOpen(true);
                }}
            >
                EDIT
            </Button>
        </div>
    );
};

// Modal for opening Prop 65
let EditProp65Modal = ({ selectedItem, setEditProp65ModalOpen, getItemInformation, setNotification }) => {
    let [rows, setRows] = useState([]);
    let [prop65Flag, setProp65Flag] = useState(selectedItem.prop65Warning);
    let [alcoholFlag, setAlcoholFlag] = useState(selectedItem.prop65WarningCategoryAlcohol);
    let [cannedFlag, setCannedFlag] = useState(selectedItem.prop65WarningCategoryCanned);
    let [foodFlag, setFoodFlag] = useState(selectedItem.prop65WarningCategoryFood);
    let [nonFoodFlag, setNonFoodFlag] = useState(selectedItem.prop65WarningCategoryNonFood);
    let [ingredients, setIngredients] = useState([]);
    let [selectedIngredient, setSelectedIngredient] = useState({});

    let getIngredients = async () => {
        let res = await api.getPropSixtyFiveIngredientsBySKU(selectedItem.externalSku);
        if (res.status === true) {
            setRows(res.data.rows);
        }
    };

    let getAllIngredients = async () => {
        let res = await api.getPropSixtyFiveIngredients();
        if (res.status === true) {
            let pleaseSelect = { name: 'Please Select', value: 0 };
            setIngredients([pleaseSelect].concat(res.data.rows));
        }
    };

    let finishedEditing = () => {
        setEditProp65ModalOpen(false);
        getItemInformation();
    };

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'name', Header: 'Name' },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        onClick={async () => {
                            let response = await api.removePropSixtyFiveIngredient(selectedItem.id, row.original.id);

                            if (response.status === false) {
                                setNotification({ text: "Can't remove item " + response.msg, level: 'error' });
                                return;
                            }

                            getIngredients();
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        getIngredients();
        getAllIngredients();
    }, []);

    useEffect(() => {
        setAlcoholFlag(selectedItem.prop65WarningCategoryAlcohol);
        setCannedFlag(selectedItem.prop65WarningCategoryCanned);
        setFoodFlag(selectedItem.prop65WarningCategoryFood);
        setNonFoodFlag(selectedItem.prop65WarningCategoryNonFood);
    }, [selectedItem]);

    return (
        <Dialog fullWidth={true} maxWidth="md" open={true} onClose={() => finishedEditing()}>
            <DialogTitle>Edit Prop 65 - External Sku: {selectedItem.externalSku}</DialogTitle>
            <DialogContent>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="Prop65Warning_checkbox"
                                onChange={async (event) => {
                                    setProp65Flag(event.target.checked);
                                    if (event.target.checked === false) {
                                        setAlcoholFlag(false);
                                        setCannedFlag(false);
                                        setFoodFlag(false);
                                        setNonFoodFlag(false);
                                    }
                                    let res = await api.updatePropSixtyFive(selectedItem.id, {
                                        prop65Warning: event.target.checked,
                                    });
                                    if (!res.status) {
                                        setNotification({
                                            level: 'error',
                                            text: `Failed to update prop65 flag: ${res.msg}`,
                                        });
                                        return;
                                    }
                                }}
                                color="primary"
                                defaultChecked={prop65Flag}
                            />
                        }
                        label="Prop65 Warning?"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="AlcoholCategory_checkbox"
                                onChange={async (event) => {
                                    setAlcoholFlag(event.target.checked);
                                    let res = await api.updatePropSixtyFive(selectedItem.id, {
                                        alcoholFlag: event.target.checked,
                                    });
                                    if (!res.status) {
                                        setNotification({
                                            level: 'error',
                                            text: `Failed to update alcohol flag: ${res.msg}`,
                                        });
                                        return;
                                    }
                                }}
                                color="primary"
                                defaultChecked={alcoholFlag}
                                checked={alcoholFlag}
                                disabled={!prop65Flag}
                            />
                        }
                        label="Alcohol Category"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                id="CannedBottledCategory_checkbox"
                                onChange={async (event) => {
                                    setCannedFlag(event.target.checked);
                                    let res = await api.updatePropSixtyFive(selectedItem.id, {
                                        cannedFlag: event.target.checked,
                                    });
                                    if (!res.status) {
                                        setNotification({
                                            level: 'error',
                                            text: `Failed to update canned flag: ${res.msg}`,
                                        });
                                        return;
                                    }
                                }}
                                color="primary"
                                defaultChecked={cannedFlag}
                                checked={cannedFlag}
                                disabled={!prop65Flag}
                            />
                        }
                        label="Canned/Bottled Category"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                id="FoodCategory_checkbox"
                                onChange={async (event) => {
                                    setFoodFlag(event.target.checked);
                                    let res = await api.updatePropSixtyFive(selectedItem.id, {
                                        foodFlag: event.target.checked,
                                    });
                                    if (!res.status) {
                                        setNotification({
                                            level: 'error',
                                            text: `Failed to update food flag: ${res.msg}`,
                                        });
                                        return;
                                    }
                                }}
                                color="primary"
                                defaultChecked={foodFlag}
                                checked={foodFlag}
                                disabled={!prop65Flag}
                            />
                        }
                        label="Food Category"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                id="NonFoodCategory_checkbox"
                                onChange={async (event) => {
                                    setNonFoodFlag(event.target.checked);
                                    let res = await api.updatePropSixtyFive(selectedItem.id, {
                                        nonFoodFlag: event.target.checked,
                                    });
                                    if (!res.status) {
                                        setNotification({
                                            level: 'error',
                                            text: `Failed to update nonFood flag: ${res.msg}`,
                                        });
                                        return;
                                    }
                                }}
                                color="primary"
                                defaultChecked={nonFoodFlag}
                                checked={nonFoodFlag}
                                disabled={!prop65Flag}
                            />
                        }
                        label="Non-Food Category"
                    />
                </div>
                <div>
                    <DataTable title="Ingredients" columns={columns} data={rows} />
                </div>
                <div>
                    <Button
                        id="AddIngredient"
                        onClick={async () => {
                            let res = await api.addPropSixtyFiveIngredient(selectedItem.id, selectedIngredient);
                            if (!res.status) {
                                setNotification({
                                    level: 'error',
                                    text: `Failed to add prop65 ingredient: ${res.msg}`,
                                });
                                return;
                            }
                            setNotification({ level: 'success', text: `Added ingredient` });
                            getIngredients();
                        }}
                    >
                        Add Ingredient
                    </Button>
                    <NativeSelect
                        id="Ingredients_select"
                        className="input-element-select"
                        value={selectedIngredient}
                        onChange={(event) => {
                            setSelectedIngredient(event.target.value);
                        }}
                        label="Ingredient"
                    >
                        {ingredients.map((opt) => {
                            return (
                                <option key={opt.id} value={opt.id}>
                                    {opt.name}
                                </option>
                            );
                        })}
                        ;
                    </NativeSelect>
                </div>
            </DialogContent>
            <DialogActions>
                <Button id="Done" onClick={() => finishedEditing()}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

let ItemsComponent = ({ tags, importedOrderSources }) => {
    let [itemInformation, setItemInformation] = useState([]);
    let [photoModalOpen, setPhotoModalOpen] = useState(false);
    let [selectedPhoto, setSelectedPhoto] = useState({});
    let [editAisleModalOpen, setEditAisleModalOpen] = useState(false);
    let [editProp65ModalOpen, setEditProp65ModalOpen] = useState(false);
    let [editPriceOverridesModalOpen, setEditPriceOverridesModalOpen] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});
    let [notification, setNotification] = useState({ text: null, level: null });
    let [taxonomy, setTaxonomy] = useState({ l1: [], l2: [], l3: [], l4: [] });
    let [activeOnly, setActiveOnly] = useState(true);
    let [segments, setSegments] = useState([]);
    let [facilities, setFacilities] = useState([]);

    let getItemInformation = async () => {
        let res = await api.getItemInformation(activeOnly);
        if (res.status === true) {
            setItemInformation(
                res.data.items.map((item) => {
                    item.id = item.externalSku;

                    // refresh selected aisle data if it exists
                    if (selectedRow && selectedRow.hasOwnProperty('externalSku')) {
                        if (selectedRow.externalSku === item.externalSku) {
                            setSelectedRow(item);
                        }
                    }

                    item.tags = item.tags.map((tag) => ({ ...tag, text: tag.name, value: tag.id }));

                    return item;
                })
            );
        }
    };

    let saveEdit = async ({ id, field, value }) => {
        let externalSku = id;
        let formData = new FormData();
        formData.append(field, value);
        formData.append('externalSku', externalSku);
        let res = await api.updateItemInformation(formData);
        if (res.status !== true) {
            setNotification({ text: res.msg ? res.msg : 'Error updating item', level: 'error' });
        }
        getItemInformation();
        return res.status;
    };

    let updateItemPhoto = async (type, file, externalSku) => {
        let formData = new FormData();
        formData.append(type + 'Photo', file);
        formData.append('externalSku', externalSku);
        let res = await api.updateItemInformation(formData);
        if (res.status === true) {
            getItemInformation();
        } else {
            setNotification({ text: 'Error uploading photo: ' + res.msg, level: 'error' });
        }
        return res.status;
    };

    let getTaxonomy = async () => {
        let promises = [1, 2, 3, 4].map((lvl) => api.getTaxonomyByLevel(lvl, 'AISLE'));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setTaxonomy({
                l1: res[0].data.categories,
                l2: res[1].data.categories,
                l3: res[2].data.categories,
                l4: res[3].data.categories,
            });
        }
    };

    let getSegments = async () => {
        let res = await api.getItemInformationSegments();
        if (res.status === true) {
            setSegments(res.data.rows);
        }
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (response.status === false) {
            return;
        }

        setFacilities(response.data.rows);
    };

    useEffect(() => {
        getTaxonomy();
        getSegments();
        getFacilities();
    }, []);

    useEffect(() => {
        getItemInformation();
    }, [activeOnly]);

    let columns = [
        { accessor: 'name', Header: 'Item Name', editable: true, editProps: { type: 'input', multiline: true } },
        { accessor: 'externalSku', Header: 'External Sku', editable: false },
        { accessor: 'ifNetsuiteID', Header: '(IF) Netsuite ID', editable: true },
        { accessor: 'dbiOnly', Header: 'Fulfillable by DBI only', type: 'checkbox', editable: true },
        { Header: 'Type', accessor: 'type' },
        {
            accessor: 'description',
            Header: 'Description',
            editable: true,
            editProps: { type: 'input', multiline: true },
            width: '100px',
        },
        {
            accessor: 'photoURLSmall',
            width: '125px',
            Header: 'Product Photo',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'photoURLNutritionalInfo',
            width: '100px',
            Header: 'Nutrition',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        { accessor: 'allergens', Header: 'Allergens', editable: true, editProps: { type: 'input', multiline: true } },
        {
            accessor: 'crossAllergens',
            Header: 'Cross allergens (may contain)',
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        {
            accessor: 'ingredients',
            Header: 'Ingredients',
            width: '150px',
            editable: true,
            editProps: { type: 'input', multiline: true },
        },
        { accessor: 'brand', Header: 'Brand', editable: true },
        {
            accessor: 'tags',
            Header: 'Tags',
            editable: true,
            filterValues: (row) => row.original.tags.map((tag) => tag.name),
            editProps: {
                type: 'autocomplete-multiple-chips',
                options: tags.map((item) => ({ text: item.name, value: item.id })),
                modalHeader: (row) => `Add Tags to Item ${row.name}`,
                addButtonText: 'Add Tags',
                addItem: async ({ values, row }) => {
                    let res = await api.createItemInformationTagMappings(values, row.externalSku);
                    if (res.status === true) {
                        let itemInfos = [...itemInformation].map((itemInfo) => {
                            if (itemInfo.externalSku === row.externalSku) {
                                let selectedTagObjs = tags.filter((tag) => values.includes(tag.id));
                                itemInfo.tags = itemInfo.tags
                                    .concat(selectedTagObjs)
                                    .map((tag) => ({ ...tag, text: tag.name, value: tag.id }));
                            }
                            return itemInfo;
                        });
                        setItemInformation(itemInfos);
                    } else {
                        setNotification({ level: 'error', text: res.msg });
                    }
                    return true;
                },
                removeItem: async ({ item, row }) => {
                    let res = await api.removeItemInformationTagMapping(item.id, row.externalSku);
                    if (res.status === true) {
                        let itemInfos = [...itemInformation].map((itemInfo) => {
                            if (itemInfo.externalSku === row.externalSku) {
                                itemInfo.tags = itemInfo.tags.filter((tag) => tag.id !== item.id);
                            }
                            return itemInfo;
                        });
                        setItemInformation(itemInfos);
                    }
                },
            },
        },
        {
            Header: 'Aisles',
            width: '200px',
            Cell: ({ row, saveEdit, column }) => AislesCell({ row, taxonomy, setSelectedRow, setEditAisleModalOpen }),
        },
        {
            accessor: 'photoTwoURLSmall',
            width: '125px',
            Header: 'Product Photo 2',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'photoThreeURLSmall',
            width: '125px',
            Header: 'Product Photo 3',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'photoFourURLSmall',
            width: '125px',
            Header: 'Product Photo 4',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'photoFiveURLSmall',
            width: '125px',
            Header: 'Product Photo 5',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, setPhotoModalOpen }),
        },
        {
            accessor: 'msrpPrice',
            Header: 'Base Price',
            editable: true,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
        },
        { accessor: 'vanityPadName', Header: 'Vanity Pad Name', editable: true },
        {
            Header: 'Prop65',
            width: '150px',
            Cell: ({ row, saveEdit, column }) => Prop65Cell({ row, setEditProp65ModalOpen, setSelectedRow }),
        },
        { accessor: 'segment', Header: 'Segment', editable: true, editProps: { type: 'select', options: segments } },
        {
            accessor: 'priceOverrides',
            Header: 'Price Overrides',
            filterValues: (row) => row.original.priceOverrides.map((item) => `${item.facility} - ${item.defaultPrice}`),
            Cell: ({ row }) => PriceOverridesCell({ row, setSelectedRow, setEditPriceOverridesModalOpen }),
        },
        {
            accessor: 'priceSensitive',
            Header: 'Price Sensitive',
            type: 'checkbox',
            editable: true,
            editProps: { type: 'checkbox' },
        },
        { accessor: 'lOneTaxonomy', Header: 'L1 Taxonomy' },
        { accessor: 'lTwoTaxonomy', Header: 'L2 Taxonomy' },
        { accessor: 'lThreeTaxonomy', Header: 'L3 Taxonomy' },
        { accessor: 'lFourTaxonomy', Header: 'L4 Taxonomy' },
        { accessor: 'maxQuantity', Header: 'Max Quantity', editable: true },
        { accessor: 'headSku', Header: 'Head Sku', type: 'checkbox', editable: true, editProps: { type: 'checkbox' } },
        {
            accessor: 'acquisitionPdp',
            Header: 'Acquisition  PDP',
            type: 'checkbox',
            editable: true,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'importedOrderSourceID',
            Header: 'FAM Partner',
            editable: true,
            editProps: {
                type: 'select',
                options: [
                    { id: null, name: '' },
                    ...importedOrderSources.map((item) => ({ id: item.id, name: item.name })),
                ],
            },
        },
    ];
    return (
        <Grid container>
            <Notifications options={notification} />
            {photoModalOpen && (
                <PhotoModal
                    setPhotoModalOpen={setPhotoModalOpen}
                    selectedPhoto={selectedPhoto}
                    updateItemPhoto={updateItemPhoto}
                />
            )}
            {editAisleModalOpen && (
                <EditAisleModal
                    setEditAisleModalOpen={setEditAisleModalOpen}
                    selectedAisle={selectedRow}
                    taxonomy={taxonomy}
                    getItemInformation={getItemInformation}
                    setNotification={setNotification}
                />
            )}
            {editProp65ModalOpen && (
                <EditProp65Modal
                    setEditProp65ModalOpen={setEditProp65ModalOpen}
                    selectedItem={selectedRow}
                    getItemInformation={getItemInformation}
                    setNotification={setNotification}
                />
            )}
            {editPriceOverridesModalOpen && (
                <EditPriceOverridesModal
                    setOpen={setEditPriceOverridesModalOpen}
                    setNotification={setNotification}
                    selectedRow={selectedRow}
                    getItemInformation={getItemInformation}
                    facilities={facilities}
                />
            )}
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="ActiveOnly_checkbox"
                            onChange={(event) => {
                                setActiveOnly(event.target.checked);
                            }}
                            color="primary"
                            defaultChecked={activeOnly}
                        />
                    }
                    label="Active Only"
                />
            </Grid>
            <DataTable
                editable={true}
                title="Item Information"
                columns={columns}
                data={itemInformation}
                saveEdit={saveEdit}
            />
        </Grid>
    );
};

let AddTagModal = ({ setAddTagModalOpen, createTag }) => {
    let [name, setName] = useState('');
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={true}
            onClose={() => {
                setAddTagModalOpen(false);
            }}
        >
            <DialogTitle>Add New Item Tag</DialogTitle>
            <DialogContent>
                <TextField
                    id="Name_textField"
                    label="Name"
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    id="Confirm"
                    onClick={async () => {
                        await createTag(name);
                        setAddTagModalOpen(false);
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

let TagsComponent = ({ tags, setTags, getTags }) => {
    let [addTagModalOpen, setAddTagModalOpen] = useState(false);
    let [notification, setNotification] = useState({ text: null, level: null });

    let createTag = async (name) => {
        let res = await api.createItemInformationTag(name);
        if (res.status === true) {
            getTags();
        } else {
            setNotification({ text: 'Failed to create tag', level: 'error' });
        }
    };

    let columns = [
        { accessor: 'id', Header: 'id', editable: false },
        { accessor: 'name', Header: 'Name', editable: false },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            {addTagModalOpen === true && <AddTagModal createTag={createTag} setAddTagModalOpen={setAddTagModalOpen} />}
            <DataTable
                columns={columns}
                data={tags}
                toolbarActions={[
                    {
                        name: 'Add Tag',
                        action: () => {
                            setAddTagModalOpen(true);
                        },
                    },
                ]}
            />
        </Grid>
    );
};

export { ItemInformation };
