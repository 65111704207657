import React, { useEffect, useState } from 'react';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import './pagination.css';

const PartnershipsPagination = ({ count, rowsPerPage, page, onChangePage }) => {
    const [minItem, setMinItem] = useState(0);
    const [maxItem, setMaxItem] = useState(0);

    useEffect(() => {
        setMinItem(page * rowsPerPage + 1);
        setMaxItem(page * rowsPerPage + rowsPerPage > count ? count : page * rowsPerPage + rowsPerPage);

        let maxPage = Math.ceil(count / rowsPerPage) - 1;
        if (maxPage < 0) {
            maxPage = 0;
        }
        if (count < minItem) {
            onChangePage(maxPage);
        }
    }, [rowsPerPage, page, count]);

    if (count <= 0) {
        return null;
    }
    return (
        <div className="partnerships-pagination-container">
            <div
                className={`partnerships-pagination-item clickable ${page <= 0 && 'visibility-hidden'}`}
                onClick={() => {
                    onChangePage(page - 1);
                }}
            >
                <KeyboardArrowLeftRoundedIcon className="partnerships-pagination-icon" />
            </div>

            <div className="partnerships-pagination-item">
                &nbsp; &nbsp;
                {minItem} to {maxItem} of {count}
                &nbsp; &nbsp;
            </div>

            <div
                className={`partnerships-pagination-item clickable ${
                    page * rowsPerPage + rowsPerPage >= count && 'visibility-hidden'
                }`}
                onClick={() => {
                    onChangePage(page + 1);
                }}
            >
                <KeyboardArrowRightRoundedIcon className="partnerships-pagination-icon" />
            </div>
        </div>
    );
};

export { PartnershipsPagination };
