import React, { useState, useEffect, useContext } from 'react';
import { packagingApi } from '../../../api/packaging';
import { FormComponent, Notifications } from '../../../shared';
import { UserContext } from '../../../contexts/UserContext';
import { fbmApi as api } from '../../../api/fbm';

const CreateFBMPackaging = ({ typeOpts }) => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [boxOpts, setBoxOpts] = useState([]);
    const { fbmAccount } = useContext(UserContext);

    let [showBoxSize, setShowBoxSize] = useState(true);

    let getBoxes = async () => {
        let response = await packagingApi.getBoxes(fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No Boxes found ' + response.msg, level: 'error' });
            setBoxOpts([]);
            return;
        }

        let boxOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setBoxOpts(boxOpts);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getBoxes();
        }
    }, [fbmAccount]);

    let formFields = [
        {
            name: 'sku',
            inputElement: 'textField',
            label: 'Partner SKU',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'itemNameInternal',
            inputElement: 'textField',
            label: 'Name',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'type',
            inputElement: 'select',
            label: 'Type',
            gridValue: 3,
            inputProps: {
                required: true,
                opts: typeOpts,
                onChange: (e) => {
                    setShowBoxSize(e.target.value !== 'box');
                },
            },
        },
        {
            name: 'description',
            inputElement: 'textField',
            label: 'Description',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'csPallet',
            inputElement: 'textField',
            label: 'Cases per Pallet',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'unitsPerCase',
            inputElement: 'textField',
            label: 'Units per Case',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'cubeLevelID',
            inputElement: 'select',
            label: 'Box Size',
            gridValue: 3,
            display: showBoxSize,
            inputProps: { opts: boxOpts, required: showBoxSize },
        },
        {
            name: 'lengthInches',
            inputElement: 'textField',
            label: 'Length (in)',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'widthInches',
            inputElement: 'textField',
            label: 'Width (in)',
            gridValue: 3,
            inputProps: { required: true },
        },
        {
            name: 'heightInches',
            inputElement: 'textField',
            label: 'Height (in)',
            gridValue: 3,
            inputProps: { required: true },
        },
    ];

    return (
        <div>
            <h1>Create FBM Packaging</h1>
            <Notifications options={notification} />
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    let res = await api.createPackaging(formData, fbmAccount.fbmAccountName);
                    if (res.status === false) {
                        let message = 'Error creating packaging';
                        if (res) {
                            message += ': ' + res.msg;
                        }
                        setNotification({ text: message, level: 'error' });
                    } else {
                        setNotification({ text: 'Packaging created!', level: 'success' });
                        resetForm();
                    }
                }}
            />
        </div>
    );
};

export default CreateFBMPackaging;
