import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { Alert } from '@material-ui/lab';
import { UserContext } from '../../../contexts/UserContext';
import { InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { ViewLocationsInZone } from './viewLocationsInZone.js';

let BulkCreateLocations = ({ v2Locations }) => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [tempZones, setTempZones] = useState([]);
    let [types, setTypes] = useState([]);
    let [creatingType, setCreatingType] = useState({});
    let [reservePrefix, setReservePrefix] = useState('');
    let [columns, setColumns] = useState([]);

    let [toolbarActions, setToolbarActions] = useState([]); //
    let [createResults, setCreateResults] = useState(null);
    let [createLocProgress, setCreateLocProgress] = useState({});
    let [zone, setZone] = useState('');
    let [viewNewAndExistingLocationsInZone, setViewNewAndExistingLocationsInZone] = useState(false);

    let [locationsCreatedResults, setLocationsCreatedResults] = useState(null);

    const user = useContext(UserContext);
    const startsAtEndsAtTypes = ['QC', 'STAGING', 'CART', 'TRANSFER', 'FORKLIFT', 'DROP ZONE', 'REPLENISHMENT'];
    const zoneAisleBayTypes = ['RESERVE'];

    let displayFormField = (accessor, formData) => {
        if (!formData.type?.value) {
            return false;
        }
        if (!creatingType.type) {
            return false;
        }
        if (Object.keys(creatingType.staticValues).includes(accessor)) {
            return false;
        }

        /**
         * Max Pallets should only be displayed if inventory type is Pallet
         */
        if (accessor === 'maxPallets' && formData.inventoryType === 'Case') {
            return false;
        }

        if (accessor === 'multipleSku' && formData.multipleLot === 'NO') {
            return false;
        }

        if (accessor === 'tempZone' && zoneAisleBayTypes.includes(formData.type?.value)) {
            return false;
        }

        return true;
    };

    let setResultsColumnsByType = (type) => {
        if (startsAtEndsAtTypes.includes(type)) {
            setColumns([...resultsColumns, ...numberRangeColumns, { Header: 'Errors', accessor: 'errors' }]);
        } else if (zoneAisleBayTypes.includes(type)) {
            setColumns([...resultsColumns, ...zoneAisleBayColumns, { Header: 'Errors', accessor: 'errors' }]);
        }
    };

    let resultsColumns = [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Type', accessor: 'type' },
        { Header: 'Temp Zone', accessor: 'tempZone' },
        { Header: 'OG/CV', accessor: 'productionMethod' },
        { Header: 'Sellable', accessor: 'usable', type: 'checkbox' },
        { Header: 'Inventory Type', accessor: 'inventoryType' },
        { Header: 'Max Pallets', accessor: 'maxPallets' },
        { Header: 'Multiple Lot', accessor: 'multipleLot', type: 'checkbox' },
        { Header: 'Multiple Sku', accessor: 'multipleSku', type: 'checkbox' },
    ];

    let zoneAisleBayColumns = [
        { Header: 'Zone', accessor: 'zone' },
        { Header: 'Aisle', accessor: 'aisle' },
        { Header: 'Bay', accessor: 'bay' },
        { Header: 'Shelf', accessor: 'shelf' },
        { Header: 'Position', accessor: 'position' },
        { Header: 'Errors', accessor: 'errors' },
    ];
    let numberRangeColumns = [{ Header: 'Errors', accessor: 'errors' }];

    let numberRangeFormFields = [
        {
            name: 'startAt',
            label: 'Start At',
            gridValue: 6,
            inputElement: 'textField',
            InputProps: {
                startAdornment: (
                    <InputAdornment position="start">
                        <span>{creatingType.prefix}</span>
                    </InputAdornment>
                ),
            },
            inputProps: { required: true, type: 'number', integer: true },
            display: (formData) => startsAtEndsAtTypes.includes(formData.type?.value),
        },
        {
            name: 'endAt',
            label: 'End At',
            gridValue: 6,
            inputElement: 'textField',
            InputProps: {
                startAdornment: (
                    <InputAdornment position="start">
                        <span>{creatingType.prefix}</span>
                    </InputAdornment>
                ),
            },
            inputProps: { type: 'number', integer: true },
            display: (formData) => startsAtEndsAtTypes.includes(formData.type?.value),
        },
    ];

    let zoneAisleBayShelfPositionFormFields = [
        {
            name: 'tempZoneReserve',
            inputElement: 'select',
            label: 'Temp Zone',
            gridValue: 12,
            display: (formData) => formData.type?.value === 'RESERVE',
            required: true,
            inputProps: {
                required: true,
                // opts: tempZones,
                opts: tempZones.filter((opt) => opt !== 'All'),
                onChange: (e) => {
                    let val = e.target.value;
                    if (val === 'Ambient (>55)') {
                        setReservePrefix('AB');
                    } else if (val === 'Frozen (0-31)') {
                        setReservePrefix('FZ');
                    } else if (val === 'Refrigerated (36-40)') {
                        setReservePrefix('RF');
                    } else if (val === 'Nightshade (50-55)') {
                        setReservePrefix('NS');
                    } else {
                        setReservePrefix('');
                    }
                },
            },
        },
        {
            name: 'zone',
            label: 'Zone (Integer)',
            gridValue: 8,
            inputElement: 'textField',
            inputProps: { required: true, type: 'number', integer: true, max: '100' },
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'viewAisles',
            ignoreOnSubmit: true,
            gridValue: 4,
            display: (formData) => !!formData.zone,
            inputElement: () => {
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    setViewNewAndExistingLocationsInZone(event.target.checked);
                                }}
                                color="primary"
                                checked={viewNewAndExistingLocationsInZone}
                            />
                        }
                        label="View Locations In Zone"
                    />
                );
            },
        },
        {
            name: 'aislesHeader',
            ignoreOnSubmit: true,
            inputElement: () => <h5 style={{ textAlign: 'left', padding: 0, margin: 0 }}>Aisles (A-Z)</h5>,
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'aisleStart',
            label: 'Start at Aisle',
            gridValue: 4,
            inputElement: 'textField',
            inputProps: { required: true },
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'aisleEnd',
            label: 'End at Aisle',
            gridValue: 4,
            inputElement: 'textField',
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'aislesHint',
            ignoreOnSubmit: true,
            gridValue: 4,
            display: () => createLocProgress.hasOwnProperty('aisles') && !createLocProgress?.errors?.length,
            inputElement: () => (
                <div style={{ fontSize: '13px' }}>
                    <div>
                        {createLocProgress.aisles.range.length} aisles in range:{' '}
                        {createLocProgress.aisles.range.join(', ')}
                    </div>
                    {createLocProgress.aisles.new.length > 0 && (
                        <div>{createLocProgress.aisles.new.length} New Aisles</div>
                    )}
                    {createLocProgress.aisles.existing.length > 0 && (
                        <div>{createLocProgress.aisles.existing.length} Existing Aisles</div>
                    )}
                </div>
            ),
        },
        {
            name: 'bayHeader',
            ignoreOnSubmit: true,
            inputElement: () => <h5 style={{ textAlign: 'left', padding: 0, margin: 0 }}>Bays (Integers)</h5>,
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'bayStart',
            label: 'Start at Bay',
            gridValue: 4,
            inputElement: 'textField',
            inputProps: { required: true, type: 'number', integer: true },
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'bayEnd',
            label: 'End at Bay',
            gridValue: 4,
            inputElement: 'textField',
            inputProps: { type: 'number', integer: true },
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'baysHint',
            ignoreOnSubmit: true,
            gridValue: 4,
            display: () => createLocProgress.hasOwnProperty('bays') && !createLocProgress?.errors?.length,
            inputElement: () => (
                <div style={{ fontSize: '13px' }}>
                    <div>
                        {createLocProgress.bays.range.length} bays in range {createLocProgress.bays.range.join(', ')}
                    </div>
                    {createLocProgress.bays.new.length > 0 && <div>{createLocProgress.bays.new.length} New Bays</div>}
                    {createLocProgress.bays.existing.length > 0 && (
                        <div>{createLocProgress.bays.existing.length} Existing Bays</div>
                    )}
                </div>
            ),
        },
        {
            name: 'shelfHeader',
            ignoreOnSubmit: true,
            inputElement: () => <h5 style={{ textAlign: 'left', padding: 0, margin: 0 }}>Shelves (A-Z)</h5>,
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'shelfStart',
            label: 'Start at Shelf',
            gridValue: 4,
            inputElement: 'textField',
            inputProps: { required: true },
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'shelfEnd',
            label: 'End at Shelf',
            gridValue: 4,
            inputElement: 'textField',
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'shelvesHint',
            ignoreOnSubmit: true,
            gridValue: 4,
            display: () => createLocProgress.hasOwnProperty('shelves') && !createLocProgress?.errors?.length,
            inputElement: () => (
                <div style={{ fontSize: '13px' }}>
                    <div>
                        {createLocProgress.shelves.range.length} shelves in range:{' '}
                        {createLocProgress.shelves.range.join(', ')}
                    </div>
                    {createLocProgress.shelves.new.length > 0 && (
                        <div>{createLocProgress.shelves.new.length} New Shelves</div>
                    )}
                    {createLocProgress.shelves.existing.length > 0 && (
                        <div>{createLocProgress.shelves.existing.length} Existing Shelves</div>
                    )}
                </div>
            ),
        },
        {
            name: 'postionHeader',
            ignoreOnSubmit: true,
            inputElement: () => <h5 style={{ textAlign: 'left', padding: 0, margin: 0 }}>Positions (Integers)</h5>,
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'positionStart',
            label: 'Start at Position',
            gridValue: 4,
            inputElement: 'textField',
            inputProps: { required: true, type: 'number', integer: true },
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'positionEnd',
            label: 'End at Position',
            gridValue: 4,
            inputElement: 'textField',
            inputProps: { type: 'number', integer: true },
            display: (formData) => zoneAisleBayTypes.includes(formData.type?.value),
        },
        {
            name: 'positionsHint',
            ignoreOnSubmit: true,
            gridValue: 4,
            display: () => createLocProgress.hasOwnProperty('positions') && !createLocProgress?.errors?.length,
            inputElement: () => (
                <div style={{ fontSize: '13px' }}>
                    <div>
                        {createLocProgress.positions.range.length} positions in range:{' '}
                        {createLocProgress.positions.range.join(', ')}
                    </div>
                    {createLocProgress.positions.new.length > 0 && (
                        <div>{createLocProgress.positions.new.length} New Positions</div>
                    )}
                    {createLocProgress.positions.existing.length > 0 && (
                        <div style={{ color: 'red' }}>
                            {createLocProgress.positions.existing.length} Existing Positions
                        </div>
                    )}
                </div>
            ),
        },
        {
            name: 'locationErrors',
            ignoreOnSubmit: true,
            gridValue: 12,
            display: () => createLocProgress?.errors?.length > 0,
            inputElement: () => (
                <div>
                    Errors found in provided ranges
                    {createLocProgress.errors.map((err) => {
                        return <Alert severity="error">{err}</Alert>;
                    })}
                </div>
            ),
        },
    ];

    let formFields = [
        {
            name: 'type',
            inputElement: 'autocomplete',
            label: 'Type',
            gridValue: 12,
            inputProps: {
                required: true,
                opts: types
                    .filter((item) => {
                        if (v2Locations && item.creatableV2) {
                            return true;
                        } else if (!v2Locations && item.creatableV1) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                    .map((item) => item.type),
            },
        },

        // fields that control the number of locations that will be created
        ...numberRangeFormFields,
        ...zoneAisleBayShelfPositionFormFields,

        {
            name: 'name',
            inputElement: 'textField',
            label: 'Name',
            gridValue: 12,
            display: !!creatingType.type && creatingType.creatableV1 && creatingType.type !== 'TRANSFER',
            inputProps: { required: true },
        },

        // attributes that will apply to all bulk-created locations
        {
            // only display this if any of the attributes can be set
            name: 'attributeHint',
            ignoreOnSubmit: true,
            display: () =>
                !!creatingType.type &&
                creatingType.creatableV2 &&
                ![
                    'usable',
                    'tempZone',
                    'productionMethod',
                    'inventoryType',
                    'maxPallets',
                    'multipleLot',
                    'multipleSku',
                ].every((field) => Object.keys(creatingType.staticValues).includes(field)),
            inputElement: () => (
                <>
                    <h4 style={{ textAlign: 'left' }}>Location Attributes</h4>
                    <h5 style={{ textAlign: 'left' }}>These will apply to all locations created</h5>
                </>
            ),
        },
        {
            name: 'usable',
            inputElement: 'select',
            label: 'Sellable',
            gridValue: 12,
            display: (formData) => displayFormField('usable', formData),
            required: true,
            inputProps: {
                required: true,
                opts: ['YES', 'NO'],
            },
        },
        {
            name: 'tempZone',
            inputElement: 'select',
            label: 'Temp Zone',
            gridValue: 12,
            display: (formData) => displayFormField('tempZone', formData),
            required: true,
            inputProps: {
                required: true,
                opts: tempZones,
            },
        },
        {
            name: 'productionMethod',
            inputElement: 'select',
            label: 'OG/CV',
            gridValue: 12,
            display: (formData) => displayFormField('productionMethod', formData),
            required: true,
            inputProps: {
                required: true,
                opts: [
                    { text: 'Both', value: 'BOTH' },
                    { text: 'Conventional', value: 'CONVENTIONAL' },
                    { text: 'Organic', value: 'ORGANIC' },
                ],
            },
        },
        {
            name: 'inventoryType',
            inputElement: 'select',
            label: 'Inventory Type',
            gridValue: 12,
            required: true,
            display: (formData) => displayFormField('inventoryType', formData),
            inputProps: {
                required: true,
                opts: ['Pallet', 'Case'],
            },
        },
        {
            name: 'maxPallets',
            inputElement: 'textField',
            label: 'Max Pallets',
            gridValue: 12,
            required: true,
            display: (formData) => displayFormField('maxPallets', formData),
            inputProps: {
                type: 'number',
                integer: true,
            },
        },
        {
            name: 'multipleLot',
            inputElement: 'select',
            label: 'Multiple Lot',
            gridValue: 12,
            required: true,
            display: (formData) => displayFormField('multipleLot', formData),
            inputProps: {
                required: true,
                opts: ['YES', 'NO'],
            },
        },
        {
            name: 'multipleSku',
            inputElement: 'select',
            label: 'Multiple Sku',
            gridValue: 12,
            required: true,
            display: (formData) => displayFormField('multipleSku', formData),
            inputProps: {
                required: true,
                opts: ['YES', 'NO'],
            },
        },
    ];

    let confirmLocations = async (key) => {
        if (!key) {
            setNotification({ text: 'No key provided for location creation', level: 'error' });
            return;
        }

        let res = await api.confirmLocationCreation(key);
        if (!res.status) {
            setNotification({ text: 'Failed to create locations ' + res.msg, level: 'error' });
            return;
        }

        setLocationsCreatedResults(res.data.locations);
    };

    const getNewAndExistingLocations = async (formData) => {
        if (!formData.zone) {
            return;
        }
        let sendParams = {
            facilityID: user.getFacilityID(),
            zone: formData.zone,
            zoneType: formData.type?.value,
            aisleStart: formData.aisleStart,
            aisleEnd: formData.aisleEnd,
            bayStart: formData.bayStart,
            bayEnd: formData.bayEnd,
            shelfStart: formData.shelfStart,
            shelfEnd: formData.shelfEnd,
            positionStart: formData.positionStart,
            positionEnd: formData.positionEnd,
            tempZone: formData.tempZoneReserve,
        };

        let res = await api.getNewAndExistingLocations(sendParams);
        if (!res.status) {
            return;
        }

        setCreateLocProgress(res.data.results);
    };

    let create = async (formData, resetForm) => {
        let sendParams = {
            name: formData.name,
            type: formData.type,
            facilityID: user.getFacilityID(),
            usable: formData.usable === 'YES' ? true : false,
            tempZone: formData.type === 'RESERVE' ? formData.tempZoneReserve : formData.tempZone,
            productionMethod: formData.productionMethod,
            cartPickable: false,
            inventoryType: formData.inventoryType,
            maxPallets: formData.inventoryType === 'Case' ? 0 : formData.maxPallets,
            multipleLot: formData.multipleLot === 'YES' ? true : false,
            multipleSku: formData.multipleLot === 'NO' ? false : formData.multipleSku === 'YES' ? true : false,
        };

        if (startsAtEndsAtTypes.includes(formData.type)) {
            sendParams['startAt'] = formData.startAt;
            sendParams['endAt'] = formData.endAt;
        }

        if (zoneAisleBayTypes.includes(formData.type)) {
            sendParams['zone'] = formData.zone;
            sendParams['aisleStart'] = formData.aisleStart;
            sendParams['aisleEnd'] = formData.aisleEnd;
            sendParams['bayStart'] = formData.bayStart;
            sendParams['bayEnd'] = formData.bayEnd;
            sendParams['shelfStart'] = formData.shelfStart;
            sendParams['shelfEnd'] = formData.shelfEnd;
            sendParams['positionStart'] = formData.positionStart;
            sendParams['positionEnd'] = formData.positionEnd;
        }
        let res = await api.createLocationV2(sendParams);
        if (!res.status) {
            setNotification({ text: 'Failed to create locations. ' + res.msg, level: 'error' });
            return;
        }

        if (creatingType.creatableV1 && creatingType.type !== 'TRANSFER') {
            if (res.status === true) {
                setNotification({ text: 'Location created!', level: 'success' });
                resetForm();
            } else {
                let message = 'Error creating location';
                if (res) {
                    message += ': ' + res.msg;
                }
                setNotification({ text: message, level: 'error' });
            }
        } else if (creatingType.creatableV2) {
            let data = res.data;

            let commonAttributes = res.data.attributes;
            let results = res.data.rows.map((row) => {
                let item = {
                    ...commonAttributes,
                    ...row,
                };
                if (res.data.existingLocations.includes(row.name)) {
                    item.errors.push('Location already exists');
                }
                item.errors = item.errors.join(', ');
                return item;
            });
            data.results = results;

            let actions = [
                {
                    name: 'Cancel',
                    action: () => {
                        setToolbarActions([]);
                        setCreateResults(null);
                    },
                },
            ];

            if (!data.error && data.results.length > 0) {
                actions.push({
                    name: 'Confirm Locations',
                    action: async () => {
                        let key = data.key;
                        await confirmLocations(key);
                    },
                });
            }

            if (data.errors) {
                // add an alert for each error
            }

            if (!data.results.length && !data.error) {
                data.errors.push('No new locations to create in given ranges');
            }

            console.log('data', data);

            setCreateResults(data);
            setToolbarActions(actions);
        }
    };

    const getTempZones = async () => {
        let res = await api.getLocationTempZones();
        if (!res.status) {
            setNotification({ text: 'Failed to get location temp zones', level: 'error' });
            return;
        }

        setTempZones(res.data.tempZones);
    };

    const getTypes = async () => {
        let res = await api.getLocationTypesV2(user.getFacilityID());
        if (!res.status) {
            setNotification({ text: 'Failed to get location types', level: 'error' });
            return;
        }

        setTypes(res.data.types);
    };

    const resetState = () => {
        setLocationsCreatedResults(null);
        setCreateResults(null);
        setToolbarActions([]);
        setCreatingType({});
        setCreateLocProgress({});
        setViewNewAndExistingLocationsInZone(false);
    };

    useEffect(() => {
        getTempZones();
        getTypes();
    }, []);

    return (
        <div>
            <h3>
                Create Locations
                {!!createResults && !locationsCreatedResults && ` - Review`}
            </h3>
            <Notifications options={notification} />
            {viewNewAndExistingLocationsInZone && (
                <ViewLocationsInZone
                    creatingTypePrefix={reservePrefix}
                    createLocProgress={createLocProgress}
                    facilityID={user.getFacilityID()}
                    zone={zone}
                    zoneType={creatingType.type}
                />
            )}
            {!locationsCreatedResults && (
                <div style={{ outline: 0, backgroundColor: 'white', display: !!createResults ? 'none' : undefined }}>
                    <FormComponent
                        compact={true}
                        formFields={formFields}
                        onSubmit={async (formData, resetForm) => {
                            create(formData, resetForm);
                        }}
                        button={{
                            disabled: () => createLocProgress?.errors?.length > 0,
                        }}
                        onFormDataChange={(formData) => {
                            let type = formData.type?.value;
                            if (type) {
                                setCreatingType(types.find((item) => item.type === type));
                                setResultsColumnsByType(type);
                            } else {
                                setCreatingType({});
                            }

                            if (type === 'RESERVE') {
                                getNewAndExistingLocations(formData);
                                setZone(formData.zone);
                            }
                        }}
                    />
                </div>
            )}
            {!!locationsCreatedResults && (
                <>
                    <Alert severity="success">Locations Created!</Alert>
                    <DataTable
                        columns={columns}
                        toolbarActions={[
                            {
                                name: 'Download All Labels',
                                action: () => {
                                    let ids = locationsCreatedResults.map((item) => item.id);
                                    api.bulkDownloadLocationLabels(ids);
                                },
                            },
                            {
                                name: 'Done',
                                action: () => {
                                    resetState();
                                },
                            },
                        ]}
                        data={locationsCreatedResults}
                    />
                </>
            )}
            {!!createResults && !locationsCreatedResults && (
                <>
                    {createResults.error ? (
                        <>
                            {createResults.errors.map((item, ind) => (
                                <Alert key={`${ind}`} severity="error">
                                    {item}
                                </Alert>
                            ))}
                            {createResults.existingLocations.length > 0 && (
                                <Alert severity="error">
                                    Locations already exist: {createResults.existingLocations.join(', ')}
                                </Alert>
                            )}
                        </>
                    ) : (
                        <Alert severity="success">
                            Confirming will create {createResults.results.length} new {creatingType.type} locations.
                        </Alert>
                    )}
                    {createResults.warnings.length > 0 && (
                        <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                            <div>{createResults.warnings.length} Warnings</div>
                            {createResults.warnings.map((item, ind) => (
                                <Alert key={`${ind}`} severity="warning">
                                    {item}
                                </Alert>
                            ))}
                        </div>
                    )}
                    {
                        <DataTable
                            columns={columns}
                            toolbarActions={toolbarActions}
                            data={createResults.results}
                            rowStyle={(row) => {
                                if (row.errors.length) {
                                    return { color: 'red', border: '3px solid red' };
                                }
                                return {};
                            }}
                        />
                    }
                </>
            )}
        </div>
    );
};

export { BulkCreateLocations };
