import React, { useState, useEffect } from 'react';
import { Notifications, DataTable } from '../../../shared';
import { marketingApi as api } from '../../../api/marketing';
import moment from 'moment';

const AcquisitionPDPs = () => {
    const [pdps, setPdps] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [generatingPDPs, setGeneratingPDPs] = useState(false);

    useEffect(() => {
        getPDPs();
    }, []);

    const getPDPs = async () => {
        let res = await api.getAcquisitionPDPs();
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }
        setPdps(res.data.results);
    };

    const generatePDPs = async () => {
        setGeneratingPDPs(true);
        setNotification({ text: 'Refreshing PDP data. This may take a minute or two.', level: 'info' });
        let res = await api.generateAcquisitionPDPs();
        setGeneratingPDPs(false);
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }

        setNotification({ text: 'Success', level: 'success' });
        getPDPs();
    };

    const columns = [
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: 'Brand', accessor: 'brand' },
        { Header: 'Name', accessor: 'name' },
        {
            Header: 'Link to PDP',
            Cell: ({ row }) => {
                return (
                    <a href={`https://www.misfitsmarket.com/${row.original.slug}/p`} target="_blank">
                        View PDP
                    </a>
                );
            },
        },
    ];

    const toolbarActions = [
        {
            name: 'Refresh PDP Data',
            action: generatePDPs,
            disabled: generatingPDPs,
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            <h1>Acquisition PDPs</h1>
            {pdps.length > 0 && !!pdps[0]?.createdAt?.date && (
                <h4>
                    PDP data last updated{' '}
                    {moment(pdps[0].createdAt.date + pdps[0].createdAt.timezone)
                        .local()
                        .format('MM/DD/YYYY h:mm a')}
                </h4>
            )}
            <DataTable columns={columns} data={pdps} toolbarActions={toolbarActions} />
        </>
    );
};

export { AcquisitionPDPs };
