import React, { useContext, useState, useEffect } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { DataTable, Notifications } from '../../../../shared';
import { UserContext } from '../../../../contexts/UserContext';
import { fbmApi as api } from '../../../../api/fbm';
import moment from 'moment';

const ViewPackagingInventory = () => {
    const { fbmAccount } = useContext(UserContext);

    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [reportIsReady, setReportIsReady] = useState(false);

    let columns = [
        { accessor: 'facility', csvAccessor: 'facility', Header: 'Facility' },
        { accessor: 'type', csvAccessor: 'type', Header: 'Type' },
        { accessor: 'external_item_name', csvAccessor: 'external_item_name', Header: 'Ext Item Name', width: 150 },
        {
            accessor: 'units_in_inventory_display',
            csvAccessor: 'units_in_inventory',
            Header: 'Units in Inv',
            width: 150,
        },
        { accessor: 'lot_number', csvAccessor: 'lot_number', Header: 'Lot #' },
        { accessor: 'pallet_name', csvAccessor: 'pallet_name', Header: 'Pallet Name', width: 125 },
        {
            accessor: 'packaging_purchase_order_id',
            csvAccessor: 'packaging_purchase_order_id',
            Header: 'Pkg PO ID',
            width: 125,
        },
        {
            accessor: 'packaging_purchase_order_map_id',
            csvAccessor: 'packaging_purchase_order_map_id',
            Header: 'Pkg PO Map ID',
            width: 150,
        },
        { accessor: 'received_date', csvAccessor: 'received_date', Header: 'Received Date', width: 150 },
        {
            accessor: 'last_updated',
            csvAccessor: 'last_updated',
            Header: 'Last Updated',
            width: 175,
        },
        {
            accessor: 'pallet_number_cases_display',
            csvAccessor: 'pallet_number_cases',
            Header: 'Pallet # Cases',
            width: 150,
        },
        { accessor: 'location', csvAccessor: 'location', Header: 'Location' },
        {
            accessor: 'po_number_of_cases_display',
            csvAccessor: 'po_number_of_cases',
            Header: 'PO # of Cases',
            width: 150,
        },
        { accessor: 'units_purchased_display', csvAccessor: 'units_purchased', Header: 'Units Purch', width: 125 },
        { accessor: 'lot_number_cases_display', csvAccessor: 'lot_number_cases', Header: 'Lot # Cases', width: 125 },
    ];

    let getAndRenderReportData = async () => {
        setReportIsReady(false);
        setRows([]);

        let response = await api.getReportPackagingInventoryView(fbmAccount.fbmAccountName);

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportIsReady(true);
            return;
        }

        setRows(
            response.data.rows.map((item) => {
                item.last_updated = moment(item.last_updated).format('YYYY-MM-DD HH:mm:ss');
                item.units_in_inventory_display = Number(item.units_in_inventory).toLocaleString();
                item.pallet_number_cases_display = Number(item.pallet_number_cases).toLocaleString();
                item.po_number_of_cases_display = Number(item.po_number_of_cases).toLocaleString();
                item.units_purchased_display = Number(item.units_purchased).toLocaleString();
                item.lot_number_cases_display = Number(item.lot_number_cases).toLocaleString();

                return item;
            })
        );

        setReportIsReady(true);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getAndRenderReportData();
        }
    }, [fbmAccount]);

    return (
        <Paper>
            <Notifications options={notification} />

            <Grid container>
                <Grid item xs={12}>
                    <h2>{fbmAccount.companyName} - Packaging Inventory Report</h2>
                </Grid>
            </Grid>

            <DataTable
                csvExport={reportIsReady}
                csvExportFileName="packaging-inventory"
                csvExportHeadersUseAccessor={true}
                csvExportAccessor="csvAccessor"
                columns={columns}
                data={rows}
                editable={false}
            />
        </Paper>
    );
};

export { ViewPackagingInventory };
