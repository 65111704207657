import React, { useState } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { TabPanel } from '../../../../shared';
import { NetsuitePackSync } from './NetsuitePackSync';
import './netsuite.css';

const Netsuite = () => {
    let [selectedTab, setSelectedTab] = useState('0');

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="NetsuitePackSync" label="Pack Sync" value="0" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <NetsuitePackSync />
            </TabPanel>
        </Paper>
    );
};

export { Netsuite };
