import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Notifications, DataTable } from '../../../shared';
import { partnershipsApi as api } from '../../../api/partnerships';

let ViewFAMOrders = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [rows, setRows] = useState([]);

    let getImportedOrders = async () => {
        let res = await api.getImportedOrders();
        if (!res.status) {
            setNotification({ text: 'Failed to get imported orders', level: 'error' });
            return;
        }

        setRows(
            res.data.orders.map((item) => {
                item.createdAt = moment(item.createdAt.date + item.createdAt.timezone)
                    .local()
                    .format('MM/DD/YYYY h:mm a');
                return item;
            })
        );
    };

    let cancelShipment = async (shipmentID) => {
        if (window.confirm('Cancel shipment?')) {
            let res = await api.cancelImportedShipment(shipmentID);
            if (!res.status) {
                setNotification({ text: 'Failed to cancel shipment', level: 'error' });
                return;
            }

            getImportedOrders();
        }
    };

    useEffect(() => {
        getImportedOrders();
    }, []);

    let columns = [
        { accessor: 'orderID', Header: 'Order #' },
        { accessor: 'shipmentID', Header: 'Shipment #' },
        { accessor: 'status', Header: 'Status' },
        {
            Header: 'Cancel',
            Cell: ({ row }) => {
                if (row.original.cancellable) {
                    return (
                        <div>
                            <span
                                onClick={() => {
                                    cancelShipment(row.original.shipmentID);
                                }}
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            >
                                CANCEL
                            </span>
                        </div>
                    );
                }

                return null;
            },
        },
        { accessor: 'source', Header: 'Source' },
        { accessor: 'createdAt', Header: 'Created At' },
        { accessor: 'estimatedDeliveryDate', Header: 'Estimated Delivery' },
        {
            Header: 'Tracking Url',
            Cell: ({ row }) => {
                if (!row.original.trackingUrl) {
                    return null;
                }
                return <a href={row.original.trackingUrl}>Tracking</a>;
            },
        },
        { accessor: 'firstName', Header: 'First Name' },
        { accessor: 'lastName', Header: 'Last Name' },
        { accessor: 'lineOne', Header: 'Addr 1' },
        { accessor: 'lineTwo', Header: 'Addr 2' },
        { accessor: 'city', Header: 'City' },
        { accessor: 'state', Header: 'State' },
        { accessor: 'zip', Header: 'Zip' },
        { accessor: 'notes', Header: 'Notes' },
        { accessor: 'recipientType', Header: 'Recipient Type' },
    ];

    return (
        <div>
            <Notifications options={notification} />

            <DataTable title="Food as Medicine Orders" columns={columns} data={rows} />
        </div>
    );
};

export { ViewFAMOrders };
