import React, { useState, useEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { DataTable, Notifications } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { productionApi } from '../../../api/production';

let RackLabels = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [downloadingLabels, setDownloadingLabels] = useState(false);

    let columns = [
        { accessor: 'packGroup', Header: 'Pack Group' },
        { accessor: 'week', Header: 'Week' },
        { accessor: 'year', Header: 'Year' },
        {
            accessor: 'supermarketLabels',
            Header: 'Supermarket Labels',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadSupermarketLabels(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - All
                        </Button>
                        <br />
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadSupermarketLabelsAdd(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - Add
                        </Button>
                        <br />
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadSupermarketLabelsRemove(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - Remove
                        </Button>
                        <br />
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadSupermarketLabelsSlotsChanging(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - Slots Changing
                        </Button>
                        <br />
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadSupermarketLabelsItemsChangingSlots(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - Items Changing Slots
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'scannableRackLabels',
            Header: 'Scannable Rack Labels',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadScannableRackLabels(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - All
                        </Button>
                        <br />
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadScannableRackLabelsAdd(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - Add
                        </Button>
                        <br />
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadScannableRackLabelsRemove(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - Remove
                        </Button>
                        <br />
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadScannableRackLabelsSlotsChanging(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - Slots Changing
                        </Button>
                        <br />
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setDownloadingLabels(true);
                                await api.downloadScannableRackLabelsItemsChangingSlots(
                                    row.original.id,
                                    row.original.week,
                                    row.original.packGroup
                                );
                                setDownloadingLabels(false);
                            }}
                        >
                            Download - Items Changing Slots
                        </Button>
                    </div>
                );
            },
        },
    ];

    let getPackGroups = async () => {
        let response = await productionApi.getPackGroups();
        if (response.status === false) {
            setNotification({ text: 'No pack groups found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }
        if (response.data.packGroups) {
            response.data.packGroups.map((row) => {
                row.packGroup = row.name;
                return row;
            });
            var outGroups = response.data.packGroups;
        }

        let currentWeek = response.data.week;
        let currentYear = response.data.year;

        // Get the previous 3 weeks
        // TODO: Make better endpoint
        if (currentWeek === 1) {
            for (let i = 50; i <= 53; i++) {
                let responseExtra = await productionApi.getPackGroups({
                    week: i,
                    year: currentYear - 1,
                });
                if (responseExtra.status === false) {
                    setNotification({ text: 'No pack groups found ' + responseExtra.msg, level: 'error' });
                    setRows([]);
                    return;
                }
                if (responseExtra.data.packGroups) {
                    responseExtra.data.packGroups.map((row) => {
                        row.packGroup = row.name;
                        return row;
                    });
                }
                outGroups = outGroups.concat(responseExtra.data.packGroups);
            }
        } else {
            let extraWeeks = 3;
            for (let i = 1; i <= extraWeeks; i++) {
                let responseExtra = await productionApi.getPackGroups({ week: currentWeek - i, year: currentYear });
                if (responseExtra.status === false) {
                    setNotification({ text: 'No pack groups found ' + responseExtra.msg, level: 'error' });
                    setRows([]);
                    return;
                }
                if (responseExtra.data.packGroups) {
                    responseExtra.data.packGroups.map((row) => {
                        row.packGroup = row.name;
                        return row;
                    });
                }
                outGroups = outGroups.concat(responseExtra.data.packGroups);
            }
        }

        setRows(outGroups);
    };

    useEffect(() => {
        getPackGroups(true);
    }, []);

    return (
        <div>
            <h1>Rack Labels</h1>
            <Notifications options={notification} />
            {}
            <div>{downloadingLabels ? <CircularProgress /> : <DataTable columns={columns} data={rows} />}</div>
        </div>
    );
};

export { RackLabels };
