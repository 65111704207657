import React, { useState, useEffect, useRef, useContext } from 'react';
import { Notifications, DataTable } from '../../../shared';
import { fbmApi as api } from '../../../api/fbm';
import { UserContext } from '../../../contexts/UserContext';
import { catalogApi } from '../../../api/catalog';

let ViewFBMItems = ({ canEditFBMItems, tempZones }) => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const { fbmAccount } = useContext(UserContext);
    let cols = [];

    // Load rows
    let getRows = async () => {
        let response = await api.getItems(fbmAccount.fbmAccountName);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        if (!!fbmAccount.id) {
            getRows();
        }
    }, [fbmAccount]);

    if (canEditFBMItems === true) {
        cols = [
            { accessor: 'externalSku', Header: 'External SKU' },
            { accessor: 'itemNameInternal', Header: 'Internal Name', editable: canEditFBMItems },
            { accessor: 'thirdPartyItemID', Header: `${fbmAccount.companyName} SKU` },
            {
                accessor: 'csPallet',
                Header: 'Cases per pallet',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', integer: true },
            },
            {
                accessor: 'lbCs',
                Header: 'Pounds per case (lb/cs)',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'lengthInCs',
                Header: 'Case Length (in)',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'widthInCs',
                Header: 'Case Width (in)',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'heightInCs',
                Header: 'Case Height (in)',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'ozUnit',
                Header: 'Ounces per Unit (oz/unit)',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'lengthInUnit',
                Header: 'Unit Length (in)',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'widthInUnit',
                Header: 'Unit Width (in)',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'heightInUnit',
                Header: 'Unit Height (in)',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'baseUnitSize',
                Header: 'Sellable Unit Size',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', step: '.01', min: '0' },
            },
            {
                accessor: 'baseUnitsPerCase',
                Header: 'Sellable Units Per Case',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', integer: true },
            },
            {
                accessor: 'dbiOnly',
                Header: 'Fulfillable by DBI Only',
                type: 'checkbox',
                editable: canEditFBMItems,
                editProps: { type: 'checkbox' },
            },
            {
                accessor: 'receivingTempZone',
                Header: 'Receiving Temp Zone',
                editable: canEditFBMItems,
                editProps: { type: 'select', options: tempZones.receiving },
            },
            {
                accessor: 'storageTempZone',
                Header: 'Storage Temp Zone',
                editable: canEditFBMItems,
                editProps: { type: 'select', options: tempZones.storage },
            },
            {
                accessor: 'shippingTempZone',
                Header: 'Carrier Shipping Temp Zone',
                editable: canEditFBMItems,
                editProps: { type: 'select', options: tempZones.shipping },
            },
            {
                accessor: 'dbiShippingTempZone',
                Header: 'DBI Shipping Temp Zone',
                editable: canEditFBMItems,
                editProps: { type: 'select', options: tempZones.dbiShipping },
            },
            {
                accessor: 'customerGuaranteeDays',
                Header: 'Customer Guarantee Days',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', integer: true },
            },
            {
                accessor: 'active',
                Header: 'Active',
                type: 'checkbox',
                editable: canEditFBMItems,
                editProps: { type: 'checkbox' },
            },
            {
                accessor: 'vendorGuaranteedDaysUponDelivery',
                Header: 'Vendor Guaranteed Days Upon Delivery',
                editable: canEditFBMItems,
                editProps: { type: 'input', inputType: 'number', integer: true },
            },
            {
                accessor: 'expirationType',
                Header: 'SKU Date Type',
                editable: canEditFBMItems,
                editProps: {
                    type: 'select',
                    options: ['NO_EXPIRATION_DATE', 'EXPIRATION_DATE', 'PRODUCTION_DATE', 'DAYS_AFTER_RECEIPT'],
                },
            },
        ];
    } else {
        cols = [
            { accessor: 'externalSku', Header: 'External SKU' },
            { accessor: 'itemNameInternal', Header: 'Internal Name', editable: canEditFBMItems },
            { accessor: 'thirdPartyItemID', Header: `${fbmAccount.companyName} SKU` },
            { accessor: 'csPallet', Header: 'Cases per pallet' },
            { accessor: 'lbCs', Header: 'Pounds per case (lb/cs)' },
            { accessor: 'lengthInCs', Header: 'Case Length (in)' },
            { accessor: 'widthInCs', Header: 'Case Width (in)' },
            { accessor: 'heightInCs', Header: 'Case Height (in)' },
            { accessor: 'ozUnit', Header: 'Ounces per Unit (oz/unit)' },
            { accessor: 'lengthInUnit', Header: 'Unit Length (in)' },
            { accessor: 'widthInUnit', Header: 'Unit Width (in)' },
            { accessor: 'heightInUnit', Header: 'Unit Height (in)' },
            { accessor: 'baseUnitSize', Header: 'Sellable Unit Size' },
            { accessor: 'baseUnitsPerCase', Header: 'Sellable Units Per Case' },
            { accessor: 'dbiOnly', Header: 'Fulfillable by DBI Only', type: 'checkbox' },
            { accessor: 'receivingTempZone', Header: 'Receiving Temp Zone' },
            { accessor: 'storageTempZone', Header: 'Storage Temp Zone' },
            { accessor: 'shippingTempZone', Header: 'Carrier Shipping Temp Zone' },
            { accessor: 'dbiShippingTempZone', Header: 'DBI Shipping Temp Zone' },
            { accessor: 'customerGuaranteeDays', Header: 'Customer Guarantee Days' },
            { accessor: 'active', Header: 'Active', type: 'checkbox' },
            { accessor: 'vendorGuaranteedDaysUponDelivery', Header: 'Vendor Guaranteed Days Upon Delivery' },
            { accessor: 'expirationType', Header: 'SKU Date Type' },
        ];
    }

    return (
        <div>
            <h1>{fbmAccount.companyName} Item Catalog</h1>
            <Notifications options={notification} />
            <div>
                <DataTable
                    columns={cols}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ field, value, row }) => {
                        let item = {};
                        item[field] = value;

                        let res = { status: false };

                        if (
                            [
                                'receivingTempZone',
                                'storageTempZone',
                                'shippingTempZone',
                                'dbiShippingTempZone',
                                'customerGuaranteeDays',
                                'dbiOnly',
                                'baseUnitSize',
                                'expirationType',
                            ].includes(field)
                        ) {
                            let formData = new FormData();
                            formData.append(field, value);
                            formData.append('externalSku', row.externalSku);
                            res = await catalogApi.updateItemInformation(formData);
                        } else {
                            res = await catalogApi.updateItemMaster(row.itemMasterID, item);
                        }
                        if (res.status === false) {
                            let message = 'Error modifying item';
                            if (res) {
                                message += ': ' + res.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Item modified!', level: 'success' });
                            await getRows();
                        }
                        return res.status;
                    }}
                />
            </div>
        </div>
    );
};

export { ViewFBMItems };
