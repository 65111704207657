const deliveryFrequencies = [
    {
        value: 1,
        text: 'Every Week',
    },
    {
        value: 2,
        text: 'Every Other Week',
    },
];

const cartExperienceDollarAmountFields = ['freeShippingThreshold', 'orderMinimum', 'coldKeeperFee'];

export { cartExperienceDollarAmountFields, deliveryFrequencies };
