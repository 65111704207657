import React, { useState } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { FormComponent } from '../../../shared';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const FormWithDisplay = ({ formFields, title, onSubmit, editable = true, button }) => {
    const useStyles = makeStyles((theme) => ({
        inputLabel: {
            paddingBottom: '10px',
            color: 'black',
            fontWeight: 'bold',
        },
    }));
    const classes = useStyles();

    const [editing, setEditing] = useState(false);

    const getMatchingOption = (item) => {
        let options = item.inputProps.opts;
        let match = options.find((opt) => !!opt.value && String(opt.value) === String(item.initialValue));
        if (match) {
            return match.text;
        }
        return item.initialValue;
    };

    return (
        <>
            <InputLabel
                className={classes.inputLabel}
                onClick={() => {
                    if (!editable) {
                        return;
                    }
                    setEditing(!editing);
                }}
            >
                <span style={{ paddingRight: '20px' }}>{title}</span>
                {editable && (
                    <span style={{ cursor: 'pointer' }}>
                        {editing ? (
                            <CloseIcon style={{ fontSize: '16px' }} />
                        ) : (
                            <EditIcon style={{ fontSize: '16px' }} />
                        )}
                    </span>
                )}
            </InputLabel>
            {editing ? (
                <FormComponent
                    button={button || null}
                    formFields={formFields}
                    inputElementStyle={{ padding: '0px', margin: '0px' }}
                    formControlStyle={{ padding: '0px 3px' }}
                    onSubmit={async (formData, resetForm) => {
                        let res = await onSubmit(formData);
                        if (res) {
                            setEditing(false);
                        }
                        return res;
                    }}
                />
            ) : (
                <Grid container alignItems="center" style={{ textAlign: 'left' }}>
                    {formFields.map((item) => (
                        <Grid item xs={12} md={item.gridValue || 12} key={item.name} style={{ padding: '5px' }}>
                            <InputLabel shrink={true}>{item.label}</InputLabel>
                            <span>{item.inputProps?.opts ? `${getMatchingOption(item)}` : `${item.initialValue}`}</span>
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

export { FormWithDisplay };
