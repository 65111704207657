import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewPackagingPO } from './viewPurchaseOrder.js';
import { CreatePackagingPO } from './createPurchaseOrder.js';
import { BulkCreatePackagingPO } from './bulkCreatePackagingPO.js';
import { TabPanel } from '../../../shared';

const PackagingPurchaseOrder = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewPurchaseOrders_Tab" label="View Purchase Orders" value="0" />
                <Tab id="CreatePurchaseOrder_Tab" label="Create Purchase Order" value="1" />
                <Tab id="BulkCreate_Tab" label="Bulk Create" value="2" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewPackagingPO />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreatePackagingPO />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'2'}>
                <BulkCreatePackagingPO />
            </TabPanel>
        </Paper>
    );
};

export { PackagingPurchaseOrder };
