import React, { useState, useEffect, useContext } from 'react';
import { productionApi as api } from '../../../api/production.js';
import { warehouseApi } from '../../../api/warehouse';
import { addressesApi } from '../../../api/addresses';
import { DataTable, FormComponent, Notifications, TabPanel } from '../../../shared';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Checkbox,
    Button,
    Tabs,
    Tab,
    Select,
    MenuItem,
    CircularProgress,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SellableItemUpload } from './sellableItemUpload';
import { LineSlotMapUpload } from './lineSlotMapUpload';
import { UserContext } from '../../../contexts/UserContext';
import { catalogApi } from '../../../api/catalog';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let UploadModal = function ({ packGroup, closeModal, getData, setNotification }) {
    let formFields = [
        { name: 'file', label: 'Sellable Item File', gridValue: 12, inputElement: 'file', accept: ['.csv'] },
    ];
    return (
        <Dialog open={true} onClose={closeModal} fullWidth={true} maxWidth="lg">
            <DialogTitle>Edit Sellable Items - Pack Group {packGroup.name}</DialogTitle>
            <DialogContent style={{ paddingTop: '0px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let response = await api.uploadLineSlotMap({ file: formData.file, packGroupID: packGroup.id });
                        if (response.status === true) {
                            setNotification({ text: 'Sellable items updated', level: 'success' });
                            closeModal();
                            getData({ packGroupID: packGroup.id });
                        } else {
                            let message = 'Error updating sellable items';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            if (response.data && response.data.numItemsUpdated) {
                                message += '. Num items updated: ' + response.data.numItemsUpdated;
                            }
                            setNotification({ text: message, level: 'error' });
                        }
                    }}
                />
                <br />
                <br />
            </DialogContent>
        </Dialog>
    );
};

let SellableItems = function () {
    let [year, setYear] = useState('');
    let [week, setWeek] = useState('');
    let [selectedTab, setSelectedTab] = useState('');
    let [packGroups, setPackGroups] = useState([]);
    let [lots, setLots] = useState([]);
    let [pos, setPos] = useState([]);
    let [upcomingWeek, setUpcomingWeek] = useState(false);
    let [uploadModalOpen, setUploadModalOpen] = useState(false);
    let [selectedSellableItemIDs, setSelectedSellableItemIDs] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [loading, setLoading] = useState(false);
    let [taxonomy, setTaxonomy] = useState({ l1: [], l2: [] });
    let [countriesData, setCountriesData] = useState([]);
    let [countriesOfOriginForSellableItems, setCountriesOfOriginForSellableItems] = useState([]);
    let user = useContext(UserContext);

    let getData = async ({ newWeek, newYear, packGroupID }) => {
        setLoading(true);
        let existingPackGroups = packGroups;
        let packGroupsRes = await api.getPackGroups({
            facilityID: user.getFacilityID(),
            week: newWeek || week,
            year: newYear || year,
        });

        if (packGroupsRes.status === false) {
            return;
        }

        setWeek(packGroupsRes.data.week);
        setYear(packGroupsRes.data.year);
        setUpcomingWeek(packGroupsRes.data.upcomingWeek);

        let promises = packGroupsRes.data.packGroups.map(async (group) => {
            if (packGroupID && packGroupID !== group.id) {
                return existingPackGroups.find((gr) => gr.id === group.id);
            }
            let sellableItems = await api.getSellableItems(group.id);
            if (sellableItems.status === false) {
                group.sellableItems = [];
            } else {
                group.sellableItems = sellableItems.data.sellableItems.map((item) => {
                    if (item.countriesOfOrigin.length > 0) {
                        item.countriesOfOrigin = item.countriesOfOrigin.map((country) => ({
                            ...country,
                            text: `${country.countryName} (${country.countryCode})`,
                            value: country.countryID,
                        }));
                    }

                    if (item.pos.length > 0) {
                        item.pos = item.pos.map((po) => ({
                            ...po,
                            text: `PO ${po.id}: SKU ${po.sku} ${po.itemNameInternal}`,
                            value: po.poMapID,
                        }));
                    }
                    return item;
                });
            }

            // Find pricing status and append
            group.pricingStatus = 'none';
            let pricingStatusResp = await api.getAutoPricingStatus(group.id);
            if (pricingStatusResp.status !== false) {
                group.pricingStatus = pricingStatusResp.data.status;
            }

            let countriesOfOriginforSellableItemsResp = await api.getCountriesOfOriginForSellableItems();
            if (countriesOfOriginforSellableItemsResp === true) {
                setCountriesOfOriginForSellableItems(countriesOfOriginforSellableItemsResp.data.rows);
            }

            return group;
        });
        let packGroupsArr = await Promise.all(promises);

        setPackGroups(packGroupsArr);
        if (!selectedTab) {
            setSelectedTab(packGroupsArr[0] ? '0' : '');
        }
        setLoading(false);
    };

    let getLots = async () => {
        let lots = await warehouseApi.getLots(true);
        setLots(lots.data.rows || []);
    };

    let getPos = async () => {
        let pos = await api.getPlacedPOs();
        if (pos.status === true) {
            setPos(pos.data.items);
        }
    };

    let getTaxonomy = async () => {
        let promises = [1, 2].map((lvl) => catalogApi.getTaxonomyByLevel(lvl, 'AISLE'));
        let res = await Promise.all(promises);
        if (res.every((item) => item.status)) {
            setTaxonomy({
                l1: res[0].data.categories.map((item) => ({ text: item.name, value: item.id })),
                l2: res[1].data.categories.map((item) => ({ text: item.name, value: item.id })),
            });
        }
    };

    // Load countries
    let getCountries = async () => {
        let response = await addressesApi.getCountries();
        if (!response.status) {
            setNotification({ text: 'Failed to get countries', level: 'error' });
            return;
        }

        let countriesAsOpts = response.data.rows.map((row) => {
            row.text = `${row.name} (${row.countryCode})`;
            row.value = row.id;
            return row;
        });

        setCountriesData(countriesAsOpts);
    };

    useEffect(() => {
        getData({});
        getLots();
        getPos();
        getTaxonomy();
        getCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let formFields = [
        {
            name: 'week',
            initialValue: week,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Week',
            inputProps: { required: true, type: 'number', max: '53' },
        },
        {
            name: 'year',
            initialValue: year,
            inputElement: 'textField',
            gridValue: 5,
            label: 'Year',
            inputProps: { required: true, type: 'number', maxLength: '4' },
        },
    ];

    let handleChange = (event, value) => {
        setSelectedTab(value);
    };
    return (
        <div>
            <Notifications options={notification} />
            {uploadModalOpen === true && (
                <UploadModal
                    packGroup={packGroups[selectedTab]}
                    closeModal={() => {
                        setUploadModalOpen(false);
                    }}
                    getData={getData}
                    setNotification={setNotification}
                />
            )}
            <h2>
                Setup Sellable Items - Week {week}, {year}
            </h2>
            {week && year && (
                <FormComponent
                    formFields={formFields}
                    button={{ gridValue: 2, text: 'Change Week/Year' }}
                    onSubmit={async (formData) => {
                        await getData({ newWeek: formData.week, newYear: formData.year });
                    }}
                />
            )}
            <br />
            <br />
            {loading ? (
                <CircularProgress />
            ) : (
                <div>
                    {packGroups.length > 0 && selectedTab ? (
                        <div>
                            <Tabs
                                onChange={handleChange}
                                value={selectedTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                {packGroups.map((group, index) => (
                                    <Tab key={group.id} label={`Pack Group ${group.name}`} value={`${index}`} />
                                ))}
                            </Tabs>
                            {packGroups.map((group, index) => {
                                let sellItems = group.sellableItems;
                                return (
                                    <TabPanel selectedTab={selectedTab} key={group.id} index={`${index}`}>
                                        <div>
                                            {sellItems && sellItems.length > 0 ? (
                                                <SellableItemsTable
                                                    packGroupID={group.id}
                                                    week={week}
                                                    year={year}
                                                    lots={lots.filter(
                                                        (lot) => lot.originFacilityID === group.facilityID
                                                    )}
                                                    pos={pos.filter((po) => po.facilityID === group.facilityID)}
                                                    getData={getData}
                                                    loading={loading}
                                                    setUploadModalOpen={setUploadModalOpen}
                                                    sellableItemsData={sellItems}
                                                    upcomingWeek={upcomingWeek}
                                                    setNotification={setNotification}
                                                    setSelectedSellableItemIDs={setSelectedSellableItemIDs}
                                                    taxonomy={taxonomy}
                                                    pricingStatus={group.pricingStatus}
                                                    countriesData={countriesData}
                                                    countriesOfOriginForSellableItems={
                                                        countriesOfOriginForSellableItems
                                                    }
                                                />
                                            ) : (
                                                <div>No sellable items found for this pack group.</div>
                                            )}
                                        </div>
                                    </TabPanel>
                                );
                            })}
                        </div>
                    ) : (
                        <div>No sellable item data found for this week.</div>
                    )}
                </div>
            )}
        </div>
    );
};

let SellableItemsTable = function ({
    sellableItemsData,
    upcomingWeek,
    setUploadModalOpen,
    lots,
    pos,
    setNotification,
    week,
    year,
    getData,
    loading,
    setSelectedSellableItemIDs,
    packGroupID,
    taxonomy,
    pricingStatus,
    countriesData,
    countriesOfOriginForSellableItems,
}) {
    let [tableData, setTableData] = useState([]);
    let [uploadingItems, setUploadingItems] = useState(false);
    let [uploadingLineSlotMap, setUploadingLineSlotMap] = useState(false);
    let [showRemoveSellableItemsForm, setShowRemoveSellableItemsForm] = useState(false);
    let [removingSellableItems, setRemovingSellableItems] = useState(false);

    let onSaveEdit = async ({ id, field, value }) => {
        if (field === 'onSale') {
            value = value === true ? 'true' : 'false';
        }
        if (field === 'newItem') {
            value = value === true ? 'true' : 'false';
        }
        if (field === 'sampleItem') {
            value = value === true ? 'true' : 'false';
        }
        if (field === 'giftItem') {
            value = value === true ? 'true' : 'false';
        }
        if (field === 'exitSku') {
            value = value === true ? 'true' : 'false';
        }
        if (field === 'bestSeller') {
            value = value === true ? 'true' : 'false';
        }

        let res = await api.updateSellableItem(id, field, value);
        if (res.status !== true) {
            let msg = `Error updating sellable item ${res.msg ? `. ${res.msg}` : ''}`;
            setNotification({ text: msg, level: 'error' });
        }
        return res.status;
    };

    let removeLotFromSellableItem = async (sellableItemID, lotID) => {
        if (window.confirm(`Remove lot ${lotID} from sellable item?`) == true) {
            let res = await api.removeLotFromSellableItem(sellableItemID, lotID);
            if (res.status === true) {
                await getData({ packGroupID });
            } else {
                let msg = 'Error removing lot from sellable item';
                if (res.msg) {
                    msg += '. ' + res.msg;
                }
                setNotification({ text: msg, level: 'error' });
            }
            return res;
        }
    };

    let removePOFromSellableItem = async (sellableItemID, poMapID, poItemName) => {
        if (window.confirm(`Remove PO item ${poItemName} from sellable item?`) === true) {
            let res = await api.removePOFromSellableItem(sellableItemID, poMapID);
            if (res.status === true) {
                await getData({ packGroupID });
            } else {
                let msg = 'Error removing PO from sellable item';
                if (res.msg) {
                    msg += '. ' + res.msg;
                }
                setNotification({ text: msg, level: 'error' });
            }
            return res;
        }
    };

    let addLotToSellableItem = async (sellableItemID, lotID) => {
        let res = await api.updateSellableItem(sellableItemID, 'lotID', lotID);
        if (res.status === true) {
            await getData({ packGroupID });
            setNotification({ text: 'Lot added to sellable item', level: 'success' });
        } else {
            let msg = `Error updating sellable item ${res.msg ? `. ${res.msg}` : ''}`;
            setNotification({ text: msg, level: 'error' });
        }
        return res.status;
    };

    let addPOToSellableItem = async (sellableItemID, poMapID) => {
        let res = await api.updateSellableItem(sellableItemID, 'poMapID', poMapID);
        if (res.status === true) {
            await getData({ packGroupID });
            setNotification({ text: 'PO added to sellable item', level: 'success' });
        } else {
            let msg = `Error updating sellable item ${res.msg ? `. ${res.msg}` : ''}`;
            setNotification({ text: msg, level: 'error' });
        }
        return res.status;
    };

    let removeSellableItems = async (ids) => {
        setRemovingSellableItems(true);
        try {
            let res = await api.removeSellableItems(ids);
            if (res.status === false) {
                setNotification({ text: 'Error deleting sellable item(s)', level: 'error' });
            } else if (res.status === true) {
                if (res.data.error.length == 0) {
                    setNotification({
                        text: `${res.data.success.length}/${ids.length} items deleted`,
                        level: 'success',
                    });
                } else {
                    setNotification({
                        text: `Error deleting some sellable item(s) - ${res.data.success.length}/${
                            ids.length
                        } items deleted. \n\n Items with errors: ${JSON.stringify(res.data.error)}`,
                        level: 'warning',
                        autoHide: false,
                    });
                }
            }
            return res;
        } finally {
            setRemovingSellableItems(false);
        }
    };

    let markItemsForPricing = async () => {
        let res = await api.markPackGroupReadyForPricing(packGroupID);
        if (res.status === false) {
            setNotification({ text: 'Error marking items for pricing: ' + res.msg, level: 'error' });
        } else if (res.status === true) {
            setNotification({ text: 'Success', level: 'success' });
        }
    };

    let toolbarActions = [
        {
            name: 'Upload Line Slot Map',
            action: () => {
                setUploadingLineSlotMap(true);
            },
        },
        {
            name: 'Upload Sellable Items',
            action: () => {
                setUploadingItems(true);
            },
        },
        {
            name: 'Remove Sellable Items',
            action: () => {
                setShowRemoveSellableItemsForm(true);
            },
        },
    ];
    let bulkActions = [
        {
            name: 'Download Pre-Populated CSV',
            action: async (ids) => {
                let obj = {}; 
                obj.sellableItemIDs = ids;
                let res = await api.downloadPrePopulatedCsv(obj);
                if (!res || !res.status) {
                    setNotification({ text: 'Failed download csv file: ' + res.msg, level: 'error' });
                    return;
                }
            }
        }
    ];

    if (upcomingWeek) {
        bulkActions.push({
            name: 'Delete',
            action: async (ids) => {
                await removeSellableItems(ids);
                await getData({});
                return true;
            },
        });

        // Only show pricing button if items are not ready to price
        if (pricingStatus == 'none') {
            toolbarActions.push({
                name: 'Mark items ready for pricing',
                action: () => {
                    markItemsForPricing();
                },
            });
        }
    }

    useEffect(() => {
        setTableData(sellableItemsData);
    }, [sellableItemsData]);

    let rowStyle = (row) => {
        if (row.lots.length === 0 && row.pos.length === 0) {
            return {
                backgroundColor: '#ffdcdc',
            };
        }
        return {};
    };

    let isEditable = upcomingWeek === true ? true : false;

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'externalSku', Header: 'External Sku' },
        { accessor: 'name', Header: 'Name', width: 150, editable: isEditable },
        { accessor: 'portionName', Header: 'Portion Name', editable: isEditable },
        {
            accessor: 'price',
            Header: 'Price',
            editable: isEditable,
            editProps: { type: 'input', inputType: 'number', step: '.01', min: '0', max: '50', required: true },
        },
        {
            accessor: 'mischiefPick',
            Header: 'Mischief',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                integer: true,
                min: '1',
                max: '10',
                required: true,
            },
        },
        {
            accessor: 'madnessPick',
            Header: 'Madness',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                integer: true,
                min: '1',
                max: '12',
                required: true,
            },
        },
        {
            accessor: 'slot',
            Header: 'Line Slot',
            editable: true,
            editProps: {
                type: 'input',
                inputType: 'number',
                integer: true,
                required: true,
            },
        },
        {
            accessor: 'lots',
            Header: 'Lots',
            editable: true,
            filterValues: (row) => row.original.lots.map((item) => `Lot ${item.id}: ${item.name}`),
            editProps: {
                type: 'autocomplete-multiple-chips',
                options: lots.map((item) => ({ text: `Lot ${item.id}: ${item.itemName}`, value: item.id })),
                modalHeader: (row) => `Add lot to ${row.name}`,
                addButtonText: 'Add Lots',
                addItem: async ({ values, row }) => {
                    for (let value of values) {
                        let res = await addLotToSellableItem(row.id, value);
                        if (!res) {
                            return false;
                        }
                    }
                    return true;
                },
                removeItem: async ({ item, row }) => {
                    removeLotFromSellableItem(row.id, item.id);
                },
            },
        },
        {
            accessor: 'pos',
            Header: 'POs',
            editable: true,
            filterValues: (row) =>
                row.original.pos.map((item) => `PO ${item.id}: SKU ${item.sku} ${item.itemNameInternal}`),
            editProps: {
                type: 'autocomplete-multiple-chips',
                options: pos.map((item) => ({
                    text: `PO ${item.poID}: SKU ${item.sku} ${item.internalName}`,
                    value: item.poMapID,
                })),
                modalHeader: (row) => `Add PO to ${row.name}`,
                addButtonText: 'Add POs',
                addItem: async ({ values, row }) => {
                    for (let value of values) {
                        let res = await addPOToSellableItem(row.id, value);
                        if (!res) {
                            return false;
                        }
                    }
                    return true;
                },
                removeItem: async ({ item, row }) => {
                    removePOFromSellableItem(row.id, item.poMapID, item.name);
                },
            },
        },
        {
            accessor: 'maxQuantity',
            Header: 'Max Qty',
            editable: true,
            editProps: {
                required: true,
                type: 'input',
                inputType: 'number',
                integer: true,
            },
        },
        {
            accessor: 'onSale',
            Header: 'On Sale',
            type: 'checkbox',
            editable: isEditable,
            editProps: { type: 'checkbox' },
        },
        { accessor: 'newItem', Header: 'New', type: 'checkbox', editable: isEditable, editProps: { type: 'checkbox' } },
        {
            accessor: 'hideSku',
            Header: 'Hide Sku',
            type: 'checkbox',
            editable: isEditable,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'sampleItem',
            Header: 'Sample',
            type: 'checkbox',
            editable: isEditable,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'soldOut',
            Header: 'Sold Out',
            type: 'checkbox',
            Cell: ({ row }) => {
                const [open, setOpen] = useState(false);
                const [reason, setReason] = useState();
                return (
                    <>
                        <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={() => setOpen(false)}>
                            <DialogContent>
                                <h3>Select Reason</h3>
                                <Select
                                    required
                                    style={{
                                        minWidth: 200,
                                    }}
                                    onChange={(e) => setReason(e.target.value)}
                                >
                                    {[
                                        'Late Truck',
                                        'Gapping',
                                        'Inventory Accuracy (Ops related)',
                                        'Inventory Accuracy (Planning related)',
                                        'Vendor Short - Case Short, Ct/Cs discrepancy',
                                        'Quality Issue - Rejection, QC pull',
                                        'No more inventory on hand',
                                        'Late Truck - DLVD',
                                        'Other',
                                    ].map((item, i) => (
                                        <MenuItem key={i} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <br />
                                <br />
                                <Button
                                    variant="outlined"
                                    onClick={async () => {
                                        let res = await api.markItemSoldOut(row.original.id, reason, 'true');
                                        if (res.status === true) {
                                            await getData({ packGroupID });
                                            setNotification({ text: 'Item marked as Sold Out', level: 'success' });
                                        } else {
                                            setNotification({
                                                text: 'Could not mark item as Sold Out',
                                                level: 'error',
                                            });
                                        }
                                        setOpen(false);
                                    }}
                                >
                                    Confirm Reason
                                </Button>
                            </DialogContent>
                        </Dialog>
                        <Checkbox
                            checked={row.original.soldOut}
                            color="primary"
                            onChange={async (e) => {
                                if (row.original.soldOut === false) {
                                    e.preventDefault();
                                    setOpen(true);
                                } else {
                                    let res = await api.markItemSoldOut(row.original.id, '', 'false');
                                    if (res.status === true) {
                                        await getData({ packGroupID });
                                    }
                                }
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: 'giftItem',
            Header: 'Gift',
            type: 'checkbox',
            editable: isEditable,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'exitSku',
            Header: 'Exit Sku',
            type: 'checkbox',
            editable: isEditable,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'bestSeller',
            Header: 'Best Seller',
            type: 'checkbox',
            editable: isEditable,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'sourcingReason',
            Header: 'Sourcing Reason',
            editable: true,
        },
        {
            accessor: 'countriesOfOrigin',
            Header: 'Country of Origin',
            editable: true,
            filterValues: (row) =>
                countriesOfOriginForSellableItems
                    .filter((item) => item.sellableItemID === row.id)
                    .map((item) => `${item.countriesOfOrigin.countryName} (${item.countriesOfOrigin.countryCode})`),
            editProps: {
                type: 'autocomplete-multiple-chips',
                options: countriesData.map((country) => ({
                    text: `${country.name} (${country.countryCode})`,
                    value: country.id,
                })),
                modalHeader: 'Add Country of Origin to Item',
                addButtonText: 'Add Country',
                addItem: async ({ values, row }) => {
                    if (values.length > 3) {
                        setNotification({
                            text: 'Can only add up to 3 countries of origin at a time',
                            level: 'error',
                        });
                        return;
                    }

                    let res = await api.createSellableItemCountryOfOriginMap(row.id, 'countryOfOrigin', values);

                    if (res.status === true) {
                        await getData({ packGroupID });
                    } else {
                        setNotification({ text: res.msg, level: 'error' });
                        return false;
                    }

                    return true;
                },
                removeItem: async ({ item, row }) => {
                    let res = await api.removeCountryOfOriginFromSellableItem(row.id, item.countryID);
                    if (res.status === true) {
                        await getData({ packGroupID });
                    } else {
                        setNotification({ level: 'error', text: res.msg });
                        return false;
                    }
                    return true;
                },
            },
        },
        {
            accessor: 'level0AisleRank',
            Header: 'L0 Rank',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'level1AisleRank',
            Header: 'L1 Rank',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'level2AisleRank',
            Header: 'L2 Rank',
            editable: true,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            accessor: 'cartPickOOS',
            Header: 'Cart Pick Out Of Stock',
            type: 'checkbox',
            Cell: ({ row }) => {
                const [open, setOpen] = useState(false);
                const [reason, setReason] = useState();
                return (
                    <>
                        <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={() => setOpen(false)}>
                            <DialogContent>
                                <h3>Select Reason</h3>
                                <Select
                                    required
                                    style={{
                                        minWidth: 200,
                                    }}
                                    onChange={(e) => setReason(e.target.value)}
                                >
                                    {[
                                        'Vendor Short - Case Short, Ct/Cs discrepancy',
                                        'Inventory Accuracy (Ops related)',
                                        'Inventory Accuracy (Planning related)',
                                        'Gapping',
                                        'Late Truck',
                                        'Quality Issue - Rejection, QC pull',
                                        'No more inventory on hand',
                                        'Late Truck - DLVD',
                                        'Other',
                                    ].map((item, i) => (
                                        <MenuItem key={i} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <br />
                                <br />
                                <Button
                                    variant="outlined"
                                    onClick={async () => {
                                        let res = await api.updateSellableItem(row.original.id, 'cartPickOOS', {
                                            reason: reason,
                                            cartPickOOS: 'true',
                                        });
                                        if (res.status === true) {
                                            await getData({ packGroupID });
                                            setNotification({
                                                text: 'Item marked as Cart Pick Out of Stock',
                                                level: 'success',
                                            });
                                        } else {
                                            setNotification({
                                                text: 'Could not mark item as Cart Pick Out of Stock',
                                                level: 'error',
                                            });
                                        }
                                        setOpen(false);
                                    }}
                                >
                                    Confirm Reason
                                </Button>
                            </DialogContent>
                        </Dialog>
                        <Checkbox
                            disabled={row.original.slot !== null || row.original.externalSku >= 300000}
                            checked={row.original.cartPickOOS}
                            color="primary"
                            onChange={async (e) => {
                                if (row.original.cartPickOOS === false) {
                                    e.preventDefault();
                                    setOpen(true);
                                } else {
                                    let res = await api.updateSellableItem(row.original.id, 'cartPickOOS', {
                                        cartPickOOS: 'false',
                                    });
                                    if (res.status === true) {
                                        await getData({ packGroupID });
                                    }
                                }
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <div>
            {uploadingItems && (
                <Grid container>
                    <Grid item xs={11}></Grid>
                    <Grid item xs={2}>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setUploadingItems(false);
                            }}
                        >
                            <span>View Items</span>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <SellableItemUpload
                            packGroupID={packGroupID}
                            onSuccess={() => {
                                getData({ packGroupID: packGroupID });
                                setUploadingItems(false);
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            {uploadingLineSlotMap && (
                <Grid container>
                    <Grid item xs={11}></Grid>
                    <Grid item xs={1}>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setUploadingLineSlotMap(false);
                            }}
                        >
                            <span>View Items</span>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <LineSlotMapUpload
                            packGroupID={packGroupID}
                            onSuccess={() => {
                                getData({ packGroupID: packGroupID });
                                setUploadingLineSlotMap(false);
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            {showRemoveSellableItemsForm && (
                <Grid container style={{ marginBottom: '30px' }}>
                    <Grid container item justifyContent="flex-end">
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setShowRemoveSellableItemsForm(false);
                            }}
                        >
                            <span>View Items</span>
                        </Button>
                    </Grid>
                    {removingSellableItems && (
                        <Grid item xs={12}>
                            <CircularProgress />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormComponent
                            formFields={[
                                {
                                    name: 'sellableItemIDs',
                                    initialValue: '',
                                    inputElement: 'textField',
                                    gridValue: 10,
                                    label: 'Sellable Item IDs',
                                    inputProps: { required: true, multiline: true },
                                },
                            ]}
                            button={{ gridValue: 2, text: 'Remove' }}
                            onSubmit={async (formData) => {
                                const ids = formData.sellableItemIDs?.split(',');
                                const res = await removeSellableItems(ids, setNotification);
                                if (res.status === true) {
                                    getData({ packGroupID: packGroupID });
                                    setShowRemoveSellableItemsForm(false);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            {!uploadingItems && !uploadingLineSlotMap && (
                <DataTable
                    key={`${week}${year}`}
                    rowStyle={rowStyle}
                    columns={columns}
                    data={loading ? [] : tableData}
                    editable={true}
                    saveEdit={onSaveEdit}
                    selectMultiple={true}
                    title={`Sellable Items`}
                    bulkActions={bulkActions}
                    toolbarActions={toolbarActions}
                />
            )}
        </div>
    );
};

export { SellableItems };
