import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { Button, Grid } from '@material-ui/core';
import { Notifications, DataTable, FacilityPicker } from '../../../../shared';
import { fbmApi as api } from '../../../../api/fbm';
import moment from 'moment';

const ViewAllOrders = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    const [facilityID, setFacilityID] = useState(null);
    const { fbmAccount, user } = useContext(UserContext);

    let columns = [
        { accessor: 'fbmOrderNum', Header: 'Partner Order Number' },
        { accessor: 'displayOrderCreatedAt', Header: 'Created Time (UTC' + moment().format('Z') + ')' },
        { accessor: 'orderId', Header: 'Misfits Order Number' },
        { accessor: 'shipmentId', Header: 'Misfits Shipment ID' },
        { accessor: 'shipmentZip', Header: 'Customer Zipcode' },
        { accessor: 'shipmentStatus', Header: 'Shipment Status' },
        { accessor: 'lineHaulsCarrier', Header: 'Carrier' },
        { accessor: 'lineHaulsHub', Header: 'Hub' },
        { accessor: 'box', Header: 'Box' },
    ];

    let getRows = async () => {
        let response = await api.getViewAllOrders(fbmAccount.fbmAccountName, facilityID);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        let items = response.data.rows.map((item) => {
            item.displayOrderCreatedAt = moment(item.orderCreatedAt).local().format('MM/DD/YYYY h:mm a');
            return item;
        });

        setRows(items);
    };

    useEffect(() => {
        if (!!user.id && !user.fbm) {
            setFacilityID(user.facilityID);
        }
    }, [user]);

    useEffect(() => {
        if (!!facilityID && !!fbmAccount.id) {
            getRows();
        }
    }, [fbmAccount, facilityID]);

    return (
        <div>
            <h1>{fbmAccount.companyName} - All Orders</h1>
            <Grid container>
                <Grid item xs={4}>
                    <Button
                        id={`DownloadIngestResults`}
                        style={{ zIndex: 1000 }}
                        onClick={() => {
                            api.downloadIngestResults(fbmAccount.fbmAccountName);
                        }}
                    >
                        Download All Ingest Results
                    </Button>
                </Grid>
                <Grid item md={6} style={{ textAlign: 'right', paddingRight: '35px' }}>
                    {user.fbm && (
                        <FacilityPicker
                            defaultValue={fbmAccount.facilityIDs[0]}
                            whitelistedFacilities={fbmAccount.facilityIDs}
                            activeOnly={true}
                            onChange={(facilityID, text, facility) => {
                                setFacilityID(facilityID);
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <Notifications options={notification} />
            <DataTable 
                columns={columns} 
                data={rows} 
                editable={false}  
                csvExport={true}
                csvExportFileName={fbmAccount.companyName + '-all-orders.csv'}
                csvExportHeadersUseAccessor={true} />
        </div>
    );
};

export { ViewAllOrders };
