import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { Grid, Select, MenuItem, FormControlLabel, Checkbox, Paper, Card, TablePagination } from '@material-ui/core';
import { DataTable, FacilityPicker, Notifications } from '../../../shared/';
import { userApi as api } from '../../../api/user';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const HomeScreen = () => {
    const { user, setFacilityIDAndName, setLanguage } = useContext(UserContext);
    const [alertSubs, setAlertSubs] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [roleRows, setRoleRows] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [facility, setFacility] = useState({ val: user.facilityID, text: user.faciiltyName });

    const useStyles = makeStyles((theme) => ({
        alertCard: {
            backgroundColor: theme.palette.bone.main,
            textAlign: 'left',
            margin: '10px',
            padding: '10px',
            fontSize: '15px',
        },
        setting: {
            paddingLeft: '30px',
            textAlign: 'left',
        },
        paddingTop10: { paddingTop: '10px' },
        paddingTop5: { paddingTop: '5px' },
        paddingSides25: { padding: '0px 25px' },
    }));

    let columns = [
        { accessor: 'id', Header: 'Role ID' },
        { accessor: 'name', Header: 'Name' },
        { accessor: 'desc', Header: 'Description' },
    ];

    const classes = useStyles();

    const getAlertSubscriptions = async (facilitiesID) => {
        // console.log(facilityID);
        let res = await api.getUserAlertSubscriptions(facilitiesID);
        if (!res.status) {
            setNotification({ text: 'Failed to get alert subscriptions', level: 'error' });
            return;
        }
        setAlertSubs(res.data.subs);
    };

    const getAlertHistory = async () => {
        let res = await api.getUserAlerts();
        if (!res.status) {
            setNotification({ text: 'Failed to get alerts', level: 'error' });
            return;
        }

        setAlerts(res.data.alerts);
    };

    const getUserRoles = async () => {
        let res = await api.getUsersRoles();
        if (!res.status) {
            setNotification({ text: 'Failed to get roles', level: 'error' });
            return;
        }

        setRoleRows(res.data.rolesLong);
    };

    useEffect(() => {
        getAlertHistory();
        getUserRoles();
    }, []);

    useEffect(() => {
        if (user.fbm) {
            window.location.href = '/lettuce/fbm/shipments';
            return;
        }

        if (!!user.id) {
            getAlertSubscriptions(user.facilityID);
            setFacility({ val: user.facilityID, text: user.facilityName });
        }
    }, [user]);

    if (user.fbm || !user.id) {
        return null;
    }

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item xs={12}>
                <h2>Jalapeno</h2>
            </Grid>
            <Grid item xs={5}>
                <h3>Settings</h3>
                <div className={classes.setting}>
                    <FacilityPicker
                        activeOnly={true}
                        inactiveDisabled={true}
                        defaultValue={facility.val}
                        selectStyle={{ width: '100%', paddingTop: '10px' }}
                        onChange={async (val, text) => {
                            if (val === user.facilityID || !val) {
                                return;
                            }
                            let res = await api.updateFacility(user.id, val);
                            if (!res.status) {
                                setNotification({ text: res.msg, level: 'error' });
                                return;
                            }
                            setFacilityIDAndName({ facilityID: val, facilityName: text });
                            setFacility({ val, text });
                            setNotification({ text: 'Facility updated', level: 'success' });
                        }}
                    />
                </div>
                <div className={classes.setting}>
                    <h2>
                        Language &nbsp;&nbsp;
                        <Select
                            style={{ width: '100%' }}
                            value={user.language}
                            onChange={async (event) => {
                                let res = await api.updateLanguage(user.id, event.target.value);
                                if (!res.status) {
                                    setNotification({ text: res.msg, level: 'error' });
                                    return;
                                }
                                setLanguage(event.target.value);
                                setNotification({ text: 'Language updated', level: 'success' });
                            }}
                        >
                            <MenuItem value="ES">Spanish</MenuItem>
                            <MenuItem value="EN">English</MenuItem>
                        </Select>
                    </h2>
                </div>
                <h2>Subscriptions</h2>
                <br />
                <div className={classes.setting}>
                    <h2>{facility.text}</h2>
                    {alertSubs.map((item) => (
                        <div key={item.alertTypeID} className={classes.paddingTop10}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${item.name.replaceAll(' ', '')}_checkbox`}
                                        onChange={async (event) => {
                                            console.log('subscribe or unsubscribe', event.target.checked, item);
                                            if (item.subscribed) {
                                                // unsubscribe
                                                let res = await api.unsubscribeFromAlert(item.alertTypeID);
                                                if (!res.status) {
                                                    setNotification({
                                                        text: 'Failed to unsubscribe from alert',
                                                        level: 'error',
                                                    });
                                                }
                                            } else {
                                                // subscribe
                                                let res = await api.subscribeToAlert(item.alertTypeID);
                                                if (!res.status) {
                                                    setNotification({
                                                        text: 'Failed to subscribe to alert',
                                                        level: 'error',
                                                    });
                                                }
                                            }
                                            getAlertSubscriptions(facility.val);
                                        }}
                                        color="primary"
                                        checked={item.subscribed}
                                    />
                                }
                                label={item.name}
                            />
                        </div>
                    ))}
                </div>
            </Grid>
            <Grid item xs={7}>
                <Alerts alerts={alerts} classes={classes} />
                <h3>Your Roles</h3>
                <DataTable columns={columns} data={roleRows} />
            </Grid>
        </Grid>
    );
};

const Alerts = ({ alerts, classes }) => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, page) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    return (
        <>
            <h3>Alerts</h3>
            <div className={classes.paddingSides25}>
                {alerts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                    return (
                        <Card className={classes.alertCard} key={item.id}>
                            <div>
                                <strong>{item.alertType}</strong>
                                &nbsp; &nbsp;
                                {item?.timestamp?.date && item?.timestamp?.timezone && (
                                    <span>
                                        {moment(item.timestamp.date + item.timestamp.timezone).format(
                                            'MM/DD/YYYY h:mm a'
                                        )}
                                    </span>
                                )}
                            </div>
                            <div className={classes.paddingTop5} dangerouslySetInnerHTML={{ __html: item.message }} />
                        </Card>
                    );
                })}
            </div>
            {alerts.length > 10 && (
                <TablePagination
                    component="div"
                    count={alerts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </>
    );
};

export { HomeScreen };
