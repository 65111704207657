import React, { useState, useEffect, useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Notifications, DataTable } from '../../../shared';
import { outboundTruckApi as api } from '../../../api/outboundTrucks';
import { UserContext } from '../../../contexts/UserContext';

const ManifestTrucks = () => {
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [rows, setRows] = useState([]);
    const user = useContext(UserContext);

    const getRows = async () => {
        let res = await api.getManifestableTrucks(user.getFacilityID());
        if (res.status) {
            setRows(res.data.rows);
        }
    };

    useEffect(() => {
        getRows();
    }, []);

    const columns = [
        { accessor: 'id', Header: 'Outbound Truck ID' },
        { accessor: 'departureTime', Header: 'Departed On' },
        { accessor: 'carrier', Header: 'Carrier' },
        { accessor: 'hub', Header: 'Hub' },
        { accessor: 'arrivalDay', Header: 'Arrival Day' },
        { accessor: 'lineHaulID', Header: 'Line Haul ID' },
        {
            accessor: 'manifest',
            Header: 'Manifest',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Manifest_${row.id}`}
                        onClick={async () => {
                            // Send manifest function
                            let res = await api.manifestTruck(row.original.id);

                            if (res.status === false) {
                                setNotification({ text: 'Failed to manifest: ' + res.msg, level: 'error' });
                                return;
                            }

                            setNotification({
                                text: 'Success! Please wait 10-20 minutes for results',
                                level: 'success',
                            });
                        }}
                    >
                        Send Manifest
                    </Button>
                );
            },
        },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item md={12} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                <h1>Manifestable Trucks</h1>
            </Grid>
            <Grid item xs={12}>
                <DataTable data={rows} columns={columns} />
            </Grid>
        </Grid>
    );
};

export { ManifestTrucks };
