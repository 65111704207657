import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { TabPanel } from '../../../shared';
import { ResetLineHaulRouting } from './resetLineHaulRouting.js'
import { ResyncLineHaul } from './resyncLineHaul.js'

let DbiManagement = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="resetLineHaul_Tab" label="Reset Line Haul Routing" value="0" />
                <Tab id="resyncLineHaul_Tab" label="Resync Line Haul To Bringg" value="1" />

            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ResetLineHaulRouting />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <ResyncLineHaul />
            </TabPanel>

        </Paper>
    )
}

export { DbiManagement }