import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { ViewPackaging } from './viewPackaging.js';
import { CreatePackaging } from './createPackaging.js';
import { TabPanel } from '../../../shared';

const Packaging = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewPackagin_Tab" label="View Packaging" value="0" />
                <Tab id="CreatePackaging_Tab" label="Create Packaging" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewPackaging />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreatePackaging />
            </TabPanel>
        </Paper>
    );
};

export { Packaging };
