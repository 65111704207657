import { mmFetch } from './mmFetch';

let mmFetchWithResponse = async (url, options) => {
    let respObj = {};
    respObj.status = false;
    respObj.msg = '';
    respObj.data = {};

    try {
        let response = await mmFetch(url, options);

        if (response.status === 403) {
            respObj.msg = 'Forbidden';

            // localStorage.removeItem("user");
            // window.location.href = "/login";
        } else if (response.status === 500) {
            let decodedResp = await response.json();
            respObj.msg = decodedResp.msg ? decodedResp.msg : 'Server error. Contact engineering';
        } else if (response.status === 400) {
            let decodedResp = await response.json();
            respObj.msg = decodedResp.msg;
            respObj.data = decodedResp.data;
        } else if (response.status === 200) {
            respObj.status = true;
            let decodedResp = await response.json();
            respObj.msg = decodedResp.msg;
            respObj.data = decodedResp.data;
        } else {
            console.log('Unrecognized status from MM API ' + response.status);
        }
    } catch (e) {
        respObj.msg = 'Error. Contact engineering. ' + e.toString();
    }

    return respObj;
};

export { mmFetchWithResponse };
