import React, { useState } from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@material-ui/core';
import { ViewGoPuffOrders } from '../components/viewGoPuffOrders.js';
import { ViewGoPuffPOCredits } from '../components/viewGoPuffPOCredits.js';
import { TabPanel } from '../../../shared';

const GoPuffOrderViewer = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab label="View Orders" value="0" />
                <Tab label="View Credits" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewGoPuffOrders />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <ViewGoPuffPOCredits />
            </TabPanel>
        </Paper>
    );
};

export { GoPuffOrderViewer };
