import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Notifications, DataTable } from '../../../shared';
import { shippingApi as api } from '../../../api/shipping';

const StuckShipments = () => {
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [rows, setRows] = useState([]);

    const getStuckShipments = async () => {
        let res = await api.getStuckShipments();
        if (res.status) {
            setRows(res.data.rows);
        }
    };

    useEffect(() => {
        getStuckShipments();
    }, []);

    const columns = [
        { accessor: 'id', Header: 'Shipment ID' },
        { accessor: 'lineHaulID', Header: 'Line Haul ID' },
    ];

    return (
        <Grid container>
            <Notifications options={notification} />
            <Grid item md={12} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                <h1>Stuck shipments</h1>
            </Grid>
            <Grid item md={12} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                <Button
                    onClick={async () => {
                        api.resetStuckShipments();
                    }}
                >
                    Reset
                </Button>
            </Grid>
            <Grid item xs={12}>
                <DataTable data={rows} columns={columns} />
            </Grid>
        </Grid>
    );
};

export { StuckShipments };
