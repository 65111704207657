import React, { useState, useEffect } from 'react';
import { Button, Grid, IconButton, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Notifications, FormComponent, DataTable } from '../../../shared';
import { marketingApi as api } from '../../../api/marketing';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const ProduceList = ({ baseUrl }) => {
    return (
        <div>
            <h2>Produce List</h2>
            <ProduceListItemsComponent />
        </div>
    );
};

let AddItemModal = function ({ closeModal, getData, setNotification }) {
    let formFields = [
        {
            name: 'itemName',
            label: 'Name',
            gridValue: 5,
            inputElement: 'textField',
            inputProps: { maxLength: 255, required: true },
        },
        {
            name: 'countryOfOrigin',
            label: 'Country of Origin',
            gridValue: 3,
            inputElement: 'textField',
            inputProps: { maxLength: 255, required: true },
        },
        {
            name: 'rankNumber',
            label: 'Rank',
            initialValue: 1,
            gridValue: 2,
            inputElement: 'textField',
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'image',
            inputElement: 'file',
            accept: ['.png', '.jpg', '.gif'],
            label: 'Choose Image',
            inputProps: { required: true },
            gridValue: 2,
        },
    ];

    return (
        <Dialog open={true} onClose={closeModal} fullWidth={true} maxWidth="lg">
            <DialogTitle>Create Produce Item</DialogTitle>
            <DialogContent style={{ paddingTop: '0px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let res = await api.createProduceListItem(formData);
                        if (!res.status) {
                            setNotification({ text: 'Failed to create produce list item', level: 'error' });
                            return;
                        }

                        closeModal();
                        setNotification({ text: 'Produce list item created', level: 'success' });
                        resetForm();
                        getData();
                    }}
                />
                <br />
                <br />
            </DialogContent>
        </Dialog>
    );
};

let PhotoModal = ({ selectedPhoto, setPhotoModalOpen, updateItemPhoto }) => {
    let [file, setFile] = useState(null);
    let [source, setSource] = useState(null);
    let [title, setTitle] = useState('');
    let [loading, setLoading] = useState(false);

    let item = selectedPhoto.item;
    let key = selectedPhoto.key;

    useEffect(() => {
        setTitle(`Edit Produce Image for ${item.itemName}`);
    }, []);

    return (
        <Dialog
            open={true}
            onClose={() => {
                setPhotoModalOpen(false);
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {item[key] && !source && <img src={`https://static.misfitsmarket.com/${item[key]}`} alt="Item" />}
                {source && (
                    <div style={{ textAlign: 'center' }}>
                        <img src={source} alt="Preview" />
                    </div>
                )}
                <Button id="ChooseFile" style={{ textAlign: 'center' }} variant="outlined" component="label">
                    Choose File
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        name="Photo Upload"
                        id="photo-file-input"
                        accept="image/png, image/jpeg"
                        onChange={(event) => {
                            let file = event.target.files[0];
                            setFile(file);

                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = (e) => {
                                setSource(e.target.result);
                            };
                        }}
                    />
                </Button>
            </DialogContent>
            <DialogActions>
                <Button
                    id="Confirm"
                    disabled={file === null || loading === true}
                    onClick={async () => {
                        setLoading(true);
                        let res = await updateItemPhoto(item.id, file);
                        setLoading(false);
                        if (res === true) {
                            setPhotoModalOpen(false);
                        }
                    }}
                >
                    Confirm
                </Button>
                <Button id="Cancel" disabled={loading} onClick={() => setPhotoModalOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

let PhotoCell = ({ row, column, setPhotoModalOpen, setSelectedPhoto }) => {
    let key = column.id;
    if (row.original[key]) {
        return (
            <div>
                <Button
                    id={`${column.Header.replaceAll(' ', '')}_${row.id}`}
                    onClick={() => {
                        setSelectedPhoto({ item: row.original, key });
                        setPhotoModalOpen(true);
                    }}
                >
                    <img
                        src={`https://static.misfitsmarket.com/${row.original[key]}`}
                        style={{ height: '100px', width: '100px' }}
                    />
                </Button>
            </div>
        );
    } else {
        return (
            <div>
                <IconButton
                    id={`${column.Header.replaceAll(' ', '')}_${row.id}_upload`}
                    onClick={() => {
                        setSelectedPhoto({ item: row.original, key });
                        setPhotoModalOpen(true);
                    }}
                >
                    <CloudUploadIcon />
                </IconButton>
            </div>
        );
    }
};

let ProduceListItemsComponent = () => {
    let [itemInformation, setItemInformation] = useState([]);
    let [photoModalOpen, setPhotoModalOpen] = useState(false);
    let [selectedPhoto, setSelectedPhoto] = useState({});
    let [notification, setNotification] = useState({ text: null, level: null });
    let [addItemModalOpen, setAddItemModalOpen] = useState(false);

    let getItemInformation = async () => {
        let response = await api.getProduceList();

        if (response.status === true) {
            setItemInformation(
                response.data.rows.map((row) => {
                    row.id = row.id;
                    return row;
                })
            );
        }
    };

    let updateItemPhoto = async (id, file) => {
        let item = {};
        item['image'] = file;

        let res = await api.updateProduceListItem(id, item);
        if (res.status === true) {
            getItemInformation();
        } else {
            setNotification({ text: 'Error uploading photo: ' + res.msg, level: 'error' });
        }
        return res.status;
    };

    let deleteItem = async (id) => {
        let res = await api.deleteProduceListItem(id);
        if (!res.status) {
            setNotification({ text: 'Failed to delete produce list item', level: 'error' });
            return;
        }

        setNotification({ text: 'Produce list item deleted', level: 'success' });
        getItemInformation();
    };

    useEffect(() => {
        getItemInformation();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID', width: 50 },
        { accessor: 'itemName', Header: 'Name', editable: true, editProps: { type: 'input', multiline: true } },
        { accessor: 'countryOfOrigin', Header: 'Country of Origin', editable: true, editProps: { type: 'input' } },
        {
            accessor: 'rankNumber',
            Header: 'Rank',
            width: '150px',
            editable: true,
            editProps: { type: 'input', inputType: 'number' },
        },
        {
            accessor: 'photoURL',
            width: '125px',
            Header: 'Produce Photo',
            editable: true,
            Cell: ({ row, saveEdit, column }) =>
                PhotoCell({ selectedPhoto, setSelectedPhoto, row, column, saveEdit, setPhotoModalOpen }),
        },
        {
            accessor: 'delete',
            Header: 'Delete',
            Cell: ({ cell: { value: initialValue }, row }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Delete_${row.id}`}
                            onClick={async () => {
                                let response = await deleteItem(row.original.id);
                                if (response.status === false) {
                                    setNotification({
                                        text: 'Failed to delete produce list item ' + response.msg,
                                        level: 'error',
                                    });
                                    return;
                                }
                                setNotification({ text: 'Deleted produce list item', level: 'success' });
                                await getItemInformation();
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </div>
                );
            },
        },
    ];
    return (
        <Grid container>
            <Notifications options={notification} />
            <Button
                id="AddItem"
                style={{ margin: '0 auto' }}
                onClick={async (e) => {
                    e.stopPropagation();
                    setAddItemModalOpen(true);
                }}
            >
                Add Item
            </Button>
            {photoModalOpen && (
                <PhotoModal
                    setPhotoModalOpen={setPhotoModalOpen}
                    selectedPhoto={selectedPhoto}
                    updateItemPhoto={updateItemPhoto}
                />
            )}
            {addItemModalOpen === true && (
                <AddItemModal
                    setNotification={setNotification}
                    closeModal={() => {
                        setAddItemModalOpen(false);
                    }}
                    getData={getItemInformation}
                />
            )}
            <DataTable
                editable={true}
                columns={columns}
                data={itemInformation}
                saveEdit={async ({ id, field, value }) => {
                    let item = {};
                    item[field] = value;

                    let response = await api.updateProduceListItem(id, item);
                    if (response.status === false) {
                        let message = 'Error modifying produce list item';
                        if (response) {
                            message += ': ' + response.msg;
                        }
                        setNotification({ text: message, level: 'error' });
                    } else {
                        setNotification({ text: 'Produce list item modified', level: 'success' });
                        await getItemInformation();
                    }
                    return response.status;
                }}
            />
        </Grid>
    );
};

export { ProduceList };
