import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Notifications, DataTable, FormComponent, TabPanel } from '../../../shared';
import { financeApi as api } from '../../../api/finance';
import { Button, Dialog, DialogContent, DialogTitle, Paper, Tabs, Tab, Grid, TextField } from '@material-ui/core';

const DriverTipsDriverReport = () => {
    let [rows, setRows] = useState([]);
    let [reportIsReady, setReportIsReady] = useState(false);
    let [reportButtonText, setReportButtonText] = useState('Loading...');
    const [notification, setNotification] = useState({ text: '', level: '' });

    const [reportStartDate, setReportStartDate] = React.useState(moment().subtract(14, 'days').format('YYYY-MM-DD'));
    let handleReportStartDateChange = (event) => {
        setReportStartDate(event.target.value);
    };

    const [reportEndDate, setReportEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    let handleReportEndDateChange = (event) => {
        setReportEndDate(event.target.value);
    };

    let handleRunReportClick = (event) => {
        getAndRenderReportData();
    };

    let getAndRenderReportData = async () => {
        setReportIsReady(false);

        setReportButtonText('Loading...');
        setRows([]);

        let response = await api.getDriverTipsDriverReport(reportStartDate, reportEndDate);

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportButtonText('Run Report');
            setReportIsReady(true);
            return;
        }

        setRows(response.data.rows);
        setReportButtonText('Run Report');
        setReportIsReady(true);
    };

    useEffect(() => {
        getAndRenderReportData();
    }, []);

    let handleSyncPressed = async () => {
        setReportIsReady(false);
        setReportButtonText('Syncing Time Cards...');
        setRows([]);

        let response = await api.syncDriverTipsTimecards();

        if (response.status === false) {
            setNotification({ text: response.msg, level: 'error' });
        } else {
            setNotification({ text: 'Time cards synced', level: 'success' });
        }
        
        getAndRenderReportData();
    };

    let bulkActions = [];

    bulkActions.push({
        name: 'Delete',
        action: async (ids) => {
            if (window.confirm('Are you sure you want to delete?') === false) {
                return true;
            }

            let response = await api.deleteDriverTipsDriverRecords(ids);

            if (response.status === false) {
                setNotification({ text: 'Unable to delete ' + response.msg, level: 'error' });
                return false;
            }

            setNotification({ text: 'Records removed', level: 'success' });
            getAndRenderReportData();
            return true;
        },
    });

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'costCenter', Header: 'Cost Center (Location)' },
        { accessor: 'date', Header: 'Date' },
        { accessor: 'driver', Header: 'Driver' },
        { accessor: 'employeeID', Header: 'Employee ID' },
        { accessor: 'hourlyRate', Header: 'Tip Rate / hour (P)' },
        { accessor: 'individualTipsEarned', Header: 'Tips Earned (I)' },
        { accessor: 'poolTipsEarned', Header: 'Tips Earned (P)' },
        { accessor: 'hoursWorked', Header: 'Hours worked' },
        { accessor: 'minutesWorked', Header: 'Minutes worked' },
        { accessor: 'payout', Header: 'Total Payout (P+I)' },
    ];

    return (
        <div>
            <Notifications options={notification} />
            <Grid item md={12} style={{ paddingRight: '35px' }}>
                <TextField
                    id="reportStartDate"
                    label="Start Date"
                    type="date"
                    value={reportStartDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportStartDateChange}
                    style={{ marginRight: '50px' }}
                />

                <TextField
                    id="reportEndDate"
                    label="End Date"
                    type="date"
                    value={reportEndDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportEndDateChange}
                    style={{ marginRight: '50px' }}
                />

                <Button
                    id="runReport"
                    onClick={handleRunReportClick}
                    color="secondary"
                    disabled={!reportIsReady}
                >
                    {reportButtonText}
                </Button>

                <Button
                    id="syncTimeCards"
                    onClick={handleSyncPressed}
                    color="secondary"
                    disabled={!reportIsReady}
                >
                    Sync Time Cards
                </Button>
            </Grid>
            <DataTable 
                columns={columns} 
                data={rows} 
                csvExport={true}
                csvExportFileName="driver-employee-report"
                csvExportHeadersUseAccessor={true}
                selectMultiple={true}
                bulkActions={bulkActions}
            />
        </div>
    );
};

export { DriverTipsDriverReport };
