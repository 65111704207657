import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { FormComponent, Notifications } from '../../../shared';
import { giftCodeApi as api } from '../../../api/giftCodes';

let CreateSystemGiftCodes = () => {
    let [notification, setNotification] = useState({ text: null, level: null });

    let formFields = [
        {
            name: 'amount',
            inputElement: 'textField',
            label: 'Amount',
            gridValue: 6,
            inputProps: { type: 'number', integer: true, required: true },
        },
        {
            name: 'numCreate',
            inputElement: 'textField',
            label: 'Number to create',
            gridValue: 6,
            inputProps: { type: 'number', integer: true, required: true },
        },
    ];

    return (
        <div>
            <h1>Create gift codes</h1>
            <Notifications options={notification} />
            {}
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let response = await api.createCodes(formData);
                        if (response.status === false) {
                            setNotification({ text: 'Unable to create codes ' + response.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        resetForm();
                    }}
                />
            </div>
        </div>
    );
};

export { CreateSystemGiftCodes };
