import { mmFetchWithResponse } from './mmFetchWithResponse';

let receivingApi = {
    getReceivablePackaging: async (facilityID) => {
        let url = `api/receiving/v1/packaging?facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getReceivablePurchaseOrders: async (facilityID) => {
        let url = `api/receiving/v1/purchaseOrder?facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getReceivableGroceryAndPackagingPurchaseOrders: async (facilityID) => {
        let url = `api/receiving/v2/purchaseOrder?facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getReceivedGroceryAndPackagingPurchaseOrders: async (facilityID) => {
        let url = `api/receiving/v2/receivedPurchaseOrder?facilityID=` + facilityID;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    receivePackaging: async (item) => {
        let url = `api/receiving/v1/packaging/` + item.poID;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    receivePurchaseOrder: async (item) => {
        let url = `api/receiving/v1/purchaseOrder/` + item.poID;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(item),
        });
        return response;
    },
    receivePurchaseOrderWithPalletCounts: async (poID, sendData) => {
        let url = `api/receiving/v2/purchaseOrder/${poID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendData),
        });
        return response;
    },
    receivePackagingPurchaseOrderWithPalletCounts: async (poID, sendData) => {
        let url = `api/receiving/v2/packagingPO/${poID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendData),
        });
        return response;
    },
    receiveTransferASNAtImperfectFacility: async (poID) => {
        let url = `api/receiving/v2/purchaseOrder/imperfect/${poID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    receiveLineItemWithPalletCounts: async ({
        poMapID,
        pallets,
        receiveQty0,
        uniqueExpirationDates,
        overrideExpirationDateBadgeNumber,
    }) => {
        let url = `api/receiving/v2/purchaseOrder/lineItem/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({
                pallets: pallets,
                receiveQty0: receiveQty0,
                uniqueExpirationDates: uniqueExpirationDates,
                overrideExpirationDateBadgeNumber,
            }),
        });
        return response;
    },
    receiveNetSuiteLineItem: async ({ poMapID, pallets, receiveQty0 }) => {
        let url = `api/receiving/v2/purchaseOrder/netsuiteLineItem/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({
                pallets: pallets,
                receiveQty0: receiveQty0,
            }),
        });
        return response;
    },
    receivePackagingLineItemWithPalletCounts: async ({ poMapID, pallets, receiveQty0 }) => {
        let url = `api/receiving/v2/packagingPO/lineItem/${poMapID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify({
                pallets: pallets,
                receiveQty0: receiveQty0,
            }),
        });
        return response;
    },
    getPurchaseOrderReceivingProgress: async (poID, type) => {
        let url = `api/receiving/v2/purchaseOrder/progress?poID=${poID}&type=${type}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
};

export { receivingApi };
