import React, { useState, useEffect, useContext } from 'react';
import { Button } from '@material-ui/core';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { trainingApi as api } from '../../../api/training';
import { UserContext } from '../../../contexts/UserContext';

let Training = () => {
    let [notification, setNotification] = useState({ text: null, level: null });
    let [rows, setRows] = useState([]);
    const user = useContext(UserContext);

    let getRows = async () => {
        let response = await api.getAllTrainingScenarios(user.getFacilityID());

        if (response.status === false) {
            setNotification({ text: 'Failed to find training scenarios', level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getRows();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'description', Header: 'Scenario' },
        {
            Header: 'Picks',
            Cell: ({ row }) => {
                return (
                    <div>
                        <ul>
                            {row.original.picks.map((item) => (
                                <li key={item.slot}>
                                    Slot {item.slot}: {item.itemName}. Qty: {item.pickQty}. {item.outOfStock && 'OOS'}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            },
        },
        {
            Header: 'Download',
            Cell: ({ row }) => {
                return (
                    <Button
                        onClick={() => {
                            api.getTrainingLPN(row.original.id);
                        }}
                    >
                        Download
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h1>Training Labels</h1>
            <Notifications options={notification} />
            <div>
                <DataTable columns={columns} data={rows} />
            </div>
        </div>
    );
};

export { Training };
