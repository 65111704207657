import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { CreateSystemGiftCodes } from '../components/createSystemGiftCodes.js';
import { ViewSystemGiftCodes } from '../components/viewSystemGiftCodes.js';
import { TabPanel } from '../../../shared';

const SystemGiftCodes = () => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Paper>
            <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                <Tab id="ViewSystemCodes_Tab" label="View System Codes" value="0" />
                <Tab id="CreateSystemCodes_Tab" label="Create System Codes" value="1" />
            </Tabs>
            <TabPanel selectedTab={selectedTab} index={'0'}>
                <ViewSystemGiftCodes />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={'1'}>
                <CreateSystemGiftCodes />
            </TabPanel>
        </Paper>
    );
};

export { SystemGiftCodes };
