import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { Notifications, DataTable, FormComponent, UploadCSVAndReviewComponent } from '../../../shared';
import { holidayOffsetApi as api } from '../../../api/holidayOffsets';
import { logisticsApi } from '../../../api/logistics';

const ViewHolidayOffsets = () => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: '', level: '' });
    const [lineHauls, setLineHauls] = useState([]);
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const [formModalOpen, setFormModalOpen] = useState(false);
    const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);

    useEffect(() => {
        getHolidayOffsets();

        const getLineHauls = async () => {
            const res = await logisticsApi.getLineHauls('activeonly');
            if (!res.status) {
                setNotification({ text: res.msg, level: 'error' });
                return;
            }
            setLineHauls(res.data.lineHauls.filter((item) => !item.fbmPartnerName));
        };
        getLineHauls();
    }, []);

    const getHolidayOffsets = async () => {
        const res = await api.getHolidayOffsets();
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
            return;
        }
        setRows(res.data.offsets);
    };

    const handleEdit = async ({ id, field, value }) => {
        const res = await api.updateHolidayOffset(id, { [field]: value });
        if (!res.status) {
            setNotification({ text: res.msg, level: 'error' });
        }
        getHolidayOffsets();
        return res.status;
    };

    const columns = [
        { Header: 'Offset ID', accessor: 'id' },
        {
            Header: 'Line Haul ID',
            accessor: 'lineHaulID',
            editable: (row) => row.original.canCancel,
            editProps: { type: 'select', options: lineHauls.map((item) => ({ text: item.id, value: item.id })) },
        },
        { Header: 'LM Carrier', accessor: 'carrier' },
        { Header: 'Hub', accessor: 'hub' },
        {
            Header: 'Pack Week',
            accessor: 'packWeek',
            editable: (row) => row.original.canCancel,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        {
            Header: 'Pack Year',
            accessor: 'packYear',
            editable: (row) => row.original.canCancel,
            editProps: { type: 'input', inputType: 'number', integer: true },
        },
        { Header: 'Original Ship Day', accessor: 'originalShipDay' },
        {
            Header: 'New Ship Day',
            accessor: 'shipDay',
            editable: (row) => row.original.canCancel,
            editProps: { type: 'select', options: daysOfWeek },
        },
        { Header: 'Original Injection Day', accessor: 'originalInjectionDay' },
        {
            Header: 'New Injection Day',
            accessor: 'injectionDay',
            editable: (row) => row.original.canCancel,
            editProps: { type: 'select', options: daysOfWeek },
        },
        { Header: 'Reason', accessor: 'reason', editable: (row) => row.original.canCancel },
        { Header: 'Notes', accessor: 'notes', editable: (row) => row.original.canCancel },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Created', accessor: 'createdAt.date' },
        { Header: 'Created By', accessor: 'createdBy' },
        {
            Header: 'Cancel',
            accessor: 'cancel',
            Cell: ({ row }) => {
                if (row.original.canCancel) {
                    return (
                        <Button
                            onClick={async () => {
                                if (window.confirm('Cancel holiday offset?')) {
                                    const res = await api.cancelHolidayOffset(row.original.id);
                                    if (!res.status) {
                                        setNotification({ text: res.msg, level: 'error' });
                                        return;
                                    }

                                    getHolidayOffsets();
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    );
                }
                return '';
            },
        },
        { Header: 'Activate', accessor: 'activate' },
    ];

    const toolbarActions = [
        {
            name: 'Create New Offset',
            action: () => setFormModalOpen(true),
        },
        {
            name: 'Bulk Upload Offsets',
            action: () => setBulkUploadModalOpen(true),
        },
    ];

    return (
        <>
            <Notifications options={notification} />
            <h1>Holiday Offsets</h1>
            <h4>Offsets can be created and canceled up until 2pm on the Monday before the pack week's window opens.</h4>
            <FormModal
                open={formModalOpen}
                closeModal={() => {
                    setFormModalOpen(false);
                }}
                lineHauls={lineHauls}
                daysOfWeek={daysOfWeek}
                setNotification={setNotification}
                getOffsets={getHolidayOffsets}
            />
            <BulkUploadModal
                open={bulkUploadModalOpen}
                closeModal={() => {
                    setBulkUploadModalOpen(false);
                }}
                setNotification={setNotification}
                getOffsets={getHolidayOffsets}
            />
            <DataTable
                columns={columns}
                data={rows}
                editable={true}
                saveEdit={handleEdit}
                toolbarActions={toolbarActions}
            />
        </>
    );
};

const BulkUploadModal = ({ open, closeModal, setNotification, getOffsets }) => {
    let columns = [
        { Header: 'Line Haul ID', accessor: 'lineHaulID' },
        { Header: 'Pack Week', accessor: 'packWeek' },
        { Header: 'Pack Year', accessor: 'packYear' },
        { Header: 'Ship Day', accessor: 'shipDay' },
        { Header: 'Injection Day', accessor: 'injectionDay' },
        { Header: 'Reason', accessor: 'reason' },
        { Header: 'Notes', accessor: 'notes' },
        { Header: 'Errors', accessor: 'errors' },
    ];

    const upload = async (formData) => {
        const res = await api.bulkUploadHolidayOffset(formData);
        return res;
    };

    const confirm = async (key) => {
        const res = await api.confirmBulkUpload(key);
        return res;
    };

    const downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Line Haul ID,Pack Week,Pack Year,';
        csvContent += 'Ship Day,Injection Day,Reason,Notes';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'holiday_offset_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <Dialog open={open} onClose={closeModal} maxWidth="lg" fullWidth={true}>
            <DialogTitle>Bulk Create Holiday Offsets</DialogTitle>
            <DialogContent>
                <UploadCSVAndReviewComponent
                    fileLabel="Offset File"
                    columns={columns}
                    upload={upload}
                    confirm={confirm}
                    downloadTemplate={downloadTemplate}
                    onSuccess={getOffsets}
                />
            </DialogContent>
        </Dialog>
    );
};

const FormModal = ({ lineHauls, daysOfWeek, setNotification, open, closeModal, getOffsets }) => {
    const lineHaulOpts = lineHauls.map((lh) => ({
        text: `ID ${lh.id} - ${lh.carrier} - ${lh.hub} - ${lh.arrivalDay}`,
        value: lh.id,
    }));
    const formFields = [
        {
            label: 'Line Haul',
            name: 'lineHaulID',
            inputElement: 'autocomplete',
            inputProps: { required: true, opts: lineHaulOpts },
        },
        {
            label: 'Pack Week',
            name: 'packWeek',
            gridValue: 6,
            inputProps: { required: true, type: 'number', integer: true, min: '1', max: '53' },
        },
        {
            label: 'Pack Year',
            name: 'packYear',
            gridValue: 6,
            inputProps: { required: true, type: 'number', integer: true },
        },
        {
            label: 'Ship Day',
            name: 'shipDay',
            inputElement: 'select',
            gridValue: 6,
            inputProps: { required: true, type: 'select', opts: daysOfWeek },
        },
        {
            label: 'Injection Day',
            name: 'injectionDay',
            inputElement: 'select',
            gridValue: 6,
            inputProps: { required: true, type: 'select', opts: daysOfWeek },
        },
        {
            label: 'Reason',
            name: 'reason',
            inputProps: { required: true },
        },
        {
            label: 'Notes',
            name: 'notes',
        },
    ];

    const handleSubmit = async (formData, resetForm) => {
        const response = await api.createHolidayOffset(formData);
        if (response.status === false) {
            setNotification({ text: response.msg, level: 'error' });
            return;
        }

        setNotification({ text: 'Success!', level: 'success' });
        getOffsets();
        resetForm();
    };

    return (
        <Dialog open={open} onClose={closeModal} maxWidth="lg" fullWidth={true}>
            <DialogTitle>Create Holiday Offset</DialogTitle>
            <DialogContent>
                <FormComponent formFields={formFields} onSubmit={handleSubmit} />
            </DialogContent>
        </Dialog>
    );
};

export { ViewHolidayOffsets };
