import React, { useEffect, useState } from 'react';
import { useSelect, useCombobox, useMultipleSelection } from 'downshift';
import './input.css';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const Input = ({ label, required, type, value, onChange, readOnly = false, RightIcon }) => {
    return (
        <div className="partnerships-form-field">
            <div className="partnerships-form-field-container">
                <label>
                    {label} {required && <span style={{ color: 'red' }}>*</span>}{' '}
                </label>
                <input
                    className="partnerships-form-field-input"
                    disabled={readOnly}
                    readOnly={readOnly}
                    required={required}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    value={value || ''}
                    type={type}
                ></input>
                {!!RightIcon && <div>{React.cloneElement(RightIcon)}</div>}
            </div>
        </div>
    );
};

const Combobox = ({
    label,
    required,
    options,
    onChange,
    value,
    filterableFields = [],
    optionClassNames = '',
    OptionComponent,
    noOptionsText = '',
}) => {
    const getFilterOptions = (inputValue) => {
        const lowercaseInput = inputValue.toLowerCase();

        return function filterOptions(option) {
            if (!inputValue) {
                return true;
            }

            let filterableValues = filterableFields
                .map((key) => option[key])
                .filter((item) => !!item && typeof item === 'string');
            if (!!option.text) {
                filterableValues.push(option.text);
            }

            return filterableValues.some((val) => val.toLowerCase().includes(lowercaseInput));
        };
    };
    const [items, setItems] = useState(options);
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
    } = useCombobox({
        onInputValueChange({ inputValue }) {
            if (!inputValue) {
                this.onSelectedItemChange({ selectedItem: { value: '' } });
            }
            setItems(options.filter(getFilterOptions(inputValue)));
        },
        onSelectedItemChange: onChange,
        items,
        selectedItem:
            value && options.find((item) => item.value === value) ? options.find((item) => item.value === value) : null,
        itemToString(item) {
            return item ? item.text : '';
        },
    });

    useEffect(() => {
        setItems(options);
    }, [options]);

    return (
        <div className="partnerships-form-field">
            <div className="partnerships-form-field-container">
                <label {...getLabelProps()}>
                    {label} {required && <span style={{ color: 'red' }}>*</span>}
                </label>
                <div className="partnerships-combobox-input-container">
                    <input required={required} className="partnerships-form-field-input" {...getInputProps()} />
                    {!isOpen && !selectedItem && (
                        <ArrowDropDownRoundedIcon className="partnerships-combobox-icon partnerships-gray-icon partnerships-icon-40px" />
                    )}
                    {!isOpen && !!selectedItem && (
                        <ClearRoundedIcon
                            onClick={() => {
                                onChange({ selectedItem: { value: '' } });
                            }}
                            className="partnerships-combobox-icon partnerships-gray-icon partnerships-icon-40px"
                        />
                    )}
                </div>
            </div>
            <ul
                className={`partnerships-form-field-custom-select-items-container ${
                    !(isOpen && items.length) && 'display-none-'
                }`}
                {...getMenuProps()}
            >
                {isOpen &&
                    items.map((item, index) => (
                        <li
                            className={`partnerships-form-field-custom-select-item grotesk-regular ${optionClassNames}`}
                            key={`${item.value}${index}`}
                            {...getItemProps({ item, index })}
                        >
                            {!!OptionComponent ? (
                                <div>{React.cloneElement(OptionComponent, { item })}</div>
                            ) : (
                                <span className="grotesk-regular">{item.text}</span>
                            )}
                        </li>
                    ))}
                {isOpen && !items.length && !!noOptionsText && (
                    <li className="partnerships-form-field-custom-select-item grotesk-regular partnership-form-field-option-disabled">
                        {noOptionsText}
                    </li>
                )}
            </ul>
        </div>
    );
};

const MultiSelect = ({ label, required, options, onChange, value }) => {
    const { getSelectedItemProps, getDropdownProps, selectedItems } = useMultipleSelection({
        selectedItems: options.filter((item) => value.includes(item.value)),
    });

    const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } =
        useSelect({
            items: options,
            selectedItem: '',
            stateReducer: (state, actionAndChanges) => {
                const { changes, type } = actionAndChanges;
                switch (type) {
                    case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
                    case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
                    case useSelect.stateChangeTypes.ItemClick:
                        return {
                            ...changes,
                            isOpen: true, // keep the menu open after selection.
                            highlightedIndex: 0, // with the first option highlighted.
                        };
                }
                return changes;
            },
        });

    return (
        <div className="partnerships-form-field" key={label}>
            <div className="partnerships-form-field-container">
                <label {...getLabelProps()}>
                    {label} {required && <span style={{ color: 'red' }}>*</span>}{' '}
                </label>

                <div className="partnerships-form-field-custom-select" {...getToggleButtonProps()}>
                    {selectedItems.map((item, index) => {
                        return (
                            <span
                                className="partnerships-multiselect-selected-item grotesk-regular"
                                key={`selected-item-${index}`}
                                {...getSelectedItemProps({
                                    selectedItem: item,
                                    index,
                                })}
                            >
                                {item.text}
                                <span
                                    className="clickable"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        let tmp = [...selectedItems];
                                        tmp = tmp.filter((tmpItem) => tmpItem.value !== item.value);
                                        onChange({ selectedItems: tmp });
                                    }}
                                >
                                    &nbsp;&nbsp;
                                    <span className="grotesk-bold">X</span>
                                </span>
                            </span>
                        );
                    })}
                    {!isOpen && (
                        <div
                            {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}
                            className="select-arrow-icon"
                        >
                            <ArrowDropDownRoundedIcon className="select-arrow-icon partnerships-gray-icon partnerships-icon-40px" />
                        </div>
                    )}
                </div>
                <ul {...getMenuProps()} className="partnerships-form-field-custom-select-items-container">
                    {isOpen &&
                        options
                            .filter((option) => !selectedItems.map((item) => item.value).includes(option.value))
                            .map((item, index) => (
                                <li
                                    style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
                                    className="partnerships-form-field-custom-select-item grotesk-regular"
                                    key={`${item}${index}`}
                                    {...getItemProps({
                                        item,
                                        index,
                                        onClick: () => {
                                            onChange({ selectedItems: [...selectedItems, item] });
                                        },
                                    })}
                                >
                                    {item.text}
                                </li>
                            ))}
                </ul>
                {/* if you Tab from menu, focus goes on button, and it shouldn't. only happens here. */}
                <div tabIndex="0" />
            </div>
        </div>
    );
};

const Select = ({ label, required, options, onChange, value, readOnly = false, small = false, noOptionsText = '' }) => {
    const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } =
        useSelect({
            items: options,
            onSelectedItemChange: onChange,
            selectedItem:
                value && options.find((item) => item.value === value)
                    ? options.find((item) => item.value === value)
                    : null,
        });

    return (
        <div className="partnerships-form-field">
            <div className="partnerships-form-field-container">
                <label {...getLabelProps()}>
                    {label} {required && <span style={{ color: 'red' }}>*</span>}{' '}
                </label>

                {/* <input style={{display: "none"}} required={required} value={selectedItem?.value}></input> */}
                <div
                    className={`
                        partnerships-form-field-custom-select 
                        ${small && 'partnerships-form-field-custom-select-small'} 
                        ${readOnly && 'partnerships-form-field-custom-select-read-only'}`}
                    {...getToggleButtonProps()}
                >
                    {!!selectedItem ? selectedItem.text : ' '}
                    {!isOpen && !readOnly && (
                        <ArrowDropDownRoundedIcon className="select-arrow-icon partnerships-gray-icon partnerships-icon-40px" />
                    )}
                </div>
                <ul {...getMenuProps()} className="partnerships-form-field-custom-select-items-container">
                    {isOpen &&
                        !readOnly &&
                        options.map((item, index) => (
                            <li
                                style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
                                className="partnerships-form-field-custom-select-item grotesk-regular"
                                key={`${item}${index}`}
                                {...getItemProps({ item, index })}
                            >
                                {item.text}
                            </li>
                        ))}
                    {isOpen && !readOnly && !options.length && !!noOptionsText && (
                        <li className="partnerships-form-field-custom-select-item grotesk-regular partnership-form-field-option-disabled">
                            {noOptionsText}
                        </li>
                    )}
                </ul>
                {/* if you Tab from menu, focus goes on button, and it shouldn't. only happens here. */}
                <div tabIndex="0" />
            </div>
        </div>
    );
};

const DropdownMultipleCombobox = ({
    label,
    required,
    options,
    onChange,
    value,
    OptionComponent,
    optionClassNames = '',
}) => {
    const [inputValue, setInputValue] = useState('');

    const { getSelectedItemProps, getDropdownProps, selectedItems, removeSelectedItem } = useMultipleSelection({
        selectedItems:
            options.length && Array.isArray(value) ? value.map((val) => options.find((opt) => opt.value === val)) : [],
        onStateChange({ selectedItems: newSelectedItems, type }) {
            switch (type) {
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                    onChange({
                        selectedItems: newSelectedItems,
                        resetInputValue: () => {
                            setInputValue('');
                        },
                    });
                    break;
                default:
                    break;
            }
        },
    });

    const getFilteredItems = (value, inputValue) => {
        let filteredOptions = [...options];
        if (!!inputValue) {
            const lowerCasedInputValue = inputValue.toLowerCase();
            filteredOptions = filteredOptions.filter(
                (opt) => !value.includes(opt.value) && opt?.text.toLowerCase().includes(lowerCasedInputValue)
            );
        }
        if (selectedItems.length > 0) {
            const selectedItemValues = selectedItems.map((item) => item?.value);
            filteredOptions = filteredOptions.filter((opt) => !selectedItemValues.includes(opt.value));
        }

        return filteredOptions;
    };

    const items = React.useMemo(() => getFilteredItems(value, inputValue), [value, inputValue, options, selectedItems]);

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        openMenu,
    } = useCombobox({
        items,
        inputValue,
        selectedItem: '',
        stateReducer(state, actionAndChanges) {
            const { changes, type } = actionAndChanges;

            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        ...(changes.selectedItem && { isOpen: true, highlightedIndex: 0 }),
                    };
                default:
                    return changes;
            }
        },
        onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    onChange({
                        selectedItems: [...selectedItems, newSelectedItem],
                        resetInputValue: () => {
                            setInputValue('');
                        },
                    });
                    break;
                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(newInputValue);
                    break;
                default:
                    break;
            }
        },
    });

    return (
        <div
            className="partnerships-form-field"
            onClick={(e) => {
                if (!isOpen) {
                    openMenu();
                }
            }}
        >
            <div className="partnerships-form-field-container">
                <label {...getLabelProps()}>
                    {label} {required && <span style={{ color: 'red' }}>*</span>}
                </label>
                <div>
                    <div className="partnerships-combobox-input-container-2">
                        {selectedItems.map((item, index) => {
                            return (
                                <div
                                    className="partnerships-multi-combobox-selected-item grotesk-regular"
                                    key={`selected-item-${index}`}
                                    {...getSelectedItemProps({
                                        selectedItem: item,
                                        index,
                                    })}
                                >
                                    {item?.text}
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeSelectedItem(item);
                                        }}
                                    >
                                        &nbsp;&nbsp;
                                        <span className="grotesk-bold">X</span>
                                    </span>
                                </div>
                            );
                        })}
                        <input
                            required={required}
                            className="partnerships-form-field-combobox-multiple-input"
                            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
                        />
                        {!isOpen && (
                            <ArrowDropDownRoundedIcon className="partnerships-combobox-icon partnerships-gray-icon partnerships-icon-40px" />
                        )}
                    </div>
                </div>

                <ul
                    className={`partnerships-form-field-custom-select-items-container ${
                        !(isOpen && items.length) && 'display-none'
                    }`}
                    {...getMenuProps()}
                >
                    {isOpen &&
                        items.map((item, index) => (
                            <li
                                className={`partnerships-form-field-custom-select-item grotesk-regular ${optionClassNames}`}
                                key={`${item.value}`}
                                {...getItemProps({ item, index })}
                            >
                                {!!OptionComponent ? (
                                    <div>{React.cloneElement(OptionComponent, { item })}</div>
                                ) : (
                                    <span className="grotesk-regular">{item.text}</span>
                                )}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

export { Input, Select, Combobox, MultiSelect, DropdownMultipleCombobox };
