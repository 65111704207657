import React, { useState } from 'react';
import { Paper, Select, MenuItem, Grid } from '@material-ui/core';
import { FBMAccountPicker } from '../../../shared';

import { ViewReceivingDiscrepancy } from './reports/viewReceivingDiscrepancy.js';
import { ViewPackagingInventory } from './reports/viewPackagingInventory.js';
import { ViewOnTimeDelivery } from './reports/viewOnTimeDelivery.js';
import { ViewExternalSkuUsage } from './reports/viewExternalSkuUsage.js';
import { ViewDailySkuPack } from './reports/viewDailySkuPack.js';

const FBMViewReports = () => {
    const reportList = [
        {
            id: 'receiving-discrepancy',
            displayName: 'Receiving Discrepancy',
        },
        {
            id: 'packaging-inventory',
            displayName: 'Packaging Inventory',
        },
        {
            id: 'on-time-delivery',
            displayName: 'On Time Delivery',
        },
        {
            id: 'external-sku-usage',
            displayName: 'External Sku Usage',
        },
        {
            id: 'daily-sku-pack',
            displayName: 'Daily Sku Pack',
        },
    ];

    let [currentReport, setCurrentReport] = useState(reportList[0].id);

    let handleSelectReportChange = (event) => {
        setCurrentReport(event.target.value);
    };

    const renderSelectedReport = () => {
        switch (currentReport) {
            case 'receiving-discrepancy':
                return <ViewReceivingDiscrepancy />;
            case 'packaging-inventory':
                return <ViewPackagingInventory />;
            case 'on-time-delivery':
                return <ViewOnTimeDelivery />;
            case 'external-sku-usage':
                return <ViewExternalSkuUsage />;
            case 'daily-sku-pack':
                return <ViewDailySkuPack />;
            default:
                return <p>Sorry, this report is not properly configured!</p>;
        }
    };

    return (
        <Paper>
            <FBMAccountPicker fromReportScreen={true} />

            <Grid container>
                <Grid item xs={4}>
                    <span style={{ fontWeight: 'bold', paddingRight: '25px' }}>Report Type:</span>
                    <Select id="frmSelectReport" value={currentReport} onChange={handleSelectReportChange}>
                        {reportList.map((report) => (
                            <MenuItem key={report.id} value={report.id}>
                                {report.displayName}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

            {renderSelectedReport()}
        </Paper>
    );
};

export { FBMViewReports };
