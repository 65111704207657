import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { DataTable, Notifications, FormComponent } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';

let ViewLocations = ({ v2Locations }) => {
    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ text: null, level: null });
    const [tempZones, setTempZones] = useState([]);
    const [types, setTypes] = useState([]);
    const [updatingLocationType, setUpdatingLocationType] = useState(null);
    const [updatingLocationIDs, setUpdatingLocationIDs] = useState([]);
    const [externalFilters, setExternalFilters] = useState({});
    const [activeOnly, setActiveOnly] = useState(true);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

    const user = useContext(UserContext);

    const productionTypeOptions = [
        { text: 'Both', value: 'BOTH' },
        { text: 'Conventional', value: 'CONVENTIONAL' },
        { text: 'Organic', value: 'ORGANIC' },
    ];
    const inventoryTypeOptions = [
        { text: '', value: null },
        { text: 'Pallet', value: 'Pallet' },
        { text: 'Case', value: 'Case' },
    ];

    let canEditField = (accessor, row) => {
        let locationType = types.find((item) => item.type === row.type);
        if (!locationType) {
            return false;
        }
        if (Object.keys(locationType.staticValues).includes(accessor)) {
            return false;
        }

        if (accessor === 'tempZone' && row.type === 'RESERVE') {
            return false;
        }

        return true;
    };

    let columns = [
        // { accessor: "id", Header: "Location ID" },
        { accessor: 'name', Header: 'Name' },
        {
            accessor: 'type',
            Header: 'Type',
            editable: (row) => canEditField('type', row.original),
            // only V1 location types can have their type updated
            editProps: { type: 'select', options: types.filter((item) => item.creatableV1).map((item) => item.type) },
            filterType: 'select',
        },
        {
            accessor: 'active',
            Header: 'Active',
            type: 'checkbox',
            editable: (row) => canEditField('active', row.original),
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'usable',
            Header: 'Sellable',
            type: 'checkbox',
            editable: (row) => canEditField('usable', row.original),
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'tempZone',
            Header: 'Temp Zone',
            editable: (row) => canEditField('tempZone', row.original),
            editProps: { type: 'select', options: tempZones },
            filterType: 'select',
        },
        {
            accessor: 'productionMethod',
            Header: 'OG/CV',
            editable: (row) => canEditField('productionMethod', row.original),
            editProps: { type: 'select', options: productionTypeOptions },
            filterType: 'select',
        },
        {
            accessor: 'inventoryType',
            Header: 'Inventory Type',
            editable: (row) => canEditField('inventoryType', row.original),
            editProps: { type: 'select', options: inventoryTypeOptions },
            filterType: 'select',
        },
        {
            accessor: 'maxPallets',
            Header: 'Max Pallets',
            editable: (row) => canEditField('maxPallets', row.original),
            editProps: { type: 'input', inputType: 'number', integer: true, min: '0' },
        },
        {
            accessor: 'multipleLot',
            Header: 'Multiple Lot',
            type: 'checkbox',
            editable: (row) => canEditField('multipleLot', row.original),
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'multipleSku',
            Header: 'Multiple Sku',
            type: 'checkbox',
            editable: (row) => canEditField('multipleSku', row.original),
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'download',
            Header: 'Label',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                api.bulkDownloadLocationLabels([row.original.id]);
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },

        // hidden columns, used for filtering
        { accessor: 'zone', Header: 'Zone', hiddenColumn: true },
        { accessor: 'aisle', Header: 'Aisle', hiddenColumn: true, filterType: 'includes' },
        { accessor: 'bay', Header: 'Bay', hiddenColumn: true, filterType: 'between' },
        { accessor: 'shelf', Header: 'Shelf', hiddenColumn: true, filterType: 'includes' },
        { accessor: 'position', Header: 'Position', hiddenColumn: true, filterType: 'between' },
    ];

    let bulkActions = [
        {
            name: 'Download Labels',
            action: async (ids) => {
                api.bulkDownloadLocationLabels(ids);
                return true;
            },
        },
    ];

    if (v2Locations) {
        bulkActions.push({
            name: 'Edit Attributes',
            action: async (ids) => {
                let locType = rows.find((item) => item.id === ids[0]).type;
                let canUpdate = ids.every((id) => {
                    let row = rows.find((item) => item.id === id);
                    if (row.type === locType) {
                        return true;
                    }
                    return false;
                });
                if (canUpdate) {
                    let locationType = types.find((item) => item.type === locType);
                    if (
                        [
                            'usable',
                            'tempZone',
                            'productionMethod',
                            'active',
                            'inventoryType',
                            'maxPallets',
                            'multipleLot',
                            'multipleSku',
                        ].every((field) => Object.keys(locationType.staticValues).includes(field))
                    ) {
                        setNotification({
                            text: `Location Type ${locType} has no editable attributes`,
                            level: 'error',
                        });
                        return false;
                    }

                    // Open a Bulk Update attribute modal
                    setUpdatingLocationType(locationType);
                    setUpdatingLocationIDs(ids);
                    return true;
                } else {
                    setNotification({ text: 'Cannot bulk update attributes for mixed location types', level: 'error' });
                    return false;
                }
            },
        });
    }

    let getLocations = async () => {
        let response = await api.getLocations(user.getFacilityID(), activeOnly);

        if (response.status === false) {
            setNotification({ text: 'No locations found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    const getTempZones = async () => {
        let res = await api.getLocationTempZones();
        if (!res.status) {
            setNotification({ text: 'Failed to get location temp zones', level: 'error' });
            return;
        }

        setTempZones(res.data.tempZones);
    };

    const getTypes = async () => {
        let res = await api.getLocationTypesV2(user.getFacilityID());
        if (!res.status) {
            setNotification({ text: 'Failed to get location types', level: 'error' });
            return;
        }

        setTypes(res.data.types);
    };

    let onSaveEdit = async ({ id, field, value }) => {
        let updates = {
            [field]: value,
            ids: [id],
        };
        let response = await api.updateLocations(updates);
        if (response.status === false) {
            let message = 'Error modifying location';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
            return false;
        } else {
            setNotification({ text: 'Location modified!', level: 'success' });
            return true;
        }
    };

    useEffect(() => {
        getLocations();
    }, [activeOnly]);

    useEffect(() => {
        getTempZones();
        getTypes();
    }, []);

    let advancedFilterFormFields = [
        { name: 'zone', label: 'Zone', gridValue: 12 },
        { name: 'aisleStart', label: 'Aisle Start', gridValue: 6 },
        { name: 'aisleEnd', label: 'Aisle End', gridValue: 6 },
        { name: 'bayStart', label: 'Bay Start', gridValue: 6 },
        { name: 'bayEnd', label: 'Bay End', gridValue: 6 },
        { name: 'shelfStart', label: 'Shelf Start', gridValue: 6 },
        { name: 'shelfEnd', label: 'Shelf End', gridValue: 6 },
        { name: 'positionStart', label: 'Position Start', gridValue: 6 },
        { name: 'positionEnd', label: 'Position End', gridValue: 6 },
    ];

    let handleFilterFormDataChange = (formData) => {
        let validLetters = getLettersInRange('A', 'ZZ');
        let ext = {};
        ext.zone = formData.zone;
        if (!!formData.aisleStart) {
            let aisleRange = [formData.aisleStart.toUpperCase()];
            if (!!formData.aisleEnd) {
                aisleRange = validLetters.slice(
                    validLetters.indexOf(formData.aisleStart.toUpperCase()),
                    validLetters.indexOf(formData.aisleEnd.toUpperCase()) + 1
                );
            }
            ext.aisle = aisleRange.length > 0 ? aisleRange : '';
        } else {
            ext.aisle = '';
        }
        if (!!formData.bayStart) {
            let bayRange = [Number(formData.bayStart)];
            bayRange.push(!!formData.bayEnd ? Number(formData.bayEnd) : Number(formData.bayStart));
            ext.bay = bayRange;
        } else {
            ext.bay = '';
        }
        if (!!formData.shelfStart) {
            let shelfRange = [formData.shelfStart.toUpperCase()];
            if (!!formData.shelfEnd) {
                shelfRange = validLetters.slice(
                    validLetters.indexOf(formData.shelfStart.toUpperCase()),
                    validLetters.indexOf(formData.shelfEnd.toUpperCase()) + 1
                );
            }
            ext.shelf = shelfRange.length > 0 ? shelfRange : '';
        } else {
            ext.shelf = '';
        }
        if (!!formData.positionStart) {
            let positionRange = [Number(formData.positionStart)];
            positionRange.push(!!formData.positionEnd ? Number(formData.positionEnd) : Number(formData.positionStart));
            ext.position = positionRange;
        } else {
            ext.position = '';
        }
        setExternalFilters(ext);
    };

    return (
        <div>
            <Notifications options={notification} />
            {updatingLocationType && (
                <BulkUpdateModal
                    locationType={updatingLocationType}
                    locationIDs={updatingLocationIDs}
                    tempZones={tempZones}
                    productionTypeOptions={productionTypeOptions}
                    inventoryTypeOptions={inventoryTypeOptions}
                    setNotification={setNotification}
                    getData={getLocations}
                    closeModal={() => {
                        setUpdatingLocationType(null);
                    }}
                />
            )}
            <Grid container>
                {v2Locations && (
                    <Grid item xs={12}>
                        <Button
                            onClick={() => {
                                setShowAdvancedFilters(!showAdvancedFilters);
                            }}
                            style={{ float: 'right', margin: 0, padding: 0 }}
                            size="small"
                        >
                            {showAdvancedFilters ? 'Hide Filters' : 'Reserve Location Filters'}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={showAdvancedFilters ? 9 : 12}>
                    <h3>Warehouse Locations</h3>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        setActiveOnly(!event.target.checked);
                                    }}
                                    color="primary"
                                    checked={!activeOnly}
                                />
                            }
                            label="Show Deactivated Locations"
                        />
                    </Grid>
                    <DataTable
                        selectMultiple={true}
                        columns={columns}
                        data={rows}
                        editable={true}
                        saveEdit={onSaveEdit}
                        bulkActions={bulkActions}
                        externalFilters={externalFilters}
                    />
                </Grid>
                {showAdvancedFilters && (
                    <Grid item xs={3}>
                        <div
                            style={{
                                marginLeft: '10px',
                                paddingLeft: '10px',
                                backgroundColor: '#e8f0ff',
                                height: '100%',
                            }}
                        >
                            <h3>Reserve Location Filters</h3>
                            <Button
                                onClick={() => {
                                    handleFilterFormDataChange({});
                                    setShowAdvancedFilters(false);
                                }}
                            >
                                Clear External Filters
                            </Button>
                            <FormComponent
                                compact={true}
                                formFields={advancedFilterFormFields}
                                onSubmit={handleFilterFormDataChange}
                                button={{ gridValue: 12 }}
                            />
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

let BulkUpdateModal = ({
    locationType,
    closeModal,
    getData,
    setNotification,
    locationIDs,
    tempZones,
    productionTypeOptions,
    inventoryTypeOptions,
    getLocations,
}) => {
    let formFields = [
        {
            name: 'attribute',
            label: 'Attribute',
            gridValue: 12,
            inputElement: 'select',
            required: true,
            inputProps: {
                opts: [
                    { text: 'Active', value: 'active' },
                    { text: 'Sellable', value: 'usable' },
                    { text: 'Temp Zone', value: 'tempZone' },
                    { text: 'OG/CV', value: 'productionMethod' },
                    { text: 'Inventory Type', value: 'inventoryType' },
                    { text: 'Max Pallets', value: 'maxPallets' },
                    { text: 'Multiple Lot', value: 'multipleLot' },
                    { text: 'Multiple Sku', value: 'multipleSku' },
                ].filter((item) => !Object.keys(locationType.staticValues).includes(item.value)),
            },
        },
        {
            name: 'active',
            label: 'Active',
            required: true,
            display: (formData) => formData.attribute === 'active',
            inputElement: 'select',
            inputProps: { opts: ['YES', 'NO'] },
        },
        {
            name: 'usable',
            label: 'Sellable',
            required: true,
            display: (formData) => formData.attribute === 'usable',
            inputElement: 'select',
            inputProps: { opts: ['YES', 'NO'] },
        },
        {
            name: 'tempZone',
            label: 'Temp Zone',
            required: true,
            display: (formData) => formData.attribute === 'tempZone',
            inputElement: 'select',
            inputProps: { opts: tempZones },
        },
        {
            name: 'productionMethod',
            label: 'OG/CV',
            required: true,
            display: (formData) => formData.attribute === 'productionMethod',
            inputElement: 'select',
            inputProps: { opts: productionTypeOptions },
        },
        {
            name: 'inventoryType',
            label: 'Inventory Type',
            required: true,
            display: (formData) => formData.attribute === 'inventoryType',
            inputElement: 'select',
            inputProps: { opts: inventoryTypeOptions },
        },
        {
            name: 'maxPallets',
            label: 'Max Pallets',
            required: true,
            display: (formData) => formData.attribute === 'maxPallets',
            inputElement: 'textField',
            inputProps: { required: true, type: 'number', integer: true },
        },
        {
            name: 'multipleLot',
            label: 'multiple Lot',
            required: true,
            display: (formData) => formData.attribute === 'multipleLot',
            inputElement: 'select',
            inputProps: { opts: ['YES', 'NO'] },
        },
        {
            name: 'multipleSku',
            label: 'multiple Sku',
            required: true,
            display: (formData) => formData.attribute === 'multipleSku',
            inputElement: 'select',
            inputProps: { opts: ['YES', 'NO'] },
        },
    ];

    return (
        <Dialog open={true} onClose={closeModal} fullWidth={true} maxWidth="lg">
            <DialogTitle>Bulk Update Attributes for {locationIDs.length} locations</DialogTitle>
            <DialogContent style={{ paddingTop: '0px' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        let value = formData[formData.attribute];
                        if (value === 'YES') {
                            value = true;
                        } else if (value === 'NO') {
                            value = false;
                        }
                        let sendParams = {
                            ids: locationIDs,
                            [formData.attribute]: value,
                        };
                        console.log('sendParams', sendParams);

                        let response = await api.updateLocations(sendParams);
                        if (response.status === false) {
                            let message = 'Error updating locations';
                            if (response) {
                                message += ': ' + response.msg;
                            }
                            setNotification({ text: message, level: 'error' });
                        } else {
                            setNotification({ text: 'Locations updated!', level: 'success' });
                            closeModal();
                            getData();
                        }
                    }}
                />
                <br />
                <br />
            </DialogContent>
        </Dialog>
    );
};

function nextChar(c) {
    var u = c.toUpperCase();
    if (same(u, 'Z')) {
        var txt = '';
        var i = u.length;
        while (i--) {
            txt += 'A';
        }
        return txt + 'A';
    } else {
        var p = '';
        var q = '';
        if (u.length > 1) {
            p = u.substring(0, u.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }
        var l = u.slice(-1).charCodeAt(0);
        var z = nextLetter(l);
        if (z === 'A') {
            return p.slice(0, -1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
        } else {
            return p + z;
        }
    }
}

let getLettersInRange = (start, end) => {
    let range = [start];
    let cur = start;
    while (cur != end) {
        cur = nextChar(cur);
        range.push(cur);
    }
    return range;
};

function nextLetter(l) {
    if (l < 90) {
        return String.fromCharCode(l + 1);
    } else {
        return 'A';
    }
}

function same(str, char) {
    var i = str.length;
    while (i--) {
        if (str[i] !== char) {
            return false;
        }
    }
    return true;
}

export { ViewLocations };
