import React, { useState, useEffect } from 'react';
import { InputLabel, Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { DataTable } from '../../../shared';
import { customerApi as api } from '../../../api/customer';
import moment from 'moment';
import { DrinksRefundModal } from './DrinksRefundModal';

const DrinksOrderDetailsModal = ({ customerID, drinksOrderID, open, setOpen }) => {
    const [drinksOrder, setDrinksOrder] = useState(null);

    const getDrinksOrderDetails = async () => {
        let res;
        res = await api.getDrinksOrderDetails(drinksOrderID);

        if (!res) {
            console.log('error getting drinks order details');
            return;
        }
        setDrinksOrder(res.data.orderInfo);
    };

    useEffect(() => {
        if (open) {
            getDrinksOrderDetails();
        }
    }, [open]);

    if (!drinksOrder) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>Drinks Order Information</DialogTitle>
            <DialogContent>
                <div>
                    <strong>Misfits Market Order Number: </strong> {drinksOrder.mmOrderID}
                </div>
                <div>
                    <strong>Drinks Order Number: </strong> {drinksOrder.drinksOrderID}
                </div>
                <div>
                    <strong>Ordered At: </strong>{' '}
                    {drinksOrder.orderDate && `${moment(drinksOrder.orderDate.date).format('MM/DD/YYYY')}`}
                </div>
                <div>
                    <strong>Num Items: </strong> {drinksOrder.totalQty}
                </div>
                <div>
                    <strong>Status: </strong> {drinksOrder.orderStatus}
                </div>
                {drinksOrder?.shipments &&
                    drinksOrder.shipments.map((ship, index) => {
                        return (
                            <div>
                                <strong>Delivery Date Box {index + 1}: </strong>{' '}
                                {ship.estimatedDeliveryDate &&
                                    `${moment(ship.estimatedDeliveryDate.date).format('dddd MM-DD-YYYY')}`}
                            </div>
                        );
                    })}
                <div>
                    <strong>Shipping Addr: </strong> {drinksOrder.shipAddr}
                </div>
                {drinksOrder?.shipments &&
                    drinksOrder.shipments.map((ship, index) => {
                        return (
                            <div>
                                <div>
                                    <strong>Shipping Status Box {index + 1} :</strong> {ship.status}
                                </div>
                                <div>
                                    <strong>Tracking Info Box {index + 1}: </strong> {ship.trackingNumber}
                                </div>
                                <div>
                                    <strong>Tracking URL Box {index + 1} </strong>{' '}
                                    <a href={ship.trackingURL}> {ship.trackingURL} </a>
                                </div>
                            </div>
                        );
                    })}

                <h4>Charge</h4>
                <div>
                    <strong>Subtotal: </strong> ${drinksOrder?.charge?.subtotal}
                </div>
                <div>
                    <strong>Taxes: </strong> ${drinksOrder?.charge?.taxes}
                </div>
                <div>
                    <strong>Alcohol Fees: </strong> ${drinksOrder?.charge?.alcoholFees}
                </div>
                <div>
                    <strong>Total: </strong> ${drinksOrder?.charge?.total}
                </div>

                <h4>Items In Box (${drinksOrder?.charge?.total})</h4>
                {drinksOrder?.items.map((item) => {
                    return (
                        <div key={item.sellableItemModel.id}>
                            {item.sellableItemModel.name} | Qty: {item.qty}
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DrinksOrders = ({ classes, customerID, drinksOrders, getSubscription, setNotification, getDrinksOrders }) => {
    const [drinksOrderDetailsModalOpen, setDrinksOrderDetailsModalOpen] = useState(false);
    const [rowInfo, setRowInfo] = useState(null);
    const [drinksRefundModalOpen, setDrinksRefundModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    let columns = [
        {
            Header: 'Order #',
            accessor: 'mmOrderID',
            cellStyle: { cursor: 'pointer', textDecoration: 'underline' },
            onCellClick: (row) => {
                setSelectedOrder(row.drinksOrderID);
                setDrinksOrderDetailsModalOpen(true);
            },
        },
        { Header: 'Date', accessor: 'orderedAt' },
        { Header: 'Status', accessor: 'orderStatus' },
        { Header: 'Charge Status', accessor: 'chargeStatus' },
        {
            Header: 'Refund',
            Cell: ({ row }) => {
                if (row.original.refunded === true) {
                    return null;
                }

                return (
                    <div>
                        <span
                            onClick={() => {
                                console.log('open drinks order refund modal');
                                setRowInfo(row.original);
                                setSelectedOrder(row.original.drinksOrderID);
                                setDrinksRefundModalOpen(true);
                            }}
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        >
                            Refund
                        </span>
                    </div>
                );
            },
        },
    ];

    let data = drinksOrders.map((item) => {
        item.orderedAt = moment(item?.orderDate?.date).format('MM/DD/YYYY');
        return item;
    });

    return (
        <>
            <DrinksOrderDetailsModal
                customerID={customerID}
                drinksOrderID={selectedOrder}
                open={drinksOrderDetailsModalOpen}
                setOpen={setDrinksOrderDetailsModalOpen}
            />
            <DrinksRefundModal
                customerID={customerID}
                getSubscription={getSubscription}
                getDrinksOrders={getDrinksOrders}
                setNotification={setNotification}
                drinkOrderID={selectedOrder}
                rowInfo={rowInfo}
                open={drinksRefundModalOpen}
                setOpen={setDrinksRefundModalOpen}
            />
            <InputLabel className={classes.inputLabel}>Drinks Orders</InputLabel>
            <DataTable columns={columns} data={data} />
        </>
    );
};

export { DrinksOrders };
