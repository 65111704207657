import React, { useState } from 'react';
import { FormComponent, Notifications } from '../../../shared';
import { csvUtil as util } from '../../../shared/utils/csvUtil';
import { customerApi as api } from '../../../api/customer';
import { CustomerBulkTemplateDownload } from './CustomerBulkTemplateDownload';

const MassCancel = () => {
    const [notification, setNotification] = useState({ text: null, level: null });
    const formFields = [
        {
            name: 'orderIDFile',
            inputElement: 'file',
            accept: ['.csv'],
            label: 'Order Number File',
            gridValue: 12,
            inputProps: { required: true },
        },
    ];

    return (
        <div>
            <h1>Mass Cancel</h1>
            <Notifications options={notification} />
            <CustomerBulkTemplateDownload csvColumns={'Order Number'} fileName={'mass_cancel'}/>
            <div>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        if (formData?.orderIDFile === null) {
                            setNotification({
                                text: 'Please Upload a CSV File containing a column of Order Numbers',
                                level: 'warning',
                            });
                            return;
                        }
                        const fileReader = new FileReader();
                        fileReader.readAsText(formData?.orderIDFile, 'UTF-8');
                        fileReader.onload = async (event) => {
                            const csvData = event.target.result;

                            const cleanCsvResults = await util.cleanCsv(csvData);
                            if (cleanCsvResults?.text && cleanCsvResults?.level) {
                                setNotification(cleanCsvResults);
                                return;
                            }

                            const orderNumberValidationResults = await util.csvColumnIntegerValidation(cleanCsvResults, 'Order Number');
                            if (orderNumberValidationResults?.text && orderNumberValidationResults?.level) {
                                setNotification(orderNumberValidationResults);
                                return;
                            }

                            const response = await api.massCancelOrders(formData?.orderIDFile);
                            if (response.status === false) {
                                setNotification({ text: 'Unable to cancel ' + response.msg, level: 'error' });
                                return;
                            }

                            setNotification({ text: 'Success!', level: 'success' });
                            resetForm();
                        };
                    }}
                />
            </div>
        </div>
    );
};

export { MassCancel };