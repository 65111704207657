import React, { useState, useEffect } from 'react';
import { warehouseApi as api } from '../../../api/warehouse';
import { lighten } from '@material-ui/core/styles';
import { Select, FormControl, InputLabel } from '@material-ui/core';
import './locations.css';

let green = '#76bf87';
let red = '#db3144';

let ViewLocationsInZone = ({
    createLocProgress,
    zone,
    zoneType,
    facilityID,
    selectedLocations = [],
    creatingTypePrefix = '',
}) => {
    const [aisle, setAisle] = useState(null);
    const [aisleOptions, setAisleOptions] = useState([]);
    const [locationsInZone, setLocationsInZone] = useState([]);

    const getLocationsInZone = async () => {
        if (!zone || !zoneType || !creatingTypePrefix) {
            setLocationsInZone([]);
            return;
        }

        let res = await api.getLocationsInZone(facilityID, zone, zoneType, creatingTypePrefix);
        if (!res.status) {
            return;
        }

        setLocationsInZone(res.data.zone);
    };

    useEffect(() => {
        getLocationsInZone();
    }, [zone, zoneType, creatingTypePrefix]);

    useEffect(() => {
        let arr = [];
        if (!!locationsInZone?.aisles) {
            locationsInZone.aisles.forEach((aisle) => {
                arr.push(aisle.aisle);
            });
        }
        if (!!createLocProgress?.aisles?.range) {
            createLocProgress.aisles.range.forEach((aisle) => {
                if (!arr.includes(aisle)) {
                    arr.push(aisle);
                }
            });
        }

        if (arr.length > 0) {
            setAisleOptions(arr);
            if (!aisle || !arr.includes(aisle)) {
                // if a selected range, select first aisle selected
                if (createLocProgress?.aisles?.range?.length > 0) {
                    setAisle(createLocProgress.aisles.range[0]);
                } else {
                    // if no range selected, select first aisle in zone
                    if (arr.length > 0) {
                        setAisle(arr[0]);
                    }
                }
            }
        } else {
            setAisleOptions([]);
        }
    }, [locationsInZone, createLocProgress]);

    let getPositionBackgroundColor = ({ aisle, bay, shelf, position }) => {
        let color = '';
        if (!!createLocProgress && createLocProgress.hasOwnProperty('positions')) {
            for (let i = 0; i < createLocProgress.positions.new.length; i++) {
                let item = createLocProgress.positions.new[i];
                if (item.aisle === aisle && item.bay === bay && item.shelf === shelf && item.position === position) {
                    color = green;
                    break;
                }
            }
            if (color) {
                return color;
            }
            for (let i = 0; i < createLocProgress.positions.existing.length; i++) {
                let item = createLocProgress.positions.existing[i];
                if (item.aisle === aisle && item.bay === bay && item.shelf === shelf && item.position === position) {
                    color = red;
                    break;
                }
            }
            if (color) {
                return color;
            }
            if (color === '') {
                let existingLocations = locationsInZone.positions;
                for (let i = 0; i < existingLocations.length; i++) {
                    let item = existingLocations[i];
                    if (
                        item.aisle === aisle &&
                        item.bay === bay &&
                        item.shelf === shelf &&
                        item.position === position
                    ) {
                        color = '#808080';
                        break;
                    }
                }
            }
            if (color) {
                return color;
            }
        }
        return '#FFFFFF';
    };

    let getRangeForDisplay = (type, bay = null, shelf = null) => {
        let newAndExisting = [];
        if (type === 'aisles') {
            let existingLocations = locationsInZone[type];
            newAndExisting = existingLocations.map((item) => item.aisle);
            if (!!createLocProgress && createLocProgress.hasOwnProperty('aisles')) {
                createLocProgress.aisles.range.forEach((aisle) => {
                    if (!newAndExisting.includes(aisle)) {
                        newAndExisting.push(aisle);
                    }
                });
            }
        } else if (type === 'bays') {
            let existingLocations = locationsInZone[type];
            newAndExisting = existingLocations.filter((item) => item.aisle === aisle).map((item) => item.bay);
            if (!!createLocProgress && createLocProgress.hasOwnProperty('bays')) {
                createLocProgress.bays.new.forEach((bay) => {
                    if (bay.aisle === aisle && !newAndExisting.includes(bay.bay)) {
                        newAndExisting.push(bay.bay);
                    }
                });
            }
            newAndExisting.sort(function (a, b) {
                return a - b;
            });
        } else if (type === 'shelves' || type === 'allShelves') {
            let existingLocations = locationsInZone['shelves'];
            existingLocations = JSON.parse(JSON.stringify(existingLocations)).reverse();
            if (type === 'shelves') {
                newAndExisting = existingLocations
                    .filter((item) => item.aisle === aisle && item.bay === bay)
                    .map((item) => item.shelf);
            } else if (type === 'allShelves') {
                newAndExisting = [
                    ...new Set(existingLocations.filter((item) => item.aisle === aisle).map((item) => item.shelf)),
                ];
            }
            let newShelves = [];
            if (!!createLocProgress && createLocProgress.hasOwnProperty('shelves')) {
                createLocProgress.shelves.new.forEach((shelf) => {
                    if (type === 'allShelves') {
                        if (
                            shelf.aisle === aisle &&
                            !newAndExisting.includes(shelf.shelf) &&
                            !newShelves.includes(shelf.shelf)
                        ) {
                            newAndExisting.push(shelf.shelf);
                        }
                    } else {
                        if (shelf.aisle === aisle && shelf.bay === bay && !newAndExisting.includes(shelf.shelf)) {
                            newAndExisting.push(shelf.shelf);
                        }
                    }
                });
            }

            newAndExisting.sort((a, b) => b.localeCompare(a));
        } else if (type === 'positions') {
            let existingLocations = locationsInZone[type];
            newAndExisting = existingLocations.filter(
                (item) => item.aisle === aisle && item.bay === bay && item.shelf === shelf
            );
            if (!!createLocProgress && createLocProgress.hasOwnProperty('positions')) {
                createLocProgress.positions.new.forEach((position) => {
                    if (
                        position.aisle === aisle &&
                        position.bay === bay &&
                        position.shelf === shelf &&
                        !newAndExisting.includes(position.position)
                    ) {
                        position.name = `${creatingTypePrefix}${zone}-${aisle}${bay}-${shelf}${position.position}`;
                        position.active = true;
                        newAndExisting.push(position);
                    }
                });
            }
            newAndExisting.sort(function (a, b) {
                return a.position - b.position;
            });
        }

        return newAndExisting;
    };

    if (!zone || !zoneType || !Object.keys(locationsInZone).length) {
        return null;
    }
    if (!aisle) {
        return null;
    }
    if (createLocProgress?.errors?.length > 0) {
        return null;
    }

    return (
        <div style={{ width: '100%' }}>
            <FormControl style={{ width: '300px' }}>
                <InputLabel shrink={true}>Select Aisle In Zone {zone}</InputLabel>
                <Select
                    label="Select Aisle To View"
                    value={aisle || ''}
                    onChange={async (event) => {
                        setAisle(event.target.value);
                    }}
                    native
                >
                    {aisleOptions.map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </Select>
            </FormControl>

            <h3 style={{ textAlign: 'center' }}>
                {zoneType} {creatingTypePrefix} Zone {zone} Aisle {aisle} - Existing and New Locations
            </h3>
            {!!createLocProgress ? (
                <>
                    <div style={{ width: '25%', display: 'inline-block' }}>
                        <div
                            style={{
                                height: '25px',
                                width: '25px',
                                border: '1px solid black',
                                backgroundColor: 'gray',
                                display: 'inline-block',
                            }}
                        ></div>
                        <span style={{ fontSize: '13px' }}> - Existing location</span>
                    </div>
                    <div style={{ width: '25%', display: 'inline-block' }}>
                        <div
                            style={{
                                height: '25px',
                                width: '25px',
                                border: '1px solid black',
                                backgroundColor: green,
                                display: 'inline-block',
                            }}
                        ></div>
                        <span style={{ fontSize: '13px' }}> - New location</span>
                    </div>
                    <div style={{ width: '25%', display: 'inline-block' }}>
                        <div
                            style={{
                                height: '25px',
                                width: '25px',
                                border: '1px solid black',
                                backgroundColor: red,
                                display: 'inline-block',
                            }}
                        ></div>
                        <span style={{ fontSize: '13px' }}> - Location would be created but already exists</span>
                    </div>
                    <div style={{ width: '25%', display: 'inline-block' }}>
                        <div
                            style={{
                                height: '25px',
                                width: '25px',
                                border: '1px solid black',
                                backgroundColor: 'white',
                                display: 'inline-block',
                            }}
                        ></div>
                        <span style={{ fontSize: '13px' }}> - Location does not and will not exist</span>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ width: '25%', display: 'inline-block' }}>
                        <div
                            style={{
                                height: '25px',
                                width: '25px',
                                border: '1px solid black',
                                backgroundColor: 'gray',
                                display: 'inline-block',
                            }}
                        ></div>
                        <span style={{ fontSize: '13px' }}> - Existing location</span>
                    </div>
                    <div style={{ width: '25%', display: 'inline-block' }}>
                        <div
                            style={{
                                height: '25px',
                                width: '25px',
                                border: '1px solid black',
                                backgroundColor: 'lightblue',
                                display: 'inline-block',
                            }}
                        ></div>
                        <span style={{ fontSize: '13px' }}> - Selected location</span>
                    </div>
                </>
            )}

            <div style={{ overflow: 'scroll', whiteSpace: 'nowrap' }}>
                <div style={{ display: 'inline-block', paddingLeft: '100px' }}>
                    {getRangeForDisplay('bays').map((bay, bayIndex) => {
                        let allShelvesInAisle = getRangeForDisplay('allShelves', bay);

                        return (
                            <div
                                key={`${zoneType}-${aisle}-${bay}`}
                                style={{
                                    display: 'inline-block',
                                    maxWidth: '600px',
                                    minWidth: '100px',
                                    padding: '2px',
                                }}
                            >
                                {
                                    <div>
                                        {allShelvesInAisle.map((shelf, index) => {
                                            let newAndExistingPositions = getRangeForDisplay('positions', bay, shelf);
                                            return (
                                                <div style={{ position: 'relative' }}>
                                                    {bayIndex === 0 && (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                left: '-80px',
                                                                top: '30px',
                                                                marginLeft: '20px',
                                                            }}
                                                        >
                                                            Shelf {shelf}
                                                        </div>
                                                    )}
                                                    <div key={`${zoneType}-${aisle}-${bay}-${shelf}`}>
                                                        <div
                                                            style={{
                                                                height: '100px',
                                                                width: '100%',
                                                                marginBottom: '2px',
                                                                border: '1px solid black',
                                                            }}
                                                        >
                                                            {newAndExistingPositions.map((position) => {
                                                                return (
                                                                    <div
                                                                        key={`${zoneType}-${aisle}-${bay}-${shelf}-${position.position}`}
                                                                        className={`position ${
                                                                            !position.active ? 'inactive-position' : ''
                                                                        }`}
                                                                        style={{
                                                                            display: 'inline-block',
                                                                            height: '100%',
                                                                            border: '1px solid black',
                                                                            width: `${
                                                                                (1 / newAndExistingPositions.length) *
                                                                                100
                                                                            }%`,
                                                                            backgroundColor:
                                                                                getPositionBackgroundColor(position),
                                                                            padding: '2px',
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className={'position-tooltip'}
                                                                            style={{
                                                                                backgroundColor: lighten(
                                                                                    getPositionBackgroundColor(
                                                                                        position
                                                                                    ),
                                                                                    0.5
                                                                                ),
                                                                            }}
                                                                        >
                                                                            {position.name}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div>Bay {bay}</div>
                                    </div>
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export { ViewLocationsInZone };
