import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';
import { Modal, Button, Select, NativeSelect } from '@material-ui/core';

let ViewMicroGoPuffMFC = ({ stateOpts, mmFacilityOpts }) => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    // Load  table
    let getRows = async () => {
        let response = await api.getMicroFulfillmentCenters();
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getRows();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'name', Header: 'Name', editable: true },
        { accessor: 'lineOne', Header: 'Line One', editable: true },
        { accessor: 'lineTwo', Header: 'Line Two', editable: true },
        { accessor: 'city', Header: 'City', editable: true },
        {
            accessor: 'state',
            Header: 'State',
            type: 'select',
            editable: true,
            editProps: { type: 'select', options: stateOpts },
        },
        { accessor: 'zip', Header: 'Zip', editable: true },
        { accessor: 'phone', Header: 'Phone', editable: true },
        {
            accessor: 'active',
            Header: 'Active',
            type: 'checkbox',
            editable: true,
            editProps: { type: 'checkbox' },
        },
        {
            accessor: 'mmFacilityId',
            Header: 'MM Facility',
            type: 'select',
            editable: true,
            editProps: { type: 'select', options: mmFacilityOpts },
        },
    ];

    return (
        <div>
            <h1>Go Puff Micro Fulfillment Centers</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value, row }) => {
                        let item = {};
                        item[field] = value;
                        let response = await api.updateMicroFulfillmentCenter(id, item);

                        if (response.status === false) {
                            setNotification({ text: 'Failed: ' + response.msg, level: 'error' });
                            return;
                        }

                        setNotification({ text: 'Success!', level: 'success' });
                        getRows();
                    }}
                />
            </div>
        </div>
    );
};

export { ViewMicroGoPuffMFC };
