import React, { useState, useEffect } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { systemApi as api } from '../../../api/system';
import { Modal, Button, Select, NativeSelect } from '@material-ui/core';

let CCPADashboard = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    // Load table
    let getRows = async (packGroupID) => {
        let response = await api.getCCPARequests();
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        getRows();
    }, []);

    let columns = [
        { accessor: 'id', Header: 'ID' },
        { accessor: 'submittedAt', Header: 'Submitted At', type: 'date' },
        { accessor: 'validatedAt', Header: 'Validated At', type: 'date' },
        { accessor: 'requestType', Header: 'Type' },
        { accessor: 'email', Header: 'Email' },
        { accessor: 'firstName', Header: 'First Name' },
        { accessor: 'lastName', Header: 'Last Name' },
        { accessor: 'street', Header: 'Street' },
        { accessor: 'city', Header: 'City' },
        { accessor: 'province', Header: 'State' },
        { accessor: 'zip', Header: 'Zip' },
        { accessor: 'phone', Header: 'Phone' },
        { accessor: 'processedOn', Header: 'Processed On', type: 'date' },
    ];

    return (
        <div>
            <h1>CCPA Requests</h1>
            <Notifications options={notification} />
            {}
            <div>
                <DataTable csvExport={true} csvExportFileName="ccpa_requests" columns={columns} data={rows} />
            </div>
        </div>
    );
};

export { CCPADashboard };
