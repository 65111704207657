import React, { useState, useEffect, useContext } from 'react';
import { DataTable, Notifications } from '../../../shared';
import { warehouseApi as api } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';
import { ActionModal } from './palletAndCaseActions';
import { Button } from '@material-ui/core';

let ViewCases = ({ palletID = null, lotID = null }) => {
    const user = useContext(UserContext);

    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [viewActions, setViewActions] = useState(false);
    let [selectedRow, setSelectedRow] = useState({});
    let [locations, setLocations] = useState([]);

    // Load cases
    let getCases = async () => {
        let response = await api.getCases(user.getFacilityID(), palletID, lotID);

        if (response.status === false) {
            setNotification({ text: 'Failed to get cases' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows);
    };

    let getLocations = async () => {
        let response = await api.getActiveLocations(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No facilities found ' + response.msg, level: 'error' });
            setLocations([]);
            return;
        }

        let locationsAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setLocations([pleaseSelect].concat(locationsAsOpts));
    };

    useEffect(() => {
        getCases();
        getLocations();
    }, []);

    let columns = [
        { accessor: 'caseID', Header: 'Case ID' },
        { accessor: 'caseName', Header: 'Case Name' },
        { accessor: 'sku', Header: 'External Sku' },
        { accessor: 'itemType', Header: 'Item Type' },
        { accessor: 'itemName', Header: 'Item' },
        { accessor: 'numUnits', Header: 'Num Units' },
        { accessor: 'status', Header: 'Status' },
        { accessor: 'caseType', Header: 'Case Type' },
        { accessor: 'locationName', Header: 'Location' },
        { accessor: 'lotID', Header: 'Lot ID' },
        { accessor: 'currentPalletName', Header: 'Current Pallet' },
        { accessor: 'originPalletName', Header: 'Origin Pallet' },
        {
            accessor: 'actions',
            Header: 'Actions',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            onClick={() => {
                                setSelectedRow(row.original);
                                setViewActions(true);
                            }}
                        >
                            Actions
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            {viewActions ? (
                <>
                    <ActionModal
                        type="case"
                        reloadData={getCases}
                        open={viewActions}
                        row={selectedRow}
                        facilities={[]}
                        setNotification={setNotification}
                        locations={locations}
                        closeModal={() => {
                            setViewActions(false);
                        }}
                    />
                </>
            ) : (
                <>
                    <h1>Cases</h1>
                    <div>
                        <DataTable columns={columns} data={rows} />
                    </div>
                </>
            )}
        </div>
    );
};

export { ViewCases };
