import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { qualityApi } from '../../../api/quality';
import { makeStyles } from '@material-ui/core/styles';

const QualityReceivingModuleResetModal = ({ purchaseOrderMap, setNotification, open, handleClose }) => {
    const classes = makeStyles((theme) => ({
        resetButton: {
            fontSize: 20,
            marginBottom: 25,
            color: 'white',
            backgroundColor: '#485bbd',
            minWidth: 200,
            '&:hover': {
                backgroundColor: '#485bbd',
                color: 'white',
            },
        },
    }))();

    let resetQCModuleOnClick = async (moduleName, displayName) => {
        let response = await qualityApi.resetQCModule(purchaseOrderMap.id, moduleName);

        if (response.status === false) {
            setNotification({ text: response.msg, level: 'error' });
            return;
        }

        setNotification({ text: 'Successfully RESET ' + displayName + '.', level: 'success' });

        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="lg" height="90%">
            <DialogTitle>Make Reset Selection</DialogTitle>
            <DialogContent>
                <div
                    style={{
                        outline: 0,
                        backgroundColor: 'white',
                        width: '700px',
                        margin: '10% auto',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Button
                        className={classes.resetButton}
                        onClick={async () => {
                            await resetQCModuleOnClick('external_inspection', 'External Inspection');
                        }}
                    >
                        Reset External Inspection
                    </Button>

                    <Button
                        className={classes.resetButton}
                        onClick={async () => {
                            await resetQCModuleOnClick('internal_inspection', 'Internal Inspection');
                        }}
                    >
                        Reset Internal Inspection
                    </Button>

                    <Button
                        className={classes.resetButton}
                        onClick={async () => {
                            await resetQCModuleOnClick('inventory_count', 'Units/Case Count');
                        }}
                    >
                        Reset Unit/Case Count
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export { QualityReceivingModuleResetModal };
