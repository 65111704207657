import React, { useState, useEffect } from 'react';
import { pricingApi } from '../../../api/pricing';
import { DataTable, Notifications } from '../../../shared';

const PricingZones = function () {
    const [zipCodes, setZipCodes] = useState([]);

    let getData = async () => {
        let res = await pricingApi.getPricingZones();

        if (res.status === false) {
            return;
        }

        let zoneMap = {};
        res.data.zones.forEach((zone) => {
            zoneMap[zone.id] = zone.name;
        });

        let zips = res.data.zipCodes
            .filter((zip) => zip.pricingZoneID > 0)
            .map((zip) => {
                zip.zoneName = zoneMap[zip.pricingZoneID];
                return zip;
            });
        setZipCodes(zips);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <h2>Pricing Zones</h2>
            Zip codes missing from this table have not been assigned a pricing zone
            <DataTable
                data={zipCodes}
                csvExport={true}
                columns={[
                    { accessor: 'zipCode', Header: 'Zip Code' },
                    { accessor: 'state', Header: 'State' },
                    { accessor: 'zoneName', Header: 'PricingZone' },
                ]}
            />
        </div>
    );
};

export { PricingZones };
