import React from 'react';
import { FormComponent } from '../../../../shared';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

const CarouselModal = ({ open, onClose, createCarousel, brands, initialValues, onSuccess, mostRecentPackGroups }) => {
    const formatPackGroups = (packGroup) => ({
        text: `${packGroup.name} - Week ${packGroup.week}`,
        value: packGroup.id,
    });
    const sortPackGroupsByName = (pg1, pg2) => pg1.name.localeCompare(pg2.name);

    let formFields = [
        {
            name: 'name',
            label: 'Name',
            inputElement: 'textField',
            inputProps: { required: true },
            initialValue: !!initialValues ? initialValues.name : undefined,
        },
        {
            name: 'rankNumber',
            label: 'Rank Number',
            inputElement: 'textField',
            inputProps: { type: 'number', integer: true, required: true },
            initialValue: !!initialValues ? initialValues.rankNumber : undefined,
        },
        {
            name: 'type',
            label: 'Type',
            inputElement: 'select',
            inputProps: { opts: ['NORMAL', 'EASY_REORDER', 'LIST', 'PLUS_MEMBERSHIP_DEALS'], required: true },
            initialValue: !!initialValues ? initialValues.type : 'NORMAL',
        },
        {
            name: 'l1AisleID',
            label: 'L1 Aisle ID',
            inputElement: 'textField',
            inputProps: { type: 'number', integer: true, required: false },
            initialValue: !!initialValues ? initialValues.l1AisleID : undefined,
        },
        {
            name: 'l2AisleID',
            label: 'L2 Aisle ID',
            inputElement: 'textField',
            inputProps: { type: 'number', integer: true, required: false },
            initialValue: !!initialValues ? initialValues.l2AisleID : undefined,
        },
        {
            name: 'linkUrl',
            label: 'Link URL',
            inputElement: 'textField',
            initialValue: !!initialValues ? initialValues.linkUrl : undefined,
        },
        {
            name: 'linkTitle',
            label: 'Link Title',
            inputElement: 'textField',
            initialValue: !!initialValues ? initialValues.linkTitle : undefined,
        },
        {
            name: 'linkDescriptor',
            label: 'Link Descriptor',
            inputElement: 'textField',
            initialValue: !!initialValues ? initialValues.linkDescriptor : undefined,
        },
        {
            name: 'featured',
            label: 'Featured',
            inputElement: 'checkbox',
            inputProps: { ref: 'featured' },
            initialValue: !!initialValues ? initialValues.featured : undefined,
        },
        {
            name: 'photoSmall',
            label: 'Small Image (Max 300 KB)',
            display: (formData) => formData.featured,
            inputElement: 'file',
            accept: ['.png', '.jpg', '.gif'],
            inputProps: { required: true },
            initialValue: !!initialValues ? initialValues.photoUrlSmall : undefined,
        },
        {
            name: 'photoLarge',
            label: 'Large Image (Max 300 KB)',
            display: (formData) => formData.featured,
            inputElement: 'file',
            accept: ['.png', '.jpg', '.gif'],
            inputProps: { required: true },
            initialValue: !!initialValues ? initialValues.photoUrlLarge : undefined,
        },
        {
            name: 'photoMedium',
            label: 'Medium Image (Max 300 KB)',
            display: (formData) => formData.featured,
            inputElement: 'file',
            accept: ['.png', '.jpg', '.gif'],
            inputProps: { required: true },
        },
        {
            name: 'photoExtraSmall',
            label: 'Extra Small Image (Max 300 KB)',
            display: (formData) => formData.featured,
            inputElement: 'file',
            accept: ['.png', '.jpg', '.gif'],
            inputProps: { required: true },
        },
        {
            name: 'backgroundColor',
            label: 'Background Color Hex Code',
            display: (formData) => formData.featured,
            inputElement: 'textField',
            initialValue: !!initialValues ? initialValues.backgroundColor : undefined,
        },
        {
            name: 'percentOff',
            label: 'Percent Off',
            display: (formData) => formData.featured,
            inputElement: 'textField',
            inputProps: { integer: true, inputType: 'number' },
            initialValue: !!initialValues ? initialValues.percentOff : undefined,
        },
        {
            name: 'description',
            label: 'Description',
            display: (formData) => formData.featured,
            inputElement: 'textField',
            inputProps: { multiline: true },
            initialValue: !!initialValues ? initialValues.description : undefined,
        },
        {
            name: 'sponsoredBrands',
            label: 'Sponsored Brand Names',
            inputElement: 'autocomplete',
            inputProps: { multiple: true, opts: brands },
            initialValue: !!initialValues ? initialValues.sponsoredBrands : undefined,
        },
        {
            name: 'packGroupIDs',
            label: 'Apply To Pack Group',
            display: !!initialValues,
            inputElement: 'autocomplete',
            inputProps: {
                required: true,
                opts: mostRecentPackGroups
                    ? [
                          ...mostRecentPackGroups.nextWeek.slice().sort(sortPackGroupsByName).map(formatPackGroups),
                          ...mostRecentPackGroups.currentWeek.slice().sort(sortPackGroupsByName).map(formatPackGroups),
                      ]
                    : [],
                multiple: true,
            },
        },
        {
            name: 'duplicateItems',
            label: 'Duplicate Items?',
            display: !!initialValues,
            inputElement: 'select',
            inputProps: {
                opts: [
                    { text: 'No', value: 'no' },
                    { text: 'Duplicate all (return errors)', value: 'all' },
                    { text: 'Duplicate available (ignore errors)', value: 'available' },
                ],
                required: true,
            },
            initialValue: 'no',
        },
        {
            name: 'duplicateOriginalID',
            display: false,
            inputElement: 'textField',
            inputProps: { required: true },
            initialValue: !!initialValues ? initialValues.id : undefined,
        },
    ];

    return (
        <>
            {!!initialValues ? (
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData) => {
                        formData.packGroupIDs = formData.packGroupIDs.map((item) => item.value);
                        let res = await createCarousel(formData);
                        if (onSuccess && onSuccess instanceof Function && res) {
                            onSuccess();
                        }
                        return res;
                    }}
                />
            ) : (
                <Dialog open={open} onClose={onClose}>
                    <DialogTitle>Create Carousel</DialogTitle>
                    <DialogContent>
                        <FormComponent
                            formFields={formFields}
                            onSubmit={async (formData) => {
                                let res = await createCarousel(formData);
                                if (res) {
                                    onClose();
                                }
                                return res;
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export { CarouselModal };
