import React, { useState, useEffect } from 'react';
import { Notifications, DataTable } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';
import { useHistory } from 'react-router-dom';
import {
    Modal,
    Button,
    Select,
    NativeSelect,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';

let IssueGoPuffCredits = () => {
    let [rows, setRows] = useState([]);
    let [creditReasons, setCreditReasons] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const history = useHistory();
    const queryParameters = new URLSearchParams(window.location.search);
    const poNum = queryParameters.get('poNum');

    let getCreditReasons = async () => {
        let response = await api.getPurchaseOrderCreditReasons(poNum);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setCreditReasons(response.data.rows ? response.data.rows : []);
    };

    // Load  table
    let getRows = async () => {
        let response = await api.getPurchaseOrderDetails(poNum);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        let rowsWithDefaults = response.data.rows
            ? response.data.rows.map((row) => ({
                  ...row,
                  numToCredit: 0,
                  reason: '',
              }))
            : [];

        setRows(rowsWithDefaults);
    };

    useEffect(() => {
        getRows();
        getCreditReasons();
    }, []);

    let handleReviewItems = () => {
        const reviewRows = rows
            .map((row) => {
                if (row.numToCredit > 0 && row.reason !== '') {
                    return {
                        ...row,
                        dollarToCredit: parseFloat((row.pricePerSKU * row.numToCredit).toFixed(2)),
                    };
                }
                return row;
            })
            .filter((row) => row.dollarToCredit > 0);

        if (reviewRows.length > 0) {
            history.push('/gopuff/review-po-credits', { rows: reviewRows });
        } else {
            setNotification({ text: 'No entries found for review. Must have number and reason', level: 'error' });
        }
    };

    let columns = [
        { accessor: 'poNum', Header: 'PO#' },
        { accessor: 'microFC', Header: 'GP MFC' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        { accessor: 'externalSKU', Header: 'External SKU' },
        { accessor: 'name', Header: 'Product Name' },
        { accessor: 'numOrdered', Header: '# Ordered' },
        { accessor: 'pricePerSKU', Header: 'Price Per SKU' },
        { accessor: 'numToCredit', Header: '# Credit', editable: true },
        { accessor: 'reason', Header: 'Reason', editable: true, editProps: { type: 'select', options: creditReasons } },
    ];

    return (
        <div>
            <h1>Issue Go Puff Credits</h1>
            <Notifications options={notification} />
            <div>
                <DataTable
                    columns={columns}
                    data={rows}
                    editable={true}
                    saveEdit={async ({ id, field, value, row }) => {
                        if (field === 'numToCredit') {
                            if (value > row.numOrdered) {
                                setNotification({
                                    text: 'Num Credit can’t be higher than Num Ordered',
                                    level: 'error',
                                });
                                return;
                            }
                        }

                        const updatedRows = rows.map((r) => {
                            if (r.externalSKU === row.externalSKU) {
                                return { ...r, [field]: value };
                            }
                            return r;
                        });
                        setRows(updatedRows);
                    }}
                />
            </div>
            <Button variant="contained" color="primary" onClick={handleReviewItems} style={{ marginTop: '20px' }}>
                Review Credits
            </Button>
        </div>
    );
};

export { IssueGoPuffCredits };
