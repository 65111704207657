import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { goPuffApi as api } from '../../../api/goPuff';
import { Modal, Button, Select, NativeSelect, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';

let ViewGoPuffBatches = () => {
    let [printableRows, setPrintableRows] = useState([]);
    let [printedRows, setPrintedRows] = useState([]);
    let [allocationRows, setAllocationRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const [printableRowsGroupBy, setPrintableRowsGroupBy] = useState('lineHaul');
    const [lineHaulGroupedRows, setLineHaulGroupedRows] = useState([]);
    const [gpMarketGroupedRows, setGpMarketGroupedRows] = useState([]);
    const user = useContext(UserContext);

    let getPrintableRows = async () => {
        let response = await api.getPrintableBatches(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setPrintableRows([]);
            return;
        }

        setPrintableRows(response.data.rows.lineHaulGroupedArr ? response.data.rows.lineHaulGroupedArr : []);
        setLineHaulGroupedRows(response.data.rows.lineHaulGroupedArr ? response.data.rows.lineHaulGroupedArr : []);
        setGpMarketGroupedRows(
            response.data.rows.gpPackMarketGroupedArr ? response.data.rows.gpPackMarketGroupedArr : []
        );
    };

    let getPrintedRows = async () => {
        let response = await api.getPrintedBatches(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setPrintableRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY HH:mm:ss');
                return row;
            });
        }

        setPrintedRows(response.data.rows ? response.data.rows : []);
    };

    let getAllocationRows = async () => {
        let response = await api.getAllocationRollup(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setPrintableRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.deliveryDate = moment(row.deliveryDate.date).format('MM/DD/YYYY');
                return row;
            });
        }

        setAllocationRows(response.data.rows ? response.data.rows : []);
    };

    useEffect(() => {
        getPrintableRows();
        getPrintedRows();
        getAllocationRows();
    }, []);

    const togglePrintableRows = (e) => {
        setPrintableRowsGroupBy(e.target.value);
        if (e.target.value === 'gpMarket') {
            setPrintableRows(gpMarketGroupedRows);
        } else {
            setPrintableRows(lineHaulGroupedRows);
        }
    };

    let allocationColumns = [
        { accessor: 'packingFC', Header: 'Packing FC' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        { accessor: 'numShipments', Header: 'Num Shipments' },
        {
            accessor: 'download',
            Header: 'Download Template',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Download`}
                        onClick={async () => {
                            api.downloadAllocationCsv(user.getFacilityID(), row.original.deliveryDate);
                        }}
                    >
                        Download Template
                    </Button>
                );
            },
        },
    ];

    let printableColumns = [
        { accessor: 'lineHaulName', Header: 'Line Haul', hide: printableRowsGroupBy === 'gpMarket' },
        { accessor: 'gpPackMarket', Header: 'GP Pack Market', hide: printableRowsGroupBy === 'lineHaul' },
        { accessor: 'numMFCs', Header: 'Num MFCs' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        {
            accessor: 'print',
            Header: 'Print',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Print`}
                        onClick={async (e) => {
                            e.stopPropagation();
                            let value;

                            if (printableRowsGroupBy === 'gpMarket') {
                                value = row.original.gpPackMarket;
                            } else {
                                value = row.original.id;
                            }

                            await api.printBatch(
                                user.getFacilityID(),
                                printableRowsGroupBy,
                                value,
                                row.original.deliveryDate
                            );

                            getPrintableRows();
                        }}
                    >
                        Print
                    </Button>
                );
            },
        },
    ];

    let printedColumns = [
        { accessor: 'batchID', Header: 'Batch ID' },
        { accessor: 'createdAt', Header: 'Printed On' },
        { accessor: 'numItems', Header: 'Num Shipments' },
        { accessor: 'creator', Header: 'Printed By' },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Download`}
                        onClick={async () => {
                            api.reprintBatch(row.original.batchID);
                        }}
                    >
                        Download PDF
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h1>Go Puff Batches</h1>
            <Notifications options={notification} />
            <div>
                <h2>Available for Allocation Adjustment</h2>
                <Link to={`/goPuff/adjustments-upload`}>Upload Adjustments</Link>
                <DataTable columns={allocationColumns} data={allocationRows} />
                <h2>Available to Print</h2>
                <div>
                    <RadioGroup
                        style={{ width: 'auto' }}
                        defaultValue={'lineHaul'}
                        value={printableRowsGroupBy}
                        onChange={togglePrintableRows}
                        row
                    >
                        <FormControlLabel value={'lineHaul'} control={<Radio />} label="Group By Line Haul" />
                        <FormControlLabel value={'gpMarket'} control={<Radio />} label="Group By GP Market" />
                    </RadioGroup>
                </div>
                <DataTable
                    columns={printableColumns}
                    data={printableRows}
                    expandable={true}
                    ExpansionComponent={
                        <ViewLineItems setNotification={setNotification} printableRowsGroupBy={printableRowsGroupBy} />
                    }
                />
                <h2>Printed</h2>
                <DataTable columns={printedColumns} data={printedRows} />
            </div>
        </div>
    );
};

const ViewLineItems = ({ parentRow, setNotification, printableRowsGroupBy }) => {
    const [lineData, setLineData] = useState([]);
    const [searchVal, setSearchVal] = useState(
        printableRowsGroupBy === 'lineHaul' ? parentRow.original.id : parentRow.original.gpPackMarket
    );
    const user = useContext(UserContext);

    const getLineData = async () => {
        let res = await api.getPrintableBatchesDetails(
            printableRowsGroupBy,
            searchVal,
            parentRow.original.deliveryDate
        );
        if (res.status === false) {
            setNotification({ text: 'No printable batches Micro FC shipments found ' + res.msg, level: 'error' });
            setLineData([]);
            return;
        }

        setLineData(res.data.rows ? res.data.rows : []);
    };

    useEffect(() => {
        getLineData();
    }, []);

    let columns = [
        { accessor: 'microFC', Header: 'Micro FC' },
        { accessor: 'poNum', Header: 'PO ID' },
        { accessor: 'numShipments', Header: 'Num Shipments' },
        {
            accessor: 'print',
            Header: 'Print',
            Cell: ({ cell: { value: initialValue }, row }) => {
                return (
                    <Button
                        id={`Print`}
                        onClick={async () => {
                            await api.printBatch(
                                user.getFacilityID(),
                                'microFC',
                                row.original.microFC,
                                parentRow.original.deliveryDate
                            );

                            getLineData();
                        }}
                    >
                        Print
                    </Button>
                );
            },
        },
    ];

    return <DataTable columns={columns} data={lineData} />;
};

export { ViewGoPuffBatches };
